import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import { sharedHeader_template } from "../components/templates/sharedHeader_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class sharedHeader extends vnModule_knockoutJS{

    protected requestHandler: XMLHttpRequestHandler;
    protected template: sharedHeader_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return 'sharedHeader';
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Shared Header");

        // create the users form
        this.template = new sharedHeader_template('sharedHeader_template', this.viewModel, this);

        return [this.template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getEditData();
    }

    /**
     * Hook.
     *
     * Cancels the edits to a user.
     */
    protected cancel(){

        this.loadingIn(this.template.builtTemplate);

        let idBrand = this.getVnAppZone().app.params.idBrand;

        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, 'The ads.txt action was canceled!');

        page.redirect(`/brand/${idBrand}/ads_setups`);
    }

    /**
     * Requests a lock release.
     */
    protected releaseLock(): void{

        let formReq_params: Array<Array<string>> = [];

        // Request a lock release.
        this.requestHandler = new XMLHttpRequestHandler(`/api/site_config/${this.getVnAppZone().app.params.id}/release_lock/`, formReq_params, this);
        this.requestHandler.execute();
    }


    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditData(): void{

        this.loadingIn(this.template.builtTemplate);

        let idBrand = this.getVnAppZone().app.params.idBrand;

        this.requestHandler = new XMLHttpRequestHandler(`/api/brand/${idBrand}/site_config/get/`, [], this);
        this.requestHandler.onReadyStateFunction = this.onEditDataRequestReturn;
        this.requestHandler.execute();

    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onEditDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){


                let responseParsed = JSON.parse(req.request.response || '{}');

                obj.updateEditData(responseParsed);

                obj.loadingOut(obj.template.builtTemplate);
            });
        }
    }

    /**
     * Updates the form elements.
     */
    protected updateEditData(responseParsed: any){

        if(responseParsed.data !== null) {

            this.template.setData(responseParsed.data);
        }
    }

    /**
     * Hook.
     *
     * Saves the data.
     */
    protected save(): void{

        this.loadingIn(this.template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.template.getViewModelForRequest();
        let idBrand = this.getVnAppZone().app.params.idBrand;

        formReq_params.push(["data", ko.toJSON(subViewModel)] );


        let requestHandler:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/brand/${idBrand}/site_config/save/`, formReq_params, this);
        requestHandler.mode                      = XMLHttpRequestHandler_requestType.POST;
        requestHandler.onReadyStateFunction      = this.onSaveReturn;
        requestHandler.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{

        let idBrand = obj.getVnAppZone().app.params.idBrand;

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'The ads.txt action was successful!');
                obj.releaseLock();
                page.redirect(`/brand/${idBrand}/ads_setups`);
            });

            obj.loadingOut(obj.template.builtTemplate);
        }
    }
}
