import {vnTemplate} from "./vnTemplate";

declare var ko: any;

export class filterPreset_template extends vnTemplate{

    protected getTemplateHtml(): string{

        return `
            <div class="w-left">
                <strong class="small">My preset filters:</strong>
                    <div>
                        <div data-bind="foreach: searchPresets">
                            <div class="btn secondary" >
                                <input type="radio" data-bind="checked: $parent.searchPresetSelected().id,  value: id,  attr: { id: 'searchPreset_' + id, name: 'searchPreset'}">
                                <label class="form-label icon"  data-bind="attr: {for: 'searchPreset_' + id }, event:{ click: $parent.onSetSearchPresetToggleFavorite}" ></label>
                                <span data-bind="event:{ click: $parent.onApplySearchPreset}, attr: {for: 'searchPreset_' + id }, text: name">present name</span>
                                <button class="delete icon i-delete" data-bind="event:{ click: $parent.onDeleteSearchPreset}"><span>delete</span></button>
                            </div>
                        </div>
                        
                    </div>
                    <div>
                        <button class="btn icon i-add" data-bind="css: {'is-hidden': showSaveSearchPreset }, event:{ click: onShowSaveSearchPreset}"></button>
                        <div class="filter-feild-name" data-bind="css: { 'is-showing': showSaveSearchPreset }">
                            <input class="input-text" placeholder="Preset filter name" data-bind="value: searchPresetName"/>
                            <button class="btn secondary icon i-check" type="button" data-bind="event: { click: onSaveSearchPreset}" ><span>Save Preset</span></button> 
                        </div>
                    </div>

           </div>
    
            `;
    }

    protected getTemplateName(): string{
        return "filterPreset-template";
    }

    protected buildViewModel(): any{

        // define the viewmodel data/ functions
        let huit_neuf_this = this;
        return  {
            searchPresets: ko.observableArray([]),
            showSaveSearchPreset: ko.observable(false),
            searchPresetSelected: ko.observable({id: null, name: null}),
            disableSaveSearchPreset: ko.observable(false),
            searchPresetType: ko.observable('default'),
            searchPresetName: ko.observable(''),
            onSaveSearchPreset: (data, event) => {
                huit_neuf_this.onSaveSearchPreset();
            },
            onSetSearchPresetToggleFavorite: (data, event) => {
                huit_neuf_this.onSetSearchPresetToggleFavorite(data);
            },
            onDeleteSearchPreset: (data, event) => {
                huit_neuf_this.onDeleteSearchPreset(data);
            },
            onApplySearchPreset: (data, event) => {
                huit_neuf_this.onApplySearchPreset(data);
            },
            onShowSaveSearchPreset: (data, event) => {
                huit_neuf_this.onShowSaveSearchPreset();
            }
        };
    }

    public onShowSaveSearchPreset(): void {

        this.getTemplateViewModel().showSaveSearchPreset(true);
    }

    public onSaveSearchPreset(): void {

        let isSaveDisabled = this.getTemplateViewModel().disableSaveSearchPreset();

        if (!isSaveDisabled) {

            this.getTemplateViewModel().disableSaveSearchPreset(true);
            this.caller.saveSearchPreset();
        }
    }

    public onSetSearchPresetToggleFavorite(data): void {

        this.getTemplateViewModel().searchPresetSelected(data);
        let type = this.getTemplateViewModel().searchPresetType();

        //clear favorite when going from true to false
        if(data.isFavorite){
            this.getTemplateViewModel().searchPresetSelected({id: null, name: null, isFavorite: false});
        }

        let params = {presetId: data.id, searchPresetType: type};

        this.caller.saveFavoriteSearchPreset(params);

        this.caller.getSearchPresetsListData();
    }

    public onDeleteSearchPreset(preset): void {

        let presets = this.getTemplateViewModel().searchPresets();
        let remainingPresets = presets.filter((p) =>  (p.id != preset.id));

        this.getTemplateViewModel().searchPresets(remainingPresets);

        this.caller.deleteSearchPreset(preset.id);
    }

    public onApplySearchPreset(preset): void {

        let presets = this.getTemplateViewModel().searchPresets();

        this.getTemplateViewModel().searchPresets([]);
        this.getTemplateViewModel().searchPresets(presets);

        if(preset.url == null || preset.url == ""){

            this.caller.filterByPresets(preset.filters);
        }else{

            window.history.pushState({}, '', preset.url);
            this.caller.filterByUrl();
        }
    }

    public addCreatedPresetToList(preset): void {

        this.getTemplateViewModel().searchPresetName('')
        this.getTemplateViewModel().showSaveSearchPreset(false);
        let presets = this.getTemplateViewModel().searchPresets();

        // append and sort.
        presets.push(preset);
        presets.sort((a,b) => (a.name.localeCompare(b.name)));

        this.getTemplateViewModel().searchPresets(presets);
        this.getTemplateViewModel().disableSaveSearchPreset(false);
    }

    public setSearchPresets(presets: Array<{id: number, idUser:number, name: string, filters, isFavorite: boolean, url: string}>): void {

        this.getTemplateViewModel().searchPresets(presets);

        let isFavorite = presets.filter((p) => (p.isFavorite == true));
        let preset = isFavorite.shift();

        let currentUrl = window.location.href;

        if (preset != undefined) {

            this.getTemplateViewModel().searchPresetSelected(preset);
            if(currentUrl.includes("?")){
                return;
            }

            if(preset.url == null || preset.url == ""){

                this.caller.filterByPresets(preset.filters);
            }else{

                window.history.pushState({}, '', preset.url);
                this.caller.filterByUrl();
            }
        }
        else {
            let queryString = window.location.href.split('?')[1];
            if(queryString == null){
                this.caller.setDefaultStatusFilters();
            }
        }
    }
}
