import {vnTemplate} from "./vnTemplate";

declare var ko: any;

export class pagination_template extends vnTemplate{

    protected getTemplateHtml(): string{

        return `
            <div class="pagination">
                <input data-bind="value: pageNumber" type="hidden">
                <span><a data-bind="event: { click: onClickPageFirst }" class="hidden prev">first</a></span>
                <ul data-bind="foreach: pages">
                    <li><a data-bind="css: { active: isSelected > 0 }, text: pageNumber, event: { click: $parent.onClickPageAction }" class="page"></a></li>
                </ul>
                <span><a data-bind="event: { click: onClickPageLast }" class="hidden next" >last</a></span>
            </div>
            `;
    }

    protected getTemplateName(): string{
        return "pagination-template";
    }

    protected getMainElementClasses(): Array<string>{
    	return ["w-pagination"];
    }

    protected buildViewModel(): any{

        // define the viewmodel data/ functions
        let huit_neuf_this = this;
        return  {    pageNumber: ko.observable(1)
                    ,pageMax: ko.observable(1)
                    ,pages: ko.observableArray([ {pageNumber: 1, isSelected: 1} ])
                    ,itemCount: function (data, event) { huit_neuf_this.setNumberItemToPaginate(data); }
                    ,itemPerPage: 25
                    ,reducedSidePages: false
                    ,onClickPageAction : function(data, event){ huit_neuf_this.onClickPageAction(data.pageNumber); }
                    ,onClickPageFirst : function(data, event){ huit_neuf_this.onClickPageFirst(); }
                    ,onClickPageLast : function(data, event){ huit_neuf_this.onClickPageLast(data.pageMax()); }
                };
    }

    public setNumberItemToPaginate(numberItemToPaginate: number): void{

        let numberOfPage = Math.ceil(numberItemToPaginate/this.viewModel[this.idElement].itemPerPage);
        let reducedSidePages = this.viewModel[this.idElement].reducedSidePages;
        let firstOffset = 2;
        let secondOffset = 3;
        if(reducedSidePages){
            firstOffset--;
            secondOffset--;
        }

        let pages = [];
        for (var _i = this.viewModel[this.idElement].pageNumber()-firstOffset; _i <= numberOfPage; _i++) {
            let pn = this.viewModel[this.idElement].pageNumber();

            if( _i < (pn+secondOffset) && pn > (pn-secondOffset) && _i >= 1){

                if( _i == this.viewModel[this.idElement].pageNumber() ){
                    pages.push({pageNumber: _i, isSelected: 1})
                }
                else{
                    pages.push({pageNumber: _i, isSelected: 0})
                }
            }
        }

        this.viewModel[this.idElement].pages(pages);
        this.viewModel[this.idElement].pageMax(numberOfPage);
    }

    //
    // Update the pageNumber with the one that is being clicked on
    protected onClickPageAction(pageNumber: number): void{
        this.filterList(pageNumber);
    }
    protected onClickPageFirst(): void{

        this.filterList(1);
    }
    protected onClickPageLast(pageMax: number): void{

        this.filterList(pageMax);
    }

    protected filterList(pageNumber){

        this.viewModel[this.idElement].pageNumber(pageNumber);
        this.caller.paginationOnPageClick(this.idElement);
    }
}
