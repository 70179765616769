import { sponsoredRevenueEdit_template } from '../components/templates/sponsoredRevenueEdit_template';
import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import {header} from "./header";
import {vnApp_ads_ninja} from "../components/vnApp_ads_ninja";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";

declare var ko: any;
declare var page: any;

export class sponsoredRevenueEdit extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected sponsoredRevenueEdit_template: sponsoredRevenueEdit_template;

    protected getModuleName(){

        return "sponsoredRevenueEdit";
    }


    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Sponsored Revenue");

        this.sponsoredRevenueEdit_template = new sponsoredRevenueEdit_template("sponsoredRevenueEdit_template", this.viewModel, this);
        return [this.sponsoredRevenueEdit_template.build()];
    }

    fillPage() : void{

        this.sponsoredRevenueEdit_template.buildDatePickerCalendar();
        this.getFormData();
    }

    protected cancel(){

        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "Sponsored Revenue action canceled !");
        page.redirect('/sponsored_revenue');
    }

    protected getFormData(){

        this.loadingIn(this.sponsoredRevenueEdit_template.builtTemplate);

        let id: number;
        let action: string;

        if( (id = this.getVnAppZone().app.params.id) && !isNaN(id)) {
            this.viewModel["sponsoredRevenueEdit_template"].idSponsoredRevenue(id);
        }

        let usrReq_url: string = '/api/sponsoredRevenue/get/' + id + "/";
        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(["data", ko.toJSON(this.viewModel["sponsoredRevenueEdit_template"])] );
        this.XHRHdl = new XMLHttpRequestHandler(usrReq_url, usrReq_params, this);

        this.XHRHdl.onReadyStateFunction = this.onGetFormDataReturn;
        this.XHRHdl.execute();
    }
    //
    // use the form data to fill the form
    protected onGetFormDataReturn(req, obj){

        return function () {

            obj.manageResponse(req, obj, function () {

                let responseParsed = JSON.parse(req.request.response);
                obj.sponsoredRevenueEdit_template.setupForm(responseParsed.data.brands, responseParsed.data.sponsoredRevenue);
            });

            obj.loadingOut(obj.sponsoredRevenueEdit_template.builtTemplate);
        }
    }

    protected getPermalinkDate(){

        //this.loadingIn(this.sponsoredRevenueEdit_template.builtTemplate);
        console.log("in permalink");

        let _this = this;
        let formReq_url: string = '/api/sponsoredRevenue/getPermalinkDate';
        let formReq_params: Array<Array<string>> = [];

        if(this.XHRHdl){

            this.XHRHdl.cancelAndReset();
        }

        let subViewModel = JSON.parse(ko.toJSON(this.sponsoredRevenueEdit_template.getViewModelForRequest()));
        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        console.log(formReq_url);

        this.XHRHdl = new XMLHttpRequestHandler(formReq_url,formReq_params, this);


        this.XHRHdl.onReadyStateFunction = this.onGetPermalinkDateReturn;
        this.XHRHdl.execute();


    }
    //
    // use the form data to fill the form
    protected onGetPermalinkDateReturn(req, obj){

        return function () {

            obj.manageResponse(req, obj, function () {

                console.log("return permalink");
                let responseParsed = JSON.parse(req.request.response);
                obj.sponsoredRevenueEdit_template.setPermalinkDate(responseParsed.date);
            });

            //obj.loadingOut(obj.sponsoredRevenueEdit_template.builtTemplate);
        }
    }

    protected save(){

        this.loadingIn(this.sponsoredRevenueEdit_template.builtTemplate);

        let _this = this;
        let formReq_url: string = '/api/sponsoredRevenue/save';
        let formReq_params: Array<Array<string>> = [];

        let subViewModel = JSON.parse(ko.toJSON(this.sponsoredRevenueEdit_template.getViewModelForRequest()));
        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(formReq_url,formReq_params, this);
        XHRHdl.mode = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction = (function(req, obj){

            return function () {

                obj.manageResponse(req, obj, function () {

                    let responseParsed = JSON.parse(req.request.response);

                    obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, "Sponsored Revenue saved successfully !");

                    page.redirect('/sponsored_revenue');
                });
                obj.loadingOut(obj.sponsoredRevenueEdit_template.builtTemplate);

            }
        });
        XHRHdl.execute();
    }


    protected filterDropdownOnChange(): void {

        this.sponsoredRevenueEdit_template.brandChange();
        this.sponsoredRevenueEdit_template.validate();
    }


    protected filterSingleDateOnChange(): void {

    }
}
