import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { usersProfile_template } from "../components/templates/usersProfile_template";
import {header} from "./header";
import {vnApp_ads_ninja} from "../components/vnApp_ads_ninja";

declare var ko: any;
declare var page: any;
declare var Dropzone: any;

export class users_profile extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected userProfile: usersProfile_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return "users_profile";
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("User Profile");

        // create the users form
        this.userProfile = new usersProfile_template("userProfile", this.viewModel, this);

        return [this.userProfile.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getFormData();
    }

    /**
     * Hook.
     *
     * Redirects to edit user page.
     *
     * @param idUser
     */
    protected editUser(idUser): void{

        page(`/users/edit/${idUser}`)
    }

    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getFormData(): void{

        this.loadingIn(this.userProfile.builtTemplate);

        let id = this.getVnAppZone().app.params.id || null;

        if(id) {
            this.userProfile.getTemplateViewModel().idUser(id);
        }

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(['data', ko.toJSON(this.userProfile.getViewModelForRequest())]);

        this.XHRHdl                      = new XMLHttpRequestHandler(`/api/users/get/${id}`, [['lock', '0']], this);
        this.XHRHdl.onReadyStateFunction = this.onGetFormDataReturn;
        this.XHRHdl.execute();
    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onGetFormDataReturn(req, obj): Function{

        return function(){
            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response || '{}');
                let user           = responseParsed.data.user;
                let permission     = responseParsed.permission;

                if(user.valnet_user.id !== undefined && user.valnet_user.id !== null){

                    obj.userProfile.setRoles(user.valnet_user.roles);
                    obj.userProfile.setProperties(user.properties);
                    obj.updateElementForm(user, obj);
                }

                obj.userProfile.setHasUpdateAccess((permission.update));
                obj.loadingOut(obj.userProfile.builtTemplate);
            })
        }
    }

    /**
     * Updates the form elements.
     *
     * @param user
     * @param thisModule
     */
    protected updateElementForm(user, thisModule: users_profile): void{

        this.userProfile.getTemplateViewModel().idUser(user.id);
        this.userProfile.getTemplateViewModel().firstName(user.valnet_user.firstName);
        this.userProfile.getTemplateViewModel().lastName(user.valnet_user.lastName);
        this.userProfile.getTemplateViewModel().loginName(user.valnet_user.loginName);
        this.userProfile.getTemplateViewModel().email(user.valnet_user.email);
        this.userProfile.getTemplateViewModel().pictureUrl((user.valnet_user.pictureUrl) ? `/usersImages/${user.valnet_user.pictureUrl}` : '/assets/images/default-user.png');
        this.userProfile.getTemplateViewModel().isActive((user.valnet_user.isActive) ? 'true' : 'false');
    }
}
