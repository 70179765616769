import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class templateTypeDependencies_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <h3 class="form-title icon i-dependencies">Edit Template Type Dependencies</h3>
                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
            <div class="w-form-body">
                <!-- ko foreach: dependencies -->
                <div class="form-body"> 
                    
                    <h2 class="form-sub-title" data-bind="text: templateType.name"></h2>
                        
                    <div class="form-group">
                        <label class="form-label">Header</label>
                        <textarea data-bind="value: header" class="input-text long"style="height: 120rem"></textarea>
                    </div> 
                    <div class="form-group">
                        <label class="form-label">Footer</label>
                        <textarea data-bind="value: footer" class="input-text long"></textarea>
                    </div>  
                    <div class="form-group">
                        <label class="form-label">Style</label>
                        <textarea data-bind="value: style" class="input-text long"></textarea>
                    </div>       
                   
                </div>
                <!-- /ko -->  
            </div>
            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "templateTypeDependencies-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "w-form-large","templateTypeDependencies"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;

        return {
            dependencies: ko.observableArray([]),
            allTypes: ko.observableArray([]),
            brand: ko.observable({}),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); }
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            data: this.getTemplateViewModel().dependencies(),
        };
    }

    public setFormData(data): void{

        let allDependencies   = [];
        let allTypes     = this.getTemplateViewModel().allTypes();
        let brand        = this.getTemplateViewModel().brand();
        let dependencies = data;

        allTypes.forEach(function(element, index){

            let found = dependencies.find(i => i.idTemplateType === element.id);

            if(found){

                allDependencies.push(found);
            }
            else{

                allDependencies.push({
                    id: null,
                    idBrand: brand.id,
                    brand: brand,
                    idTemplateType: element.id,
                    templateType: element,
                    header: '',
                    footer: '',
                    style: '',
                    dateCreated: null,
                    dateUpdated: null,
                });
            }
        });

        this.getTemplateViewModel().dependencies(allDependencies);
    }

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        this.caller.save();
    }

    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancel();
    }
}
