import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";
import {infoButton_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/infoButton_template";

declare var ko: any;

export class siteConfig_template extends vnTemplate{

    protected biddingTypeHelpInfoButton: infoButton_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <!-- ko ifnot: id -->
                <h3 class="form-title icon i-config">Edit Config</h3>
                <!-- /ko -->

                <!-- ko if: id -->
                <h3 class="form-title icon i-config">Edit Config</h3>
                <!-- /ko -->

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
            <div class="w-form-body">
                <div class="form-body full-width">
                    <div class="form-group row ">
                        <div class="with-tooltips">
                            <label class="form-label">Bidding Type</label>
                            <div class="w-tooltips-adv" data-bind="template: { name: 'infoButton-template', data: $data.biddingTypeHelpInfoButton }"></div>
                        </div>
                        <div data-bind="foreach: $root.siteConfig_template.biddingTypes">
                            <div class="form-check-inline" style="width: auto;margin-right: 2rem;"> 
                                <input type="radio" data-bind="checked: $parent.biddingType, value: id, attr: {'id': 'biddingType_' + id, 'name': 'biddingType_' + id}">
                                <label class="form-label" style="width: auto" data-bind="text: name, attr: {'for': 'biddingType_' + id}"></label>
                            </div>
                        </div>
                    </div> 
                    <div class="form-group row">
                        <label for="globFailSafeTimeout" class="form-label">Global Failsafe Timeout(ms)</label>
                        <input id="globFailSafeTimeout" data-bind="value: globFailSafeTimeout" type="text" class="input-text num">
                    </div>
                    <div class="form-group row">
                        <label for="cmpTimeout" class="form-label">CMP Timeout(ms)</label>
                        <input id="cmpTimeout" data-bind="value: cmpTimeout" type="text" class="input-text num">
                    </div>    
                    <div class="form-group row">
                        <label for="prebidTimeout" class="form-label">Prebid Auction Timeout(ms)</label>
                        <input id="prebidTimeout" data-bind="value: prebidTimeout" type="text" class="input-text num">
                    </div>
                    <div class="form-group row">
                        <label for="amazonTimeout" class="form-label">Amazon Auction(ms)</label>
                        <input id="amazonTimeout" data-bind="value: amazonTimeout" type="text" class="input-text num">
                    </div>
                    <div class="form-group row">
                        <input id="adUnitBackgroundDetailsEnabled" type="checkbox" data-bind="checked: adUnitBackgroundDetailsEnabled, attr: {'name': 'adUnitBackgroundDetailsEnabled' }">
                        <label for="adUnitBackgroundDetailsEnabled" class="form-label icon i-check">Ad Unit Background Details</label>
                    </div>
                    <div class="form-group row">
                        <input id="adUnitLoadingAnimationEnabled" type="checkbox" data-bind="checked: adUnitLoadingAnimationEnabled, attr: {'name': 'adUnitLoadingAnimationEnabled' }">
                        <label for="adUnitLoadingAnimationEnabled" class="form-label icon i-check">Ad Unit Loading Animation</label>
                    </div>                
                    <div class="form-group row">
                        <input id="sentinelEnabled" type="checkbox" data-bind="checked: sentinelEnabled, attr: {'name': 'sentinelEnabled' }">
                        <label for="sentinelEnabled" class="form-label icon i-check">Sentinel enabled</label>
                    </div>
                    <div class="form-group row textarea">
                        <label for="allowedDomains" class="form-label">Allowed Domains</label>
                        <textarea id="allowedDomains" data-bind="value: allowedDomains" class="input-text"></textarea>
                    </div> 
                    
               </div> 
            </div>

            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

        let biddingTypeHelpInfoButtonContent = `
            <p>Determines where JavaScript and other HTML elements will be added to the page.</p>
            <div style="display: inline-block">
                <p>Footer: This will print the JavaScript and other HTML elements, which are not required to be in the header of the page, to instead be printed to the end of the HTML document body. This process for selecting the required elements from what is put in this field is automatic. The reason to use the Footer for printing these elements is to deprioritize them and give priority to other javascript elements on the page. Ads may load later but content should load faster.</p>
            </div>
            <br/>
            <div style="display: inline-block">
                <p>Header: All JavaScript and other HTML elements will be added in the head of HTML document. May increase load times and delay execution of other JavaScript. Ads may load sooner but content could load slower.</p>
            </div>
        `;

        this.biddingTypeHelpInfoButton = new infoButton_template(`biddingTypeHelpInfoButton`, this.getTemplateViewModel(), this.caller);
        this.biddingTypeHelpInfoButton.setTitle('Help: Bidding Type');
        this.biddingTypeHelpInfoButton.setContent(biddingTypeHelpInfoButtonContent);
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "siteConfig-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;

        return {
            id : null,
            globFailSafeTimeout: ko.observable(2000),
            cmpTimeout: ko.observable(5000),
            prebidTimeout: ko.observable(2000),
            amazonTimeout: ko.observable(2000),
            sharedHeader: ko.observable(''),
            allowedDomains: ko.observable(''),
            biddingType: ko.observable('header'),
            biddingTypes: ko.observableArray([]),
            adUnitBackgroundDetailsEnabled: ko.observable(true),
            adUnitLoadingAnimationEnabled: ko.observable(false),
            sentinelEnabled: ko.observable(true),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); }
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            siteConfig: this.getTemplateViewModel(),
        };
    }

    public setSiteConfig(siteConfig): void{
        this.getTemplateViewModel().id = siteConfig.id;
        this.getTemplateViewModel().globFailSafeTimeout(siteConfig.globFailSafeTimeout);
        this.getTemplateViewModel().cmpTimeout(siteConfig.cmpTimeout);
        this.getTemplateViewModel().prebidTimeout(siteConfig.prebidTimeout);
        this.getTemplateViewModel().amazonTimeout(siteConfig.amazonTimeout);
        this.getTemplateViewModel().sharedHeader(siteConfig.sharedHeader);
        this.getTemplateViewModel().allowedDomains(siteConfig.allowedDomains);
        this.getTemplateViewModel().biddingType(siteConfig.biddingType);
        this.getTemplateViewModel().adUnitBackgroundDetailsEnabled(siteConfig.adUnitBackgroundDetailsEnabled);
        this.getTemplateViewModel().adUnitLoadingAnimationEnabled(siteConfig.adUnitLoadingAnimationEnabled);
        this.getTemplateViewModel().sentinelEnabled(siteConfig.sentinelEnabled);
    }


    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        this.caller.saveEditSiteConfig();
    }


    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancelEditSiteConfig();
    }
}
