import {vnTemplate} from "./vnTemplate";
import {infoButton_template} from "./infoButton_template";

declare var ko: any;

export class filterSingleGroupSelector_template extends vnTemplate{

    protected getTemplateHtml(): string{

        return `
            <div class="dropdown select-single" data-bind="attr: { id: 'multipleSelector_' + elementIdentifier()}">
                <button type="button" class="dropdown-toggle icon" data-bind="text: selectedOptionText"></button>
                <div data-bind="class: 'dropdown-list '">
                    <div class="w-multi-dropdown">
                        <div class="w-multi-dropdown-header">
                        
                             <div class="multi-dropdown-header">
                                <div class="w-search icon i-search2">
                                    <input class="multi-dropdown-search" type="text" data-bind="textInput: searchValue, event: { keyup: onSearchAction}">
                                </div>
                            </div>
                             
                             <div class="multi-dropdown-header" data-bind="visible: groups().length > 1">
                                 <select data-bind="
                                    options: groups,
                                    optionsText: 'name',
                                    optionsValue: 'id',
                                    value: selectedGroup,
                                    event: { change: onChangeGroupAction }
                                "></select>
                             </div>
                    
                        </div>
                        
                        <div class="multi-dropdown-body">
                            <ul class="multi-dropdown-list" data-bind="attr: { id: 'multipleSelector_content_' + elementIdentifier()}">
                                
                                <!-- ko foreach: options -->
                                <li class="multi-dropdown-item" data-bind="style: { display: (($parent.searchValue() != '' && $parent.searchMatches.indexOf(id) == -1) || ($parent.selectedGroup() !== 0 && $parent.groupMatches.indexOf(id) == -1)) ? 'none' : 'block' }">
                                    <div class="w-input">
                                        <input class="icon" data-bind="value: id, checked: $parent.selectedOption, attr:{id: 'item_' + $parent.elementIdentifier() + '_' + id}, event: { change: $parent.onOptionChange}" type="radio">

                                        <label class="icon i-check" data-bind="attr:{for: 'item_' + $parent.elementIdentifier() + '_' + id}">
                                            <span class="ex-name" data-bind="text: name"></span>
                                            
                                            <!-- ko if: $data.group != undefined -->
                                                <span class="ex-group-name" data-bind="text: group.name"></span>
                                            <!-- /ko -->

                                        </label>

                                    </div>
                                </li>
                                <!-- /ko -->

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            `;
    }

    protected getTemplateName(): string{
        return "filterSingleGroupSelector-template";
    }

    protected buildViewModel(): any{

        // define the viewmodel data/ functions
        let huit_neuf_this = this;
        return  {

            groups: ko.observableArray([{"id" : 0, "name" : "-- all --"}]),
            selectedGroup: ko.observable(0),
            groupMatches: ko.observableArray([]),
            options: ko.observableArray([{"id" : 0, "name" : "all"}]),
            selectedOption: ko.observable(0),
            selectedOptionText: ko.observable("all"),
            elementIdentifier: ko.observable(this.idElement),
            searchMatches: ko.observableArray([]),
            searchValue: ko.observable(""),
            onSearchAction: function () { huit_neuf_this.onSearchAction();},
            onOptionChange : function (data) { huit_neuf_this.onOptionChange(data);return true;},
            onChangeGroupAction: function (data) { huit_neuf_this.onChangeGroupAction();return true;},
        };
    }

    public setOptions(options) {

        let temp = this.onOptionChange;
        this.onOptionChange = function(){};

        this.getTemplateViewModel().options(options);

        this.onOptionChange = temp;
    }

    public setGroups(groups : any) {

        groups.unshift({"id" : 0, "name" : "-- all --"});
        this.getTemplateViewModel().groups(groups);
    }

    public setSelectedOption(selectedOption : any) {

        this.getTemplateViewModel().selectedOption(selectedOption);
    }
    
    
    public setselectedOptionText(text : string){
        this.getTemplateViewModel().selectedOptionText(text);
    }

    protected onOptionChange(item){

        this.getTemplateViewModel().selectedOptionText(item.name);
        this.applyFilter();
    }

    public onChangeGroupAction(){

        let selectedGroup = this.getTemplateViewModel().selectedGroup();
        if (selectedGroup === 0){

            this.getTemplateViewModel().groupMatches([]);
            return;
        }

        let matches = [];
        for (let item of this.getTemplateViewModel().options()){

            if (item.group != undefined && item.group.id == selectedGroup) {

                matches.push(item.id);
            }
        }

        this.getTemplateViewModel().groupMatches(matches);
    }

    protected onSearchAction(){

        let searchValue = this.getTemplateViewModel().searchValue();
        if (searchValue == ''){

            this.getTemplateViewModel().searchMatches([]);
            return;
        }

        let matches = [];
        for (let item of this.getTemplateViewModel().options()){

            if (item.name.search(new RegExp(searchValue, "i")) != -1 && matches.indexOf(item.id) == -1) {

                matches.push(item.id);
            }
        }

        this.getTemplateViewModel().searchMatches(matches);
    }

    private applyFilter(){

        this.caller.filterSingleGroupSelectorOnChange(this.idElement);
    }
}
