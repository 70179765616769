import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class adTemplateEdit_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <!-- ko ifnot: idAdTemplate -->
                <h3 class="form-title icon i-add">Add New Ad Template</h3>
                <!-- /ko -->

                <!-- ko if: idAdTemplate -->
                <h3 class="form-title icon i-ad_template_2">edit Ad Template</h3>
                <!-- /ko -->

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>

            <div class="form-body">
                <div class="form-group row">
                    <label for="name" class="form-label">name</label>
                    <input id="name" data-bind="value: name" type="text" class="input-text">
                </div>
                <div class="form-group row">
                    <label for="additionalClasses" class="form-label">Additional Classes</label>
                    <input id="additionalClasses" data-bind="value: additionalClasses" type="text" class="input-text">
                </div>
                 <div class="form-group row start">
                    <label for="header" class="form-label">header</label>
                    <textarea id="header" data-bind="value: header" class="input-text" style="height:24rem"></textarea>
                 </div>
                 <div class="form-group row start">
                    <label for="content" class="form-label">content</label>
                    <textarea id="content" data-bind="value: content" class="input-text"></textarea>
                 </div>
            </div>   

            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save Ad Template</span></button>  
            </div> 
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "adTemplateEdit-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;

        return {
            idAdTemplate: ko.observable(''),
            name: ko.observable(''),
            additionalClasses: ko.observable(''),
            header: ko.observable(''),
            content: ko.observable(''),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); }
        };

    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            idAdTemplate: this.getTemplateViewModel().idAdTemplate(),
            name: this.getTemplateViewModel().name(),
            additionalClasses: this.getTemplateViewModel().additionalClasses(),
            header: this.getTemplateViewModel().header(),
            content: this.getTemplateViewModel().content()
        };
    }

    public setAdTemplate(adTemplate): void{

        this.getTemplateViewModel().idAdTemplate(adTemplate.id);
        this.getTemplateViewModel().name(adTemplate.name);
        this.getTemplateViewModel().additionalClasses(adTemplate.additionalClasses);
        this.getTemplateViewModel().header(adTemplate.header);
        this.getTemplateViewModel().content(adTemplate.content);

    }


    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        if(!this.getTemplateViewModel().idAdTemplate()) {
            this.caller.save();
        } else {
            this.caller.confirmationModal.setContext('saveModal');
            this.caller.confirmationModal.setTitle('Are you sure?');
            this.caller.confirmationModal.setDataConfirm(null);
            this.caller.confirmationModal.setMessageMain('Updating an in use ad template will update the every associated ad unit. When updating an existing ad template only adding know macros and removing macros are supported. For anything else please create a new ad template instead.');
            this.caller.confirmationModal.setMessageSecondary('This operation may take several minutes are you sure you want to proceed?');
            this.caller.confirmationModal.show();
        }


    }


    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancelEditAdTemplate();
    }
}
