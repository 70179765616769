import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class repeatableAdInjectionContentToIgnore_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <h3 class="form-title icon i-dependencies">Repeatable Ad Injection Content to Ignore</h3>
                <button data-bind="click: onClickCancel" class="close-button icon i-close-2" type="button"></button>
            </div>
            
            <div class="w-form-body btn-only">
                <button data-bind="click: onClickAdd" class="btn icon i-add" type="button">Add new content</button>
            </div>
            
            <div class="w-form-body content-list-2row">
                
                <!-- ko foreach: markers -->
                <div class="form-body"> 
                    <div class="form-group row">
                        <label class="form-label">Opener</label>
                        <input data-bind="value: opener" type="text" class="input-text">
                    </div>
                    <div class="form-group row">
                        <label class="form-label">Closer</label>
                        <input data-bind="value: closer" type="text" class="input-text">
                    </div>
                    <div class="form-group row">
                        <button data-bind="click: $parent.onClickDelete" class="close-button icon i-cancel-thin" type="button">Remove content</button>
                    </div>
                </div>
                <!-- /ko -->  
            </div>
            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return 'repeatableAdInjectionContentToIgnore-template';
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return [
            'w-form',
            'w-form-large',
            'repeatableAdInjectionContentToIgnore',
        ];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let self = this;

        return {
            markers: ko.observableArray([]),
            onClickSave: function(data, event){ self.onClickSave(); },
            onClickCancel: function(data, event){ self.onClickCancel(); },
            onClickAdd: function(data, event){ self.onClickAdd(); },
            onClickDelete: function(data, event){ self.onClickDelete(data); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            data: this.getTemplateViewModel().markers(),
        };
    }

    //
    // Set the form data.
    public setFormData(data): void{

        this.getTemplateViewModel().markers(data || []);
    }

    //
    // Handles clicking save button event.
    protected onClickSave(): void{

        this.caller.save();
    }

    //
    // Handles clicking cancel button event.
    protected onClickCancel(): void{

        this.caller.cancel();
    }

    //
    // Handles clicking add button event.
    protected onClickAdd(): void{

        let newMarker = {
            id: null,
            opener: '',
            closer: '',
        };

        this.getTemplateViewModel().markers.unshift(newMarker);
    }

    //
    // Handles clicking delete button event.
    protected onClickDelete(marker): void{

        this.getTemplateViewModel().markers.remove(marker);
    }
}