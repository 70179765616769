import {vnTemplate} from "./vnTemplate";

declare var ko: any;

export class resultsInfo_template extends vnTemplate{

    protected getTemplateHtml(): string{

        return `
                <span><span data-bind="text: Number(nbr_results()).toLocaleString()"></span> result(s)</span>
                <span><span data-bind="text: Number(nbr_pages()).toLocaleString()"></span> pages</span>
                <span>Executed in <span data-bind="text: execution_time"></span> sec.</span>
            `;
    }

    protected getTemplateName(): string{
        return "resultsInfo-template";
    }

    protected getMainElementType(): string{
        return "caption";
    }

    protected getMainElementClasses(): Array<string>{
        return ["info-results"];
    }


    protected buildViewModel(): any{

        // define the viewmodel data/ functions
        return  { nbr_results: ko.observable("")
            ,nbr_pages: ko.observable("")
            ,execution_time: ko.observable("")
        };
    }

    public setExecutionTime(execTime){

        this.viewModel[this.idElement].execution_time(execTime);
    }

    public setNbResults(nbResults: number){

        this.viewModel[this.idElement].nbr_results(nbResults);
    }

    public setNbPages(nbPages: number){

        this.viewModel[this.idElement].nbr_pages(nbPages);
    }

    public setResults(results){

        this.viewModel[this.idElement].nbr_results(results.nbr_results);
        this.viewModel[this.idElement].nbr_pages(results.nbr_pages);
        this.viewModel[this.idElement].execution_time(results.execution_time);
    }

}
