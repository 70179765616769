import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import {header} from "./header";
import {performanceSummaryList_template} from "../components/templates/performanceSummaryList_template";

declare var ko: any;

export class performanceSummary extends vnModule_knockoutJS{

    protected elementsList: performanceSummaryList_template;
    protected XHRHdl: XMLHttpRequestHandler;

    protected getModuleName(): string{

        return 'performanceSummary';
    }

    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Performance Summary");

        this.elementsList = new performanceSummaryList_template('performanceSummary', this.viewModel, this)

        return [this.elementsList.build()];
    }

    public fillPage(): void{

        this.elementsList.buildDatePickerCalendar();
        this.getFormData();
    }

    protected getFormData(): void{

        this.loadingIn(this.elementsList.builtTemplate.children.namedItem("list-results"));
        this.XHRHdl = new XMLHttpRequestHandler('/api/performanceSummary/references/', [], this);
        this.XHRHdl.onReadyStateFunction = this.onReferencesRequestReturn;
        this.XHRHdl.execute();
    }

    protected onReferencesRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateFilters(responseParsed);
                obj.getListData();
            });
        }
    }

    public getListData(): void{

        if('getListData' in this.XHRHdl){

            this.XHRHdl['getListData'].cancelAndReset();
        }

        this.loadingIn(this.elementsList.builtTemplate.children.namedItem("list-results"));

        let subViewModel = this.elementsList.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(['data', ko.toJSON(subViewModel)]);

        this.XHRHdl['getListData'] = new XMLHttpRequestHandler('/api/performanceSummary/get/', usrReq_params, this);
        this.XHRHdl['getListData'].onReadyStateFunction = this.onDataRequestReturn;
        this.XHRHdl['getListData'].execute();
    }

    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateList(responseParsed);

                obj.loadingOut(obj.elementsList.builtTemplate.children.namedItem("list-results"));
            });
        }
    }

    protected updateList(responseParsed: any): void{

        this.elementsList.setResults(responseParsed.results);
    }

    protected updateFilters(responseParsed: any): void{

        this.elementsList.setBrandsFilterItems(responseParsed.brands);
        this.elementsList.setTemplatesFilterItems(responseParsed.templates);
        this.elementsList.setSourcesFilterItems(responseParsed.sources);
        this.elementsList.setDevicesFilterItems(responseParsed.devices);
    }

    protected filterMultipleSelectorOnChange(idElement){

        this.elementsList.setSelectedBrands();
        this.elementsList.setSelectedTemplates();
        this.elementsList.setSelectedSources();
        this.elementsList.setSelectedDevices();

        // Do not make a call to the backend for the brandsFilter
        if (idElement != 'brandsFilter'){

            this.getListData();
        }
    }

    public filterDropdownOnChange(): void{

    }

    protected filterDateOnChange(){

        this.getListData();
    }

    protected filterSingleDateOnChange(){

        this.getListData();
    }
}