import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import {header} from "./header";
import { globalCss_template } from '../components/templates/globalCss_template';

declare var ko: any;
declare var page: any;

export class globalCss extends vnModule_knockoutJS{

    protected globalCss_template: globalCss_template;
    protected XHRHdl: XMLHttpRequestHandler;

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'globalCss';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Global CSS");

        this.globalCss_template = new globalCss_template('globalCss', this.viewModel, this)

        return [this.globalCss_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getEditData();
    }

    /**
     * Hook.
     *
     * Cancels the edits to a user.
     */
    protected cancelEditGlobalCss(){

        this.loadingIn(this.globalCss_template.builtTemplate);

        // Redirect to configurations page
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "Canceled global css edit");

        page.redirect(`/configurations`);
    }

    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditData(): void{

        this.loadingIn(this.globalCss_template.builtTemplate);

        this.XHRHdl  = new XMLHttpRequestHandler(`/api/global_css/get/`,[], this);
        this.XHRHdl.onReadyStateFunction = this.onEditDataRequestReturn;
        this.XHRHdl.execute();

    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onEditDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditData(responseParsed);
                obj.loadingOut(obj.globalCss_template.builtTemplate);
            });
        }
    }

    /**
     * Updates the form elements.
     */
    protected updateEditData(responseParsed: any){

        console.log(responseParsed);
        if(responseParsed.data !== null) {

            this.globalCss_template.setData(responseParsed.data.globalCss);
        }
    }


    /**
     * Saves the source data.
     */
    protected save(): void{

        this.loadingIn(this.globalCss_template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.globalCss_template.getViewModelForRequest();

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/global_css/save/`, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveReturn;
        XHRHdl.execute();

    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, "Global CSS saved");

                page.redirect(`/configurations`);
            });

            obj.loadingOut(obj.globalCss_template.builtTemplate);
        }
    }

}