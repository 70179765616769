import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import { geoEdit_template } from "../components/templates/geoEdit_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class geo_edit extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected geoEdit_template: geoEdit_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return "geo_edit";
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Geo Edit");

        // create the users form
        this.geoEdit_template = new geoEdit_template("geoEdit_template", this.viewModel, this);

        return [this.geoEdit_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getEditData();
    }

    /**
     * Hook.
     *
     * Saves the edits to a geo.
     */
    protected saveEditGeo(): void{

        this.save();
    }

    /**
     * Hook.
     *
     * Cancels the edits to a user.
     */
    protected cancelEditGeo(){

        this.loadingIn(this.geoEdit_template.builtTemplate);

        this.releaseLock();

        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "Geo action canceled!");

        page.redirect(`/geos`);
    }

    /**
     * Requests a lock release.
     */
    protected releaseLock(): void{

        let formReq_params: Array<Array<string>> = [];

        // Request a lock release.
        this.XHRHdl = new XMLHttpRequestHandler(`/api/geos/${this.getVnAppZone().app.params.id}/release_lock/`, formReq_params, this);
        this.XHRHdl.execute();
    }


    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditData(takeover: boolean = false): void{

        this.loadingIn(this.geoEdit_template.builtTemplate);

        let id = this.getVnAppZone().app.params.id || null;

        this.XHRHdl = new XMLHttpRequestHandler(`/api/geos/get/${id}/`, [['takeover', (takeover) ? '1' : '0']], this);
        this.XHRHdl.onReadyStateFunction = this.onEditDataRequestReturn;
        this.XHRHdl.execute();

    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onEditDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditData(responseParsed);
                obj.loadingOut(obj.geoEdit_template.builtTemplate);
            });
        }
    }

    /**
     * Updates the form elements.
     *
     */
    protected updateEditData(responseParsed: any){

        this.setGeo(responseParsed.data);
    }

    protected setGeo(geo){

        this.geoEdit_template.setGeo(geo);
    }


    /**
     * Saves the source data.
     */
    protected save(): void{

        this.loadingIn(this.geoEdit_template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.geoEdit_template.getViewModelForRequest();

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/geos/save/`, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveReturn;
        XHRHdl.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'geo created successfully!');
                obj.releaseLock();
                page.redirect(`/geos/`);
            });

            obj.loadingOut(obj.geoEdit_template.builtTemplate);
        }
    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.getEditData(true);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }

    //
    // Obtain the dynamic data required to fill the form.
    protected getEditFormData(): void{

        this.loadingIn(this.geoEdit_template.builtTemplate);

        this.XHRHdl = new XMLHttpRequestHandler(`/api/geos/references/`, [], this);
        this.XHRHdl.onReadyStateFunction = this.onEditFormDataRequestReturn;
        this.XHRHdl.execute();
    }

    //
    // Returns handler for edit form request.
    protected onEditFormDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditFormData(responseParsed);
                obj.loadingOut(obj.geoEdit_template.builtTemplate);
                obj.getEditData();
            });
        }
    }

    //
    // Update form data.
    protected updateEditFormData(responseParsed: any): void{

    }
}
