import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class advertisingPartnerEdit_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
        <div class="w-form" style="width:90rem;">
            <div class="form-header">
                <!-- ko ifnot: idAdvertisingPartner -->
                <h3 class="form-title icon i-add">Add New advertising Partner</h3>
                <!-- /ko -->

                <!-- ko if: idAdvertisingPartner -->
                <h3 class="form-title icon i-content">edit advertising Partner</h3>
                <!-- /ko -->

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>

            <div class="form-body">
                <div class="form-group row">
                    <label for="name" class="form-label">name</label>
                    <input id="name" data-bind="value: name" type="text" class="input-text">
                 </div>                
                 <div class="form-group row">
                    <label for="automatic_task_frequency" class="form-label">Task frequency</label>
                    <input id="automatic_task_frequency" data-bind="value: automaticTaskFrequency" type="text" class="input-text">
                 </div>           
                 <div class="form-group row">
                    <label for="idOperation" class="form-label">Task operation</label>
                    <select data-bind="
                            options: taskOperations,
                            optionsText: 'name',
                            optionsValue: 'id',
                            value: idTaskOperation
                        ">
                    </select>
                 </div>
                 <div class="form-group row">
                    <div class="form-check-inline">
                        <input id="isBrandSpecific" data-bind="checked: isBrandSpecific" type="checkbox">
                        <label for="isBrandSpecific" class="form-label icon i-check">Brand Specific ?</label>
                    </div>                 
                </div>                 
                <div class="form-group row" data-bind="visible: !isBrandSpecific()">
                <label for="idOperation" class="form-label">Task executer</label>
                <select data-bind="
                        options: taskExecuters,
                        optionsText: 'name',
                        optionsValue: 'id',
                        value: idTaskExecuter
                    ">
                </select>
                </div>
                <div class="form-group row" data-bind="visible: isBrandSpecific">
                    <label for="taskExecuterSuffix" class="form-label">Task Executer Suffix</label>
                    <input id="taskExecuterSuffix" data-bind="value: taskExecuterSuffix" type="text" class="input-text">
                </div>   
                <div class="form-group">
                    <hr class="form-hr">
                </div>
                <div class="form-group">
                    <label class="form-sub-title">FIELDS</label>
                </div>
                <div data-bind="foreach: fields">
                    <div class="form-group row">
                        <label class="form-label">Field name</label>
                        <input data-bind="value: field_name" type="text" class="input-text">
                    </div>
                </div>
                <div class="form-group">
                    <button data-bind="event: { click: onClickAddField }" class="btn icon i-add" type="button">Add field</button>
                </div>
            </div>   

            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save advertising Partner</span></button>  
            </div>
        </div>
        `;


    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "advertisingPartnerEdit-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{
        let huit_neuf_this = this;

        return {
            idAdvertisingPartner: ko.observable(''),
            name: ko.observable(''),
            automaticTaskFrequency: ko.observable(''),
            idTaskOperation: ko.observable(''),
            isBrandSpecific: ko.observable(''),
            idTaskExecuter: ko.observable(''),
            taskExecuterSuffix: ko.observable(''),
            taskOperations: ko.observableArray([]),
            taskExecuters: ko.observableArray([]),
            fields: ko.observableArray([]),
            onClickAddField: function(data, event){ huit_neuf_this.onClickAddField(); },
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        let viewModel = this.getTemplateViewModel()
        return {
            idAdvertisingPartner: viewModel.idAdvertisingPartner(),
            idTaskOperation: viewModel.idTaskOperation(),
            idTaskExecuter: viewModel.isBrandSpecific() ? null : viewModel.idTaskExecuter(),
            taskExecuterSuffix: viewModel.isBrandSpecific() ? viewModel.taskExecuterSuffix() : '',
            name: viewModel.name(),
            isBrandSpecific: viewModel.isBrandSpecific(),
            automaticTaskFrequency: viewModel.automaticTaskFrequency(),
            fields : viewModel.fields(),
        };
    }

    public setAdvertisingPartner(advertisingPartner): void{
        this.getTemplateViewModel().idAdvertisingPartner(advertisingPartner.id);
        this.getTemplateViewModel().name(advertisingPartner.name);
        this.getTemplateViewModel().automaticTaskFrequency(advertisingPartner.automaticTaskFrequency);
        this.getTemplateViewModel().idTaskOperation(advertisingPartner.idTaskOperation);
        this.getTemplateViewModel().idTaskExecuter(advertisingPartner.idTaskExecuter);
        this.getTemplateViewModel().taskExecuterSuffix(advertisingPartner.taskExecuterSuffix);
        this.getTemplateViewModel().isBrandSpecific(advertisingPartner.isBrandSpecific);
        
        let fields = [];
        if (advertisingPartner.fields != null)  {
            advertisingPartner.fields.forEach((field) => {
                fields.push({field_name: field.field_name});
            })
        }
        this.getTemplateViewModel().fields(fields);

    }

    public setTaskOperations(taskOperations): void{
        taskOperations.unshift({id: null, name: ''});
        this.getTemplateViewModel().taskOperations(taskOperations);
    }    
    public setTaskExcecuters(taskExecuters): void{
        taskExecuters.unshift({id: null, name: ''});
        this.getTemplateViewModel().taskExecuters(taskExecuters);
    }    


    /**
     * Handles clicking the add field button event.
     */
    protected onClickAddField(): void{
        this.getTemplateViewModel().fields.push({field_name: ''});
    }        
    
    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{
        this.caller.saveEditadvertisingPartner();
    }


    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{
        this.caller.cancelEditadvertisingPartner();
    }
}
