import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";
import {pagination_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/pagination_template";
import {filterSimple_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterSimple_template";
import {multipleSelector_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/multipleSelector_template";
import {resultsInfo_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/resultsInfo_template";
import {vnApp_ads_ninja} from "../vnApp_ads_ninja";

declare var ko: any;

export class usersList_template extends vnTemplate{

    protected filter: filterSimple_template;
    protected pagination: pagination_template;
    protected filterRole: multipleSelector_template;
    protected resultInfos: resultsInfo_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="sub-header">
                <div class="w-justify">
                    <div class="w-left">
                        <div>
                            <label class="form-label form-label-box multi">Roles</label>
                            ${this.filterRole.getHtmlBuild()}
                        </div>   
                        <div class="form-check-inline">                  
                            <input type="checkbox" data-bind="checked: isShowActiveUsers, click: showActiveUsers" id="showActiveUser">
                            <label for="showActiveUser" class="form-label icon i-check">Show Active Users</label>   
                        </div>                    
                    </div>
                </div>
            </div>        
            <div class="w-filters-selected">
                <div id="filters-selected" class="filters-selected">
                    <div id="selected-userRole"></div>
                </div>
            </div>
            
            <div class="list-header w-justify" >
                <div class="w-left" data-bind="if: hasWriteAccess">
                    <button data-bind="click: onClickAdd" class="btn icon i-add" type="button">Add user</button>
                </div>
                <div class="w-center">
                    ${this.filter.getHtmlBuild()}
                </div>
                <div class="w-right">
                    ${this.pagination.getHtmlBuild()}
                </div>
            </div>
            
            <div class="w-list-results" id="list-results">
                <table class="list-results">
                    ${this.resultInfos.getHtmlBuild()}
                    <thead>
                        <tr>
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'fullName') }, css: { 'list-sort-desc': orderByAndDirection()=='fullName_DESC', 'list-sort-asc': orderByAndDirection()=='fullName_ASC' }">Name</span></th>
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'loginName') }, css: { 'list-sort-desc': orderByAndDirection()=='loginName_DESC', 'list-sort-asc': orderByAndDirection()=='loginName_ASC' }">User</span></th>
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'email') }, css: { 'list-sort-desc': orderByAndDirection()=='email_DESC', 'list-sort-asc': orderByAndDirection()=='email_ASC' }">Email</span></th>
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'dateCreation') }, css: { 'list-sort-desc': orderByAndDirection()=='dateCreation_DESC', 'list-sort-asc': orderByAndDirection()=='dateCreation_ASC' }">Created On</span></th>
                        </tr>
                    </thead>
                    <tbody data-bind="foreach: users">
                        <tr class="cursor">
                            <td data-bind="event: { click: $parent.onClickView }" class="flex td-img">
                                <div class="w-user-img"><img class="user-img" data-bind="attr:{src: pictureUrl}" alt=""></div>
                                <span data-bind="text: fullName"></span>
                            </td>
                            <td data-bind="event: { click: $parent.onClickView }"><span data-bind="text: loginName"></span></td>
                            <td data-bind="event: { click: $parent.onClickView }"><span data-bind="text: email"></span></td>
                            <td data-bind="event: { click: $parent.onClickView }"><span data-bind="text: dateCreation"></span></td>
                        </tr>
                    </tbody>
                </table>
            </div>    
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{

        this.filter      = new filterSimple_template("filter", this.getTemplateViewModel(), this.caller);
        this.resultInfos = new resultsInfo_template("resultInfo", this.getTemplateViewModel(), this.caller);

        this.pagination = new pagination_template("pagination", this.getTemplateViewModel(), this.caller);
        this.pagination.getTemplateViewModel().itemPerPage = 25;

        this.filterRole = new multipleSelector_template("filterRole", this.getTemplateViewModel(), this.caller);
        this.filterRole.setFilterContainerClass('margin-neg');
        this.filterRole.setTagContainerId('selected-userRole');
        this.filterRole.setTagContainerCaption('Selected User Role:');
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "usersList-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementType(): string{

        return "div";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-content"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        // Define the viewmodel data/ functions.
        let huit_neuf_this = this;

        return{
            orderBy: ko.observable("fullName"),
            orderByDirection: ko.observable("ASC"),
            users: ko.observableArray([]),
            orderByAndDirection: ko.observable("fullName_ASC"),
            hasWriteAccess: ko.observable(this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_USER_OTHERS, vnApp_ads_ninja.ACCESS_TYPE_WRITE)),
            isShowActiveUsers: ko.observable(true),
            onClickReorder: function(value, data, event){ huit_neuf_this.onClickReorder(value, data, event); },
            onClickView: function(data, event){ huit_neuf_this.onClickView(data.id); },
            onClickAdd: function(){ huit_neuf_this.onClickAdd(); },
            showActiveUsers: function(data){ huit_neuf_this.showActiveUsers(); return true; },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(){

        return{
            orderBy: this.getTemplateViewModel().orderBy,
            orderByDirection: this.getTemplateViewModel().orderByDirection,
            orderByAndDirection: this.getTemplateViewModel().orderByAndDirection,
            pagination: this.getTemplateViewModel().pagination,
            filterRole: this.getTemplateViewModel().filterRole,
            filters: {
                keywords: this.getTemplateViewModel().filter.filterKeyword,
                roles: this.getTemplateViewModel().filterRole.selectedItems,
                isActive: this.getTemplateViewModel().isShowActiveUsers,
            },
        }
    }

    /**
     * Handles Reordering table.
     *
     * @param value
     * @param data
     * @param event
     */
    public onClickReorder(value, data, event){

        data.orderBy(value);
        //if column has already been sorted, flip the direction
        if (data.orderByAndDirection().includes(value)){

            if (data.orderByDirection() == "DESC"){

                data.orderByDirection("ASC");
            }else{

                data.orderByDirection("DESC");
            }
        }else{

            //default column sort direction
            data.orderByDirection("DESC");
        }

        data.orderByAndDirection(value + "_" + data.orderByDirection());
        this.caller.dataReorder();
    }

    /**
     * Handles the click data row event.
     *
     * @param id
     */
    public onClickView(id){

        this.caller.viewUserProfile(id);
    }

    /**
     * Handles the add user action.
     */
    public onClickAdd(){

        this.caller.addUser();
    }

    /**
     * Sets users.
     *
     * @param users
     */
    public setUsers(users: Array<{ pictureUrl: string, fullName: string, loginName: string, email: string, dateCreation: string }>): void{

        this.getTemplateViewModel().users(users);
    }

    /**
     * Handles change to show active users checkbox.
     */
    public showActiveUsers(){

        this.caller.onShowActiveUsers();
        return true;
    }

    /**
     * Sets data for the result info template.
     *
     * @param nbResults
     * @param nbPages
     * @param execTime
     */
    public setResultInfo(nbResults, nbPages, execTime){

        this.resultInfos.setExecutionTime(execTime);
        this.resultInfos.setNbResults(nbResults);
        this.resultInfos.setNbPages(nbPages);
    }

    /**
     * Sets the post types.
     *
     * @param {Array<{ id: number, name: string }>} roles
     */
    public setRoles(roles: Array<{ id: string, name: string }>): void{

        this.filterRole.setItems(roles);
    }
}
