import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import {appHeader_template} from "../components/templates/appHeader_template";
import {vnApp_ads_ninja} from "../components/vnApp_ads_ninja";

declare var page: any;
declare var ko: any;
export class header extends vnModule_knockoutJS{

    protected getModuleName(){
        return "header";
    }

    public appHeader: appHeader_template;
    protected XHRHdl: XMLHttpRequestHandler;

    buildContent() : Array<Element>{

        let vnApp = this.getVnAppZone().app;

        //
        // Only if were logged in
        if(vnApp.loggedUser != null && vnApp.loggedUser.isLoggedIn) {

            this.appHeader = new appHeader_template("mainHeader", this.viewModel, this);

            vnApp.loggedUser.user.valnet_user.pictureUrl = "/usersImages/" + vnApp.loggedUser.user.valnet_user.pictureUrl;

            console.log(vnApp.loggedUser);

            this.appHeader.setUser(vnApp.loggedUser);

            // set picture
            if( vnApp.loggedUser.user.valnet_user.pictureUrl ){
                this.appHeader.setUserImageSrc("/usersImages/" + vnApp.loggedUser.user.pictureUrl);
            }
            else{
                this.appHeader.setUserImageSrc("/assets/images/default-user.png");
            }

            // set full name
            this.appHeader.setUserFullName(vnApp.loggedUser.user.valnet_user.firstName +' '+ vnApp.loggedUser.user.lastName);

            // set profile page link
            this.appHeader.setProfilePageLink("/users/edit/"+ vnApp.loggedUser.user.id);

            let isUsersVisible = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_USER_OTHERS, vnApp_ads_ninja.ACCESS_TYPE_READ);
            let isLogsVisible  = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_LOG, vnApp_ads_ninja.ACCESS_TYPE_READ);
            let isRolesVisible = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_ROLE, vnApp_ads_ninja.ACCESS_TYPE_READ);
            let isTasksVisible = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_TASK, vnApp_ads_ninja.ACCESS_TYPE_READ);

            this.appHeader.setIsUsersVisible(isUsersVisible);
            this.appHeader.setIsLogsVisible(isLogsVisible);
            this.appHeader.setIsRolesVisible(isRolesVisible);
            this.appHeader.getTemplateViewModel().isTasksVisible(isTasksVisible);
            this.appHeader.getTemplateViewModel().isSettingsVisible((isUsersVisible || isLogsVisible || isRolesVisible || isTasksVisible));

            return [this.appHeader.build()]
        }
        else{
            return [document.createElement("div")];
        }
    }

    public fillPage() : void{

    }

    public logOut(){
        let _mythis = this;

        // loading the page if the user is logged in
        let loginReq_url: string = '/api/access/logout/';
        let loginReq_Params: Array<Array<string>> = [];
        let XHRHdl: XMLHttpRequestHandler = new XMLHttpRequestHandler(loginReq_url,loginReq_Params, this);
        XHRHdl.onReadyStateFunction = (function(req, obj){

            return function(){

                if (req.request.readyState == 4) {

                    if(  req.request.status == 200  ){
                        page.redirect('/');
                    }
                }
            }
        });
        XHRHdl.execute();
    }

    public setHeaderTitle(title: string){
        this.appHeader.setHeaderTitle(title);
    }

    public setBrandClass(brandClass: string){
        this.appHeader.setBrandClass(brandClass);
    }

    public setBrandName(brandName: string){
        this.appHeader.setBrandName(brandName);
    }

    protected viewProfile(idUser) {

        page(`/users/profile/${idUser}`);
    }
}
