import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class templateTypeEdit_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <!-- ko ifnot: id -->
                <h3 class="form-title icon i-add">Add New Template Type</h3>
                <!-- /ko -->

                <!-- ko if: id -->
                <h3 class="form-title icon i-template_types">Edit Template Type</h3>
                <!-- /ko -->

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>

            <div class="form-body">
                <div class="form-group row">
                    <label for="name" class="form-label">name</label>
                    <input id="name" data-bind="value: name" type="text" class="input-text">
                 </div>
            </div>   
          
            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save Template Type</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "templateTypeEdit-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;

        return {
            id: ko.observable(''),
            name: ko.observable(''),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); }
        };

    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            id: this.getTemplateViewModel().id(),
            name: this.getTemplateViewModel().name()
        };
    }


    public setFormData(data): void{

        this.getTemplateViewModel().id(data.id);
        this.getTemplateViewModel().name(data.name);
    }

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        this.caller.saveEditTemplate();
    }

    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancelEditTemplate();
    }
}
