import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { home_template } from "../components/templates/home_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class home extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected home: home_template;

    protected getModuleName(){

        return "home";
    }

    public buildContent(): Array<Element>{

        this.home = new home_template( "homePage", this.viewModel, this );
        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Home");

        return [ this.home.build()];
    }

    public fillPage(): void{

    }
}
