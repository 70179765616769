import { vn_app_zone_notification } from "./vn_app_zone_notification";
import { vnModule_knockoutJS } from "./vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../XMLHttpRequestHandler/XMLHttpRequestHandler";
import { rolesEdit_template } from "../vnKnockoutJsTemplates/rolesEdit_template";
import {XMLHttpRequestHandler_requestType} from "../XMLHttpRequestHandler/XMLHttpRequestHandler"
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class roles_edit extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected rolesEditTemplate: rolesEdit_template;
    protected _isLeavePageWarningNeeded: boolean = true;

    protected getModuleName(){
        return "roles_edit";
    }

    buildContent() : Array<Element> {

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");

        if(zoneHeader){

            let loadedModule = zoneHeader.loadedModule;
            (<header>loadedModule).setHeaderTitle("Role Edit");
        }

        this.rolesEditTemplate = new rolesEdit_template("rolesEdit_template", this.viewModel, this);
        return [this.rolesEditTemplate.build()];
    }
    //
    // fill the page at first load.
    fillPage(): void {
        this.getFormData();
    }

    protected saveEditRole() {
        this.save();
    }

    public cancelForm() {
        this.cancelEditRole();
    }

    protected cancelEditRole(){

        // redirect to earnings page or user list
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "Role action canceled !");

        page.redirect('/roles');
    }
    // Getting role data from server.
    public getFormData(takeover: boolean = false): void {

        this.loadingIn(this.rolesEditTemplate.builtTemplate.children.namedItem("view-results"));
        let id = this.getVnAppZone().app.params.id;

        if(!isNaN(id) && this.getVnAppZone().app.params.id != 'undefined') {
            this.rolesEditTemplate.getTemplateViewModel().idRole(id);
        } else {
            id = 0;
        }
        console.log(id);

        this.XHRHdl = new XMLHttpRequestHandler(`/api/roles/get/${id}/`, [['takeover', (takeover) ? '1' : '0'], ['mode', 'edit']], this);
        this.XHRHdl.onReadyStateFunction = this.onGetRoleDataReturn;
        this.XHRHdl.execute();
    }

    // Handling response data.
    protected onGetRoleDataReturn(req, obj): Function {

        return function () {
            obj.manageResponse(req, obj, function () {
                let responseParsed = JSON.parse(req.request.response);
                obj.viewRole(responseParsed);
                obj.loadingOut(obj.rolesEditTemplate.builtTemplate.children.namedItem("view-results"));
            });
        }
    }

    public viewRole(role: any) : void {
        this.rolesEditTemplate.setRole(role);
    }

    protected save(): void {


        this.loadingIn(this.rolesEditTemplate.builtTemplate.children.namedItem("view-results"));
        let formReq_url: string = '/api/roles/save/';
        let formReq_params: Array<Array<string>> = [];

        let subViewModel = JSON.parse(ko.toJSON(this.rolesEditTemplate.getRole()));

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(formReq_url,formReq_params, this);

        XHRHdl.mode = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction = this.onPostRoleData;
        XHRHdl.execute();
    }

    private onPostRoleData(req, obj): Function {
        return function() {

            obj.manageResponse(req, obj, function () {
                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, "Role saved successfully!");

                obj.loadingOut(obj.rolesEditTemplate.builtTemplate.children.namedItem("view-results"));
                page.redirect('/roles');

            });

            if(req.request.status != 200){

                obj.unprepareAdsSetupForBackend(obj.adUnits_template.getTemplateViewModel().role());
            }
        }
    }

    //
    // Fix sections for further editing.
    private unprepareAdsSetupForBackend(role){

        for(let section of role.sections){
            
            this.rolesEditTemplate.addInfoButton(section, section['id'], section['section']['description'] || '');
        }
    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.getFormData(true);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }
}
