import { vnApp } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app";

export class vnApp_ads_ninja extends vnApp{

	// Roles IDs.
	public static readonly ROLE_ID_ADMINISTRATOR  = 1;
	public static readonly ROLE_ID_AD_OPERATIONS  = 2;
	public static readonly ROLE_ID_REPORT_ANALYST = 3;
	public static readonly ROLE_ID_API 		      = 4;

	// Sections IDs.
	public static readonly SECTION_ID_TAKEOVER                    				= 1;
	public static readonly SECTION_ID_USER                        				= 2;
	public static readonly SECTION_ID_ROLE                        				= 3;
	public static readonly SECTION_ID_LOG                         				= 4;
	public static readonly SECTION_ID_TASK                        				= 5;
	public static readonly SECTION_ID_AD_SETUP                    				= 6;
	public static readonly SECTION_ID_AD_UNIT                     				= 7;
	public static readonly SECTION_ID_AUTHORIZED_DIGITAL_SELLERS  				= 8;
	public static readonly SECTION_ID_SSP_CONFIG                  				= 9;
	public static readonly SECTION_ID_TEMPLATE_TYPE_DEPENDENCY    				= 10;
	public static readonly SECTION_ID_ZONE                        				= 11;
	public static readonly SECTION_ID_AD_TEMPLATE                 				= 12;
	public static readonly SECTION_ID_AD_TYPE                     				= 13;
	public static readonly SECTION_ID_BIDDER_TYPE                 				= 14;
	public static readonly SECTION_ID_CONTENT_TYPE                				= 15;
	public static readonly SECTION_ID_GEO                         				= 16;
	public static readonly SECTION_ID_GEO_GROUP              			        = 17;
	public static readonly SECTION_ID_NETWORK_CATEGORY        				    = 18;
	public static readonly SECTION_ID_SOURCE                 				    = 19;
	public static readonly SECTION_ID_SSP                    				    = 20;
	public static readonly SECTION_ID_SSP_GROUP              				    = 21;
	public static readonly SECTION_ID_TEMPLATE                				    = 22;
	public static readonly SECTION_ID_TEMPLATE_TYPE          				    = 23;
	public static readonly SECTION_ID_DATA_IMPORT             				    = 24;
	public static readonly SECTION_ID_AD_HEALTH              				    = 25;
	public static readonly SECTION_ID_SITE_CONFIG             				    = 26;
	public static readonly SECTION_ID_USER_OTHERS             				    = 27;
	public static readonly SECTION_ID_USER_EDIT_ROLES         				    = 28;
	public static readonly SECTION_ID_USER_EDIT_BRANDS            				= 29;
	public static readonly SECTION_ID_USER_EDIT_ROLES_ADMIN       				= 30;
	public static readonly SECTION_ID_PERFORMANCE_SUMMARY         				= 31;
	public static readonly SECTION_ID_PREBID_SSP_SETUP_ADD 					    = 32;
	public static readonly SECTION_ID_GLOBAL_CSS				 				= 35;
	public static readonly SECTION_ID_PREBID_CONFIGURATOR        				= 36;
	public static readonly SECTION_ID_REPEATABLE_AD_INJECTION_CONTENT_TO_IGNORE = 37;
	public static readonly SECTION_ID_BIDDER_TYPE_LIBRARY                       = 38;
	public static readonly SECTION_ID_ADS_SETUP_TAG                             = 39;
	public static readonly SECTION_ID_REAL_TIME_STATS                           = 40;
	public static readonly SECTION_ID_ARTICLE_LEVEL_SV                          = 41;
	public static readonly SECTION_ID_USER_EDIT_ACTIVE                          = 42;
	public static readonly SECTION_ID_MENU_SHOW_BRANDS	                        = 43;
	public static readonly SECTION_ID_USER_OTHERS_ALL	                        = 44;
	public static readonly SECTION_ID_BRANDS_ALL		                        = 45;
	public static readonly SECTION_ID_AD_DISPLAY_TYPE		                    = 46;
	public static readonly SECTION_ID_AD_REVENUE                        		= 47;
	public static readonly SECTION_ID_ADVERTISING_PARTNER             			= 48;
	public static readonly SECTION_ID_ADVERTISING_PARTNER_BY_BRAND              = 49;
	public static readonly SECTION_ID_ADS_BEHAVIOUR                             = 50;
	public static readonly SECTION_ID_REVENUE_SHEET                             = 51;
	public static readonly SECTION_ID_GAM_ACCOUNT                               = 52;
	public static readonly SECTION_ID_SPONSORED_REVENUE							= 53;
	public static readonly SECTION_ID_CONNECTION_CONFIG							= 55;
	public static readonly SECTION_ID_AB_TEST_SCHEDULE							= 56;
	public static readonly SECTION_ID_VIDEO_PREBID_FLOOR					    = 57;

	public getIsLoggedInHttpCallUrl(){

		return '/api/access/isLoggedIn/';
	}
}
