import {vnTemplate} from "./vnTemplate";

declare var ko: any;
declare var dhtmlXCalendarObject: any;
type validMinutesInterval = 1|5|10|15;

export class filterSingleDate_template extends vnTemplate{

    public calendar;

    protected minutesInterval: validMinutesInterval = 5;

    protected getTemplateHtml(): string{

        return `
                <div class="w-date-picker single">
                    <div class="date-picker icon i-calendar">
                        <input type="text" readonly data-bind="value: singleDatePicker, disable: isPickerDisabled, attr: { id: idPicker, name: idPicker}">
                    </div>
                    <div data-bind="attr: { id: idPicker}"></div>
                    
                    <button type="button" title="Clear Dates" class="clear icon i-close" data-bind="event: { click: onClickClearDatePicker }, visible: showClearDatesButton(), css: { 'is-activated' : (singleDatePicker()) }"><span>Clear Date</span></button>
                </div>
            `;
    }

    protected getTemplateName(): string{
        return "filterSingleDate_template";
    }

    protected getMainElementClasses(): Array<string>{
        return [];
    }

    protected buildViewModel(): any{

        // define the viewmodel data/ functions
        let currentDate = new Date();
        let huit_neuf_this = this;

        return  {
             singleDatePicker: ko.observable(this.getDateString(currentDate))
            ,isPickerDisabled: ko.observable(false)
            ,idPicker: ko.observable("datePicker" + this.idElement)
            ,showClearDatesButton: ko.observable(false)
            ,onClickClearDatePicker: function(data, event) { huit_neuf_this.onClickClearDatePicker(); }

        };
    }

    protected onChangeFilterAction(data){

       this.applyFilter();
    }

    protected applyFilter(){
        this.caller.filterSingleDateOnChange(this.idElement);
    }

    public setMinutesInterval(minutes: validMinutesInterval) {
        this.minutesInterval = minutes;
    }

    public buildCalendar(calendarDate? : Date){

        let idPicker = this.getTemplateViewModel().idPicker();
        const currentDate = new Date();

        this.calendar = new dhtmlXCalendarObject([idPicker]);
        this.calendar.setDateFormat("%Y-%m-%d %H:%i");

        const initialDate = (calendarDate) 
         ? new Date(calendarDate.getFullYear(), calendarDate.getMonth(), calendarDate.getDate(), calendarDate.getHours(), calendarDate.getMinutes())
         : new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes() + 1);

        this.getTemplateViewModel().singleDatePicker(this.getDateString(initialDate));

        this.calendar.setDate(initialDate);
        this.calendar.setMinutesInterval(this.minutesInterval);

        let _this = this;

        this.calendar.attachEvent("onClick", function (date) {
            _this.getTemplateViewModel().singleDatePicker(_this.getDateString(date));

        });

        this.calendar.attachEvent("onTimeChange", function (date) {
            _this.getTemplateViewModel().singleDatePicker(_this.getDateString(date));

        });

    }

    public buildCalendarWithoutTime(){

        let idPicker = this.getTemplateViewModel().idPicker();

        this.calendar = new dhtmlXCalendarObject([idPicker]);
        this.calendar.hideTime();

        this.calendar.setDateFormat("%Y-%m-%d");

        let selectedDateString = this.getTemplateViewModel().singleDatePicker();
        selectedDateString = selectedDateString.replaceAll('/', '-');

        let currentDateString = selectedDateString.substring(0,10) + "T00:00:00";
        let currentDate = new Date(currentDateString);

        this.getTemplateViewModel().singleDatePicker(this.getSingleDateString(currentDate));

        this.calendar.setDate(currentDate);

        let _this = this;

        this.calendar.attachEvent("onClick", function (date) {

            _this.getTemplateViewModel().singleDatePicker(_this.getSingleDateString(date));

            _this.applyFilter();
        });
    }

    // yyyy/mm/dd is the expected format
    public setDate( date: string ){

        this.getTemplateViewModel().singleDatePicker(date);
    }

    public setIsPickerDisabled(isPickerDisabled){
        this.getTemplateViewModel().isPickerDisabled(isPickerDisabled);
    }


    private getDateString(date: Date)
    {
        let year = date.getFullYear();
        let month = date.getMonth()+1;
        let day = date.getDate();
        let hour = date.getHours();
        let minutes = date.getMinutes();

        return year + '/' + this.padInteger(month,2) + '/' + this.padInteger(day,2) + ' ' + this.padInteger(hour,2) + ':' + this.padInteger(minutes,2);
    }

    private getSingleDateString(date: Date)
    {
        let year = date.getFullYear();
        let month = date.getMonth()+1;
        let day = date.getDate();

        return year + '/' + this.padInteger(month,2) + '/' + this.padInteger(day,2);
    }

    private padInteger(number: number, size: number)
    {
        let s = number+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    public setPickerId(idPicker){
        this.getTemplateViewModel().idPicker(idPicker);
    }

    public onClickClearDatePicker() {

        this.getTemplateViewModel().singleDatePicker(null);
    }

    public toggleClearButton(value){
        this.getTemplateViewModel().showClearDatesButton(value);
    }


}



