import {vnTemplate} from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate';
import {filterDropdown_template} from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDropdown_template';
import {multipleSelector_template} from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/multipleSelector_template';
import {exportDataCsv} from "../../../vendor/valnet/valnet_jslib/vnHelpers/exportDataCsv";
import {filterDate_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDate_template";
import {simpleListHeader_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/simpleListHeader_template";

declare var ko: any;

export class adHealthList_template extends vnTemplate {

    // Dimensions
    protected dimensionFilter: filterDropdown_template;

    // Filters
    protected brandsFilter: multipleSelector_template;
    protected templatesFilter: multipleSelector_template;
    protected sourcesFilter: multipleSelector_template;
    protected devicesFilter: multipleSelector_template;
    protected operatingSystemsFilter: multipleSelector_template;
    protected geosFilter: multipleSelector_template;
    protected datesFilter: filterDate_template;
    protected headers: simpleListHeader_template;

    // Selectable Metrics
    protected metricsFilter: multipleSelector_template;

    // CSV
    protected exportAdHealthDataCsv: exportDataCsv;

    protected headersArrayForFields = [];

    protected getTemplateHtml(): string{

        return `

            <div class="sub-header header-model-report-page"  id="sub-filters">
                <div class="w-justify margin-bottom">
                    <div class="w-left">
                        <div>
                            <label class="form-label">Dimensions</label>     
                            ` + this.dimensionFilter.getHtmlBuild() + `      
                        </div>
                        <div class="multi">
                            <label class="form-label form-label-box multi"></label> 
                            ` + this.brandsFilter.getHtmlBuild() + `
                        </div>
                        <div>
                            <label class="form-label form-label-box multi"></label> 
                            ` + this.templatesFilter.getHtmlBuild() + `
                        </div>
                        <div>
                            <label class="form-label form-label-box multi"></label> 
                            ` + this.sourcesFilter.getHtmlBuild() + `
                        </div>
                        <div>
                            <label class="form-label form-label-box multi"></label> 
                            ` + this.devicesFilter.getHtmlBuild() + `
                        </div>
                        <div>
                            <label class="form-label form-label-box multi"></label> 
                            ` + this.operatingSystemsFilter.getHtmlBuild() + `
                        </div>
                        <div>
                            <label class="form-label form-label-box multi"></label> 
                            ` + this.geosFilter.getHtmlBuild() + `
                        </div>
                        ` + this.datesFilter.getHtmlBuild() + `
                        <div>
                            <label class="form-label form-label-box multi"></label> 
                            ` + this.metricsFilter.getHtmlBuild() + `
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="w-filters-selected">
                <div id="filters-selected" class="filters-selected">
                    <div id="selected-brands"></div>
                    <div id="selected-templates"></div>
                    <div id="selected-sources"></div>
                    <div id="selected-devices"></div>
                    <div id="selected-operatingSystems"></div>
                    <div id="selected-geos"></div>
                    <div id="selected-metrics"></div>
                </div>
            </div>
            
            <div class="list-header w-justify">
                <div class="w-left">
                    <h2 class="section-title icon i-health">Ad Health</h2>
                </div>
                <div class="w-right">
                    <button class="btn icon highlight i-export" type="button" data-bind="event: { click: exportData}, css: { 'is-hidden': exportButtonHidden }">Export</button>
                </div>
            </div>

            <div class="w-list-results"  id="list-results">
                <table class="list-results ad-health">
                    <thead class="list-header-section">
                        ${this.headers.getHtmlBuild()}
                    </thead>
                    <tbody>
                        <!-- ko foreach: results -->
                        <tr>
                            <td colspan="1"><span class="no-wrap" data-bind="text: date"></span></td>
                            <!-- ko foreach: groups -->
                            <!-- ko if: (typeof metrics.impressions !== 'undefined') -->
                            <td colspan="1"><span class="align-right" data-bind="text: Number(metrics.impressions).toLocaleString('en-EG')"></span></td>
                            
                            <!-- /ko -->
                            <!-- ko if: (typeof metrics.requests !== 'undefined') -->
                            <td colspan="1"><span class="align-right" data-bind="text: Number(metrics.requests).toLocaleString('en-EG')"></span></td>
                            <!-- /ko -->
                            <!-- ko if: (typeof metrics.pageRPM !== 'undefined') -->
                            <td colspan="1"><span class="align-right" data-bind="text: Number(metrics.pageRPM).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})"></span></td>
                            <!-- /ko -->
                            <!-- ko if: (typeof metrics.requestRPM !== 'undefined') -->
                            <td colspan="1"><span class="align-right" data-bind="text: Number(metrics.requestRPM).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})"></span></td>
                            <!-- /ko -->
                            <!-- ko if: (typeof metrics.pageviews !== 'undefined') -->
                            <td colspan="1"><span class="align-right" data-bind="text: Number(metrics.pageviews).toLocaleString('en-EG')"></span></td>
                            <!-- /ko -->
                            <!-- ko if: (typeof metrics.adsPerPage !== 'undefined') -->
                            <td colspan="1"><span class="align-right" data-bind="text: Number(metrics.adsPerPage).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})"></span></td>
                            <!-- /ko -->
                            <!-- ko if: (typeof metrics.requestPerPage !== 'undefined') -->
                            <td colspan="1"><span class="align-right" data-bind="text: Number(metrics.requestPerPage).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})"></span></td>
                            <!-- /ko -->
                            <!-- ko if: (typeof metrics.CPM !== 'undefined') -->
                            <td colspan="1"><span class="align-right" data-bind="text: Number(metrics.CPM).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})"></span></td>
                            <!-- /ko -->
                            <!-- ko if: (typeof metrics.fillRates !== 'undefined') -->
                            <td colspan="1"><span class="align-right" data-bind="text: Number(metrics.fillRates).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'"></span></td>
                            <!-- /ko -->
                            <!-- ko if: (typeof metrics.viewability !== 'undefined') -->
                            <td colspan="1"><span class="align-right" data-bind="text: Number(metrics.viewability).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'"></span></td>
                            <!-- /ko -->
                            <!-- ko if: (typeof metrics.tier1Geos !== 'undefined') -->
                            <td colspan="1"><span class="align-right" data-bind="text: Number(metrics.tier1Geos).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'"></span></td>
                            <!-- /ko -->
                            <!-- ko if: (typeof metrics.tier2Geos !== 'undefined') -->
                            <td colspan="1"><span class="align-right" data-bind="text: Number(metrics.tier2Geos).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'"></span></td>
                            <!-- /ko -->
                            <!-- ko if: (typeof metrics.CTR !== 'undefined') -->
                            <td colspan="1"><span class="align-right" data-bind="text: Number(metrics.CTR).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'"></span></td>
                            <!-- /ko -->
                            <!-- /ko -->
                        </tr>
                        <!-- /ko -->
                    </tbody>
                    <tfoot>
                    </tfoot>
                </table>
            </div>
        `;
    }

    protected initPartial(): void{

        this.headers = new simpleListHeader_template("listHeader", this.getTemplateViewModel(), this.caller, []);

        this.dimensionFilter = new filterDropdown_template('dimensionFilter', this.getTemplateViewModel(), this.caller);
        this.dimensionFilter.setSelectedOption('none');

        this.brandsFilter = new multipleSelector_template('brandsFilter', this.getTemplateViewModel(), this.caller);
        this.brandsFilter.setLabel("brands");
        this.brandsFilter.setFilterContainerClass('margin-neg');
        this.brandsFilter.setTagContainerId('selected-brands');
        this.brandsFilter.setTagContainerCaption('Selected Brand:');

        this.templatesFilter = new multipleSelector_template('templatesFilter', this.getTemplateViewModel(), this.caller);
        this.templatesFilter.setLabel('templates');
        this.templatesFilter.setFilterContainerClass('margin-neg');
        this.templatesFilter.setTagContainerId('selected-templates');
        this.templatesFilter.setTagContainerCaption('Selected Templates');

        this.sourcesFilter = new multipleSelector_template('sourcesFilter', this.getTemplateViewModel(), this.caller);
        this.sourcesFilter.setLabel('sources');
        this.sourcesFilter.setFilterContainerClass('margin-neg');
        this.sourcesFilter.setTagContainerId('selected-sources');
        this.sourcesFilter.setTagContainerCaption('Selected Sources');

        this.devicesFilter = new multipleSelector_template('devicesFilter', this.getTemplateViewModel(), this.caller);
        this.devicesFilter.setLabel('devices');
        this.devicesFilter.setFilterContainerClass('margin-neg');
        this.devicesFilter.setTagContainerId('selected-devices');
        this.devicesFilter.setTagContainerCaption('Selected Devices');

        this.operatingSystemsFilter = new multipleSelector_template('operatingSystemsFilter', this.getTemplateViewModel(), this.caller);
        this.operatingSystemsFilter.setLabel('operatingSystems');
        this.operatingSystemsFilter.setFilterContainerClass('margin-neg');
        this.operatingSystemsFilter.setTagContainerId('selected-operatingSystems');
        this.operatingSystemsFilter.setTagContainerCaption('Selected Operating Systems');

        this.geosFilter = new multipleSelector_template('geosFilter', this.getTemplateViewModel(), this.caller);
        this.geosFilter.setLabel('geos');
        this.geosFilter.setFilterContainerClass('margin-neg');
        this.geosFilter.setTagContainerId('selected-geos');
        this.geosFilter.setTagContainerCaption('Selected Geos');

        let dateEnd = new Date();
        dateEnd.setDate(dateEnd.getDate()-1);

        let dateStart = new Date();
        dateStart.setDate(dateStart.getDate()-8);

        this.datesFilter = new filterDate_template("datesFilter", this.getTemplateViewModel(), this.caller);
        this.datesFilter.setStartPickerId('startDatePicker');
        this.datesFilter.setEndPickerId('endDatePicker');
        this.datesFilter.setDateRange(dateStart, dateEnd);

        this.metricsFilter = new multipleSelector_template('metricsFilter', this.getTemplateViewModel(), this.caller);
        this.metricsFilter.setLabel("metrics");
        this.metricsFilter.setFilterContainerClass('margin-neg');
        this.metricsFilter.setTagContainerId('selected-metrics');
        this.metricsFilter.setTagContainerCaption('Selected Metrics:');

        this.exportAdHealthDataCsv = new exportDataCsv();
    }

    protected getTemplateName(): string{

        return 'adHealthList_template';
    }

    protected getMainElementClasses(): Array<string>{

        return [];
    }

    public hideExportButton(){

        this.getTemplateViewModel().exportButtonHidden(1);
    }

    public showExportButton(){

        this.getTemplateViewModel().exportButtonHidden(0);
    }

    public activateLoading(): void{

        this.getTemplateViewModel().isLoading(1);
    }

    /**
     * @inheritDoc
     */
    public deactivateLoading(): void{

        this.getTemplateViewModel().isLoading(0);
    }

    /**
     * @inheritDoc
     */
    public buildViewModel(): Object{

        let self = this;

        return {

            dimensionSelected: ko.observable('none'),
            brandsSelected: ko.observableArray([]),
            templatesSelected: ko.observableArray([]),
            sourcesSelected: ko.observableArray([]),
            devicesSelected: ko.observableArray([]),
            operatingSystemsSelected: ko.observableArray([]),
            geosSelected: ko.observableArray([]),
            metricsSelected: ko.observableArray([]),
            groupsHeaders: ko.observableArray([]),
            metricsHeaders: ko.observableArray([]),
            results: ko.observableArray([]),
            isLoading: ko.observable(0),
            isVisible: ko.observable('is-visible'),
            exportButtonHidden: ko.observable(1),

            exportData: function(){

                self.exportData(self);
            },
        };
    }

    public setSelectedDimension(){

        this.getTemplateViewModel().dimensionSelected(this.getTemplateViewModel().dimensionFilter.selectedOption());
    }


    public setSelectedBrands(){

        this.getTemplateViewModel().brandsSelected(this.brandsFilter.getSelectedItems());
    }

    public setSelectedTemplates(){

        this.getTemplateViewModel().templatesSelected(this.templatesFilter.getSelectedItems());
    }

    public setSelectedSources(){

        this.getTemplateViewModel().sourcesSelected(this.sourcesFilter.getSelectedItems());
    }

    public setSelectedDevices(){

        this.getTemplateViewModel().devicesSelected(this.devicesFilter.getSelectedItems());
    }

    public setSelectedOperatingSystems(){

        this.getTemplateViewModel().operatingSystemsSelected(this.operatingSystemsFilter.getSelectedItems());
    }

    public setSelectedGeos(){

        this.getTemplateViewModel().geosSelected(this.geosFilter.getSelectedItems());
    }

    public setSelectedMetrics(){

        this.getTemplateViewModel().metricsSelected(this.metricsFilter.getSelectedItems());
    }

    public getViewModelForRequest(): Object{

        return {

            filters:{

                dimension: this.getTemplateViewModel().dimensionFilter.selectedOption,
                brands: this.getTemplateViewModel().brandsFilter.selectedItems,
                templates: this.getTemplateViewModel().templatesFilter.selectedItems,
                sources: this.getTemplateViewModel().sourcesFilter.selectedItems,
                devices: this.getTemplateViewModel().devicesFilter.selectedItems,
                operatingSystems: this.getTemplateViewModel().operatingSystemsFilter.selectedItems,
                geos: this.getTemplateViewModel().geosFilter.selectedItems,
                startDate: this.getTemplateViewModel().datesFilter.startDate,
                endDate: this.getTemplateViewModel().datesFilter.endDate,
                metrics: this.getTemplateViewModel().metricsFilter.selectedItems,
            },
        };
    }

    public buildDatePickerCalendar(){

        this.datesFilter.buildCalendar();
    }

    public setHeaders(groupsHeaders: Array<object> = [], metricsHeaders: Array<object> = []){

        this.getTemplateViewModel().groupsHeaders(groupsHeaders);
        this.getTemplateViewModel().metricsHeaders(metricsHeaders);

        this.buildHeadersArray();
    }

    public setResults(results): void{

        this.getTemplateViewModel().results(results);
        this.showExportButton();
    }

    public setDimensionsItems(dimensions: Array<{ id: number, name: string }>){

        this.dimensionFilter.setOptions(dimensions);
    }

    public setBrandsFilterItems(items){

        this.brandsFilter.setItems(items);
    }

    public setTemplatesFilterItems(items){

        this.templatesFilter.setItems(items);
    }

    public setSourcesFilterItems(items){

        this.sourcesFilter.setItems(items);
    }

    public setDevicesFilterItems(items){

        this.devicesFilter.setItems(items);
    }

    public setOperatingSystemsFilterItems(items){

        this.operatingSystemsFilter.setItems(items);
    }

    public setGeosFilterItems(items){

        let groups = [];
        for (let groupedGeos of items){

            let group = {"name" : groupedGeos.name, "items" : groupedGeos.geos};
            groups.push(group);
        }

        this.geosFilter.setMultiSectionItems(groups);
    }

    public setMetricsFilterItems(metrics: Array<{ id: number, name: string }>){

        this.metricsFilter.setItems(metrics);
    }

    private exportData(obj){

        let fileName = "adHealth_data_" + this.datesFilter.getTemplateViewModel().startDate() + "_" + this.datesFilter.getTemplateViewModel().endDate()+ ".csv";

        let statsCsv = [];
        let groupsHeaders = this.getTemplateViewModel().groupsHeaders();
        let metricsHeaders = this.getTemplateViewModel().metricsHeaders();

        this.getTemplateViewModel().results().forEach(function(line, resultIndex){

            let csvStatLine = {

                date: line.date
            };

            if(groupsHeaders.length > 0){

                groupsHeaders.forEach(function(header, headerIndex){

                    metricsHeaders.forEach(function(metric, metricIndex){

                        line.groups.forEach(function(group, groupIndex){

                            if (header.name == group.group){

                                csvStatLine[header.name + " " + metric.name] = group.metrics[metric.id];
                            }
                        });
                    });
                });
            }
            else{

                metricsHeaders.forEach(function(metric, metricIndex){

                    csvStatLine[metric.name] = line.groups[0].metrics[metric.id];
                });
            }

            statsCsv.push(csvStatLine)
        });

        this.exportAdHealthDataCsv.exportToCsv(fileName, statsCsv);
    }

    //
    // Build headers array.
    private buildHeadersArray(){

        let groupsHeaders  = this.getTemplateViewModel().groupsHeaders();
        let metricsHeaders = this.getTemplateViewModel().metricsHeaders();

        if(0 === groupsHeaders.length){

            groupsHeaders = [
                {
                    id: 0,
                    name: '',
                },
            ];
        }

        let headersArrayForFields = [{
            headerName: this.getTemplateViewModel().dimensionFilter.selectedOption || 'No Dimension',
            fieldName: null,
            "subHeaders": [
                { headerName: 'Date', fieldName: null, "subHeaders": [] },
            ],
        }];

        for(let groupsHeader of groupsHeaders){

            let subHeaders = [];

            for(let metricsHeader of metricsHeaders){

                subHeaders.push({ headerName: metricsHeader.name, fieldName: null, "subHeaders": [] });
            }

            headersArrayForFields.push({ headerName: groupsHeader.name, fieldName: null, "subHeaders": subHeaders });
        }

        this.headersArrayForFields = headersArrayForFields;

        this.headers.setHeadersLevels(this.headersArrayForFields);
    }
}