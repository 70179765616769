import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";
import {vnApp_ads_ninja} from "../vnApp_ads_ninja";

declare var ko: any;

export class usersProfile_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="user-profil">
            
               <div class="user-header">
                   <button data-bind="visible: hasUpdateAccess, event: { click: onClickEdit }" class="btn icon-only icon i-edit" type="button"><span class="hidden-text">Edit</span></button>
                   <a href="/users/" class="btn icon-only icon i-back" type="button" data-bind="visible: hasUserOthersAccess"></a>

                   <div class="w-user-img"><img class="user-img" data-bind="attr:{src: pictureUrl}" alt=""></div>
                   <strong>
                        <span data-bind="text: firstName"></span>
                        <span data-bind="text: lastName"></span>
                   </strong>
                   <ul class="property-list" data-bind="foreach: userProperties">
                       <li class="property-item" data-bind="css: logoImageUrl"></li>
                   </ul>

               </div>
              
               <div class="user-infos">
                   <ul class="user-infos-list">
                       <li class="user-infos-item">
                           <span class="user-infos-title">First Name</span>
                           <span data-bind="text: firstName" class="user-infos-value"></span>
                       </li>
                       
                       <li class="user-infos-item">
                           <span class="user-infos-title">Last Name</span>
                           <span data-bind="text: lastName" class="user-infos-value"></span>
                       </li>
                       
                       <li class="user-infos-item">
                           <span class="user-infos-title">User Name</span>
                           <span data-bind="text: loginName" class="user-infos-value"></span>
                       </li>
                       
                       <li class="user-infos-item">
                           <span class="user-infos-title">Email</span>
                           <span data-bind="text: email" class="user-infos-value"></span>
                       </li>
                       
                        <li class="user-infos-item">
                           <span class="user-infos-title">Role(s)</span>
                           <span data-bind="text: roles" class="user-infos-value"></span>
                       </li>
                   </ul>
               </div>
           </div>
            `;
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "usersProfile-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementType(): string{

        return "div";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return [""];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;

        return{
            idUser: ko.observable(""),
            firstName: ko.observable(""),
            lastName: ko.observable(""),
            loginName: ko.observable(""),
            hasUpdateAccess: ko.observable(false),
            hasUserOthersAccess: ko.observable(this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_USER_OTHERS)),
            email: ko.observable(""),
            pictureUrl: ko.observable(null),
            roles: ko.observableArray([]),
            userProperties: ko.observableArray([]),
            isActive: ko.observable(""),
            onClickEdit: function(data, event){ huit_neuf_this.onClickEdit(data.idUser()) },
        }
    }

    /**
     * Sets has update access.
     *
     * @param hasUpdateAccess
     */
    public setHasUpdateAccess(hasUpdateAccess: boolean): void{

        this.getTemplateViewModel().hasUpdateAccess(hasUpdateAccess);
    }

    /**
     * Sets the roles.
     *
     * @param selectedRoles
     */
    public setRoles(selectedRoles: Array<{name: string}>): void{

        let roles = [];

        for(let r of selectedRoles){

            roles.push(r.name);
        }

        this.getTemplateViewModel().roles(roles);
    }

    /**
     * Sets the properties.
     *
     * @param properties
     */
    public setProperties(properties: any): void{

        this.getTemplateViewModel().userProperties(properties);
    }

    /**
     * Handles on edit button click event.
     *
     * @param idUser
     */
    public onClickEdit(idUser){

        this.caller.editUser(idUser);
    }
}
