import { multipleSelector_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/multipleSelector_template";
import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class adBehaviours_highValueSegment_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `

            <div class="w-page-tab">
                <div class="page-tab">
                    <button data-bind="click: onClickRedirect" class="btn large icon i-settings" type="button">General</button>
                    <button class="btn large icon i-unit is-selected" type="button">High Value</button>
                </div>
            </div>

            <div class="form-header" style="margin-top: 0px;">
                <h3 class="form-title icon i-config">Edit Ads Behaviour</h3>
                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
                  
            <div class="w-form-body ads-behaviour-data">
                <div class="form-body">
                    
                    <div class="form-group">
                        <label class="form-sub-title">Prebid Floors</label>
                    </div>
                    
                    <!-- ko foreach: prebidFloors -->

                        <div class="form-group row">
                            <label class="form-label" data-bind="text: key, attr: {for: 'prebidFloors_' + key }"></label>
                            <input data-bind="value: globalValue" type="number" class="input-text num read-only" disabled style="width: 15%; margin: 0 5px">
                            <input class="input-text num" style="width: 15%; margin: 0 5px" type="number" data-bind="value: value, attr: {id: 'prebidFloors_' + key, name: 'prebidFloors_' + key }" min="0">
                        </div>      

                    <!-- /ko -->

                    <div class="form-group">
                        <label class="form-sub-title">Bidding Timeouts</label>
                    </div>
                                      
                    <div class="form-group row">
                        <label for="globalFailsafeTimeout" class="form-label">Global Failsafe Timeout</label>
                        <input data-bind="value: brandLevelOptions().globalFailsafeTimeout" type="number" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <input id="globalFailsafeTimeout" data-bind="value: globalFailsafeTimeout" type="number" class="input-text num" style="width: 15%; margin: 0 5px" min="0">
                    </div>      
                    
                    <div class="form-group row">
                        <label for="prebidTimeout" class="form-label">Prebid Timeout</label>
                        <input data-bind="value: brandLevelOptions().prebidTimeout" type="number" class="input-text num read-only" disabled style="width: 15%; margin: 0 5px">
                        <input id="prebidTimeout" data-bind="value: prebidTimeout" type="number" class="input-text num" style="width: 15%; margin: 0 5px" min="0">
                    </div>

                    <div class="form-group row">
                        <label for="amazonTimeout" class="form-label">Amazon Timeout</label>
                        <input data-bind="value: brandLevelOptions().amazonTimeout" type="number" class="input-text num read-only" disabled style="width:15%; margin: 0 5px">
                        <input id="amazonTimeout" data-bind="value: amazonTimeout" type="number" class="input-text num" style="width: 15%; margin: 0 5px" min="0">
                    </div>
                </div>
                <div class="form-body">

                    <div class="form-group row">
                        &nbsp;
                    </div>

                    <div class="form-group">
                        <label class="form-sub-title">Injection Counts</label>
                    </div>

                    <div class="form-group row">
                        <label for="adDensityLiftPercentageCountCharacter" class="form-label">Character Count <br> Ad Density Lift Percentage <span style="color: white" class="form-label legend">(%)</span></label>
                        <input data-bind="value: brandLevelOptions().adDensityLiftPercentageCountCharacter" type="number" class="input-text num read-only" disabled style="width: 15%; margin: 0 5px">
                        <input id="adDensityLiftPercentageCountCharacter" data-bind="value: adDensityLiftPercentageCountCharacter" type="number" class="input-text num" style="width: 15%; margin: 0 5px" min="0">
                    </div>

                    <div class="form-group row">
                        <label for="adDensityLiftPercentageCountWord" class="form-label">Word Count <br> Ad Density Lift Percentage <span style="color: white" class="form-label legend">(%)</span></label>
                        <input data-bind="value: brandLevelOptions().adDensityLiftPercentageCountWord" type="number" class="input-text num read-only" disabled style="width: 15%; margin: 0 5px">
                        <input id="adDensityLiftPercentageCountWord" data-bind="value: adDensityLiftPercentageCountWord" type="number" class="input-text num" style="width: 15%; margin: 0 5px" min="0">
                    </div>

                    <div class="form-group">
                        <label class="form-sub-title">Boosted Articles</label>
                    </div>

                    <div class="form-group row">
                        <label for="articleCPMThresholdPercentage" class="form-label">Threshold Percentage <span style="color: white" class="form-label legend">(%)</span></label>
                        <input data-bind="value: brandLevelOptions().articleCPMThresholdPercentage" type="number" class="input-text num read-only" disabled style="width: 15%; margin: 0 5px;">
                        <input id="articleCPMThresholdPercentage" data-bind="value: articleCPMThresholdPercentage" type="number" class="input-text num" style="width: 15%; margin: 0 5px" min="0">
                    </div>

                    <div class="form-group row">
                        <label for="articleBoostPercentage" class="form-label">Boosted Percentage <span style="color: white" class="form-label legend">(%)</span></label>
                        <input data-bind="value: brandLevelOptions().articleBoostPercentage" type="number" class="input-text num read-only" disabled style="width: 15%; margin: 0 5px;">
                        <input id="articleBoostPercentage" data-bind="value: articleBoostPercentage" type="number" class="input-text num" style="width: 15%; margin: 0 5px" min="0">
                    </div>   
                </div>
            </div>

            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "adBehaviours_highValueSegment_template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "w-form-large", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let self = this;

        return {
            id: ko.observable(''),
            idBrand: ko.observable(null),
            prebidFloors: ko.observableArray([]),
            globalFailsafeTimeout: ko.observable(''),
            prebidTimeout: ko.observable(''),
            amazonTimeout: ko.observable(''),
            adDensityLiftPercentageCountCharacter: ko.observable(''),
            adDensityLiftPercentageCountWord: ko.observable(''),
            articleCPMThresholdPercentage: ko.observable(''),
            articleBoostPercentage: ko.observable(''),
            brandLevelOptions: ko.observable({}),
            onClickSave: function(data, event){ self.onClickSave(); },
            onClickCancel: function(data, event){ self.onClickCancel(); },
            onClickRedirect: function(data, event){ self.caller.goToTab(); },

        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        let viewModel         = this.getTemplateViewModel();
        let prebidFloorsArray = viewModel.prebidFloors();
        let prebidFloors      = {};
        let hasFloors         = false;

        for(let prebidFloor of prebidFloorsArray){
            
            if(prebidFloor.value()){

                hasFloors = true;
                break;
            }
        }

        if(hasFloors){

            for(let prebidFloor of prebidFloorsArray){
            
                prebidFloors[prebidFloor.key] = prebidFloor.value() || prebidFloor.globalValue();
            }
        }
        else{

            prebidFloors = null;
        }

        return {
            id: viewModel.id(),
            idBrand: viewModel.idBrand(),
            prebidFloors: prebidFloors,
            globalFailsafeTimeout: viewModel.globalFailsafeTimeout(),
            prebidTimeout: viewModel.prebidTimeout(),
            amazonTimeout: viewModel.amazonTimeout(),
            adDensityLiftPercentageCountCharacter: viewModel.adDensityLiftPercentageCountCharacter(),
            adDensityLiftPercentageCountWord: viewModel.adDensityLiftPercentageCountWord(),
            articleCPMThresholdPercentage: viewModel.articleCPMThresholdPercentage(),
            articleBoostPercentage: viewModel.articleBoostPercentage(),
        };
    }

    //
    // Global config.
    public setBrandLevelOptions(brandLevelOptions){

        this.getTemplateViewModel().brandLevelOptions(brandLevelOptions);
    }

    //
    // Set data.
    public setEditData(data){

        this.getTemplateViewModel().id(data.id);
        this.getTemplateViewModel().idBrand((data.brand) ? data.brand.id : null);
        this.getTemplateViewModel().globalFailsafeTimeout((isNaN(data.globalFailsafeTimeout)) ? null : data.globalFailsafeTimeout);
        this.getTemplateViewModel().prebidTimeout((isNaN(data.prebidTimeout)) ? null : data.prebidTimeout);
        this.getTemplateViewModel().amazonTimeout((isNaN(data.amazonTimeout)) ? null : data.amazonTimeout);
        this.getTemplateViewModel().adDensityLiftPercentageCountCharacter((isNaN(data.adDensityLiftPercentageCountCharacter)) ? null : data.adDensityLiftPercentageCountCharacter);
        this.getTemplateViewModel().adDensityLiftPercentageCountWord((isNaN(data.adDensityLiftPercentageCountWord)) ? null : data.adDensityLiftPercentageCountWord);
        this.getTemplateViewModel().articleCPMThresholdPercentage((isNaN(data.articleCPMThresholdPercentage)) ? null : data.articleCPMThresholdPercentage);
        this.getTemplateViewModel().articleBoostPercentage((isNaN(data.articleBoostPercentage)) ? null : data.articleBoostPercentage);

        let prebidFloors      = [];
        let brandLevelOptions = this.getTemplateViewModel().brandLevelOptions();
        // Price floors.
        Object.keys(brandLevelOptions.prebidFloors).forEach(function(key){
                    
            prebidFloors.push({
                key: key, 
                value: ko.observable((data.prebidFloors && typeof data.prebidFloors === 'object' && Object.keys(data.prebidFloors).length > 0 && data.prebidFloors.hasOwnProperty(key)) ? data.prebidFloors[key] : null), 
                globalValue: ko.observable(brandLevelOptions.prebidFloors[key])
            });
        });

        this.getTemplateViewModel().prebidFloors(prebidFloors);
    }

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        this.caller.save();
    }


    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancel();
    }
}
