import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import { abTestingScheduleEdit_template } from "../components/templates/abTestingScheduleEdit_template";
import { header } from "./header";

declare var ko: any;
declare var page: any;

export class abTestingSchedule_edit extends vnModule_knockoutJS{

    protected request: XMLHttpRequestHandler;
    protected template: abTestingScheduleEdit_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return "abTestingSchedule_edit";
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName('Header');
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle('A/B Testing Edit');

        this.template = new abTestingScheduleEdit_template('abTestingSchedule_edit', this.viewModel, this);

        return [this.template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getEditFormData();
    }

    /**
     * Hook.
     *
     * Cancels the edits to a user.
     */
    protected cancel(){

        this.loadingIn(this.template.builtTemplate);

        this.releaseLock();

        let idBrand = this.getVnAppZone().app.params.idBrand;

        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, 'Action canceled!');

        page.redirect(`/brand/${idBrand}/ads_setups`);
    }

    /**
     * Requests a lock release.
     */
    protected releaseLock(): void{

        let idBrand = this.getVnAppZone().app.params.idBrand;

        // Request a lock release.
        this.request = new XMLHttpRequestHandler(`/api/brand/${idBrand}/ab_test_schedule/release_lock/`, [], this);
        this.request.execute();
    }

    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditData(takeover: boolean = false): void{

        this.loadingIn(this.template.builtTemplate);

        let idBrand = this.getVnAppZone().app.params.idBrand;

        this.request = new XMLHttpRequestHandler(`/api/brand/${idBrand}/ab_test_schedule/get/`, [['takeover', (takeover) ? '1' : '0']], this);
        this.request.onReadyStateFunction = this.onEditDataRequestReturn;
        this.request.execute();
    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onEditDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditData(responseParsed);
                obj.loadingOut(obj.template.builtTemplate);
            });
        }
    }

    /**
     * Updates the form elements.
     *
     */
    protected updateEditData(responseParsed: any){

        this.template.getTemplateViewModel().ratioA(responseParsed.ratioA);
        this.template.getTemplateViewModel().ratioB(responseParsed.ratioB);
    }

    /**
     * Saves the source data.
     */
    protected save(): void{

        this.loadingIn(this.template.builtTemplate);

        let subViewModel = this.template.getViewModelForRequest();
        let idBrand      = this.getVnAppZone().app.params.idBrand;

        let params: Array<Array<string>> = [];
        params.push(['data', ko.toJSON(subViewModel)]);

        let request                  = new XMLHttpRequestHandler(`/api/brand/${idBrand}/ab_test_schedule/save/`, params, this);
        request.mode                 = XMLHttpRequestHandler_requestType.POST;
        request.onReadyStateFunction = this.onSaveReturn;
        request.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{

        let idBrand = obj.getVnAppZone().app.params.idBrand;

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Created successfully!');
                obj.releaseLock();
                page.redirect(`/brand/${idBrand}/ads_setups`);
            });

            obj.loadingOut(obj.template.builtTemplate);
        }
    }

    //
    // Obtain the dynamic data required to fill the form.
    protected getEditFormData(): void{

        this.loadingIn(this.template.builtTemplate);

        let idBrand = this.getVnAppZone().app.params.idBrand;

        this.request = new XMLHttpRequestHandler(`/api/brand/${idBrand}/ab_test_schedule/references/`, [], this);
        this.request.onReadyStateFunction = this.onEditFormDataRequestReturn;
        this.request.execute();
    }

    //
    // Returns handler for edit form request.
    protected onEditFormDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.loadingOut(obj.template.builtTemplate);
                obj.updateEditFormData(responseParsed);
                obj.getEditData();
            });
        }
    }

    /**
     * Update the references.
     *
     * @param responseParsed
     */
    protected updateEditFormData(responseParsed: any): void{

        this.template.getTemplateViewModel().brand(responseParsed.brand);
        this.template.getTemplateViewModel().group(responseParsed.group);
    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        switch(context){

            case 'takeover':

                this.getEditData(true);
                break;
        }
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }
}
