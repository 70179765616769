    import { vnModule } from "../vnApp/vnModule";
    import { vnApp } from "../vnApp/vn_app";
    import { vn_app_zone_notification } from "./vn_app_zone_notification";

    declare var page: any;

    export abstract class vnAppZone {

        // MEMBER VARIABLES
        protected _globals: Array<{index:string, value: any}>;
        protected _app : vnApp;
        protected _lastLoadedModule;
        protected _loadedModule: vnModule;

        protected _destination: HTMLElement;
        protected _routesActions;
        protected _notification: vn_app_zone_notification;
        public validRoutes: string[];
        public _zoneName: string;
        public isOnAlwaysReloadMode: boolean;

        get app():vnApp {
            return this._app;
        }
        set app(app: vnApp) {
            this._app = app;
        }

        get lastLoadedModule():vnModule {
            return this._lastLoadedModule;
        }
        set lastLoadedModule(lastLoadedModule: vnModule) {
            this._lastLoadedModule = lastLoadedModule;
        }

        get loadedModule():vnModule {
            return this._loadedModule;
        }
        set loadedModule(loadedModule: vnModule) {
            this._loadedModule = loadedModule;
        }

        get routesActions():any {
            return this._routesActions;
        }
        set routesActions(routesActions) {
            this._routesActions = routesActions;
        }

        get globals():Array<{index:string, value: any}> {
            return this._globals;
        }
        set globals(globals:Array<{index:string, value: any}>) {
            this._globals = globals;
        }

        public addGlobal(value: {index:string, value: any}){
            for (var g = 0; g < this.globals.length; g++) {
                if( this.globals[g].index == value.index ){
                    this.globals[g].value = value.value;
                    return;
                }
            }
            this.globals.push( value );
        }
        public removeGlobal(index: string){
            for (var g = 0; g < this.globals.length; g++) {
                if( this.globals[g].index == index ){
                    this.globals.splice(g,1);
                }
            }
        }
        public getGlobal(index: string){
            for (var g = 0; g < this.globals.length; g++) {
                if( this.globals[g].index == index ){
                    return this.globals[g].value;
                }
            }
            return null;
        }

        get notification():vn_app_zone_notification {
            return this._notification;
        }
        set notification(notification :vn_app_zone_notification) {
            this._notification = notification;
        }

        get zoneName(): string {
            return this._zoneName;
        }
        set zoneName(zoneName: string) {
            this._zoneName = zoneName;
        }

        get destination(): HTMLElement {
            return this._destination;
        }
        set destination(destination: HTMLElement) {
            this._destination = destination;
        }

        public constructor(destination) {
            this.zoneName = "undefined";
            this.destination = destination;
            this.routesActions = [];
            this.loadedModule = null;
            this.defineRoutes();
            this.isOnAlwaysReloadMode = false;
            this.globals = [];
        }

        //
        // add a function to execute for specific route
        public addForRoute(route: string, loggedInFct, loggedOutFct) {

            this.routesActions[route] = [loggedInFct,loggedOutFct];
        }
        //
        // loaded the specifyed module
        // * only load module if its no the one already loaded
        public loadModule(module: vnModule){

                if(module != null) {

                    if( this.isOnAlwaysReloadMode || this.loadedModule == null || module.name != this.loadedModule.name || (this.app.loggedUser.lastIsLoggedIn !=  this.app.loggedUser.isLoggedIn) ) {

                        this.destination.className = "";
                        this.destination.classList.add("loading");

                        // load it
                        module.load(this.destination);

                        // keep it as loaded
                        this.loadedModule = module;

                        this.app.isLeavePageWarningNeeded = module.isLeavePageWarningNeeded;

                        if( this.notification ){

                            this.showNotificationToModule();
                        }else if(module.name == "home"){
                            //check permalink if it has error parameter, if it has it, check the code, and display the error using showNotificationToModule
                            let url = window.location.href;
                            let errorCode = url.split("redirected=")[1];

                            if(errorCode){
                                let errorCodeMapping = {
                                    1: "Redirected since the requested page does not exist."
                                }
                                let errorMessage = errorCodeMapping[errorCode];
                                if(errorMessage){
                                    this.notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, errorMessage);
                                    let homeUrl = url.split('?')[0];
                                    history.pushState({}, '', homeUrl);
                                }
                            }
                            this.showNotificationToModule();
                        }
                        // insert the module into the world !!! ( * rainbows and butterflies * )
                        this.destination.classList.remove("loading");

                        let nameClasses = this.loadedModule.name.split(" ");
                        for( let nc of nameClasses ){
                            this.destination.classList.add(nc);
                        }
                        //This initializes the state of the app so when cancelling the back button it doesn't refresh the page
                        this.moduleStateInitializer(this.app.isLeavePageWarningNeeded);
                    }
                }
                else {
                    this.destination.innerHTML = "";
                }
        }

        public moduleStateInitializer(isLeavePageWarningNeeded : boolean){
            if(isLeavePageWarningNeeded){
                history.pushState(true, "unused argument", null);
                history.pushState(true, "unused argument2", null);
            }
        }

        public addNotificationToModule(notification: vn_app_zone_notification){

            this.notification = notification;
        }

        public showNotificationToModule(){

            if( this.notification ){

                let notificationDiv = document.createElement("div");
                notificationDiv.className = "zone-notification icon i-notice-"+this.notification.type+" "+this.notification.type;
                notificationDiv.innerHTML = "<div class='text'><strong>"+this.notification.type+"</strong><p>" + this.notification.message + "</p></div>";
                this.destination.insertBefore(notificationDiv, this.destination.firstChild);
                this.notification = null;
                this.deleteIn(4, notificationDiv);
            }
        }

        public showLoadingPopup(){
            this.hideLoadingPopup();
            let loadingDiv = document.createElement("div");
            loadingDiv.id = "loading-popup";
            loadingDiv.className = "w-loader";
            loadingDiv.innerHTML = "<div class='loader'></div>";
            this.destination.insertBefore(loadingDiv, this.destination.firstChild);
        }

        public hideLoadingPopup(){
            let element = document.getElementById("loading-popup");
            if(element){
                element.parentNode.removeChild(element);
            }
        }

        protected deleteIn(sec: number, element : HTMLElement){
            setTimeout(function(){
                  element.parentNode.removeChild(element);
            }, sec*1000);
        }

        //
        // Uses the  loaded module to inject it's content into
        // the specifyed destination
        public setContent(content: HTMLElement){

            this.destination.innerHTML = "";
            this.destination.appendChild(content);
        }

        public getApp() {
            return this._app;
        }
        public abstract defineRoutes();
    }


