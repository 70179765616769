/**
 * Created by abdelhalim on 2017-07-14.
 */

import { vnAppZone } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone";
import { pageFactory } from "../modules/factory";


export class vnAppZoneMenu extends vnAppZone {

    public constructor(destination) {
            super(destination);
            this.zoneName = "Menu";
            this.isOnAlwaysReloadMode = true;
    }

    defineRoutes() {
        let factory = new pageFactory();
        let _mythis = this;
        //define routes
        this.addForRoute('all', function(){

            // obtain the right module object
            let moduleObj = factory.get_actionHandler("menu", _mythis);

            // load it into the zone
            _mythis.loadModule(moduleObj);
        },  null);

        this.addForRoute('/menu', function() {}, null );

    }
}
