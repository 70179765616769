import {vnTemplate} from "./vnTemplate";

declare var ko: any;

export class appTitle_template extends vnTemplate{

    protected getTemplateHtml(): string{

        return `
             <!-- ko if: !isLinkDisabled() -->
             <a data-bind="attr: { href: url }"><span data-bind="text: title"></span></a>
             <!-- /ko -->
            `;
    }

    protected getTemplateName(): string{
        return "appTitle-template";
    }

    protected getMainElementType(): string{
        return "h1";
    }

    protected getMainElementClasses(): Array<string>{
        return ["site-logo"];
    }

    protected buildViewModel(): any{

        return {
            title: ko.observable("no title")
            ,isLinkDisabled: ko.observable(false)
            ,url: ko.observable("/")
        };
    }

    public setTitle(title: string){
        this.getTemplateViewModel().title(title);
    }

    public setIsLinkDisabled(isLinkDisabled: boolean){
        this.getTemplateViewModel().isLinkDisabled(isLinkDisabled);
    }

    public setUrl(url: string){
        this.getTemplateViewModel().url(url);
    }

}
