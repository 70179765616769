import { vnTemplate } from "./vnTemplate";
import { filterDropdown_template } from "./filterDropdown_template";
import { pagination_template } from "./pagination_template";
import { resultsInfo_template } from "./resultsInfo_template";
import { filterSimple_template } from "./filterSimple_template";
import {autoRefresh_template} from "./autoRefresh_template";

declare var ko: any;

export class logsList_template extends vnTemplate{

    protected accountFilter: filterDropdown_template;
    protected operationFilter: filterDropdown_template;
    protected keywordSearch: filterSimple_template;
    protected pagination: pagination_template;
    protected resultsInfo: resultsInfo_template;
    protected autoRefresh: autoRefresh_template;


    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="sub-header">
                <div class="w-justify">
                    <div class="w-left">  
                        <div>
                            <label class="form-label form-label-box">Task Executers</label>
                            ${this.accountFilter.getHtmlBuild()}     
                        </div>
                        <div>
                            <label class="form-label form-label-box">Operations</label>
                            ${this.operationFilter.getHtmlBuild()}     
                        </div>
                       
                    </div>
                </div>
            </div>
            
            
            <div class="list-header  w-justify">
                <div class="w-left">
                    <h2 class="section-title">Logs</h2>
                    ${this.autoRefresh.getHtmlBuild()}
                </div>
                <div class="w-center">
                    ${this.keywordSearch.getHtmlBuild()}
                </div>
                <div class="w-right">
                    ${this.pagination.getHtmlBuild()}
                </div>
               
            </div>
            
            <div class="w-list-results" id="list-results">
                <table class="list-results">
                    ${this.resultsInfo.getHtmlBuild()}
                    <thead>
                        <input data-bind="value: orderBy" type="hidden">
                        <input data-bind="value: orderByDirection" type="hidden">
                        <th><span data-bind="event: { click: onClickReorder.bind($data, 'id', 'highlight') }, css: { 'list-sort-desc': orderByAndDirection()=='id_DESC', 'list-sort-asc': orderByAndDirection()=='id_ASC' }" class="icon
                        ">Id</span></th>
                        <th><span data-bind="event: { click: onClickReorder.bind($data, 'dateLog') }, css: { 'list-sort-desc': orderByAndDirection()=='dateLog_DESC', 'list-sort-asc': orderByAndDirection()=='dateLog_ASC' }" class="icon">Date Log</span></th>
                        <th><span data-bind="event: { click: onClickReorder.bind($data, 'message') }, css: { 'list-sort-desc': orderByAndDirection()=='message_DESC', 'list-sort-asc': orderByAndDirection()=='message_ASC' }" class="icon">Message</span></th>
                        <th><span data-bind="event: { click: onClickReorder.bind($data, 'type') }, css: { 'list-sort-desc': orderByAndDirection()=='type_DESC', 'list-sort-asc': orderByAndDirection()=='type_ASC' }" class="icon">Account</span></th>
                        <th><span>See in Full List</span></th>
                    </thead>
                    <tbody data-bind="foreach: logs">   
                        <tr data-bind="attr: {id: 'row-' + $data.id}, css: {'highlight': $data.highlight}">
                            <td class="log_id"><span data-bind="text: id"></span></td>
                            <td class="log_dateLog"><span data-bind="text: dateLog.date"></span></td>
                            <td class="log_message"><span data-bind="html: message"></span></td>
                            <td class="log_type"><span data-bind="text: type"></span></td>
                            <td class="button align-left"><button data-bind="event: { click: $parent.onClickLogTrack }" type="button" class="btn only-icon i-back icon"></button></td>
                        </tr>                 
                    <tbody>
                    <tfoot>
                    </tfoot>
                </table>
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{

        this.accountFilter = new filterDropdown_template("accountFilter", this.getTemplateViewModel(), this.caller);
        this.operationFilter = new filterDropdown_template("operationFilter", this.getTemplateViewModel(), this.caller);

        this.keywordSearch = new filterSimple_template("keywordSearch", this.getTemplateViewModel(), this.caller);
        this.resultsInfo   = new resultsInfo_template("resultsInfo", this.getTemplateViewModel(), this.caller);

        this.autoRefresh     = new autoRefresh_template('autoRefresh', this.getTemplateViewModel(), this.caller);
        // this.autoRefresh.getTemplateViewModel().setRefreshFrequency(10000);

        this.pagination = new pagination_template("pagination", this.getTemplateViewModel(), this.caller);
        this.pagination.getTemplateViewModel().itemPerPage = 25;
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "logsList_template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return [];
    }

    /**
     * @inheritDoc
     */
    public buildViewModel(){

        let self = this;

        return {
            // orderBy: ko.observable('dateLog'),
            // orderByDirection: ko.observable('DESC'),
            // orderByAndDirection: ko.observable('dateLog_DESC'),
            orderBy: ko.observable('id'),
            orderByDirection: ko.observable('DESC'),
            orderByAndDirection: ko.observable('id_DESC'),
            logs: ko.observableArray([]),
            isLoading: ko.observable(0),
            accountFilter: ko.observable([]),
            operationFilter: ko.observable([]),
            keyword: ko.observable(""),
            trackedLogId: ko.observable(null),
            isHighlightable: ko.observable(0),
            onClickReorder: function (value, data, event) { self.onClickReorder(value, data, event); },
            onClickLogTrack: function (data, event) { self.onClickLogTrack(data.id); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            operationFilter: this.operationFilter.getTemplateViewModel().selectedOption(),
            accountFilter: this.accountFilter.getTemplateViewModel().selectedOption(),
            pagination: this.pagination.getTemplateViewModel().pageNumber(),
            orderBy: this.getTemplateViewModel().orderBy(),
            orderByDirection: this.getTemplateViewModel().orderByDirection(),
            keyword: this.keywordSearch.getTemplateViewModel().filterKeyword(),
            trackedId: this.getTemplateViewModel().trackedLogId(),
            autoRefresh: this.autoRefresh.getTemplateViewModel().callRefresh()
        };
    }

    /**
     * @inheritDoc
     */
    public activateLoading(){

        this.getTemplateViewModel().isLoading(1);
    }

    /**
     * @inheritDoc
     */
    public deactivateLoading(){

        this.getTemplateViewModel().isLoading(0);
    }

    /**
     * Track specific log and identify its page on the normal list.
     *
     * @param logId
     */
    public onClickLogTrack(logId: number): any{

        this.getTemplateViewModel().trackedLogId(logId);
        this.resetSelectedOperationFilter();
        this.keywordSearch.getTemplateViewModel().filterKeyword("");
        this.caller.getListData();
    }

    public resetTrackedLogId(): void{

        this.getTemplateViewModel().trackedLogId(null);
    }

    /**
     * Handles reordering on click event.
     *
     * @param value
     * @param data
     * @param event
     */
    public onClickReorder(value, data, event){

        this.getTemplateViewModel().orderBy(value);

        if(this.getTemplateViewModel().orderByDirection() == "DESC"){

            this.getTemplateViewModel().orderByDirection("ASC");
        }
        else{

            this.getTemplateViewModel().orderByDirection("DESC");
        }

        this.getTemplateViewModel().orderByAndDirection(value + "_" + this.getTemplateViewModel().orderByDirection());
        this.caller.reorderLogs();
    }

    public setAccountFilterItems(accounts: Array<any>){

        accounts.unshift({"id" : 0, "name" : "all"});
        this.accountFilter.setOptions(accounts);
    }

    public setOperationFilterItems(operations: Array<any>){

        operations.unshift({"id" : 0, "name" : "all"});
        this.operationFilter.setOptions(operations);
    }

    public setPagination(totalCount: number){

        this.pagination.setNumberItemToPaginate(totalCount);
    }

    public setResultPage(totalCount: number){

        let resultsInfoToSet = {
            nbr_results: totalCount,
            nbr_pages: Math.ceil(totalCount /  this.getTemplateViewModel().pagination.itemPerPage),
            execution_time: "00,000",
        };

        this.resultsInfo.setResults(resultsInfoToSet);
    }

    /**
     * Sets the logs.
     *
     * @param logs
     */
    public setLogs(logs: Array<any>): void{

        let trackedLogId: number = this.getTemplateViewModel().trackedLogId();

        logs.forEach((log) => {

            log.highlight = (log.id == trackedLogId);
        });

        this.getTemplateViewModel().logs(logs);
    }

    public resetPageNumber(): void{

        this.getTemplateViewModel().pagination.pageNumber(1);
    }

    public resetSelectedAccountFilter(): void{

        this.accountFilter.setSelectedOption(0);
    }

    public resetSelectedOperationFilter(): void{

        this.operationFilter.setSelectedOption(0);
    }

    public resetOrderParameters(): void{

        this.getTemplateViewModel().orderBy("");
        this.getTemplateViewModel().orderByDirection("");
        this.getTemplateViewModel().orderByAndDirection("");
    }

    public setPageNumber(currentPage: number): void{

        this.pagination.getTemplateViewModel().pageNumber(currentPage);
    }
}