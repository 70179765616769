import {vnTemplate} from "./vnTemplate";

declare var ko: any;

export class validationsList_template extends vnTemplate{

    protected getTemplateHtml(): string{

        return `
            <div data-bind="css: { hide: isHidden }, style: {width: width()}" class="w-validation-errors">
                <div class="val-error-header">
                    <strong data-bind="text: msg" class="val-error-header-title icon i-notice-alert"></strong>
                    <button class="close-button icon i-close-2" type="button"></button>
                </div>
                
                <ul data-bind="foreach: messages" class="val-error-list">
                    <li class="val-error-item icon i-arrow-right-before" >
                        <span class="validation-label" data-bind="text: label" ></span>
                        <span class="validation-message" data-bind="html: message" ></span>
                    </li>
                </ul>
            </div>
            `;
    }

    protected getTemplateName(): string{
        return "validationList-template";
    }
    protected getMainElementType(): string{
        return "div";
    }
    protected getMainElementClasses(): Array<string>{
        return [];
    }

    protected buildViewModel(): any{

        // define the viewmodel data/functions
        return  {
            width: ko.observable('50em'),
            msg: ko.observable("There seems to be a problem"),
            messages: ko.observableArray([]),
            show: ko.observable("none"),
            isHidden: ko.observable(1)
        };
    }

    public setSingleMessage(message: {field: string, label: string, message: string}){
        this.getTemplateViewModel().messages([message]);
        this.getTemplateViewModel().isHidden(0);
    }

    clearMessages(){
        this.getTemplateViewModel().messages([]);
        this.getTemplateViewModel().msg("There seems to be a problem");
        this.getTemplateViewModel().isHidden(1);
    }

    setMessages(messages: Array<{field: string, label: string, messages:Array<string>}>): void{

        this.clearMessages();

        let allMessages = [];
        for(let m of messages){

            for(let mm of m.messages ){
                let tmp = { field: m.field, label:m.label, message: mm };
                allMessages.push(tmp)
            }
        }

        this.getTemplateViewModel().messages(allMessages);
        this.getTemplateViewModel().isHidden(0);
    }


    public setMessageUsingDefaultFormat(errors){


        this.clearMessages();
        this.getTemplateViewModel().msg(errors.message);

        if( errors.type == "validation" || errors.type == "error_validation"){

            let validations = [];
            for (let v of errors.validations) {

                for (let vm of v.messages) {

                    validations.push( {field: v.field, label: v.label, message: vm} );
                }

            }

            this.getTemplateViewModel().messages(validations);
        }

        this.getTemplateViewModel().isHidden(0);
    }

    public setWidth(width): void {
        this.getTemplateViewModel().width(width);
    }
}
