import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";
import {infoButton_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/infoButton_template";

declare var ko: any;

export class adUnits_template extends vnTemplate{

    // Constants.
    protected readonly ID_VIDEO_PLAYER_CONTEXT_INSTREAM  = 1;  
    protected readonly ID_VIDEO_PLAYER_CONTEXT_OUTSTREAM = 2;
    protected readonly ID_VIDEO_PLAYER_CONTEXT_COMPANION = 3;

    protected readonly ID_VIDEO_PLAYER_REQUEST_MODE_VIDEO   = 1;
    protected readonly ID_VIDEO_PLAYER_REQUEST_MODE_DISPLAY = 2;

    protected byTimeHelpInfoButtonContent = `
        <p>This sets the timing, which when met on the site, will trigger a refresh of the ad unit, on the next user scroll action of 1 pixel. Without the scroll action, no refresh will occur (this is to avoid spamming requests on an inactive page). This trigger condition is independent of the “By Distance” setting.</p>
    `;

    protected byTimePixelTriggerHelpInfoButtonContent = `
        <p>When checked will allow the ad unit to trigger a refresh after the specified time independent of a user scroll.</p>
    `;

    protected byDistanceHelpInfoButtonContent = `
        <p>This value is used to set trigger points, at equidistant intervals, from the top of the page. When a user scrolls past one of the trigger points, the ad unit will refresh. These points are set on page load and do not change. This trigger condition is independent of the “By Time” setting.</p>
    `;

    protected whenAdComesInviewHelpInfoButtonContent = `
        <p>whenAdComesInviewHelpInfoButtonContent</p>
    `;

    protected prefetchHelpInfoButtonContent = `
        <p>Allows the prefetching of the domain credentials, before the next refresh is triggered. This allows for small gains in speed for the refresh timing.</p>
    `;

    protected floatingPositionHelpInfoButtonContent = `
        <div style="display: inline-block">
            <video src="/assets/videos/floating-position-top-middle.mp4" autoplay loop muted style="width: 40%; float: left; margin: 0 10px 5px 0; margin-top: 10px;"></video>
            <br/>
            <p>Top Middle: The ad zone will be positioned to float at the top of the page, centered horizontally.</p>
        </div>
        <br/>
        <div style="display: inline-block">
            <video src="/assets/videos/floating-position-bottom-middle.mp4" autoplay loop muted style="width: 40%; float: left; margin: 0 10px 5px 0; margin-top: 10px;"></video>
            <br/>
            <p>Bottom Middle: The ad zone will be positioned to float at the bottom of the page, centered horizontally.</p>
        </div>
        <br/>
        <div style="display: inline-block">
            <video src="/assets/videos/floating-position-bottom-right.mp4" autoplay loop muted style="width: 40%; float: left; margin: 0 10px 5px 0; margin-top: 10px;"></video>
            <br/>
            <p>Bottom Right: The ad zone will be positioned to float at the bottom of the page, touching the right side of the page.</p>
        </div>
    `;

    protected floatingTypeHelpInfoButtonContent = `
        <div style="display: inline-block">
            <video src="/assets/videos/floating-ad-always.mp4" autoplay loop muted style="width: 40%; float: left; margin: 0 10px 5px 0; margin-top: 10px;"></video>
            <br/>
            <p>Always: The ad zone will load into the viewport immediately on page load and follow the user throughout the page as they scroll.</p>
        </div>
        <br/>
        <div style="display: inline-block">
            <video src="/assets/videos/Floating-ad-out-of-view.mp4" autoplay loop muted style="width: 40%; float: left; margin: 0 10px 5px 0; margin-top: 10px;"></video>
            <br/>
            <p>Out of View: The ad zone will originally load in at its specific placement on the webpage. Once the ad zone comes into view, the floating effect will be triggered, staying in the viewport as the user scrolls.  If the original placement of the ad zone comes back into view, the ad will snap back to its original placement.</p>
        </div>
    `;

    protected floatingOffsetHelpInfoButtonContent = `
        <p>This determines the pixel distance, in which the top of the ad zone element is ABOVE the top of the viewport. When this distance is reached, the ad zone element will be cleared and the ad will snap to its floating position. </p>
        <br/>
        <div style="display: inline-block">
            <video src="/assets/videos/floating-ad-offset-always.mp4" autoplay loop muted style="width: 40%; float: left; margin: 0 10px 5px 0; margin-top: 10px;"></video>
            <br/>
            <p>In the case of “Always”, this means the distance that needs to be scrolled before the ad will appear and continue to “always float”. </p>
        </div>
        <br/>
        <div style="display: inline-block">
            <video src="/assets/videos/floating-ad-offset-out-of-view.mp4" autoplay loop muted style="width: 40%; float: left; margin: 0 10px 5px 0; margin-top: 10px;"></video>
            <br/>
            <p>In the case of “Out of View”, this means the distance that needs to be scrolled as the ad falls out of view at the top of the window, before it will reappear, in its float position.</p>
        </div>
    `;

    protected floatingWidthAndHeightHelpInfoButtonContent = `
        <p>This setting only takes effect without of view type floating. These fields set and resize the pixel size of ad zone when floating activates. The pixel size set in other fields of the ad unit, will determine the size of the ad zone as it normally appears in view and are independent of floating size.</p>
    `;

    protected minimumTimeBufferHelpInfoButtonContent = `
        <p>This timing is used to ensure the "By Time" and/or "By Distance" trigger conditions cannot both trigger within a minimum interval of time.</p>
    `;

    protected styleHelpInfoButtonContent = `
        <p>None: No text will appear above the ad zone on the page.</p>
        <br/>
        <p>Advertisement: The standard ad disclaimer text, of the website, will appear above the ad zone.</p>
        <br/>
        <p>Custom: The custom text will appear as a disclaimer above the ad zone.</p>
    `;

    protected dynamicallyInjectedHelpInfoButtonContent = `
        <p>The ad unit code will only appear on the page once the ad zone comes into view.  The bidding process will not begin until after the zone is in view.</p>
    `;

    protected connectedBelowAdHelpInfoButtonContent = `
        <p>Check to included the connected below ad.</p>
        <br/>
        <p>Will inject the ad zone <i>connected below ad</i> below this ad unit if it has been set up. </p>
        <br/>
        <p>*Note: This can only be selected on one ad unit.</p>
        <p>The <i>connected below ad</i> unit will inherit the zone display type from the parent (this) ad unit.</p>
    `;

    protected stickySlideAnimationHelpInfoButtonContent = `
        <p>Sizes larger than the ad will trigger a slide animation.</p>
        <br/>
        <p>Uncheck to remove the sticky slide animation.</p>
    `;

    protected interscrollerAnimationHelpInfoButtonContent = `
        <p>Provides the user with the experience that the content is reaving the ads while scrolling.</p>
        <br/>
        <p>Uncheck to remove this animation.</p>
    `;

    protected doNotUseSpaceHelpInfoButtonContent = `
        <p>When checked will allow the ad unit to take up no space on the page.</p>
    `;

    protected useAutoScrollHelpInfoButtonContent = `
        <p>When checked will allow the ad unit to scroll in its container with the page if an ad is larger than it's container.</p>
    `;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="w-ads-unit-page" data-bind="css: { 'is-fixed' : isFixed }">
                <!-- ko with: adsSetup -->
                <div class="zone-list">
                    <div class="zone-list-header">
                    
                        <div>
                            <h3 data-bind="text: name"></h3>
                            <button class="btn icon i-edit" type="button" data-bind="event: { click: $parent.onClickOpenZoneManagement }"><span>Manage Ad Zones</span></button>
                        </div>
                        
                        <div>
                            <!-- <button class="btn icon i-edit" type="button" data-bind="event: { click: $parent.onClickOpenAdsSetup }">Edit Ads Setup</button> -->
                            <button class="btn-action icon i-cancel-thin" type="button" data-bind="event: { click: $parent.onClickCancel }"><span>Cancel</span></button>
                            <button class="btn-action icon i-save-thin" type="button" data-bind="event: { click: $parent.onClickSave }"><span>Save Ads Setup</span></button>  
                        </div>
                        
                    </div>
                    <!-- ko foreach: zoneGroups -->
                    <div class="zone-list-group">
                        <strong class="icon" data-bind="text: name, attr: { 'data-content': name }"></strong>
                    </div>
                    <!-- ko foreach: zones -->
                    <div class="zone-item" data-bind="hidden: isDeleted, css: { 'is-open': isOpen, 'is-option-open': isOptionOpen, 'is-child': isChild, 'is-secondary': isSecondary, 'zone-item-repeatable': isRepeatable }, style: { 'padding-left': isChildOrSecondary ? '30px' : '0px' }">
                        <div>
                            <div class="zone-item-header">
                                
                                <div data-bind="event: { click: $parents[2].onClickOpenCloseZone}">
                                    <button class="btn icon-only no-bg icon i-dropdown-simple" type="button"><span>Open/close</span></button>
                                    <strong class="zone-item-header-title" data-bind="text: displayName"></strong>
                                    <div data-bind="if: isChild">
                                        <select data-bind="
                                            options: $root.adUnits_template.zonePositionTypes,
                                            optionsText: 'name',
                                            optionsValue: 'id',
                                            value: idZonePositionType,
                                            attr: { 'id': 'zonePositionType_' + id, 'name': 'zonePositionType_' + id }
                                        ">
                                        </select>
                                    </div> 
                                </div>
                                <div data-bind="if: isChild">
                                    <label class="form-label icon i-position"></label>
                                    <input type="number" class="input-text num" style="flex: none;" min="0" step="1" data-bind="value: position">
                                    
                                    
                                </div>
                                <span data-bind="text: adUnitsCount() + ' unit(s)'"></span>
                                <span data-bind="text: activeAdUnitsCount() + ' active'"></span>  
                                
                                <select data-bind="
                                    options: $root.adUnits_template.zoneDisplayTypes,
                                    optionsText: 'description',
                                    optionsValue: 'id',
                                    value: idZoneDisplayType,
                                    attr: { 'id': 'zoneDisplayType_' + id, 'name': 'zoneDisplayType_' + id }
                                ">
                                </select>     
                                
                                <button class="btn-list icon i-add" type="button" data-bind="event: { click: $parents[2].onClickAddNewAdUnit}">Add Unit</button>    
                                
                                <!-- ko if: isRepeatable -->
                                    <!-- ko ifnot: isFrontEndInjectable -->
                                        <button class="btn-list icon i-child" type="button" data-bind="event: { click: $parents[2].onClickAddChildZone}">Add Child Zone</button> 
                                    <!-- /ko -->  
                                <!-- /ko -->   
                                              
                                <!-- ko if: isChild -->    
                                    <span class="spacer"></span>             
                                    <button class="btn-list alert icon i-delete " type="button" data-bind="event: { click: $parents[2].onClickRemoveChildZone}">Remove Child Zone</button>
                                <!-- /ko -->

                                <!-- ko if: idSecondary -->    
                                <div class="form-sentence fix-width">
                                    <input type="checkbox" data-bind="checked: secondaryEnabled, attr:{ 'id': 'secondaryEnabled_' + id, 'name': 'secondaryEnabled_' + id }">
                                    <label class="form-label icon i-check title" data-bind="attr:{ 'for': 'secondaryEnabled_' + id }">Secondary</label>   
                                </div>
                                <!-- /ko -->
                            </div>  
                             <!-- ko if: repeatableOptions -->     
                             <div class="zone-item-header-option">
                                <div class="preview">
                                    <button class="btn-list secondary icon i-dropdown-simple" type="button" data-bind="event: { click: $parents[2].onEditZoneSettings}">Edit Settings</button>
                                    
                                    <div data-bind="if: repeatableOptions.type">
                                        <p>Type:<span data-bind="text: repeatableOptions.type"></span>.</p>
                                    </div>
                                    <div class="form-sentence" data-bind="visible: repeatableOptions.isTypeCount()">
                                        <p>Insert Every<span data-bind="text: repeatableOptions.count"></span><span data-bind="text: repeatableOptions.countType() + 's'"></span>starting from placement number<span data-bind="text: repeatableOptions.startingPoint"></span>.</p>
                                        <p data-bind="visible: repeatableOptions.showSkipEvery()">Skip Every<span data-bind="text: repeatableOptions.skipEvery"></span>placement(s).</p>
                                        <p data-bind="visible: repeatableOptions.showDegradation()">Degradation: Insert Every<span data-bind="text: repeatableOptions.degradationCount"></span><span data-bind="text: repeatableOptions.countType() + 's'"></span>starting from placement number<span data-bind="text: repeatableOptions.degradationStartingPoint"></span>.</p>
                                        <p data-bind="visible: repeatableOptions.showStopAds()">Stop Ads after placement <span data-bind="text: repeatableOptions.stopAds"></span>.</p>
                                    </div>
                                    <div class="form-sentence" data-bind="visible: repeatableOptions.isTypeEntity()">
                                        <p>Insert<span data-bind="text: repeatableOptions.placement"></span>Every<span data-bind="text: repeatableOptions.htmlTag"></span>starting from placement number<span data-bind="text: repeatableOptions.startingPoint"></span>.</p>
                                        <p data-bind="visible: repeatableOptions.showSkipEvery()">Skip Every<span data-bind="text: repeatableOptions.skipEvery"></span>placement(s).</p>
                                        <p data-bind="visible: repeatableOptions.showDegradation()">Insert every<span data-bind="text: repeatableOptions.degradationEntityCount"></span>placement(s) starting from number<span data-bind="text: repeatableOptions.degradationStartingPoint"></span>.</p>
                                        <p data-bind="visible: repeatableOptions.showStopAds()">Stop Ads after placement <span data-bind="text: repeatableOptions.stopAds"></span>.</p>
                                    </div>
                                    <div class="form-sentence" data-bind="visible: repeatableOptions.isTypeStickyRail()">
                                        <p>Insert one after another. With a <span data-bind="text: repeatableOptions.railItemMargin"></span> pixel margin between ads.</p>
                                    </div>
                                </div>
                                <div class="edit">                   
                                    <div class="form-sentence">
                                        <div class="form-sentence fix-width"> 
                                            <label class="form-label">Type</label>
                                            <select data-bind="
                                                options: $root.adUnits_template.repeatableTypes,
                                                optionsText: 'name',
                                                optionsValue: 'id',
                                                value: repeatableOptions.type,
                                                attr: { 'id': 'type_' + repeatableOptions.idHelper, 'name': 'type_' + repeatableOptions.idHelper }
                                            ">
                                            </select> 
                                        </div>   
                                        <div class="form-sentence" data-bind="visible: repeatableOptions.isTypeCount()">
                                            <label class="form-label fix-width">Insert every </label>
                                            <input type="number" class="input-text num" min="2" data-bind="
                                                value: repeatableOptions.count, 
                                                attr: { 'id': 'count_' + repeatableOptions.idHelper, 'name': 'count_' + repeatableOptions.idHelper }
                                            "> 
                                            <select data-bind="
                                                options: $root.adUnits_template.repeatableCountTypes,
                                                optionsText: 'name',
                                                optionsValue: 'id',
                                                value: repeatableOptions.countType,
                                                attr: { 'id': 'countType_' + repeatableOptions.idHelper, 'name': 'countType_' + repeatableOptions.idHelper }
                                            ">
                                            </select> 
                                        </div>
                                        <div class="form-sentence" data-bind="visible: repeatableOptions.isTypeEntity()">
                                            <label class="form-label fix-width">Insert </label>
                                            <select data-bind="
                                                options: $root.adUnits_template.repeatablePlacementTypes,
                                                optionsText: 'name',
                                                optionsValue: 'id',
                                                value: repeatableOptions.placement,
                                                attr: { 'id': 'placementType_' + repeatableOptions.idHelper, 'name': 'placementType_' + repeatableOptions.idHelper }
                                            ">
                                            </select> 
                                            <label class="form-label" style="width: auto; margin-left: 0">Every </label>
                                            <select data-bind="
                                                options: $root.adUnits_template.repeatableHtmlTags,
                                                optionsText: 'name',
                                                optionsValue: 'id',
                                                value: repeatableOptions.htmlTag,
                                                attr: { 'id': 'htmlTag_' + repeatableOptions.idHelper, 'name': 'htmlTag_' + repeatableOptions.idHelper }
                                            ">
                                            </select>
                                            <label class="form-label" style="width: auto; margin-left: 0" data-bind="hidden: repeatableOptions.htmlTag() != 'custom'">Open </label>
                                            <input class="input-text" data-bind="
                                                hidden: repeatableOptions.htmlTag() != 'custom',
                                                value: repeatableOptions.customOpenHtmlTag, 
                                                attr: { 'id': 'customOpenHtmlTag_' + repeatableOptions.idHelper, 'name': 'customOpenHtmlTag_' + repeatableOptions.idHelper }
                                            ">   
                                            <label class="form-label" style="width: auto; margin-left: 0" data-bind="hidden: repeatableOptions.htmlTag() != 'custom'">Close </label>                 
                                            <input class="input-text" data-bind="
                                                hidden: repeatableOptions.htmlTag() != 'custom',
                                                value: repeatableOptions.customCloseHtmlTag, 
                                                attr: { 'id': 'customCloseHtmlTag_' + repeatableOptions.idHelper, 'name': 'customCloseHtmlTag_' + repeatableOptions.idHelper }
                                            ">
                                        </div>
                                        <div class="form-sentence" data-bind="visible: !repeatableOptions.isTypeStickyRail()"> 
                                            <label class="form-label" style="width: auto;">starting from placement number</label>
                                            <input type="number" class="input-text num" min="1" data-bind="
                                                value: repeatableOptions.startingPoint, 
                                                attr: { 'id': 'startingPoint_' + repeatableOptions.idHelper, 'name': 'startingPoint_' + repeatableOptions.idHelper }
                                            ">
                                        </div>  
                                        <div class="form-sentence" data-bind="visible: repeatableOptions.isTypeStickyRail()">
                                            <div class="form-sentence">
                                                <label class="form-label">Insert one after another. With a </label>
                                                <input type="number" class="input-text num" min="0" data-bind="
                                                    value: repeatableOptions.railItemMargin, 
                                                    attr: { 'id': 'railItemMargin_' + repeatableOptions.idHelper, 'name': 'railItemMargin_' + repeatableOptions.idHelper }
                                                "> 
                                                <label class="form-label">pixel margin between ads. </label>
                                            </div>
                                        </div>
                                    </div>  
                                    <div class="form-sentence" data-bind="visible: !repeatableOptions.isTypeStickyRail()">
                                        <div class="form-sentence fix-width">
                                            <input type="checkbox" data-bind="checked: repeatableOptions.showSkipEvery, attr:{ 'id': 'showSkipEvery_' + repeatableOptions.idHelper, 'name': 'showSkipEvery_' + repeatableOptions.idHelper }">
                                            <label class="form-label icon i-check title" data-bind="attr:{ 'for': 'showSkipEvery_' + repeatableOptions.idHelper }">Skip Ads</label>   
                                        </div>
                                        <div class="form-sentence" data-bind="visible: repeatableOptions.showSkipEvery">                                 
                                            <label class="form-label fix-width" data-bind="attr:{ 'for': 'skipEvery_' + repeatableOptions.idHelper }">Skip every </label>
                                            <input type="number" class="input-text num" min="2" data-bind="
                                                value: repeatableOptions.skipEvery, 
                                                attr: { 'id': 'skipEvery_' + repeatableOptions.idHelper, 'name': 'skipEvery_' + repeatableOptions.idHelper }
                                            "> 
                                            <label class="form-label" data-bind="attr:{ 'for': 'skipEvery_' + repeatableOptions.idHelper }">placement(s)</label>                                          
                                        </div>
                                    </div>
                                    <div class="form-sentence" data-bind="visible: repeatableOptions.isTypeStickyRail()">
                                    </div>
                                    <div class="form-sentence" data-bind="visible: repeatableOptions.isTypeCount()">
                                                                       
                                        <div class="form-sentence fix-width"> 
                                            <input type="checkbox" data-bind="checked: repeatableOptions.showDegradation, attr:{ 'id': 'showDegradation_' + repeatableOptions.idHelper, 'name': 'showDegradation_' + repeatableOptions.idHelper }">
                                            <label class="form-label icon i-check title" data-bind="attr:{ 'for': 'showDegradation_' + repeatableOptions.idHelper }">Degradation</label>
                                        </div>
                                        <div class="form-sentence" data-bind="visible: repeatableOptions.showDegradation">
                                            <label class="form-label fix-width">Insert every </label> 
                                            <input type="number" class="input-text num" min="2" data-bind="
                                                value: repeatableOptions.degradationCount, 
                                                attr: { 'id': 'degradationCount_' + repeatableOptions.idHelper, 'name': 'degradationCount_' + repeatableOptions.idHelper }
                                            "> 
                                            <select data-bind="
                                                options: $root.adUnits_template.repeatableCountTypes,
                                                optionsText: 'name',
                                                optionsValue: 'id',
                                                value: repeatableOptions.countType,
                                                attr: { 'id': 'countType_2_' + repeatableOptions.idHelper, 'name': 'countType_2_' + repeatableOptions.idHelper }
                                            ">
                                            </select> 
                                            <label class="form-label">starting from placement number </label>    
                                            <input type="number" class="input-text num" min="1" data-bind="
                                                value: repeatableOptions.degradationStartingPoint, 
                                                attr:{ 'id': 'startingPoint_' + repeatableOptions.idHelper, 'name': 'startingPoint_' + repeatableOptions.idHelper }" 
                                            >
                                        </div>                                     
                                    </div>
                                    <div class="form-sentence" data-bind="visible: repeatableOptions.isTypeEntity()">
                                        <div class="form-sentence fix-width"> 
                                            <input type="checkbox" data-bind="checked: repeatableOptions.showDegradation, attr:{ 'id': 'showDegradation_' + repeatableOptions.idHelper, 'name': 'showDegradation_' + repeatableOptions.idHelper }">
                                            <label class="form-label icon i-check title" data-bind="attr:{ 'for': 'showDegradation_' + repeatableOptions.idHelper }">Degradation</label>
                                        </div>
                                        <div class="form-sentence" data-bind="visible: repeatableOptions.showDegradation">
                                            <label class="form-label fix-width">Insert every</label>
                                            <input type="number" class="input-text num" min="1" data-bind="
                                                value: repeatableOptions.degradationEntityCount, 
                                                attr:{ 'id': 'degradationEntityCount_' + repeatableOptions.idHelper, 'name': 'degradationEntityCount_' + repeatableOptions.idHelper }" 
                                            >
                                            <label class="form-label">placements starting from number </label>    
                                            <input type="number" class="input-text num" min="1" data-bind="
                                                value: repeatableOptions.degradationStartingPoint, 
                                                attr:{ 'id': 'startingPoint_' + repeatableOptions.idHelper, 'name': 'startingPoint_' + repeatableOptions.idHelper }" 
                                            >
                                        </div> 
                                     
                                    </div>       
                                    <div class="form-sentence" data-bind="visible: !repeatableOptions.isTypeStickyRail()">
                                        <div class="form-sentence fix-width">
                                            <input type="checkbox" data-bind="checked: repeatableOptions.showStopAds, attr:{ 'id': 'showStopAds_' + repeatableOptions.idHelper, 'name': 'showStopAds_' + repeatableOptions.idHelper }">
                                            <label class="form-label icon i-check title" data-bind="attr:{ 'for': 'showStopAds_' + repeatableOptions.idHelper }">Stop Ads</label>
                                        </div>
                                        <div class="form-sentence" data-bind="visible: repeatableOptions.showStopAds">
                                            <label class="form-label">As of placement</label>    
                                            <input type="number" class="input-text num" min="1" data-bind="
                                                value: repeatableOptions.stopAds, 
                                                attr:{ 'id': 'endingPoint_' + repeatableOptions.idHelper, 'name': 'endingPoint_' + repeatableOptions.idHelper }" 
                                            >
                                        </div>
                                    </div>      
                                </div>
                            </div>
                            <!-- /ko -->
                            <div class="ads-unit-list" data-bind="foreach: adUnits">
                                <div class="ads-unit-item" data-bind="css: { 'is-deactivated': !status(), 'is-deleted': isDeleted() }">
                                
                                    <div class="ads-unit-side">
                                        <!-- <button type="button" class="icon i-on-off" data-bind="css: { 'is-activated' : status() }, event: { click: $parents[2].onClickChangeStatusAdUnit}" ><span data-bind = "text: !status() ? 'activate' : 'deactivate'"></span></button> -->
                                        <button type="button" title="activate" class="icon i-on-off" data-bind="event: { click: $parents[3].onClickChangeStatusAdUnit}" ><span data-bind = "text: !status() ? 'activate' : 'deactivate'"></span></button>
                                        <input id="ratio" title="ratio" type="text" class="input-text" placeholder="ratio" data-bind="
                                            value: ratio,
                                            attr: {'id' : 'ratio_' + order, 'name' : 'ratio_' + order }
                                            "
                                        >
                                        <button type="button" title="copy" class="icon i-copy"  data-bind="event: { click: $parents[3].onClickCopyAdUnit}"><span>Copy</span></button>
                                        <button type="button" title="delete" class="icon i-delete" data-bind="event: { click: $parents[3].onClickDeleteAdUnit}"><span data-bind = "text: isDeleted() ? 'undelete' : 'delete'">>Delete</span></button>
                                    </div>
                                    
                                    <div class="w-ads-unit-header">
                                    
                                        <div class="ads-unit-header full-width">
                                            <div class="form-group row">
                                                <label class="form-label">Name</label>
                                                <input type="text" class="input-text" data-bind="value: name">                                       
                                            </div>
                                        </div>
                                        
                                        <div class="ads-unit-header full-width">
                                             <div class="form-group row">
                                                <label class="form-label">Slot Name</label>
                                                <input type="text" class="input-text" data-bind="value: slotName">                                       
                                            </div>
                                        </div>                                       
                                        
                                        <div class="ads-unit-header full-width">
                                            <div class="form-group row">
                                                <label class="form-label">DIV ID</label>
                                                <input type="text" class="input-text" data-bind="value: divId">                                       
                                            </div>
                                        </div>
                               
                                        <div class="ads-unit-header full-width">
                                            <div class="form-group row">
                                                <label class="form-label">Sizes</label>
                                                <input type="text" class="input-text" data-bind="value: sizeArray">                                       
                                            </div>
                                        </div>
                                        
                                        <div class="ads-unit-header full-width">
                                            <div class="form-group row">
                                                <label class="form-label">Positions</label>
                                                <input type="text" class="input-text" data-bind="value: positionsName">                                       
                                            </div>
                                        </div>
                                        
                                        <div class="ads-unit-header">                                     
                                            <div class="form-group row"> <!--  is-disable -->
                                                <label class="form-label">Dimensions</label>
                                                <select data-bind="
                                                options: $root.adUnits_template.dimensions,
                                                optionsText: 'name',
                                                optionsValue: 'id',
                                                value: idDimension,
                                                attr: {'id' : 'dimension_' + order, 'name' : 'dimension_' + order }
                                                ">
                                                </select>   
                                            </div>                                                
                                        </div>
                                        
                                        <div class="ads-unit-header">                                        
                                            <div class="form-group row">
                                                <label class="form-label">Type</label>
                                                <select data-bind="
                                                options: $root.adUnits_template.adTypes,
                                                optionsText: 'name',
                                                optionsValue: 'id',
                                                value: idAdType,
                                                attr: {'id' : 'adType_' + order, 'name' : 'adType_' + order }
                                                ">
                                                </select> 
                                            </div>                                            
                                        </div>
                                        
                                        <div class="ads-unit-header" data-bind="visible: useCustomDimensions">                                           
                                            <div class="form-group row">
                                                <label class="form-label">Width</label>
                                                <input type="text" class="input-text num" data-bind="value: customDimensions().width">           
                                                <select data-bind="options: $root.adUnits_template.sizePostfixes, optionsText: 'name', optionsValue: 'id', value: customDimensions().widthPostfix"></select>                               
                                            </div>                                                               
                                        </div>
                                        
                                        <div class="ads-unit-header" data-bind="visible: useCustomDimensions">      
                                            <div class="form-group row">
                                                <label class="form-label">Height</label>
                                                <input type="text" class="input-text num" data-bind="value: customDimensions().height">     
                                                <select data-bind="options: $root.adUnits_template.sizePostfixes, optionsText: 'name', optionsValue: 'id', value: customDimensions().heightPostfix"></select>                                     
                                            </div>
                                        </div>
                                        
                                        <div class="ads-unit-header full-width">
                                            <div class="form-group">
                                                <div class=form-check-inline" style="display: flex">
                                                    <input type="checkbox" data-bind="checked: doNotUseSpace, attr: {'id': 'doNotUseSpace_' + order, 'name': 'doNotUseSpace_' + order }">
                                                    <label style="margin-left: 7.5rem;" class="form-label icon i-check" data-bind="attr: {'for' : 'doNotUseSpace_' + order }">Don't use space</label>    
                                                    <div class="w-tooltips-adv" style="margin-left: 8rem;" data-bind="template: { name: 'infoButton-template', data: $data.doNotUseSpaceHelpInfoButton.getTemplateViewModel() }"></div>
                                                </div>                                            
                                            </div>                                            
                                        </div>
                                        
                                         <div class="ads-unit-header full-width">
                                            <div class="form-group">
                                                <div class=form-check-inline" style="display: flex">
                                                    <input type="checkbox" data-bind="disable: disableStickySlideAnimation, checked: useStickySlideAnimation, attr: {'id': 'useStickySlideAnimation_' + order, 'name': 'useStickySlideAnimation_' + order }">
                                                    <label style="margin-left: 7.5rem;" class="form-label icon i-check" data-bind="attr: {'for' : 'useStickySlideAnimation_' + order }">Use sticky "slide" animation</label>    
                                                    <div class="w-tooltips-adv" style="margin-left: 8rem;" data-bind="template: { name: 'infoButton-template', data: $data.stickySlideAnimationHelpInfoButton.getTemplateViewModel() }"></div>
                                                </div>                                            
                                            </div>                                            
                                        </div>
                                        <div class="ads-unit-header full-width">
                                            <div class="form-group">
                                                <div class=form-check-inline" style="display: flex">
                                                    <input type="checkbox" data-bind="disable: disableInterscrollerAnimation, checked: useInterscrollerAnimation, attr: {'id': 'useInterscrollerAnimation_' + order, 'name': 'useInterscrollerAnimation_' + order }">
                                                    <label style="margin-left: 7.5rem;" class="form-label icon i-check" data-bind="attr: {'for' : 'useInterscrollerAnimation_' + order }">Use interscroller animation</label>    
                                                    <div class="w-tooltips-adv" style="margin-left: 8rem;" data-bind="template: { name: 'infoButton-template', data: $data.interscrollerAnimationHelpInfoButton.getTemplateViewModel() }"></div>
                                                </div>                                            
                                            </div>                                            
                                        </div>

                                        <div class="ads-unit-header full-width">
                                            <div class="form-group">
                                                <div class=form-check-inline" style="display: flex">
                                                    <input type="checkbox" data-bind="disable: disableAutoScroll, checked: useAutoScroll, attr: {'id': 'useAutoScroll_' + order, 'name': 'useAutoScroll_' + order }">
                                                    <label style="margin-left: 7.5rem;" class="form-label icon i-check" data-bind="attr: {'for' : 'useAutoScroll_' + order }">Use AutoScroll Animation</label>
                                                    <div class="w-tooltips-adv" style="margin-left: 8rem;" data-bind="template: { name: 'infoButton-template', data: $data.useAutoScrollHelpInfoButton.getTemplateViewModel() }"></div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="ads-unit-header full-width border-top" style="margin-bottom: 0">                                        
                                            <div class="form-group row" style="margin-bottom: .5rem"> 
                                                <legend class="form-legend off-style " data-bind="css: { 'is-activated': idAuctionsManager }">Auctions Manager</legend>
                                                <div data-bind="foreach: $root.adUnits_template.auctionsManagers">
                                                    <div class="form-check-inline" style="width: auto;margin-right: 2rem;"> 
                                                        <input type="radio" data-bind="checked: $parent.idAuctionsManager, value: id, attr:{'id': 'auctionsManager_' + $parent.order + '_' + id, 'name': 'auctionsManager_' + $parent.order}">
                                                        <label class="form-label" style="width: auto" data-bind="text: name, attr:{'for': 'auctionsManager_' + $parent.order + '_' + id}"></label>
                                                    </div>
                                                </div>
                                            </div>                                            
                                        </div>
                                        
                                        <div class="w-ads-unit-header-sub" data-bind="visible: useAuctionsManager">
                                            <div class="form-group row">
                                                <legend class="form-legend" style="margin-bottom: .5rem;margin-right: 2rem;">Bidder Types</legend>
                                                <div data-bind="foreach: $root.adUnits_template.allBidderTypes">
                                                    <div class="form-check-inline"> 
                                                        <input type="checkbox" data-bind="checked: $parent.bidderTypes, value: id, attr:{'id' : 'bidderType_' + $parent.order + '_' + id, 'name' : 'bidderType_' + $parent.order}">
                                                        <label class="form-label icon i-check" data-bind="text: name, attr:{'for': 'bidderType_' + $parent.order + '_' + id}"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                  
                                        <div class="ads-unit-header full-width border-top">
                                            <div class="form-group no-margin">
                                                <div class="form-check-inline" style="display: flex"> 
                                                    <input type="checkbox" data-bind="checked: isDynamicallyInjected, attr: {'id': 'isDynamicallyInjected_' + order, 'name': 'isDynamicallyInjected_' + order }">
                                                    <label class="form-label icon i-check" data-bind="attr: {'for' : 'isDynamicallyInjected_' + order }">Dynamically Injected</label>    
                                                    <div class="w-tooltips-adv" style="margin-left: 4rem;" data-bind="template: { name: 'infoButton-template', data: $data.dynamicallyInjectedHelpInfoButton.getTemplateViewModel() }"></div>                                   
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Style Section Start -->
                                        <div class="ads-unit-header full-width border-top">
                                            <div class="form-group no-margin">
                                                <div class="form-check-inline no-margin"> 
                                                    <input type="checkbox" data-bind="checked: hasStyle, attr: {'id': 'hasStyle_' + order, 'name': 'hasStyle_' + order }">
                                                    <label class="form-label icon i-check title" data-bind="attr: {'for' : 'hasStyle_' + order }">Style</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-bind="visible: hasStyle" class="w-ads-unit-header-sub">
                                            <div class="ads-unit-header sub" >
                                                <div class="form-group row">
                                                    <label class="form-label wrap">Advertiser Tag</label>
                                                    <div style="display: flex;align-items: center">
                                                        <select data-bind="options: $parents[3].styleAdvertiserTags, optionsText: 'name', optionsValue: 'id', value: styleOptions.idStyleAdvertiserTag"></select>   
                                                        <div class="w-tooltips-adv" data-bind="template: { name: 'infoButton-template', data: $data.styleHelpInfoButton.getTemplateViewModel() }"></div>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class=form-check-inline no-margin">
                                                        <input type="checkbox" data-bind="checked: styleOptions.adUnitBackgroundDetailsEnabled, attr: {'id': 'adUnitBackgroundDetailsEnabled_' + order, 'name': 'adUnitBackgroundDetailsEnabled_' + order }">
                                                        <label class="form-label icon i-check" data-bind="attr: {'for' : 'adUnitBackgroundDetailsEnabled_' + order }">Ad Unit Background Details Enabled</label>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <div class=form-check-inline no-margin">
                                                        <input type="checkbox" data-bind="checked: styleOptions.adUnitLoadingAnimationEnabled, attr: {'id': 'adUnitLoadingAnimationEnabled_' + order, 'name': 'adUnitLoadingAnimationEnabled_' + order }">
                                                        <label class="form-label icon i-check" data-bind="attr: {'for' : 'adUnitLoadingAnimationEnabled_' + order }">Ad Unit Loading Animation Enabled</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ads-unit-header " >
                                                <div class="form-group row">
                                                    <div class="form-group row" data-bind="visible: styleOptions.isCustom">
                                                        <label class="form-label wrap width">Custom Border Text</label>
                                                        <input type="text" class="input-text" data-bind="value: styleOptions.customAdvertiserTag">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Style Section End -->
                                        
                                        <!-- Video Section Start -->
                                        <div class="ads-unit-header full-width border-top">
                                            <div class="form-group no-margin">
                                                <div class="form-check-inline no-margin"> 
                                                    <input type="checkbox" data-bind="checked: couldBeVideo, attr: {'id': 'couldBeVideo_' + order, 'name': 'couldBeVideo_' + order }">
                                                    <label class="form-label icon i-check title" data-bind="attr: {'for' : 'couldBeVideo_' + order }">Video</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-bind="visible: couldBeVideo" class="w-ads-unit-header-sub">
                                            <div class="ads-unit-header sub" >
                                                <div class="form-group row">
                                                    <label class="form-label wrap">Request Mode</label>
                                                    <div style="display: flex;align-items: center">
                                                        <select data-bind="options: videoRequestModes, optionsText: 'name', optionsValue: 'id', value: videoOptions.requestMode.id"></select>   
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="form-label wrap">Context</label>
                                                    <div style="display: flex;align-items: center">
                                                        <select data-bind="options: videoContexts, optionsText: 'name', optionsValue: 'id', enable: videoOptions.isVideoRequestModeVideo, value: videoOptions.context.id"></select>   
                                                    </div>
                                                </div>
                                                <div class="form-group no-margin" data-bind="visible: videoOptions.isVideoRequestModeVideo">
                                                    <div class=form-check-inline no-margin">
                                                        <input type="checkbox" data-bind="disable: disableIncludeDefaultPlaylist, checked: videoOptions.allowAutoplay, attr: {'id': 'allowAutoplay_' + order, 'name': 'allowAutoplay_' + order }">
                                                        <label class="form-label icon i-check" data-bind="attr: {'for' : 'allowAutoplay_' + order }">Autoplays</label>    
                                                    </div>      
                                                    <div class=form-check-inline no-margin">
                                                        <input type="checkbox" data-bind="checked: videoOptions.showTitle, attr: {'id': 'showTitle_' + order, 'name': 'showTitle_' + order }">
                                                        <label class="form-label icon i-check" data-bind="attr: {'for' : 'showTitle_' + order }">Show Title</label>    
                                                    </div>
                                                    <div class=form-check-inline no-margin">
                                                        <input type="checkbox" data-bind="checked: videoOptions.soundOn, disable: ($root.adUnits_template.device() === 'desktop' && $parents[0].name === 'Instream-Feature') ? false : videoOptions.allowAutoplay(), attr: {'id': 'soundOn_' + order, 'name': 'soundOn_' + order }">
                                                        <label class="form-label icon i-check" data-bind="attr: {'for' : 'soundOn_' + order }">Sound On</label>   
                                                        
                                                    </div>
                                                    <div class=form-check-inline no-margin">
                                                        <input type="checkbox" data-bind="disable: disableIncludeDefaultPlaylist, checked: videoOptions.includeDefaultPlaylist, attr: {'id': 'includeDefaultPlaylist_' + order, 'name': 'includeDefaultPlaylist_' + order }">
                                                        <label class="form-label icon i-check" data-bind="attr: {'for' : 'includeDefaultPlaylist_' + order }">Include Default Playlist</label>
                                                    </div>
                                                    <div class="form-check-inline no-margin">
                                                        <input type="checkbox" data-bind="checked: videoOptions.shouldDelayAfterAdSkipped, attr: {'id': 'shouldDelayAfterAdSkipped_' + order, 'name': 'shouldDelayAfterAdSkipped_' + order }">
                                                        <label class="form-label icon i-check" data-bind="attr: {'for' : 'shouldDelayAfterAdSkipped_' + order }">Should Delay After Ad Skipped</label>
                                                    </div>
                                                    <div data-bind="visible: videoOptions.shouldDelayAfterAdSkipped">
                                                        <div class="form-group row">
                                                            <label class="form-label wrap" style="flex:1">Delay After Ad Skipped (ms)</label>
                                                            <input type="number" class="input-text num" style="flex: none;" data-bind="value: videoOptions.delayAfterAdSkippedInMilliseconds">
                                                        </div>
                                                    </div>
                                                    <div class="form-check-inline no-margin">
                                                        <input type="checkbox" data-bind="checked: videoOptions.useLargeSoundToggleButton, attr: {'id': 'useLargeSoundToggleButton_' + order, 'name': 'useLargeSoundToggleButton_' + order }">
                                                        <label class="form-label icon i-check" data-bind="attr: {'for' : 'useLargeSoundToggleButton_' + order }">Use Large Sound Toggle Button</label>
                                                    </div>
                                                </div>                                         
                                            </div> 
                                        </div>
                                        <!-- Video Section End -->
                                        
                                        <!-- Floating Section Start -->
                                        <div class="ads-unit-header full-width border-top">
                                            <div class="form-group no-margin">
                                                <div class="form-check-inline no-margin"> 
                                                    <input type="checkbox" data-bind="checked: shouldFloat, attr: {'id': 'shouldFloat_' + order, 'name': 'shouldFloat_' + order }">
                                                    <label class="form-label icon i-check title" data-bind="attr: {'for' : 'shouldFloat_' + order }">Floating</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="ads-unit-header sub" data-bind="visible: shouldFloat">
                                            <div class="form-group row">                                                
                                                <label class="form-label">Type</label>
                                                <div style="display: flex;align-items: center">
                                                    <select style="width: 14rem;flex:none;" data-bind="options: floatingModes, optionsText: 'name', optionsValue: 'id', value: floatingOptions.floatingMode"></select>
                                                    <div class="w-tooltips-adv" style="margin-left: -1rem" data-bind="template: { name: 'infoButton-template', data: floatingTypeHelpInfoButton.getTemplateViewModel() }"></div>
                                                </div>
                                            </div>  
                                            <div class="form-group row">
                                                <label class="form-label">Position</label> 
                                                <div style="display: flex;align-items: center">
                                                    <select style="width: 14rem;flex:none;" data-bind="options: floatingPositions, optionsText: 'name', optionsValue: 'id', value: floatingOptions.position"></select>
                                                    <div class="w-tooltips-adv" style="margin-left: -1rem" data-bind="template: { name: 'infoButton-template', data: $data.floatingPositionHelpInfoButton.getTemplateViewModel() }"></div>
                                                </div>
                                            </div>   
                                            <div class="form-group no-margin">
                                                <div class="form-check-inline no-margin"> 
                                                    <input type="checkbox" data-bind="checked: floatingOptions.useCloseButton, attr: {'id': 'useCloseButton_' + order, 'name': 'useCloseButton_' + order }">
                                                    <label class="form-label icon i-check" data-bind="attr: {'for' : 'useCloseButton_' + order }">Add Close Button</label>
                                                </div>
                                            </div>
                                            <div class="form-group no-margin">
                                                <div class="form-check-inline no-margin"> 
                                                    <input type="checkbox" data-bind="checked: floatingOptions.clickToFloat, attr: {'id': 'clickToFloat_' + order, 'name': 'clickToFloat_' + order }">
                                                    <label class="form-label icon i-check" data-bind="attr: {'for' : 'clickToFloat_' + order }">Click to Float</label>
                                                </div>
                                            </div>
                                            <div class="form-group no-margin">
                                                <div class="form-check-inline no-margin"> 
                                                    <input type="checkbox" data-bind="checked: floatingOptions.floatUntilViewed, attr: {'id': 'floatUntilViewed_' + order, 'name': 'floatUntilViewed_' + order }">
                                                    <label class="form-label icon i-check" data-bind="attr: {'for' : 'floatUntilViewed_' + order }">Float Until Viewed</label>
                                                </div>
                                            </div>
                                            <!--<div class="form-group no-margin">
                                                <div class="form-check-inline no-margin">
                                                    <input type="checkbox" data-bind="checked: floatingOptions.isAllowedToRefloat, attr: {'id': 'isAllowedToRefloat_' + order, 'name': 'floatUntilViewed_' + order }">
                                                    <label class="form-label icon i-check" data-bind="attr: {'for' : 'isAllowedToRefloat_' + order }">Allowed to Refloat</label>
                                                </div>
                                            </div>-->
                                        </div>   
                                        
                                        <div class="ads-unit-header" data-bind="visible: shouldFloat">
                                            <div class="form-group row" style="justify-content: flex-end">
                                                <span class="form-label" style="display: inline-flex;">Offset <br>floating by</span>
                                                <input type="number" class="input-text num" style="margin-right: .5rem;flex: none;" data-bind="value: floatingOptions.floatingOffset" style="min-width: 75px">
                                                <span class="form-label" style="display: inline-flex; width: auto;left: 1rem;">px</span>
                                                <div class="w-tooltips-adv" data-bind="template: { name: 'infoButton-template', data: $data.floatingOffsetHelpInfoButton.getTemplateViewModel() }"></div>
                                            </div>
                                            <div data-bind="visible: floatWhenOutOfView">
                                                <div class="form-group row">
                                                    <label class="form-label">Width</label>
                                                    <input type="text" class="input-text num" data-bind="value: floatingOptions.widthFloating">
                                                    <select data-bind="options: $root.adUnits_template.sizePostfixes, optionsText: 'name', optionsValue: 'id', value: floatingOptions.widthFloatingPostfix"></select>
                                                    <div class="w-tooltips-adv" data-bind="template: { name: 'infoButton-template', data: $data.floatingWidthAndHeightHelpInfoButton.getTemplateViewModel() }"></div>                               
                                                </div>
                                                <div class="form-group row">
                                                    <label class="form-label">Height</label>
                                                    <input type="text" class="input-text num" data-bind="value: floatingOptions.heightFloating">
                                                    <select data-bind="options: $root.adUnits_template.sizePostfixes, optionsText: 'name', optionsValue: 'id', value: floatingOptions.heightFloatingPostfix"></select>
                                                    <span style="width: 20px"></span>     
                                                </div>
                                            </div>
                                            <div data-bind="visible: floatingOptions.floatUntilViewed">
                                                <div class="form-group row">
                                                    <label class="form-label wrap" style="flex:1">Float Until Viewed Delay (ms)</label>
                                                    <input type="number" class="input-text num" style="flex: none;" data-bind="value: floatingOptions.floatUntilViewedDelay">
                                                </div>
                                            </div>
                                            <!--<div data-bind="visible: floatingOptions.isAllowedToRefloat">
                                                <div class="form-group row">
                                                    <label class="form-label wrap" style="flex:1">Refloat Delay (ms)</label>
                                                    <input type="number" class="input-text num" style="flex: none;" data-bind="value: floatingOptions.refloatDelayInMilliseconds">
                                                </div>
                                            </div>-->
                                        </div>  
                                        <!-- Floating Section End -->
                                        <!-- Refresh Section Start -->
                                        <div class="ads-unit-header full-width border-top">
                                            <div class="form-group no-margin">
                                                <div class="form-check-inline no-margin"> 
                                                    <input type="checkbox" data-bind="checked: shouldRefresh, attr: {'id': 'shouldRefresh_' + order, 'name': 'shouldRefresh_' + order }">
                                                    <label class="form-label icon i-check title" data-bind="attr: {'for' : 'shouldRefresh_' + order }">Refresh</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ads-unit-header sub" data-bind="visible: shouldRefresh"> 
                                            <div class="form-group row">
                                                <label class="form-label wrap">Mode</label>
                                                <div style="display: flex;align-items: center">
                                                    <select data-bind="options: refreshModes, optionsText: 'name', optionsValue: 'id', value: refreshOptions().mode.id"></select>   
                                                </div>
                                            </div>
                                             <div class="form-group row">
                                                <label class="form-label">By Time <br>(Sec)</label>
                                                <input type="number" class="input-text num" data-bind="value: refreshOptions().timer">           
                                                <div class="w-tooltips-adv" data-bind="template: { name: 'infoButton-template', data: $data.byTimeHelpInfoButton.getTemplateViewModel() }"></div>
                                            </div> 
                                            <div class="form-group row">
                                                <input type="checkbox" data-bind="checked: refreshOptions().pixelTrigger, attr: {'id': 'pixelTrigger_' + order, 'name': 'pixelTrigger_' + order }">
                                                <label class="form-label icon i-check" data-bind="attr: {'for' : 'pixelTrigger_' + order }">Ignore Pixel Trigger</label>       
                                                <div class="w-tooltips-adv" data-bind="template: { name: 'infoButton-template', data: $data.byTimePixelTriggerHelpInfoButton.getTemplateViewModel() }"></div>
                                            </div> 
                                            <div class="form-group row" data-bind="visible: !refreshOptions().pixelTrigger()">
                                                <label class="form-label">By Distance <br> (px)</label>
                                                <input type="number" class="input-text num" data-bind="value: refreshOptions().pixelDistance">
                                                <div class="w-tooltips-adv" data-bind="template: { name: 'infoButton-template', data: $data.byDistanceHelpInfoButton.getTemplateViewModel() }"></div>
                                            </div>
     
                                            <!--
                                            <div class="form-group row">
                                                <div class="w-tooltips-adv" data-bind="template: { name: 'infoButton-template', data: $data.whenAdComesInviewHelpInfoButton.getTemplateViewModel() }"></div>
                                                <input type="checkbox" data-bind="checked: refreshOptions().inview, attr: {'id': 'inview_' + order, 'name': 'inview_' + order }">
                                                <label class="form-label icon i-check" data-bind="attr: {'for' : 'inview_' + order }">When Ad Comes In-view</label>
                                            </div>
                                            -->

                                        </div>
                                        <div class="ads-unit-header sub" data-bind="visible: shouldRefresh">  
                                            <div class="form-group row">
                                                <input type="checkbox" data-bind="checked: refreshOptions().preFetch, attr: {'id': 'preFetch_' + order, 'name': 'preFetch_' + order }">
                                                <label class="form-label icon i-check" data-bind="attr: {'for' : 'preFetch_' + order }">Prefetch</label>
                                                <div class="w-tooltips-adv" data-bind="template: { name: 'infoButton-template', data: $data.prefetchHelpInfoButton.getTemplateViewModel() }"></div>
                                            </div>
                                            <div class="form-group row" style="justify-content: flex-end">
                                                <label class="form-label">Min Time<br> Buffer (Sec)</label>
                                                <input type="number" class="input-text num" style="flex: none;" data-bind="value: refreshOptions().minimumTimeBuffer"> 
                                                <div class="w-tooltips-adv" data-bind="template: { name: 'infoButton-template', data: $data.minimumTimeBufferHelpInfoButton.getTemplateViewModel() }"></div>
                                            </div> 
                                        </div>
                                        <!-- Refresh Section End -->
                                        
                                        <!-- User Activity Section Start -->
                                        <div class="ads-unit-header full-width border-top">
                                            <div class="form-group no-margin">
                                                <div class="form-check-inline no-margin"> 
                                                    <input type="checkbox" data-bind="checked: trackUserActivity, attr: {'id': 'trackUserActivity_' + order, 'name': 'trackUserActivity_' + order }">
                                                    <label class="form-label icon i-check title" data-bind="attr: {'for': 'trackUserActivity_' + order }">User Activity</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-bind="visible: trackUserActivity" class="w-ads-unit-header-sub">
                                            <div class="ads-unit-header sub">
<!--                                                <div class="form-group row">-->
<!--                                                    <label class="form-label wrap">Type</label>-->
<!--                                                    <div style="display: flex; align-items: center;">-->
<!--                                                        <select data-bind="options: userActivityTypes, optionsText: 'name', optionsValue: 'id', value: userActivityOptions.idType"></select>   -->
<!--                                                    </div>-->
<!--                                                </div>-->
                                                <div class="form-group row">
                                                    <label class="form-label" style="width:10rem;">Threshold (SEC)</label>
                                                    <input type="number" class="input-text num" data-bind="value: userActivityOptions.thresholdInSeconds">                              
                                                </div>
                                            </div> 
                                        </div>
                                        <!-- User Activity Section End -->
                                        
                                        <div class="ads-unit-header full-width border-top">
                                            <div class="form-group no-margin">
                                                <div class="form-check-inline" style="display: flex"> 
                                                    <input type="checkbox" data-bind="checked: isConnectedBelowAd, attr: {'id': 'isConnectedBelowAd_' + order, 'name': 'isConnectedBelowAd_' + order }">
                                                    <label class="form-label icon i-check" data-bind="attr: {'for' : 'isConnectedBelowAd_' + order }">Connected Below Ad</label>    
                                                     <div class="w-tooltips-adv" style="margin-left: 4rem;" data-bind="template: { name: 'infoButton-template', data: $data.connectedBelowAdHelpInfoButton.getTemplateViewModel() }"></div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <!-- Connected Ad Zone Section Start -->
                                        <div class="ads-unit-header full-width border-top">
                                            <div class="form-group no-margin">
                                                <div class="form-check-inline no-margin"> 
                                                    <input type="checkbox" data-bind="checked: hasConnectedAdZone, attr: {'id': 'hasConnectedAdZone_' + order, 'name': 'hasConnectedAdZone_' + order }">
                                                    <label class="form-label icon i-check title" data-bind="attr: {'for' : 'hasConnectedAdZone_' + order }">Connected Ad Zone</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-bind="visible: hasConnectedAdZone" class="w-ads-unit-header-sub">
                                            <div class="ads-unit-header sub" >
                                                <div class="form-group row">
                                                    <label class="form-label wrap">Zone</label>
                                                    <div style="display: flex;align-items: center">
                                                        <select data-bind="options: $root.adUnits_template.zones, optionsText: 'name', optionsValue: 'id', value: connectedAdZoneOptions.zone.id" disabled></select>   
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label class="form-label wrap">position</label>
                                                    <div style="display: flex;align-items: center">
                                                        <select data-bind="options: $root.adUnits_template.connectedAdZonePositions, optionsText: 'name', optionsValue: 'id', value: connectedAdZoneOptions.position.id" disabled></select>   
                                                    </div>
                                                </div>                     
                                            </div> 
                                        </div>
                                        <!-- Connected Ad Zone Section End -->
                                
                                        <div class="ads-unit-header full-width border-top">
                                            <div class="form-group row">
                                                <label class="form-label">Templates</label>
                                                <select data-bind="
                                                options: $root.adUnits_template.adTemplates,
                                                optionsText: 'name',
                                                optionsValue: 'id',
                                                value: idTemplate,
                                                attr: {'id' : 'adTemplate_' + order, 'name' : 'adTemplate_' + order }
                                                ">
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="w-ads-unit-body">
                                        <div class="ads-unit-body">
                                            <strong class="ads-unit-body-title">Header</strong>
                                            <!-- ko foreach: adTemplateData().header -->
                                            <div class="form-group">
                                                <label class="form-label" data-bind="text: name"></label>
                                                <input type="text" data-bind="value: value, disable: disabled, attr:{placeholder: placeholder}" class="input-text">                                       
                                            </div>
                                            <!-- /ko -->
                                                                                   
                                        </div>
                                        
                                        <div class="ads-unit-body full-width">                                        
                                            <div class="form-group">
                                                <div class="ads-unit-preview">
                                                    <pre data-bind="html: adTemplate().header"></pre>  
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="ads-unit-body">
                                            <strong class="ads-unit-body-title">Content</strong>
       
                                            <!-- ko foreach: adTemplateData().content -->
                                            <div class="form-group row">
                                                <label class="form-label" data-bind="text: name"></label>
                                                <input type="html" data-bind="value: value, disable: disabled, attr:{placeholder: placeholder}" class="input-text">                                       
                                            </div>
                                            <!-- /ko -->
                                        </div><!-- ads-unit-body -->
                                        
                                        
                                        <div class="ads-unit-body full-width">                                          
                                        
                                             <div class="form-group">
                                                <div class="ads-unit-preview">
                                                    <pre data-bind="html: adTemplate().content"></pre>  
                                                </div>
                                            </div>
                                        </div><!-- ads-unit-body -->
                                    </div> <!-- w-ads-unit-body -->
                                    
                                    
                                    
                                    <div class="w-ads-unit-footer">

                                        <div class="footer-item">
                                            <div>
                                                <span>ID</span>
                                                <strong data-bind="text: id"></strong>
                                            </div>
                                        </div>

                                        <div class="footer-item">
                                            <div>
                                                <span>Created</span>
                                                <strong data-bind="text: dateCreated"></strong>
                                            </div>
                                            <div>
                                                <span>By</span>
                                                <strong data-bind="text: createdByUser.valnet_user.fullNameAndLogin"></strong>
                                            </div>
                                        </div>
                                        <div class="footer-item">
                                            <div>
                                                <span>Updated</span>
                                                <strong data-bind="text: dateUpdated"></strong>
                                            </div>    
                                            <div>
                                                <span>By</span>
                                                <strong data-bind="text: updatedByUser.valnet_user.fullNameAndLogin"></strong>
                                            </div>
                                        </div>

                                    </div><!-- w-ads-unit-footer -->
                                </div> <!-- ads-unit --> 
                            </div><!--  w-ads-unit -->
                        </div>    
                    </div> <!--  zone-item -->
                    <!-- /ko -->
                    <!-- /ko -->
                </div> <!--  zone-list --> 
                <!-- /ko -->  
            </div> <!--  w-ads-unit -->
            
            <!-- ko with: zonesManagement -->
            <div class="w-lightbox light-theme zone-activation" data-bind="css: { 'is-visible': isVisible }" > 
                <div class="lightbox-content large">      
                    <div class="w-form w-form-large">
                        <div class="form-header">
                            <h3 class="form-title">Manages Ad Zones</h3>
                            <button type="button" class="close-button icon i-close-2" data-bind="event: { click: $parent.onClickCloseZoneManagement}"></button>
                        </div>
                   
                        <div class="form-body full-width">
                            <div class="form-group no-margin">
                                <p class="form-text">Ad zones with active ad units cannot be deactivated.</p>                        
                            </div>
                         </div>
                         
                         <div class="form-body scroll full-width" data-bind="foreach: allZonesGroups()">
                            <div>
                                <strong class="form-sub-title icon" data-bind="text: name, attr: { 'data-content': name }"></strong>
                                <div class="form-group" data-bind="foreach: zones()">
                                    <div class="form-check-inline" data-bind="hidden: isChildOrSecondary">
                                        <input type="checkbox" data-bind="checked: $parents[1].selectedZones(), value: id, attr:{'id' : 'zone_' + id, 'name' : 'zone_' + id}, css: { 'read-only': containsAdUnits } ">
                                        <label class="form-label icon i-check" data-bind="text: name, attr:{'for': 'zone_' + id}"></label>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    <div class="form-footer">
                        <button class="btn-action icon i-cancel-thin" type="button" data-bind="event: { click: $parent.onClickCloseZoneManagement}"><span>Cancel</span></button>
                        <button class="btn-action icon i-save-thin" type="button" data-bind="event: { click: $parent.onClickSaveZones}"><span>Save</span></button>
                    </div>
                    </div>
                </div>
                
            </div>
            <!-- /ko -->    
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "ads-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["test"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;

        return {

            adsSetup: ko.observable({id: null, name: null, zoneGroups: ko.observableArray([])}),
            dimensions: ko.observableArray([]),
            adTypes: ko.observableArray([]),
            adTemplates: ko.observableArray([]),
            zones: ko.observableArray([]),
            adUnitsCount: ko.observable(0),
            allBidderTypes: ko.observableArray([]),
            zonesManagement: ko.observable(),
            floatingModes: ko.observableArray([]),
            floatingPositions: ko.observableArray([]),
            sizePostfixes: ko.observableArray([]),
            brand: ko.observable({}),
            isFixed: ko.observable(false),
            styleAdvertiserTags: ko.observableArray([]),
            minimumTimeBuffer: ko.observable(0),
            repeatableTypes: ko.observableArray([]),
            repeatableCountTypes: ko.observableArray([]),
            repeatablePlacementTypes: ko.observableArray([]),
            repeatableHtmlTags: ko.observableArray([]),
            zoneDisplayTypes: ko.observableArray([]),
            zonePositionTypes: ko.observableArray([]),
            videoContexts: ko.observableArray([]),
            refreshModes: ko.observableArray([]),
            videoRequestModes: ko.observableArray([]),
            connectedAdZonePositions: ko.observableArray([]),
            idZoneConnectedRight: ko.observable(0),
            adBehaviours: ko.observable({}),
            userActivityTypes: ko.observableArray([]),
            auctionsManagers: ko.observableArray([]),
            device: ko.observable(''),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
            onClickSaveZones: function(data, event){ huit_neuf_this.onClickSaveZones(); },
            onClickCloseZoneManagement: function(data, event){ huit_neuf_this.onClickCloseZoneManagement(); },
            onClickCopyAdUnit: function(data, event){ huit_neuf_this.onClickCopyAdUnit(data); },
            onClickAddNewAdUnit: function(data, event){ huit_neuf_this.onClickAddNewAdUnit(data); },
            onClickDeleteAdUnit: function(data, event){ huit_neuf_this.onClickDeleteAdUnit(data); },
            onClickOpenAdsSetup: function(data, event){ huit_neuf_this.onClickOpenAdsSetup(); },
            onClickOpenCloseZone: function(data, event){ huit_neuf_this.onClickOpenCloseZone(data); },
            onClickOpenZoneManagement: function(data, event){ huit_neuf_this.onClickOpenZoneManagement(); },
            onClickChangeStatusAdUnit: function(data, event){ huit_neuf_this.onClickChangeStatusAdUnit(data); },
            onEditZoneSettings: function(data, event){ huit_neuf_this.onEditZoneSettings(data); },
            onClickAddChildZone: function(data, event){ huit_neuf_this.onClickAddChildZone(data); },
            onClickRemoveChildZone: function(data, event){ huit_neuf_this.onClickRemoveChildZone(data); },
        };

    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            adsSetup: this.getTemplateViewModel().adsSetup(),
            selectedZones : this.getTemplateViewModel().zonesManagement().selectedZones()
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForZonesSaveRequest(): Object{

        return {
            selectedZones : this.getTemplateViewModel().zonesManagement().selectedZones()
        };
    }

    public setAdTypes(adTypes){

        adTypes.unshift({id : null, name : 'others'});
        this.getTemplateViewModel().adTypes(adTypes);

    }

    //
    // Sets auctions managers.
    public setAuctionsManagers(data): void{

        data.unshift({id : null, name : 'None'});
        this.getTemplateViewModel().auctionsManagers(data);
    }

    //
    // Sets auctions managers.
    public setUserActivityTypes(data): void{

        this.getTemplateViewModel().userActivityTypes(data);
    }

    public setAdsSetup(adsSetup): void {

        let floatingModes     = this.getTemplateViewModel().floatingModes();
        let floatingPositions = this.getTemplateViewModel().floatingPositions();
        let order             = 1;

        for (let zoneGroup of adsSetup.zoneGroups){

            for (let zone of zoneGroup.zones()) {

                for (let adUnit of zone.adUnits()) {

                    let customDimensions = adUnit.customDimensions;

                    if(customDimensions){

                        let customDimensionsParts = customDimensions.split('x');
                        let widthPostfix          = (customDimensionsParts[0].includes('%')) ? '%' : 'px';
                        let heightPostfix         = (customDimensionsParts[1].includes('%')) ? '%' : 'px';

                        customDimensions = {
                            width: customDimensionsParts[0].split('%')[0],
                            height: customDimensionsParts[1].split('%')[0],
                            widthPostfix: widthPostfix,
                            heightPostfix: heightPostfix,
                        };
                    }
                    else{

                        customDimensions = null;
                    }

                    if(!adUnit.refreshOptions || !adUnit.refreshOptions.id){

                        adUnit.refreshOptions = null;
                    }

                    if(adUnit.styleOptions){
                        adUnit.styleOptions.idStyleAdvertiserTag           = ko.observable(adUnit.styleOptions.idStyleAdvertiserTag);
                        adUnit.styleOptions.styleAdvertiserTag             = ko.observable(adUnit.styleOptions.styleAdvertiserTag);
                        adUnit.styleOptions.customAdvertiserTag            = ko.observable(adUnit.styleOptions.customAdvertiserTag);
                        adUnit.styleOptions.adUnitBackgroundDetailsEnabled = ko.observable(adUnit.styleOptions.adUnitBackgroundDetailsEnabled);
                        let styleAdvertiserTag                             = this.getTemplateViewModel().styleAdvertiserTags().find(i => i.id === adUnit.styleOptions.idStyleAdvertiserTag());
                        let isCustom                                       = false;

                        if(styleAdvertiserTag && 'custom' === styleAdvertiserTag.name.toLowerCase()){

                            isCustom = true;
                        }

                        adUnit.styleOptions['isCustom'] = ko.observable(isCustom);
                    }

                    if(adUnit.connectedAdZoneOptions){

                        adUnit.connectedAdZoneOptions.zone.id = ko.observable(adUnit.connectedAdZoneOptions.zone.id);
                        adUnit.connectedAdZoneOptions.position.id = ko.observable(adUnit.connectedAdZoneOptions.position.id);
                    }

                    adUnit.bidderTypes = ko.observableArray(adUnit.bidderTypes.map(a => a.id));
                    adUnit.idTemplate = ko.observable(adUnit.idAdTemplate);
                    adUnit.idZone = ko.observable(adUnit.idZone);
                    adUnit.zone = ko.observable(adUnit.zone);
                    adUnit.idAdType = ko.observable(adUnit.idAdType);
                    adUnit.idDimension = ko.observable(adUnit.idDimension);
                    adUnit.status = ko.observable(adUnit.isActive);
                    adUnit.amazon = ko.observable(adUnit.amazon);
                    adUnit.order = order;
                    adUnit.isDeleted = ko.observable(false);
                    adUnit.adTemplateDataHistory = ko.observableArray([]);
                    adUnit.previousIdTemplate = ko.observable(adUnit.idAdTemplate);
                    adUnit.adTemplateData = ko.observable(adUnit.adTemplateData);
                    adUnit.name = ko.observable(adUnit.name);
                    adUnit.slotName = ko.observable(adUnit.slotName);
                    adUnit.sizeArray = ko.observable(adUnit.sizeArray);
                    adUnit.positionsName = ko.observable(adUnit.positionsName);
                    adUnit.divId = ko.observable(adUnit.divId);
                    adUnit.isDynamicallyInjected = ko.observable(adUnit.isDynamicallyInjected);
                    adUnit.isConnectedBelowAd= ko.observable(adUnit.isConnectedBelowAd);
                    adUnit.shouldFloat = ko.observable((adUnit.floatingOptions && Object.keys(adUnit.floatingOptions).length !== 0));
                    adUnit.floatingOptions = this.createFloatingOptions(adUnit.floatingOptions);
                    adUnit.floatAlways = ko.observable((adUnit.shouldFloat && 'always' === adUnit.floatingOptions.floatingMode()));
                    adUnit.floatWhenOutOfView = ko.observable((adUnit.shouldFloat && 'outOfView' === adUnit.floatingOptions.floatingMode()));
                    adUnit.floatingModes = ko.observable(floatingModes);
                    adUnit.floatingPositions = ko.observable(floatingPositions);
                    adUnit.shouldRefresh = ko.observable((adUnit.refreshOptions && Object.keys(adUnit.refreshOptions).length !== 0));
                    adUnit.refreshOptions = ko.observable(this.createRefreshOptions(adUnit.refreshOptions));
                    adUnit.customDimensions = ko.observable(customDimensions || this.createCustomDimensions());
                    adUnit.hasStyle = ko.observable((adUnit.styleOptions && Object.keys(adUnit.styleOptions).length !== 0));
                    adUnit.styleOptions = adUnit.styleOptions || this.createStyleOptions();
                    adUnit.hasConnectedAdZone = ko.observable((adUnit.connectedAdZoneOptions && Object.keys(adUnit.connectedAdZoneOptions).length !== 0));
                    adUnit.connectedAdZoneOptions = adUnit.connectedAdZoneOptions || this.createConnectedAdZoneOptions();
                    adUnit.couldBeVideo = ko.observable((adUnit.videoOptions && Object.keys(adUnit.videoOptions).length !== 0));
                    adUnit.videoOptions = this.createVideoOptions(JSON.parse(JSON.stringify(adUnit.videoOptions)));
                    adUnit.trackUserActivity = ko.observable((adUnit.userActivityOptions && Object.keys(adUnit.userActivityOptions).length !== 0));
                    adUnit.userActivityOptions = adUnit.userActivityOptions || this.createUserActivityOptions();
                    adUnit.videoRequestModes = ko.observable(this.getTemplateViewModel().videoRequestModes());
                    adUnit.videoContexts = ko.observable(this.getTemplateViewModel().videoContexts());
                    adUnit.refreshModes = ko.observable(this.getTemplateViewModel().refreshModes());
                    adUnit.userActivityTypes = ko.observable(this.getTemplateViewModel().userActivityTypes());
                    adUnit.useCustomDimensions = ko.observable(('custom' === this.getTemplateViewModel().dimensions().find(i => i.id === adUnit.idDimension()).dimension));
                    adUnit.useStickySlideAnimation = ko.observable(adUnit.useInterscrollerAnimation || adUnit.useStickySlideAnimation || adUnit.useAutoScroll);
                    adUnit.disableStickySlideAnimation = ko.observable(adUnit.useAutoScroll || adUnit.useInterscrollerAnimation);
                    adUnit.useInterscrollerAnimation = ko.observable(adUnit.useInterscrollerAnimation);
                    adUnit.disableInterscrollerAnimation = ko.observable(adUnit.useAutoScroll);
                    adUnit.disableIncludeDefaultPlaylist = ko.observable((this.ID_VIDEO_PLAYER_CONTEXT_COMPANION === adUnit.videoOptions.context.id() || this.ID_VIDEO_PLAYER_CONTEXT_OUTSTREAM === adUnit.videoOptions.context.id()));
                    adUnit.doNotUseSpace = ko.observable(adUnit.doNotUseSpace);
                    adUnit.useAutoScroll = ko.observable(adUnit.useAutoScroll);
                    adUnit.disableAutoScroll = ko.observable(adUnit.useInterscrollerAnimation());
                    adUnit.useAmazon = ko.observable(this.shouldUseAmazon(adUnit.bidderTypes()));
                    adUnit.onPageLoad = ko.observable((!adUnit.isDynamicallyInjected()));
                    adUnit.floatingOptionsField = ko.observable(`${JSON.stringify(adUnit.floatingOptions)}`);
                    adUnit.refreshOptionsField = ko.observable(`${JSON.stringify(adUnit.refreshOptions())}`);
                    adUnit.useAuctionsManager = ko.observable((adUnit.idAuctionsManager));
                    adUnit.idAuctionsManager = ko.observable(adUnit.idAuctionsManager || null);
                    adUnit.dateCreated = (adUnit.dateCreated.date !== null) ? adUnit.dateCreated.date.substr(0, 19) : null;
                    adUnit.dateUpdated = (adUnit.dateUpdated.date !== null) ? adUnit.dateUpdated.date.substr(0, 19) : null;

                    adUnit.idTemplate.subscribe(function (previousValue) {
                        adUnit.previousIdTemplate(previousValue);
                    }, this, "beforeChange");

                    adUnit.status.ForEditing = ko.computed({
                        read: function () {
                            return adUnit.status().toString();
                        },
                        write: function (newValue) {
                            adUnit.status(newValue === "true");
                        },
                        owner: this
                    });

                    adUnit.floatingOptions.floatingMode.subscribe(function(newValue){

                        adUnit.floatAlways((newValue === 'always'));
                        adUnit.floatWhenOutOfView((newValue === 'outOfView'));

                        adUnit.floatingOptions.floatingMode(newValue);
                    }, this);

                    adUnit.width = ko.computed(function(){

                        return this.getDimension('width', adUnit);
                    }, this);

                    adUnit.height = ko.computed(function(){

                        return this.getDimension('height', adUnit);
                    }, this);

                    adUnit.idDimension.subscribe(function(newValue){

                        let dimension = this.getTemplateViewModel().dimensions().find(i => i.id === newValue).dimension;

                        adUnit.useCustomDimensions(('custom' === dimension));
                    }, this);

                    adUnit.useAmazon = ko.computed(function(){

                        return `${this.shouldUseAmazon(adUnit.bidderTypes())}`;
                    }, this);

                    adUnit.onPageLoad = ko.computed(function(){

                        return `${(!adUnit.isDynamicallyInjected())}`;
                    }, this);

                    adUnit.floatingOptionsField = ko.computed(function(){

                        return `${JSON.stringify(adUnit.floatingOptions)}`;
                    }, this);

                    adUnit.refreshOptionsField = ko.computed(function(){

                        return `${JSON.stringify(adUnit.refreshOptions())}`;
                    }, this);

                    adUnit.adTemplateData = ko.computed(function () {
                        return this.getAdTemplateData(adUnit);
                    }, this);

                    adUnit.adTemplate = ko.computed(function () {
                        return this.getAdTemplate(adUnit);
                    }, this)

                    adUnit.styleOptions.idStyleAdvertiserTag.subscribe(function(newValue){

                        let styleAdvertiserTag = this.getTemplateViewModel().styleAdvertiserTags().find(i => i.id === newValue);

                        adUnit.styleOptions.isCustom(('custom' === styleAdvertiserTag.name.toLowerCase()));
                    }, this);

                    adUnit.useAuctionsManager = ko.computed(function(){

                        return (adUnit.idAuctionsManager());
                    }, this);

                    adUnit.useStickySlideAnimation.subscribe(function(newValue){

                        newValue = !!newValue;

                        if(newValue){

                            adUnit.useAutoScroll(false);
                        }

                    }, this);

                    adUnit.useInterscrollerAnimation.subscribe(function(newValue){

                        newValue = !!newValue;

                        if(newValue){

                            adUnit.useStickySlideAnimation(true);
                            adUnit.useAutoScroll(false);
                        }

                        adUnit.disableStickySlideAnimation(newValue);
                        adUnit.disableAutoScroll(newValue);
                    }, this);

                    adUnit.useAutoScroll.subscribe(function(newValue){

                        newValue = !!newValue;

                        if(newValue){

                            adUnit.useStickySlideAnimation(true);
                            adUnit.useInterscrollerAnimation(false);
                        }

                        adUnit.disableStickySlideAnimation(newValue);
                        adUnit.disableInterscrollerAnimation(newValue);
                    }, this);

                    adUnit.videoOptions.requestMode.id.subscribe(function(newValue){

                        adUnit.videoOptions.isVideoRequestModeVideo(this.ID_VIDEO_PLAYER_REQUEST_MODE_VIDEO === newValue);

                        if(this.ID_VIDEO_PLAYER_REQUEST_MODE_DISPLAY === newValue){

                            adUnit.videoOptions.context.id(this.ID_VIDEO_PLAYER_CONTEXT_OUTSTREAM);
                        }
                    }, this);

                    adUnit.videoOptions.context.id.subscribe(function(newValue){

                        if(this.ID_VIDEO_PLAYER_CONTEXT_COMPANION === newValue){

                            adUnit.videoOptions.includeDefaultPlaylist(true);
                            adUnit.videoOptions.allowAutoplay(true);
                            adUnit.disableIncludeDefaultPlaylist(true);
                        }
                        else if(this.ID_VIDEO_PLAYER_CONTEXT_OUTSTREAM === newValue){

                            adUnit.videoOptions.includeDefaultPlaylist(false);
                            adUnit.videoOptions.allowAutoplay(true);
                            adUnit.disableIncludeDefaultPlaylist(true);
                        }
                        else{

                            adUnit.disableIncludeDefaultPlaylist(false);
                        }
                    }, this);

                    adUnit.videoOptions.allowAutoplay.subscribe(function(newValue){

                        if(newValue) {

                            adUnit.videoOptions.soundOn(!newValue);
                        }
                    }, this);

                    // Add info buttons.
                    adUnit = this.addInfoButtons(adUnit, order);

                    order++;
                }
            }
        }


        this.getTemplateViewModel().adUnitsCount(order);

        let adsSetupTemp = {id: adsSetup.id, name: adsSetup.name, zoneGroups: ko.observableArray(adsSetup.zoneGroups)};

        this.getTemplateViewModel().adsSetup(adsSetupTemp);

        console.log('adsetup');
        console.log(this.getTemplateViewModel().adsSetup());

    }

    public setZoneInfos(zonesManagement){

        this.getTemplateViewModel().zonesManagement(zonesManagement);

        console.log(this.getTemplateViewModel().zonesManagement());
    }

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        this.caller.saveEditAdsSetup();
    }


    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancelEditAdsSetup();
    }

    /**
     * Handles clicking open/close zone button event.
     */
    protected onClickOpenCloseZone(data): void{

        if(data.isOpen()){
            data.isOpen(false);
        }else{
            data.isOpen(true);
        }
    }

    /**
     * Handles clicking open zone management button event.
     */
    protected onClickOpenZoneManagement(): void{

        this.getTemplateViewModel().zonesManagement().isVisible(!this.getTemplateViewModel().zonesManagement().isVisible());
        this.getTemplateViewModel().isFixed(true);
    }

    /**
     * Handles clicking close zone management button event.
     */
    protected onClickCloseZoneManagement(): void{

        this.getTemplateViewModel().zonesManagement().isVisible(false);
        this.getTemplateViewModel().isFixed(false);
    }



    /**
     * Handles clicking add new adUnit button event.
     */
    protected onClickAddNewAdUnit(data): void{

        console.log(data);

        data.isOpen(true);

        let floatingModes     = this.getTemplateViewModel().floatingModes();
        let floatingPositions = this.getTemplateViewModel().floatingPositions();
        let adTemplate        = this.getTemplateViewModel().adTemplates()[0];
        let dimension         = this.getTemplateViewModel().dimensions().find(i => i.dimension === 'no_size');
        let adType            = {id: null, name: 'others'};
        let zoneName          = data.name;

        let newAdUnit = this.buildNewAdUnit(
            null,
            "",
            "",
            data,
            dimension,
            adType,
            adTemplate,
            null,
            [],
            true,
            false,
            [],
            this.generateDivId(zoneName),
            false,
            false,
            false,
            this.createFloatingOptions(),
            floatingModes,
            floatingPositions,
            false,
            this.createRefreshOptions(),
            false,
            this.createStyleOptions(),
            false,
            this.createCustomDimensions(),
            true,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true,
            null,
            null,
            false,
            null,
            '',
            '',
            '',
            false,
            this.createConnectedAdZoneOptions(),
            false,
            this.createVideoOptions(),
            false,
            this.createUserActivityOptions(),
            {valnet_user: ""},
            {valnet_user: ""},
            "",
            ""
        );

        let zoneGroupToAddIn = this.getTemplateViewModel().adsSetup().zoneGroups().find(i => i.name === data.groupName);
        let zoneToAddIn = zoneGroupToAddIn.zones().find(i => i.name === data.name);

        console.log(zoneToAddIn);

        this.addAdUnitToZone(newAdUnit, zoneToAddIn);

        console.log(this.getTemplateViewModel().adsSetup().zoneGroups());

    }


    /**
     * Handles clicking copy adUnit button event.
     */
    protected onClickCopyAdUnit(data): void{

        let adUnitToCopy = data;

        let newAdUnit = this.buildNewAdUnit(
            null,
            adUnitToCopy.name(),
            "",
            adUnitToCopy.zone(),
            adUnitToCopy.dimension,
            adUnitToCopy.adType,
            adUnitToCopy.adTemplate(),
            adUnitToCopy.adTemplateData(),
            [],
            adUnitToCopy.status(),
            adUnitToCopy.isDeleted(),
            adUnitToCopy.bidderTypes(),
            adUnitToCopy.divId(),
            adUnitToCopy.isDynamicallyInjected(),
            adUnitToCopy.isConnectedBelowAd(),
            adUnitToCopy.shouldFloat(),
            adUnitToCopy.floatingOptions,
            adUnitToCopy.floatingModes(),
            adUnitToCopy.floatingPositions,
            adUnitToCopy.shouldRefresh(),
            adUnitToCopy.refreshOptions(),
            adUnitToCopy.hasStyle(),
            adUnitToCopy.styleOptions,
            adUnitToCopy.useCustomDimensions(),
            adUnitToCopy.customDimensions(),
            adUnitToCopy.useStickySlideAnimation(),
            adUnitToCopy.disableStickySlideAnimation(),
            adUnitToCopy.useInterscrollerAnimation(),
            adUnitToCopy.disableInterscrollerAnimation(),
            adUnitToCopy.doNotUseSpace(),
            adUnitToCopy.useAutoScroll(),
            adUnitToCopy.disableAutoScroll(),
            adUnitToCopy.useAmazon(),
            adUnitToCopy.onPageLoad(),
            adUnitToCopy.width(),
            adUnitToCopy.height(),
            adUnitToCopy.useAuctionsManager(),
            adUnitToCopy.idAuctionsManager(),
            adUnitToCopy.slotName(),
            adUnitToCopy.sizeArray(),
            adUnitToCopy.positionsName(),
            adUnitToCopy.hasConnectedAdZone(),
            adUnitToCopy.connectedAdZoneOptions,
            adUnitToCopy.couldBeVideo(),
            adUnitToCopy.videoOptions,
            adUnitToCopy.trackUserActivity(),
            adUnitToCopy.userActivityOptions,
            {valnet_user: ""},
            {valnet_user: ""},
            null,
            null
        );

        let zoneGroupToCopyIn =  this.getTemplateViewModel().adsSetup().zoneGroups().find(i => i.name === data.zone().groupName);

        let zoneToCopyIn = zoneGroupToCopyIn.zones().find(i => i.name === data.zone().name);

        this.addAdUnitToZone(newAdUnit, zoneToCopyIn);

    }

    /**
     * Handles clicking delete adUnit button event.
     */
    protected onClickDeleteAdUnit(data): void{

        data.isDeleted(!data.isDeleted());

    }

    /**
     * Handles clicking activate/deactivate adUnit button event.
     */
    protected onClickChangeStatusAdUnit(data): void{

        data.status(!data.status());
    }

    protected onClickOpenAdsSetup(): void{

        this.caller.openAdsSetupPage()
    }

    /**
     * Handles clicking edit zone settings button event.
     */
    protected onEditZoneSettings(data): void{

        // this.caller.editZoneSettings(data.id);

        if(data.isOptionOpen()){

            data.isOptionOpen(false);
        }
        else{

            data.isOptionOpen(true);
        }
    }

    //
    // Handles clicking add child zone button event.
    protected onClickAddChildZone(data): void{

        this.caller.addChildZone(data);
    }

    //
    // Handles clicking remove child zone button event.
    protected onClickRemoveChildZone(data): void{

        this.caller.confirmationModal.setContext('removeChildZone');
        this.caller.confirmationModal.setTitle('Are you sure?');
        this.caller.confirmationModal.setDataConfirm(data);
        this.caller.confirmationModal.setMessageMain('Removing this child zone will also remove any associated ad units.');
        this.caller.confirmationModal.setMessageSecondary('Are you sure you want to proceed?');
        this.caller.confirmationModal.show();
    }

    protected filterDropdownOnChange(idElement): void{

    }

    private getAdTemplate(adUnit){

        let idAdTemplate = adUnit.idTemplate();

        let adTemplate = this.getTemplateViewModel().adTemplates().find(i => i.id === idAdTemplate);

        let adUnitAdTemplate = {header: null, content: null, id: null};

        //adTemplate.header = adTemplate.header.replace(/[^<span>]{{{/g, '<span>{{{').replace(/}}}[^</span>]/g, '}}}</span>').replace(/<[^span>]/g, '&lt;').replace(/<[^/span>]/g, '&lt;').replace(/[^span]>/g, '&gt;');

        //adTemplate.content = adTemplate.content.replace(/[^<span>]{{{/g, '<span>{{{').replace(/}}}[^</span>]/g, '}}}</span>').replace(/<[^span>]/g, '&lt;').replace(/<[^/span>]/g, '&lt;').replace(/[^span]>/g, '&gt;');

        adUnitAdTemplate.header = '';

        if(adTemplate.header){

            adUnitAdTemplate.header = adTemplate.header.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/(?!<span>){{{/g, '<span>{{{').replace(/}}}(?!<\/span>)/g, '}}}</span>');
        }

        adUnitAdTemplate.content = '';

        if(adTemplate.content){

            adUnitAdTemplate.content = adTemplate.content.replace(/<(?!span>|\/span>)/g, '&lt;').replace(/(?!<span|)>/g, '&gt;').replace(/(?!<span>){{{/g, '<span>{{{').replace(/}}}(?!<\/span>)/g, '}}}</span>');
        }

        adUnitAdTemplate.id = idAdTemplate;

        return adUnitAdTemplate;

    }

    private getAdTemplateData(adUnit){

        let idAdTemplate = adUnit.idTemplate();

        let newAdTemplate = this.getTemplateViewModel().adTemplates().find(i => i.id === idAdTemplate);

        let headerData = [];
        let contentData = [];

        let header = newAdTemplate.header;
        let content = newAdTemplate.content;

        let headerVariables = (header !== null && header !== "") ? header.match(/{{{(.*?)}}}/g) : null;
        let contentVariables = (content !== null && content !== "") ? content.match(/{{{(.*?)}}}/g) : null;


        //TODO check here if there is adTemplateDataNewHistory for the idTemplate and use it to build headerData and contentData by using the history value except for zone.

        if(headerVariables !== null){

            for(let headerVariable of headerVariables){

                let value  = null;

                if(!headerVariable){

                    continue;
                }

                let name = headerVariable.replace('{{{', '').replace('}}}', '');
                let result = this.processAdTemplateData(name, value, adUnit);

                if(!headerData.find(element => element.name == name)){

                    headerData.push({ name: name, value: result.value, disabled: result.disabled, placeholder: result.placeholder});
                }
            }
        }

        if(contentVariables !== null){

            for(let contentVariable of contentVariables){

                let value  = null;

                if(!contentVariable){

                    continue;
                }

                let name = contentVariable.replace('{{{', '').replace('}}}', '');
                let result = this.processAdTemplateData(name, value, adUnit);

                if(!contentData.find(element => element.name == name)){

                    contentData.push({name: name, value: result.value, disabled: result.disabled, placeholder: result.placeholder});
                }
            }
        }


        if(adUnit.adTemplateData() !== null) {

            let adTemplateDataNewHistory = {
                identifier: adUnit.previousIdTemplate(),
                adTemplateData: adUnit.adTemplateData()
            };

            this.pushToArray(adUnit.adTemplateDataHistory(), adTemplateDataNewHistory);
        }


        // if we already have the adTemplateData for an already saved adUnit we return it but we need to do all the calculation above to bind the value to the computed observable
        let adTemplateDataHistoryFound = adUnit.adTemplateDataHistory().find(i => i.identifier === idAdTemplate);

        if( typeof adTemplateDataHistoryFound !== 'undefined'){



            adTemplateDataHistoryFound.adTemplateData['header'].forEach((element, index) => {

                let value  = adTemplateDataHistoryFound.adTemplateData['header'][index]['value'];
                let name   = adTemplateDataHistoryFound.adTemplateData['header'][index]['name'];
                let result = this.processAdTemplateData(name, value, adUnit);

                adTemplateDataHistoryFound.adTemplateData['header'][index]['value']       = result.value;
                adTemplateDataHistoryFound.adTemplateData['header'][index]['disabled']    = result.disabled;
                adTemplateDataHistoryFound.adTemplateData['header'][index]['placeholder'] = result.placeholder;
            });

            adTemplateDataHistoryFound.adTemplateData['content'].forEach((element, index) => {

                let name   = adTemplateDataHistoryFound.adTemplateData['content'][index]['name'];
                let value  = adTemplateDataHistoryFound.adTemplateData['content'][index]['value'];
                let result = this.processAdTemplateData(name, value, adUnit);

                adTemplateDataHistoryFound.adTemplateData['content'][index]['value']       = result.value;
                adTemplateDataHistoryFound.adTemplateData['content'][index]['disabled']    = result.disabled;
                adTemplateDataHistoryFound.adTemplateData['content'][index]['placeholder'] = result.placeholder;
            });

            return adTemplateDataHistoryFound.adTemplateData;
        }

        return {'header' : headerData, 'content' : contentData, 'idTemplate' : idAdTemplate};

    }

    private addAdUnitToZone(adUnit, zone){

        let order = this.getTemplateViewModel().adUnitsCount();
        let activeAdUnitsCount = zone.activeAdUnitsCount() + 1;
        let adUnitsCount = zone.adUnitsCount() + 1;

        adUnit.id = null;
        adUnit.dateCreated = "";
        adUnit.dateUpdated = "";
        adUnit.order = order;

        // Add info buttons.
        adUnit = this.addInfoButtons(adUnit, order);

        zone.adUnits.unshift(adUnit);
        zone.activeAdUnitsCount(activeAdUnitsCount);
        zone.adUnitsCount(adUnitsCount);

        order = order + 1;

        this.getTemplateViewModel().adUnitsCount(order);

        console.log('adsetup');
        console.log(this.getTemplateViewModel().adsSetup());

    }


    private onClickSaveZones(){

        //add the selected zone to the adsSetup zone groups list
        let selectedZones = this.getTemplateViewModel().zonesManagement().selectedZones();
        let zoneGroups = this.getTemplateViewModel().zonesManagement().allZonesGroups();
        let adsSetupZoneGroups = this.getTemplateViewModel().adsSetup().zoneGroups();
        // let adsSetupZoneGroupsToDelete = [];

        for(let selectedZoneId of selectedZones){

            for(let zoneGroup of zoneGroups){

                let selectedZoneInfo = zoneGroup.zones().find(i => i.id === selectedZoneId);

                if(typeof selectedZoneInfo !== 'undefined' && selectedZoneInfo !== null){

                    let adsSetupZoneGroup = adsSetupZoneGroups.find(i => i.name === selectedZoneInfo.groupName);

                    let repeatableOptions = null;

                    if(selectedZoneInfo.isRepeatable){

                        repeatableOptions = this.caller.setupRepeatableOptions({
                            id: new Date().getTime(),
                            type: 'characterCount',
                            startingPoint: 0,
                            htmlTag: '<table>',
                            customOpenHtmlTag: '',
                            customCloseHtmlTag: '',
                            count: 0,
                            placement: 'after',
                            skipEvery: 0,
                            degradationHtmlTag: '<table>',
                            degradationPlacement: 'after',
                            degradationStartingPoint: 0,
                            stopAds: 0,
                            degradationEntityCount: 0,
                            degradationCount: 0,
                            isContinuous: false,
                            railItemMargin: 0,
                        });
                    }

                    let newZone = {
                        id: selectedZoneInfo.id,
                        name: selectedZoneInfo.name,
                        displayName: selectedZoneInfo.name,
                        type: selectedZoneInfo.type,
                        groupName: selectedZoneInfo.groupName,
                        idBrand: selectedZoneInfo.idBrand,
                        idParent: null,
                        repeatableOptions: repeatableOptions,
                        idZoneDisplayType: 1,
                        idZonePositionType: 1,
                        isOpen: ko.observable(false),
                        isOptionOpen: ko.observable(false),
                        adUnits: ko.observableArray([]),
                        activeAdUnitsCount: ko.observable(0),
                        adUnitsCount: ko.observable(0),
                        isRepeatable: selectedZoneInfo.isRepeatable,
                        isChild: selectedZoneInfo.isChild,
                        isSecondary: selectedZoneInfo.isSecondary,
                        isChildOrSecondary: selectedZoneInfo.isChildOrSecondary,
                        idSecondary: null,
                        secondary: null,
                        secondaryEnabled: ko.observable(false),
                        isFrontEndInjectable: selectedZoneInfo.isFrontEndInjectable,
                        canRemoveVendorSpecificContainers: selectedZoneInfo.canRemoveVendorSpecificContainers,
                        position: ko.observable(selectedZoneInfo.position),
                        isDeleted: ko.observable(false),
                    };

                    newZone.position.subscribe(function(newValue){

                        newZone.position(newValue.replace(/\D/g, ''));

                        newZone.id = null;
                    });

                    if(typeof adsSetupZoneGroup !== 'undefined' && adsSetupZoneGroup !== null){

                        let alreadySelectedZone = adsSetupZoneGroup.zones().find(i => i.name === selectedZoneInfo.name)

                        if(typeof alreadySelectedZone === 'undefined' || alreadySelectedZone === null){

                            adsSetupZoneGroup.zones.push(newZone);
                        }
                    }
                    else{

                        let newZoneGroup = {name: selectedZoneInfo.groupName, zones: ko.observableArray([])};
                        newZoneGroup.zones.push(newZone);

                        this.getTemplateViewModel().adsSetup().zoneGroups.push(newZoneGroup);
                    }
                }
            }
        }

        console.log(adsSetupZoneGroups);
        console.log(selectedZones);

        //return;

        // We don't want to remove any zones. As some are now created on the fly.

        // //remove unselected zones
        // for(let adsSetupZoneGroup of adsSetupZoneGroups){
        //
        //     let adSetupZones = adsSetupZoneGroup.zones();
        //
        //     adsSetupZoneGroup.zones(adSetupZones.filter(function(element){
        //
        //         return (selectedZones.includes(element.id));
        //     }));
        // }
        //
        // //remove empty zoneGroups
        // for(let adsSetupZoneGroup of adsSetupZoneGroups){
        //
        //     if(adsSetupZoneGroup.zones().length === 0){
        //
        //         adsSetupZoneGroupsToDelete.push(adsSetupZoneGroup);
        //     }
        // }
        //
        // for(let adsSetupZoneGroupToDelete of adsSetupZoneGroupsToDelete){
        //
        //     this.getTemplateViewModel().adsSetup().zoneGroups.remove(adsSetupZoneGroupToDelete);
        // }

        this.getTemplateViewModel().adsSetup().zoneGroups.sort(function(a, b){

            return a.name.localeCompare(b.name, undefined, {numeric: true});
        });

        this.getTemplateViewModel().zonesManagement().isVisible(false);

        this.saveZones();

        this.getTemplateViewModel().isFixed(false);
    }

    private saveZones(){

        this.caller.saveZones();
    }

    private buildNewAdUnit(
        id,
        name,
        ratio,
        zone,
        dimension,
        adType,
        adTemplate,
        adTemplateData,
        adTemplateDataHistory,
        status,
        isDeleted,
        bidderTypes,
        divId,
        isDynamicallyInjected,
        isConnectedBelowAd,
        shouldFloat,
        floatingOptions,
        floatingModes,
        floatingPositions,
        shouldRefresh,
        refreshOptions,
        hasStyle,
        styleOptions,
        useCustomDimensions,
        customDimensions,
        useStickySlideAnimation,
        disableStickySlideAnimation,
        useInterscrollerAnimation,
        disableInterscrollerAnimation,
        doNotUseSpace,
        useAutoScroll,
        disableAutoScroll,
        useAmazon,
        onPageLoad,
        width,
        height,
        useAuctionsManager,
        idAuctionsManager,
        slotName,
        sizeArray,
        positionsName,
        hasConnectedAdZone,
        connectedAdZoneOptions,
        couldBeVideo,
        videoOptions,
        trackUserActivity,
        userActivityOptions,
        createdByUser,
        updatedByUser,
        dateCreated,
        dateUpdated
    ){

        let myZone = {
            id: zone.id,
            name: zone.name,
            type: zone.type,
            displayName: zone.displayName,
            groupName: zone.groupName,
            idBrand: zone.idBrand,
            idParent: zone.idParent,
            repeatableOptions: zone.repeatableOptions,
            isRepeatable: zone.isRepeatable,
            isChild: zone.isChild,
            isSecondary: zone.isSecondary,
            isChildOrSecondary: zone.isChildOrSecondary,
            idSecondary: null,
            secondary: null,
            secondaryEnabled: false,
            isFrontEndInjectable: zone.isFrontEndInjectable,
            canRemoveVendorSpecificContainers: zone.canRemoveVendorSpecificContainers,
            position: null,
        };

        let newConnectedAdZoneOptions = null;

        if(connectedAdZoneOptions){

            newConnectedAdZoneOptions = this.createConnectedAdZoneOptions();
            newConnectedAdZoneOptions.zone.id(connectedAdZoneOptions.zone.id());
            newConnectedAdZoneOptions.position.id(connectedAdZoneOptions.position.id());
        }

        let newVideoOptions    = this.createVideoOptions(videoOptions);
        let newFloatingOptions = this.createFloatingOptions(floatingOptions);

        let newAdUnit = {
            id: id,
            name: ko.observable(name),
            ratio: ratio,
            zone: ko.observable(myZone),
            dimension: dimension,
            adType: adType,
            idTemplate: ko.observable(adTemplate.id),
            previousIdTemplate: ko.observable(adTemplate.id),
            idZone: ko.observable(zone.id),
            idAdType: ko.observable(null),
            idDimension: ko.observable(dimension.id),
            adTemplate: null,
            adTemplateData: ko.observable(JSON.parse(JSON.stringify(adTemplateData))),
            adTemplateDataHistory: ko.observableArray(adTemplateDataHistory),
            status: ko.observable(status),
            isDeleted : ko.observable(isDeleted),
            bidderTypes : ko.observableArray(JSON.parse(JSON.stringify(bidderTypes))),
            divId: ko.observable(divId),
            slotName: ko.observable(slotName),
            sizeArray: ko.observable(sizeArray),
            positionsName: ko.observable(positionsName),
            isDynamicallyInjected: ko.observable(isDynamicallyInjected),
            isConnectedBelowAd: ko.observable(isConnectedBelowAd),
            shouldFloat: ko.observable(shouldFloat),
            floatAlways: ko.observable((newFloatingOptions && 'always' === newFloatingOptions.floatingMode())),
            floatWhenOutOfView: ko.observable((newFloatingOptions && 'outOfView' === newFloatingOptions.floatingMode())),
            floatingOptions: newFloatingOptions,
            floatingModes: ko.observable(floatingModes),
            floatingPositions: floatingPositions,
            shouldRefresh: ko.observable(shouldRefresh),
            refreshOptions: ko.observable(this.createRefreshOptions(JSON.parse(JSON.stringify(refreshOptions)))),
            useCustomDimensions: ko.observable(useCustomDimensions),
            customDimensions: ko.observable(JSON.parse(JSON.stringify(customDimensions))),
            useStickySlideAnimation: ko.observable(useStickySlideAnimation || useAutoScroll || useInterscrollerAnimation),
            disableStickySlideAnimation: ko.observable(useAutoScroll || useInterscrollerAnimation),
            useInterscrollerAnimation: ko.observable(useInterscrollerAnimation),
            disableInterscrollerAnimation: ko.observable(useAutoScroll),
            disableIncludeDefaultPlaylist: ko.observable((this.ID_VIDEO_PLAYER_CONTEXT_COMPANION === newVideoOptions.context.id() || this.ID_VIDEO_PLAYER_CONTEXT_OUTSTREAM === newVideoOptions.context.id())),
            doNotUseSpace: ko.observable(doNotUseSpace),
            useAutoScroll: ko.observable(useAutoScroll),
            disableAutoScroll: ko.observable(useInterscrollerAnimation),
            useAmazon: ko.observable(useAmazon),
            onPageLoad: ko.observable(onPageLoad),
            floatingOptionsField: ko.observable(`${JSON.stringify(newFloatingOptions)}`),
            refreshOptionsField: ko.observable(`${JSON.stringify(refreshOptions)}`),
            hasStyle: ko.observable(hasStyle),
            styleOptions: JSON.parse(JSON.stringify(styleOptions)),
            hasConnectedAdZone: ko.observable(hasConnectedAdZone),
            connectedAdZoneOptions: newConnectedAdZoneOptions,
            couldBeVideo: ko.observable(couldBeVideo),
            videoOptions: newVideoOptions,
            trackUserActivity: ko.observable(trackUserActivity),
            userActivityOptions: JSON.parse(JSON.stringify(userActivityOptions)),
            videoRequestModes: ko.observable(this.getTemplateViewModel().videoRequestModes()),
            videoContexts: ko.observable(this.getTemplateViewModel().videoContexts()),
            refreshModes: ko.observable(this.getTemplateViewModel().refreshModes()),
            userActivityTypes: ko.observable(this.getTemplateViewModel().userActivityTypes()),
            width: null,
            height: null,
            useAuctionsManager: ko.observable(useAuctionsManager),
            idAuctionsManager: ko.observable(idAuctionsManager || null),
            createdByUser: createdByUser,
            updatedByUser: updatedByUser,
            dateCreated: dateCreated,
            dateUpdated: dateUpdated,
        };

        let self = this;

        if(adType !== null){
            newAdUnit.idAdType(adType.id);
        }

        if(styleOptions && Object.keys(styleOptions).length > 0){

            newAdUnit.styleOptions.idStyleAdvertiserTag           = ko.observable(styleOptions.idStyleAdvertiserTag());
            newAdUnit.styleOptions.styleAdvertiserTag             = ko.observable(styleOptions.styleAdvertiserTag());
            newAdUnit.styleOptions.customAdvertiserTag            = ko.observable(styleOptions.customAdvertiserTag());
            newAdUnit.styleOptions.isCustom                       = ko.observable(styleOptions.isCustom());
            newAdUnit.styleOptions.adUnitBackgroundDetailsEnabled = ko.observable(styleOptions.adUnitBackgroundDetailsEnabled());
        }

        newAdUnit.status.ForEditing = ko.computed({
            read: function() {
                return newAdUnit.status().toString();
            },
            write: function(newValue) {
                newAdUnit.status(newValue === "true");

            },
            owner: this
        });

        newAdUnit.idTemplate.subscribe(function(previousValue){
            newAdUnit.previousIdTemplate(previousValue);
        }, this, "beforeChange");

        newAdUnit.floatingOptions.floatingMode.subscribe(function(newValue){

            newAdUnit.floatAlways((newValue === 'always'));
            newAdUnit.floatWhenOutOfView((newValue === 'outOfView'));

            newAdUnit.floatingOptions.floatingMode(newValue);
        }, this);

        newAdUnit.width = ko.computed(function(){

            return this.getDimension('width', newAdUnit);
        }, this);

        newAdUnit.height = ko.computed(function(){

            return this.getDimension('height', newAdUnit);
        }, this);

        newAdUnit.idDimension.subscribe(function(newValue){

            let dimension = this.getTemplateViewModel().dimensions().find(i => i.id === newValue).dimension;

            newAdUnit.useCustomDimensions(('custom' === dimension));
        }, this);

        newAdUnit.useAmazon = ko.computed(function(){

            return `${this.shouldUseAmazon(newAdUnit.bidderTypes())}`;
        }, this);

        newAdUnit.onPageLoad = ko.computed(function(){

            return `${(!newAdUnit.isDynamicallyInjected())}`;
        }, this);

        newAdUnit.floatingOptionsField = ko.computed(function(){

            return `${JSON.stringify(newAdUnit.floatingOptions)}`;
        }, this);

        newAdUnit.refreshOptionsField = ko.computed(function(){

            return `${JSON.stringify(newAdUnit.refreshOptions())}`;
        }, this);

        newAdUnit.adTemplateData = ko.computed(function() {
            return this.getAdTemplateData(newAdUnit);
        }, this);

        newAdUnit.adTemplate = ko.computed(function() {
            return this.getAdTemplate(newAdUnit);
        }, this);

        newAdUnit.styleOptions.idStyleAdvertiserTag.subscribe(function(newValue){

            let styleAdvertiserTag = this.getTemplateViewModel().styleAdvertiserTags().find(i => i.id === newValue);

            newAdUnit.styleOptions.isCustom(('custom' === styleAdvertiserTag.name.toLowerCase()));
        }, this);

        newAdUnit.useAuctionsManager = ko.computed(function(){

            return (newAdUnit.idAuctionsManager());
        }, this);

        newAdUnit.useStickySlideAnimation.subscribe(function(newValue){

            newValue = !!newValue;

            if(newValue){

                newAdUnit.useAutoScroll(false);
            }

        }, this);

        newAdUnit.useInterscrollerAnimation.subscribe(function(newValue){

            newValue = !!newValue;

            if(newValue){

                newAdUnit.useStickySlideAnimation(true);
                newAdUnit.useAutoScroll(false);
            }

            newAdUnit.disableStickySlideAnimation(newValue);
            newAdUnit.disableAutoScroll(newValue);
        }, this);

        newAdUnit.useAutoScroll.subscribe(function(newValue){

            newValue = !!newValue;

            if(newValue){

                newAdUnit.useStickySlideAnimation(true);
                newAdUnit.useInterscrollerAnimation(false);
            }

            newAdUnit.disableStickySlideAnimation(newValue);
            newAdUnit.disableInterscrollerAnimation(newValue);
        }, this);

        newAdUnit.videoOptions.requestMode.id.subscribe(function(newValue){

            newAdUnit.videoOptions.isVideoRequestModeVideo(self.ID_VIDEO_PLAYER_REQUEST_MODE_VIDEO === newValue);

            if(this.ID_VIDEO_PLAYER_REQUEST_MODE_DISPLAY === newValue){

                newAdUnit.videoOptions.context.id(this.ID_VIDEO_PLAYER_CONTEXT_OUTSTREAM);
            }
        }, this);

        newAdUnit.videoOptions.context.id.subscribe(function(newValue){

            if(self.ID_VIDEO_PLAYER_CONTEXT_COMPANION === newValue){

                newAdUnit.videoOptions.includeDefaultPlaylist(true);
                newAdUnit.disableIncludeDefaultPlaylist(true);
            }
            else if(self.ID_VIDEO_PLAYER_CONTEXT_OUTSTREAM === newValue){

                newAdUnit.videoOptions.includeDefaultPlaylist(false);
                newAdUnit.videoOptions.allowAutoplay(true);
                newAdUnit.disableIncludeDefaultPlaylist(true);
            }
            else{

                newAdUnit.disableIncludeDefaultPlaylist(false);
            }
        }, this);

        newAdUnit.videoOptions.allowAutoplay.subscribe(function(newValue){

            newAdUnit.videoOptions.soundOn(!newValue);
        }, this);

        return newAdUnit;
    }

    private pushToArray(arr, obj) {
        const index = arr.findIndex((e) => e.identifier === obj.identifier);

        if (index === -1) {
            arr.push(obj);
        } else {
            arr[index] = obj;
        }
    }

    /**
     * Determines if we should use Amazon. Used as computed observable.
     *
     * @private
     */
    private shouldUseAmazon(bidderTypes){

        let allBidderTypes   = this.getTemplateViewModel().allBidderTypes();
        let amazonBidderType = allBidderTypes.find(i => i.name === 'Amazon');
        let useAmazon        = false;

        if('object' === typeof amazonBidderType && Object.keys(amazonBidderType).length !== 0){

            useAmazon = (!!bidderTypes.find(i => i === amazonBidderType.id));
        }

        return useAmazon;
    }

    /**
     * Processes ad template data.
     *
     * @param name
     * @param value
     * @param adUnit
     * @private
     */
    private processAdTemplateData(name: string, value: any, adUnit: any){

        let idZone        = adUnit.idZone();
        let zone          = this.getTemplateViewModel().zones().find(i => i.id === idZone);
        let divId         = adUnit.divId;
        let brand         = this.getTemplateViewModel().brand();
        let disabled      = false;
        let placeholder   = null;
        let slotName      = adUnit.slotName;
        let sizeArray     = adUnit.sizeArray;
        let positionsName = adUnit.positionsName;

        switch(name){

            case 'zone':

                if(zone){

                    value = zone.name;
                }
                else{

                    value = `${adUnit.zone().displayName} #${adUnit.zone().position}`;
                }

                disabled = true;

                break;

            case 'DIV ID':

                value    = divId;
                disabled = true;

                break;

            case 'CONTENT TYPE':
            case 'TEMPLATE':
            case 'NETWORK CATEGORY':
            case 'SITE CATEGORY':
            case 'POST ID':
            case 'VIDEO LIST':
            case 'VIDEO TITLE':
            case 'VIDEO DESCRIPTION':

                value       = null;
                disabled    = true;
                placeholder = 'Contextual';

                break;

            case 'SITE':

                value    = `["${brand.name}"]`;
                disabled = true;

                break;

            case 'USE AMAZON':

                value    = adUnit.useAmazon;
                disabled = true;

                break;

            case 'ON PAGE LOAD':

                value    = adUnit.onPageLoad;
                disabled = true;

                break;

            case 'REFRESH OPTIONS':

                value    = adUnit.refreshOptionsField;
                disabled = true;

                break;

            case 'FLOATING OPTIONS':

                value    = adUnit.floatingOptionsField;
                disabled = true;

                break;

            case 'WIDTH':

                value    = adUnit.width;
                disabled = true;

                break;

            case 'HEIGHT':

                value    = adUnit.height;
                disabled = true;

                break;

            case 'SLOT NAME':

                value    = slotName;
                disabled = true;

                break;

            case 'SIZES':

                value    = sizeArray;
                disabled = true;

                break;

            case 'POSITIONS':

                value    = positionsName;
                disabled = true;

                break;
        }

        return {
            value: value,
            disabled: disabled,
            placeholder: placeholder,
        };
    }

    /**
     * Returns requested dimension.
     *
     * @param dimensionName
     * @param adUnit
     * @private
     */
    private getDimension(dimensionName: string, adUnit){

        let dimensions = this.getTemplateViewModel().dimensions().find(i => i.id === adUnit.idDimension()).dimension;

        if('custom' !== dimensions && 'no_size' !== dimensions){

            let dimensionsData = dimensions.split("x");
            let index          = ('width' === dimensionName) ? 0 : 1;

            return `${dimensionsData[index]}px`;
        }
        else if('custom' === dimensions){

            return 'custom';
        }

        return 'auto';
    }

    //
    // Add info buttons.
    public addInfoButtons(adUnit, order){

        adUnit.byTimeHelpInfoButton = new infoButton_template(`byTimeHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.byTimeHelpInfoButton.setTitle('Help: By Time (Seconds)');
        adUnit.byTimeHelpInfoButton.setContent(this.byTimeHelpInfoButtonContent);

        adUnit.byTimePixelTriggerHelpInfoButton = new infoButton_template(`byTimePixelTriggerHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.byTimePixelTriggerHelpInfoButton.setTitle('Help: By Time Pixel Trigger');
        adUnit.byTimePixelTriggerHelpInfoButton.setContent(this.byTimePixelTriggerHelpInfoButtonContent);

        adUnit.byDistanceHelpInfoButton = new infoButton_template(`byDistanceHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.byDistanceHelpInfoButton.setTitle('Help: By Distance (Pixels)');
        adUnit.byDistanceHelpInfoButton.setContent(this.byDistanceHelpInfoButtonContent);

        adUnit.whenAdComesInviewHelpInfoButton = new infoButton_template(`whenAdComesInviewHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.whenAdComesInviewHelpInfoButton.setTitle('Help: Refresh When Ad Comes In-View');
        adUnit.whenAdComesInviewHelpInfoButton.setContent(this.whenAdComesInviewHelpInfoButtonContent);

        adUnit.prefetchHelpInfoButton = new infoButton_template(`prefetchHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.prefetchHelpInfoButton.setTitle('Help: Prefetch');
        adUnit.prefetchHelpInfoButton.setContent(this.prefetchHelpInfoButtonContent);

        adUnit.minimumTimeBufferHelpInfoButton = new infoButton_template(`minimumTimeBufferHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.minimumTimeBufferHelpInfoButton.setTitle('Help: Minimum Time Buffer (Seconds)');
        adUnit.minimumTimeBufferHelpInfoButton.setContent(this.minimumTimeBufferHelpInfoButtonContent);

        adUnit.floatingTypeHelpInfoButton = new infoButton_template(`floatingTypeHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.floatingTypeHelpInfoButton.setTitle('Help: Floating Type');
        adUnit.floatingTypeHelpInfoButton.setContent(this.floatingTypeHelpInfoButtonContent);

        adUnit.floatingOffsetHelpInfoButton = new infoButton_template(`floatingOffsetHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.floatingOffsetHelpInfoButton.setTitle('Help: Floating Offset (Pixels)');
        adUnit.floatingOffsetHelpInfoButton.setContent(this.floatingOffsetHelpInfoButtonContent);

        adUnit.floatingWidthAndHeightHelpInfoButton = new infoButton_template(`floatingWidthAndHeightHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.floatingWidthAndHeightHelpInfoButton.setTitle('Help: Floating Width & Height');
        adUnit.floatingWidthAndHeightHelpInfoButton.setContent(this.floatingWidthAndHeightHelpInfoButtonContent);

        adUnit.floatingPositionHelpInfoButton = new infoButton_template(`floatingPositionHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.floatingPositionHelpInfoButton.setTitle('Help: Floating Position');
        adUnit.floatingPositionHelpInfoButton.setContent(this.floatingPositionHelpInfoButtonContent);

        adUnit.styleHelpInfoButton = new infoButton_template(` styleHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.styleHelpInfoButton.setTitle('Help: Style');
        adUnit.styleHelpInfoButton.setContent(this.styleHelpInfoButtonContent);

        adUnit.dynamicallyInjectedHelpInfoButton = new infoButton_template(`dynamicallyInjectedHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.dynamicallyInjectedHelpInfoButton.setTitle('Help: Dynamic Injection');
        adUnit.dynamicallyInjectedHelpInfoButton.setContent(this.dynamicallyInjectedHelpInfoButtonContent);

        adUnit.connectedBelowAdHelpInfoButton = new infoButton_template(`connectedBelowAdHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.connectedBelowAdHelpInfoButton.setTitle('Help: Connected Below Ad');
        adUnit.connectedBelowAdHelpInfoButton.setContent(this.connectedBelowAdHelpInfoButtonContent);

        adUnit.stickySlideAnimationHelpInfoButton = new infoButton_template(`stickySlideAnimationHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.stickySlideAnimationHelpInfoButton.setTitle('Help: Sticky slide animation');
        adUnit.stickySlideAnimationHelpInfoButton.setContent(this.stickySlideAnimationHelpInfoButtonContent);

        adUnit.interscrollerAnimationHelpInfoButton = new infoButton_template(`interscrollerAnimationHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.interscrollerAnimationHelpInfoButton.setTitle('Help: Interscroller animation');
        adUnit.interscrollerAnimationHelpInfoButton.setContent(this.interscrollerAnimationHelpInfoButtonContent);

        adUnit.doNotUseSpaceHelpInfoButton = new infoButton_template(`doNotUseSpaceHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.doNotUseSpaceHelpInfoButton.setTitle("Help: Don't Use Space");
        adUnit.doNotUseSpaceHelpInfoButton.setContent(this.doNotUseSpaceHelpInfoButtonContent);

        adUnit.useAutoScrollHelpInfoButton = new infoButton_template(`useAutoScrollHelpInfoButton_${order}`, this.getTemplateViewModel(), this.caller);
        adUnit.useAutoScrollHelpInfoButton.setTitle("Help: Use AutoScroll");
        adUnit.useAutoScrollHelpInfoButton.setContent(this.useAutoScrollHelpInfoButtonContent);

        return adUnit;
    }

    //
    // Returns new style options object.
    private createStyleOptions(){

        return {
            idStyleAdvertiserTag: ko.observable(0),
            styleAdvertiserTag: ko.observable({}),
            customAdvertiserTag: ko.observable(''),
            isCustom: ko.observable(false),
            adUnitBackgroundDetailsEnabled: ko.observable(true),
        };
    }

    //
    // Returns new connected ad zone options object.
    private createConnectedAdZoneOptions(){

        let id = this.getTemplateViewModel().idZoneConnectedRight();

        return {
            zone: {id: ko.observable(id), name: null},
            position: {id: ko.observable(1), name: null},
        };
    }

    //
    // Returns new video options object.
    private createVideoOptions(videoOptions = null){

        if(videoOptions && Object.keys(videoOptions).length > 0){

            let newVideoOptions                   = this.createVideoOptions();
            let requestModeId                     = (typeof videoOptions.requestMode.id === 'function') ? videoOptions.requestMode.id() : videoOptions.requestMode.id;
            let contextId                         = (typeof videoOptions.context.id === 'function') ? videoOptions.context.id() : videoOptions.context.id;
            let allowAutoplay                     = (typeof videoOptions.allowAutoplay === 'function') ? videoOptions.allowAutoplay() : videoOptions.allowAutoplay;
            let soundOn                           = (typeof videoOptions.soundOn === 'function') ? videoOptions.soundOn() : videoOptions.soundOn;
            let showTitle                         = (typeof videoOptions.showTitle === 'function') ? videoOptions.showTitle() : videoOptions.showTitle;
            let includeDefaultPlaylist            = (typeof videoOptions.includeDefaultPlaylist === 'function') ? videoOptions.includeDefaultPlaylist() : videoOptions.includeDefaultPlaylist;
            let shouldDelayAfterAdSkipped         = (typeof videoOptions.shouldDelayAfterAdSkipped === 'function') ? videoOptions.shouldDelayAfterAdSkipped() : videoOptions.shouldDelayAfterAdSkipped;
            let delayAfterAdSkippedInMilliseconds = (typeof videoOptions.delayAfterAdSkippedInMilliseconds === 'function') ? videoOptions.delayAfterAdSkippedInMilliseconds() : videoOptions.delayAfterAdSkippedInMilliseconds;
            let useLargeSoundToggleButton         = (typeof videoOptions.useLargeSoundToggleButton === 'function') ? videoOptions.useLargeSoundToggleButton() : videoOptions.useLargeSoundToggleButton;

            newVideoOptions.isVideoRequestModeVideo(this.ID_VIDEO_PLAYER_REQUEST_MODE_VIDEO === requestModeId);
            newVideoOptions.requestMode.id(requestModeId);
            newVideoOptions.context.id(contextId);
            newVideoOptions.allowAutoplay(allowAutoplay);
            newVideoOptions.soundOn(soundOn);
            newVideoOptions.showTitle(showTitle);
            newVideoOptions.includeDefaultPlaylist(includeDefaultPlaylist);
            newVideoOptions.shouldDelayAfterAdSkipped(shouldDelayAfterAdSkipped);
            newVideoOptions.delayAfterAdSkippedInMilliseconds(delayAfterAdSkippedInMilliseconds);
            newVideoOptions.useLargeSoundToggleButton(useLargeSoundToggleButton);

            return newVideoOptions;
        }

        return {
            requestMode: {id: ko.observable(1), name: null},
            context: {id: ko.observable(3), name: null},
            allowAutoplay: ko.observable(true),
            soundOn: ko.observable(false),
            showTitle: ko.observable(false),
            isVideoRequestModeVideo: ko.observable(true),
            includeDefaultPlaylist: ko.observable(true),
            shouldDelayAfterAdSkipped: ko.observable(false),
            delayAfterAdSkippedInMilliseconds: ko.observable(5000),
            useLargeSoundToggleButton: ko.observable(false),
        };
    }

    //
    // Returns new style options object.
    private createUserActivityOptions(){

        return {
            idType: ko.observable(1),
            threshold: ko.observable(600),
        };
    }

    //
    // Returns new floating options object.
    private createFloatingOptions(floatingOptions = null){

        if(floatingOptions && Object.keys(floatingOptions).length > 0){

            let newFloatingOptions         = this.createFloatingOptions();
            let widthFloating              = (typeof floatingOptions.widthFloating === 'function') ? floatingOptions.widthFloating() : floatingOptions.widthFloating;
            let heightFloating             = (typeof floatingOptions.heightFloating === 'function') ? floatingOptions.heightFloating() : floatingOptions.heightFloating;
            let floatingMode               = (typeof floatingOptions.floatingMode === 'function') ? floatingOptions.floatingMode() : floatingOptions.floatingMode;
            let floatingOffset             = (typeof floatingOptions.floatingOffset === 'function') ? floatingOptions.floatingOffset() : floatingOptions.floatingOffset;
            let position                   = (typeof floatingOptions.position === 'function') ? floatingOptions.position() : floatingOptions.position;
            let widthFloatingPostfix       = (typeof floatingOptions.widthFloatingPostfix === 'function') ? floatingOptions.widthFloatingPostfix() : floatingOptions.widthFloatingPostfix;
            let heightFloatingPostfix      = (typeof floatingOptions.heightFloatingPostfix === 'function') ? floatingOptions.heightFloatingPostfix() : floatingOptions.heightFloatingPostfix;
            let floatingOffsetPostfix      = (typeof floatingOptions.floatingOffsetPostfix === 'function') ? floatingOptions.floatingOffsetPostfix() : floatingOptions.floatingOffsetPostfix;
            let useCloseButton             = (typeof floatingOptions.useCloseButton === 'function') ? floatingOptions.useCloseButton() : floatingOptions.useCloseButton;
            let floatUntilViewed           = (typeof floatingOptions.floatUntilViewed === 'function') ? floatingOptions.floatUntilViewed() : floatingOptions.floatUntilViewed;
            let floatUntilViewedDelay      = (typeof floatingOptions.floatUntilViewedDelay === 'function') ? floatingOptions.floatUntilViewedDelay() : floatingOptions.floatUntilViewedDelay;
            let isAllowedToRefloat         = (typeof floatingOptions.isAllowedToRefloat === 'function') ? floatingOptions.isAllowedToRefloat() : floatingOptions.isAllowedToRefloat;
            let refloatDelayInMilliseconds = (typeof floatingOptions.refloatDelayInMilliseconds === 'function') ? floatingOptions.refloatDelayInMilliseconds() : floatingOptions.refloatDelayInMilliseconds;
            let clickToFloat               = (typeof floatingOptions.clickToFloat === 'function') ? floatingOptions.clickToFloat() : floatingOptions.clickToFloat;

            newFloatingOptions.widthFloating(widthFloating);
            newFloatingOptions.heightFloating(heightFloating);
            newFloatingOptions.floatingMode(floatingMode);
            newFloatingOptions.floatingOffset(floatingOffset);
            newFloatingOptions.position(position);
            newFloatingOptions.widthFloatingPostfix(widthFloatingPostfix);
            newFloatingOptions.heightFloatingPostfix(heightFloatingPostfix);
            newFloatingOptions.floatingOffsetPostfix(floatingOffsetPostfix);
            newFloatingOptions.useCloseButton(useCloseButton);
            newFloatingOptions.floatUntilViewed(floatUntilViewed);
            newFloatingOptions.floatUntilViewedDelay(floatUntilViewedDelay);
            newFloatingOptions.isAllowedToRefloat(isAllowedToRefloat);
            newFloatingOptions.refloatDelayInMilliseconds(refloatDelayInMilliseconds);
            newFloatingOptions.clickToFloat(clickToFloat);

            return newFloatingOptions;
        }

        return {
            widthFloating: ko.observable(null),
            heightFloating: ko.observable(null),
            floatingMode: ko.observable('always'),
            floatingOffset: ko.observable(0),
            position: ko.observable(null),
            widthFloatingPostfix: ko.observable('px'),
            heightFloatingPostfix: ko.observable('px'),
            floatingOffsetPostfix: ko.observable('px'),
            useCloseButton: ko.observable(true),
            floatUntilViewed: ko.observable(false),
            floatUntilViewedDelay: ko.observable(0),
            isAllowedToRefloat: ko.observable(false),
            refloatDelayInMilliseconds: ko.observable(10000),
            clickToFloat: ko.observable(false),
        };
    }

    //
    // Returns new refresh options object.
    private createRefreshOptions(refreshOptions = null){

        if(refreshOptions && Object.keys(refreshOptions).length > 0){

            refreshOptions.pixelTrigger = ko.observable(refreshOptions.pixelTrigger);

            return refreshOptions;
        }

        let adBehaviours = this.getTemplateViewModel().adBehaviours();

        return {
            mode: {id: ko.observable(1), name: null},
            timer: (adBehaviours && adBehaviours.hasOwnProperty('refreshLogicRefreshTime')) ? adBehaviours['refreshLogicRefreshTime'] : 30,
            pixelTrigger: ko.observable(true),
            pixelDistance: null,
            preFetch: (adBehaviours && adBehaviours.hasOwnProperty('refreshLogicPrefetch')) ? Boolean(adBehaviours['refreshLogicPrefetch']) : true,
            minimumTimeBuffer: (adBehaviours && adBehaviours.hasOwnProperty('refreshLogicMinBufferTime')) ? adBehaviours['refreshLogicMinBufferTime'] : 2,
            inview: false,
        };
    }

    //
    // Returns new custom dimensions object.
    private createCustomDimensions(){

        return {
            width: null,
            height: null,
            widthPostfix: 'px',
            heightPostfix: 'px',
        };
    }

    //
    // Generates a unique div ID.
    private generateDivId(zoneName){

        let divId = zoneName.replace(/[-_]/g, " ");
        divId = divId.replace(/\b\w/g, l => l.toUpperCase());
        divId = divId.replace(/\s/g, '');
        divId = divId[0].toLowerCase() + divId.substring(1);
        divId = divId.replace(/[^A-Za-z0-9\-]/g, '');

        let uniqid = (prefix = divId, random = false) => {
            const sec = Date.now() * 1000 + Math.random() * 1000;
            const id = sec.toString(16).replace(/\./g, "");
            return `adsninja-ad-unit-${prefix}-${id}${random ? `.${Math.trunc(Math.random() * 100000000)}`:""}`;
        };

        return uniqid();
    }

}
