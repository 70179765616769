import { vnTemplate } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate';
import { pagination_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/pagination_template';
import { resultsInfo_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/resultsInfo_template';
import { filterSimple_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterSimple_template";
import { multipleSelector_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/multipleSelector_template";
import { filterDate_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDate_template";
import {vnApp_ads_ninja} from "../vnApp_ads_ninja";
import { exportDataCsv } from "../../../vendor/valnet/valnet_jslib/vnHelpers/exportDataCsv";

declare var ko: any;

export class sponsoredRevenue_template extends vnTemplate {

    protected brandsFilter : multipleSelector_template;
    protected datesFilter: filterDate_template;
    protected keyword: filterSimple_template;
    protected pagination: pagination_template;
    protected resultsInfo: resultsInfo_template;
    protected exportDataCsv: exportDataCsv;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="sub-header margin-top">
                <div class="w-justify">
                
                    <div class="w-left">
                    
                        <div class="multi">
                            <label class="form-label form-label-box multi"></label> 
                            ${this.brandsFilter.getHtmlBuild()}
                        </div>
                        
                        ${this.datesFilter.getHtmlBuild()}
    
                    </div>
                    <div class="w-right">
                        <button style="margin-bottom: 0" class="btn icon i-export" type="button" data-bind="event: {click: onExportData}">Export</button>
    
                    </div>
                </div>
            </div>
            
            <div class="w-filters-selected">
                <div id="filters-selected" class="filters-selected">
                    <div id="selected-brands"></div>
                </div>
            </div>

            <div class="list-header">
                <div class="w-justify">
                
                    <div class="w-left" data-bind="if: hasWriteAccess">
                        <button data-bind="click: onClickAdd" class="btn icon i-add" type="button">Add Sponsored Revenue</button>
                    </div>
                    
                    <div class="w-center">
                        ${this.keyword.getHtmlBuild()}
                    </div>
 
                    <div class="w-right">
                        ${this.pagination.getHtmlBuild()}
                    </div>
                    
                </div>
            </div>
  
            <div class="w-list-results" id="list-results">
                <table class="list-results">
                    ${this.resultsInfo.getHtmlBuild()}
                    <thead class="list-header-section">
                        <tr>    
                            <th class="w-btn" data-bind="visible: hasUpdateAccess"><span>edit</span></th>
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'brandName') }, css: { 'list-sort-desc': orderByAndDirection()=='brandName_DESC', 'list-sort-asc': orderByAndDirection()=='brandName_ASC' }" class="icon i-dropdown-simple">brand</span></th>
                            <th> <span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'dealName') }, css: { 'list-sort-desc': orderByAndDirection()=='dealName_DESC', 'list-sort-asc': orderByAndDirection()=='dealName_ASC' }" class="icon i-dropdown-simple">deal name</span></th>
                            <th> <span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'invoiceNumber') }, css: { 'list-sort-desc': orderByAndDirection()=='invoiceNumber_DESC', 'list-sort-asc': orderByAndDirection()=='invoiceNumber_ASC' }" class="icon i-dropdown-simple">invoice number</span></th>
                            <th> <span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'revenue') }, css: { 'list-sort-desc': orderByAndDirection()=='revenue_DESC', 'list-sort-asc': orderByAndDirection()=='revenue_ASC' }" class="icon i-dropdown-simple">revenue</span></th>
                            <th> <span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'permalink') }, css: { 'list-sort-desc': orderByAndDirection()=='permalink_DESC', 'list-sort-asc': orderByAndDirection()=='permalink_ASC' }" class="icon i-dropdown-simple">permalink</span></th>
                            <th> <span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'date') }, css: { 'list-sort-desc': orderByAndDirection()=='date_DESC', 'list-sort-asc': orderByAndDirection()=='date_ASC' }" class="icon i-dropdown-simple">date</span></th>
                            <th class="w-btn" data-bind="visible: hasDeleteAccess"><span>delete</span></th>
                        </tr> 
                    </thead>
                    <tbody data-bind="foreach: data">
    
                        <tr>
                            <td class="" data-bind="visible: $parent.hasUpdateAccess"><button data-bind="visible: $parent.hasUpdateAccess, event: { click: $parent.onClickEdit }" class="btn icon-only icon i-edit" type="button"><span class="hidden-text">Edit</span></button></td>
                            <td><span data-bind="text: brandName"></span></td>
                            <td><span data-bind="text: dealName"></span></td>
                            <td><span data-bind="text: invoiceNumber"></span></td>
                            <td><span data-bind="text: revenue"></span></td>
                            <td><span data-bind="text: permalink"></span></td>
                            <td><span data-bind="text: date"></span></td>
                            <td class="" data-bind="visible: $parent.hasDeleteAccess"><button data-bind="visible: $parent.hasDeleteAccess, event: { click: $parent.onClickDelete }" class="btn icon-only icon i-delete" type="button"><span class="hidden-text">Delete</span></button></td>
                        </tr>
                        
                    </tbody>
                    <tfoot>
                    </tfoot>
                </table>
            </div>
        `;
    }


    protected initPartial(): void {

        this.resultsInfo     = new resultsInfo_template('resultsInfo', this.getTemplateViewModel(), this.caller);

        this.brandsFilter = new multipleSelector_template('brandsFilter', this.getTemplateViewModel(), this.caller);
        this.brandsFilter.setLabel("brands");
        this.brandsFilter.setFilterContainerClass('margin-neg');
        this.brandsFilter.setTagContainerId('selected-brands');
        this.brandsFilter.setTagContainerCaption('Selected Brand:');

        this.datesFilter = new filterDate_template("datesFilter", this.getTemplateViewModel(), this.caller);

        this.datesFilter.getTemplateViewModel().startDate(null);
        this.datesFilter.getTemplateViewModel().endDate(null);
        this.datesFilter.getTemplateViewModel().selectedMonth(null);
        this.datesFilter.getTemplateViewModel().lastStartDate(null);
        this.datesFilter.getTemplateViewModel().lastEndDate(null);
        this.datesFilter.setCalenderDates(null, null);

        this.keyword = new filterSimple_template("keyword", this.getTemplateViewModel(), this.caller);

        this.pagination = new pagination_template("pagination", this.getTemplateViewModel(), this.caller);
        this.pagination.getTemplateViewModel().itemPerPage = 25;

        this.exportDataCsv = new exportDataCsv();

    }

    protected getTemplateName(): string{

        return "sponsoredRevenue_template";
    }

    protected getMainElementClasses(): Array<string>{

        return [];
    }

    protected buildViewModel(): any{

        // define the viewmodel data/ functions
        let huit_neuf_this = this;
        return  {
            orderBy: ko.observable('date'),
            orderByDirection: ko.observable('ASC'),
            orderByAndDirection: ko.observable('date_ASC'),
            data: ko.observableArray([]),
            sponsoredRevenueToDelete: ko.observableArray([]),
            hasUpdateAccess: ko.observable(this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SPONSORED_REVENUE, vnApp_ads_ninja.ACCESS_TYPE_UPDATE)),
            hasDeleteAccess: ko.observable(this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SPONSORED_REVENUE, vnApp_ads_ninja.ACCESS_TYPE_DELETE)),
            hasWriteAccess: ko.observable(this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SPONSORED_REVENUE, vnApp_ads_ninja.ACCESS_TYPE_WRITE)),
            exportData: ko.observableArray([]),
            onClickReorder: function(value, data, event){ huit_neuf_this.onClickReorder(value, data, event); },
            onClickEdit : function(data, event){ huit_neuf_this.onClickEdit(data.id); },
            onClickAdd : function(){ huit_neuf_this.onClickAdd(); },
            onClickDelete: function(data,event){ huit_neuf_this.onClickDelete(data); },
            onExportData : function(){ huit_neuf_this.onExportData(); },
        };
    }

    //
    // implementation of base class [vnTemplate]
    public getViewModelForRequest(){

        return {
            filter: {
                brands: this.getTemplateViewModel().brandsFilter.selectedItems,
                dateFilterType: this.getTemplateViewModel().datesFilter.selectedFilter(),
                startDate: this.getTemplateViewModel().datesFilter.startDate(),
                endDate: this.getTemplateViewModel().datesFilter.endDate(),
                startDateRange: this.getTemplateViewModel().datesFilter.fromRange(),
                endDateRange: this.getTemplateViewModel().datesFilter.toRange(),
                keyword : this.getTemplateViewModel().keyword.filterKeyword(),
            },
            pagination: this.getTemplateViewModel().pagination,
            orderBy: this.getTemplateViewModel().orderBy(),
            orderByDirection: this.getTemplateViewModel().orderByDirection(),
            sponsoredRevenueToDelete: this.getTemplateViewModel().sponsoredRevenueToDelete,
        }
    }

    public onClickReorder(value, data, event): void{

        this.getTemplateViewModel().orderBy(value);

        if( this.getTemplateViewModel().orderByDirection() == 'DESC' ){

            this.getTemplateViewModel().orderByDirection('ASC');
        }
        else{

            this.getTemplateViewModel().orderByDirection('DESC');
        }

        this.getTemplateViewModel().orderByAndDirection(value + '_' + this.getTemplateViewModel().orderByDirection());
        this.caller.dataReorder();
    }

    public buildDatePickerCalendar(){

        this.datesFilter.buildCalendar();
    }

    public onClickEdit(id){

        this.caller.editSponsoredRevenue(id);
    }

    public onClickAdd(){

        this.caller.addSponsoredRevenue();
    }

    public setBrandFilterItems(items){

        this.brandsFilter.setItems(items);
    }

    public setData(data): void{

        this.getTemplateViewModel().data(data);
    }

    public setPaginationTotalCount(totalCount){

        this.pagination.setNumberItemToPaginate(totalCount);
    }

    public resetPageNumber() {

        this.pagination.getTemplateViewModel().pageNumber(1);
    }

    public dataReorder(id) {

        this.caller.getListData();
    }

    public onClickDelete(data) {

        this.getTemplateViewModel().sponsoredRevenueToDelete(data);
        this.caller.confirmationModal.setTitle('Confirm Deletion');
        this.caller.confirmationModal.setContext('delete');
        this.caller.confirmationModal.setMessageMain('Are you sure you would like to delete this Sponsored Revenue?');
        this.caller.confirmationModal.setDataConfirm(data.id);
        this.caller.confirmationModal.setMessageSecondary(data.dealName);
        this.caller.confirmationModal.show();
    }

    public setResultInfo(nbResults, nbPages, execTime){

        this.resultsInfo.setExecutionTime(execTime);
        this.resultsInfo.setNbResults(nbResults);
        this.resultsInfo.setNbPages(nbPages);
    }

    private onExportData(){

        this.caller.getExportData();
    }


    public setExportData(exportData){
        
        let exportDataView = [];

        for (let exportDataRow of exportData) {

            let exportDataViewRow = [];
            exportDataViewRow["Brand"] = exportDataRow.brandName;
            exportDataViewRow["Deal Name"] = exportDataRow.dealName;
            exportDataViewRow["Invoice Number"] = exportDataRow.invoiceNumber;
            exportDataViewRow["Revenue"] = exportDataRow.revenue;
            exportDataViewRow["Permalink"] = exportDataRow.permalink;
            exportDataViewRow["Comment"] = exportDataRow.comment;
            exportDataViewRow["Date"] = exportDataRow.date;
        

            exportDataView.push(exportDataViewRow);
        }

        this.getTemplateViewModel().exportData(exportDataView);
    }

    private exportData(obj){

        let fileName = "SponsoredRevenue_"  + this.brandsFilter.getTemplateViewModel().selectedItems() + this.datesFilter.getTemplateViewModel().startDate() + "_" + this.datesFilter.getTemplateViewModel().endDate()+ ".csv";
        this.exportDataCsv.exportToCsv(fileName, this.getTemplateViewModel().exportData());
    }
}