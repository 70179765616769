import { vnModule_knockoutJS } from "./vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../XMLHttpRequestHandler/XMLHttpRequestHandler";
import { rolesView_template } from "../vnKnockoutJsTemplates/rolesView_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class roles_view extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected rolesViewTemplate: rolesView_template;

    protected getModuleName(){
        return "roles_view";
    }

    buildContent() : Array<Element>{
        
        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");

        if(zoneHeader){

            let loadedModule = zoneHeader.loadedModule;
            (<header>loadedModule).setHeaderTitle("Role View");
        }
        
        this.rolesViewTemplate = new rolesView_template("rolesView_template", this.viewModel, this);

        return [this.rolesViewTemplate.build()];
    }

    protected backToList(){
        page.redirect('/roles');
    }
    //
    // fill the template page with data.
    fillPage() : void{
        this.getFormData();
    }
    //
    // getting data from server by ajax call.
    public getFormData(): void {

        this.loadingIn(this.rolesViewTemplate.builtTemplate.children.namedItem("view-results"));
        let id: number;

        if( (id = this.getVnAppZone().app.params.id) && !isNaN(id)) {
            this.rolesViewTemplate.getTemplateViewModel().idRole(id);
        }
        
        this.XHRHdl = new XMLHttpRequestHandler(`/api/roles/get/${id}`, [['lock', '0'], ['mode', 'view']], this);
        this.XHRHdl.onReadyStateFunction = this.onGetRoleDataReturn;
        this.XHRHdl.execute();
    }

    //
    // Handling response data
    protected onGetRoleDataReturn(req, obj): Function {

        return function () {
            obj.manageResponse(req, obj, function () {
                let responseParsed = JSON.parse(req.request.response);
                obj.setRole(responseParsed);
                obj.loadingOut(obj.rolesViewTemplate.builtTemplate.children.namedItem("view-results"));
            });
        }
    }
    //
    // set role on rolesView_template
    public setRole(role: any) : void {
        this.rolesViewTemplate.setRole(role);
    }
}
