import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class brandEdit_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
        <div class="w-form" style="width:90rem;">
            <div class="form-header">
                <!-- ko ifnot: idBrand -->
                <h3 class="form-title icon i-add">Add New brand</h3>
                <!-- /ko -->

                <!-- ko if: idBrand -->
                <h3 class="form-title icon i-content">Edit brand</h3>
                <!-- /ko -->

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>

            <div class="form-body">
                <div class="form-group row">
                    <label for="name" class="form-label">name</label>
                    <input id="name" data-bind="value: name" type="text" class="input-text">
                </div>                   
                <div class="form-group row">
                    <label for="shortname" class="form-label">short name</label>
                    <input id="shortname" data-bind="value: shortname" type="text" class="input-text">
                </div>                    
                <div class="form-group row">
                    <label for="domain" class="form-label">domain</label>
                    <input id="domain" data-bind="value: domain" type="text" class="input-text">
                </div>                     
                <div class="form-group row">
                    <label for="idDepartment" class="form-label">Department</label>
                    <select name="idDepartment" 
                        data-bind="
                            options: departments,
                            optionsText: 'name',
                            optionsValue: 'id',
                            value: idDepartment
                        ">
                    </select>
                </div>
            </div>   

            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save brand</span></button>  
            </div>
        </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "brandEdit-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{
        let huit_neuf_this = this;

        return {
            idBrand: ko.observable(''),
            name: ko.observable(''),
            shortname: ko.observable(''),
            domain: ko.observable(''),
            idDepartment: ko.observable(''),
            departments: ko.observable([]),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        let viewModel = this.getTemplateViewModel()
        return {
            idBrand: viewModel.idBrand(),
            name: viewModel.name(),
            shortname: viewModel.shortname(),
            domain: viewModel.domain(),
            idDepartment: viewModel.idDepartment()
        };
    }

    public setBrand(brand): void{
        this.getTemplateViewModel().idBrand(brand.id);
        this.getTemplateViewModel().name(brand.name);
        this.getTemplateViewModel().shortname(brand.shortname);
        this.getTemplateViewModel().domain(brand.domain);
        this.getTemplateViewModel().idDepartment(brand.idDepartment);
    }

    public setDepartments(departments): void{
        departments.unshift({id: null, name: ''});
        this.getTemplateViewModel().departments(departments);
    }    

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{
        this.caller.saveBrand();
    }

    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{
        this.caller.cancelEditBrand();
    }    
}
