import {vnTemplate} from "./vnTemplate";
import {infoButton_template} from "./infoButton_template";

declare var ko: any;

export class filterSimple_template extends vnTemplate{

    protected delay = 400;
    protected debounceTimerId: number;
    protected lastRequestedValue = "";
    public tooltip: infoButton_template;

    protected getTemplateHtml(): string{

        return `
            <div class="w-search-feild icon i-search2">
	            <input data-bind="textInput: filterKeyword, event: { keyup: onKeyUpFilterAction}, valueUpdate : 'afterkeydown'" type="input" class="search-feild">
	            <input data-bind="value: filterKeywordLastKeyPress" type="hidden">
	            <input date-bind="value: filterKeywordRequestedKeyWord" type="hidden">
	            <button data-bind="event: { click: onClearInput }, css: { 'is-showing' : filterInputClearButton }" class="close-btn icon i-x-after" id="close-btn" type="button"></button>
                <div data-bind="visible: tooltipVisible" class="w-tooltips-adv auto-height">
                    ` + this.tooltip.getHtmlBuild() + `
                </div>
            </div>
            `;
    }

    protected getTemplateName(): string{
        return "filterSimple-template";
    }

    protected getMainElementClasses(): Array<string>{
    	return ["filter-keyword-wrap"];
    }

    protected initPartial(): void{
        this.tooltip = new infoButton_template('toolTipSearch', this.getTemplateViewModel(), this.caller)
        this.tooltip.setTitle("Search Bar:");
    }

    protected buildViewModel(): any{

        // define the viewmodel data/ functions
        let huit_neuf_this = this;
        return  {    filterKeyword: ko.observable("")
                    ,filterKeywordLastKeyPress: ko.observable("")
                    ,filterKeywordRequestedKeyWord: ko.observable("")
                    ,filterInputClearButton: ko.observable(0)
                    ,onClearInput: function(data, event){ huit_neuf_this.onClearInput(event); }
                    ,onKeyUpFilterAction : function(data,event){ huit_neuf_this.onKeyUpFilterAction(data, event) }
                    ,tooltipVisible: ko.observable(false)
                };
    }

    /*
    *
    * Calls the caller definition of the "filterSimpleOnChange" function
    */
    protected onKeyUpFilterAction(data, event){

        this.getTemplateViewModel().filterInputClearButton(1);

        clearTimeout(this.debounceTimerId);

        let _myThis = this;
        this.debounceTimerId = window.setTimeout(function(){

            _myThis.cancelFilter();
            _myThis.applyFilter();
        }, this.delay);
    }

    protected onClearInput(event){

        this.getTemplateViewModel().filterInputClearButton(0);
        this.getTemplateViewModel().filterKeyword("");
        this.applyFilter();
    }

    private applyFilter(){

        this.caller.filterSimpleOnChange(this.idElement);
    }

    private cancelFilter(){

        this.caller.filterSimpleCancel();
    }

    public setFilterKeyword(filterKeyword) {
        this.getTemplateViewModel().filterKeyword(filterKeyword);
        this.applyFilter();
    }

    public setTooltipTitle(tooltipTitle) {

        this.tooltip.setTitle(tooltipTitle);
    }

    public setTooltipContent(tooltipContent) {

        if (tooltipContent === '') {

            this.getTemplateViewModel().tooltipVisible(false);
        }else  {

            this.getTemplateViewModel().tooltipVisible(true);
        }
        this.tooltip.setContent(tooltipContent);
    }

    public setSearchableFields(searchableFields: Array<any>) {

        let mesg = "<p>This allows you to search partial matches, contained in the following fields:</p><ul><li>" + searchableFields.join(",</li><li>") + "</li></ul>";
        this.setTooltipContent(mesg);
    }
}

