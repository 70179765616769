import {vnTemplate} from "./vnTemplate";

declare var ko: any;

export class reset_template extends vnTemplate{

    protected getTemplateHtml(): string{

        return `
                <div class="w-form center over" id="retrieve-password" data-bind="style: {display: invalidateToken}">
                    <div class="form-header">
                        <h3>Token has expired! Please Provide your Username or Email to retrieve your password.</h3>
                    </div>
                    <form class="form-body" onsubmit="return false;">
                    <div>
                        <div class="form-group oneline no-margin">
                            <div class="inner-groupe">
                                <input data-bind="value: userInput" type="hidden" />
                                <input data-bind="event: { click: onClickRadioPasswordAction}" type="radio" name="radioPass" id="radio_email" value="1" checked>
                                <label for="radio_email">Email</label>
                                <input data-bind="event: { click: onClickRadioPasswordAction}" type="radio" name="radioPass" id="radio_name" value="0" >
                                <label for="radio_name">User Name</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <input data-bind="value: retrieveInput" id="retrieveInput" type="text" class="input-text"/>
                        </div>
                        </div>
                    </form>
                    <div class="form-footer">
                        <button class="btn dark full" type="button" data-bind="event: { click: onClickRetrievePasswordAction}">Send Me Reset Link</button>
                    </div>
                </div>

                <div class="w-form center" id="reset-password-form" data-bind="style: {display: validateToken}">
                    <div class="form-header">
                        <h3>Please provide new password</h3>
                    </div>
                    <form class="form-body">
                        <div class="form-group">
                            <label>New Password</label>
                            <input data-bind="value: pw, uniqueName: true, event: { keyup: onKeyUpLoginAction}" type="password" class="input-text"  />
                        </div>
                        <div class="form-group">
                            <label>Confirm New Password</label><input data-bind="value: pwc, uniqueName: true, event: { keyup: onKeyUpLoginAction}" type="password" class="input-text" />
                            <small class="form-error"></small>
                        </div>
                        
                    </form>
                    <div class="form-footer">
                        <button data-bind="event: { click: onResetPasswordAction}" type="button" class="btn dark full">Reset Password</button>
                    </div>
                </div>

                <div class="w-form center over" data-bind="style: {display: sentMessage}">
                    <div class="form-header">
                        <h3>Password reset/update confirmation</h3>
                        <button data-bind="event: { click: onClickCloseRetrieveAction}" type="button" class="close-btn icon i-close"></button>
                    </div>
                    <form class="form-body">
                        <div class="form-group">
                             <span data-bind="text: retrieveInfo" class="sub-title"></span>
                        </div>
                    </form>
                    <div class="form-footer">
                        <button class="btn dark full" type="button" data-bind="event: { click: onClickCloseRetrieveAction}">Go back to Log In</button>
                    </div>
                </div>
            `;
    }

    protected getTemplateName(): string{
        return "reset-password-template-valid";
    }

    protected getMainElementClasses(): Array<string>{
        return ["form-container"];
    }

    protected buildViewModel(): any{

        let huit_neuf_this = this;
        return  {    pw: ko.observable("")
                    ,pwc: ko.observable("")
                    ,token: ko.observable("")
                    ,retrieveInput: ko.observable("")
                    ,retrieveInfo: ko.observable("")
                    ,userInput: ko.observable("1")
                    ,sentMessage: ko.observable("none")
                    ,validateToken: ko.observable("none")
                    ,invalidateToken: ko.observable("none")
                    ,onKeyUpLoginAction: this.onKeyUpLoginAction
                    ,onClickRetrievePasswordAction: function(data, event){ huit_neuf_this.onClickRetrievePasswordAction(); }
                    ,onClickRadioPasswordAction: this.onClickRadioPasswordAction
                    ,onClickCloseRetrieveAction: function(data, event){ huit_neuf_this.onRedirectToLogin(); }
                    ,onResetPasswordAction: function(data, event){ huit_neuf_this.caller.resetPassword(); }
                };
    }

    public onKeyUpLoginAction(data, event) {

        event.preventDefault();
        if (event.keyCode == 13) {
            data.onResetPasswordAction();
        }
    }

    protected onClickRetrievePasswordAction(){
        this.caller.retrievePassword();
    }

    protected onClickRadioPasswordAction(data,event){
        if(event.target.getAttribute("id") == "radio_email"){
            data.userInput("1");
        }else{
            data.userInput("0");
        }
        data.retrieveInput("");

        return true;
    }

    protected onRedirectToLogin(){
        this.caller.redirectToLogin();
    }

}
