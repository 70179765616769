import { geoGroupList_template } from '../components/templates/geoGroupList_template';
import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class geoGroup_list extends vnModule_knockoutJS{

    protected geoGroupList_template: geoGroupList_template;
    protected XHRHdl: XMLHttpRequestHandler;

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'geoGroup_list';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Geo Groups List");


        this.geoGroupList_template = new geoGroupList_template('geoGroup_list', this.viewModel, this)

        return [this.geoGroupList_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getListData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public paginationOnPageClick(): void{

        this.getListData();
    }

    /**
     * @inheritDoc
     */
    protected reorderSspGroups(): void{

        this.getListData();
    }


    /**
     * Fetches the list data.
     */
    public getListData(): void{

        this.loadingIn(this.geoGroupList_template.builtTemplate.children.namedItem('list-results'));

        let subViewModel = this.geoGroupList_template.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(['data', ko.toJSON(subViewModel)] );

        this.XHRHdl = new XMLHttpRequestHandler('/api/geo_groups/get/', usrReq_params, this);
        this.XHRHdl.onReadyStateFunction = this.onDataRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for data request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateList(responseParsed);
                obj.setResultInfo(responseParsed.totalCount, responseParsed.totalPage);
                obj.updatePagination(responseParsed.totalCount);
                obj.loadingOut(obj.geoGroupList_template.builtTemplate.children.namedItem('list-results'));
            });
        }
    }

    /**
     * Update the module list using the objects list.
     *
     * @param responseParsed
     */
    protected updateList(responseParsed: any): void{

        this.loadingIn(this.geoGroupList_template.builtTemplate.children.namedItem('list-results'));

        let data = responseParsed.data;

        for(let entry of data){

            entry.geos_list_name.sort();

            entry.geos_list_name = entry.geos_list_name.join(', ');
        }

        this.geoGroupList_template.setGeoGroups(responseParsed.data);
    }

    /**
     * Hook.
     *
     * Redirects to create source page.
     */
    protected addGeoGroup(): void{

        page(`/geo_groups/add`);
    }

    /**
     * Hook.
     *
     * Redirects to create source page.
     */
    protected editGeoGroup(id): void{

        page(`/geo_groups/edit/${id}`);
    }

    /**
     * Deletes the requested resource.
     *
     * @param id
     * @protected
     */
    protected deleteGeoGroup(id): void{

        this.loadingIn(this.geoGroupList_template.builtTemplate.children.namedItem('list-results'));

        this.XHRHdl                      = new XMLHttpRequestHandler(`/api/geo_groups/delete/${id}`, [], this);
        this.XHRHdl.mode                 = XMLHttpRequestHandler_requestType.POST;
        this.XHRHdl.onReadyStateFunction = this.onDeleteRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for delete request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDeleteRequestReturn(req, obj): Function{

        return function(){

            obj.loadingOut(obj.geoGroupList_template.builtTemplate.children.namedItem('list-results'));

            obj.manageResponse(req, obj, function(){

                obj.getListData();
            });
        }
    }


    protected setResultInfo(totalCount, totalPage){
        let execTime = Math.round((new Date().getTime() - this.XHRHdl.requestStartTime)/1000*100)/100;
        this.geoGroupList_template.setResultInfo(totalCount, totalPage, execTime);
    }

    protected updatePagination(totalCount) {

        this.geoGroupList_template.updatePagination(totalCount);

    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.deleteGeoGroup(data);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

    }

    /**
     * Hook.
     *
     * Cancels data request.
     */
    protected filterSimpleCancel(){

        if(this.XHRHdl){

            this.XHRHdl.cancelAndReset();
        }
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected filterSimpleOnChange(){

        this.viewModel.geoGroup_list.pagination.pageNumber(1);
        this.getListData();
    }
}