import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class sharedHeader_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <!-- ko with: siteConfig() -->
                <div class="form-header">
                    <!-- ko ifnot: id -->
                    <h3 class="form-title icon i-unit-header">Edit Shared Header</h3>
                    <!-- /ko -->
    
                    <!-- ko if: id -->
                    <h3 class="form-title icon i-unit-header">Edit Shared Header</h3>
                    <!-- /ko -->
    
                    <button data-bind="event: { click: $parent.onClickCancel }" class="close-button icon i-close-2" type="button"></button>
                </div>
                <div class="w-form-body">
                    <div class="form-body full-width">
                        <div class="form-group">
                            <textarea id="adsTxt" data-bind="value: sharedHeader" class="input-text long extra"></textarea>
                        </div>
                     </div>
                </div>
            <!-- /ko -->   

            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return 'sharedHeader-template';
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ['w-form', 'w-form-large', 'margin-top'];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;

        return {
            siteConfig: ko.observable({
                id : null,
                sharedHeader: ko.observable(''),
            }),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); }
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            siteConfig: this.getTemplateViewModel().siteConfig(),
        };
    }

    /**
     * Sets form data.
     *
     * @param data
     */
    public setData(data): void{

        this.getTemplateViewModel().siteConfig().id = data.id;
        this.getTemplateViewModel().siteConfig().sharedHeader(data.sharedHeader);
    }

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        this.caller.save();
    }

    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancel();
    }
}
