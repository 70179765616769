import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class prebidConfigurator_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <h3 class="form-title icon i-prebid-conf">Prebid Configurator</h3>
                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
            <div class="w-form-body">
                <div class="form-body full-width">
                    <div data-bind="foreach: options">
                        <div class="form-group">
                            <strong class="form-sub-title" data-bind="text: name"></strong>
                        </div>
                        <div data-bind="foreach: items" class="form-group grid">
                            <div class="form-check-inline">
                                <input type="checkbox" data-bind="checked: checked, attr:{ 'id': 'option_' + id, 'name': 'option_' + id }, event: {click:$parents[1].onClickCheckBidder}">
                                <label class="form-label icon i-check title" data-bind="text: displayName, attr:{ 'for': 'option_' + id }"></label>   
                            </div>
                        </div>
                    </div>
                    <div data-bind="visible:showPrebidServer()">
                         <div class="form-group">
                            <label for="" class="form-sub-title">Prebid Server SSPs</label>
                         </div>
                         <div data-bind="foreach: allServerSsps" class="form-group grid">
                            <div class="form-check-inline">
                                <input type="checkbox" data-bind="checked: $parent.serverSsps(), value: id, attr:{'id' : 'ssp_' + id, 'name' : 'ssp_' + id}">
                                <label class="form-label icon i-check" data-bind="text: displayName, attr:{'for': 'ssp_' + id}" style="white-space: nowrap;"></label>
                            </div>
                        </div>
                    </div>
                 </div>
            </div>

            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return 'prebidConfigurator-template';
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ['w-form', 'w-form-large', 'prebid'];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;

        return {
            id: ko.observable(0),
            options: ko.observableArray([]),
            showPrebidServer: ko.observable(false),
            idSspGroup: ko.observable(''),
            allServerSsps: ko.observableArray([]),
            serverSsps: ko.observableArray([]),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
            onClickCheckBidder: function (data, event) {huit_neuf_this.onClickCheckBidder(data);return true}
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            id: this.getTemplateViewModel().id(),
            options: this.getTemplateViewModel().options(),
            idSspGroup: this.getTemplateViewModel().idSspGroup(),
            serverSsps: this.getTemplateViewModel().serverSsps(),
        };
    }

    //
    // Sets form data.
    public setData(data): void{

        console.log(data);
        let options = [];
        let sectionName = {
            bidAdapter: 'Bid Adapters',
            analyticsAdapter: 'Analytics Adapters',
            idSystem: 'ID System',
            realTimeDataProvider: 'Real Time Data Providers',
            adServerVideo: 'Ad Server Video Modules',
        };

        for(let x in data.options){

            options.push({ name: sectionName[x] || x, items: data.options[x] });
        }

        this.getTemplateViewModel().options(options);
        this.getTemplateViewModel().id(data.id);
        this.getTemplateViewModel().showPrebidServer(data.prebidServerDefault);
        this.getTemplateViewModel().idSspGroup(data.idSspGroup);
        this.getTemplateViewModel().serverSsps(data.serverSsps);
    }

    protected onClickCheckBidder(data){

        if(data.name == "prebidServer"){

            this.getTemplateViewModel().showPrebidServer(data.checked);
        }

        return true;
    }

    //
    // Sets filter data.
    public setFilterData(data): void{

    }

    //
    // Handles clicking save button event.
    protected onClickSave(): void{

        this.caller.save();
    }

    //
    // Handles clicking cancel button event.
    protected onClickCancel(): void{

        this.caller.cancel();
    }


    public setAllServerSsps(data) : void {

        this.getTemplateViewModel().allServerSsps(data);

    }

}
