import {vnTemplate} from "./vnTemplate";

declare var ko: any;

export class confirmationModal_template extends vnTemplate {

    /** @inheritDoc */
    protected getTemplateHtml(): string{

        return `
            <div class="w-lightbox light-theme zone-activation" data-bind="css: { 'is-visible': isVisible }"> 
                <div class="lightbox-content">      
                    <div class="w-form">
                        <div class="form-header">
                            <h3 class="form-title" data-bind="text: title"></h3>
                            <button type="button" class="close-button icon i-close-2" data-bind="event: { click: onClickClose }"></button>
                        </div>
                   
                        <div class="form-body">
                            <div class="form-group">
                                <p class="form-text" data-bind="text: messageMain"></p>     
                                <br/>
                                <p class="form-text highlight" data-bind="text: messageSecondary"></p>                   
                            </div>
                         </div>
                        
                        <div class="form-footer">
                            <button class="btn-action icon i-cancel-thin" type="button" data-bind="event: { click: onClickClose }"><span>Cancel</span></button>
                            <button class="btn-action icon i-save-thin" type="button" data-bind="event: { click: onClickConfirm }"><span>Confirm</span></button>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    /** @inheritDoc */
    protected initPartial(): void{

    }

    /** @inheritDoc */
    protected getTemplateName(): string{

        return 'confirmationModal_template';
    }

    /** @inheritDoc */
    protected getMainElementClasses(): Array<string>{

        return [];
    }

    /** @inheritDoc */
    public buildViewModel(): Object{

        let self = this;

        return {
            dataConfirm: ko.observable({}),
            dataClose: ko.observable({}),
            isVisible: ko.observable(false),
            title: ko.observable('Confirm'),
            messageMain: ko.observable('Are you sure?'),
            messageSecondary: ko.observable(''),
            context: ko.observable(''),
            onClickClose: function(){ self.onClickClose(); },
            onClickConfirm: function(){ self.onClickConfirm(); },
        };
    }

    //
    // Set context.
    public setContext(data){

        this.getTemplateViewModel().context(data);
    }

    //
    // Set confirm data.
    public setDataConfirm(data){

        this.getTemplateViewModel().dataConfirm(data);
    }

    //
    // Set close data.
    public setDataClose(data){

        this.getTemplateViewModel().dataClose(data);
    }

    //
    // Set the modal's title.
    public setTitle(title: string){

        this.getTemplateViewModel().title(title);
    }

    //
    // Set the modal's main message.
    public setMessageMain(messageMain: string){

        this.getTemplateViewModel().messageMain(messageMain);
    }

    //
    // Set the modal's secondary message.
    public setMessageSecondary(messageSecondary: string){

        this.getTemplateViewModel().messageSecondary(messageSecondary);
    }

    //
    // Returns true if modal is visible.
    public isVisible(){

        this.getTemplateViewModel().isVisible();
    }

    //
    // Show modal.
    public show(){

        this.getTemplateViewModel().isVisible(true);
    }

    //
    // Hide modal.
    public hide(){

        this.getTemplateViewModel().isVisible(false);
    }

    //
    // Handles on close button click event.
    public onClickClose(){

        this.hide();
        this.caller.confirmationModalOnClickClose(this.getTemplateViewModel().context(), this.getTemplateViewModel().dataClose());
    }

    //
    // Handles on confirm button click event.
    public onClickConfirm(){

        this.hide();
        this.caller.confirmationModalOnClickConfirm(this.getTemplateViewModel().context(), this.getTemplateViewModel().dataConfirm());
    }
}