import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import { sspEdit_template } from "../components/templates/sspEdit_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class ssp_edit extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected sspEdit_template: sspEdit_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return "ssp_edit";
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("SSP Edit");

        // create the users form
        this.sspEdit_template = new sspEdit_template("sspEdit_template", this.viewModel, this);

        return [this.sspEdit_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getEditFormData();
    }

    /**
     * Hook.
     *
     * Saves the edits to a user.
     */
    protected saveEditSsp(): void{

        this.save();
    }

    /**
     * Hook.
     *
     * Cancels the edits to a user.
     */
    protected cancelEditSsp(){

        this.loadingIn(this.sspEdit_template.builtTemplate);

        this.releaseLock();

        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "SSP edit action canceled!");

        page.redirect(`/ssps`);
    }

    /**
     * Requests a lock release.
     */
    protected releaseLock(): void{

        let formReq_params: Array<Array<string>> = [];

        // Request a lock release.
        this.XHRHdl = new XMLHttpRequestHandler(`/api/ssps/${this.getVnAppZone().app.params.id}/release_lock/`, formReq_params, this);
        this.XHRHdl.execute();
    }

    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditFormData(): void{

        this.loadingIn(this.sspEdit_template.builtTemplate);

        this.XHRHdl = new XMLHttpRequestHandler(`/api/ssps/references/`, [], this);
        this.XHRHdl.onReadyStateFunction = this.onEditFormDataRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for edit form request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onEditFormDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditFormData(responseParsed);
                obj.loadingOut(obj.sspEdit_template.builtTemplate);
                obj.getEditData();
            });
        }
    }

    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditData(takeover: boolean = false): void{

        this.loadingIn(this.sspEdit_template.builtTemplate);

        let id = this.getVnAppZone().app.params.id || null;

        if(id) {

            this.XHRHdl = new XMLHttpRequestHandler(`/api/ssps/get/${id}/`, [['takeover', (takeover) ? '1' : '0']], this);
            this.XHRHdl.onReadyStateFunction = this.onEditDataRequestReturn;
            this.XHRHdl.execute();
        }
        else{

            this.loadingOut(this.sspEdit_template.builtTemplate);
        }
    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onEditDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditData(responseParsed);
                obj.loadingOut(obj.sspEdit_template.builtTemplate);
            });
        }
    }

    /**
     * Update the filters.
     *
     * @param responseParsed
     */
    protected updateEditFormData(responseParsed: any): void{

        this.sspEdit_template.getTemplateViewModel().allTypes(responseParsed.labelTypes);
        this.sspEdit_template.setBiddingStyleOptionItems(responseParsed.biddingStyles);
        this.sspEdit_template.setSspBidAdapterItems(responseParsed.bidAdapters);
    }

    /**
     * Updates the form elements.
     *
     */
    protected updateEditData(responseParsed: any){

        this.sspEdit_template.setSsp(responseParsed.data);
    }

    /**
     * Saves the user data.
     */
    protected save(): void{

        this.loadingIn(this.sspEdit_template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.sspEdit_template.getViewModelForRequest();

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/ssps/save/`, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveReturn;
        XHRHdl.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{

        let idBrand = obj.getVnAppZone().app.params.idBrand;

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'SSP edit action successful!');

                obj.releaseLock();
                page.redirect(`/ssps/`);
            });

            obj.loadingOut(obj.sspEdit_template.builtTemplate);
        }
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public filterDropdownOnChange(): void{

    }

    public notifyParamLabelMissingName(){

        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_ERROR, 'All params label should have a name before adding configs!');
        this.getVnAppZone().showNotificationToModule();

    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.getEditData(true);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }
}
