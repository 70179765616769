import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class home_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `<div>
                </div>`;
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "home-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementType(): string{

        return "div";
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;
        return  {  };
    }
}