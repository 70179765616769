import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";
import {infoButton_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/infoButton_template";

declare var ko: any;

export class abTestingScheduleEdit_template extends vnTemplate{

    protected biddingTypeHelpInfoButton: infoButton_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <h3 class="form-title icon i-config">Edit</h3>

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
            <div class="w-form-body">
                <div class="form-body full-width">
                    <div class="form-group row">
                        <label for="ratioA" class="form-label">A Ratio</label>
                        <input id="ratioA" data-bind="value: ratioA" type="number" step="1" class="input-text num">
                    </div>
                    <div class="form-group row">
                        <label for="ratioB" class="form-label">B Ratio</label>
                        <input id="ratioB" data-bind="value: ratioB" type="number" step="1" class="input-text num">
                    </div>    
               </div> 
            </div>

            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return 'abTestingSchedule_edit-form-template';
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ['w-form', 'margin-top'];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let self = this;

        return {
            id: null,
            brand: ko.observable({}),
            group: ko.observable({}),
            ratioA: ko.observable(1),
            ratioB: ko.observable(1),
            onClickSave: function(data, event){ self.onClickSave(); },
            onClickCancel: function(data, event){ self.onClickCancel(); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            idGroup: this.getTemplateViewModel().group().id,
            ratioA: this.getTemplateViewModel().ratioA(),
            ratioB: this.getTemplateViewModel().ratioB(),
        };
    }

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        this.caller.save();
    }

    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancel();
    }
}
