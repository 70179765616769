import {vnTemplate} from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate';
import {filterSimple_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterSimple_template";
import {
    multipleSelector_template
} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/multipleSelector_template";
import {pagination_template} from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/pagination_template';
import {resultsInfo_template} from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/resultsInfo_template';
import {
    filterDropdown_template
} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDropdown_template";

declare var ko: any;

export class adDisplayType_template extends vnTemplate {

    protected searchFilter: filterSimple_template;
    protected dimensionFilter: multipleSelector_template;
    protected typeFilter: multipleSelector_template;
    protected templateFilter: multipleSelector_template;
    protected statusFilter: multipleSelector_template;
    protected bidderTypeFilter: multipleSelector_template;
    protected pagination: pagination_template;
    protected resultsInfo: resultsInfo_template;
    protected brandsFilter: multipleSelector_template;
    protected displayTypeFilter: multipleSelector_template;
    protected updateDisplayType: filterDropdown_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="sub-header">
                <div class="w-justify">
                    <div class="w-left">
                        <div>
                            <label class="form-label form-label-box multi">Brands</label>
                            ${this.brandsFilter.getHtmlBuild()}
                        </div>
                        <div>
                            <label class="form-label form-label-box multi">Display Type</label>
                            ${this.displayTypeFilter.getHtmlBuild()}
                        </div> 
                        <div>
                            <label class="form-label form-label-box multi">Dimension</label>
                            ${this.dimensionFilter.getHtmlBuild()}
                        </div> 
                        <div>
                            <label class="form-label form-label-box multi">Type</label>
                            ${this.typeFilter.getHtmlBuild()}
                        </div>
                        <div>
                            <label class="form-label form-label-box multi">Template</label>
                            ${this.templateFilter.getHtmlBuild()}
                        </div>
                        <div>
                            <label class="form-label form-label-box multi">Status</label>
                            ${this.statusFilter.getHtmlBuild()}
                        </div>
                        <div>
                            <label class="form-label form-label-box multi">Bidder Type</label>
                            ${this.bidderTypeFilter.getHtmlBuild()}
                        </div>
                    </div>
                </div>
            </div>      
            <div class="w-filters-selected">
                <div id="filters-selected" class="filters-selected">
                    <div id="selected-brands"></div>
                    <div id="selected-displayType"></div>
                    <div id="selected-dimensions"></div>
                    <div id="selected-types"></div>
                    <div id="selected-templates"></div>
                    <div id="selected-statuses"></div>
                    <div id="selected-bidderTypes"></div>
                </div>
            </div>      
            <div class="list-header w-justify">
                <div class="w-left">
                    <button data-bind="click: setAllChecked(true)" class="btn" type="button" >Select All</button>
                    <button data-bind="click: setAllChecked(false)" class="btn" type="button">Deselect All</button>
<!--                    <button class="btn left-join icon i-check" type="button" data-bind="css: { 'is-disable': atLeastOneSelected() == false}, event: {click: onClickUpdate}">Apply</button>-->
                    <div class="border-left apply" >
                        <label class="form-label">Ad Display Type</label>
                            ` + this.updateDisplayType.getHtmlBuild() + `
                    </div>
                    <button class="btn left-join icon i-check" type="button" data-bind="css: { 'is-disable': atLeastOneSelected() == false}, event: {click: onClickUpdate}">Apply</button>
                </div>
            </div>
            <div class="list-header w-justify" >
                <div class="w-left" >
                    <strong class="section-title icon i-unit">Ads Units</strong>
                </div>
                <div class="w-center">
                    ${this.searchFilter.getHtmlBuild()}
                </div>
                <div class="w-right">
                    ${this.pagination.getHtmlBuild()}
                </div>
            </div>
            
            <div class="w-list-results" id="list-results">
                <table class="list-results">
                    ${this.resultsInfo.getHtmlBuild()}
                    <thead> 
                        <tr>
                            <th><span>Select</span></th> 
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'name') }, css: { 'list-sort-desc': orderByAndDirection()=='name_DESC', 'list-sort-asc': orderByAndDirection()=='name_ASC' }">Name</span></th> 
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'parent') }, css: { 'list-sort-desc': orderByAndDirection()=='parent_DESC', 'list-sort-asc': orderByAndDirection()=='parent_ASC' }">Parent</span></th> 
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'nameDisplayType') }, css: { 'list-sort-desc': orderByAndDirection()=='nameDisplayType_DESC', 'list-sort-asc': orderByAndDirection()=='nameDisplayType_ASC' }">Display Type</span></th> 
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'nameBrand') }, css: { 'list-sort-desc': orderByAndDirection()=='nameBrand_DESC', 'list-sort-asc': orderByAndDirection()=='nameBrand_ASC' }">Brand</span></th>                             
                       </tr> 
                    </thead>
                    <tbody data-bind="foreach: adUnits">  
                        <tr>
                            <td>
                                <div style="margin-top:-1.25rem">
                                    <input type="checkbox" data-bind="checked: checked, attr:{id: 'adUnit_' + idAdUnit}, click:$parent.onClickChecked()"/> 
                                    <label data-bind="attr:{for: 'adUnit_' + idAdUnit}" class="form-label icon i-check"></label>
                                </div>
                            </td>
                            <td class=""><span data-bind="text: name"></span></td>
                            <td class=""><span data-bind="text: parent"></span></td>
                            <td class=""><span data-bind="text: nameDisplayType"></span></td>
                            <td class=""><span data-bind="text: nameBrand"></span></td>
                        </tr>
                    </tbody>
                </table>    
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{

        this.searchFilter = new filterSimple_template('searchFilter', this.getTemplateViewModel(), this.caller);
        this.resultsInfo  = new resultsInfo_template('resultsInfo', this.getTemplateViewModel(), this.caller);

        this.brandsFilter = new multipleSelector_template('brandsFilter', this.getTemplateViewModel(), this.caller);
        this.brandsFilter.setFilterContainerClass('margin-neg');
        this.brandsFilter.setTagContainerId('selected-brands');
        this.brandsFilter.setTagContainerCaption('Selected Brand:');

        this.displayTypeFilter = new multipleSelector_template('displayTypeFilter', this.getTemplateViewModel(), this.caller);
        this.displayTypeFilter.setFilterContainerClass('margin-neg');
        this.displayTypeFilter.setTagContainerId('selected-displayType');
        this.displayTypeFilter.setTagContainerCaption('Selected Display Type:');

        this.dimensionFilter = new multipleSelector_template("dimensionFilter", this.getTemplateViewModel(), this.caller);
        this.dimensionFilter.setFilterContainerClass('margin-neg');
        this.dimensionFilter.setTagContainerId('selected-dimensions');
        this.dimensionFilter.setTagContainerCaption('Selected Dimensions:');

        this.typeFilter = new multipleSelector_template("typeFilter", this.getTemplateViewModel(), this.caller);
        this.typeFilter.setFilterContainerClass('margin-neg');
        this.typeFilter.setTagContainerId('selected-types');
        this.typeFilter.setTagContainerCaption('Selected Types:');

        this.templateFilter = new multipleSelector_template("templateFilter", this.getTemplateViewModel(), this.caller);
        this.templateFilter.setFilterContainerClass('margin-neg');
        this.templateFilter.setTagContainerId('selected-templates');
        this.templateFilter.setTagContainerCaption('Selected Templates:');

        this.statusFilter = new multipleSelector_template("statusFilter", this.getTemplateViewModel(), this.caller);
        this.statusFilter.setFilterContainerClass('margin-neg');
        this.statusFilter.setTagContainerId('selected-statuses');
        this.statusFilter.setTagContainerCaption('Selected Statuses:');

        this.bidderTypeFilter = new multipleSelector_template("bidderTypeFilter", this.getTemplateViewModel(), this.caller);
        this.bidderTypeFilter.setFilterContainerClass('margin-neg');
        this.bidderTypeFilter.setTagContainerId('selected-bidderTypes');
        this.bidderTypeFilter.setTagContainerCaption('Selected Bidder Types:');

        this.updateDisplayType = new filterDropdown_template("updateDisplayType", this.getTemplateViewModel(), this.caller);

        this.pagination = new pagination_template('pagination', this.getTemplateViewModel(), this.caller);
        this.pagination.getTemplateViewModel().itemPerPage = 25;
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return 'adDisplayType_template';
    }

    /**
     * @inheritDoc
     */
    protected getMainElementType(): string{

        return "div";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-content"];
    }

    /**
     * @inheritDoc
     */
    public buildViewModel(): Object{

        let self = this;

        return {
            orderBy: ko.observable('name'),
            orderByDirection: ko.observable('ASC'),
            orderByAndDirection: ko.observable('name_ASC'),
            adUnits: ko.observableArray([]),
            atLeastOneSelected: ko.observable(false),
            onClickReorder: function(value, data, event){ self.onClickReorder(value, data, event); },
            onClickRedirect: function(data, event){ self.caller.goToAdSetupsList(); },
            setAllChecked: function (value, data, event) { self.setAllChecked(value); },
            onClickUpdate: function () {self.onClickUpdate();},
            onClickChecked: function () { self.onClickChecked(); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            pagination: this.pagination.getTemplateViewModel().pageNumber(),
            orderBy: this.getTemplateViewModel().orderBy(),
            orderByDirection: this.getTemplateViewModel().orderByDirection(),
            filters: {
                brands: this.brandsFilter.getTemplateViewModel().selectedItems(),
                selectedDisplayType: this.displayTypeFilter.getTemplateViewModel().selectedItems(),
                searchString: this.searchFilter.getTemplateViewModel().filterKeyword(),
                selectedDimensions: this.dimensionFilter.getTemplateViewModel().selectedItems(),
                selectedTypes: this.typeFilter.getTemplateViewModel().selectedItems(),
                selectedTemplates: this.templateFilter.getTemplateViewModel().selectedItems(),
                selectedStatuses: this.statusFilter.getTemplateViewModel().selectedItems(),
                selectedBidderTypes: this.bidderTypeFilter.getTemplateViewModel().selectedItems(),
            },
        };
    }

    /**
     * Handles Reordering table.
     *
     * @param value
     * @param data
     * @param event
     */
    public onClickReorder(value, data, event){

        data.orderBy(value);

        // If column has already been sorted, flip the direction.
        if(data.orderByAndDirection().includes(value)){

            if(data.orderByDirection() == "DESC"){

                data.orderByDirection("ASC");
            }
            else{

                data.orderByDirection("DESC");
            }
        }
        else{

            //default column sort direction
            data.orderByDirection("DESC");
        }

        data.orderByAndDirection(value + "_" + data.orderByDirection());
        this.caller.dataReorder();
    }

    /**
     * Sets users.
     *
     * @param adUnits
     */
    public setAdUnits(adUnits): void{

        adUnits.forEach((adUnit) => {

            adUnit.checked = ko.observable(false);

            if(adUnit.parent === null){

                let adUnitName = adUnit.name;
                adUnit['parent'] = adUnitName.substr(0, adUnitName.lastIndexOf('/'));
            }
        });

        this.getTemplateViewModel().adUnits(adUnits);
    }

    /**
     * Sets filter items.
     *
     * @param data
     */
    public setDimensionFilterItems(data): void{

        this.setMultiSelectFilter(this.dimensionFilter, data);
    }

    /**
     * Sets filter items.
     *
     * @param data
     */
    public setDisplayTypeFilterItems(data): void{

        this.setMultiSelectFilter(this.displayTypeFilter, data);
    }

    /**
     * Sets filter items.
     *
     * @param data
     */
    public setTypeFilterItems(data): void{

        data.unshift({'id' : 0, 'name' : 'other'});
        this.setMultiSelectFilter(this.typeFilter, data);
    }

    /**
     * Sets filter items.
     *
     * @param data
     */
    public setTemplateFilterItems(data): void{

        this.setMultiSelectFilter(this.templateFilter, data);
    }

    /**
     * Sets filter items.
     *
     * @param data
     */
    public setStatusFilterItems(data): void{

        this.setMultiSelectFilter(this.statusFilter, data);
    }

    /**
     * Sets filter items.
     *
     * @param data
     */
    public setBidderTypeFilterItems(data): void{

        this.setMultiSelectFilter(this.bidderTypeFilter, data);
    }

    public setSelectedBrands(){

        this.getTemplateViewModel().brandsSelected(this.brandsFilter.getSelectedItems());
    }

    public setBrandsFilterItems(items){

        this.brandsFilter.setItems(items);
    }

    /**
     * Sets data for the result info template.
     *
     * @param nbResults
     * @param nbPages
     * @param execTime
     */
    public setResultInfo(nbResults, nbPages, execTime){

        this.resultsInfo.setExecutionTime(execTime);
        this.resultsInfo.setNbResults(nbResults);
        this.resultsInfo.setNbPages(nbPages);
    }

    /**
     * Sets data for a multi select filter.
     *
     * @param filter
     * @param {Array<{ id: number, name: string }>} data
     */
    public setMultiSelectFilter(filter, data: Array<{ id: string, name: string }>): void{

        filter.setItems(data);
    }

    public onClickChecked(){

        this.setEnableApply();

        return true;
    }

    public setAllChecked(value: boolean) {

        let adUnits = this.getTemplateViewModel().adUnits();

        adUnits.forEach((adUnit) => {

            adUnit.checked(value);
        });

        this.getTemplateViewModel().adUnits(adUnits);

        this.setEnableApply();
    }

    public setEnableApply() {

        let adUnits = this.getTemplateViewModel().adUnits();

        let atLeastOne = false;

        adUnits.forEach((adUnit) => {

            if (adUnit.checked() == true) {

                atLeastOne = true;
            }

        });

        this.getTemplateViewModel().atLeastOneSelected(atLeastOne);

    }

    public onClickUpdate() {

        let adUnits = this.getTemplateViewModel().adUnits();
        let adUnitsToSave = [];

        adUnits.forEach((adUnit) => {

            if (adUnit.checked() == true) {

                adUnitsToSave.push(adUnit);
            }

        });

        this.caller.saveAdDisplayTypes(adUnitsToSave, this.updateDisplayType.getTemplateViewModel().selectedOption());
    }

    public setUpdateDisplayType(displayTypes): void{

        this.updateDisplayType.setOptions(displayTypes);
    }


}