import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import { advertisingPartnerByBrand_template } from "../components/templates/advertisingPartnerByBrand_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class advertisingPartnerByBrand extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected advertisingPartnerByBrand_template: advertisingPartnerByBrand_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return "advertisingPartnerByBrand";
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{
        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Advertising Partners");

        // create the users form
        this.advertisingPartnerByBrand_template = new advertisingPartnerByBrand_template("advertisingPartnerByBrand_template", this.viewModel, this);

        return [this.advertisingPartnerByBrand_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{
        this.getAdvertisingPartnersByBrand();
    }

    /**
     * Requests a lock release.
     */
    protected releaseLock(): void{

        let formReq_params: Array<Array<string>> = [];

        let idBrand = this.getVnAppZone().app.params.idBrand;

        // Request a lock release.
        this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${idBrand}/advertising_partners/${this.getVnAppZone().app.params.id}/release_lock/`, formReq_params, this);
        this.XHRHdl.execute();
    }
    
    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getAdvertisingPartnersByBrand(takeover: boolean = false): void{

        this.loadingIn(this.advertisingPartnerByBrand_template.builtTemplate);

        let idBrand = this.getVnAppZone().app.params.idBrand;

        this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${idBrand}/advertising_partners/get`, [['takeover', (takeover) ? '1' : '0']], this);
        this.XHRHdl.onReadyStateFunction = this.onGetAdvertisingPartnersByBrand;
        this.XHRHdl.execute();

    } 
    
    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onGetAdvertisingPartnersByBrand(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){
                let responseParsed = JSON.parse(req.request.response);
                obj.setAdvertisingPartnersByBrand(responseParsed);
                obj.loadingOut(obj.advertisingPartnerByBrand_template.builtTemplate);
            });
        }
    }

    protected setAdvertisingPartnersByBrand(advertising_partners_by_brand){
        this.advertisingPartnerByBrand_template.setAdvertisingPartnersByBrand(advertising_partners_by_brand);
    }


    /**
     * Saves the advertising partner data.
     */
    protected save(): void{

        this.loadingIn(this.advertisingPartnerByBrand_template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.advertisingPartnerByBrand_template.getViewModelForRequest();

        let idBrand = this.getVnAppZone().app.params.idBrand;

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/brand/${idBrand}/advertising_partners/save/`, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveReturn;
        XHRHdl.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{
        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Advertising Partner saved successfully!');
                obj.releaseLock();
                let idBrand = obj.getVnAppZone().app.params.idBrand;
                page.redirect(`/brand/${idBrand}/advertising_partners/`);
            });

            obj.loadingOut(obj.advertisingPartnerByBrand_template.builtTemplate);
        }
    }

    /**
     * Hook.
     *
     * Saves the edits to a advertisiing partner by brand
     */
    protected saveAdvertisingPartnerByBrand(): void{

        this.save();
    }

    protected cancelAdvertisingPartnerByBrand(): void{
        this.releaseLock();
        page.redirect(`/brand/${this.getVnAppZone().app.params.idBrand}/advertising_partners/`);
        
    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.getAdvertisingPartnersByBrand(true);
    }

}
