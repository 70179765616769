import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import {header} from "./header";
import {bidderTypeLibraryEdit_template} from "../components/templates/bidderTypeLibraryEdit_template";

declare var ko: any;
declare var page: any;

export class bidderTypeLibrary_edit extends vnModule_knockoutJS{getViewModelForRequest

    protected requestHandler: XMLHttpRequestHandler;
    protected template: bidderTypeLibraryEdit_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return 'bidderTypeLibrary_edit';
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName('Header');
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle('Bidder Type Library Edit');

        this.template = new bidderTypeLibraryEdit_template('bidderTypeLibraryEdit_template', this.viewModel, this);

        return [this.template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getEditFormData();
    }

    //
    // Hook.
    // Saves the edits to a entity.
    protected saveEditBidderType(): void{

        this.save();
    }

    //
    // Hook.
    //
    // Cancels the edits to an entity.
    protected cancelEditBidderType(){

        this.loadingIn(this.template.builtTemplate);

        this.releaseLock();

        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "Bidder Type action canceled!");

        page.redirect(`/brand/${this.getVnAppZone().app.params.idBrand}/bidder_type_library/`);
    }

    //
    // Requests a lock release.
    protected releaseLock(): void{

        let formReq_params: Array<Array<string>> = [];

        // Request a lock release.
        this.requestHandler = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/bidder_type_library/${this.getVnAppZone().app.params.id}/release_lock/`, formReq_params, this);
        this.requestHandler.execute();
    }


    //
    // Obtain the dynamic data required to fill the form.
    protected getEditData(takeover: boolean = false): void{

        this.loadingIn(this.template.builtTemplate);

        let id = this.getVnAppZone().app.params.id || null;

        this.requestHandler = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/bidder_type_library/get/${id}/`, [['takeover', (takeover) ? '1' : '0']], this);
        this.requestHandler.onReadyStateFunction = this.onEditDataRequestReturn;
        this.requestHandler.execute();

    }

    //
    // Handles the form data response.
    protected onEditDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditData(responseParsed);
                obj.loadingOut(obj.template.builtTemplate);
            });
        }
    }

    /**
     * Updates the form elements.
     */
    protected updateEditData(responseParsed: any){

        this.template.setData(responseParsed.data);
        // this.template.getTemplateViewModel().extraParameters(this.formatExtraParametersForFrontend(responseParsed.data.extraParameters));
    }

    /**
     * Saves the source data.
     */
    protected save(): void{

        this.loadingIn(this.template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.template.getViewModelForRequest();

        formReq_params.push(["data", ko.toJSON(subViewModel)]);

        let requestHandler:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/bidder_type_library/save/`, formReq_params, this);
        requestHandler.mode                      = XMLHttpRequestHandler_requestType.POST;
        requestHandler.onReadyStateFunction      = this.onSaveReturn;
        requestHandler.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Bidder type library edit successful!');
                obj.releaseLock();
                page.redirect(`/brand/${obj.getVnAppZone().app.params.idBrand}/bidder_type_library/`);
            });

            obj.loadingOut(obj.template.builtTemplate);
        }
    }

    // //
    // // Format extra params.
    // private formatExtraParametersForFrontend(extraParameters){
    //
    //     let extraParametersForTemplate = [];
    //
    //     if(extraParameters){
    //
    //         for(let extraParameter of extraParameters){
    //
    //             extraParametersForTemplate.push({'name': Object.keys(extraParameter)[0], 'value': extraParameter[Object.keys(extraParameter)[0]]});
    //         }
    //     }
    //
    //     return extraParametersForTemplate;
    // }
    //
    // //
    // // Format extra params.
    // private formatExtraParametersForBackend(extraParameters){
    //
    //     let extraParametersForBackend = [];
    //
    //     for(let extraParameter of extraParameters){
    //
    //         let newExtraParameter                  = {};
    //         newExtraParameter[extraParameter.name] = extraParameter.value;
    //
    //         extraParametersForBackend.push(newExtraParameter);
    //     }
    //
    //     return extraParametersForBackend;
    // }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.getEditData(true);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }

    //
    // Obtain the dynamic data required to fill the form.
    protected getEditFormData(): void{

        this.loadingIn(this.template.builtTemplate);

        this.requestHandler = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/bidder_type_library/references/`, [], this);
        this.requestHandler.onReadyStateFunction = this.onEditFormDataRequestReturn;
        this.requestHandler.execute();
    }

    //
    // Returns handler for edit form request.
    protected onEditFormDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditFormData(responseParsed);
                obj.loadingOut(obj.template.builtTemplate);
                obj.getEditData();
            });
        }
    }

    //
    // Update form data.
    protected updateEditFormData(responseParsed: any): void{

        this.template.setDevicesOptionItems(responseParsed.devices);
        this.template.setBidderTypeOptionItems(responseParsed.bidderTypes);
        this.template.setAdsSetupTagOptionItems(responseParsed.adsSetupTags);
        this.template.setGeoGroupsOptionItems(responseParsed.geoGroups);
        this.template.setPrebidOptionItems(responseParsed.prebidOptions);
        this.template.setPrebidServerDefault(responseParsed.prebidServerDefault);
        this.template.setAllServerSsps(responseParsed.serverSsps);

    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public filterDropdownOnChange(): void{

    }
}
