import { templateTypeDependencies_template } from '../components/templates/templateTypeDependencies_template';
import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class templateTypeDependencies extends vnModule_knockoutJS{

    protected template: templateTypeDependencies_template;
    protected requestHandler: XMLHttpRequestHandler;

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'templateTypeDependencies';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Template Type Dependencies");

        this.template = new templateTypeDependencies_template('templateTypeDependencies_template', this.viewModel, this)

        return [this.template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getEditFormData();
    }

    /**
     * @inheritDoc
     */
    protected reorderTemplates(): void{

        this.getEditFormData();
    }

    /**
     * Fetches the list data.
     */
    public getListEditData(takeover: boolean = false): void{

        this.loadingIn(this.template.builtTemplate);

        let idBrand = this.getVnAppZone().app.params.idBrand;

        this.requestHandler = new XMLHttpRequestHandler(`/api/brand/${idBrand}/template_type_dependencies/get/`, [['takeover', (takeover) ? '1' : '0']], this);
        this.requestHandler.onReadyStateFunction = this.onDataRequestReturn;
        this.requestHandler.execute();
    }

    /**
     * Returns handler for data request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateList(responseParsed);
                obj.loadingOut(obj.template.builtTemplate);
            });
        }
    }

    /**
     * Update the module list using the objects list.
     *
     * @param responseParsed
     */
    protected updateList(responseParsed: any): void{

        this.loadingIn(this.template.builtTemplate);
        this.template.setFormData(responseParsed);
    }

    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditFormData(): void{

        this.loadingIn(this.template.builtTemplate);

        let idBrand = this.getVnAppZone().app.params.idBrand;

        this.requestHandler = new XMLHttpRequestHandler(`/api/brand/${idBrand}/template_type_dependencies/references/`, [], this);
        this.requestHandler.onReadyStateFunction = this.onEditFormDataRequestReturn;
        this.requestHandler.execute();
    }

    /**
     * Returns handler for edit form request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onEditFormDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditFormData(responseParsed);
                obj.getListEditData();
            });
        }
    }

    /**
     * Update the filters.
     *
     * @param responseParsed
     */
    protected updateEditFormData(responseParsed: any): void{

        this.template.getTemplateViewModel().brand(responseParsed.brand);
        this.template.getTemplateViewModel().allTypes(responseParsed.types);
    }

    /**
     * Saves the source data.
     */
    protected save(): void{

        this.loadingIn(this.template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.template.getViewModelForRequest();
        let idBrand = this.getVnAppZone().app.params.idBrand;

        formReq_params.push(["data", ko.toJSON(subViewModel)] );


        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/brand/${idBrand}/template_type_dependencies/save/`, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveReturn;
        XHRHdl.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{

        let idBrand = obj.getVnAppZone().app.params.idBrand;

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Template type dependencies edited successfully!');
                obj.releaseLock();
                page.redirect(`/brand/${idBrand}/ads_setups`);
            });

            obj.loadingOut(obj.template.builtTemplate);
        }
    }

    /**
     * Hook.
     *
     * Cancels the edits to a user.
     */
    protected cancel(){

        this.loadingIn(this.template.builtTemplate);

        this.releaseLock();

        let idBrand = this.getVnAppZone().app.params.idBrand;

        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, 'Template type dependencies edit canceled!');

        page.redirect(`/brand/${idBrand}/ads_setups`);
    }

    /**
     * Requests a lock release.
     */
    protected releaseLock(): void{

        let idBrand = this.getVnAppZone().app.params.idBrand;

        // Request a lock release.
        this.requestHandler = new XMLHttpRequestHandler(`/api/brand/${idBrand}/template_type_dependencies/release_lock/`, [], this);
        this.requestHandler.execute();
    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.getListEditData(true);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }
}