import {vnTemplate} from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate';
import {multipleSelector_template} from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/multipleSelector_template';
import {filterSingleDate_template} from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterSingleDate_template';
import {infoButton_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/infoButton_template";

declare var ko: any;

export class performanceSummaryList_template extends vnTemplate {

    // Filters
    protected brandsFilter: multipleSelector_template;
    protected templatesFilter: multipleSelector_template;
    protected sourcesFilter: multipleSelector_template;
    protected devicesFilter: multipleSelector_template;
    protected dateFilter: filterSingleDate_template;
    protected comparisonDateFilter: filterSingleDate_template;

    protected getTemplateHtml(): string{

        return `

            <div class="sub-header header-model-report-page"  id="sub-filters">
                <div class="w-justify" style="margin-bottom: -2rem">
                    <div class="w-left">
                        <div>
                            <label class="form-label form-label-box multi"></label>
                            ` + this.brandsFilter.getHtmlBuild() + `
                        </div>
                        <div>
                            <label class="form-label form-label-box multi"></label> 
                            ` + this.templatesFilter.getHtmlBuild() + `
                        </div>
                        <div>
                            <label class="form-label form-label-box multi"></label> 
                            ` + this.sourcesFilter.getHtmlBuild() + `
                        </div>
                        <div>
                            <label class="form-label form-label-box multi"></label> 
                            ` + this.devicesFilter.getHtmlBuild() + `
                        </div>

                    </div>
                   
                </div>
                <div class="w-justify">
                    <div class="w-left">
                        <div class="emp-filters">
                            <div class="ww-date-picker"> 
                                <label class="form-label icon i-arrow" for="">Date</label>
                                ${this.dateFilter.getHtmlBuild()}
                            </div>
                            <div class="ww-date-picker">
                                <label class="form-label icon i-circle" for="">Comparison Date</label>
                                ${this.comparisonDateFilter.getHtmlBuild()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="w-filters-selected">
                <div id="filters-selected" class="filters-selected">
                    <div id="selected-brands"></div>
                    <div id="selected-templates"></div>
                    <div id="selected-sources"></div>
                    <div id="selected-devices"></div>
                </div>
            </div>
            
            <div class="list-header w-justify">
                <div class="w-left">
                    <h2 class="section-title icon i-report">Performance Summary</h2>
                </div>
            </div>

            <div id="list-results">
                <!-- ko foreach: results -->
                <!-- ko if: $parent.isBrandVisible($data.idBrand) -->
                <ul class="report-list">
                    <li class="report-item" >
                        <ul data-bind="class: 'report-brand-list ' +  ($data.brandIconImage ? $data.brandIconImage : 'full-icon')">
                            <li data-bind="class: 'report-brand-item indic-' + (Math.abs($data.impressionsPercentChange) >= 10 ? 'very-': '') + ($data.impressionsPercentChange >= 0 ? 'good': 'bad')">
                                <div class="w-left">
                                    <strong class="metric">Impression</strong>
                                    <span class="value icon i-arrow" data-bind="text: Number($data.impressions).toLocaleString('en-EG')"></span>
                                    <span class="value icon i-circle" data-bind="text: Number($data.impressionsComparison).toLocaleString('en-EG')"></span>
                                </div>
                                <div class="w-right">
                                    <strong class="change" data-bind="text: ($data.impressionsPercentChange > 0 ? '+': '') + Number($data.impressionsPercentChange).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'">+88.88%</strong>
                                </div>
                            </li>
                            <li data-bind="class: 'report-brand-item indic-' + (Math.abs($data.viewabilityPercentChange) >= 10 ? 'very-': '') + ($data.viewabilityPercentChange >= 0 ? 'good': 'bad')">
                                <div class="w-left">
                                    <strong class="metric width-tooltips">viewability
                                        <div class="w-tooltips-adv short" style="margin-left: 4rem;" data-bind="template: { name: 'infoButton-template', data: $data.viewableImpressionInfoButton.getTemplateViewModel() }"></div>
                                    </strong>
                                    <span class="value icon i-arrow" data-bind="text: Number($data.viewability).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'">3,616,983</span>
                                    <span class="value icon i-circle" data-bind="text: Number($data.viewabilityComparison).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'">3,616,983</span>
                                </div>
                                <div class="w-right">
                                    <strong class="change" data-bind="text: ($data.viewabilityPercentChange > 0 ? '+': '') + Number($data.viewabilityPercentChange).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'">-88.88%</strong>
                                </div>
                            </li>
                            <li data-bind="class: 'report-brand-item indic-' + (Math.abs($data.requestsPercentChange) >= 10 ? 'very-': '') + ($data.requestsPercentChange >= 0 ? 'good': 'bad')">
                                <div class="w-left">
                                    <strong class="metric">requests</strong>
                                    <span class="value icon i-arrow" data-bind="text: Number($data.requests).toLocaleString('en-EG')">3,616,983</span>
                                    <span class="value icon i-circle" data-bind="text: Number($data.requestsComparison).toLocaleString('en-EG')">3,616,983</span>
                                </div>
                                <div class="w-right">
                                    <strong class="change" data-bind="text: ($data.requestsPercentChange > 0 ? '+': '') + Number($data.requestsPercentChange).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'">+88.88%</strong>
                                </div>
                            </li>
                            <li data-bind="class: 'report-brand-item indic-' + (Math.abs($data.pageRPMPercentChange) >= 10 ? 'very-': '') + ($data.pageRPMPercentChange >= 0 ? 'good': 'bad')">
                                <div class="w-left">
                                    <strong class="metric">page RPM</strong>
                                    <span class="value icon i-arrow" data-bind="text: Number($data.pageRPM).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})">3,616,983</span>
                                    <span class="value icon i-circle" data-bind="text: Number($data.pageRPMComparison).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})">3,616,983</span>
                                </div>
                                <div class="w-right">
                                    <strong class="change" data-bind="text: ($data.pageRPMPercentChange > 0 ? '+': '') + Number($data.pageRPMPercentChange).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'">-88.88%</strong>
                                </div>
                            </li>
                            <li data-bind="class: 'report-brand-item indic-' + (Math.abs($data.requestRPMPercentChange) >= 10 ? 'very-': '') + ($data.requestRPMPercentChange >= 0 ? 'good': 'bad')">
                                <div class="w-left">
                                    <strong class="metric">request RPM</strong>
                                    <span class="value icon i-arrow" data-bind="text: Number($data.requestRPM).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})">3,616,983</span>
                                    <span class="value icon i-circle" data-bind="text: Number($data.requestRPMComparison).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})">3,616,983</span>
                                </div>
                                <div class="w-right">
                                    <strong class="change" data-bind="text: ($data.requestRPMPercentChange > 0 ? '+': '') + Number($data.requestRPMPercentChange).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'">-88.88%</strong>
                                </div>
                            </li>
                            <li data-bind="class: 'report-brand-item indic-' + (Math.abs($data.pageviewsPercentChange) >= 10 ? 'very-': '') + ($data.pageviewsPercentChange >= 0 ? 'good': 'bad')">
                                <div class="w-left">
                                    <strong class="metric">page views</strong>
                                    <span class="value icon i-arrow" data-bind="text: Number($data.pageviews).toLocaleString('en-EG')">3,616,983</span>
                                    <span class="value icon i-circle" data-bind="text: Number($data.pageviewsComparison).toLocaleString('en-EG')">3,616,983</span>
                                </div>
                                <div class="w-right">
                                    <strong class="change" data-bind="text: ($data.pageviewsPercentChange > 0 ? '+': '') + Number($data.pageviewsPercentChange).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'">-88.88%</strong>
                                </div>
                            </li>
                            <li data-bind="class: 'report-brand-item indic-' + (Math.abs($data.adsPerPagePercentChange) >= 10 ? 'very-': '') + ($data.adsPerPagePercentChange >= 0 ? 'good': 'bad')">
                                <div class="w-left">
                                    <strong class="metric">ads Per Page</strong>
                                    <span class="value icon i-arrow" data-bind="text: Number($data.adsPerPage).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})">3,616,983</span>
                                    <span class="value icon i-circle" data-bind="text: Number($data.adsPerPageComparison).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})">3,616,983</span>
                                </div>
                                <div class="w-right">
                                    <strong class="change" data-bind="text: ($data.adsPerPagePercentChange > 0 ? '+': '') + Number($data.adsPerPagePercentChange).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'">-88.88%</strong>
                                </div>
                            </li>
                            <li data-bind="class: 'report-brand-item indic-' + (Math.abs($data.requestPerPagePercentChange) >= 10 ? 'very-': '') + ($data.requestPerPagePercentChange >= 0 ? 'good': 'bad')">
                                <div class="w-left">
                                    <strong class="metric">request Per Page</strong>
                                    <span class="value icon i-arrow" data-bind="text: Number($data.requestPerPage).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})">3,616,983</span>
                                    <span class="value icon i-circle" data-bind="text: Number($data.requestPerPageComparison).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})">3,616,983</span>
                                </div>
                                <div class="w-right">
                                    <strong class="change" data-bind="text: ($data.requestPerPagePercentChange > 0 ? '+': '') + Number($data.requestPerPagePercentChange).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'">-88.88%</strong>
                                </div>
                            </li>
                            <li data-bind="class: 'report-brand-item indic-' + (Math.abs($data.CPMPercentChange) >= 10 ? 'very-': '') + ($data.CPMPercentChange >= 0 ? 'good': 'bad')">
                                <div class="w-left">
                                    <strong class="metric">CPM</strong>
                                    <span class="value icon i-arrow" data-bind="text: Number($data.CPM).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})">3,616,983</span>
                                    <span class="value icon i-circle" data-bind="text: Number($data.CPMComparison).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})">3,616,983</span>
                                </div>
                                <div class="w-right">
                                    <strong class="change" data-bind="text: ($data.CPMPercentChange > 0 ? '+': '') + Number($data.CPMPercentChange).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'">-88.88%</strong>
                                </div>
                            </li>
                            <li data-bind="class: 'report-brand-item indic-' + (Math.abs($data.fillRatesPercentChange) >= 10 ? 'very-': '') + ($data.fillRatesPercentChange >= 0 ? 'good': 'bad')">
                                <div class="w-left">
                                    <strong class="metric">fill Rates</strong>
                                    <span class="value icon i-arrow" data-bind="text: Number($data.fillRates).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'">3,616,983</span>
                                    <span class="value icon i-circle" data-bind="text: Number($data.fillRatesComparison).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'">3,616,983</span>
                                </div>
                                <div class="w-right">
                                    <strong class="change" data-bind="text: ($data.fillRatesPercentChange > 0 ? '+': '') + Number($data.fillRatesPercentChange).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'">-88.88%</strong>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
                <!-- /ko -->
                <!-- /ko -->
            </div>
        `;
    }

    protected initPartial(): void{


        this.brandsFilter = new multipleSelector_template('brandsFilter', this.getTemplateViewModel(), this.caller);
        this.brandsFilter.setLabel('brands');
        this.brandsFilter.setFilterContainerClass('margin-neg');
        this.brandsFilter.setTagContainerId('selected-brands');
        this.brandsFilter.setTagContainerCaption('Selected Brands');

        this.templatesFilter = new multipleSelector_template('templatesFilter', this.getTemplateViewModel(), this.caller);
        this.templatesFilter.setLabel('templates');
        this.templatesFilter.setFilterContainerClass('margin-neg');
        this.templatesFilter.setTagContainerId('selected-templates');
        this.templatesFilter.setTagContainerCaption('Selected Templates');

        this.sourcesFilter = new multipleSelector_template('sourcesFilter', this.getTemplateViewModel(), this.caller);
        this.sourcesFilter.setLabel('sources');
        this.sourcesFilter.setFilterContainerClass('margin-neg');
        this.sourcesFilter.setTagContainerId('selected-sources');
        this.sourcesFilter.setTagContainerCaption('Selected Sources');

        this.devicesFilter = new multipleSelector_template('devicesFilter', this.getTemplateViewModel(), this.caller);
        this.devicesFilter.setLabel('devices');
        this.devicesFilter.setFilterContainerClass('margin-neg');
        this.devicesFilter.setTagContainerId('selected-devices');
        this.devicesFilter.setTagContainerCaption('Selected Devices');

        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        this.dateFilter = new filterSingleDate_template('dateFilter', this.getTemplateViewModel(), this.caller);
        this.dateFilter.setDate(yesterday.toISOString().split('T')[0]);

        let twoDaysAgo = new Date();
        twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
        this.comparisonDateFilter = new filterSingleDate_template('comparisonDateFilter', this.getTemplateViewModel(), this.caller);
        this.comparisonDateFilter.setDate(twoDaysAgo.toISOString().split('T')[0]);
    }

    protected getTemplateName(): string{

        return 'performanceSummaryList_template';
    }

    protected getMainElementClasses(): Array<string>{

        return [];
    }

    public activateLoading(): void{

        this.getTemplateViewModel().isLoading(1);
    }

    /**
     * @inheritDoc
     */
    public deactivateLoading(): void{

        this.getTemplateViewModel().isLoading(0);
    }

    /**
     * @inheritDoc
     */
    public buildViewModel(): Object{

        let huit_neuf_this = this;;

        return {

            brandsSelected: ko.observableArray([]),
            templatesSelected: ko.observableArray([]),
            sourcesSelected: ko.observableArray([]),
            devicesSelected: ko.observableArray([]),
            results: ko.observableArray([]),
            isLoading: ko.observable(0),
            isVisible: ko.observable('is-visible'),
            isBrandVisible: function (idBrand){
                return ko.computed(function (){

                    return huit_neuf_this.isBrandVisible(idBrand);
                }, this);
            },
        };
    }

    public setSelectedBrands(){

        this.getTemplateViewModel().brandsSelected(this.brandsFilter.getSelectedItems());
    }

    public setSelectedTemplates(){

        this.getTemplateViewModel().templatesSelected(this.templatesFilter.getSelectedItems());
    }

    public setSelectedSources(){

        this.getTemplateViewModel().sourcesSelected(this.sourcesFilter.getSelectedItems());
    }

    public setSelectedDevices(){

        this.getTemplateViewModel().devicesSelected(this.devicesFilter.getSelectedItems());
    }

    public getViewModelForRequest(): Object{

        return {

            filters:{

                templates: this.getTemplateViewModel().templatesFilter.selectedItems,
                sources: this.getTemplateViewModel().sourcesFilter.selectedItems,
                devices: this.getTemplateViewModel().devicesFilter.selectedItems,
                date: this.dateFilter.getTemplateViewModel().singleDatePicker,
                comparisonDate: this.comparisonDateFilter.getTemplateViewModel().singleDatePicker,
            },
        };
    }

    /**
     * Build the date Picker calendar
     */
    public buildDatePickerCalendar(){

        this.dateFilter.buildCalendarWithoutTime();
        this.comparisonDateFilter.buildCalendarWithoutTime();
    }

    public setResults(results): void{

        for (let result of results){

            let viewableImpressionInfoButton = new infoButton_template('viewableImpressionInfoButton-' + result.brandShortName, this.getTemplateViewModel(), this.caller);
            viewableImpressionInfoButton.setTitle('Viewable impressions');
            viewableImpressionInfoButton.setContent('<p>Viewable impressions are not available for all advertisers and can only be obtained from GAM. The actual viewability percentage is slightly higher than what is displayed here.</p>');

            result.viewableImpressionInfoButton = viewableImpressionInfoButton;
        }

        this.getTemplateViewModel().results(results);
    }

    public setBrandsFilterItems(items){

        this.brandsFilter.setItems(items);
    }

    public setTemplatesFilterItems(items){

        this.templatesFilter.setItems(items);
    }

    public setSourcesFilterItems(items){

        this.sourcesFilter.setItems(items);
    }

    public setDevicesFilterItems(items){

        this.devicesFilter.setItems(items);
    }

    public isBrandVisible(idBrand){

        let selectedBrands = this.getTemplateViewModel().brandsFilter.selectedItems();
        let isVisible = false;

        if (selectedBrands.length == 0){

            isVisible = true;
        } else{

            for (let i = 0; i < selectedBrands.length; i++){

                if (selectedBrands[i] == idBrand){

                    isVisible = true;
                    break;
                }
            }
        }

        return isVisible;
    }
}