import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import {header} from "./header";
import {adRevenue_template} from "../components/templates/adRevenue_template";

declare var ko: any;
declare var page: any;

export class adRevenue extends vnModule_knockoutJS{

    protected adRevenue_template: adRevenue_template;
    protected requestHandler: XMLHttpRequestHandler;

    static devices : Array<{id : string, name : string}> = [
        { "id": "mobile",  "name": "Mobile"},
        { "id": "desktop",  "name": "Desktop"},
    ];

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'ad revenue report';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName('Header');
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle('Ad Revenue');

        this.adRevenue_template = new adRevenue_template('adRevenue_template', this.viewModel, this)

        return [this.adRevenue_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.adRevenue_template.buildDatePickerCalendar();
        this.getListFormData();
    }

    /**
     * @inheritDoc
     */
    protected reorderAdsSetups(): void{

        this.getListData();
    }

    //
    // Requests data for the filters.
    protected getListFormData(): void{

        this.loadingIn(this.adRevenue_template.builtTemplate.children.namedItem('list-results'));
        this.adRevenue_template.hideExportButton();
        this.requestHandler = new XMLHttpRequestHandler(`/api/ad_revenue/references`, [], this);
        this.requestHandler.onReadyStateFunction = this.onFilterRequestReturn;
        this.requestHandler.execute();
    }

    //
    // Returns handler for filter request.
    protected onFilterRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateFilters(responseParsed);
                obj.getListData();
            });
        }
    }

    //
    // Fetches the list data.
    public getListData(): void{

        this.loadingIn(this.adRevenue_template.builtTemplate.children.namedItem('list-results'));
        this.adRevenue_template.hideExportButton();

        if(this.requestHandler){

            this.requestHandler.cancelAndReset();
        }

        let subViewModel = this.adRevenue_template.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(['data', ko.toJSON(subViewModel)] );

        this.requestHandler = new XMLHttpRequestHandler(`/api/ad_revenue/get`, usrReq_params, this);
        this.requestHandler.onReadyStateFunction = this.onDataRequestReturn;
        this.requestHandler.execute();
    }

    //
    // Returns handler for data request.
    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateList(responseParsed);
                obj.setResultInfo(responseParsed.totalCount, responseParsed.totalPage);
                obj.loadingOut(obj.adRevenue_template.builtTemplate.children.namedItem('list-results'));
            });
        }
    }

    //
    // Update the module list using the objects list.
    protected updateList(responseParsed: any): void{

        this.loadingIn(this.adRevenue_template.builtTemplate.children.namedItem('list-results'));

        let data                           = responseParsed;
        // let records                        = data.records;
        // let maxHour                        = data.maxHour;
        // let chartValues                    = [];
        // let eventTypes                     = data.eventTypes;
        let headers                         = data.headers;
        // let adDimensionHeaders              = headers.adDimensionHeaders;
        let adDimension                     = data.adDimension;
        let tableData                       = data.tableData;
        this.adRevenue_template.setTableData(tableData);
        this.adRevenue_template.getTemplateViewModel().isTableVisible(true);
        this.adRevenue_template.setHeaders(headers, headers, adDimension);
    }


    //
    // Update the filters.
    protected updateFilters(responseParsed: any): void{

        this.adRevenue_template.setDeviceFilterItems(adRevenue.devices);
        this.adRevenue_template.setTemplateFilterItems(responseParsed.templates);
        this.adRevenue_template.setNetworkCategoryFilterItems(responseParsed.networkCategories);
        this.adRevenue_template.setContentTypeFilterItems(responseParsed.contentTypes);
        this.adRevenue_template.setGeoFilterItems(responseParsed.geos);
        this.adRevenue_template.setSourceFilterItems(responseParsed.sources);
        this.adRevenue_template.setOperatingSystemsFilterItems(responseParsed.operatingSystems);
        this.adRevenue_template.setBrandFilterItems(responseParsed.brands);
        this.adRevenue_template.setAdDimensionFilterItems(responseParsed.adDimensions);
    }

    //
    // Hook.
    // Initiates data refresh.
    public filterDropdownOnChange(): void{

        this.getListData();
    }

    //
    // Sets results info.
    protected setResultInfo(totalCount, totalPage){

        let execTime = Math.round((new Date().getTime() - this.requestHandler.requestStartTime)/1000*100)/100;
        this.adRevenue_template.setResultInfo(totalCount, totalPage, execTime);
    }

    //
    // Hook.
    // Cancels data request.
    protected filterSimpleCancel(){

        if(this.requestHandler){

            this.requestHandler.cancelAndReset();
        }
    }

    //
    // Hook.
    // Initiates data refresh.
    protected filterSimpleOnChange(){

        this.getListData();
    }

    //
    // Hook.
    // Initiates data refresh.
    protected filterMultipleSelectorOnChange(){

        this.getListData();
    }

    //
    // Hook.
    // Initiates data refresh.
    protected filterSingleDateOnChange(){

        this.getListData();
    }

    //
    // Returns display hour.
    private getDisplayHour(hour){

        if(hour >= 10){

            return `${hour}:00`;
        }

        return `0${hour}:00`;
    }


    protected filterDateOnChange(){

        this.getListData();
    }

    /**
     * Fetches export data
     */
    public getExportData(): void{

        this.loadingIn(this.adRevenue_template.builtTemplate.children.namedItem("list-results"));

        this.requestHandler.cancelAndReset();

        let subViewModel = this.adRevenue_template.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(["data", ko.toJSON(subViewModel)] );
        this.requestHandler = new XMLHttpRequestHandler('/api/ad_revenue/get/',usrReq_params, this);

        this.requestHandler.onReadyStateFunction = this.onGetExportData;
        this.requestHandler.execute();
    }

    protected onGetExportData(req, obj){

        return function () {

            obj.manageResponse(req, obj, function () {

                let responseParsed = JSON.parse(req.request.response);

                if (req.request.status == 200) {

                    obj.adRevenue_template.setExportData(responseParsed);
                    obj.adRevenue_template.exportData();
                }
            });

            obj.loadingOut(obj.adRevenue_template.builtTemplate.children.namedItem("list-results"));
        }
    }
}