import { adsSetupList_template } from '../components/templates/adsSetupList_template';
import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class adsSetups extends vnModule_knockoutJS{

    protected adSetupList_template: adsSetupList_template;
    protected XHRHdl: XMLHttpRequestHandler;

    static devices : Array<{id : string, name : string}> = [
        { "id": "mobile",  "name": "Mobile"},
        { "id": "desktop",  "name": "Desktop"},
    ];

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'ads_setups';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Ads Setups List");

        this.adSetupList_template = new adsSetupList_template('ads_setups', this.viewModel, this)

        return [this.adSetupList_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getListFormData();
    }

    /**
     * @inheritDoc
     */
    protected reorderAdsSetups(): void{

        this.getListData();
    }

    /**
     * Requests data for the filters.
     */
    protected getListFormData(): void{

        this.loadingIn(this.adSetupList_template.builtTemplate.children.namedItem('list-results'));

        this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ads_setup/references`, [], this);
        this.XHRHdl.onReadyStateFunction = this.onFilterRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for filter request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onFilterRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateFilters(responseParsed);
                obj.getListData();
            });
        }
    }

    /**
     * Fetches the list data.
     */
    public getListData(): void{

        this.loadingIn(this.adSetupList_template.builtTemplate.children.namedItem("list-results"));

        if( this.XHRHdl ){
            this.XHRHdl.cancelAndReset();
        }

        let subViewModel = this.adSetupList_template.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(['data', ko.toJSON(subViewModel)] );

        this.XHRHdl = new XMLHttpRequestHandler('/api/brand/' + this.getVnAppZone().app.params.idBrand + '/ads_setup/get', usrReq_params, this);
        this.XHRHdl.onReadyStateFunction = this.onDataRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for data request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateList(responseParsed);
                obj.setResultInfo(responseParsed.totalCount, responseParsed.totalPage);
                obj.loadingOut(obj.adSetupList_template.builtTemplate.children.namedItem('list-results'));
            });
        }
    }

    /**
     * Update the module list using the objects list.
     *
     * @param responseParsed
     */
    protected updateList(responseParsed: any): void{

        this.loadingIn(this.adSetupList_template.builtTemplate.children.namedItem('list-results'));

        this.adSetupList_template.getTemplateViewModel().isInsertButtonShown(responseParsed.data.copiedAdsetInSession);
        this.adSetupList_template.setAdsSetups(responseParsed.data.adsSetups, responseParsed.data.adsSetupsMetrics);
        this.adSetupList_template.setIsFiltered(responseParsed.data.isFiltered);
    }

    /**
     * Update the filters.
     *
     * @param responseParsed
     */
    protected updateFilters(responseParsed: any): void{

        this.adSetupList_template.setDeviceFilterItems(adsSetups.devices);
        this.adSetupList_template.setTemplateFilterItems(responseParsed.templates);
        this.adSetupList_template.setNetworkCategoryFilterItems(responseParsed.networkCategories);
        this.adSetupList_template.setContentTypeFilterItems(responseParsed.contentTypes);
        this.adSetupList_template.setSspGroupFilterItems(responseParsed.sspGroups);
        this.adSetupList_template.setGeoFilterItems(responseParsed.geos);
        this.adSetupList_template.setSourceFilterItems(responseParsed.sources);
    }

    /**
     * Saves the user data.
     */
    protected updatePositions(): void{

        this.loadingIn(this.adSetupList_template.builtTemplate.children.namedItem('list-results'));

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.adSetupList_template.getViewModelForRequest();
        let idBrand = this.getVnAppZone().app.params.idBrand;

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/brand/${idBrand}/ads_setup/update_positions/`, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onUpdatePositionsReturn;
        XHRHdl.execute();
    }

    /**
     * Handles update positions response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onUpdatePositionsReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getListData();
            });
        }
    }

    /**
     * Hook.
     *
     * Redirects to create user page.
     */
    protected addAdsSetup(): void{

        page(`/brand/${this.getVnAppZone().app.params.idBrand}/ads_setups/add`);
    }

    /**
     * Hook.
     *
     * Redirects to edit adsSetup.
     */
    protected editAdsSetup(id, event): void{

        // page(`/brand/${this.getVnAppZone().app.params.idBrand}/ads_setups/edit/${id}`);
        this.handleNavigation(`/brand/${this.getVnAppZone().app.params.idBrand}/ads_setups/edit/${id}`, event);
    }

    /**
     * Hook.
     *
     * Redirects to edit adUnits.
     */
    protected editAdUnits(id, event): void{

        // page(`/brand/${this.getVnAppZone().app.params.idBrand}/ads_setups/${id}/adunits`);
        this.handleNavigation(`/brand/${this.getVnAppZone().app.params.idBrand}/ads_setups/${id}/adunits`, event);
    }


    protected deleteAdsSetup(id): void{

        this.loadingIn(this.adSetupList_template.builtTemplate.children.namedItem("list-results"));

        this.XHRHdl                         = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ads_setup/delete/${id}`, [], this);
        this.XHRHdl.mode                    = XMLHttpRequestHandler_requestType.POST;
        this.XHRHdl.onReadyStateFunction    = this.onDeleteRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for filter request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDeleteRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.updatePositions();
            });
        }
    }

    protected copyAdsSetup(id): void{

        this.loadingIn(this.adSetupList_template.builtTemplate.children.namedItem("list-results"));

        this.XHRHdl                         = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ads_setup/copy/${id}`, [], this);
        this.XHRHdl.mode                    = XMLHttpRequestHandler_requestType.POST;
        this.XHRHdl.onReadyStateFunction    = this.onCopyRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for filter request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onCopyRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.loadingOut(obj.adSetupList_template.builtTemplate.children.namedItem('list-results'));

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Adsetup successfully copied ');
                obj.getVnAppZone().showNotificationToModule();
                obj.adSetupList_template.getTemplateViewModel().isInsertButtonShown(true);
                obj.adSetupList_template.getTemplateViewModel().setAllChecked(false);

            });
        }
    }


    protected insertAdsSetup(): void{

        this.loadingIn(this.adSetupList_template.builtTemplate.children.namedItem("list-results"));

        this.XHRHdl                         = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ads_setup/insert`, [], this);
        this.XHRHdl.mode                    = XMLHttpRequestHandler_requestType.POST;
        this.XHRHdl.onReadyStateFunction    = this.onInsertRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for filter request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onInsertRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getListData();
                obj.loadingOut(obj.adSetupList_template.builtTemplate.children.namedItem('list-results'));
            });
        }
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public filterDropdownOnChange(): void{

        this.getListData();
    }

    protected setResultInfo(totalCount, totalPage){
        let execTime = Math.round((new Date().getTime() - this.XHRHdl.requestStartTime)/1000*100)/100;
        this.adSetupList_template.setResultInfo(totalCount, totalPage, execTime);
    }

    /**
     * Hook.
     *
     * Redirects to ad units page.
     */
    protected goToAdUnitsList(): void{

        page(`/brand/${this.getVnAppZone().app.params.idBrand}/ad_units`);
    }

    /**
     * Hook.
     *
     * Cancels data request.
     */
    protected filterSimpleCancel(){

        if(this.XHRHdl){

            this.XHRHdl.cancelAndReset();
        }
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected filterSimpleOnChange(){

        this.adSetupList_template.resetOrderParameters();
        this.getListData();
    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.deleteAdsSetup(data);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

    }


    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected filterMultipleSelectorOnChange(){

        this.getListData();
    }
}