import {vnTemplate} from "./vnTemplate";
import {validationsList_template} from "./validationsList_template";
import {pagination_template} from "./pagination_template";
import {filterSimple_template} from "./filterSimple_template";

declare var ko: any;

export class login_template extends vnTemplate{

    protected validationsList: validationsList_template;

    static readonly ERROR_TYPE_VALIDATION = "validation";

    protected getTemplateHtml(): string{

        return `
           
            ` + this.validationsList.getHtmlBuild() + `
           
                <div class="w-form center" id="login-form">
                    <div class="form-header">
                        <h3 class="form-title icon i-login">Log In</h3>
                        <button class="close-button icon i-close-2" type="button"></button>
                    </div>
                    <form class="form-body">
                        <div class="form-group">
                            <label class="form-label">Username or Email Address</label>
                            <input data-bind="value: u, uniqueName: true, event: { keyup: onKeyUpLoginAction}" type="text" class="input-text"  />
                        </div>
                        <div class="form-group">
                            <label class="form-label">Password</label>
                            <input data-bind="value: p, uniqueName: true, event: { keyup: onKeyUpLoginAction}" type="password" class="input-text" />
                            <button class="btn-text" type="button" data-bind="event: { click: onClickForgotPasswordAction}">Forgot your password</button>
                        </div>
                        <div class="form-group">

                        </div>
                    </form>
                    <div class="form-footer">
                        <button data-bind="event: { click: onSubmitLoginForm}" type="button" class="btn highlight">log in</button>
                        <button type="button" class="btn">Cancel</button>
                    </div>
                </div>

                <div class="w-form over center is-hidden" id="retrieve-password">
                    <div class="form-header">
                        <h3 class="form-title icon i-user">Please Enter your Username or Email Below</h3>
                        <button data-bind="event: { click: onClickCloseRetrieveAction}" type="button" class="close-button icon i-close-2"></button>
                    </div>
                    <form class="form-body" onsubmit="return false;">
                        <div>
                            <div class="form-group">
                                <input data-bind="value: userInput" type="hidden" />
                                <div class="form-check-inline width-auto">
                                    <input data-bind="event: { click: onClickRadioPasswordAction}" type="radio" name="radioPass" id="radio_email" value="1" checked>
                                    <label class="form-label" for="radio_email">Email</label>
                                </div>
                                <div class="form-check-inline width-auto">
                                    <input data-bind="event: { click: onClickRadioPasswordAction}" type="radio" name="radioPass" id="radio_name" value="0" >
                                    <label class="form-label" for="radio_name">User Name</label>
                                </div> 
                            </div>
                            <div class="form-group">
                                <input data-bind="value: retrieveInput, event: { keyup: onKeyUpSentLinkAction}" id="retrieveInput" type="text" class="input-text"/>
                            </div>
                        </div>
                    </form>
                    <div class="form-footer">
                        <button class="btn" type="button" data-bind="event: { click: onClickRetrievePasswordAction}">Send Me Reset Link</button>
                    </div>
                </div>

                <div class="w-form center over is-hidden" data-bind="style: {display: sentMessage}">
                    <div class="form-header">
                        <h3 class="form-title icon i-user">Password update confirmation</h3>
                        <button data-bind="event: { click: onClickCloseRetrieveAction}" type="button" class="close-button icon i-close-2"></button>
                    </div>
                    <form class="form-body">
                        <div class="form-group">
                             <span data-bind="text: retrieveInfo" class="sub-title"></span>
                        </div>
                    </form>
                    <div class="form-footer">
                        <button  class="btn" type="button" data-bind="event: { click: onClickCloseRetrieveAction}">Go back to Log In</button>
                    </div>
                </div>
            `;
    }

    protected initPartial(): void {

        this.validationsList = new validationsList_template("validationList", this.getTemplateViewModel(), this.caller);
    }

    protected getTemplateName(): string{
        return "login-form-template";
    }

    protected getMainElementClasses(): Array<string>{
        return ["form-container"];
    }

    protected buildViewModel(): any{

        let huit_neuf_this = this;
        return  {    u: ""
                    ,p: ""
                    ,retrieveInput: ko.observable("")
                    ,retrieveInfo: ko.observable("")
                    ,userInput: ko.observable("1")
                    ,sentMessage: ko.observable("none")
                    ,onKeyUpLoginAction: this.onKeyUpLoginAction
                    ,onKeyUpSentLinkAction: function(data, event){ huit_neuf_this.onKeyUpSentLinkAction(data,event);}
                    ,onClickForgotPasswordAction: this.onClickForgotPasswordAction
                    ,onClickRetrievePasswordAction: function(data, event){ huit_neuf_this.onClickRetrievePasswordAction(); }
                    ,onClickRadioPasswordAction: this.onClickRadioPasswordAction
                    ,onClickCloseRetrieveAction: function(data, event){ huit_neuf_this.onRedirectToLogin(); }
                    ,onSubmitLoginForm: function(data, event){ huit_neuf_this.caller.onSubmitLoginForm(); }
                };
    }

    public onKeyUpLoginAction(data, event) {

        event.preventDefault();
        if (event.keyCode == 13) {
            data.onSubmitLoginForm();
        }
    }

    public onKeyUpSentLinkAction(data, event) {

        event.preventDefault();
        if (event.keyCode == 13) {
            this.caller.retrievePassword();
        }
        return false;
    }

    protected onClickForgotPasswordAction(data,event){
        event.preventDefault();
        let zone1 = document.querySelector("#retrieve-password");
        zone1.classList.remove("is-hidden");

        let zone2 = document.querySelector("#login-form");
        zone2.classList.add("is-hidden");

    }

    protected onClickRetrievePasswordAction(){
        this.caller.retrievePassword();
    }

    protected onClickRadioPasswordAction(data,event){
        if(event.target.getAttribute("id") == "radio_email"){
            data.userInput("1");
        }else{
            data.userInput("0");
        }
        data.retrieveInput("");

        return true;
    }

    protected onRedirectToLogin(){
        this.caller.redirectToLogin();
    }

    public setError(error): void{

        this.validationsList.setMessageUsingDefaultFormat(error);
        window.scrollTo(0, 0);
    }

    public clearValidationsMessages(){

        this.validationsList.clearMessages();
    }
}
