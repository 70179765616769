import { vnTemplate } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate';
import { filterDropdown_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDropdown_template';
import { resultsInfo_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/resultsInfo_template';
import { filterSimple_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterSimple_template";
import {infoButton_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/infoButton_template";
import { multipleSelector_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/multipleSelector_template";
import {vnApp_ads_ninja} from "../vnApp_ads_ninja";

declare var ko: any;


export class adsSetupList_template extends vnTemplate {

    protected searchFilter: filterSimple_template;
    protected deviceFilter: multipleSelector_template;
    protected templateFilter: multipleSelector_template;
    protected networkCategoryFilter: multipleSelector_template;
    protected contentTypeFilter: multipleSelector_template;
    protected geoFilter: multipleSelector_template;
    protected sourceFilter: multipleSelector_template;
    protected sspGroupFilter: multipleSelector_template;
    protected resultsInfo: resultsInfo_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="w-page-tab">
                <div class="page-tab">
                    <button class="btn large icon i-settings is-selected" type="button">Ad Setups</button>
                    <button data-bind="click: onClickRedirect" class="btn large icon i-unit" type="button">Ad Units</button>
                </div>
            </div>

            <div class="sub-header">
                <div class="w-justify">
                    <div class="w-left">
                        <div>
                            <label class="form-label form-label-box multi">Device</label>
                            ${this.deviceFilter.getHtmlBuild()}
                        </div>
                        <div>
                            <label class="form-label form-label-box multi">Template</label>
                            ${this.templateFilter.getHtmlBuild()}
                        </div>
                        <div>  
                            <label class="form-label form-label-box multi">Source</label>
                            ${this.sourceFilter.getHtmlBuild()}
                        </div> 
                         <div>   
                            <label class="form-label form-label-box multi">Geo</label>
                            ${this.geoFilter.getHtmlBuild()}
                        </div>
                        <div>
                            <label class="form-label form-label-box multi">Network Category</label>
                            ${this.networkCategoryFilter.getHtmlBuild()}
                        </div>
                        <div>
                            <label class="form-label form-label-box multi">Content Type</label>
                            ${this.contentTypeFilter.getHtmlBuild()}
                        </div>
                         <div>
                            <label class="form-label form-label-box multi">SSP Group</label>
                            ${this.sspGroupFilter.getHtmlBuild()}
                        </div>  
                    </div>
                </div>
        </div>
        <div class="w-filters-selected">
            <div id="filters-selected" class="filters-selected">
                <div id="selected-devices"></div>
                <div id="selected-templates"></div>
                <div id="selected-networkCategory"></div>
                <div id="selected-contentType"></div>
                <div id="selected-sspGroup"></div>
                <div id="selected-geo"></div>
                <div id="selected-source"></div>
            </div>
        </div>      
        
        <div class="list-header w-justify"> 
            <div class="w-left" data-bind="if: hasWriteAccess">
                <button data-bind="click: onClickAdd" class="btn icon i-add" type="button">Add Ads Setup</button>
                <button data-bind="click: setAllChecked(true)" class="btn" type="button" >Select All</button>
                <button data-bind="click: setAllChecked(false)" class="btn" type="button">Deselect All</button>
                
                <button class="btn left-join icon i-check" type="button" data-bind="css: { 'is-disable': atLeastOneSelected() == false}, event: {click: onClickUpdate}">Copy selected</button>
                
                <!-- ko if: isInsertButtonShown  -->
                <button data-bind="click: onClickInsert" class="btn icon i-insert" type="button">Insert copied Ads Setup</button>
                <!-- /ko -->
            </div>  
            <div class="w-center">
                ${this.searchFilter.getHtmlBuild()}
            </div>   
            <div class="w-right"></div>
        </div>
        
        <div class="w-list-results" id="list-results">
       
            <table class="list-results">
                ${this.resultsInfo.getHtmlBuild()}
                <thead> 
                    <tr>  
                        <th class="short" data-bind="visible: hasUpdateAccess"></th>  
                        <th data-bind="visible: hasUpdateAccess"><span>Edit</span></th>
                        <th><span class="icon i-position"></span></th>      
                        <th><span>status</span></th>  
                        <th><span>ratio</span></th>  
                        <th><span>name</span></th>
                        <!--<th><span>group</span></th>-->
                        <th><span>group label</span></th>
                        <th><span>tag</span></th>
                        <th><span>RPM</span></th>
                        <th><span>REQUESTS/<br>PAGE</span></div></th>
                        <th><span>route</span></th>
                        <th><span>device</span></th>
                        <th><span>template</span></th>
                        <th><span>source</span></th>
                        <th><span>Geo</span></th>
                        <th><span>network<br />category</span></th>
                        <th><span>content<br />type</span></th>
                        <th><span>ssp<br />group</span></th>
                        <th><span>extra<br />parameters</span></th>
                        <th><span>updated</span></th>
                        <th data-bind="visible: hasWriteAccess"><span>select</span></th>
                        <th data-bind="visible: hasWriteAccess"><span>copy</span></th>
                        <th data-bind="visible: hasDeleteAccess"><span>delete</span></th>
                   </tr> 
                </thead>
                <tbody data-bind="foreach: sortable" class="drag-handles sortable">  
                    <tr data-bind="css: {'is-checked': value.checked, dragging: dragging },
                                       dragZone: { name: 'sortable',
                                         dragStart: $parent.dragStart,
                                         dragEnd: $parent.dragEnd
                                       },
                                       dragEvents: {
                                         accepts: 'sortable',
                                         dragOver: $parent.reorderSortable,
                                         data: { items: $parent.sortable, item: $data }
                                       }">
                        <td class="drag-handle short" data-bind="visible: $parent.hasUpdateAccess"><span class="btn no-bg icon i-move"></span></td>
                        <td class="w-btn" data-bind="visible: $parent.hasUpdateAccessForAdsSetupsOrAdUnits">
                            <div class="flex">
<!--                                <button title="edit ads setup" data-bind="visible: $parent.hasUpdateAccess, event: { click: $parent.onClickEdit }" class="btn icon-only icon i-edit" type="button"><span class="hidden-text">Edit</span></button>-->
<!--                                <button title="edit ads unit" data-bind="visible: $parent.hasUpdateAccessForAdUnits, event: { click: $parent.onClickEditAdUnits }" class="btn icon-only icon i-unit" type="button"><span>edit ads unit</span></button>-->
                                    <a title="edit ads setup" data-bind="attr: {href: 'ads_setups/edit/'+value.id, class: 'btn icon-only icon i-edit'}, visible: $parent.hasUpdateAccess"><span class="hidden-text">Edit</span></a>  
                                    <a title="edit ads unit" data-bind="attr: {href: 'ads_setups/'+value.id+'/adunits', class: 'btn icon-only icon i-unit'}, visible: $parent.hasUpdateAccessForAdUnits"><span>edit ads unit</span></a>  
                            </div>
                        </td>
                        <td class=""><span data-bind="text: value.position"></span></td>
                        <td class=""><span data-bind="text: value.status"></span></td>
                        <td class=""><span data-bind="text: value.ratio"></span></td>
                        <td class=""><span data-bind="text: value.name"></span></td>
                        <!--<td class=""><span data-bind="text: value.group_name"></span></td>-->
                        <td class=""><span data-bind="text: value.groupLabel_name"></span></td>
                        <td class=""><span data-bind="text: value.adsSetupTag_name"></span></td>
                        
                        <!-- ko ifnot: value.yesterdayPageviews == 0 -->
                        <td class="is-rpm">
                            <div class="with-
                            ips">
                                <span data-bind="text: Number(value.yesterdayRpm).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '$'"></span>
                            </div>
                            <span data-bind="text: (value.rpmTrend > 0 ? '+': '') + Number(value.rpmTrend).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%', class: 'is-rpm indic-' + (Math.abs(value.rpmTrend) >= 10 ? 'very-': '') + (value.rpmTrend >= 0 ? 'good': 'bad')"></span>
                        </td>
                        <!-- /ko -->

                        <!-- ko if: value.yesterdayPageviews == 0 -->
                        <td class="is-revenue ">
                            <div class="with-tooltips">
                                <span data-bind="text: Number(value.yesterdayRevenues).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '$'"></span>
                                <div class="w-tooltips-adv short" style="margin-left: 4rem;" data-bind="template: { name: 'infoButton-template', data: value.rpmInfoButton.getTemplateViewModel() }"></div>
                            </div>
                            <span data-bind="text: (value.revenuesTrend > 0 ? '+': '') + Number(value.revenuesTrend).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%', class: 'is-revenue indic-' + (Math.abs(value.revenuesTrend) >= 10 ? 'very-': '') + (value.revenuesTrend >= 0 ? 'good': 'bad')"></span>
                        </td>
                        <!-- /ko -->

                        <!-- RPP & RPP trend -->
                        <td class="">
                            <div class="with-tooltips">
                                <span data-bind="text: Number(value.yesterdayRpp).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})"></span>
                                <!-- ko if: value.yesterdayPageviews == 0 -->
                                <div class="w-tooltips-adv short" style="margin-left: 4rem;" data-bind="template: { name: 'infoButton-template', data: value.rppInfoButton.getTemplateViewModel() }"></div>
                                <!-- /ko -->
                            </div>
                            <span data-bind="text: (value.rppTrend > 0 ? '+': '') + Number(value.rppTrend).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%', class: 'indic-' + (Math.abs(value.rppTrend) >= 10 ? 'very-': '') + (value.rppTrend >= 0 ? 'good': 'bad')"></span>
                        </td>
                        <td class=""><span data-bind="text: value.route"></span></td>
                        <td class=""><span data-bind="text: value.device"></span></td>
                        <td class="no-wrap"><span data-bind="text: value.template_name"></span></td>
                        <td class=""><span data-bind="text: value.sources_list_name"></span></td>
                        <td class="no-wrap"><span data-bind="html: $parent.getGeoColumnValue(value), attr: { title: value.geos_list_name }"></span></td>
                        <td class=""><span data-bind="text: value.networkCategory_name"></span></td>
                        <td class="no-wrap"><span data-bind="text: value.contentType_name"></span></td>
                        <td class=""><span data-bind="text: value.sspGroup_name"></span></td>
                        <td class=""><span data-bind="text: value.extraParameters"></span></td>
                        <td class="">
                            <span class="no-wrap icon i-user" data-bind="text: value.updater_name"></span>
                            <span class="no-wrap icon i-calendar-b" data-bind="text: value.dateUpdated"></span>
                        </td>
                        <td class="" data-bind="visible: $parent.hasWriteAccess">
                            <div style="margin-top:-1.25rem">
                                <input type="checkbox" data-bind="checked: value.checked, visible: $parent.hasWriteAccess, attr:{id: 'adsetup_' + value.id}, click:$parent.onClickChecked()"/> 
                                <label data-bind="attr:{for: 'adsetup_' + value.id}" class="form-label icon i-check"></label>

                            </div>
                        </td>
                        <td class="" data-bind="visible: $parent.hasWriteAccess">
                            <button title="copy ads setup" data-bind="visible: $parent.hasWriteAccess, event: { click: $parent.onClickCopyAdset }" class="btn icon-only icon i-copy" type="button"><span>Copy</span></button>
                        </td>
                        <td class="" data-bind="visible: $parent.hasDeleteAccess">
                            <button title="delete ads setup" data-bind="class: 'btn icon-only icon i-delete' + (value.isActive ? ' is-disable' : ''), visible: $parent.hasWriteAccess, event: { click: value.isActive ? '' : $parent.onClickDelete }" type="button"><span>Delete</span></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <style>
            .dragging {
                opacity: 0.5;
                filter: "alpha(opacity=50)";
            }                
        </style>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{

        this.searchFilter = new filterSimple_template('searchFilter', this.getTemplateViewModel(), this.caller);

        this.deviceFilter = new multipleSelector_template('deviceFilter', this.getTemplateViewModel(), this.caller);
        this.deviceFilter.setFilterContainerClass('margin-neg');
        this.deviceFilter.setTagContainerId('selected-devices');
        this.deviceFilter.setTagContainerCaption('Selected Devices:');


        this.templateFilter = new multipleSelector_template('templateFilter', this.getTemplateViewModel(), this.caller);
        this.templateFilter.setFilterContainerClass('margin-neg');
        this.templateFilter.setTagContainerId('selected-templates');
        this.templateFilter.setTagContainerCaption('Selected Templates:');

        this.networkCategoryFilter    = new multipleSelector_template('networkCategoryFilter', this.getTemplateViewModel(), this.caller);
        this.networkCategoryFilter.setFilterContainerClass('margin-neg');
        this.networkCategoryFilter.setTagContainerId('selected-networkCategory');
        this.networkCategoryFilter.setTagContainerCaption('Selected Network Category:');

        this.contentTypeFilter  = new multipleSelector_template('contentTypeFilter', this.getTemplateViewModel(), this.caller);
        this.contentTypeFilter.setFilterContainerClass('margin-neg');
        this.contentTypeFilter.setTagContainerId('selected-contentType');
        this.contentTypeFilter.setTagContainerCaption('Selected Content Type:');

        this.sspGroupFilter  = new multipleSelector_template('sspGroupFilter', this.getTemplateViewModel(), this.caller);
        this.sspGroupFilter.setFilterContainerClass('margin-neg');
        this.sspGroupFilter.setTagContainerId('selected-sspGroup');
        this.sspGroupFilter.setTagContainerCaption('Selected sspGroup:');

        this.geoFilter    = new multipleSelector_template('geoFilter', this.getTemplateViewModel(), this.caller);
        this.geoFilter.setFilterContainerClass('margin-neg');
        this.geoFilter.setTagContainerId('selected-geo');
        this.geoFilter.setTagContainerCaption('Selected Geo:');

        this.sourceFilter  = new multipleSelector_template('sourceFilter', this.getTemplateViewModel(), this.caller);
        this.sourceFilter.setFilterContainerClass('margin-neg');
        this.sourceFilter.setTagContainerId('selected-source');
        this.sourceFilter.setTagContainerCaption('Selected Source:');

        this.resultsInfo     = new resultsInfo_template('resultsInfo', this.getTemplateViewModel(), this.caller);
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return 'adsSetupList_template';
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return [];
    }

    /**
     * @inheritDoc
     */
    public activateLoading(): void{

        this.getTemplateViewModel().isLoading(1);
    }

    /**
     * @inheritDoc
     */
    public deactivateLoading(): void{

        this.getTemplateViewModel().isLoading(0);
    }

    /**
     * @inheritDoc
     */
    public buildViewModel(): Object{

        let self                      = this;
        let hasUpdateAccessForAdUnits = this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_AD_UNIT, vnApp_ads_ninja.ACCESS_TYPE_UPDATE);
        let hasUpdateAccess           = this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_AD_SETUP, vnApp_ads_ninja.ACCESS_TYPE_UPDATE);
        let hasWriteAccess            = this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_AD_SETUP, vnApp_ads_ninja.ACCESS_TYPE_WRITE);
        let hasDeleteAccess           = this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_AD_SETUP, vnApp_ads_ninja.ACCESS_TYPE_DELETE);

        return {
            orderBy: ko.observable('position'),
            orderByDirection: ko.observable('ASC'),
            orderByAndDirection: ko.observable('position_ASC'),
            adsSetups: ko.observableArray([]),
            hasUpdateAccessForAdsSetupsOrAdUnits: ko.observable((hasUpdateAccessForAdUnits || hasUpdateAccess)),
            hasUpdateAccessForAdUnits: ko.observable(hasUpdateAccessForAdUnits),
            hasUpdateAccess: ko.observable(hasUpdateAccess),
            hasDeleteAccess: ko.observable((hasDeleteAccess && hasUpdateAccess)),
            hasWriteAccess: ko.observable(hasWriteAccess),
            isInsertButtonShown: ko.observable(false),
            sortable: ko.observableArray([]),
            isFiltered: ko.observable(false),
            atLeastOneSelected: ko.observable(false),
            onClickReorder: function(value, data, event){ self.onClickReorder(value, data, event); },
            onClickEdit: function(data, event){ self.onClickEdit(data.value.id, event); },
            onClickAdd: function(){ self.onClickAdd(); },
            onClickDelete: function(data){ self.onClickDelete(data.value); },
            onClickCopyAdset: function(data){ self.onClickCopy(data.value.id); },
            onClickInsert: function(data){ self.onClickInsert(); },
            onClickEditAdUnits: function(data){ self.onClickEditAdUnits(data.value.id, event); },
            dragStart: function(data, event){ self.startDrag(data, event); },
            dragEnd: function(data){ data.dragging(false); self.updatePositions() },
            reorderSortable: function(event, dragData, zoneData){ self.reorderSortable(event, dragData, zoneData); },
            onClickRedirect: function(data, event){ self.caller.goToAdUnitsList(); },
            getGeoColumnValue: function(data){ return data.geo_parts.join('<br><br>'); },
            setAllChecked: function (value, data, event) { self.setAllChecked(value); },
            onClickChecked: function () { self.onClickChecked(); },
            onClickUpdate: function () {self.onClickUpdate();},
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        let sortable = this.getTemplateViewModel().sortable();

        let sortableForBackend = [];

        if(typeof sortable !== "undefined" && sortable !== null && Object.keys(sortable).length !== 0) {

            for(let currentSortable of sortable) {

                sortableForBackend.push({id: currentSortable.value.id, position: currentSortable.value.position});
            }

        }

        return {
            sortable: sortableForBackend,
            orderBy: this.getTemplateViewModel().orderBy(),
            orderByDirection: this.getTemplateViewModel().orderByDirection(),
            isFiltered: this.getTemplateViewModel().isFiltered(),
            filters: {
                searchString: this.searchFilter.getTemplateViewModel().filterKeyword(),
                selectedDevices: this.deviceFilter.getTemplateViewModel().selectedItems(),
                selectedTemplates: this.templateFilter.getTemplateViewModel().selectedItems(),
                selectedNetworkCategories: this.networkCategoryFilter.getTemplateViewModel().selectedItems(),
                selectedContentTypes: this.contentTypeFilter.getTemplateViewModel().selectedItems(),
                selectedSspGroups: this.sspGroupFilter.getTemplateViewModel().selectedItems(),
                selectedGeos: this.geoFilter.getTemplateViewModel().selectedItems(),
                selectedSources: this.sourceFilter.getTemplateViewModel().selectedItems(),
            },
        };
    }

    /**
     * Handles reordering on click event.
     *
     * @param value
     * @param data
     * @param event
     */
    public onClickReorder(value, data, event): void{

        this.getTemplateViewModel().orderBy(value);

        if( this.getTemplateViewModel().orderByDirection() == 'DESC' ){

            this.getTemplateViewModel().orderByDirection('ASC');
        }
        else{

            this.getTemplateViewModel().orderByDirection('DESC');
        }

        this.getTemplateViewModel().orderByAndDirection(value + '_' + this.getTemplateViewModel().orderByDirection());
        this.caller.reorderAdsSetups();
    }


    public startDrag(data, event){

        try{
            this.executeDrag(data, event);

        }catch(err){
            throw new Error(err.message);
        }
    }

    public executeDrag(data, event) {

        if (event.srcElement.className.indexOf('i-move') !== -1) {
            data.dragging(true);
            return true;
        } else {
            throw new Error('drag only allowed from the handle');
        }
    }

    public reorderSortable(event, dragData, zoneData): void{

        if(dragData !== zoneData.item){

            var zoneDataIndex = zoneData.items.indexOf(zoneData.item);

            zoneData.items.remove(dragData);
            zoneData.items.splice(zoneDataIndex, 0, dragData);
        }
    }

    public setDeviceFilterItems(devices): void{

        let devicesList = [];

        for(let device of devices){
            devicesList.push(device);
        }

        devicesList.unshift({'id' : 0, 'name' : 'all'});
        this.setMultiSelectFilter(this.deviceFilter, devicesList)
    }

    public setTemplateFilterItems(templates): void{

        templates.unshift({'id' : 0, 'name' : 'all'});
        this.setMultiSelectFilter(this.templateFilter, templates);
    }

    public setNetworkCategoryFilterItems(networkCategories): void{

        networkCategories.unshift({'id' : 0, 'name' : 'all'});
        this.setMultiSelectFilter(this.networkCategoryFilter, networkCategories);
    }

    public setContentTypeFilterItems(contentTypes): void{

        contentTypes.unshift({'id' : 0, 'name' : 'all'});
        this.setMultiSelectFilter(this.contentTypeFilter, contentTypes);
    }

    public setSspGroupFilterItems(sspGroups): void{

        sspGroups.unshift({'id' : 0, 'name' : 'all'});
        this.setMultiSelectFilter(this.sspGroupFilter, sspGroups);
    }

    public setGeoFilterItems(geos): void{

        geos.unshift({'id' : 0, 'name' : 'all'});
        this.setMultiSelectFilter(this.geoFilter, geos);
    }

    public setSourceFilterItems(sources): void{

        sources.unshift({'id' : 0, 'name' : 'all'});
        this.setMultiSelectFilter(this.sourceFilter, sources);
    }

    public resetOrderParameters(): void{

        this.getTemplateViewModel().orderBy('');
        this.getTemplateViewModel().orderByDirection('');
        this.getTemplateViewModel().orderByAndDirection('');
    }

    /**
     * Sets adsSetups.
     *
     * @param {Array} adsSetups
     * @param {Array} adsSetupsMetrics
     */
    public setAdsSetups(adsSetups: Array<any>, adsSetupsMetrics: Array<any>): void{

        let i = 0;

        adsSetups.forEach((adsSetup) => {

            let sourcesNames = '';
            let geosNames = '';
            adsSetup.status = 'inactive';
            adsSetup.checked = ko.observable(false);

            adsSetup.extraParameters = (adsSetup.extraParameters != null) ? JSON.stringify(adsSetup.extraParameters) : '';

            if(adsSetup.template_name === null){

                adsSetup['template_name'] = 'all';

            }

            if(adsSetup.contentType_name === null){

                adsSetup['contentType_name'] = 'all';

            }

            if(adsSetup.networkCategory_name === null){

                adsSetup['networkCategory_name'] = 'all';

            }

            if(adsSetup.isActive){

                adsSetup['status'] = 'active';

            }

            adsSetup['sourcesNames'] = sourcesNames;
            adsSetup['geosNames'] = geosNames;
            adsSetup.position = ko.observable(adsSetup.position);

            adsSetup['yesterdayRequests'] = 0;
            adsSetup['yesterdayRevenues'] = 0;
            adsSetup['yesterdayPageviews'] = 0;
            adsSetup['yesterdayRpm'] = 0;
            adsSetup['rpmTrend'] = 0;
            adsSetup['rppTrend'] = 0;
            adsSetup['revenuesTrend'] = 0;

            let rpmInfoButton = new infoButton_template('rpmInfoButton-' + i, this.getTemplateViewModel(), this.caller);
            rpmInfoButton.setTitle('RPM');
            rpmInfoButton.setContent('<p>RPM could not be calculated (0 pageviews). Displaying revenue instead.</p>');
            adsSetup['rpmInfoButton'] = rpmInfoButton;

            let rppInfoButton = new infoButton_template('rppInfoButton-' + i++, this.getTemplateViewModel(), this.caller);
            rppInfoButton.setTitle('RPP');
            rppInfoButton.setContent('<p>RPP could not be calculated (0 pageviews).</p>');
            adsSetup['rppInfoButton'] = rppInfoButton;

            adsSetupsMetrics.forEach((adsSetupMetrics) => {

                if (adsSetupMetrics.yesterday.idAdsSetup == adsSetup.id){

                    let yesterdayRPM = 0;
                    let twoDaysAgoRPM = 0;
                    let yesterdayRPP = 0;
                    let twoDaysAgoRPP = 0;

                    if (adsSetupMetrics.yesterday.pageviews != 0){

                        yesterdayRPM = (adsSetupMetrics.yesterday.revenues / adsSetupMetrics.yesterday.pageviews) * 1000;
                        yesterdayRPP = (adsSetupMetrics.yesterday.requests / adsSetupMetrics.yesterday.pageviews);
                    }

                    if(adsSetupMetrics.twoDaysAgo.pageviews != 0){

                        twoDaysAgoRPM = (adsSetupMetrics.twoDaysAgo.revenues / adsSetupMetrics.twoDaysAgo.pageviews) * 1000;
                        twoDaysAgoRPP = (adsSetupMetrics.twoDaysAgo.requests / adsSetupMetrics.twoDaysAgo.pageviews);
                    }

                    adsSetup['yesterdayRequests'] = adsSetupMetrics.yesterday.requests;
                    adsSetup['yesterdayRevenues'] = adsSetupMetrics.yesterday.revenues;
                    adsSetup['yesterdayPageviews'] = adsSetupMetrics.yesterday.pageviews;
                    adsSetup['yesterdayRpm'] = yesterdayRPM;
                    adsSetup['yesterdayRpp'] = yesterdayRPP;

                    if (twoDaysAgoRPM != 0){

                        adsSetup['rpmTrend'] = ((yesterdayRPM - twoDaysAgoRPM) / twoDaysAgoRPM) * 100;
                    }

                    if (adsSetupMetrics.twoDaysAgo.revenues != 0){

                        adsSetup['revenuesTrend'] = ((adsSetupMetrics.yesterday.revenues - adsSetupMetrics.twoDaysAgo.revenues) / adsSetupMetrics.twoDaysAgo.revenues) * 100;
                    }

                    if (twoDaysAgoRPP != 0){

                        adsSetup['rppTrend'] = ((yesterdayRPP - twoDaysAgoRPP) / twoDaysAgoRPP) * 100;
                    }
                }
            });
        });

        this.getTemplateViewModel().adsSetups(adsSetups);

        let x = this.toDraggables(adsSetups);
        this.getTemplateViewModel().sortable([].concat(x));
    }

    public setResultInfo(nbResults, nbPages, execTime){

        this.resultsInfo.setExecutionTime(execTime);
        this.resultsInfo.setNbResults(nbResults);
        this.resultsInfo.setNbPages(nbPages);
    }

    /**
     * Handles the add user action.
     */
    public onClickAdd(){

        this.caller.addAdsSetup();
    }

    /**
     * Handles on edit button click event.
     *
     * @param idAdsSetup
     */
    public onClickEdit(idAdsSetup, event){

        this.caller.editAdsSetup(idAdsSetup, event);
    }

    /**
     * Handles on edit button click event.
     *
     * @param idAdsSetup
     */
    public onClickEditAdUnits(idAdsSetup, event){

        this.caller.editAdUnits(idAdsSetup, event);
    }

    /**
     * Handles on delete button click event.
     *
     * @param idAdsSetup
     */
    public onClickDelete(data){

        this.caller.confirmationModal.setTitle('Confirm Deletion');
        this.caller.confirmationModal.setContext('delete');
        this.caller.confirmationModal.setMessageMain('Are you sure you would like to delete this ads setup?');
        this.caller.confirmationModal.setDataConfirm(data.id);
        this.caller.confirmationModal.setMessageSecondary(data.name);
        this.caller.confirmationModal.show();
    }

    /**
     * Handles on copy button click event.
     *
     * @param idAdsSetup
     */
    public onClickCopy(idAdsSetup){

        let id = [];
        id.push(idAdsSetup);
        this.caller.copyAdsSetup(JSON.stringify(id));

    }

    /**
     * Handles on insert button click event.
     *
     */
    public onClickInsert(){

        this.caller.insertAdsSetup();
    }

    /**
     * Handles dragstop event mainly to update adsSetups positions.
     *
     */
    public updatePositions(){
        console.log('update');
        this.caller.updatePositions();
    }

    private toDraggables(values) {
        return ko.utils.arrayMap(values, function (value) {
            return {
                value: value,
                dragging: ko.observable(false),
                isSelected: ko.observable(false)
            };
        });
    }
/*
    private getClosest(element, selector) {
        do {
            if (this.matches(element, selector)) {
                return element;
            }
            element = element.parentNode;
        } while (element);
        return null;
    }

    private  matches(element, selector) {
        if(!element.tagName) {
            return null;
        }
        var docEl = document.documentElement;
        var match = docEl.matches || docEl.matchesSelector || docEl.webkitMatchesSelector || docEl.mozMatchesSelector || docEl.msMatchesSelector || docEl.oMatchesSelector;
        return match.call(element, selector);
    }


 */

    public setIsFiltered(value){

        this.getTemplateViewModel().isFiltered(value);
    }

    public setAllChecked(value: boolean) {

        let adsSetups = this.getTemplateViewModel().adsSetups();

        adsSetups.forEach((adsSetup) => {

            adsSetup.checked(value);
        });

        this.getTemplateViewModel().adsSetups(adsSetups);

        this.setEnableApply();
    }

    public onClickChecked(){

        this.setEnableApply();

        return true;
    }

    public setEnableApply() {

        let adsSetups = this.getTemplateViewModel().adsSetups();

        let atLeastOne = false;

        adsSetups.forEach((adsSetup) => {

            if (adsSetup.checked() == true) {

                atLeastOne = true;
            }

        });

        this.getTemplateViewModel().atLeastOneSelected(atLeastOne);

    }

    public onClickUpdate() {

        let adsSetups = this.getTemplateViewModel().adsSetups();
        let ids = [];

        adsSetups.forEach((adsSetup) => {

            if (adsSetup.checked() == true) {

                ids.push(adsSetup.id)
            }

        });

        this.caller.copyAdsSetup(JSON.stringify(ids));
    }

    /**
     * Sets data for a multi select filter.
     *
     * @param filter
     * @param {Array<{ id: number, name: string }>} data
     */
    public setMultiSelectFilter(filter, data: Array<{ id: string, name: string }>): void{

        filter.setItems(data);
    }


}