import {vnTemplate} from "./vnTemplate";
import {validationsList_template} from './validationsList_template';

declare var ko: any;
declare var dhtmlXCalendarObject: any;
export class filterDate_template extends vnTemplate{

    public calendar1;
    public calendar2;

    protected getTemplateHtml(): string{

        return `

            <div class="w-time-period">
                <div class="dropdown">
            
                <button type="button" class="dropdown-toggle" data-bind="event:{click : toggle }, css: {'is-visible' : isActive() && !isShown()}, text: label"></button>
                <button type="button" class="dropdown-toggle" data-bind="event:{click : onClickCancel }, css: {'is-visible' : isActive() && isShown()}, text: label"></button>
                
                <div class="w-selection" data-bind="css: {'is-visible' : !isActive()}">
                      <button title="edit" type="button" class="btn-period-edit" data-bind=" event:{click: onClickEdit}">
                        <span class="clear-top-label" data-bind="text: label"></span>
                        <span class="w-clear-text"><label for="">from</label> <span data-bind="text: startDate"></span></span>
                        <span class="w-clear-text"><label for="">to</label> <span data-bind="text: endDate"></span></span>
                      </button>
                      <button title="clear" class="btn-period-clear icon i-x-after"  data-bind=" event:{click: onClickClear}"><span class="hide">clear-selection</span></button>
                </div>
                
                <div class="dropdown-period is-visible" data-bind="visible: isShown">
                
                    <div class="time-period-header">
                        <div class="form-check-inline width-auto">
                            <input type="radio" name="dates" id="dates" value="dates" data-bind="checked: selectedFilter">
                            <label class="form-label" for="dates">Dates</label>
                        </div>
                        <div class="form-check-inline width-auto">
                            <input type="radio" name="dates" id="range" value="range" data-bind="checked: selectedFilter">
                            <label class="form-label" for="range">Range</label>
                        </div>
                    </div>
                    
                    <div class="time-period-body period is-selected" data-bind="visible: selectedFilter() == 'dates'">
                        
                        <div class="dropdown">
                            <button type="button" class="dropdown-toggle icon i-month">Month</button>
                            <select data-bind="options: months,
                                               optionsText: 'month',
                                               value: selectedMonth,
                                               optionsCaption: 'Month',
                                               event: { change : onClickMonthAction }"></select>
                        </div>
                        
                        <div class="">
                            <span class="label-cal">From</span>
                                <div readonly="" data-bind="attr: { id: idStartPicker, name: idStartPicker}"></div>
                        </div>
                        
                        <hr class="spacer">
                        
                        <div class="">
                            <span class="label-cal">To</span>
                                <div readonly="" data-bind="attr: { id: idEndPicker, name: idEndPicker}"></div>
                        </div>    
                        <hr class="spacer">
                    </div>  
            
                    <div class="time-period-body range is-selected" data-bind="visible: selectedFilter() == 'range'">

                        <span class="label-range">from</span>

                        <div class="">
                            <input type="input" class="input-text" data-bind="textInput: fromRange, event: { keyup: onChangeInput}, attr: {placeholder: hmsEnabled() ? '-2m 3w 4d 12h 30mm' : '-2m 3w 4d'}">
                            <span class="label-preview" data-bind="text: fromRangeDate">From</span>
                        </div>
                        <span class="label-range">to</span>
                        <div class="">
                            <input type="input" class="input-text" data-bind="textInput: toRange, event: { keyup: onChangeInput}, attr: {placeholder: hmsEnabled() ? '-2m 3w 4d 12h 30mm' : '-2m 3w 4d'}">
                            <span class="label-preview" data-bind="text: toRangeDate">To</span>
                        </div>
                    </div>
                    
                    <div class="time-period-footer">
                        <button class="btn" type="button" data-bind="event:{click: onClickCancel}">cancel</button>
                        <button class="btn highlight" type="button" data-bind="event:{click: apply}">apply</button>
                    </div>
                </div>
            </div>
            </div>
            `;
    }

    protected getTemplateName(): string{
        return "filterDate-template";
    }

    protected getMainElementClasses(): Array<string>{
    	return [];
    }

    protected buildViewModel(): any{

        // define the viewmodel data/ functions
        let huit_neuf_this = this;
        let currentDate = new Date();

        return  {    startDate: ko.observable(this.getDateString(new Date(currentDate.getFullYear(),currentDate.getMonth(),1), true))
                    ,lastStartDate: ko.observable(null)
                    ,endDate: ko.observable(this.getDateString(new Date(currentDate.getFullYear(),currentDate.getMonth() + 1,0, 23, 59, 59), true))
                    ,lastEndDate: ko.observable(null)
                    ,months: ko.computed(function () { return huit_neuf_this.getAllMonths();}, this)
                    ,idStartPicker: ko.observable("startDatePicker")
                    ,idEndPicker: ko.observable("endDatePicker")
                    ,showClearDatesButton: ko.observable(false)
                    ,isActive:ko.observable(true)
                    ,isShown:ko.observable(false)
                    ,selectedFilter:ko.observable("dates")
                    ,lastSelectedFilter:ko.observable("dates")
                    ,fromRange : ko.observable(null)
                    ,lastFromRange : ko.observable(null)
                    ,fromRangeDate: ko.observable("")
                    ,toRange : ko.observable(null)
                    ,lastToRange : ko.observable(null)
                    ,toRangeDate: ko.observable("")
                    ,selectedMonth:ko.observable(null)
                    ,lastSelectedMonth:ko.observable(null)
                    ,label:ko.observable("Date Range")
                    ,hmsEnabled: ko.observable(false)
                    ,onClickMonthAction: function(data, event){huit_neuf_this.onClickMonthAction();}
                    ,onClickClear: function(data, event) { huit_neuf_this.onClickClear(); }
                    ,onClickCancel: function(data, event) { huit_neuf_this.onClickCancel(); }
                    ,onClickEdit: function(data, event) { huit_neuf_this.onClickEdit(); }
                    ,apply: function(data, event) { huit_neuf_this.apply(); }
                    ,toggle: function(data, event) { huit_neuf_this.toggle(); }
                    ,onChangeInput: function (data, event) {huit_neuf_this.onChangeInput(event);}
                };
    }

    protected onClickMonthAction() {
        
        let data = this.getTemplateViewModel().selectedMonth();

        let startDate = new Date(data.currentYear,data.currentMonth - 1,1, 0, 0, 0);
        let endDate = new Date(data.currentYear,data.currentMonth,0, 23, 59, 59);

        let startDateString = this.getDateString(startDate, this.getTemplateViewModel().hmsEnabled());
        let endDateString = this.getDateString(endDate, this.getTemplateViewModel().hmsEnabled());
        
        //set filters
        this.viewModel[this.idElement].startDate(startDateString);
        this.viewModel[this.idElement].endDate(endDateString);
        
        //set calender
        this.setCalenderDates(startDate, endDate);

        this.getTemplateViewModel().selectedMonth(null);
    }
    
    protected toggle(){
        
        this.getTemplateViewModel().isShown(true);
    }

    public buildCalendar(){

        let idStartPicker = this.getTemplateViewModel().idStartPicker();
        let idEndPicker = this.getTemplateViewModel().idEndPicker();

        if(this.calendar1 || this.calendar2){

            return;
        }

        this.calendar1 = new dhtmlXCalendarObject(idStartPicker);
        this.calendar1.showToday();
        this.calendar2 = new dhtmlXCalendarObject(idEndPicker);
        this.calendar2.showToday();

        if(!this.getTemplateViewModel().hmsEnabled()){

            this.calendar1.hideTime();
            this.calendar2.hideTime();
        }else{
            this.calendar1.setDateFormat("%Y-%m-%d %H:%i:%s");
            this.calendar2.setDateFormat("%Y-%m-%d %H:%i:%s");
        }

        //update calender if any
        this.setCalenderDates(this.getTemplateViewModel().startDate(), this.getTemplateViewModel().endDate());

        //call show api
        this.calendar1.show();
        this.calendar2.show();
    }

    public setCalenderDates(startDate, endDate){

        if(this.calendar1){
            this.calendar1.setDate(startDate);
        }
        if(this.calendar2){
            this.calendar2.setDate(endDate);
        }
    }
    
    public apply(){

        if(this.getTemplateViewModel().selectedFilter() == 'range'){

            this.clearValues(true);
            this.handleRangeFilter();

        }else{

            this.clearValues(false);
            let startDate = new Date();
            let endDate = new Date();
            endDate.setHours(23,59,59);

            if(this.calendar1._activeDate){
                if(!this.calendar1._nullDate){
                    startDate = this.calendar1._activeDate;
                }else{
                    startDate = null;
                }
            }

            if(this.calendar2._activeDate){
                if(!this.calendar2._nullDate){
                    endDate = this.calendar2._activeDate;
                }else{
                    endDate = null;
                }
            }

            if(!startDate && !endDate){
                this.onClickClear();
                return;
            }

            //to make the today button more reliable
            if(!this.getTemplateViewModel().hmsEnabled()){
                if(startDate){
                    startDate.setHours(0,0,0);
                }
                if(endDate){
                    endDate.setHours(23,59,59);
                }
            }

            if(startDate && endDate && startDate > endDate){
                this.showErrorMsg();
                return;
            }

            this.getTemplateViewModel().startDate(this.getDateString(startDate, this.getTemplateViewModel().hmsEnabled()));
            this.getTemplateViewModel().endDate(this.getDateString(endDate, this.getTemplateViewModel().hmsEnabled()));

            this.getTemplateViewModel().isActive(false)
            this.getTemplateViewModel().isShown(false);
            this.applyFilter();
        }
    }

    protected clearValues(clearCalendars){

        if(clearCalendars){

            this.getTemplateViewModel().startDate(null);
            this.getTemplateViewModel().endDate(null);
            this.getTemplateViewModel().selectedMonth(null);
            this.getTemplateViewModel().lastStartDate(null);
            this.getTemplateViewModel().lastEndDate(null);
            this.setCalenderDates(null, null);
        }else{

            this.getTemplateViewModel().fromRange(null);
            this.getTemplateViewModel().toRange(null);
            this.getTemplateViewModel().fromRangeDate("");
            this.getTemplateViewModel().toRangeDate("");
        }
    }

    public applyFilter(){

        this.setLastToCurrent();
        this.caller.filterDateOnChange(this.idElement);
        this.caller.validationTemplate.clearMessages();
    }

    protected getAllMonths(){

        let months: Array<{month:string, currentYear: number, currentMonth: number}> = [];
        let startDate = new Date();
        let currentYear = startDate.getFullYear();
        let currentMonth = startDate.getMonth()+2;

        //fill array with dates from the past 12 months
        for(let i=1; i<=14; i++){
            let monthString: string;
            monthString = currentMonth < 10 ? '0' + currentMonth + '/' + currentYear : currentMonth + '/' + currentYear;
            months.push({month: monthString, currentYear: currentYear, currentMonth: currentMonth});

            if (currentMonth == 1){
                currentMonth = 12;
                currentYear -= 1;
            }else{
                currentMonth -= 1;
            }
        }

        return months;
    }

    public setRange(startRange, endRange){
        if(startRange){
           this.getTemplateViewModel().fromRange(startRange);
        }
        if(endRange){
            this.getTemplateViewModel().toRange(endRange);
        }

        this.getTemplateViewModel().isActive(false);
        this.handleRangeFilter(false);
        this.setLastToCurrent();
    }

    public setDateRange(startDate: Date, endDate: Date) {

        //set fiter values
        this.setStartingDate(this.getDateString(startDate, this.getTemplateViewModel().hmsEnabled()));
        this.setEndDate(this.getDateString(endDate, this.getTemplateViewModel().hmsEnabled()));

        //set calender
        this.setCalenderDates(startDate, endDate);

        //show
        this.getTemplateViewModel().isActive(false);

        this.setLastToCurrent();

        return this;
    }

    public setStartPickerId(idStartPicker){
        this.getTemplateViewModel().idStartPicker(idStartPicker);
    }
    
    public toggleClearButton(value){
        this.getTemplateViewModel().showClearDatesButton(value);
    }

    public setEndPickerId(idEndPicker){
        this.getTemplateViewModel().idEndPicker(idEndPicker);
    }

    public setLabel( label: string ){
        this.getTemplateViewModel().label(label);
    }

    protected setStartingDate( startDate: string ){

        this.getTemplateViewModel().startDate(startDate);
    }

    protected setEndDate( endDate: string ){

        this.getTemplateViewModel().endDate(endDate);
    }

    protected setFromRange( fromRange: string ){

        this.getTemplateViewModel().fromRange(fromRange);
    }

    protected setToRange( toRange: string ){

        this.getTemplateViewModel().toRange(toRange);
    }

    private getDateString(date: Date, showTime) {

        if(!date){
            return null;
        }

        if(showTime){
            return this.getDateTimeString(date);
        }

        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        return year + '-' + this.padInteger(month,2) + '-' + this.padInteger(day,2);
    }

    private getDateTimeString(date: Date) {

        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        let miliseconds = date.getMilliseconds();

        return year + '-' + this.padInteger(month,2) + '-' + this.padInteger(day,2) + ' ' + this.padInteger(hours, 2) + ':' + this.padInteger(minutes, 2) + ':' + this.padInteger(seconds,2);
    }

    private padInteger(number: number, size: number) {
        let s = number+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    public reset() {

        this.getTemplateViewModel().startDate(null);
        this.getTemplateViewModel().endDate(null);
        this.getTemplateViewModel().fromRange(null);
        this.getTemplateViewModel().toRange(null);

        this.getTemplateViewModel().selectedMonth(null);

        this.getTemplateViewModel().fromRangeDate("");
        this.getTemplateViewModel().toRangeDate("");

        this.setLastToCurrent();

        this.setCalenderDates(null, null);

        this.getTemplateViewModel().isActive(true);
    }

    public onClickClear(){
        
        this.onClickCancel();
        this.reset();
        this.applyFilter();
    }

    public onClickCancel(){

        //restore to previous
        this.getTemplateViewModel().selectedMonth(this.getTemplateViewModel().lastSelectedMonth());
        this.getTemplateViewModel().selectedFilter(this.getTemplateViewModel().lastSelectedFilter());
        this.getTemplateViewModel().startDate(this.getTemplateViewModel().lastStartDate());
        this.getTemplateViewModel().endDate(this.getTemplateViewModel().lastEndDate());
        this.getTemplateViewModel().fromRange(this.getTemplateViewModel().lastFromRange());
        this.getTemplateViewModel().toRange(this.getTemplateViewModel().lastToRange());

        this.setCalenderDates(null, null);

        if(this.getTemplateViewModel().startDate() || this.getTemplateViewModel().endDate() || this.getTemplateViewModel().fromRange() || this.getTemplateViewModel().toRange()){

            this.setCalenderDates(this.getTemplateViewModel().startDate(), this.getTemplateViewModel().endDate());
            this.getTemplateViewModel().isActive(false);
        }
        this.getTemplateViewModel().isShown(false);
    }

    public onClickEdit(){

        this.getTemplateViewModel().isActive(true);
        this.getTemplateViewModel().isShown(true);
        this.onChangeInput(null);
    }
    
    private handleRangeFilter(applyFilter = true){

        let startDate = null;
        let endDate = null;
        let dates = this.handleRangeDates();

        if(dates.invalidFilter){
            this.showErrorMsg();
            return;
        }

        startDate = dates.start;
        endDate = dates.end;

        //make sure start date is smaller than end date
        if((startDate && !endDate) || (endDate && !startDate) || (startDate && endDate && startDate < endDate)){

            let startDateString = this.getDateString(startDate, this.getTemplateViewModel().hmsEnabled());
            let endDateString = this.getDateString(endDate, this.getTemplateViewModel().hmsEnabled());

            this.getTemplateViewModel().startDate(startDateString);
            this.getTemplateViewModel().endDate(endDateString);

            this.getTemplateViewModel().isActive(false);
            this.getTemplateViewModel().isShown(false);
            if(applyFilter){
                this.applyFilter();
            }
        }else if(!startDate && !endDate) {

            this.onClickClear();
        }else{

            this.showErrorMsg();
        }
    }
    
    private showErrorMsg(){

        //show validartion msg using vnModule_knockoutjs validation template
        let message = {field: "Date Range", label: "Date Range", message: "Invalid filter inputs. Please ensure the dates or ranges are valid, and the start date is on or before the end date"};
        this.caller.validationTemplate.setSingleMessage(message);
    }

    private formatDate(date: Date, operator = '+', value, filter, isStart){

        if(!date){
            return null;
        }

        if(isNaN(value)){
            return null;
        }

        switch (filter){
            case 'y':
                date.setFullYear( eval(date.getFullYear() + operator + value));
                date = this.defaultTimes(date, isStart);
                return date;
            case 'm': //month
                date.setMonth( eval(date.getMonth() + operator + value));
                date = this.defaultTimes(date, isStart);
                return date;
            case 'w':
                date.setDate( eval(date.getDate() + operator + value * 7));
                date = this.defaultTimes(date, isStart);
                return date;
            case 'd':
                date.setDate( eval(date.getDate() + operator + value));
                date = this.defaultTimes(date, isStart);
                return date;
            case 'h':
                date.setHours( eval(date.getHours() + operator + value));
                return date;
            case 'mm': //mintues
                date.setMinutes( eval(date.getMinutes() + operator + value));
                return date;
            case 's':
                date.setSeconds( eval(date.getSeconds() + operator + value));
                return date;
            default:
                break;
        }
    }

    protected defaultTimes(date, isStart){
        let returnDate = 0;
        if(isStart){
            returnDate = date.setHours(0,0,0);
        }else{
            returnDate = date.setHours(23,59,59);
        }
        return new Date(returnDate);
    }

    protected onChangeInput(event) {

        if(this.getTemplateViewModel().selectedFilter() == 'range'){

            let dates = this.handleRangeDates();

            if(dates.start){
                this.getTemplateViewModel().fromRangeDate(this.getDateTimeString(dates.start));
            }else{
                this.getTemplateViewModel().fromRangeDate("");
            }

            if(dates.end){
                this.getTemplateViewModel().toRangeDate(this.getDateTimeString(dates.end));
            }else{
                this.getTemplateViewModel().toRangeDate("");
            }
        }
    }

    protected handleRangeDates(){
        let fromString  = this.getTemplateViewModel().fromRange();
        let toString = this.getTemplateViewModel().toRange();
        let allowedFilters = [];
        let invalidFilter = false;
        if(this.getTemplateViewModel().hmsEnabled()){
            allowedFilters = ['y', 'm', 'w', 'd', 'h', 'mm', 's'];
        }else{
            allowedFilters = ['y', 'm', 'w', 'd'];
        }

        let today = new Date();
        let startDate = null;
        let endDate = null;

        let fromStringArray = [];
        let fromStringSign = '+';
        if(fromString && fromString.length > 1) {

            if (fromString.slice(0, 1) == '-' || fromString.slice(0, 1) == '+') {
                fromStringSign = fromString.slice(0, 1);
                fromString = fromString.slice(1);
            }
            fromStringArray = fromString.split(" ");
            startDate = new Date();
        }else if(fromString) {
            invalidFilter = true;
        }

        let toStringArray = [];
        let toStringSign = '+';
        if(toString && toString.length > 1) {

            if(toString.slice(0,1) == '-' || toString.slice(0,1) == '+'){
                toStringSign = toString.slice(0,1);
                toString = toString.slice(1);
            }
            toStringArray = toString.split(" ");
            endDate = new Date();
            endDate.setHours(23,59,59);
        }else if(toString) {
            invalidFilter = true;
        }

        //update From date obejct
        for(let i = 0; i < fromStringArray.length; i++){

            let fromStringFilter = '';
            let fromStringvalue = '';

            //mintues check
            if(fromStringArray[i].endsWith('mm')){
                fromStringFilter = fromStringArray[i].slice(-2);
                fromStringvalue = fromStringArray[i].slice(0, -2);
            }else {
                fromStringFilter = fromStringArray[i].slice(-1);
                fromStringvalue = fromStringArray[i].slice(0, -1);
            }

            if(allowedFilters.indexOf(fromStringFilter) !== -1){
                startDate = this.formatDate(startDate, fromStringSign, fromStringvalue, fromStringFilter, true);
            }else {
                invalidFilter = true;
                startDate = null;
            }
        }

        //update To date obejct
        for(let i = 0; i < toStringArray.length; i++){

            let toStringFilter = '';
            let toStringvalue = '';

            //mintues check
            if(toStringArray[i].endsWith('mm')){
                toStringFilter = toStringArray[i].slice(-2);
                toStringvalue = toStringArray[i].slice(0, -2);
            }else {
                toStringFilter = toStringArray[i].slice(-1);
                toStringvalue = toStringArray[i].slice(0, -1);
            }

            if(allowedFilters.indexOf(toStringFilter) !== -1){
                endDate = this.formatDate(endDate, toStringSign, toStringvalue, toStringFilter, false);
            }else {
                invalidFilter = true;
                endDate = null;
            }
        }
        return {"start": startDate,"end": endDate, "invalidFilter": invalidFilter};
    }

    protected setLastToCurrent(){

        this.getTemplateViewModel().lastSelectedFilter(this.getTemplateViewModel().selectedFilter());
        this.getTemplateViewModel().lastStartDate(this.getTemplateViewModel().startDate());
        this.getTemplateViewModel().lastEndDate(this.getTemplateViewModel().endDate());
        this.getTemplateViewModel().lastFromRange(this.getTemplateViewModel().fromRange());
        this.getTemplateViewModel().lastToRange(this.getTemplateViewModel().toRange());
    }
}
