import {XMLHttpRequestHandler} from "../XMLHttpRequestHandler/XMLHttpRequestHandler";
import {vnAppZone} from "./vn_app_zone";

export abstract class vnModule{

        // MEMBER VARIABLES

        protected _content: Array<HTMLElement>;
        protected _loggedUser: any;
        protected _loggedUserAccessType: string;
        protected _isLeavePageWarningNeeded: boolean = false;
        protected _name: string;

        protected vnAppZone : vnAppZone;

        constructor(vnAppZone: vnAppZone){
            this.name = this.getModuleName();
            this.vnAppZone = vnAppZone;
        }

        // PROPERTIES
        protected abstract getModuleName(): string;

        get name():string {
            return this._name;
        }
        set name(name: string) {
            this._name = name;
        }

        get content():Array<HTMLElement> {
            return this._content;
        }
        set content(content: Array<HTMLElement>) {
            this._content = content;
        }

        get isLeavePageWarningNeeded():boolean{
            return this._isLeavePageWarningNeeded;
        }

        set isLeavePageWarningNeeded(isLeavePageWarningNeeded){
            this._isLeavePageWarningNeeded = isLeavePageWarningNeeded;
        }

        get loggedUser(): any {
            return this._loggedUser;
        }
        set loggedUser(loggedUser: any) {
            this._loggedUser = loggedUser;
        }

        get loggedUserAccessType(): string {
            return this._loggedUserAccessType;
        }
        set loggedUserAccessType(loggedUserAccessType: string) {
            this._loggedUserAccessType = loggedUserAccessType;
        }

        public setVnAppZone(vnAppZone:vnAppZone){
            this.vnAppZone = vnAppZone;
        }
        public getVnAppZone(){
            return this.vnAppZone;
        }

        public cancelForm() :void{

        }

        public cleanup():  void{
            
        }
        
        /**
         * Execute the loading process of a page.
         * If the the user needs to be logged in, execute the loggin check, and,
         * in the case where the user is NOT logged in and reqired to be,
         * load the login page.
         *
         */
        public load(container: HTMLElement) :void{

            this.internalLoad(container);
        }

        /**
         * this function loads the page without any loggin check.
         * This function is to be used ONLY in this class. (private)
         *
         */
        protected internalLoad(container: HTMLElement){

            this.build();
            this.insertIn(container);
            this.fillPage();
        }

        public build() :Array<HTMLElement>{
            this.content = [];
            for(let c of this.buildContent()){
                this.content.push(<HTMLElement>c);
            }

            return this.content;
        }

        public insertIn(container: HTMLElement): void{

            container.innerHTML = "";

            for(let c of this.content){
                container.appendChild(c);
            }
        }

        public fillPage() :void{
            // default no action
        }

        public abstract buildContent() :Array<Element>;

        protected loadingIn(e: Element): void{
            e.classList.add("loading");
        }
        protected loadingOut(e: Element): void{
            e.classList.remove("loading");
        }
    public getUser() {
        return this.vnAppZone.getApp().loggedUser;
    }
    //
    // This function let you set a function to handle each of
    // the different http code returned by a request.
    public getRequestReturnFunction(httpCodeFunction: Array<{"code": number, "function": (responseParsed:any, obj:any)=>void}>, elseAction: (responseParsed:any, obj:any)=>void ) {
        return function (req, obj) {
            return function () {
                if (req.request.readyState == 4) {
                    let responseParsed = JSON.parse(req.request.response);
                    for (let codeReturn of httpCodeFunction) {
                        if (req.request.status == codeReturn.code) {
                            codeReturn.function(responseParsed, obj);
                            return;
                        }
                    }
                    elseAction(responseParsed, obj);
                }
            }
        }
    }
}
