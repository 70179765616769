import { vnAppZoneContent } from "./components/vn_app_zone_content";
import { vnAppZoneHeader } from "./components/vn_app_zone_header";
import { vnAppZoneMenu } from "./components/vn_app_zone_menu";
import { vnApp_ads_ninja } from "./components/vnApp_ads_ninja";

window.onload = function(){
    let vnAppZoneHeaderObj = new vnAppZoneHeader(document.getElementById('header'));
    let vnAppZoneMenuObj = new vnAppZoneMenu(document.getElementById('menu'));
    let vnAppZoneContentObj = new vnAppZoneContent(document.getElementById('content'));
    let vnAppObj = new vnApp_ads_ninja([ vnAppZoneHeaderObj, vnAppZoneMenuObj, vnAppZoneContentObj]);

    // set reference to the main app from zones
    vnAppZoneHeaderObj.app = vnAppObj;
    vnAppZoneMenuObj.app = vnAppObj;
    vnAppZoneContentObj.app = vnAppObj;
}
