import { pageFactory } from "./factory";
import {vnAppZone} from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone";
import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";

import {XMLHttpRequestHandler} from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import {reset_template} from "../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/reset_template";

declare var ko: any;
declare var page: any;

export class reset_password extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;

    protected getModuleName(){
        return "reset_password";
    }

    buildContent() : Array<Element>{


        let  resetTemplate = new reset_template("resetTemplate", this.viewModel, this);

        return [resetTemplate.build()];

    }

    fillPage() : void{

        this.validateToken();

    }

    protected validateToken(){

        let token: string;
        let action: string;

        if(token = this.getVnAppZone().app.params.token){
            let validateReq_url: string = '/api/access/validate/token/' + token + '/';
            let validateReq_params: Array<Array<string>> = [];

            // validateReq_params.push(["data", JSON.stringify({"token":token})] );
            this.XHRHdl = new XMLHttpRequestHandler(validateReq_url, validateReq_params, this);

            this.XHRHdl.onReadyStateFunction = this.showValidateTemplate;
            this.XHRHdl.execute();
        }
    }

    protected showValidateTemplate(req, obj){
        return function(){
            if (req.request.readyState == 4) {

                let thisModule = (<reset_password>obj);
                if(  req.request.status == 200  ){
                    obj.viewModel["resetTemplate"].validateToken("block");
                }
                else{
                    obj.viewModel["resetTemplate"].invalidateToken("block");
                }
            }
        }
    }

    public retrievePassword(){

        let _this = this;

        let loginReq_url: string = '/api/access/retrievepassword/';
        let loginReq_Params: Array<Array<string>> = [];
        loginReq_Params.push( ["data", ko.toJSON(this.viewModel["resetTemplate"])] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(loginReq_url,loginReq_Params, this);
        XHRHdl.onReadyStateFunction = (function(req, obj){

            return function() {

                if (req.request.readyState == 4) {

                    let responseParsed = JSON.parse(req.request.response);
                    //let actualpageCtrl = (<login>obj);

                    if(  req.request.status == 200  ){
                        //let factory = new pageFactory;
                        if(responseParsed.data){

                            // GET OBJECT OF THE RIGHT PAGE
                            //page.redirect('/home');
                            document.querySelector("#retrieve-password").setAttribute("style", "display: none;");
                            obj.viewModel["resetTemplate"].sentMessage("block");
                            obj.viewModel["resetTemplate"].retrieveInfo(responseParsed.data);
                        }
                    }
                    else{
                        document.querySelector("#retrieve-password").setAttribute("style", "display: none;");
                        obj.viewModel["resetTemplate"].sentMessage("block");
                        obj.viewModel["resetTemplate"].retrieveInfo(responseParsed.message);
                    }

                }
            }
        });
        XHRHdl.execute();
    }

    public redirectToLogin(){
        page.redirect('/login');
    }

    public resetPassword(){

        let _this = this;
        let token:string;

        let loginReq_url: string = '/api/access/resetpassword/';
        let loginReq_Params: Array<Array<string>> = [];

        if( token = this.getVnAppZone().app.params.token ) {
            this.viewModel["resetTemplate"].token(token);
        }
        loginReq_Params.push( ["data", ko.toJSON(this.viewModel["resetTemplate"])] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(loginReq_url,loginReq_Params, this);
        XHRHdl.onReadyStateFunction = (function(req, obj){

            return function() {

                if (req.request.readyState == 4) {

                    let responseParsed = JSON.parse(req.request.response);
                    //let actualpageCtrl = (<login>obj);

                    if(  req.request.status == 200  ){
                        //let factory = new pageFactory;

                            // GET OBJECT OF THE RIGHT PAGE
                            //page.redirect('/home');
                            document.querySelector("#reset-password-form").setAttribute("style", "display: none;");
                            obj.viewModel["resetTemplate"].sentMessage("block");
                            obj.viewModel["resetTemplate"].retrieveInfo(responseParsed.data);
                    }
                    else{
                        _this.resetFormFieldsNotifications();
                        _this.renderNotifications(responseParsed.message);
                    }
                }
            }
        });
        XHRHdl.execute();
    }

    protected renderNotifications(errorMessage) {

        let errorField: HTMLElement = document.querySelector("small");

        if (errorField !== null) {
            errorField.classList.add('is-error');
            errorField.innerHTML = errorMessage;
        }


    }

    protected  resetFormFieldNotifications(field) {
        if (field.classList.contains('is-error')) {
            field.classList.remove('is-error');
            field.innerHTML = '';
        }
    }


    protected resetFormFieldsNotifications() {
        let fields:any = document.querySelectorAll("small");

        for (let field of fields) {
            this.resetFormFieldNotifications(field);
        }
    }

}
