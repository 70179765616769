import { adHealthList_template } from '../components/templates/adHealthList_template';
import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import {header} from "./header";

declare var ko: any;

export class adHealth extends vnModule_knockoutJS{

    protected elementsList: adHealthList_template;
    protected XHRHdl: XMLHttpRequestHandler;

    protected getModuleName(): string{

        return 'adHealth';
    }

    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Ad Health");

        this.elementsList = new adHealthList_template('adHealth', this.viewModel, this)

        return [this.elementsList.build()];
    }

    public fillPage(): void{

        this.elementsList.buildDatePickerCalendar();
        this.getFormData();
    }

    protected getFormData(): void{

        this.loadingIn(this.elementsList.builtTemplate.children.namedItem("list-results"));
        this.elementsList.hideExportButton();
        this.XHRHdl = new XMLHttpRequestHandler('/api/adHealth/references/', [], this);
        this.XHRHdl.onReadyStateFunction = this.onReferencesRequestReturn;
        this.XHRHdl.execute();
    }

    protected onReferencesRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateFilters(responseParsed);
                obj.getListData();
            });
        }
    }

    public getListData(): void{

        if('getListData' in this.XHRHdl){

            this.XHRHdl['getListData'].cancelAndReset();
        }

        this.loadingIn(this.elementsList.builtTemplate.children.namedItem("list-results"));
        this.elementsList.hideExportButton();

        let subViewModel = this.elementsList.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(['data', ko.toJSON(subViewModel)] );

        this.XHRHdl['getListData'] = new XMLHttpRequestHandler('/api/adHealth/get/', usrReq_params, this);
        this.XHRHdl['getListData'].onReadyStateFunction = this.onDataRequestReturn;
        this.XHRHdl['getListData'].execute();
    }

    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateList(responseParsed);

                obj.loadingOut(obj.elementsList.builtTemplate.children.namedItem("list-results"));
            });
        }
    }

    protected updateList(responseParsed: any): void{

        this.elementsList.setResults(responseParsed.results);
        this.elementsList.setHeaders(responseParsed.groupsHeaders, responseParsed.metricsHeaders);
    }

    protected updateFilters(responseParsed: any): void{

        this.elementsList.setDimensionsItems(responseParsed.dimensions);
        this.elementsList.setBrandsFilterItems(responseParsed.brands);
        this.elementsList.setTemplatesFilterItems(responseParsed.templates);
        this.elementsList.setSourcesFilterItems(responseParsed.sources);
        this.elementsList.setDevicesFilterItems(responseParsed.devices);
        this.elementsList.setOperatingSystemsFilterItems(responseParsed.operatingSystems);
        this.elementsList.setGeosFilterItems(responseParsed.geos);
        this.elementsList.setMetricsFilterItems(responseParsed.metrics);
    }

    protected filterMultipleSelectorOnChange(){

        this.elementsList.setSelectedMetrics();
        this.elementsList.setSelectedBrands();
        this.elementsList.setSelectedTemplates();
        this.elementsList.setSelectedSources();
        this.elementsList.setSelectedDevices();
        this.elementsList.setSelectedOperatingSystems();
        this.elementsList.setSelectedGeos();
        this.elementsList.setSelectedMetrics();

        this.getListData();
    }

    public filterDropdownOnChange(): void{

        this.elementsList.setSelectedDimension();
        this.getListData();
    }

    protected filterDateOnChange(){

        this.getListData();
    }
}