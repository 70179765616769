import {vnTemplate} from "./vnTemplate";

declare var ko: any;

export class autoRefresh_template extends vnTemplate{

    protected autoRefreshTimerId: number;

    protected getTemplateHtml(): string{

        return `
                <div class="toggleWrapper">
                    <input type="checkbox" name="toggle1" class="mobileToggle" id="toggle1" checked data-bind="disable: autoRefreshDisabled, checked: toggleAutoRefresh, click: onClickToggleAutoRefresh">
                    <label for="toggle1"></label>
                </div>
            `;
    }

    protected getTemplateName(): string{
        return "autoRefresh-template";
    }

    protected getMainElementType(): string{
        return "div";
    }

    protected getMainElementClasses(): Array<string>{
        return ["autoRefresh"];
    }

    protected buildViewModel(): any{

        let huit_neuf_this = this;

        // define the viewmodel data/ functions
        return  {
            refreshFrequency: ko.observable(5000),
            toggleAutoRefresh: ko.observable(true),
            autoRefreshDisabled: ko.observable(false),
            callRefresh: function (data, event) { huit_neuf_this.callRefresh()},
            onClickToggleAutoRefresh: function (data, event) {huit_neuf_this.onClickToggleAutoRefresh();return true;},
            setRefreshFrequency: function(data, event) {huit_neuf_this.setRefreshFrequency(data)}
        };
    }

    public onClickToggleAutoRefresh(): void{

        this.callRefresh();

        if(this.caller) this.caller.filterAutoRefreshStatusOnChange()
    }

    protected callRefresh(){

        clearTimeout(this.autoRefreshTimerId);


        let _myThis = this;

        if(this.getTemplateViewModel().toggleAutoRefresh()){
            this.autoRefreshTimerId = setTimeout(function(){
                if (!document.getElementById(_myThis.idElement)){
                    clearTimeout(_myThis.autoRefreshTimerId)
                }else{
                    _myThis.autoRefresh();
                }
            }, this.getTemplateViewModel().refreshFrequency());
        }

    }

    protected autoRefresh(){

        this.caller.autoRefresh();
    }

    public setRefreshFrequency(frequency){

        this.getTemplateViewModel().refreshFrequency(frequency);
    }

    public setToggleAutoRefresh(toggleAutoRefresh){

        this.getTemplateViewModel().toggleAutoRefresh(toggleAutoRefresh);
        this.callRefresh();
    }

}

