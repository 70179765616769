import { vnTemplate } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate';
import { filterDropdown_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDropdown_template';
import { resultsInfo_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/resultsInfo_template';
import { filterSimple_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterSimple_template";
import { multipleSelector_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/multipleSelector_template";
import {filterSingleDate_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterSingleDate_template";
import { filterDate_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDate_template";
import {amCharts_v4_category_line_template} from "./amCharts_v4_category_line_template";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import {simpleListHeader_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/simpleListHeader_template";
import {first} from "@amcharts/amcharts4/.internal/core/utils/Array";
import { pagination_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/pagination_template';

declare var ko: any;


export class realTimeStats_template extends vnTemplate {

    protected searchFilter: filterSimple_template;
    protected deviceFilter: multipleSelector_template;
    protected geoFilter: multipleSelector_template;
    protected operatingSystemsFilter: multipleSelector_template;
    protected placementFilter: multipleSelector_template;
    protected brandFilter: multipleSelector_template;
    protected resultsInfo: resultsInfo_template;
    protected dateFilter: filterSingleDate_template;
    protected datesFilter: filterDate_template;
    protected comparisonDateFilter: filterSingleDate_template;
    protected chart: amCharts_v4_category_line_template;
    protected headers: simpleListHeader_template;
    protected headersArrayForFields = [];
    protected pagination: pagination_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="sub-header">
                <div class="w-justify">
                    <div class="w-left">
                        <div>
                            <label class="form-label form-label-box multi">Device</label>
                            ${this.deviceFilter.getHtmlBuild()}
                        </div>
                         <div>   
                            <label class="form-label form-label-box multi">Geo</label>
                            ${this.geoFilter.getHtmlBuild()}
                        </div> 
                        <div>
                            <label class="form-label form-label-box multi">Operating System</label>
                            ${this.operatingSystemsFilter.getHtmlBuild()}
                        </div> 
                        <div>
                            <label class="form-label form-label-box multi">Placements</label>
                            ${this.placementFilter.getHtmlBuild()}
                        </div> 
                        <!-- ko ifnot: idBrand  -->
                            <div>
                                <label class="form-label form-label-box multi">Brands</label>
                                ${this.brandFilter.getHtmlBuild()}
                            </div>  
                        <!-- /ko -->
                        <div>
                            <!--<label class="form-label form-label-box multi">Date</label>-->
                            ${this.datesFilter.getHtmlBuild()}
                        </div>                
                    </div>
                </div>
            </div>
            <div class="w-filters-selected">
                <div id="filters-selected" class="filters-selected">
                    <div id="selected-devices"></div>
                    <div id="selected-templates"></div>
                    <div id="selected-networkCategories"></div>
                    <div id="selected-contentTypes"></div>
                    <div id="selected-geos"></div>
                    <div id="selected-sources"></div>
                    <div id="selected-operatingSystems"></div>
                    <div id="selected-placements"></div>
                    <div id="selected-brands"></div>
                </div>
            </div>      
            

<!--                            <div class="ww-date-picker">
                                <label class="form-label icon i-circle" for="">Comparison Date</label>
                                ${this.comparisonDateFilter.getHtmlBuild()}
                            </div>-->

            <div class="list-header w-justify"> 
                <div class="w-center">
                    ${this.searchFilter.getHtmlBuild()}
                </div>   
                <div class="w-right">
                    ${this.pagination.getHtmlBuild()}
                </div>
            </div>
            
            <div class="list-header w-justify">
                <div class="w-center">
                    <h2 class="section-title icon i-google">Video Player Stats</h2>
                </div>
            </div>
            
            <section class="w-graph" id="chart-results" data-bind="css: { loading: isChartLoading }">   
                ${this.chart.getHtmlBuild()}
            </section>
            
            <div class="w-list-results" id="table-results" data-bind="visible: isTableVisible">
           
                <table class="list-results">
                    <!-- ${this.resultsInfo.getHtmlBuild()} -->
                    <thead> 
<!--                        ${this.headers.getHtmlBuild()}-->
                        <tr>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'permalink') }, css: { 'list-sort-desc': orderByAndDirection()=='permalink_DESC', 'list-sort-asc': orderByAndDirection()=='permalink_ASC' }">URL</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'idBrand') }, css: { 'list-sort-desc': orderByAndDirection()=='idBrand_DESC', 'list-sort-asc': orderByAndDirection()=='idBrand_ASC' }">Brand</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'videoUrl') }, css: { 'list-sort-desc': orderByAndDirection()=='videoUrl_DESC', 'list-sort-asc': orderByAndDirection()=='videoUrl_ASC' }">Video File</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'placementId') }, css: { 'list-sort-desc': orderByAndDirection()=='placementId_DESC', 'list-sort-asc': orderByAndDirection()=='placementId_ASC' }">Placement</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'isAutoplay') }, css: { 'list-sort-desc': orderByAndDirection()=='isAutoplay_DESC', 'list-sort-asc': orderByAndDirection()=='isAutoplay_ASC' }">Autoplay</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'loadCount') }, css: { 'list-sort-desc': orderByAndDirection()=='loadCount_DESC', 'list-sort-asc': orderByAndDirection()=='loadCount_ASC' }">Load</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'playCount') }, css: { 'list-sort-desc': orderByAndDirection()=='playCount_DESC', 'list-sort-asc': orderByAndDirection()=='playCount_ASC' }">Play</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'ctr') }, css: { 'list-sort-desc': orderByAndDirection()=='ctr_DESC', 'list-sort-asc': orderByAndDirection()=='ctr_ASC' }">CTR</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'videoStartCount') }, css: { 'list-sort-desc': orderByAndDirection()=='videoStartCount_DESC', 'list-sort-asc': orderByAndDirection()=='videoStartCount_ASC' }">VideoStart</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'video5secCount') }, css: { 'list-sort-desc': orderByAndDirection()=='video5secCount_DESC', 'list-sort-asc': orderByAndDirection()=='video5secCount_ASC' }">Video5sec</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'video15secCount') }, css: { 'list-sort-desc': orderByAndDirection()=='video15secCount_DESC', 'list-sort-asc': orderByAndDirection()=='video15secCount_ASC' }">Video15sec</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'video30secCount') }, css: { 'list-sort-desc': orderByAndDirection()=='video30secCount_DESC', 'list-sort-asc': orderByAndDirection()=='video30secCount_ASC' }">Video30sec</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'video60secCount') }, css: { 'list-sort-desc': orderByAndDirection()=='video60secCount_DESC', 'list-sort-asc': orderByAndDirection()=='video60secCount_ASC' }">Video60sec</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'engaged5secCount') }, css: { 'list-sort-desc': orderByAndDirection()=='engaged5secCount_DESC', 'list-sort-asc': orderByAndDirection()=='engaged5secCount_ASC' }">engaged5sec</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'lifetimeVideoStarts') }, css: { 'list-sort-desc': orderByAndDirection()=='lifetimeVideoStarts_DESC', 'list-sort-asc': orderByAndDirection()=='lifetimeVideoStarts_ASC' }">Lifetime VideoStarts</span></th>
                        </tr>
                    </thead>
                    <tbody data-bind="foreach: tableData" style="text-align: right">  
                        <tr>
                            
                            <td>
                                <a target="_blank" data-bind="attr: { href: pageLink }">
                                    <span class="icon i-link" data-bind="text: permalink"></span>
                                   
                                </a>
                            </td>
                            <td><span data-bind="text: brandName, css: brandIcon"></span></td>
                            <td><span data-bind="text: videoUrl"></span></td>
                            <td><span data-bind="text: placementId"></span></td>
                            <td><span data-bind="text: isAutoplay == 0 ? 'No' : 'Yes'"></span></td>
                            <td><span data-bind="text: Number(loadCount).toLocaleString('en-EG')"></span></td>
                            <td><span data-bind="text: Number(playCount).toLocaleString('en-EG')"></span></td>
                            <td><span data-bind="text: Number(ctr).toLocaleString('en-EG',{style: 'percent', maximumSignificantDigits: 2})"></span></td>
                            <td><span data-bind="text: Number(videoStartCount).toLocaleString('en-EG')"></span></td>
                            <td><span data-bind="text: Number(video5secCount).toLocaleString('en-EG')"></span></td>
                            <td><span data-bind="text: Number(video15secCount).toLocaleString('en-EG')"></span></td>
                            <td><span data-bind="text: Number(video30secCount).toLocaleString('en-EG')"></span></td>
                            <td><span data-bind="text: Number(video60secCount).toLocaleString('en-EG')"></span></td>
                            <td><span data-bind="text: Number(engaged5secCount).toLocaleString('en-EG')"></span></td>
                            <td><span data-bind="text: Number(lifetimeVideoStarts).toLocaleString('en-EG')"></span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{

        this.searchFilter = new filterSimple_template('searchFilter', this.getTemplateViewModel(), this.caller);

        this.deviceFilter = new multipleSelector_template('deviceFilter', this.getTemplateViewModel(), this.caller);
        this.deviceFilter.setFilterContainerClass('margin-neg');
        this.deviceFilter.setTagContainerId('selected-devices');
        this.deviceFilter.setTagContainerCaption('Selected Devices:');

        this.geoFilter = new multipleSelector_template('geoFilter', this.getTemplateViewModel(), this.caller);
        this.geoFilter.setFilterContainerClass('margin-neg');
        this.geoFilter.setTagContainerId('selected-geos');
        this.geoFilter.setTagContainerCaption('Selected Geos:');

        this.operatingSystemsFilter = new multipleSelector_template('operatingSystemsFilter', this.getTemplateViewModel(), this.caller);
        this.operatingSystemsFilter.setFilterContainerClass('margin-neg');
        this.operatingSystemsFilter.setTagContainerId('selected-operatingSystems');
        this.operatingSystemsFilter.setTagContainerCaption('Selected Operating Systems:');

        this.placementFilter = new multipleSelector_template('placementFilter', this.getTemplateViewModel(), this.caller);
        this.placementFilter.setFilterContainerClass('margin-neg');
        this.placementFilter.setTagContainerId('selected-placements');
        this.placementFilter.setTagContainerCaption('Selected Placements:');

        this.brandFilter = new multipleSelector_template('brandFilter', this.getTemplateViewModel(), this.caller);
        this.brandFilter.setFilterContainerClass('margin-neg');
        this.brandFilter.setTagContainerId('selected-brands');
        this.brandFilter.setTagContainerCaption('Selected Brands:');

        this.resultsInfo = new resultsInfo_template('resultsInfo', this.getTemplateViewModel(), this.caller);

        let today = new Date();
        this.dateFilter = new filterSingleDate_template('dateFilter', this.getTemplateViewModel(), this.caller);
        this.dateFilter.setDate(today.toISOString().split('T')[0]);

        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        this.comparisonDateFilter = new filterSingleDate_template('comparisonDateFilter', this.getTemplateViewModel(), this.caller);
        this.comparisonDateFilter.setDate(yesterday.toISOString().split('T')[0]);


        // Today's date
        let dateEnd = new Date();
        dateEnd.setDate(dateEnd.getDate());

        let dateStart = new Date();
        dateStart.setDate(dateStart.getDate());
        // Start of current month
        // dateStart.setDate(1);

        this.datesFilter = new filterDate_template("datesFilter", this.getTemplateViewModel(), this.caller);
        this.datesFilter.setStartPickerId('startDatePicker');
        this.datesFilter.setEndPickerId('endDatePicker');
        this.datesFilter.setDateRange(dateStart, dateEnd);

        this.chart = new amCharts_v4_category_line_template('chart', this.getTemplateViewModel(), this.caller);

        this.headers = new simpleListHeader_template("listHeader", this.getTemplateViewModel(), this.caller, []);

        this.pagination = new pagination_template('pagination', this.getTemplateViewModel(), this.caller);
        this.pagination.getTemplateViewModel().itemPerPage = 25;
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return 'realTimeStats_template';
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return [];
    }

    /**
     * @inheritDoc
     */
    public activateLoading(): void{

        this.getTemplateViewModel().isLoading(1);
    }

    /**
     * @inheritDoc
     */
    public deactivateLoading(): void{

        this.getTemplateViewModel().isLoading(0);
    }

    /**
     * @inheritDoc
     */
    public buildViewModel(): Object{

        let self = this;

        return {
            orderBy: ko.observable(''),
            orderByDirection: ko.observable(''),
            orderByAndDirection: ko.observable(''),
            data: ko.observableArray([]),
            tableData: ko.observableArray([]),
            chartData: ko.observableArray([]),
            isChartLoading: ko.observable(0),
            headers: ko.observableArray([]),
            isTableVisible: ko.observable(true),
            idBrand: ko.observable(0),
            eventTypesToExcludeFromChart: ko.observable([]),
            eventTypesToHideOnInitForChart: ko.observable([]),
            eventTypes: ko.observableArray([]),
            onClickReorder: function(value, data, event){ self.onClickReorder(value, data, event); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            pagination: this.pagination.getTemplateViewModel().pageNumber(),
            orderBy: this.getTemplateViewModel().orderBy(),
            orderByDirection: this.getTemplateViewModel().orderByDirection(),
            filters: {
                searchString: this.searchFilter.getTemplateViewModel().filterKeyword(),
                selectedDevices: this.deviceFilter.getTemplateViewModel().selectedItems(),
                selectedGeos: this.geoFilter.getTemplateViewModel().selectedItems(),
                selectedOperatingSystems: this.operatingSystemsFilter.getTemplateViewModel().selectedItems(),
                selectedPlacements: this.placementFilter.getTemplateViewModel().selectedItems(),
                selectedBrands: this.brandFilter.getTemplateViewModel().selectedItems(),
                date: this.dateFilter.getTemplateViewModel().singleDatePicker,
                comparisonDate: this.comparisonDateFilter.getTemplateViewModel().singleDatePicker,
                startDate: this.getTemplateViewModel().datesFilter.startDate,
                endDate: this.getTemplateViewModel().datesFilter.endDate,
            },
        };
    }

    public setDeviceFilterItems(devices): void{

        this.setMultiSelectFilter(this.deviceFilter, devices);
    }

    public setGeoFilterItems(geos): void{

        this.setMultiSelectFilter(this.geoFilter, geos);
    }

    public setOperatingSystemsFilterItems(operatingSystems): void{

        this.setMultiSelectFilter(this.operatingSystemsFilter, operatingSystems);
    }

    public setPlacementFilterItems(operatingSystems): void{

        this.setMultiSelectFilter(this.placementFilter, operatingSystems);
    }

    public setBrandFilterItems(operatingSystems): void{

        this.setMultiSelectFilter(this.brandFilter, operatingSystems);
    }

    public setResultInfo(nbResults, nbPages, execTime){

        this.resultsInfo.setExecutionTime(execTime);
        this.resultsInfo.setNbResults(nbResults);
        this.resultsInfo.setNbPages(nbPages);
    }

    //
    // Sets data for a multi select filter.
    public setMultiSelectFilter(filter, data: Array<{ id: string, name: string }>): void{

        filter.setItems(data);
    }

    /**
     * Build the date Picker calendar.
     */
    public buildDatePickerCalendar(){

        this.dateFilter.buildCalendarWithoutTime();
        this.comparisonDateFilter.buildCalendarWithoutTime();
        this.datesFilter.buildCalendar();
    }

    //
    // Update the chart.
    public updateChart(){

        let chartData = this.getTemplateViewModel().chartData();
        let yaxis     = this.getChartYAxis();

        this.chart.setCategory('dateHour');
        this.chart.setAutoRotateLabels(1);
        this.chart.setLabelMaxWidth(200);
        this.chart.setEnableXScrollbar(0);
        this.chart.setYaxis(yaxis);
        this.chart.drawChart(chartData);
    }

    //
    // Returns y-axis.
    public getChartYAxis(){

        let colours = [
            '#ea80fc',
            '#02dac6',
            '#FCF370',
            '#cf6679',
            '#fc70d3',
            '#8c70fc',
            '#7081fc',
            '#70bcfc',
            '#72fc70',
            '#fca570',
        ];

       let yaxis               = [];

        for(const x of this.getTemplateViewModel().eventTypes()){

            if(this.getTemplateViewModel().eventTypesToExcludeFromChart().includes(x)){

                continue;
            }

            var line = {
                field: x,
                tooltipfield: x,
                name: x,
                axis: 'main',
                color: am4core.color(colours.shift() || '#ffffff'),
                strokeWidth: 3,
                opposite: false,
                maxPrecision: 0,
            };

            if(this.getTemplateViewModel().eventTypesToHideOnInitForChart().includes(x)){

                line['hidden']     = true;
                line['showOnInit'] = false;
            }

            yaxis.push(line);
        }

        return yaxis;
    }

    //
    // Show loading effect.
    public activateChartLoading(){

        this.getTemplateViewModel().isChartLoading(1);
    }

    //
    // Hide loading effect.
    public deactivateChartLoading(){

        this.getTemplateViewModel().isChartLoading(0);
    }


    public setPagination(totalCount: number): void{

        this.pagination.setNumberItemToPaginate(totalCount);
    }
    public resetPageNumber(): void{

        this.getTemplateViewModel().pagination.pageNumber(1);
    }

    public updatePagination(totalCount){
        this.pagination.setNumberItemToPaginate(totalCount);
    }

    public resetOrderParameters(): void{

        this.getTemplateViewModel().orderBy('');
        this.getTemplateViewModel().orderByDirection('');
        this.getTemplateViewModel().orderByAndDirection('');
    }

    public onClickReorder(value, data, event){

        this.getTemplateViewModel().orderBy(value);

        if( this.getTemplateViewModel().orderByDirection() == 'DESC' ){

            this.getTemplateViewModel().orderByDirection('ASC');
        }
        else{

            this.getTemplateViewModel().orderByDirection('DESC');
        }

        this.getTemplateViewModel().orderByAndDirection(value + '_' + this.getTemplateViewModel().orderByDirection());

        this.caller.reorderTable();
    }

}