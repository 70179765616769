import { vnModule_knockoutJS } from "./vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "./vn_app_zone_notification";
import { validationsList_template } from "../vnKnockoutJsTemplates/validationsList_template";

import { tasksEdit_template } from "../vnKnockoutJsTemplates/tasksEdit_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class tasks_edit extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected editForm: tasksEdit_template;
    protected validationsList: validationsList_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return "tasks_edit";
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        if(zoneHeader){

            let loadedModule = zoneHeader.loadedModule;
            (<header>loadedModule).setHeaderTitle("Add Task");
        }

        // create validation errors list template
        this.validationsList = new validationsList_template("validationList", this.viewModel, this);

        // create the tasks form
        this.editForm = new tasksEdit_template("editForm", this.viewModel, this);

        return [this.validationsList.build(), this.editForm.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{
        this.editForm.buildOpertationDatePickerCalendar();
        this.editForm.buildScheduledDatePickerCalendar();

        this.getFormData();
    }

    /**
     * Hook.
     *
     * Saves the edits to a task.
     */
    protected saveTask(): void{

        this.save();
    }

    /**
     * Hook.
     *
     * Cancels the edits to a task.
     */
    protected cancelEditTask(){

        // Redirect to tasks list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "Task action canceled !");

        page.redirect('/tasks');
    }

    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getFormData(): void{

        this.loadingIn(this.editForm.builtTemplate.children.namedItem("view-results"));

        let usrReq_url: string = '/api/tasks/list_from_data/';
        let usrReq_params: Array<Array<string>> = [];
        this.XHRHdl = new XMLHttpRequestHandler(usrReq_url,usrReq_params, this);

        this.XHRHdl.onReadyStateFunction = this.onGetFormDataReturn;
        this.XHRHdl.execute();
    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onGetFormDataReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.editForm.setOperationPickerItems(responseParsed.createOperations);
                obj.editForm.setExecutorPickerItems(responseParsed.executors);


                obj.loadingOut(obj.editForm.builtTemplate.children.namedItem("view-results"));
            });
        }
    }

    /**
     * Hook.
     */
    protected filterDropdownOnChange(){

        return 1;
    }

    /**
     * Saves the user data.
     */
    protected save(): void{

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.editForm.getViewModelForRequest();

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler('/api/tasks/save', formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveReturn;
        XHRHdl.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){



                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Task created successfully!');

                obj.validationsList.clearMessages();
                page.redirect('/tasks');
            });
        }
    }
}
