import { vnTemplate } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate';
import {infoButton_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/infoButton_template";


declare var ko: any;

export class prebid_sspSetupAdd_template extends vnTemplate {

    protected creativeTypeHelpInfoButton: infoButton_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <h3 class="form-title icon i-config">SSP Line Item Upload</h3>
                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
            <div class="w-form-body">
                <div class="form-body full-width">
                    <div class="form-group row">
                        <label for="advertiserName" class="form-label">Advertiser Name</label>
                        <input id="advertiserName" data-bind="value: advertiserName" type="text" class="input-text">
                    </div>
                    <div class="form-group row">
                        <label for="advertiserCode" class="form-label">CODE
                            <span class="w-tooltips-adv short" data-bind="template: { name: 'infoButton-template', data: $data.creativeTypeHelpInfoButton }"></span>
                        </label>
                        <input id="advertiserCode" data-bind="value: advertiserCode" type="text" class="input-text">
                    </div>        
                     <div class="form-group row ">
                        <label class="form-label">Creative Type</label>
                        <div>
                            <div class="form-check-inline" style="width: auto;margin-right: 2rem;"> 
                                <input type="radio" name="creativeTypes" id="banner" value="banner" data-bind="checked: creativeType">
                                <label class="form-label" style="width: auto" for="banner">Banner</label>
                            </div>
                            <div class="form-check-inline" style="width: auto;margin-right: 2rem;"> 
                                <input type="radio" name="creativeTypes" id="video" value="video" data-bind="checked: creativeType">
                                <label class="form-label" style="width: auto" for="video">Video</label>
                            </div>
                        </div>
                    </div>       
                    <div class="form-group row ">
                        <label class="form-label">Bidding Style</label>
                        <div>
                            <div class="form-check-inline" style="width: auto;margin-right: 2rem;"> 
                                <input type="radio" name="biddingStyle" id="client" value="_PB" data-bind="checked: biddingStyle">
                                <label class="form-label" style="width: auto" for="client">Client</label>
                            </div>
                            <div class="form-check-inline" style="width: auto;margin-right: 2rem;"> 
                                <input type="radio" name="biddingStyle" id="server" value="_PBS" data-bind="checked: biddingStyle">
                                <label class="form-label" style="width: auto" for="server">Server</label>
                            </div>
                        </div>
                    </div>               
               </div> 
            </div>
            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{
        let creativeTypeHelpInfoButtonContent = `
            <p>Max length of 10 characters</p>
        `;

        this.creativeTypeHelpInfoButton = new infoButton_template(`creativeTypeHelpInfoButton`, this.getTemplateViewModel(), this.caller);
        this.creativeTypeHelpInfoButton.setTitle('Help: CODE LENGTH');
        this.creativeTypeHelpInfoButton.setContent(creativeTypeHelpInfoButtonContent);

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return 'prebid_sspSetupAdd_template';
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    public buildViewModel(): Object{

        let self = this;

        return {
            advertiserName: ko.observable(''),
            advertiserCode: ko.observable(''),
            creativeType: ko.observable('banner'),
            biddingStyle: ko.observable('_PB'),
            onClickSave: function(){ self.onClickSave(); },
            onClickCancel: function(){ self.onClickCancel(); }
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            advertiserName: this.getTemplateViewModel().advertiserName(),
            advertiserCode: this.getTemplateViewModel().advertiserCode(),
            creativeType: this.getTemplateViewModel().creativeType(),
            biddingStyle: this.getTemplateViewModel().biddingStyle(),
        };
    }


    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        this.caller.confirmationModal.setTitle('Confirm SSP Line Item Upload');
        this.caller.confirmationModal.setContext('confirm');
        this.caller.confirmationModal.setMessageMain('Double check and make sure Advertiser Name and CODE are correct. This operation wil take around 30 minutes. Are you sure you want to proceed?');
        this.caller.confirmationModal.setDataConfirm(null);
        this.caller.confirmationModal.setMessageSecondary(`Advertiser Name: ${this.getTemplateViewModel().advertiserName()}    CODE: ${this.getTemplateViewModel().advertiserCode()}    Creative Type:${this.getTemplateViewModel().creativeType()}    Bidding Style:${this.getTemplateViewModel().biddingStyle()=='_PB'?'Client':'Server'}`);
        this.caller.confirmationModal.show();
    }


    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancelEditPrebidSspSetupAdd();
    }

}