import { vnTemplate } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate';
import { pagination_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/pagination_template';
import { resultsInfo_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/resultsInfo_template';
import { filterSimple_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterSimple_template";
import { multipleSelector_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/multipleSelector_template";
import { filterDate_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDate_template";
import { exportDataCsv } from "../../../vendor/valnet/valnet_jslib/vnHelpers/exportDataCsv";
import {infoButton_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/infoButton_template";
import {filterDropdown_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDropdown_template";

declare var ko: any;

export class articleLevelSV_template extends vnTemplate {

    protected searchFilter: filterSimple_template;
    protected deviceFilter: multipleSelector_template;
    protected brandsFilter: multipleSelector_template;
    protected brandFilter: filterDropdown_template;
    protected pagination: pagination_template;
    protected resultsInfo: resultsInfo_template;
    protected datesFilter: filterDate_template;
    protected exportDataCsv: exportDataCsv;
    protected reportLimit: filterDropdown_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="sub-header margin-top">
                <div class="w-justify">
                
                    <div class="w-left">
                        <div>
                            <label class="form-label">Brand</label>
                            ${this.brandFilter.getHtmlBuild()}
                        </div>
                        <div>
                            <label class="form-label form-label-box multi">Device</label>
                            ${this.deviceFilter.getHtmlBuild()}
                        </div>
                        <div>
                            <label class="form-label">Results</label>
                            ${this.reportLimit.getHtmlBuild()}
                        </div>
                        <div>
                            <label class="form-label form-label-box multi"></label>
                            ${this.datesFilter.getHtmlBuild()}
                        </div>
                    </div>
                </div>
            </div>
            <div class="list-header">
                <div class="w-justify">
                <div class="w-left"></div>
                    <div class="w-center">
                        ${this.searchFilter.getHtmlBuild()}

                    </div> 
                    <div class="w-right">
                        <!-- ${this.pagination.getHtmlBuild()} -->
                        <button class="btn icon highlight i-export" type="button" data-bind="event: {click: onExportData}">Export</button>          
                    </div>
                </div>
            </div>
            <div class="w-filters-selected">
                <div id="filters-selected" class="filters-selected">
                    <div id="selected-devices"></div>
                </div>
            </div>    
            <div class="w-list-results" id="list-results">
                <table class="list-results">
                    ${this.resultsInfo.getHtmlBuild()}
                    <thead> 
                        <tr>    
<!--                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'brandName') }, css: { 'list-sort-desc': orderByAndDirection()=='brandName_DESC', 'list-sort-asc': orderByAndDirection()=='brandName_ASC' }">Brand</span></th> -->
                            <th class="w-btn"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'pageUrl') }, css: { 'list-sort-desc': orderByAndDirection()=='pageUrl_DESC', 'list-sort-asc': orderByAndDirection()=='fullPageUrl_ASC' }">Article</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'sessionValue') }, css: { 'list-sort-desc': orderByAndDirection()=='sessionValue_DESC', 'list-sort-asc': orderByAndDirection()=='sessionValue_ASC' }">Page RPM</span></th> 
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'totalRevenue') }, css: { 'list-sort-desc': orderByAndDirection()=='totalRevenue_DESC', 'list-sort-asc': orderByAndDirection()=='totalRevenue_ASC' }">Revenue</span></th> 
                            
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'totalPageViewCount') }, css: { 'list-sort-desc': orderByAndDirection()=='totalPageViewCount_DESC', 'list-sort-asc': orderByAndDirection()=='totalPageViewCount_ASC' }">Views</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'recirculation') }, css: { 'list-sort-desc': orderByAndDirection()=='recirculation_DESC', 'list-sort-asc': orderByAndDirection()=='recirculation_ASC' }">Recirculation</span></th>
                            
                            
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'impressionsPageView') }, css: { 'list-sort-desc': orderByAndDirection()=='impressionsPageView_DESC', 'list-sort-asc': orderByAndDirection()=='impressionsPageView_ASC' }">Imp/View</span></th>  
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'requestsPageView') }, css: { 'list-sort-desc': orderByAndDirection()=='requestsPageView_DESC', 'list-sort-asc': orderByAndDirection()=='requestsPageView_ASC' }">Req/View</span></th> 
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'cpm') }, css: { 'list-sort-desc': orderByAndDirection()=='cpm_DESC', 'list-sort-asc': orderByAndDirection()=='cpm_ASC' }">CPM</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'totalUsPageViewRatio') }, css: { 'list-sort-desc': orderByAndDirection()=='totalUsPageViewRatio_DESC', 'list-sort-asc': orderByAndDirection()=='totalUsPageViewRatio_ASC' }">% US</span></th>
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'totalNonUsPageViewRatio') }, css: { 'list-sort-desc': orderByAndDirection()=='totalNonUsPageViewRatio_DESC', 'list-sort-asc': orderByAndDirection()=='totalNonUsPageViewRatio_ASC' }">% Non-US Prime</span></th> 
                            <th class="align-right"><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'totalChromePageViewRatio') }, css: { 'list-sort-desc': orderByAndDirection()=='totalChromePageViewRatio_DESC', 'list-sort-asc': orderByAndDirection()=='totalChromePageViewRatio_ASC' }">% Chrome</span></th>  
                       </tr> 
                    </thead>
                    <tbody data-bind="foreach: articles">  
                        <tr >
<!--                            <td><span data-bind="text:brandName, css: iconImage"></span></td>-->
                            <td>
                                <a target="_blank" data-bind="attr: { href: 'https://' + domain + pageUrl }" style="float:left">
                                    <span class="icon i-link" data-bind="text: displayPageUrl"></span>
                                   
                                </a>

                            </td>
                            <td><span class="align-right" data-bind="text:Number(sessionValue).toLocaleString('en-EG', {style: 'currency', currency: 'USD'})"></span></td>
                            <td><span class="align-right" data-bind="text:Number(totalRevenue).toLocaleString('en-EG', {style: 'currency', currency: 'USD'})"></span></td>
                            <td><span class="align-right" data-bind="text:Number(totalPageViewCount).toLocaleString('en-EG', {minimumFractionDigits: 0, maximumFractionDigits: 0})"></span></td>
                            <td><span class="align-right" data-bind="text:Number(recirculation).toLocaleString('en-EG', {minimumFractionDigits: 0, maximumFractionDigits: 0})"></span></td>
                            <td><span class="align-right" data-bind="text:Number(impressionsPageView).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})"></span></td>
                            <td><span class="align-right" data-bind="text:Number(requestsPageView).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})"></span></td>
                            <td><span class="align-right" data-bind="text:Number(cpm).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})"></span></td>
                            <td><span class="align-right" data-bind="text:Number(totalUsPageViewRatio).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})  + '%'"></span></td>
                            <td><span class="align-right" data-bind="text:Number(totalNonUsPageViewRatio).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})  + '%'"></span></td>
                            <td><span class="align-right" data-bind="text:Number(totalChromePageViewRatio).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})  + '%'"></span></td>
                        </tr>
                            
                    </tbody>
                </table>
            <style>
                .w-tooltips-adv.short {
                    margin-left: 0.5rem;
                    display:inline-block;
                }
                button.open-button{
                    color: #FCF370;
                    font-size:3rem;
                }
            </style>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{

        this.searchFilter = new filterSimple_template('searchFilter', this.getTemplateViewModel(), this.caller);

        this.deviceFilter = new multipleSelector_template('deviceFilter', this.getTemplateViewModel(), this.caller);
        this.deviceFilter.setFilterContainerClass('margin-neg');
        this.deviceFilter.setTagContainerId('selected-devices');
        this.deviceFilter.setTagContainerCaption('Selected Devices:');

        this.brandsFilter = new multipleSelector_template('brandsFilter', this.getTemplateViewModel(), this.caller);
        this.brandsFilter.setLabel("brands");
        this.brandsFilter.setFilterContainerClass('margin-neg');
        this.brandsFilter.setTagContainerId('selected-brands');
        this.brandsFilter.setTagContainerCaption('Selected Brand:');

        this.brandFilter = new filterDropdown_template('brandFilter', this.getTemplateViewModel(), this.caller);

        this.reportLimit = new filterDropdown_template('reportLimit', this.getTemplateViewModel(), this.caller);

        this.resultsInfo  = new resultsInfo_template('resultsInfo', this.getTemplateViewModel(), this.caller);

        this.pagination = new pagination_template('pagination', this.getTemplateViewModel(), this.caller);
        this.pagination.getTemplateViewModel().itemPerPage = 25;

        let dateEnd = new Date();
        dateEnd.setDate(dateEnd.getDate()-1);

        let dateStart = new Date();
        dateStart.setDate(dateStart.getDate()-1);

        this.datesFilter = new filterDate_template("datesFilter", this.getTemplateViewModel(), this.caller);
        this.datesFilter.setStartPickerId('startDatePicker');
        this.datesFilter.setEndPickerId('endDatePicker');
        this.datesFilter.setDateRange(dateStart, dateEnd);

        this.exportDataCsv = new exportDataCsv();
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return 'articleLevelSV_template';
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return [];
    }

    public hideExportButton(){

        this.getTemplateViewModel().exportButtonHidden(1);
    }

    public showExportButton(){

        this.getTemplateViewModel().exportButtonHidden(0);
    }

    /**
     * @inheritDoc
     */
    public activateLoading(): void{

        this.getTemplateViewModel().isLoading(1);
    }

    /**
     * @inheritDoc
     */
    public deactivateLoading(): void{

        this.getTemplateViewModel().isLoading(0);
    }

    /**
     * @inheritDoc
     */
    public buildViewModel(): Object{

        let self = this;

        return {
            orderBy: ko.observable(''),
            orderByDirection: ko.observable(''),
            orderByAndDirection: ko.observable(''),
            articles: ko.observableArray([]),
            brandsSelected: ko.observableArray([]),
            exportButtonHidden: ko.observable(1),
            exportData: ko.observableArray([]),
            onClickReorder: function(value, data, event){ self.onClickReorder(value, data, event); },
            onExportData : function(){ self.onExportData(); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            pagination: this.pagination.getTemplateViewModel().pageNumber(),
            orderBy: this.getTemplateViewModel().orderBy(),
            orderByDirection: this.getTemplateViewModel().orderByDirection(),
            filters: {
                searchString: this.searchFilter.getTemplateViewModel().filterKeyword(),
                selectedDevices: this.deviceFilter.getTemplateViewModel().selectedItems(),
                // brands: this.getTemplateViewModel().brandsFilter.selectedItems,
                selectedBrand: this.brandFilter.getTemplateViewModel().selectedOption(),
                reportLimit: this.reportLimit.getTemplateViewModel().selectedOption(),
                startDate: this.getTemplateViewModel().datesFilter.startDate,
                endDate: this.getTemplateViewModel().datesFilter.endDate,
            },
        };
    }

    public setBrandFilterItems(brands): void{

        this.brandFilter.setOptions(brands);
    }

    public setReportLimitFilterItems(reportLimit): void{
        this.reportLimit.setOptions(reportLimit);
    }

    public buildDatePickerCalendar(){

        this.datesFilter.buildCalendar();
    }

    public setDeviceFilterItems(devices): void{

        let devicesList = [];

        for(let device of devices){
            devicesList.push(device);
        }

        devicesList.unshift({'id' : 0, 'name' : 'all'});
        this.setMultiSelectFilter(this.deviceFilter, devices);
    }

    public setSelectedBrands(){

        this.getTemplateViewModel().brandsSelected(this.brandsFilter.getSelectedItems());
    }

    public setBrandsFilterItems(items){

        this.brandsFilter.setItems(items);
    }


    public setPagination(totalCount: number): void{

        this.pagination.setNumberItemToPaginate(totalCount);
    }


    public resetPageNumber(): void{

        this.getTemplateViewModel().pagination.pageNumber(1);
    }

    public resetOrderParameters(): void{

        this.getTemplateViewModel().orderBy('');
        this.getTemplateViewModel().orderByDirection('');
        this.getTemplateViewModel().orderByAndDirection('');
    }

    /**
     * Sets articles
     *
     * @param {array} articles
     * */
    public setArticles(articles: Array<any>): void{

        let i = 0;
        articles.forEach((article) => {

            article['displayPageUrl'] = article.pageUrl.replace(/^.*\/\/[^/]+/, '');

        })

        console.log(articles);
        this.getTemplateViewModel().articles(articles);
        this.showExportButton();
    }


    // public setIdBrand(value: Number): void{
    //
    //     this.getTemplateViewModel().idBrand(value);
    // }

    public setResultInfo(nbResults, nbPages, execTime){

        this.resultsInfo.setExecutionTime(execTime);
        this.resultsInfo.setNbResults(nbResults);
        this.resultsInfo.setNbPages(nbPages);
    }

    public updatePagination(totalCount){
        this.pagination.setNumberItemToPaginate(totalCount);
    }

    /**
     * Sets data for a multi select filter.
     *
     * @param filter
     * @param {Array<{ id: number, name: string }>} data
     */
    public setMultiSelectFilter(filter, data: Array<{ id: string, name: string }>): void{

        filter.setItems(data);
    }

    /**
     * Handles Reordering table.
     *
     * @param value
     * @param data
     * @param event
     */
    public onClickReorder(value, data, event){

        this.getTemplateViewModel().orderBy(value);

        if( this.getTemplateViewModel().orderByDirection() == 'DESC' ){

            this.getTemplateViewModel().orderByDirection('ASC');
        }
        else{

            this.getTemplateViewModel().orderByDirection('DESC');
        }

        this.getTemplateViewModel().orderByAndDirection(value + '_' + this.getTemplateViewModel().orderByDirection());
        this.caller.reorderArticles();
    }

    private onExportData(){

        this.caller.getExportData();
    }

    public setExportData(exportData){

        let exportDataView = [];
        for (let exportDataRow of exportData) {

            let exportDataViewRow = [];
            exportDataViewRow["Brand"] = exportDataRow.brandName;
            // exportDataViewRow["Article"] = exportDataRow.pageUrl;
            exportDataViewRow["Article"] = exportDataRow.pageUrl.replace(/^.*\/\/[^/]+/, '');
            exportDataViewRow["Page RPM"] = Number(exportDataRow.sessionValue).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            exportDataViewRow["Revenue"] = Number(exportDataRow.totalRevenue).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            exportDataViewRow["Views"] = Number(exportDataRow.totalPageViewCount).toLocaleString('en-EG', {minimumFractionDigits: 0, maximumFractionDigits: 0});
            exportDataViewRow["Recirculation"] = Number(exportDataRow.recirculation).toLocaleString('en-EG', {minimumFractionDigits: 0, maximumFractionDigits: 0});
            //exportDataViewRow["Total Requests"] = Number(exportDataRow.totalRequests).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            exportDataViewRow["Imp / View"] = Number(exportDataRow.impressionsPageView).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            exportDataViewRow["Req / View"] = Number(exportDataRow.requestsPageView).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            exportDataViewRow["CPM"] = Number(exportDataRow.cpm).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            exportDataViewRow["% US"] = Number(exportDataRow.totalUsPageViewRatio).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            exportDataViewRow["% Non-US Prime"] = Number(exportDataRow.totalNonUsPageViewRatio).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            exportDataViewRow["% Chrome"] = Number(exportDataRow.totalChromePageViewRatio).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2});
            // exportDataViewRow["Device"] = exportDataRow.device;

            exportDataView.push(exportDataViewRow);
        }

        this.getTemplateViewModel().exportData(exportDataView);
    }

    private exportData(obj){

        let fileName = "ArticleLevelSV_"  + this.brandFilter.getTemplateViewModel().selectedOption() + "_" + this.datesFilter.getTemplateViewModel().startDate() + "_" + this.datesFilter.getTemplateViewModel().endDate()+ ".csv";
        this.exportDataCsv.exportToCsv(fileName, this.getTemplateViewModel().exportData());
    }

}