import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import { siteConfig_template } from "../components/templates/siteConfig_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class siteConfig extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected siteConfig_template: siteConfig_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return "siteConfig";
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Config Edit");

        // create the users form
        this.siteConfig_template = new siteConfig_template("siteConfig_template", this.viewModel, this);

        return [this.siteConfig_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getEditFormData();
    }

    /**
     * Hook.
     *
     * Saves the edits to a geo.
     */
    protected saveEditSiteConfig(): void{

        this.save();
    }

    /**
     * Hook.
     *
     * Cancels the edits to a user.
     */
    protected cancelEditSiteConfig(){

        this.loadingIn(this.siteConfig_template.builtTemplate);

        this.releaseLock();

        let idBrand = this.getVnAppZone().app.params.idBrand;

        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "Site Config action canceled!");

        page.redirect(`/brand/${idBrand}/ads_setups`);
    }

    /**
     * Requests a lock release.
     */
    protected releaseLock(): void{

        let idBrand = this.getVnAppZone().app.params.idBrand;

        // Request a lock release.
        this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${idBrand}/site_config/release_lock/`, [], this);
        this.XHRHdl.execute();
    }


    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditData(takeover: boolean = false): void{

        this.loadingIn(this.siteConfig_template.builtTemplate);

        let idBrand = this.getVnAppZone().app.params.idBrand;

        this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${idBrand}/site_config/get/`, [['takeover', (takeover) ? '1' : '0']], this);
        this.XHRHdl.onReadyStateFunction = this.onEditDataRequestReturn;
        this.XHRHdl.execute();

    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onEditDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditData(responseParsed);
                obj.loadingOut(obj.siteConfig_template.builtTemplate);
            });
        }
    }

    /**
     * Updates the form elements.
     *
     */
    protected updateEditData(responseParsed: any){

        if(responseParsed.data !== null) {

            this.setSiteConfig(responseParsed.data);
        }
    }

    protected setSiteConfig(adType){

        this.siteConfig_template.setSiteConfig(adType);
    }


    /**
     * Saves the source data.
     */
    protected save(): void{

        this.loadingIn(this.siteConfig_template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.siteConfig_template.getViewModelForRequest();
        let idBrand = this.getVnAppZone().app.params.idBrand;

        formReq_params.push(["data", ko.toJSON(subViewModel)] );


        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/brand/${idBrand}/site_config/save/`, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveReturn;
        XHRHdl.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{

        let idBrand = obj.getVnAppZone().app.params.idBrand;

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Site Config created successfully!');
                obj.releaseLock();
                page.redirect(`/brand/${idBrand}/ads_setups`);
            });

            obj.loadingOut(obj.siteConfig_template.builtTemplate);
        }
    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.getEditData(true);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }

    //
    // Obtain the dynamic data required to fill the form.
    protected getEditFormData(): void{

        this.loadingIn(this.siteConfig_template.builtTemplate);

        let idBrand = this.getVnAppZone().app.params.idBrand;

        this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${idBrand}/site_config/references/`, [], this);
        this.XHRHdl.onReadyStateFunction = this.onEditFormDataRequestReturn;
        this.XHRHdl.execute();
    }

    //
    // Returns handler for edit form request.
    protected onEditFormDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditFormData(responseParsed);
                obj.loadingOut(obj.siteConfig_template.builtTemplate);
                obj.getEditData();
            });
        }
    }

    //
    // Update form data.
    protected updateEditFormData(responseParsed: any): void{

        this.siteConfig_template.getTemplateViewModel().biddingTypes(responseParsed.biddingTypes);
    }
}
