import {tasksList_template} from "../vnKnockoutJsTemplates/tasksList_template";
import {vnModule_knockoutJS} from "./vnModule_knockoutJS";
import {XMLHttpRequestHandler} from "../XMLHttpRequestHandler/XMLHttpRequestHandler";
import {vn_app_zone_notification} from "./vn_app_zone_notification";
import { XMLHttpRequestHandler_requestType } from "../XMLHttpRequestHandler/XMLHttpRequestHandler";
import { validationsList_template } from "../vnKnockoutJsTemplates/validationsList_template";
import {header} from "./header";


declare var ko: any;
declare var page: any;

export class tasks extends vnModule_knockoutJS{

    protected tasksList: tasksList_template;
    protected XHRHdl: XMLHttpRequestHandler;
    protected validationsList: validationsList_template;

    //
    // implementation of base class [vnModule]
    protected getModuleName(): string{

        return "tasks";
    }

    //
    // implementation of base class [vnModule]
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Tasks");

        this.validationsList = new validationsList_template("validationList", this.viewModel, this);

        this.tasksList = new tasksList_template("tasks", this.viewModel, this)

        return [this.validationsList.build(), this.tasksList.build()];
    }

    // implementation of base class [vnModule]
    public fillPage(): void {

        this.getListFormData();
    }

    //
    // required from template [usersList_template]
    public paginationOnPageClick(): void {

        this.getListData();
    }

    //
    // required from template [accountsList_template]
    protected reorderTasks(): void {

        this.getListData();
    }

    protected getListFormData(): void {

        this.loadingIn(this.tasksList.builtTemplate.children.namedItem('list-results'));

        this.XHRHdl = new XMLHttpRequestHandler('/api/tasks/list_from_data/', [], this);
        this.XHRHdl.onReadyStateFunction = this.onFilterRequestReturn;
        this.XHRHdl.execute();
    }

    protected onFilterRequestReturn(req, obj): Function {

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);
                if(  req.request.status == 200  ){

                    obj.updateFilters(responseParsed);
                    obj.getListData();
                }
            });
        }
    }

    protected getMultipleAutoCompleteSuggestions(idSourceElement, keyword) {
        switch (idSourceElement) {
            case 'executerFilterAutocomplete':
                this.getTaskExecuterSuggestions(keyword, idSourceElement);
                break;
            default:
                console.log(`getMultipleAutoCompleteSuggestions not implemented for ${idSourceElement}`);
                break;
        }
    }

    private getTaskExecuterSuggestions(keyword, idSourceElement, limit = 5) {
        if( idSourceElement in this.XHRHdls ){
            this.XHRHdls[idSourceElement].cancelAndReset();
        }

        let subViewModel = {filter: {
            keyword: keyword,
            limit: limit || 5,
        } };

        let req_url: string = '/api/task-executer/search/';
        let req_params: Array<Array<string>> = [];
        req_params.push(["data", ko.toJSON(subViewModel)]);

        this.XHRHdls[idSourceElement] = new XMLHttpRequestHandler(req_url, req_params, this);
        this.XHRHdls[idSourceElement].onReadyStateFunction = this.onGetTaskExecuterSuggestionsReturn;
        this.XHRHdls[idSourceElement].execute();
    }

    private onGetTaskExecuterSuggestionsReturn(req, obj) {

        return function () {
            obj.manageResponse(req, obj, function () {
                let responseParsed = JSON.parse(req.request.response);
                if (responseParsed.data.taskExecuters)
                {
                    obj.tasksList.executerAutocomplete.setItems(responseParsed.data.taskExecuters);
                }
            });
        }
    }

    protected onAutocompleteOptionChange(idElement) {
        this.getListData();
    }

    protected onAutocompleteInputClick(idElement) {
    }

    protected onAutocompleteInputClear(idElement) {
        this.getListData();
    }

    public filterMultipleSelectorOnChange(elem): void {
        this.tasksList.resetPageNumber();
        this.tasksList.resetOrderParameters();
        this.getListData();
    }

    public getListData(): void {

        this.loadingIn(this.tasksList.builtTemplate.children.namedItem("list-results"));

        if( "getListData" in this.XHRHdl ){

            this.XHRHdl["getListData"].cancelAndReset();
        }

        let subViewModel = this.tasksList.getViewModelForRequest();
        //console.log(subViewModel);

        let usrReq_url: string = '/api/tasks/get/';
        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(["data", ko.toJSON(subViewModel)] );
        this.XHRHdl["getListData"] = new XMLHttpRequestHandler(usrReq_url,usrReq_params, this);

        this.XHRHdl["getListData"].onReadyStateFunction = this.onDataRequestReturn;
        this.XHRHdl["getListData"].execute();

    }

    /**
     * @param req
     * @param obj
     * @protected
     */
    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                const responseParsed = JSON.parse(req.request.response);
                const execTime = Math.round((new Date().getTime() - req._requestStartTime)/1000*100)/100;
                obj.updateList(responseParsed, execTime);
                obj.loadingOut(obj.tasksList.builtTemplate.children.namedItem('list-results'));
                obj.tasksList.autoRefresh.callRefresh();
            });
        }
    }

    protected onUpdateError(): void {

    }

    protected errorHandler(req, obj): void {

        obj.getVnAppZone().showNotificationToModule(new vn_app_zone_notification(vn_app_zone_notification.TYPE_ERROR, "An error occured."));
        console.log("ERROR");
    }

    protected updateList(responseParsed: any, execTime = 0): void {
        this.loadingIn(this.tasksList.builtTemplate.children.namedItem("list-results"));

        this.tasksList.setPagination(responseParsed.totalCount);
        this.tasksList.setResultPage(responseParsed.totalCount, execTime);

        let tasks = [];
        if( responseParsed.data && responseParsed.data.length > 0 ){
            tasks = responseParsed.data.map(item => {
                let task = item['0'];
                if(task.dateExecuted && task.formattedDateUpdated) {
                    const dateUpdate = new Date(task.formattedDateUpdated);
                    const dateExecuted = new Date(task.dateExecuted);
                    const diff = dateUpdate.getTime() - dateExecuted.getTime();

                    const hours = Math.floor(diff / 1000 / 60 / 60);
                    const formattedHours = (hours.toString().length < 2 ? '0'.repeat(2 - hours.toString().length) : '') + hours.toString();
                    const minutes = Math.floor((diff - (hours * 1000 * 60 * 60)) / 1000 / 60);
                    const formattedMinutes = (minutes.toString().length < 2 ? '0'.repeat(2 - minutes.toString().length) : '') + minutes.toString();
                    const seconds = Math.floor((diff - (hours * 1000 * 60 * 60) - (minutes * 1000 * 60)) / 1000);
                    const formattedSeconds = (seconds.toString().length < 2 ? '0'.repeat(2 - seconds.toString().length) : '') + seconds.toString();

                    task.executionTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
                } else {
                    task.executionTime = ``;
                }

                return task;
            });
        }

        this.tasksList.setTasks(tasks);
    }

    public filterDropDownOnChange(): number {

        return 1;
    }

    public filterSingleGroupSelectorOnChange(elem): void {

        this.tasksList.resetPageNumber();
        this.tasksList.resetOrderParameters();
        this.getListData();

    }

    // required from template [tasksList_template]
    protected filterSimpleOnChange(){

        this.tasksList.resetPageNumber();
        this.tasksList.resetOrderParameters();
        this.getListData();
    }

    protected updateFilters(responseParsed: any): void {

        this.tasksList.setOperationFilterItems(responseParsed.operations);
        this.tasksList.setStatusFilterItems(responseParsed.status);
        this.tasksList.setUpdateStatuses(responseParsed.updateStatuses);
        this.tasksList.setIdUpdateDelete(responseParsed.idUpdateDelete);
        this.tasksList.setPipelineFilterItems(responseParsed.pipelines);
        this.tasksList.setPermissions(responseParsed.permissions);
        this.tasksList.setBrandFilterItems(responseParsed.brands);
    }


    public filterDropdownOnChange(elem): void {

        if (elem != "updateStatuses") {

            this.tasksList.resetPageNumber();
            this.tasksList.resetOrderParameters();
            this.getListData();
        }
    }

    private resetPageNumber(){
        // go back to page 1 in the pagination
        this.viewModel.tasks.pagination.pageNumber(1);
    }

    public filterSimpleCancel(): void {

    }

    protected deleteTask(task: any): void {

        let formReq_params: Array<Array<string>> = [];

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler('/api/tasks/delete/'+task. id , formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onDeleteReturn;
        XHRHdl.execute();
    }

    protected filterAutoRefreshStatusOnChange(): void {

    }

    /**
     * Handles delete response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onDeleteReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Task deleted successfully!');
                obj.getVnAppZone().showNotificationToModule();

                obj.validationsList.clearMessages();

                obj.getListData();
            });
        }
    }


    protected rescheduleTask(): void {

        this.loadingIn(this.tasksList.builtTemplate.children.namedItem("timeline"));

        if( "rescheduleTask" in this.XHRHdl ){

            this.XHRHdl["rescheduleTask"].cancelAndReset();
        }

        let subViewModel = this.tasksList.getViewModelForRequest();

        let usrReq_url: string = '/api/tasks/rescheduleBlocks/get/';
        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(["data", ko.toJSON(subViewModel)] );
        this.XHRHdl["rescheduleTask"] = new XMLHttpRequestHandler(usrReq_url,usrReq_params, this);

        this.XHRHdl["rescheduleTask"].onReadyStateFunction = this.onRescheduleTaskReturn;
        this.XHRHdl["rescheduleTask"].execute();
    }

    protected onRescheduleTaskReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.tasksList.setTimeline(responseParsed.timeline);
            });

            obj.loadingOut(obj.tasksList.builtTemplate.children.namedItem("timeline"));
        }
    }


    protected saveRescheduleTask(): void {

        this.loadingIn(this.tasksList.builtTemplate.children.namedItem("timeline"));

        if( "saveRescheduleTask" in this.XHRHdl ){

            this.XHRHdl["saveRescheduleTask"].cancelAndReset();
        }

        let subViewModel = this.tasksList.getViewModelForRequest();

        let usrReq_url: string = '/api/tasks/reschedule/save/';
        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(["data", ko.toJSON(subViewModel)] );
        this.XHRHdl["saveRescheduleTask"] = new XMLHttpRequestHandler(usrReq_url,usrReq_params, this);
        this.XHRHdl["saveRescheduleTask"].mode = XMLHttpRequestHandler_requestType.POST;

        this.XHRHdl["saveRescheduleTask"].onReadyStateFunction = this.onSaveRescheduleTaskkReturn;
        this.XHRHdl["saveRescheduleTask"].execute();
    }

    protected onSaveRescheduleTaskkReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                //let responseParsed = JSON.parse(req.request.response);
                obj.loadingOut(obj.tasksList.builtTemplate.children.namedItem("timeline"));

                obj.tasksList.onClickCancelReschedule();

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Task rescheduled successfully!');
                obj.getVnAppZone().showNotificationToModule();

                obj.validationsList.clearMessages();

                obj.getListData();

            });

        }
    }

    protected updateTask(ids, idStatus): void {

        let formReq_params: Array<Array<string>> = [];
        let data = {ids: ids, idStatus: idStatus};

        formReq_params.push(["data", ko.toJSON(data)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler('/api/tasks/update/' , formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onUpdateReturn;
        XHRHdl.execute();
    }

    /**
     * Handles delete response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onUpdateReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Task updated successfully!');
                obj.getVnAppZone().showNotificationToModule();

                obj.validationsList.clearMessages();

                obj.getListData();
            });
        }
    }

    /**
     * Hook.
     *
     * Redirects to create task page.
     */
    protected addTask(): void{

        page('/tasks/add');
    }

    /**
     * Hook.
     *
     * refresh the data on request
     */
    public autoRefresh(){
        this.getListData();
    }
}