import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import { sspConfigsEdit_template } from "../components/templates/sspConfigsEdit_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class sspConfig_edit extends vnModule_knockoutJS{

    protected requestHandler: XMLHttpRequestHandler;
    protected template: sspConfigsEdit_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return 'sspConfig_edit';
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{

        let zoneHeader   = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        let idBrand      = this.getVnAppZone().app.params.idBrand;

        if(idBrand){

            (<header>loadedModule).setHeaderTitle("SSP Config Edit");
        }
        else{

            (<header>loadedModule).setHeaderTitle("SSP List");
        }

        // create the users form
        this.template = new sspConfigsEdit_template('sspConfigsEdit_template', this.viewModel, this);

        return [this.template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getEditFormData();
    }

    /**
     * Hook.
     *
     * Cancels edit.
     */
    protected cancel(){

        this.loadingIn(this.template.builtTemplate);

        this.releaseLock();

        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, 'SSP configs edit action canceled!');

        page.redirect(`/brand/${this.getVnAppZone().app.params.idBrand}/ssps/`);
    }

    /**
     * Requests a lock release.
     */
    protected releaseLock(): void{

        // Request a lock release.
        this.requestHandler = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ssps/${this.getVnAppZone().app.params.idSsp}/configs/release_lock/`, [], this);
        this.requestHandler.execute();
    }

    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditFormData(): void{

        this.loadingIn(this.template.builtTemplate);

        this.requestHandler = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ssps/configs/references/`, [], this);
        this.requestHandler.onReadyStateFunction = this.onEditFormDataRequestReturn;
        this.requestHandler.execute();
    }

    /**
     * Returns handler for edit form request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onEditFormDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditFormData(responseParsed);
                obj.getEditData();
            });
        }
    }

    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditData(takeover: boolean = false): void{

        this.loadingIn(this.template.builtTemplate);

        this.requestHandler = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ssps/${this.getVnAppZone().app.params.idSsp}/configs/get/`, [['takeover', (takeover) ? '1' : '0']], this);
        this.requestHandler.onReadyStateFunction = this.onEditDataRequestReturn;
        this.requestHandler.execute();
    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onEditDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditData(responseParsed);
                obj.loadingOut(obj.template.builtTemplate);
            });
        }
    }

    /**
     * Update the filters.
     *
     * @param responseParsed
     */
    protected updateEditFormData(responseParsed: any): void{

        this.template.getTemplateViewModel().allMediaTypes(responseParsed.mediaTypes);
        this.template.getTemplateViewModel().allDevices(responseParsed.devices);
    }

    /**
     * Updates the form elements.
     *
     */
    protected updateEditData(responseParsed: any){

        this.template.setData(responseParsed.data);
    }

    /**
     * Hook.
     *
     * Saves edit data.
     */
    protected save(stay = false): void{

        this.loadingIn(this.template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];
        formReq_params.push(["data", ko.toJSON(this.template.getViewModelForRequest())]);

        let requestHandler:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ssps/${this.getVnAppZone().app.params.idSsp}/configs/save/`, formReq_params, this);
        requestHandler.mode                      = XMLHttpRequestHandler_requestType.POST;

        if(stay){

            requestHandler.onReadyStateFunction = this.onSaveAndStayReturn;
        }
        else{

            requestHandler.onReadyStateFunction = this.onSaveReturn;
        }

        requestHandler.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'SSP configs edit action successful!');

                obj.releaseLock();
                page.redirect(`/brand/${obj.getVnAppZone().app.params.idBrand}/ssps/`);
            });

            obj.loadingOut(obj.template.builtTemplate);
        }
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveAndStayReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'SSP configs edit action successful!');
                page();
            });

            obj.loadingOut(obj.template.builtTemplate);
        }
    }

    //
    // Hook.
    // Copies SSP configs and inserts them into current context.
    protected copy(ids): void{

        this.loadingIn(this.template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];
        formReq_params.push(["ids", ko.toJSON(ids)]);

        let requestHandler:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ssps/${this.getVnAppZone().app.params.idSsp}/configs/copy/`, formReq_params, this);
        requestHandler.mode                      = XMLHttpRequestHandler_requestType.POST;
        requestHandler.onReadyStateFunction      = this.onCopyRequestReturn;

        requestHandler.execute();
    }

    //
    // Returns handler for copy request.
    protected onCopyRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Copy successful, insert copies SSP configs into the desired location.');
                obj.getVnAppZone().showNotificationToModule();
                obj.template.getTemplateViewModel().isInsertButtonShown(true);
                obj.template.getTemplateViewModel().setAllSelected(false);
            });

            obj.loadingOut(obj.template.builtTemplate);
        };
    }

    //
    // Insert copied configs.
    protected insert(): void{

        this.loadingIn(this.template.builtTemplate);

        this.requestHandler                      = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ssps/${this.getVnAppZone().app.params.idSsp}/configs/insert`, [], this);
        this.requestHandler.mode                 = XMLHttpRequestHandler_requestType.POST;
        this.requestHandler.onReadyStateFunction = this.onInsertRequestReturn;
        this.requestHandler.execute();
    }

    //
    // Returns handler for filter request.
    protected onInsertRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Insert successful.');
                page();
            });

            obj.loadingOut(obj.template.builtTemplate);
        }
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public filterDropdownOnChange(): void{

    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.getEditData(true);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }
}
