import { sponsoredRevenue_template } from '../components/templates/sponsoredRevenue_template';
import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import {header} from "./header";
import {vnApp_ads_ninja} from "../components/vnApp_ads_ninja";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";

declare var ko: any;
declare var page: any;

export class sponsoredRevenue extends vnModule_knockoutJS{

    protected sponsoredRevenue_template: sponsoredRevenue_template;
    protected XHRHdl: XMLHttpRequestHandler;

    protected getModuleName(){

        return "sponsoredRevenue";
    }

    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Sponsored Revenue");

        this.sponsoredRevenue_template = new sponsoredRevenue_template("sponsoredRevenue", this.viewModel, this);
        return [this.sponsoredRevenue_template.build()];
    }

    public fillPage(): void{

        this.sponsoredRevenue_template.buildDatePickerCalendar();
        this.getListFormData();
    }


    protected getListFormData(): void{

        this.loadingIn(this.sponsoredRevenue_template.builtTemplate.children.namedItem('list-results'));
        this.XHRHdl = new XMLHttpRequestHandler('/api/sponsoredRevenue/references/', [], this);
        this.XHRHdl.onReadyStateFunction = this.onGetListFormDataReturn;
        this.XHRHdl.execute();
    }



    protected onGetListFormDataReturn(req, obj){

        return function(){
            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);
                obj.updateFilters(responseParsed);
                obj.getListData();
            });
        }
    }

    protected updateFilters(responseParsed: any){

        this.sponsoredRevenue_template.setBrandFilterItems(responseParsed.brands);
    }


    public getListData(): void{

        // LOADING : starts
        this.loadingIn(this.sponsoredRevenue_template.builtTemplate.children.namedItem("list-results"));

        if( this.XHRHdl ){
            this.XHRHdl.cancelAndReset();
        }

        let usrReq_url: string = '/api/sponsoredRevenue/get/';
        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(["data", ko.toJSON(this.sponsoredRevenue_template.getViewModelForRequest())] );
        this.XHRHdl = new XMLHttpRequestHandler(usrReq_url,usrReq_params, this);

        this.XHRHdl.onReadyStateFunction = this.onDataRequestReturn;
        this.XHRHdl.execute();
    }

    protected onDataRequestReturn(req, obj){

        return function (){

            obj.manageResponse(req, obj, function (){

                let responseParsed = JSON.parse(req.request.response);
                obj.updateList(responseParsed);
            });

            obj.loadingOut(obj.sponsoredRevenue_template.builtTemplate.children.namedItem("list-results"));
        };
    }

    protected updateList(responseParsed: any){

        this.sponsoredRevenue_template.setData(responseParsed.data);
        this.sponsoredRevenue_template.setPaginationTotalCount(responseParsed.totalCount);
        let execTime = Math.round((new Date().getTime() - this.XHRHdl.requestStartTime)/1000*100)/100;
        this.sponsoredRevenue_template.setResultInfo(responseParsed.totalCount, responseParsed.totalPage, execTime);
    }

    protected deleteSponsoredRevenue(id): void {

        this.loadingIn(this.sponsoredRevenue_template.builtTemplate.children.namedItem("list-results"));

        let formReq_params: Array<Array<string>> = [];
        formReq_params.push(["data", ko.toJSON({"idSponsoredRevenue": id})] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler('/api/sponsoredRevenue/delete', formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onDeleteReturn;
        XHRHdl.execute();
    }

    protected onDeleteReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Sponsored Revenue deleted successfully!');
                obj.getVnAppZone().showNotificationToModule();

                obj.getListData();
            });

            obj.loadingOut(obj.sponsoredRevenue_template.builtTemplate.children.namedItem("list-results"));

        }
    }


    protected editSponsoredRevenue(id){

        page('/sponsored_revenue/edit/'+id);
    }


    protected addSponsoredRevenue(){

        page('/sponsored_revenue/add');
    }

    protected paginationOnPageClick(){

        this.getListData();
    }

    protected filterDropdownOnChange() {

        this.resetPageNumber();
        this.getListData();
    }

    public filterSimpleCancel(): void {
        console.log('filterSimpleCancel');
    }

    protected filterMultipleSelectorOnChange(){

        this.resetPageNumber();
        this.getListData();
    }

    protected filterSimpleOnChange(){

        this.resetPageNumber();
        this.getListData();
    }

    private resetPageNumber(){

        this.sponsoredRevenue_template.resetPageNumber();
    }

    protected filterDateOnChange(){

        this.resetPageNumber();
        this.getListData();
    }

    public dataReorder(id) {

        this.getListData();
    }

    public confirmationModalOnClickConfirm(context, data): void{

        this.deleteSponsoredRevenue(data);
    }

/*
    protected setResultInfo(totalCount, totalPage){
        let execTime = Math.round((new Date().getTime() - this.XHRHdl.requestStartTime)/1000*100)/100;
        this.sponsoredRevenue_template.setResultInfo(totalCount, totalPage, execTime);
    }
 */

    /**
     * Fetches export data
     */
    public getExportData(): void{

        if( this.XHRHdl ){
            this.XHRHdl.cancelAndReset();
        }

        let subViewModel = this.sponsoredRevenue_template.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(["data", ko.toJSON(subViewModel)] );
        this.XHRHdl = new XMLHttpRequestHandler('/api/sponsoredRevenue/export/',usrReq_params, this);

        this.XHRHdl.onReadyStateFunction = this.onGetExportData;
        this.XHRHdl.execute();
    }

    protected onGetExportData(req, obj){

        return function () {

            obj.manageResponse(req, obj, function () {

                let responseParsed = JSON.parse(req.request.response);

                if (req.request.status == 200) {

                    obj.sponsoredRevenue_template.setExportData(responseParsed);
                    obj.sponsoredRevenue_template.exportData();
                }
            });
        }
    }
}