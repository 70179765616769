import {vnModule_knockoutJS} from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import {XMLHttpRequestHandler} from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import {XMLHttpRequestHandler_requestType} from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import {vn_app_zone_notification} from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import {dataImport_template} from "../components/templates/dataImport_template";
import {validationsList_template} from "../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/validationsList_template";

declare var ko: any;
declare var page: any;
declare var Dropzone: any;

export class data_import extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected data_import: dataImport_template;
    protected validationsList: validationsList_template;

    protected getModuleName(){

        return "data_import";
    }

    buildContent(): Array<Element>{

        this.validationsList = new validationsList_template("validationList", this.viewModel, this);
        this.data_import = new dataImport_template("importPageTemplate", this.viewModel, this);

        return [this.validationsList.build(), this.data_import.build()];
    }

    public fillPage(): void{

        this.data_import.filterDate.buildCalendarWithoutTime();

        let revenueReports = [

            {id: "", name: "- Choose Report -"},
            {id: "kargo (brand)", name: "kargo (brand)"},
            {id: "index HB (brand)", name: "index HB (brand)"},
            {id: "undertone (ad unit)", name: "undertone (ad unit)"},
            {id: "appnexus (brand)", name: "appnexus (brand)"},
            {id: "aol (brand)", name: "aol (brand)"},
            {id: "pubmaticHB (brand)", name: "pubmaticHB (brand)"},
            {id: "consumable (brand)", name: "consumable (brand)"},
            {id: "infolinks (brand)", name: "infolinks (brand)"},
        ];
        this.viewModel.importPageTemplate.reportFilter.setOptions(revenueReports);
        this.viewModel.importPageTemplate.reportFilter.setLabel("Report Type");

        this.generateDropzone();
        this.getAdvertiserData();
    }

    protected generateDropzone(){

        let myDropzone = new Dropzone("div#my-drop-zone", {

            url: "/api/data/upload/file/",
            maxFiles: 1,
            acceptedFiles: ".csv,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            dictDefaultMessage: "Drop files here or<br>Click to upload...",
        });

        let _this = this;

        myDropzone.on("success", function (file, serverFileName){

            _this.viewModel["importPageTemplate"].reportPath(serverFileName.data);
        });
    }

    protected getAdvertiserData(): void{

        let usrReq_url: string = '/api/import/getDataPullStatus';
        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(["data", ko.toJSON("a")]);

        this.XHRHdl = new XMLHttpRequestHandler(usrReq_url, usrReq_params, this);
        this.XHRHdl.mode = XMLHttpRequestHandler_requestType.GET;
        this.XHRHdl.onReadyStateFunction = this.onGetLastPullStatusByDay;
        this.XHRHdl.execute();
    }

    protected filterDropdownOnChange(){

    }

    protected filterSingleDateOnChange(){

    }

    protected onGetLastPullStatusByDay(req, obj){

        return function (){

            if (req.request.readyState == 4){

                let responseParsed = JSON.parse(req.request.response);

                if (req.request.status == 200){

                    obj.data_import.resetDataPullStatus();
                    for (let entry of responseParsed.data.lastPulls){

                        obj.data_import.addDataPullStatus(entry, entry[0].source);
                    }

                    obj.data_import.setDatesForPulls(responseParsed.data.datePulls);
                    obj.data_import.setBrands(responseParsed.data.brands);
                    obj.data_import.setCountries(responseParsed.data.countries);

                }

                obj.data_import.addCellSelectedClass();
            }
        }
    }

    protected importReport(){

        let _this = this;
        let formReq_url: string = '/api/data/import/file/';
        let formReq_params: Array<Array<string>> = [];

        //validate date is not in future
        if (new Date(this.viewModel.importPageTemplate.filterDate.singleDatePicker()) > new Date()){

            let msg = "Cannot choose a date in the future";
            _this.validationsList.setSingleMessage({field: "error", "label": "Date Error", message: msg});
            window.scrollTo(0, 0);

            return;
        }

        formReq_params.push(["data", ko.toJSON(_this.data_import.getViewModelForRequest())]);

        let XHRHdl: XMLHttpRequestHandler = new XMLHttpRequestHandler(formReq_url, formReq_params, this);
        XHRHdl.mode = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction = (function (req, obj){

            return function (){

                //reset dropzone
                _this.viewModel["importPageTemplate"].reportPath(null);
                let objDZ = Dropzone.forElement("div#my-drop-zone");
                objDZ.removeAllFiles(true);
                objDZ.emit("resetFiles");

                if (req.request.readyState == 4){

                    let responseParsed = JSON.parse(req.request.response);

                    if (req.request.status == 200){

                        obj.validationsList.clearMessages();
                        obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, "Report imported successfully !");
                        obj.getVnAppZone().showNotificationToModule();
                        console.log("Report imported successfully");

                        _this.getAdvertiserData();
                    } else{

                        if (req.request.status == 400){

                            obj.validationsList.setMessages(responseParsed.validations);
                            window.scrollTo(0, 0);
                        } else{

                            let messages = [];

                            for (let mm of responseParsed.data.split("<br/>")){

                                let cc = [];
                                cc.push(mm);
                                let tmp = {field: 'gen', label: 'Error', messages: cc};
                                messages.push(tmp)
                            }

                            obj.validationsList.setMessages(messages);
                            window.scrollTo(0, 0);
                        }
                    }
                }
            }
        });
        XHRHdl.execute();
    }
}
