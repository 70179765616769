
export class vn_app_zone_notification {

	static readonly TYPE_SUCCESS: string = "success";
	static readonly TYPE_WARNING: string = "alert";
	static readonly TYPE_ERROR: string = "error";

	protected _type: string;
	protected _message: string;

    constructor(type: string, message: string){
        this.type = type;
        this.message = message;
    }

	get type():string {
        return this._type;
    }
    set type(type: string) {
        this._type = type;
    }

    get message():string {
        return this._message;
    }
    set message(message: string) {
        this._message = message;
    }
}