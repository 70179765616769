import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";
import {appTitle_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/appTitle_template";
import {singleSelector_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/singleSelector_template";
import {menu_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/menu_template";

declare var ko: any;

export class menuNav_template extends vnTemplate{

    protected appTitle: appTitle_template;
    protected brandSelector: singleSelector_template;
    protected menuTemplate: menu_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            ${this.appTitle.getHtmlBuild()}
            
            <!-- ko if: showBrandSelector  -->
                ${this.brandSelector.getHtmlBuild()}
            <!-- /ko -->
            
            <!-- ko if: isUserLoggedIn  -->

                <!-- ko foreach: navBarItems -->
<!--                    <button class="btn large hor icon" data-bind="text: text, click: function(data, event){ $parent.onClick(data, event) }, css: { 'is-selected': selected }, class: cssClass, visible: visible"></button>-->
                    <a data-bind="attr: {href: url, class: 'btn large hor icon'}, css: { 'is-selected': selected},  class: cssClass, visible: visible, text: text"></a>      
                <!-- /ko -->  
               
            <!-- /ko -->
            
            <div id='menu-sidebar-container' data-bind="class: menuToggleClasses">
                ${this.menuTemplate.getHtmlBuild()}
                <button class="btn-sidebar icon i-dropdown-simple" data-bind="click: function(data, event) { toggleSideMenu(data, event) }" type="button"></button>
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{

        this.appTitle = new appTitle_template("appTitle", this.getTemplateViewModel(), this.caller);
        this.appTitle.setTitle("adsninja");

        this.brandSelector = new singleSelector_template("brandSelector", this.getTemplateViewModel(), this.caller);
        this.brandSelector.setSelected({id: 0, name: "-select property-"});

        this.menuTemplate = new menu_template("mainNavMenu", this.getTemplateViewModel(), this.caller)

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "menuNav-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementType(): string{

    	return "nav";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

    	return ["main-nav"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let self = this;

        return {
            menuToggleClasses: ko.observable(''),
            isBrandSelected: ko.observable(false),
            isUsersVisible: ko.observable(false),
            isUserLoggedIn: ko.observable(false),
            showBrandSelector: ko.observable(true),
            onClick: function(data, event){ self.onClick(data, event); },
            toggleSideMenu: function(data, event){ self.toggleSideMenu(data, event) },
            navBarItems: ko.observableArray([]),
        };
    }


    public changeSelection(idSelected){
        this.caller.changeSelection(idSelected);
    }

    setMenus(menus): void{

        // Get selected submenus.
        for(let x of menus){

            if(x.selected){

                this.menuTemplate.setMenus(x.subMenus);
                break;
            }
        }

        menus.shift();

        this.getTemplateViewModel().navBarItems(menus);
    }

    public getMenus(){

        return this.menuTemplate.getTemplateViewModel().menus();
    }

    //
    // set and select the brand drop down menu
    setBrands(brands: any){

        brands.sort(function(a, b){

            if(a.name < b.name){

                return -1;
            }
            else if(a.name > b.name){

                return 1;
            }

            return 0;
        });

        this.brandSelector.setItems(brands);
    }
    setSelectedBrand(idBrand: number){
        this.brandSelector.setSelectedById(idBrand);

        this.getTemplateViewModel().isBrandSelected((!!idBrand));
    }

    public isBrandSelected(){

        return this.getTemplateViewModel().isBrandSelected();
    }

    public setIsUsersVisible(isUsersVisible: boolean): void{

        this.getTemplateViewModel().isUsersVisible(isUsersVisible);
    }

    public setIsUserLoggedIn(isUserLoggedIn: boolean): void{

        this.getTemplateViewModel().isUserLoggedIn(isUserLoggedIn);
    }

    //
    // Handles on click menu items events.
    public onClick(data, event){

        this.caller.handleMenuItems(data.id, event);
    }

    //
    // Handles toggling showing or not the side menu.
    public toggleSideMenu(data, event){

        let body    = document.getElementsByTagName('body')[0];
        let classes = this.getTemplateViewModel().menuToggleClasses();

        if(classes.includes('is-closed')){

            body.classList.add('is-open');
            body.classList.remove('is-closed');
            this.getTemplateViewModel().menuToggleClasses('is-open');
            // this.caller.setShowMenuSidebar(false);
        }
        else{

            body.classList.add('is-closed');
            body.classList.remove('is-open');
            this.getTemplateViewModel().menuToggleClasses('is-closed');
            // this.caller.setShowMenuSidebar(true);
        }
    }

}
