import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import {pagination_template} from "../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/pagination_template";
import {usersList_template} from "../components/templates/usersList_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class users_list extends vnModule_knockoutJS{

    protected XHRHdl1: XMLHttpRequestHandler;
    protected XHRHdl2: XMLHttpRequestHandler;
    protected elementsList: usersList_template;
    protected pagination: pagination_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return "user_list";
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("User List");

        // Create validation errors list template.
        // Create the users list.
        this.elementsList = new usersList_template("list", this.viewModel, this);

        return [this.elementsList.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage() : void{

        this.getListFormData();
    }

    /**
     * Requests data for the filters.
     */
    public getListFormData(): void{

        this.loadingIn(this.elementsList.builtTemplate.children.namedItem('list-results'));

        // FB page filter.
        this.XHRHdl1 = new XMLHttpRequestHandler(
            '/api/users/references',
            [],
            this
        );

        this.XHRHdl1.onReadyStateFunction = this.onFilterRequestReturn;
        this.XHRHdl1.execute();
    }

    /**
     * Hook.
     *
     * Redirects to profile page.
     *
     * @param id
     */
    protected viewUserProfile(id){

        page(`/users/profile/${id}`);
    }

    /**
     * Hook.
     *
     * Redirects to create user page.
     */
    protected addUser(): void{

        page('/users/add');
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected onShowActiveUsers(){

        this.getListData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected paginationOnPageClick(){

        this.getListData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected dataReorder(){

        this.getListData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected filterMultipleSelectorOnChange(){

        this.viewModel.list.pagination.pageNumber(1);
        this.getListData();
    }

    /**
     * Hook.
     *
     * Cancels data request.
     */
    protected filterSimpleCancel(){

        if(this.XHRHdl2){

            this.XHRHdl2.cancelAndReset();
        }
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected filterSimpleOnChange(){

        this.viewModel.list.pagination.pageNumber(1);
        this.getListData();
    }

    /**
     * Fetches the list data.
     */
    protected getListData(){

        if(this.XHRHdl2){
            this.XHRHdl2.cancelAndReset();
        }

        // LOADING : starts
        this.loadingIn(this.elementsList.builtTemplate.children.namedItem("list-results"));

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(["data", ko.toJSON(this.elementsList.getViewModelForRequest())]);

        this.XHRHdl2                      = new XMLHttpRequestHandler('/api/users/get', usrReq_params, this);
        this.XHRHdl2.onReadyStateFunction = this.onDataRequestReturn;
        this.XHRHdl2.execute();
    }

    /**
     * Returns handler for filter request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onFilterRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response || '{}');

                obj.updateFilters(responseParsed);
                obj.getListData();
                obj.loadingIn(obj.elementsList.builtTemplate.children.namedItem('list-results'));
            });
        }
    }

    /**
     * Returns handler for data request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response || '{}');

                obj.updateList(responseParsed);
                obj.loadingOut(obj.elementsList.builtTemplate.children.namedItem('list-results'));
            });
        }
    }

    /**
     * Update the module list using the objects list.
     *
     * @param responseParsed
     */
    protected updateList(responseParsed: any){

        let usersToSet = [];

        for (let item of responseParsed.data) {

            usersToSet.push({
                pictureUrl: ((item.valnet_user.pictureUrl) ? `/usersImages/${item.valnet_user.pictureUrl}` : "/assets/images/default-user.png"),
                fullName: item.valnet_user.fullName,
                loginName: item.valnet_user.loginName,
                email: item.valnet_user.email,
                dateCreation: item.valnet_user.dateCreation.substring(0, 10),
                id: item.id,
            });
        }

        let execTime = Math.round((new Date().getTime() - this.XHRHdl2.requestStartTime)/1000*100)/100;

        this.elementsList.setResultInfo(responseParsed.totalCount, responseParsed.totalPage,execTime );
        this.elementsList.setUsers(usersToSet);

        // Update the paginator.
        this.viewModel.list.pagination.itemCount(responseParsed.totalCount);
    }

    /**
     * Update the filters.
     *
     * @param responseParsed
     */
    protected updateFilters(responseParsed: any): void{

        this.elementsList.setRoles(responseParsed.roles);
    }
}