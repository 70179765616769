import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;
export class userSection_template extends vnTemplate{

    protected getTemplateHtml(): string{

        return `
                <!-- ko if: user() -->    
                    <div class="w-user-img" data-bind="click: onclickViewProfile">
                        <img class="user-img" data-bind="attr:{src: profilePicture}" alt="">
                    </div>
                    <div class="w-user-info" data-bind="click: onclickViewProfile">
                        <strong class="icon i-go-to"><span data-bind="text: user().user.valnet_user.fullNameAndLogin"></span></strong>
                        <p data-bind="text: user().user.valnet_user.roles[0].name"></p>
                    </div>
                <!-- /ko -->
                `;
    }

    protected getTemplateName(): string{
        return "userSection-template";
    }

    protected getMainElementType(): string{
        return "div";
    }

    protected getMainElementClasses(): Array<string>{
        return ["user-section"];
    }

    protected buildViewModel(): any{
        let huit_neuf_this = this;
        return {
            user: ko.observable(null),
            profilePicture: ko.observable("/assets/images/default-user.png"),
            onclickViewProfile: function (data) { huit_neuf_this.onClickViewProfile(data.user().user.id);}

        };
    }

    public setUser(user: any){

        if(user.user.valnet_user.pictureUrl){
            this.getTemplateViewModel().profilePicture(user.user.valnet_user.pictureUrl);
        }

        this.getTemplateViewModel().user(user);
    }

    public onClickViewProfile(idUser){
        this.caller.viewProfile(idUser);
    }
}
