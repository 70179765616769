import { vnAppZone } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone";
import { pageFactory } from "../modules/factory";


export class vnAppZoneHeader extends vnAppZone {

    public constructor(destination) {
        super(destination);
        this.zoneName = "Header";
    }

    defineRoutes() {
        let factory = new pageFactory();
        let _mythis = this;

        //define routes
        this.addForRoute('all', function(){

            // obtain the right module object
            let moduleObj = factory.get_actionHandler("header", _mythis);

            // load it into the zone
            _mythis.loadModule(moduleObj);
        }, null );

        this.addForRoute('/header', function() {}, null );

    }
}
