import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class advertisingPartnerAccessEdit_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
        <div class="w-form" style="width:90rem;">
            <div class="form-header">
                <!-- ko ifnot: idAdvertisingPartnerAccess -->
                <h3 class="form-title icon i-add">Add New advertising Partner Access</h3>
                <!-- /ko -->

                <!-- ko if: idAdvertisingPartnerAccess -->
                <h3 class="form-title icon i-content">edit advertising Partner Access</h3>
                <!-- /ko -->

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>

            <div class="form-body">
                <div class="form-group row">
                    <label for="idOperation" class="form-label">Advertising Partner</label>
                    <select data-bind="
                            options: advertisingPartners,
                            optionsText: 'name',
                            optionsValue: 'id',
                            value: idAdvertisingPartner,
                            event: { change: onChangeAdvertisingPartner },
                            disable: idAdvertisingPartnerAccess
                        ">
                    </select>
                </div>
                <div class="form-group row">
                    <label for="name" class="form-label">name</label>
                    <input id="name" data-bind="value: name" type="text" class="input-text">
                </div>                    
                <div class="form-group row">
                    <label for="advertiserName" class="form-label">Advertiser Name from GAM</label>
                    <input id="advertiserName" data-bind="value: advertiserName" type="text" class="input-text">
                </div>                       
                <div data-bind="visible: idAdvertisingPartner">
                    <div class="form-group row">
                        <label for="executeScheduled" class="form-label">Execute Scheduled</label>
                        <input id="executeScheduled" data-bind="value: automaticTaskFrequency" type="text" class="input-text">
                    </div>                               
                    <div class="form-group">
                        <hr class="form-hr">
                    </div>
                    <div class="form-group">
                        <label class="form-sub-title">FIELDS</label>
                    </div>
                    <div data-bind="foreach: fields">
                        <div class="form-group row" data-bind="visible: field_type == 'global'">
                            <label class="form-label" data-bind="text: field_name"></label>
                            <input data-bind="value: value" type="text" class="input-text">
                        </div>
                    </div>
                    <div class="form-group">
                        <hr class="form-hr">
                    </div>

                    <!-- ko if: hasDynamicFields -->
                    <div class="form-group">
                        <label class="form-sub-title">Dynamic Fields</label>
                    </div>
                    <div data-bind="foreach: fields">
                        <!-- ko if: field_type == 'dynamic' -->
                        <div class="form-group row">
                            <label class="form-label" data-bind="text: field_name"></label>
  
                            <div data-bind="foreach: value">
                                <div class="form-group">
                                    <label class="form-label" data-bind="text: $parent.display_name"></label>
                                    <input data-bind="value: name" type="text" class="input-text">
                                    <label class="form-label" data-bind="text: $parent.display_value"></label>
                                    <input data-bind="value: value" type="text" class="input-text">
                                </div>
                            </div>
                            <div class="form-group">
                                <button data-bind="event: { click: $parent.onClickAddField.bind($data, $index()) }" class="btn icon i-add" type="button">Add field</button>
                            </div>
                        </div>
                        <!-- /ko -->
                    </div>
                    <div class="form-group">
                        <hr class="form-hr">
                    </div>
                    <!-- /ko -->
                </div>          

            </div>   

            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save advertising Partner</span></button>  
            </div>
        </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "advertisingPartnerEdit-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{
        let huit_neuf_this = this;

        return {
            idAdvertisingPartnerAccess: ko.observable(''),
            idAdvertisingPartner: ko.observable(''),
            advertisingPartners: ko.observableArray([]),
            brands: ko.observableArray([]),
            hasDynamicFields: ko.observable(false),
            automaticTaskFrequency: ko.observable(''),
            name: ko.observable(''),
            advertiserName: ko.observable(''),
            fields: ko.observableArray([]),
            onClickAddField: function(index, data, event){ huit_neuf_this.onClickAddField(index); },
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
            onChangeAdvertisingPartner: function(data, event){ huit_neuf_this.onChangeAdvertisingPartner(); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        let viewModel = this.getTemplateViewModel();

        return {
            idAdvertisingPartnerAccess: viewModel.idAdvertisingPartnerAccess(),
            idAdvertisingPartner: viewModel.idAdvertisingPartner(),
            name: viewModel.name(),
            automaticTaskFrequency: viewModel.automaticTaskFrequency(),
            advertiserName: viewModel.advertiserName(),
            fields : viewModel.fields()
        };
    }

    public setAdvertisingPartnerAccess(advertisingPartnerAccess): void{
        this.getTemplateViewModel().idAdvertisingPartnerAccess(advertisingPartnerAccess.id);
        this.getTemplateViewModel().idAdvertisingPartner(advertisingPartnerAccess.idAdvertisingPartner);
        this.getTemplateViewModel().automaticTaskFrequency(advertisingPartnerAccess.automaticTaskFrequency);
        this.getTemplateViewModel().name(advertisingPartnerAccess.name);
        this.getTemplateViewModel().advertiserName(advertisingPartnerAccess.advertiserName);
        this.onChangeAdvertisingPartner();
        
        let fields = [];
        if (advertisingPartnerAccess.fields != null)  {
            advertisingPartnerAccess.fields.forEach((field) => {
                let to_add = {
                    field_name: field.field_name, 
                    field_type: field.field_type,
                    display_name: field.display_name,
                    display_value: field.display_value,
                }
                if (field.field_type == 'dynamic') {
                    this.getTemplateViewModel().hasDynamicFields(true);
                    to_add['value'] = ko.observableArray(field.value);
                } else {
                    to_add['value'] = field.value;
                }
                fields.push(to_add);
            })
        }
        this.getTemplateViewModel().fields(fields);
    }

    public setAdvertisingPartners(advertisingPartners): void{
        advertisingPartners.unshift({id: null, name: ''});
        this.getTemplateViewModel().advertisingPartners(advertisingPartners);
    }      
    
    public setBrands(brands): void{
        brands.unshift({id: null, name: ''});
        this.getTemplateViewModel().brands(brands);
    }     

    /**
     * Handles clicking the add field button event.
     */
    protected onClickAddField(index): void{
        this.getTemplateViewModel().fields()[index]['value'].push({name: '', value: ''});
    }      
    
    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{
        this.caller.saveEditadvertisingPartner();
    }


    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{
        this.caller.cancelEditadvertisingPartner();
    }

    protected onChangeAdvertisingPartner(): void{
        this.getTemplateViewModel().advertisingPartners().forEach((advertisingPartner) => {
            if (advertisingPartner.id == this.getTemplateViewModel().idAdvertisingPartner()) {
                
                let fields = [];
                this.getTemplateViewModel().hasDynamicFields(false);
                if (advertisingPartner.fields != null)  {
                    advertisingPartner.fields.forEach((field) => {
                        fields.push({
                                field_name: field.field_name, 
                                field_type: field.field_type, 
                                display_name: field.display_name, 
                                display_value: field.display_value, 
                                value: ko.observableArray(field.value)
                            });

                        if (field.field_type == 'dynamic') {
                            this.getTemplateViewModel().hasDynamicFields(true);
                        }

                    })
                }
                this.getTemplateViewModel().fields(fields);
            }
        })
    }  
}
