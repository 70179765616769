import { templateTypeList_template } from '../components/templates/templateTypeList_template';
import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class templateType_list extends vnModule_knockoutJS{

    protected template: templateTypeList_template;
    protected requestHandler: XMLHttpRequestHandler;

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'templateType_list';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Template Types List");

        this.template = new templateTypeList_template('templateTypeList_template', this.viewModel, this)

        return [this.template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getListData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public paginationOnPageClick(): void{

        this.getListData();
    }

    /**
     * @inheritDoc
     */
    protected reorderTemplates(): void{

        this.getListData();
    }

    /**
     * Fetches the list data.
     */
    public getListData(): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem('list-results'));

        let subViewModel = this.template.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(['data', ko.toJSON(subViewModel)] );

        this.requestHandler = new XMLHttpRequestHandler('/api/template_types/get/', usrReq_params, this);
        this.requestHandler.onReadyStateFunction = this.onDataRequestReturn;
        this.requestHandler.execute();
    }

    /**
     * Returns handler for data request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateList(responseParsed);
                obj.setResultInfo(responseParsed.totalCount, responseParsed.totalPage);
                obj.updatePagination(responseParsed.totalCount);
                obj.loadingOut(obj.template.builtTemplate.children.namedItem('list-results'));
            });
        }
    }

    /**
     * Deletes the requested resource.
     *
     * @param id
     * @protected
     */
    protected delete(id): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem('list-results'));

        this.requestHandler                      = new XMLHttpRequestHandler(`/api/template_types/delete/${id}`, [], this);
        this.requestHandler.mode                 = XMLHttpRequestHandler_requestType.POST;
        this.requestHandler.onReadyStateFunction = this.onDeleteRequestReturn;
        this.requestHandler.execute();
    }

    /**
     * Returns handler for delete request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDeleteRequestReturn(req, obj): Function{

        return function(){

            obj.loadingOut(obj.template.builtTemplate.children.namedItem('list-results'));

            obj.manageResponse(req, obj, function(){

                obj.getListData();
            });
        }
    }

    /**
     * Update the module list using the objects list.
     *
     * @param responseParsed
     */
    protected updateList(responseParsed: any): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem('list-results'));
        this.template.setTemplates(responseParsed.data);
    }

    /**
     * Hook.
     *
     * Redirects to create page.
     */
    protected add(): void{

        page(`/template_types/add`);
    }

    /**
     * Hook.
     *
     * Redirects to create page.
     */
    protected edit(id): void{

        page(`/template_types/edit/${id}`);
    }

    protected setResultInfo(totalCount, totalPage){

        let execTime = Math.round((new Date().getTime() - this.requestHandler.requestStartTime)/1000*100)/100;

        this.template.setResultInfo(totalCount, totalPage, execTime);
    }

    protected updatePagination(totalCount) {

        this.template.updatePagination(totalCount);
    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.delete(data);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

    }

    /**
     * Hook.
     *
     * Cancels data request.
     */
    protected filterSimpleCancel(){

        if(this.requestHandler){

            this.requestHandler.cancelAndReset();
        }
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected filterSimpleOnChange(){

        this.template.resetPageNumber();
        this.template.resetOrderParameters();
        this.getListData();
    }
}