import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";
import {userSection_template} from "./userSection_template";

declare var ko: any;

export class appHeader_template extends vnTemplate{

    protected userSection: userSection_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="w-left">
                <span class="page-title-logo" data-bind="css: brandClass"></span>
                
                <h2 class="page-title logo">
                    <span class="prop-title" data-bind="text: brandName"></span>
                    <span data-bind="text: headerTitle"></span>
                </h2>
                
               
            </div>
            <div class="w-right">
            
                ${this.userSection.getHtmlBuild()}

                
                <div class="dropdown" data-bind="{visible: isSettingsVisible}">
                    <button class="btn large icon i-settings ">Settings</button>
                    <ul class="dropdown-list">
                        <li class="dropdown-item" data-bind="{visible: isUsersVisible}">
                            <a class="dropdown-link icon i-user" href="/users">Users</a>
                        </li>
                        <li class="dropdown-item" data-bind="{visible: isLogsVisible}">
                            <a class="dropdown-link icon i-log" href="/logs">Logs</a>
                        </li>
                        <li class="dropdown-item" data-bind="{visible: isTasksVisible}">
                            <a class="dropdown-link icon i-check" href="/tasks" >Tasks</a>
                        </li>
                        <li class="dropdown-item" data-bind="{visible: isRolesVisible}">
                            <a class="dropdown-link icon i-role" href="/roles">Roles</a>
                        </li>
                    </ul>
                </div>
             
                
        	
                <button data-bind="event: { click: onClickLogOutAction}" class="btn large icon i-logout">log out</button>
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{

        this.userSection = new userSection_template("userHeader", this.getTemplateViewModel(), this.caller);

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "appHeader-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-justify"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        // define the viewmodel data/ functions
        let huit_neuf_this = this;

        return {
            user: ko.observable(null),
            userPicture: ko.observable(""),
            userFullName: ko.observable(""),
            profileLink: ko.observable(""),
            headerTitle: ko.observable(""),
            brandClass: ko.observable(""),
            brandName: ko.observable(""),
            isUserStatsVisible: ko.observable(false),
            isRolesVisible: ko.observable(false),
            isLogsVisible: ko.observable(false),
            isUsersVisible: ko.observable(false),
            isTasksVisible: ko.observable(false),
            isSettingsVisible: ko.observable(false),
            onClickLogOutAction: function (data, event) {
                huit_neuf_this.onClickLogOutAction();
            },
        };
    }

    public setUserImageSrc(src: string): void{

        this.viewModel[this.idElement].userPicture(src);
    }

    public setUserFullName(fullName: string): void{

        this.viewModel[this.idElement].userFullName(fullName);
    }

    public setProfilePageLink(link: string): void{

        this.viewModel[this.idElement].profileLink(link);
    }

    public setIsUserStatsVisible(isUserStatsVisible: boolean): void{

        this.viewModel[this.idElement].isUserStatsVisible(isUserStatsVisible);
    }

    public setIsLogsVisible(isLogsVisible: boolean): void{

        this.getTemplateViewModel().isLogsVisible(isLogsVisible);
    }

    public setIsUsersVisible(isUsersVisible: boolean): void{

        this.getTemplateViewModel().isUsersVisible(isUsersVisible);
    }

    public setHeaderTitle(title: string){

        this.getTemplateViewModel().headerTitle(title);
    }

    public setBrandClass(brandClass: string) {
        this.getTemplateViewModel().brandClass(brandClass);
    }

    public setBrandName(brandName: string) {
        this.getTemplateViewModel().brandName(brandName);
    }

    public setIsRolesVisible(isRolesVisible: boolean): void{

        this.viewModel[this.idElement].isRolesVisible(isRolesVisible);
    }

    protected onClickLogOutAction(){

        this.caller.logOut();
    }

    //
    // set the user display
    setUser(user: any){
        this.userSection.setUser(user);
    }
}
