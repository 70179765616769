import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import {header} from "./header";
import {revenueSheet_template} from "../components/templates/revenueSheet_template";

declare var ko: any;
declare var page: any;

export class revenueSheet extends vnModule_knockoutJS{

    protected revenueSheet_template: revenueSheet_template;
    protected requestHandler: XMLHttpRequestHandler;

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'revenueSheet';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName('Header');
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle('Revenue Sheet');

        this.revenueSheet_template = new revenueSheet_template('revenueSheet_template', this.viewModel, this)

        return [this.revenueSheet_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.revenueSheet_template.buildDatePickerCalendar();
        this.getListFormData();
    }

    /**
     * @inheritDoc
     */
    protected reorderAdsSetups(): void{

        this.getListData();
    }

    //
    // Requests data for the filters.
    protected getListFormData(): void{

        this.loadingIn(this.revenueSheet_template.builtTemplate.children.namedItem('list-results'));
        // this.revenueSheet_template.hideExportButton();
        this.requestHandler = new XMLHttpRequestHandler(`/api/revenue_sheet/references`, [], this);
        this.requestHandler.onReadyStateFunction = this.onFilterRequestReturn;
        this.requestHandler.execute();
    }

    //
    // Returns handler for filter request.
    protected onFilterRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateFilters(responseParsed);
                obj.getListData();
            });
        }
    }

    //
    // Fetches the list data.
    public getListData(): void{

        this.loadingIn(this.revenueSheet_template.builtTemplate.children.namedItem('list-results'));
        // this.revenueSheet_template.hideExportButton();

        if(this.requestHandler){

            this.requestHandler.cancelAndReset();
        }

        let subViewModel = this.revenueSheet_template.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(['data', ko.toJSON(subViewModel)] );

        this.requestHandler = new XMLHttpRequestHandler(`/api/revenue_sheet/get`, usrReq_params, this);
        this.requestHandler.onReadyStateFunction = this.onDataRequestReturn;
        this.requestHandler.execute();
    }

    //
    // Returns handler for data request.
    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateList(responseParsed);
                obj.loadingOut(obj.revenueSheet_template.builtTemplate.children.namedItem('list-results'));
            });
        }
    }

    //
    // Update the module list using the objects list.
    protected updateList(responseParsed: any): void{

        this.loadingIn(this.revenueSheet_template.builtTemplate.children.namedItem('list-results'));

        let data                            = responseParsed;

        this.revenueSheet_template.setTableData(data);
    }


    //
    // Update the filters.
    protected updateFilters(responseParsed: any): void{

        this.revenueSheet_template.setBrandFilterItems(responseParsed.brands);
        this.revenueSheet_template.setViewModeFilter(responseParsed.viewModes);
        this.revenueSheet_template.setCurrencyFilter(responseParsed.currencies);
    }

    //
    // Hook.
    // Initiates data refresh.
    public filterDropdownOnChange(): void{
        this.revenueSheet_template.setViewMode();
        this.getListData();
    }

    //
    // Hook.
    // Cancels data request.
    protected filterSimpleCancel(){

        if(this.requestHandler){

            this.requestHandler.cancelAndReset();
        }
    }

    //
    // Hook.
    // Initiates data refresh.
    protected filterSimpleOnChange(){

        this.getListData();
    }

    //
    // Hook.
    // Initiates data refresh.
    protected filterMultipleSelectorOnChange(){

        this.getListData();
    }

    //
    // Hook.
    // Initiates data refresh.
    protected filterSingleDateOnChange(){

        this.getListData();
    }


    protected filterDateOnChange(){

        this.getListData();
    }

    // /**
    //  * Fetches export data
    //  */
    // public getExportData(): void{

    //     this.loadingIn(this.revenueSheet_template.builtTemplate.children.namedItem("list-results"));

    //     this.requestHandler.cancelAndReset();

    //     let subViewModel = this.revenueSheet_template.getViewModelForRequest();

    //     let usrReq_params: Array<Array<string>> = [];
    //     usrReq_params.push(["data", ko.toJSON(subViewModel)] );
    //     this.requestHandler = new XMLHttpRequestHandler('/api/revenue_sheet/get/',usrReq_params, this);

    //     this.requestHandler.onReadyStateFunction = this.onGetExportData;
    //     this.requestHandler.execute();
    // }

    // protected onGetExportData(req, obj){

    //     return function () {

    //         obj.manageResponse(req, obj, function () {

    //             let responseParsed = JSON.parse(req.request.response);

    //             if (req.request.status == 200) {

    //                 obj.revenueSheet_template.setExportData(responseParsed);
    //                 obj.revenueSheet_template.exportData();
    //             }
    //         });

    //         obj.loadingOut(obj.revenueSheet_template.builtTemplate.children.namedItem("list-results"));
    //     }
    // }
}