
declare var ko: any;

export abstract class vnTemplate{

    // MEMBER VARIABLES
    public viewModel: any;
    public idElement: string;
    public template: HTMLScriptElement;
    public caller: any;
    public builtTemplate: HTMLElement;

    // CONSTRUCTORS
    constructor(id: string, viewModel: any, caller) {
        // update member variable
            this.init(id, viewModel, caller);


        // Insert template if not already there
            if( !document.getElementById(this.template.id) ){
                let h = document.getElementsByTagName("head");
                h[0].appendChild(this.template);
            }
    }

    //
    // @return: the viewModel properties for this template
    protected abstract buildViewModel(): any;

    //
    // @return: the view sub part of the viewModel that is specific to the template
    public getTemplateViewModel(): any{
        return this.viewModel[this.idElement];
    }
    //
    // @return: the template unique name
    protected abstract getTemplateName(): string;

    // @return: the template source html
    protected abstract getTemplateHtml(): string;

    //
    // returns the classes to add to the main container
    // @ default : none
    protected getMainElementClasses(): Array<string>{

        return [];
    }

    //
    // returns the classes to add to the main container
    // @ default : none
    protected getMainElementType(): string{

        return "div";
    }

    //
    // returns an array of partial templates to be injected in the main templates
    protected initPartial():void{
    }
    //
    // Initialisation of the template members
    // Also creates the template container script tag
    protected init(id: string, viewModel: any, caller: any): void{

        this.idElement = id;
        this.viewModel = viewModel;
        this.caller = caller;
        this.viewModel[this.idElement] = this.buildViewModel();
        this.initPartial();

        // define the knocoutjs template
            this.template = document.createElement("script");
            this.template.type = "text/html";
            this.template.id = this.getTemplateName();
            this.template.innerHTML = this.getTemplateHtml();
            this.includeTemplate();
    }

    //
    // Insert the template script tag into the head of the page
    // This code will only insert each template 1 time.
    public includeTemplate(): void{

        if( !document.getElementById(this.template.id) ){
            let h = document.getElementsByTagName("head");
            h[0].appendChild(this.template);
        }
    }

    //
    // @return: the built HTMLElement container of the template data
    // @classes: set of extra classes to add to this container; default value is an empty array.
    public build(classesToAdd: Array<string> = []): HTMLElement{

        let container = document.createElement(this.getMainElementType());
        container.id = this.idElement;

        // set main element classes
        for(let c of this.getMainElementClasses() ){
            if( c != "") {
                container.classList.add(c);
            }
        }

        // set added classes
        for(let c of classesToAdd ){
            if( c != ""){
                container.classList.add(c);
            }
        }
        container.setAttribute("data-bind", "template: { name: '"+this.getTemplateName()+"', data: "+this.idElement+" }" );

        this.builtTemplate = container;

        return container;
    }

    protected initPermissions(permissionsToAdd: Array<string>): Array<object>{
        let permissions = [], values = [];
        values['read'] = values['write'] = values['update'] = values['delete'] = false;
        for(let permission of permissionsToAdd){
            permissions[permission] = values;
        }
        return permissions;
    }

    public getHtmlBuild(): string{
        let div = document.createElement('div');
        div.appendChild(this.build());
        return div.innerHTML;
    }

    public getViewModelForRequest(){
        return this.getTemplateViewModel();
    }
    public activateLoading(){
        this.builtTemplate.classList.add("loading");
    }
    public deactivateLoading(){
        this.builtTemplate.classList.remove("loading");
    }
}
