import { adDisplayType_template } from '../components/templates/adDisplayType_template';
import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler, XMLHttpRequestHandler_requestType } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import {pagination_template} from "../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/pagination_template";
import {header} from "./header";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";

declare var ko: any;
declare var page: any;

export class adDisplayType extends vnModule_knockoutJS{

    protected adDisplayType_template: adDisplayType_template;
    protected requestHandler: XMLHttpRequestHandler;
    protected pagination: pagination_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'adDisplayType';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Ad Display Type");

        this.adDisplayType_template = new adDisplayType_template('adDisplayType', this.viewModel, this)

        return [this.adDisplayType_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getListFormData();
    }

    /**
     * Requests data for the filters.
     */
    protected getListFormData(): void{

        this.loadingIn(this.adDisplayType_template.builtTemplate.children.namedItem('list-results'));

        this.requestHandler = new XMLHttpRequestHandler(`/api/ad_display_type/references/`, [], this);
        this.requestHandler.onReadyStateFunction = this.onFilterRequestReturn;
        this.requestHandler.execute();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public paginationOnPageClick(): void{

        this.getListData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected dataReorder(){

        this.getListData();
    }

    /**
     * Hook.
     *
     * Cancels data request.
     */
    protected filterSimpleCancel(){

        if(this.requestHandler){

            this.requestHandler.cancelAndReset();
        }
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected filterSimpleOnChange(){

        this.viewModel.adDisplayType.pagination.pageNumber(1);
        this.getListData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public filterDropdownOnChange(elem): void{

        if(elem != "updateDisplayType") {

            this.viewModel.adDisplayType.pagination.pageNumber(1);
            this.getListData();
        }
    }

    /**
     * Fetches the list data.
     */
    public getListData(): void{

        this.loadingIn(this.adDisplayType_template.builtTemplate.children.namedItem("list-results"));

        if( this.requestHandler ){
            this.requestHandler.cancelAndReset();
        }

        let parameters: Array<Array<string>> = [];
        parameters.push(["data", ko.toJSON(this.adDisplayType_template.getViewModelForRequest())]);

        this.requestHandler = new XMLHttpRequestHandler(`api/ad_display_type/get/`, parameters, this);
        this.requestHandler.onReadyStateFunction = this.onDataRequestReturn;
        this.requestHandler.execute();
    }

    /**
     * Returns handler for filter request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onFilterRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response || '{}');

                obj.updateFilters(responseParsed);
                obj.getListData();
            });
        }
    }

    /**
     * Returns handler for data request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response || '{}');
                console.log(responseParsed);
                obj.updateList(responseParsed);
                obj.loadingOut(obj.adDisplayType_template.builtTemplate.children.namedItem('list-results'));
            });
        }
    }

    /**
     * Update the module list using the objects list.
     *
     * @param responseParsed
     */
    protected updateList(responseParsed: any){

        let execTime = Math.round((new Date().getTime() - this.requestHandler.requestStartTime) / 1000 * 100) / 100;

        this.adDisplayType_template.setResultInfo(responseParsed.totalCount, responseParsed.totalPage, execTime);
        this.adDisplayType_template.setAdUnits(responseParsed.data);

        // Update the paginator.
        this.viewModel.adDisplayType.pagination.itemCount(responseParsed.totalCount);
    }

    /**
     * Update the filters.
     *
     * @param responseParsed
     */
    protected updateFilters(responseParsed: any): void{

        this.adDisplayType_template.setDimensionFilterItems(responseParsed.dimensions);
        this.adDisplayType_template.setTypeFilterItems(responseParsed.adTypes);
        this.adDisplayType_template.setTemplateFilterItems(responseParsed.adTemplates);
        this.adDisplayType_template.setStatusFilterItems(responseParsed.statuses);
        this.adDisplayType_template.setBidderTypeFilterItems(responseParsed.bidderTypes);
        this.adDisplayType_template.setBrandsFilterItems(responseParsed.brands);
        this.adDisplayType_template.setDisplayTypeFilterItems(responseParsed.displayTypes);
        this.adDisplayType_template.setUpdateDisplayType(responseParsed.displayTypes);
    }


    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected filterMultipleSelectorOnChange(){

        this.viewModel.adDisplayType.pagination.pageNumber(1);
        this.getListData();
    }

    protected saveAdDisplayTypes(adUnitsToSave, displayType): void{

        this.loadingIn(this.adDisplayType_template.builtTemplate.children.namedItem("list-results"));

        let usrReq_params: Array<Array<string>> = [];
        let data = {adUnits: adUnitsToSave, idDisplayType: displayType};
        usrReq_params.push(["data", ko.toJSON(data)] );

        this.requestHandler                         = new XMLHttpRequestHandler(`/api/ad_display_type/save/`, usrReq_params, this);
        this.requestHandler.mode                    = XMLHttpRequestHandler_requestType.POST;
        this.requestHandler.onReadyStateFunction    = this.onSaveRequestReturn;
        this.requestHandler.execute();
    }

    /**
     * Returns handler for filter request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onSaveRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.loadingOut(obj.adDisplayType_template.builtTemplate.children.namedItem('list-results'));

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'AdUnits updated successfully.');
                obj.getVnAppZone().showNotificationToModule();
                obj.adDisplayType_template.getTemplateViewModel().setAllChecked(false);

                obj.getListData();
            });
        }
    }
}

