import { vnTemplate } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate';
import { filterDropdown_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDropdown_template';
import { resultsInfo_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/resultsInfo_template';
import { filterSimple_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterSimple_template";
import { multipleSelector_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/multipleSelector_template";
import { filterDate_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDate_template";
import { simpleListHeader_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/simpleListHeader_template";
import { exportDataCsv } from "../../../vendor/valnet/valnet_jslib/vnHelpers/exportDataCsv";
declare var ko: any;


export class adRevenue_template extends vnTemplate {

    protected deviceFilter: multipleSelector_template;
    protected templateFilter: multipleSelector_template;
    protected networkCategoryFilter: multipleSelector_template;
    protected contentTypeFilter: multipleSelector_template;
    protected geoFilter: multipleSelector_template;
    protected sourceFilter: multipleSelector_template;
    protected operatingSystemsFilter: multipleSelector_template;
    protected placementFilter: multipleSelector_template;
    protected brandFilter: filterDropdown_template;
    protected adDimensionFilter: filterDropdown_template;
    protected resultsInfo: resultsInfo_template;
    protected datesFilter: filterDate_template;
    protected headers: simpleListHeader_template;
    protected headersArrayForFields = [];
    protected exportDataCsv: exportDataCsv;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="sub-header">
                <div class="w-justify">
                    <div class="w-left">
                        <div>
                            <label class="form-label">Brand</label>
                            ${this.brandFilter.getHtmlBuild()}
                        </div>   
                        <div>
                            <label class="form-label">aD Dimension</label>
                            ${this.adDimensionFilter.getHtmlBuild()}
                        </div>   
                        <div>
                            <label class="form-label form-label-box multi">Template</label>
                            ${this.templateFilter.getHtmlBuild()}
                        </div>
                        <div>  
                            <label class="form-label form-label-box multi">Source</label>
                            ${this.sourceFilter.getHtmlBuild()}
                        </div> 
                         <div>   
                            <label class="form-label form-label-box multi">Geo</label>
                            ${this.geoFilter.getHtmlBuild()}
                        </div>
                    <!-- 
                        <div>
                            <label class="form-label form-label-box multi">Network Category</label>
                            ${this.networkCategoryFilter.getHtmlBuild()}
                        </div>
                       -->
                       <!--
                        <div>
                            <label class="form-label form-label-box multi">Content Type</label>
                            ${this.contentTypeFilter.getHtmlBuild()}
                        </div>
                        --> 
                        <div>
                            <label class="form-label form-label-box multi">Device</label>
                            ${this.deviceFilter.getHtmlBuild()}
                        </div>
                        <!--
                        <div>
                            <label class="form-label form-label-box multi">Operating System</label>
                            ${this.operatingSystemsFilter.getHtmlBuild()}
                        </div>
                        -->   
                        <div>
                            <label class="form-label form-label-box multi"></label>
                            ${this.datesFilter.getHtmlBuild()}
                        </div>
                                        
                    </div>
                </div>
            </div>
            <div class="w-filters-selected">
                <div id="filters-selected" class="filters-selected">
                    <div id="selected-devices"></div>
                    <div id="selected-templates"></div>
<!--                    <div id="selected-networkCategories"></div>-->
<!--                    <div id="selected-contentTypes"></div>-->
                    <div id="selected-geos"></div>
                    <div id="selected-sources"></div>
<!--                    <div id="selected-operatingSystems"></div>-->
                   
                </div>
            </div>      
            
            <div class="list-header w-justify">
                 <div class="w-left"></div> 
                 <div class="w-center">
                    <h2 class="section-title icon i-google">Ad Revenue</h2>
                </div>
                <div class="w-right">
                    <button style="margin-bottom: 0" class="btn icon i-export" type="button" data-bind="event: {click: onExportData}">Export</button>

                </div>
            </div>
            <div class="w-list-results" id="list-results" data-bind="visible: isTableVisible">
           
                <table class="list-results" style="border: 0">
                    <!-- ${this.resultsInfo.getHtmlBuild()} -->
                    <thead>
                  
                        <tr class="list-header-section">
                            <th style="background-color: #2c3342"></th>
                            <!-- ko foreach: adDimensionHeaders -->
                                <th class="w-header-ad" data-bind="attr: {colspan: name === 'sponsored' ? 2 : 4}">                                
                                    <div class="header-ad">
                                        <div class="header-ad-type"><span class="header-ad-label" data-bind="text: name"></span></div>
                                        <div class="header-ad-revenue"><span class="header-ad-label">Total Revenue</span><span class="header-ad-value" data-bind="text: Number(totalRevenue).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '$'"></span></div>
                                        <div class="header-ad-avg"><span class="header-ad-label">Avg Rev/Day</span><span class="header-ad-value" data-bind="text: Number(avgPerDay).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '$'"></span></div>
                                        <div class="header-ad-revenue-percentage"><span class="header-ad-label">Revenue</span><span class="header-ad-value" data-bind="text: Number(revPerc).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%'"></span></div>
                                    </div>
                                </th>
                            <!-- /ko -->
                        </tr>  
                    </thead>
                    <thead>
                        <tr style="background: transparent; height: 3rem; ">
                            <th colspan="18"></th>
                        </tr>
                    </thead>
                    <thead>
                        <tr class="list-header-section secondary">
                            <th></th>
                            <!-- ko foreach: adDimensionHeaders -->
                                <!-- ko ifnot: name === 'sponsored' -->
    
                                    <th class="secondary-th align-right"><span>Rev</span></th>
                                    <th class="secondary-th align-right"><span>Imp</span></th>
                                    <th class="secondary-th align-right"><span>CPM</span></th>
                                    <th class="secondary-th align-right"><span>%</span></th>
                                <!-- /ko -->
                                
                                <!-- ko if: name === 'sponsored' -->
                                    <th class="secondary-th align-right"><span>Rev</span></th>
                                    <th class="secondary-th align-right"><span>%</span></th>
                                <!-- /ko -->  
                                      
                            <!-- /ko -->
                        </tr>
                    </thead>
                    
                    <tbody data-bind="foreach: tableData">  
                            <tr>
                                <td data-bind="attr: {colspan:1}"><span data-bind="text: date"></span></td>
                               <!-- ko foreach: revenueData -->
                               
                                    <!-- ko ifnot: adDimension === 'sponsored' -->
                                        <td class="align-right"><span data-bind="text: revenue === '-' ? '-' : Number(revenue).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})"></span></td>
                                        <td class="align-right"><span data-bind="text: impressions === '-' ? '-' : Number(impressions).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})"></span></td>
                                        <td class="align-right"><span data-bind="text: CPM === '-' ? '-' : Number(CPM).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})"></span></td>
                                        <td class="align-right"><span data-bind="text: percentage === '-' ? '-' : Number(percentage).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})"></span></td>
                                    <!-- /ko -->
                            
                                    <!-- ko if: adDimension === 'sponsored' -->
                                        <td class="align-right"><span data-bind="text: revenue === '-' ? '-' : Number(revenue).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})"></span></td>
                                        <td class="align-right"><span data-bind="text: percentage === '-' ? '-' : Number(percentage).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})"></span></td>
                                    <!-- /ko -->
                                    
                                <!-- /ko -->
                           </tr>
                    </tbody>
                </table>
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{

        this.deviceFilter = new multipleSelector_template('deviceFilter', this.getTemplateViewModel(), this.caller);
        this.deviceFilter.setFilterContainerClass('margin-neg');
        this.deviceFilter.setTagContainerId('selected-devices');
        this.deviceFilter.setTagContainerCaption('Selected Devices:');

        this.templateFilter = new multipleSelector_template('templateFilter', this.getTemplateViewModel(), this.caller);
        this.templateFilter.setFilterContainerClass('margin-neg');
        this.templateFilter.setTagContainerId('selected-templates');
        this.templateFilter.setTagContainerCaption('Selected Templates:');

        this.networkCategoryFilter = new multipleSelector_template('networkCategoryFilter', this.getTemplateViewModel(), this.caller);
        this.networkCategoryFilter.setFilterContainerClass('margin-neg');
        this.networkCategoryFilter.setTagContainerId('selected-networkCategories');
        this.networkCategoryFilter.setTagContainerCaption('Selected Network Categories:');

        this.contentTypeFilter  = new multipleSelector_template('contentTypeFilter', this.getTemplateViewModel(), this.caller);
        this.contentTypeFilter.setFilterContainerClass('margin-neg');
        this.contentTypeFilter.setTagContainerId('selected-contentTypes');
        this.contentTypeFilter.setTagContainerCaption('Selected Content Types:');

        this.geoFilter = new multipleSelector_template('geoFilter', this.getTemplateViewModel(), this.caller);
        this.geoFilter.setFilterContainerClass('margin-neg');
        this.geoFilter.setTagContainerId('selected-geos');
        this.geoFilter.setTagContainerCaption('Selected Geos:');

        this.sourceFilter = new multipleSelector_template('sourceFilter', this.getTemplateViewModel(), this.caller);
        this.sourceFilter.setFilterContainerClass('margin-neg');
        this.sourceFilter.setTagContainerId('selected-sources');
        this.sourceFilter.setTagContainerCaption('Selected Sources:');

        this.operatingSystemsFilter = new multipleSelector_template('operatingSystemsFilter', this.getTemplateViewModel(), this.caller);
        this.operatingSystemsFilter.setFilterContainerClass('margin-neg');
        this.operatingSystemsFilter.setTagContainerId('selected-operatingSystems');
        this.operatingSystemsFilter.setTagContainerCaption('Selected Operating Systems:');

        let dateStart = new Date();
        dateStart.setDate(dateStart.getDate()-30);

        let dateEnd = new Date();
        dateEnd.setDate(dateEnd.getDate()-1);

        this.datesFilter = new filterDate_template("datesFilter", this.getTemplateViewModel(), this.caller);
        this.datesFilter.setStartPickerId('startDatePicker');
        this.datesFilter.setEndPickerId('endDatePicker');
        this.datesFilter.setDateRange(dateStart, dateEnd);

        this.brandFilter = new filterDropdown_template('brandFilter', this.getTemplateViewModel(), this.caller);
        // this.brandFilter.setSelectedOption('none');

        this.adDimensionFilter = new filterDropdown_template('adDimensionFilter', this.getTemplateViewModel(), this.caller);
        // this.adDimensionFilter.setSelectedOption('none');

        this.resultsInfo = new resultsInfo_template('resultsInfo', this.getTemplateViewModel(), this.caller);

        this.headers = new simpleListHeader_template("listHeader", this.getTemplateViewModel(), this.caller, []);

        this.exportDataCsv = new exportDataCsv();

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return 'adRevenue_template';
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return [];
    }

    /**
     * @inheritDoc
     */
    public activateLoading(): void{

        this.getTemplateViewModel().isLoading(1);
    }

    /**
     * @inheritDoc
     */
    public deactivateLoading(): void{

        this.getTemplateViewModel().isLoading(0);
    }

    /**
     * @inheritDoc
     */
    public buildViewModel(): Object{

        let self = this;

        return {
            orderBy: ko.observable('revenue'),
            orderByDirection: ko.observable('DESC'),
            orderByAndDirection: ko.observable('revenue_DESC'),
            data: ko.observableArray([]),
            tableData: ko.observableArray([]),
            isTableVisible: ko.observable(true),
            headers: ko.observableArray([]),
            adDimensionHeaders: ko.observable([]),
            adDimension: ko.observable(""),
            exportButtonHidden: ko.observable(1),
            exportData: ko.observableArray([]),
            onExportData : function(){ self.onExportData(); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            orderBy: this.getTemplateViewModel().orderBy(),
            orderByDirection: this.getTemplateViewModel().orderByDirection(),
            filters: {
                selectedBrand: this.brandFilter.getTemplateViewModel().selectedOption(),
                selectedAdDimension: this.adDimensionFilter.getTemplateViewModel().selectedOption(),
                selectedTemplates: this.templateFilter.getTemplateViewModel().selectedItems(),
                selectedSources: this.sourceFilter.getTemplateViewModel().selectedItems(),
                selectedGeos: this.geoFilter.getTemplateViewModel().selectedItems(),
                selectedNetworkCategories: this.networkCategoryFilter.getTemplateViewModel().selectedItems(),
                selectedContentTypes: this.contentTypeFilter.getTemplateViewModel().selectedItems(),
                selectedDevices: this.deviceFilter.getTemplateViewModel().selectedItems(),
                selectedOperatingSystems: this.operatingSystemsFilter.getTemplateViewModel().selectedItems(),
                startDate: this.getTemplateViewModel().datesFilter.startDate,
                endDate: this.getTemplateViewModel().datesFilter.endDate,
            },
        };
    }

    public buildDatePickerCalendar(){

        this.datesFilter.buildCalendar();
    }

    public setDeviceFilterItems(devices): void{

        let devicesList = [];

        for(let device of devices){
            devicesList.push(device);
        }

        this.setMultiSelectFilter(this.deviceFilter, devicesList)
    }

    public setTemplateFilterItems(templates): void{

        this.setMultiSelectFilter(this.templateFilter, templates);
    }

    public setNetworkCategoryFilterItems(networkCategories): void{

        this.setMultiSelectFilter(this.networkCategoryFilter, networkCategories);
    }

    public setContentTypeFilterItems(contentTypes): void{

        this.setMultiSelectFilter(this.contentTypeFilter, contentTypes);
    }

    public setGeoFilterItems(geos): void{

        this.setMultiSelectFilter(this.geoFilter, geos);
    }

    public setSourceFilterItems(sources): void{

        // Add balance
        sources.unshift({'id' : 0, 'name' : 'balance'});

        this.setMultiSelectFilter(this.sourceFilter, sources);
    }

    public setOperatingSystemsFilterItems(operatingSystems): void{

        this.setMultiSelectFilter(this.operatingSystemsFilter, operatingSystems);
    }

    public setPlacementFilterItems(operatingSystems): void{

        this.setMultiSelectFilter(this.placementFilter, operatingSystems);
    }

    public setBrandFilterItems(brands): void{

        this.brandFilter.setOptions(brands);
    }

    public setAdDimensionFilterItems(adDimensions): void{

        this.adDimensionFilter.setOptions(adDimensions);
    }

    public setResultInfo(nbResults, nbPages, execTime){

        this.resultsInfo.setExecutionTime(execTime);
        this.resultsInfo.setNbResults(nbResults);
        this.resultsInfo.setNbPages(nbPages);
    }

    //
    // Sets data for a multi select filter.
    public setMultiSelectFilter(filter, data: Array<{ id: string, name: string }>): void{

        filter.setItems(data);
    }

    public setHeaders(headers: Array<object> = [], adDimensionHeaders: Array<object>, adDimension: string){

        this.getTemplateViewModel().headers(headers);
        this.getTemplateViewModel().adDimensionHeaders(adDimensionHeaders);
        this.getTemplateViewModel().adDimension(adDimension);

        // this.buildHeadersArray();
    }

    //
    // Build headers array.
    private buildHeadersArray(){

        let headers = this.getTemplateViewModel().headers();

        let adDimensionHeaders = headers['adDimensionHeaders']

        let headersArrayForFields = [{
            headerName: 'Date',
            fieldName: null,
                'subHeaders': [
                { headerName: '', fieldName: null, "subHeaders": [] },
            ],
        }];

        for(let header of headers){

            headersArrayForFields.push({ headerName: header.name, fieldName: null, 'subHeaders': [
                    { headerName: 'Total $', fieldName: null, "subHeaders": [] },
                    { headerName: 'Avg $/Day', fieldName: null, "subHeaders": [] },
                    { headerName: '%', fieldName: null, "subHeaders": [] },
                ] });
        }

        this.headersArrayForFields = headersArrayForFields;

        this.headers.setHeadersLevels(this.headersArrayForFields);
    }

    public setTableData(tableData) {

        this.getTemplateViewModel().tableData(tableData);
        this.showExportButton();
    }

    public hideExportButton(){

        this.getTemplateViewModel().exportButtonHidden(1);
    }

    public showExportButton(){

        this.getTemplateViewModel().exportButtonHidden(0);
    }

    private onExportData(){

        this.caller.getExportData();
    }

    public setExportData(exportData){


        let exportDataView = [];
        let adDimensionHeaders = this.getTemplateViewModel().adDimensionHeaders();
        let tableData = this.getTemplateViewModel().tableData();

        for (let data of tableData){

            let exportDataRow = [];

            exportDataRow["Date"] = data.date;

            let adDimension = data.revenueData;

            for (let dimension of adDimension){

                exportDataRow[dimension.adDimension + "_revenue"] = dimension.revenue === '-' ? '-' : Number(dimension.revenue).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                exportDataRow[dimension.adDimension + "_impressions"] = dimension.impressions === '-' ? '-' : Number(dimension.impressions).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                exportDataRow[dimension.adDimension + "_CPM"] = dimension.CPM === '-' ? '-' : Number(dimension.CPM).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2});
                exportDataRow[dimension.adDimension + "_percentage"] = dimension.percentage === '-' ? '-' : Number(dimension.percentage).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2});

            }

            exportDataView.push(exportDataRow);
        }

        this.getTemplateViewModel().exportData(exportDataView);
    }

    private exportData(obj){

        let fileName = "AdRevenue_"  + this.brandFilter.getTemplateViewModel().selectedOption() + this.datesFilter.getTemplateViewModel().startDate() + "_" + this.datesFilter.getTemplateViewModel().endDate()+ ".csv";
        this.exportDataCsv.exportToCsv(fileName, this.getTemplateViewModel().exportData());
    }

}