import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import { adsSetupEdit_template } from "../components/templates/adsSetupEdit_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class adsSetup_edit extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected adsSetupEdit_template: adsSetupEdit_template;

    static devices : Array<{id : string, name : string}> = [
        { "id": "mobile",  "name": "Mobile"},
        { "id": "desktop",  "name": "Desktop"},
    ];

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return "ads_setup_edit";
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Ads Setup Edit");

        // create the users form
        this.adsSetupEdit_template = new adsSetupEdit_template("adsSetupEdit_template", this.viewModel, this);

        return [this.adsSetupEdit_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getEditFormData();
    }

    /**
     * Hook.
     *
     * Saves the edits to a user.
     */
    protected saveEditAdsSetup(): void{

        this.save();
    }

    /**
     * Hook.
     *
     * Cancels the edits to a user.
     */
    protected cancelEditAdsSetup(){

        this.loadingIn(this.adsSetupEdit_template.builtTemplate);

        this.releaseLock();
        let idBrand = this.getVnAppZone().app.params.idBrand;

        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "Ads setup action canceled!");

        page.redirect(`/brand/${idBrand}/ads_setups`);
    }

    /**
     * Requests a lock release.
     */
    protected releaseLock(): void{

        let formReq_params: Array<Array<string>> = [];

        // Request a lock release.
        this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ads_setup/${this.getVnAppZone().app.params.id}/release_lock/`, formReq_params, this);
        this.XHRHdl.execute();
    }

    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditFormData(): void{

        this.loadingIn(this.adsSetupEdit_template.builtTemplate);

        this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ads_setup/references/`, [], this);
        this.XHRHdl.onReadyStateFunction = this.onEditFormDataRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for edit form request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onEditFormDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditFormData(responseParsed);

                obj.loadingOut(obj.adsSetupEdit_template.builtTemplate);

                obj.getEditData();
            });
        }
    }

    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditData(takeover: boolean = false): void{

        let id = this.getVnAppZone().app.params.id || null;
        let idBrand = this.getVnAppZone().app.params.idBrand;

        if(id) {

            this.loadingIn(this.adsSetupEdit_template.builtTemplate);

            this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${idBrand}/ads_setup/get/${id}/`, [['takeover', (takeover) ? '1' : '0']], this);
            this.XHRHdl.onReadyStateFunction = this.onEditDataRequestReturn;
            this.XHRHdl.execute();
        }
    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onEditDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(request, object){

                let responseParsed = JSON.parse(req.request.response);

                object.updateEditData(responseParsed);
                object.loadingOut(obj.adsSetupEdit_template.builtTemplate);
            });
        }
    }

    /**
     * Update the filters.
     *
     * @param responseParsed
     */
    protected updateEditFormData(responseParsed: any): void{

        this.adsSetupEdit_template.setTemplateOptionItems(responseParsed.templates);
        this.adsSetupEdit_template.setNetworkCategoryOptionItems(responseParsed.networkCategories);
        this.adsSetupEdit_template.setContentTypeOptionItems(responseParsed.contentTypes);
        this.adsSetupEdit_template.setSspGroupsOptionItems(responseParsed.sspGroups);
        this.adsSetupEdit_template.getTemplateViewModel().allSources(responseParsed.sources);
        this.adsSetupEdit_template.getTemplateViewModel().allGeos(responseParsed.geos);
        this.adsSetupEdit_template.setDevicesOptionItems(adsSetup_edit.devices);
        this.adsSetupEdit_template.setAdsSetupTagOptionItems(responseParsed.adsSetupTags);
        this.setGeoGroupsOptions(responseParsed.geoGroups);
        this.adsSetupEdit_template.getTemplateViewModel().groups(responseParsed.groups);
        this.adsSetupEdit_template.setGroupLabelOptionItems(responseParsed.groupLabels);
        this.adsSetupEdit_template.getTemplateViewModel().defaultGroup(responseParsed.defaultGroup);
        this.adsSetupEdit_template.getTemplateViewModel().defaulIdGroupLabel(responseParsed.defaulIdGroupLabel);
    }

    /**
     * Updates the form elements.
     *
     */
    protected updateEditData(responseParsed: any){

        this.setAdsSetup(responseParsed.data);
    }

    protected setAdsSetup(adsSetup){

        let idTemplate        = (adsSetup.template) ? adsSetup.template.id : adsSetup.idTemplate;
        let idNetworkCatgeory = (adsSetup.networkCategory) ? adsSetup.networkCategory.id : adsSetup.idNetworkCategory;
        let idContentType     = (adsSetup.contentType) ? adsSetup.contentType.id : adsSetup.idContentType;
        let idGeoGroup        = (adsSetup.idGeoGroup) ? adsSetup.idGeoGroup : null;
        let idSspGroup        = (adsSetup.idSspGroup) ? adsSetup.idSspGroup : null;
        let idAdsSetupTag     = (adsSetup.adsSetupTag && adsSetup.adsSetupTag.id) ? adsSetup.adsSetupTag.id : adsSetup.idAdsSetupTag;
        let idGroup           = (adsSetup.idGroup) ? adsSetup.idGroup : null;
        let groupName         = (adsSetup.groupName) ? adsSetup.groupName : this.adsSetupEdit_template.getTemplateViewModel().defaultGroup();
        let idGroupLabel      = (adsSetup.idGroupLabel) ? adsSetup.idGroupLabel : null;
        let groupNameLabel    = (adsSetup.groupNameLabel) ? adsSetup.groupNameLabel : this.adsSetupEdit_template.getTemplateViewModel().defaulIdGroupLabel();

        this.adsSetupEdit_template.getTemplateViewModel().idAdsSetup(adsSetup.id);
        this.adsSetupEdit_template.getTemplateViewModel().isActive(adsSetup.isActive ? '1' : '0');
        this.adsSetupEdit_template.getTemplateViewModel().name(adsSetup.name);
        this.adsSetupEdit_template.getTemplateViewModel().route(adsSetup.route);
        this.adsSetupEdit_template.getTemplateViewModel().ratio(adsSetup.ratio);
        this.adsSetupEdit_template.getTemplateViewModel().templateOption.setSelectedOption(idTemplate);
        this.adsSetupEdit_template.getTemplateViewModel().networkCategoryOption.setSelectedOption(idNetworkCatgeory);
        this.adsSetupEdit_template.getTemplateViewModel().contentTypeOption.setSelectedOption(idContentType);
        this.adsSetupEdit_template.getTemplateViewModel().deviceOption.setSelectedOption(adsSetup.device);
        this.adsSetupEdit_template.getTemplateViewModel().geoGroupOption.setSelectedOption(idGeoGroup);
        this.adsSetupEdit_template.getTemplateViewModel().sspGroupOption.setSelectedOption(idSspGroup);
        this.adsSetupEdit_template.setSources(adsSetup.sources);
        this.adsSetupEdit_template.setGeos(adsSetup.geosUnmerged || adsSetup.geos);
        this.adsSetupEdit_template.getTemplateViewModel().extraParameters(this.formatExtraParametersForFrontend(adsSetup.extraParameters));
        this.adsSetupEdit_template.getTemplateViewModel().adsSetupTagOption.setSelectedOption(idAdsSetupTag);
        this.adsSetupEdit_template.getTemplateViewModel().group(groupName);
        this.adsSetupEdit_template.getTemplateViewModel().idGroupLabel(idGroupLabel);
        this.adsSetupEdit_template.getTemplateViewModel().includeInGroup((adsSetup.idGroup));
        this.adsSetupEdit_template.getTemplateViewModel().groupLabelOption.setSelectedOption(idGroupLabel);
    }

    private formatExtraParametersForFrontend(extraParameters){

        let extraParametersForTemplate = [];

        if(extraParameters !== null) {

            for (let extraParameter of extraParameters) {

                extraParametersForTemplate.push({"name": Object.keys(extraParameter)[0], "value": extraParameter[Object.keys(extraParameter)[0]]});
            }
        }


        return extraParametersForTemplate;

    }

    private formatExtraParametersForBackend(extraParameters){

        let extraParametersForBackend = [];

        for(let extraParameter of extraParameters){

            let newExtraParameter = {};
            newExtraParameter[extraParameter.name] = extraParameter.value;
            extraParametersForBackend.push(newExtraParameter);
        }

        return extraParametersForBackend;
    }

    private setGeoGroupsOptions(geoGroups){

        geoGroups.unshift({id : null, name : 'no group'});
        this.adsSetupEdit_template.setGeoGroupsOptionItems(geoGroups);

    }

    /**
     * Saves the user data.
     */
    protected save(): void{

        this.loadingIn(this.adsSetupEdit_template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.adsSetupEdit_template.getViewModelForRequest();
        subViewModel["extraParameters"] = this.formatExtraParametersForBackend(subViewModel["extraParameters"]);

        let idBrand = this.getVnAppZone().app.params.idBrand;

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/brand/${idBrand}/ads_setup/save/`, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveReturn;
        XHRHdl.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{

        let idBrand = obj.getVnAppZone().app.params.idBrand;

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'ads setup created successfully!');

                obj.releaseLock();
                page.redirect(`/brand/${idBrand}/ads_setups`);
            });

            obj.loadingOut(obj.adsSetupEdit_template.builtTemplate);
        }
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public filterDropdownOnChange(): void{

    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.getEditData(true);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }
}
