import { sspList_template } from '../components/templates/sspList_template';
import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import {header} from "./header";
import {vnApp_ads_ninja} from "../components/vnApp_ads_ninja";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";

declare var ko: any;
declare var page: any;

export class ssp_list extends vnModule_knockoutJS{

    protected sspList_template: sspList_template;
    protected XHRHdl: XMLHttpRequestHandler;

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'ssp_list';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;

        this.sspList_template = new sspList_template('sspList_template', this.viewModel, this);

        let idBrand = this.getVnAppZone().app.params.idBrand;

        if(idBrand){

            (<header>loadedModule).setHeaderTitle("SSP Configs List");

            let hasUpdateAccessForSspConfigs = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SSP_CONFIG, vnApp_ads_ninja.ACCESS_TYPE_UPDATE);
            let hasDeleteAccessForSspConfigs = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SSP_CONFIG, vnApp_ads_ninja.ACCESS_TYPE_DELETE);
            let hasWriteAccessForSspConfigs  = this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SSP_CONFIG, vnApp_ads_ninja.ACCESS_TYPE_WRITE);

            this.sspList_template.getTemplateViewModel().hasUpdateAccess(hasUpdateAccessForSspConfigs && hasDeleteAccessForSspConfigs && hasWriteAccessForSspConfigs);
            this.sspList_template.getTemplateViewModel().hasDeleteAccess(false);
            this.sspList_template.getTemplateViewModel().hasWriteAccess(false);
        }
        else{

            (<header>loadedModule).setHeaderTitle("SSP List");

            this.sspList_template.getTemplateViewModel().hasUpdateAccess(this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SSP, vnApp_ads_ninja.ACCESS_TYPE_UPDATE));
            this.sspList_template.getTemplateViewModel().hasDeleteAccess(this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SSP, vnApp_ads_ninja.ACCESS_TYPE_DELETE));
            this.sspList_template.getTemplateViewModel().hasWriteAccess(this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SSP, vnApp_ads_ninja.ACCESS_TYPE_WRITE));
        }

        return [this.sspList_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getListData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public paginationOnPageClick(): void{

        this.getListData();
    }

    /**
     * @inheritDoc
     */
    protected reorderSsps(): void{

        this.getListData();
    }


    /**
     * Fetches the list data.
     */
    public getListData(): void{

        this.loadingIn(this.sspList_template.builtTemplate.children.namedItem('list-results'));

        let subViewModel = this.sspList_template.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(['data', ko.toJSON(subViewModel)] );

        this.XHRHdl = new XMLHttpRequestHandler('/api/ssps/get/', usrReq_params, this);
        this.XHRHdl.onReadyStateFunction = this.onDataRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for data request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateList(responseParsed);
                obj.setResultInfo(responseParsed.totalCount, responseParsed.totalPage);
                obj.updatePagination(responseParsed.totalCount);
                obj.loadingOut(obj.sspList_template.builtTemplate.children.namedItem('list-results'));
            });
        }
    }

    /**
     * Update the module list using the objects list.
     *
     * @param responseParsed
     */
    protected updateList(responseParsed: any): void{

        this.loadingIn(this.sspList_template.builtTemplate.children.namedItem('list-results'));

        this.sspList_template.setSsps(responseParsed.data);

        if(this.getVnAppZone().app.params.idBrand){

            this.sspList_template.getTemplateViewModel().hasWriteAccess(false);
            this.sspList_template.setIdBrand(this.getVnAppZone().app.params.idBrand);
        }
    }

    /**
     * Hook.
     *
     * Redirects to create source page.
     */
    protected addSsp(): void{

        page(`/ssps/add`);
    }

    /**
     * Hook.
     *
     * Redirects to create Ssp page.
     */
    protected editSsp(id): void{

        let idBrand = this.getVnAppZone().app.params.idBrand;

        if(idBrand){

            page(`/brand/${idBrand}/ssps/${id}/config/edit/`);
        }
        else{

            page(`/ssps/edit/${id}`);
        }
    }

    /**
     * Deletes the requested resource.
     *
     * @param id
     * @protected
     */
    protected deleteSsp(id): void{

        this.loadingIn(this.sspList_template.builtTemplate.children.namedItem('list-results'));

        this.XHRHdl                      = new XMLHttpRequestHandler(`/api/ssps/delete/${id}`, [], this);
        this.XHRHdl.mode                 = XMLHttpRequestHandler_requestType.POST;
        this.XHRHdl.onReadyStateFunction = this.onDeleteRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for delete request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDeleteRequestReturn(req, obj): Function{

        return function(){

            obj.loadingOut(obj.sspList_template.builtTemplate.children.namedItem('list-results'));

            obj.manageResponse(req, obj, function(){

                obj.getListData();
            });
        }
    }

    //
    // Copy request.
    protected copy(id): void{

        this.loadingIn(this.sspList_template.builtTemplate.children.namedItem("list-results"));

        this.XHRHdl                      = new XMLHttpRequestHandler(`/api/ssps/copy/${id}`, [], this);
        this.XHRHdl.mode                 = XMLHttpRequestHandler_requestType.GET;
        this.XHRHdl.onReadyStateFunction = this.onCopyRequestReturn;
        this.XHRHdl.execute();
    }

    //
    // Copy handler.
    protected onCopyRequestReturn(req, obj): Function{

        return function(){

            obj.loadingOut(obj.sspList_template.builtTemplate.children.namedItem('list-results'));

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'SSP successfully copied.');
                obj.getVnAppZone().showNotificationToModule();

                obj.getListData();
            });
        }
    }

    protected setResultInfo(totalCount, totalPage){
        let execTime = Math.round((new Date().getTime() - this.XHRHdl.requestStartTime)/1000*100)/100;
        this.sspList_template.setResultInfo(totalCount, totalPage, execTime);
    }

    protected updatePagination(totalCount) {

        this.sspList_template.updatePagination(totalCount);

    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.deleteSsp(data);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

    }

    /**
     * Hook.
     *
     * Cancels data request.
     */
    protected filterSimpleCancel(){

        if(this.XHRHdl){

            this.XHRHdl.cancelAndReset();
        }
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected filterSimpleOnChange(){

        this.sspList_template.resetPageNumber();
        this.sspList_template.resetOrderParameters();
        this.getListData();
    }
}