import {vnTemplate} from "./vnTemplate";
import {filterDropdown_template} from "./filterDropdown_template";
import {filterSingleDate_template} from "./filterSingleDate_template";

declare var ko: any;

export class tasksEdit_template extends vnTemplate{

    protected operationPicker: filterDropdown_template;
    protected executerPicker: filterDropdown_template;
    protected opertationDatePicker: filterSingleDate_template;
    protected scheduledDatePicker: filterSingleDate_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <strong class="form-title icon i-user">Add Task</strong>
                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close"  type="button"></button>
            </div>
            <div class="w-form-body" id="view-results">
                <div class="form-body">
                    <strong class="form-sub-title">Task info</strong>
                    <div class="form-group ">
                        <label for="firstName" class="form-label">Operation</label>
                        ` + this.operationPicker.getHtmlBuild() + `
                    </div>
                    <div class="form-group">
                        <label for="firstName" class="form-label">Task Executor</label>
                        ` + this.executerPicker.getHtmlBuild() + `
                    </div>
                    <div class="form-group">
                        <label for="lastName" class="form-label">Operation Date</label>
                        ` + this.opertationDatePicker.getHtmlBuild() + `
                    </div>
                    <div class="form-group">
                        <label for="lastName" class="form-label">Scheduled Date</label>
                        ` + this.scheduledDatePicker.getHtmlBuild() + `
                    </div>
                </div>
            </div>
            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn " type="button">Cancel</button>
                <button data-bind="event: { click: onClickCreateConfirm }" class="btn highlight" type="button">Create Task</button>  
            </div>
            
            <div data-bind="visible: taskCreateConfirmationVisible"  class="w-lightbox dark-theme">
                <div class="lightbox-content">
                        
                    <div class="w-form lightbox-form">
                        <div class="form-header">
                            <h3>Create Tasks</h3>
                            <button data-bind="event: { click: onClickCreateDialogHide }" type="button" class="close-btn icon i-close"></button>
                        </div>
                   
                        <div class="form-body">
                            <p class="dark">Are you sure you want to create task</p>
                         </div>
                
                        <div class="form-footer">
                            <button data-bind="event: { click: onClickCreateDialogHide }" class="btn " type="button">Cancel</button>
                            <button data-bind="event: { click: onClickCreateConfirm }" class="btn highlight" type="button">Save</button>
                        </div>
                    </div>
                    
                </div>
            </div>
            `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

        this.operationPicker = new filterDropdown_template("operationPicker", this.getTemplateViewModel(), this.caller);
        this.executerPicker = new filterDropdown_template("executerPicker", this.getTemplateViewModel(), this.caller);
        this.opertationDatePicker = new filterSingleDate_template("opertationDatePicker", this.getTemplateViewModel(), this.caller);
        this.scheduledDatePicker = new filterSingleDate_template("scheduledDatePicker", this.getTemplateViewModel(), this.caller);
    }

    public buildOpertationDatePickerCalendar(){

        this.opertationDatePicker.buildCalendar();
    }

    public buildScheduledDatePickerCalendar(){

        this.scheduledDatePicker.buildCalendar();
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "tasksEdit-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;
        return {
            taskCreateConfirmationVisible: ko.observable(0),
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
            onClickCreate: function (data) {
                huit_neuf_this.onClickCreate(data);
            },
            onClickCreateDialogHide: function(data, event){
                huit_neuf_this.onClickCreateDialogHide();
            },
            onClickCreateConfirm: function(data, event){
                huit_neuf_this.onClickCreateConfirm();
            },
        };
    }

    public getViewModelForRequest(): Object {

        return {
            operationPicker: this.operationPicker.getTemplateViewModel().selectedOption(),
            executerPicker: this.executerPicker.getTemplateViewModel().selectedOption(),
            opertationDatePicker:   this.opertationDatePicker.getTemplateViewModel().singleDatePicker(),
            scheduledDatePicker:   this.scheduledDatePicker.getTemplateViewModel().singleDatePicker()

        };
    }

    public setOperationPickerItems(operations): void {

        this.operationPicker.setOptions(operations);
    }

    public setExecutorPickerItems(executors: any): void {

        this.executerPicker.setOptions(executors);
    }

    public onClickCreate(task: Array<any>) {

        this.getTemplateViewModel().taskCreateConfirmationVisible(1);
    }

    public onClickCreateDialogHide() {

        this.getTemplateViewModel().taskCreateConfirmationVisible(0);
    }

    public onClickCreateConfirm() {

        this.getTemplateViewModel().taskCreateConfirmationVisible(0);
        this.caller.saveTask();

    }

    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancelEditTask();
    }


}
