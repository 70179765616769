import {vnTemplate} from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate';
import {filterDropdown_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDropdown_template";
import {filterSingleDate_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterSingleDate_template";

declare var ko: any;

export class sponsoredRevenueEdit_template extends vnTemplate{

    protected brandSelector : filterDropdown_template;
    protected dateSelector: filterSingleDate_template;
    protected delay = 400;
    protected debounceTimerId: number;

    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <input id="sponsoredRevenue-id" data-bind="value: idSponsoredRevenue" type="hidden">
                
                <!-- ko ifnot: $data.idSponsoredRevenue() -->
                <h3 class="form-title icon i-sponsoredRevenue-add">Add Sponsored Revenue</h3>
                <!-- /ko -->

                <!-- ko if: $data.idSponsoredRevenue() -->
                <h3 class="form-title icon i-sponsoredRevenue-add">Edit Sponsored Revenue</h3>
                <!-- /ko -->

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
            
            <div class="w-form-body" id="view-results">
                <div class="form-body">
                
                    <div class="form-group" data-bind="visible: idSponsoredRevenue() == ''">
                        <label for="" class="form-label">Brand</label>
                        ${this.brandSelector.getHtmlBuild()}
                    </div>
                    
                    <div class="form-group" data-bind="visible: idSponsoredRevenue() != ''">
                        <label for="" class="form-label">Brand</label>
                        <input id="editBrand" data-bind="value: editBrand" type="text" class="input-text" disabled>
                    </div>
                    
                    
                    <div class="form-group">
                        <label for="dealName" class="form-label">Deal Name</label>
                        <input id="dealName" data-bind="value: dealName" type="text" class="input-text">
                    </div>
                    
                    <div class="form-group">
                        <label for="invoiceNumber" class="form-label">Invoice Number</label>
                        <input id="invoiceNumber" data-bind="value: invoiceNumber" type="text" class="input-text">
                    </div>
                    
                    <div class="form-group">
                        <label for="amount" class="form-label">Amount</label>
                        <input id="amount" data-bind="value: amount" type="text" class="input-text">
                    </div>
                    
                    <div class="form-group" >
                        <label for="permalink" class="form-label">Permalink</label>
                        <input id="amount" type="text" class="input-text" data-bind="textInput: permalink, event: { keyup: onKeyUpPermalinkAction}, valueUpdate : 'afterkeydown', disable: isPermalinkDisabled">
                    </div>
                    
                    <div class="form-group" data-bind="visible: !nullDate()">
                        <label for="" class="form-label">Date</label>
                        ${this.dateSelector.getHtmlBuild()}
                    </div>
                                        
                    <div class="form-group">
                        <label for="notes" class="form-label">Comment</label>
                        <textarea data-bind="value: notes" name="test" id="notes" cols="30" rows="20" style="height: 15rem;"></textarea>
                    </div>
                     
                </div>
            </div>
            
            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn " type="button">Cancel</button>
                <button data-bind="event: { click: onClickSave }, disable: isSaveDisabled" class="btn highlight" type="button">Save</button>  
            </div>

            `;
    }

    protected initPartial():void{

        this.brandSelector = new filterDropdown_template("brandSelector", this.getTemplateViewModel(), this.caller);
        this.dateSelector = new filterSingleDate_template("dateSelector", this.getTemplateViewModel(), this.caller);
    }

    protected getTemplateName(): string{

        return "sponsoredRevenueEdit_template";
    }

    protected getMainElementType(): string{

        return "div";
    }

    protected getMainElementClasses(): Array<string>{

        return ["w-form", "margin-top"];
    }

    protected buildViewModel(): any{

        let huit_neuf_this = this;
        let viewModel = {
            idSponsoredRevenue: ko.observable(""),
            editBrand: ko.observable(""),
            dealName: ko.observable(""),
            invoiceNumber: ko.observable(""),
            amount: ko.observable(""),
            permalink: ko.observable(""),
            notes: ko.observable(""),
            nullDate: ko.observable(false),
            isPermalinkDisabled: ko.observable(true),
            isSaveDisabled: ko.observable(true),
            onKeyUpPermalinkAction : function(data,event){ huit_neuf_this.onKeyUpPermalinkAction(data, event) },


        onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
        };

        return viewModel;
    }

    public getViewModelForRequest(){

        return {

            brand: this.brandSelector.getTemplateViewModel().selectedOption(),
            idSponsoredRevenue: this.getTemplateViewModel().idSponsoredRevenue(),
            dealName: this.getTemplateViewModel().dealName(),
            invoiceNumber: this.getTemplateViewModel().invoiceNumber(),
            amount: this.getTemplateViewModel().amount(),
            permalink: this.getTemplateViewModel().permalink(),
            date: this.dateSelector.getTemplateViewModel().singleDatePicker(),
            notes: this.getTemplateViewModel().notes()
        }
    }

    public buildDatePickerCalendar(){

        this.dateSelector.buildCalendarWithoutTime();
    }

    public setSelectedBrands(selectedBrands : Array<{id: number}>){

        let brands = [];
        for(let c of selectedBrands){

            brands.push(c.id);
        }
        this.getTemplateViewModel().selectedBrands(brands);
    }

    protected onClickSave(){

        this.caller.save();
    }

    protected onClickCancel(){

        this.caller.cancel();
    }

    protected onKeyUpPermalinkAction(data, event){

        let permalink = this.getTemplateViewModel().permalink();

        if (permalink != "") {

            this.getTemplateViewModel().nullDate(true);
            this.dateSelector.setDate(null);

            clearTimeout(this.debounceTimerId);

            let _myThis = this;
            this.debounceTimerId = window.setTimeout(function () {

                console.log("calling permalink");
                _myThis.caller.getPermalinkDate()
            }, this.delay);
        }else {

            if (this.dateSelector.getTemplateViewModel().singleDatePicker() === null) {

                this.dateSelector.setDate(new Date().toISOString().split('T')[0].replace(/-/g, "\/"));
            }
            this.getTemplateViewModel().nullDate(false);
        }
    }

    public setupForm(allBrands, sponsoredRevenue){

        let brands = [];

        brands.push({id: '', name: ''});

        for ( let brand of allBrands){

            brands.push(brand);
        }

        this.brandSelector.setOptions(brands);
        this.brandSelector.setLabel("Brands");

        if(sponsoredRevenue.id !== undefined && sponsoredRevenue.id !== null){

            this.getTemplateViewModel().idSponsoredRevenue(sponsoredRevenue.id);
            this.brandSelector.getTemplateViewModel().selectedOption(sponsoredRevenue.idBrand);
            this.brandSelector.getTemplateViewModel().selectedOption(sponsoredRevenue.idBrand);

            this.getTemplateViewModel().editBrand(this.brandSelector.getTemplateViewModel().options().find(x => x.id === sponsoredRevenue.idBrand).name);


            this.getTemplateViewModel().dealName(sponsoredRevenue.dealName);
            this.getTemplateViewModel().invoiceNumber(sponsoredRevenue.invoiceNumber);
            this.getTemplateViewModel().amount(sponsoredRevenue.amount);
            this.getTemplateViewModel().permalink(sponsoredRevenue.permalink);

            if (sponsoredRevenue.permalink != "") {
                if (typeof sponsoredRevenue.dateData !== undefined && sponsoredRevenue.dateData !== null) {

                    this.dateSelector.setDate(sponsoredRevenue.dateData.replace(/-/g, "\/"));
                    this.getTemplateViewModel().nullDate(false);
                } else {

                    this.getTemplateViewModel().nullDate(true);
                    this.dateSelector.setDate(null);
                }
            }else {

                if (this.dateSelector.getTemplateViewModel().singleDatePicker() === null) {

                    this.dateSelector.setDate(new Date().toISOString().split('T')[0].replace(/-/g, "\/"));
                }
                this.getTemplateViewModel().nullDate(false);
            }

            this.getTemplateViewModel().notes(sponsoredRevenue.notes);

            var textarea = document.getElementById('notes');
            textarea.scrollTop = textarea.scrollHeight;
        }

        this.validate();
    }

    public setPermalinkDate(date) {

        let permalink = this.getTemplateViewModel().permalink();

        if (permalink !== "") {
            if (date === null) {

                this.getTemplateViewModel().nullDate(true);
                this.dateSelector.setDate(null);
            } else {

                this.dateSelector.setDate(date.replace(/-/g, "\/"));
                this.getTemplateViewModel().nullDate(false);
            }
        }else {

            if (this.dateSelector.getTemplateViewModel().singleDatePicker() === null) {

                this.dateSelector.setDate(new Date().toISOString().split('T')[0].replace(/-/g, "\/"));
            }
            this.getTemplateViewModel().nullDate(false);
        }
    }

    public brandChange() {

        this.setPermalinkDate(null);
        this.getTemplateViewModel().permalink("");
    }

    public validate() {

        let permalink = this.getTemplateViewModel().permalink();

        if (permalink == "") {

            if (this.dateSelector.getTemplateViewModel().singleDatePicker() === null) {

                this.dateSelector.setDate(new Date().toISOString().split('T')[0].replace(/-/g, "\/"));
            }
            this.getTemplateViewModel().nullDate(false);
        }

        if (this.brandSelector.getTemplateViewModel().selectedOption() == "" ) {

            this.getTemplateViewModel().isPermalinkDisabled(true);
            this.getTemplateViewModel().isSaveDisabled(true);
        }else {

            this.getTemplateViewModel().isPermalinkDisabled(false);
            this.getTemplateViewModel().isSaveDisabled(false);
        }
    }
}
