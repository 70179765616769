import {vnModule_knockoutJS} from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import {XMLHttpRequestHandler} from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import {header} from "./header";
import {realTimeStats_template} from "../components/templates/realTimeStats_template";

declare var ko: any;
declare var page: any;

export class realTimeStats extends vnModule_knockoutJS{

    protected template: realTimeStats_template;
    protected requestHandler: XMLHttpRequestHandler;
    protected chartRequestHandler: XMLHttpRequestHandler;
    protected tableRequestHandler: XMLHttpRequestHandler;

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'realTimeStats';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName('Header');
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle('Video Player Stats');

        this.template = new realTimeStats_template('realTimeStats_template', this.viewModel, this)

        return [this.template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.template.buildDatePickerCalendar();
        this.getListFormData();
    }

    //
    // Requests data for the filters.
    protected getListFormData(): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem('chart-results'));
        this.loadingIn(this.template.builtTemplate.children.namedItem('table-results'));

        let getParams = [];

        if (this.getVnAppZone().app.params.idBrand){
            getParams.push(['idBrand', this.getVnAppZone().app.params.idBrand]);
        }

        if(this.requestHandler){

            this.requestHandler.cancelAndReset();
        }

        this.requestHandler = new XMLHttpRequestHandler(`/api/real_time_stats/references`, getParams, this);
        this.requestHandler.onReadyStateFunction = this.onFilterRequestReturn;
        this.requestHandler.execute();
    }

    //
    // Returns handler for filter request.
    protected onFilterRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateFilters(responseParsed);
                console.log("filters updated");
                obj.getChartData();
                obj.getTableData();
            });
        }
    }

    //
    // Fetches the chart data.
    public getChartData(): void{

        this.template.activateChartLoading();
        this.loadingIn(this.template.builtTemplate.children.namedItem('chart-results'));

        if(this.chartRequestHandler){

            this.chartRequestHandler.cancelAndReset();
        }

        let subViewModel = this.template.getViewModelForRequest();
        
        
        let usrReq_params: Array<Array<string>> = [];
        let idBrand = this.getVnAppZone().app.params.idBrand;
        let url     = '';
        
        if(idBrand){

            usrReq_params.push(['idBrand', idBrand]);
            url = `/api/brand/${idBrand}/real_time_stats/getChartData`
        }
        else{
            
            url = `/api/real_time_stats/getChartData`
        }
        usrReq_params.push(['data', ko.toJSON(subViewModel)] );
        
        this.chartRequestHandler = new XMLHttpRequestHandler(url, usrReq_params, this);
        this.chartRequestHandler.onReadyStateFunction = this.onChartDataRequestReturn;
        this.chartRequestHandler.execute();
    }

     //
    // Fetches the table data.
    public getTableData(): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem('table-results'));

        if(this.tableRequestHandler){

            this.tableRequestHandler.cancelAndReset();
        }

        let subViewModel = this.template.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        let idBrand = this.getVnAppZone().app.params.idBrand;
        let url     = '';
        
        if(idBrand){
            
            usrReq_params.push(['idBrand', idBrand]);
            url = `/api/brand/${idBrand}/real_time_stats/getTableData`
        }
        else{
            
            url = `/api/real_time_stats/getTableData`
        }
        usrReq_params.push(['data', ko.toJSON(subViewModel)] );

        this.tableRequestHandler = new XMLHttpRequestHandler(url, usrReq_params, this);
        this.tableRequestHandler.onReadyStateFunction = this.onTableDataRequestReturn;
        this.tableRequestHandler.execute();
    }


    //
    // Returns handler for chart data request.
    protected onChartDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateChart(responseParsed);
                obj.loadingOut(obj.template.builtTemplate.children.namedItem('chart-results'));
            });
        }
    }

    //
    // Returns handler for table data request.
    protected onTableDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateTable(responseParsed);
                obj.setResultInfo(responseParsed.totalCount, responseParsed.totalPage);
                obj.updatePagination(responseParsed.totalCount);
                obj.loadingOut(obj.template.builtTemplate.children.namedItem('table-results'));
            });
        }
    }

    //
    // Update the module chart using the objects list.
    protected updateChart(responseParsed: any): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem('chart-results'));

        let chartRecords                   = responseParsed.chartRecords;
        let chartValues                    = [];
        let eventTypes                     = responseParsed.eventTypes;
        let eventTypesToExcludeFromChart   = responseParsed.eventTypesToExcludeFromChart;
        let eventTypesToHideOnInitForChart = responseParsed.eventTypesToHideOnInitForChart;

        // Chart data.
        if(Object.keys(chartRecords).length > 0){

            for (const date in chartRecords) {

                //loop through each day
                if (chartRecords.hasOwnProperty(date)) {

                    const dateEntry = chartRecords[date];
                    for (const h in dateEntry) {

                        // loop through each hour for each day
                        if(dateEntry.hasOwnProperty(h)){

                            const entry = dateEntry[h];
                            const hour = entry.hour;
                            let line = {
                                dateHour: date + ' ' + hour,
                            };

                            for(let e in eventTypes){

                                if(eventTypesToExcludeFromChart.includes(eventTypes[e])){

                                    continue;
                                }

                                line[eventTypes[e]] = entry['typeOccurrences'][eventTypes[e]];
                            }

                            chartValues.push(line);
                        }
                    }
                }
            }
        }

        this.template.getTemplateViewModel().chartData(chartValues);
        this.template.getTemplateViewModel().eventTypesToExcludeFromChart(eventTypesToExcludeFromChart);
        this.template.getTemplateViewModel().eventTypesToHideOnInitForChart(eventTypesToHideOnInitForChart);
        this.template.getTemplateViewModel().eventTypes(eventTypes);
        this.template.updateChart();
        this.template.deactivateChartLoading();
    }

    //
    // Update the module table using the objects list.
    protected updateTable(responseParsed: any): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem('table-results'));

        let data                           = responseParsed.data;
        let records                        = data.records;

        this.template.getTemplateViewModel().data(records);
        this.template.getTemplateViewModel().tableData(records);
    }

    //
    // Update the filters.
    protected updateFilters(responseParsed: any): void{

        this.template.setDeviceFilterItems(responseParsed.devices);
        this.template.setGeoFilterItems(responseParsed.geos);
        this.template.setOperatingSystemsFilterItems(responseParsed.operatingSystems);
        this.template.setPlacementFilterItems(responseParsed.placements);
        this.template.setBrandFilterItems(responseParsed.brands);
        this.template.getTemplateViewModel().idBrand(this.getVnAppZone().app.params.idBrand || 0);
    }

    //
    // Hook.
    // Initiates data refresh.
    public filterDropdownOnChange(): void{

        this.getChartData();
        this.getTableData();
    }

    //
    // Sets results info.
    protected setResultInfo(totalCount, totalPage){

        let execTime = Math.round((new Date().getTime() - this.tableRequestHandler.requestStartTime)/1000*100)/100;
        this.template.setResultInfo(totalCount, totalPage, execTime);
    }

    //
    // Hook.
    // Cancels data request.
    protected filterSimpleCancel(){

        if (this.requestHandler) {
            this.requestHandler.cancelAndReset();
        }
        
        if (this.chartRequestHandler) {
            this.chartRequestHandler.cancelAndReset();
        }
        
        if (this.tableRequestHandler) {
            this.tableRequestHandler.cancelAndReset();
        }
    }

    //
    // Hook.
    // Initiates data refresh.
    protected filterSimpleOnChange(){

        this.template.resetPageNumber();
        this.template.resetOrderParameters();
        this.getChartData();
        this.getTableData();
    }

    //
    // Hook.
    // Initiates data refresh.
    protected filterMultipleSelectorOnChange(){

        this.getChartData();
        this.getTableData();
    }

    //
    // Hook.
    // Initiates data refresh.
    protected filterSingleDateOnChange(){

        this.getChartData();
        this.getTableData();
    }

    protected filterDateOnChange(){

        this.getChartData();
        this.getTableData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected reorderTable(){

        this.getTableData();
    }

    public paginationOnPageClick(): void{

        this.getTableData();
    }

    protected updatePagination(totalCount) {

        this.template.updatePagination(totalCount);

    }
}