import { vnTemplate } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate';
import { multipleSelector_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/multipleSelector_template";
import { filterDate_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDate_template";
import { exportDataCsv } from "../../../vendor/valnet/valnet_jslib/vnHelpers/exportDataCsv";
import { filterDropdown_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDropdown_template';
declare var ko: any;


export class revenueSheet_template extends vnTemplate {

    protected brandsFilter: multipleSelector_template;
    protected datesFilter: filterDate_template;
    protected viewModeFilter: filterDropdown_template;
    protected currencyFilter: filterDropdown_template;
    // protected exportDataCsv: exportDataCsv;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="sub-header">
                <div class="w-justify">
                    <div class="w-left">
                        <div>
                            <label class="form-label">View Mode</label>
                            ${this.viewModeFilter.getHtmlBuild()}
                        </div>                            
                        <div>
                            <label class="form-label">Brands</label>
                            ${this.brandsFilter.getHtmlBuild()}
                        </div>   
                        <div>
                            <label class="form-label form-label-box multi"></label>
                            ${this.datesFilter.getHtmlBuild()}
                        </div>
                        <div>
                            <label class="form-label">Currency</label>
                            ${this.currencyFilter.getHtmlBuild()}
                        </div>      
                    </div>
                </div>
            </div>
            <div class="w-filters-selected">
                <div id="selected-brands" class="filters-selected"></div>                
            </div>      
            
            <div class="list-header w-justify">
                 <div class="w-left">
                    <h2 class="section-title icon i-payment">Revenue Sheet</h2>
                </div>
                <!--
                <div class="w-right">
                    <button style="margin-bottom: 0" class="btn icon i-export" type="button" data-bind="event: {click: onExportData}">Export</button>
                </div>
                -->
            </div>
            
            <div class="w-list-results" id="list-results">
           
                <table class="list-results first-column-fixed" style="border: 0">                  
                    <thead data-bind="visible: viewMode() === 'advertiser'">
                        <tr class="header-0">
                            <th style="min-width: 7rem; background-color: #000000;" class="align-left" data-bind="attr: {colspan:1}"></th>
                            <th class="align-left" data-bind="attr: {colspan:(tableHeaders().length -5)}"><span>Advertisers</span></th>
                            <th class="align-left" data-bind="attr: {colspan:1}"><span>Totals</span></th>
                            <th class="align-left" data-bind="attr: {colspan:3}"><span>Other Metrics</span></th>
                        </tr>
                    </thead>                   
                    <thead class="condensed">
                        <tr data-bind="foreach: tableHeaders">
                            <th data-bind="css: classes"><span data-bind="text: header"></span></th>
                        </tr>
                    </thead>
                    <tbody data-bind="foreach: tableData">  
                        <tr data-bind="foreach: $data">
                            <td class="" data-bind="attr: {colspan:1}, css: classes"><span data-bind="text: data"></span></td>
                        </tr>
                    </tbody>                    
                    <tbody data-bind="visible : tableTotal().length > 1">  
                        <tr class="total">
                            <td class="align-left" style="border: 0px solid black;"><span>Total</span></td>
                            <!-- ko foreach: tableTotal -->
                            <td class="align-right" data-bind="attr: {colspan:1}"><span data-bind="text: data"></span></td>
                            <!-- /ko -->
                        </tr>
                    </tbody>
                </table>
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{

        let dateStart = new Date();
        dateStart.setDate(dateStart.getDate()-7);

        let dateEnd = new Date();
        dateEnd.setDate(dateEnd.getDate()-1);

        this.viewModeFilter = new filterDropdown_template('viewModeFilter', this.getTemplateViewModel(), this.caller);
        this.currencyFilter = new filterDropdown_template('currencyFilter', this.getTemplateViewModel(), this.caller);

        this.datesFilter = new filterDate_template("datesFilter", this.getTemplateViewModel(), this.caller);
        this.datesFilter.setStartPickerId('startDatePicker');
        this.datesFilter.setEndPickerId('endDatePicker');
        this.datesFilter.setDateRange(dateStart, dateEnd);

        this.brandsFilter = new multipleSelector_template('brandsFilter', this.getTemplateViewModel(), this.caller);
        this.brandsFilter.setTagContainerId('selected-brands');
        this.brandsFilter.setTagContainerCaption('Selected Brands:');
        this.brandsFilter.setSearchEnabled(true);
        this.brandsFilter.setSelectAllEnabled(false);

        // this.exportDataCsv = new exportDataCsv();

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return 'revenueSheet_template';
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return [];
    }

    /**
     * @inheritDoc
     */
    public activateLoading(): void{

        this.getTemplateViewModel().isLoading(1);
    }

    /**
     * @inheritDoc
     */
    public deactivateLoading(): void{

        this.getTemplateViewModel().isLoading(0);
    }

    /**
     * @inheritDoc
     */
    public buildViewModel(): Object{

        let self = this;

        return {
            orderBy: ko.observable('revenue'),
            orderByDirection: ko.observable('DESC'),
            orderByAndDirection: ko.observable('revenue_DESC'),
            data: ko.observableArray([]),
            viewMode : ko.observable('daily'),
            tableHeaders: ko.observableArray([]),
            tableData: ko.observableArray([]),
            tableTotal: ko.observable([]),
            headers: ko.observableArray([]),
            dimensionHeaders: ko.observable([]),
            dimensions: ko.observable([]),
            exportButtonHidden: ko.observable(1),
            exportData: ko.observableArray([]),
            // onExportData : function(){ self.onExportData(); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{
        return {
            orderBy: this.getTemplateViewModel().orderBy(),
            orderByDirection: this.getTemplateViewModel().orderByDirection(),
            filters: {
                viewMode : this.viewModeFilter.getTemplateViewModel().selectedOption(),
                currency : this.currencyFilter.getTemplateViewModel().selectedOption(),
                selectedBrands: this.brandsFilter.getTemplateViewModel().selectedItems(),
                startDate: this.getTemplateViewModel().datesFilter.startDate,
                endDate: this.getTemplateViewModel().datesFilter.endDate,
            },
        };
    }

    public buildDatePickerCalendar(){

        this.datesFilter.buildCalendar();
    }

    public setBrandFilterItems(brands): void{

        this.brandsFilter.setItems(brands);
    }

    public setViewModeFilter(viewModes): void{

        this.viewModeFilter.setOptions(viewModes);
    }
    public setCurrencyFilter(currencies): void{

        this.currencyFilter.setOptions(currencies);
    }

    public setViewMode(): void{

        this.getTemplateViewModel().viewMode(this.viewModeFilter.getTemplateViewModel().selectedOption());
    }

    //
    // Sets data for a multi select filter.
    public setMultiSelectFilter(filter, data: Array<{ id: string, name: string }>): void{

        filter.setItems(data);
    }

    public setTableData(data) {
        this.getTemplateViewModel().tableHeaders(data.tableHeaders);    
        if (data.tableData.length === 0) {
            this.getTemplateViewModel().tableData([]);
            this.getTemplateViewModel().tableTotal([]);
            return;
        }
        this.getTemplateViewModel().tableTotal(data.tableData.pop());
        this.getTemplateViewModel().tableData(data.tableData);
        // this.showExportButton();
    }

    // public hideExportButton(){

    //     this.getTemplateViewModel().exportButtonHidden(1);
    // }

    // public showExportButton(){

    //     this.getTemplateViewModel().exportButtonHidden(0);
    // }

    // private onExportData(){

    //     this.caller.getExportData();
    // }

    // public setExportData(exportData){

    //     let exportDataView = [];
    //     let dimensions = this.getTemplateViewModel().dimensions();
    //     let tableData = this.getTemplateViewModel().tableData();

    //     for (let data of tableData){

    //         let exportDataRow = [];

    //         for (let dimension of dimensions){
    //             //Uppercase first letter
    //             exportDataRow[dimension.charAt(0).toUpperCase() + dimension.slice(1)] = data[dimension];
    //         }
    //         exportDataRow["Revenue"] = data.revenue === '-' ? '-' : Number(data.revenue).toLocaleString('en-EG', {minimumFractionDigits: 2, maximumFractionDigits: 2})
    //         exportDataRow["Impressions"] = data.impressions === '-' ? '-' : Number(data.impressions).toLocaleString('en-EG', {minimumFractionDigits: 0, maximumFractionDigits: 0})
    //         exportDataRow["CPM"] = data.CPM === '-' ? '-' : Number(data.CPM).toLocaleString('en-EG', {minimumFractionDigits: 0, maximumFractionDigits: 2})

    //         exportDataView.push(exportDataRow);
    //     }

    //     this.getTemplateViewModel().exportData(exportDataView);
    // }

    // private exportData(obj){

    //     let fileName = "RevenueSheet_" + this.datesFilter.getTemplateViewModel().startDate() + "_" + this.datesFilter.getTemplateViewModel().endDate()+ ".csv";
    //     this.exportDataCsv.exportToCsv(fileName, this.getTemplateViewModel().exportData());
    // }
}