export enum  XMLHttpRequestHandler_requestType{
        GET,
        POST
}

export class XMLHttpRequestHandler{

    // MEMBER VARIABLES
    protected _parameters: Array<Array<string>>;
    protected _url: string;
    protected _mode: XMLHttpRequestHandler_requestType;
    protected _onReadyStateFunction: Function;
    protected _obj: any;
    protected _request: XMLHttpRequest;
    protected _requestStartTime: number;

    protected _requestId: number;

    // CONSTRUCTORS
    constructor(requestUrl: string, parameters: Array<Array<string>>, obj: any) {

        this.parameters = parameters;
        this.url = requestUrl;
        this.mode = XMLHttpRequestHandler_requestType.GET;
        this.onReadyStateFunction = function(){return;};
        this.obj = obj;
        this.request = new XMLHttpRequest();
        this.requestId = Math.random();
    }

    public cancelAndReset(){
        if( this.request != null ){
            this.request.abort();
        }
        this.request = new XMLHttpRequest();
        this._requestId = Math.random();
    }

    // PROPERTIES
    get parameters():Array<Array<string>> {
        return this._parameters;
    }
    set parameters(parameters:Array<Array<string>>) {
        this._parameters = parameters;
    }

    get requestId():number {
        return this._requestId;
    }
    set requestId(requestId:number) {
        this._requestId = requestId;
    }

    get url():string {
        return this._url;
    }
    set url(url:string) {
        this._url = url;
    }

    get request():XMLHttpRequest {
        return this._request;
    }
    set request(request: XMLHttpRequest) {
        this._request = request;
    }

    get obj():any {
        return this._obj;
    }
    set obj(obj:any) {
        this._obj = obj;
    }

    get mode():XMLHttpRequestHandler_requestType {
        return this._mode;
    }
    set mode(mode:XMLHttpRequestHandler_requestType) {
        this._mode = mode;
    }

    get onReadyStateFunction():Function {
        return this._onReadyStateFunction;
    }
    set onReadyStateFunction(onReadyStateFunction:Function) {
        this._onReadyStateFunction = onReadyStateFunction;
    }

    get requestStartTime():any {

        return this._requestStartTime;
    }
    set requestStartTime(parameter:any) {

        this._requestStartTime = parameter;
    }

    public execute(): void{

        var time = new Date().getTime();
        this.requestStartTime = time;

        let req_params: string = "";
        let p_separator: string = "?";
        for (let p of this.parameters) {

            req_params += p_separator + p[0] + "=" + encodeURIComponent(p[1]);
            p_separator = "&";
        }

        if(this._mode == XMLHttpRequestHandler_requestType.GET){

             this.request.open("GET", this.url + req_params, true);
             this.request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
             this.request.send();
        }
        else{

            this.request.open("POST", this.url, true);
            this.request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            this.request.send(req_params.substring(1));
        }

        this.request.onreadystatechange = this.onReadyStateFunction(this, this.obj);
    }

    public execute_upload(file): void{

        this.request.open("POST", this.url, true);
        let ext = file.name.slice((Math.max(0, file.name.lastIndexOf(".")) || Infinity) + 1);
        let newName = file.name.replace(/[^a-z\d\s]+/gi, "")+"."+ext;
        this.request.setRequestHeader("X-FILENAME", newName);
        this.request.send(file);

        this.request.onreadystatechange = this.onReadyStateFunction(this, this.obj);
    }
}
