export function arraysMerge(arrayOfObjects: any[]) {


    let mergedResult = [];

    for (let nextObject of arrayOfObjects) {

        for (let route in nextObject) {
            if (mergedResult[route] == null) {
                mergedResult[route] = [];
            }

            mergedResult[route].push(nextObject[route])
        }
    }

    return mergedResult;
}

/*export function downloadCSV(args){
    var data, filename, link;
    data = args.data;
    var csv = convertArrayToCSV({
        data: data
    });
    if (csv == null) return;

    filename = args.filename || 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    data = encodeURI(csv);

    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
}*/

export function downloadCSV(args){
    let data, data2, filename, link;
    data = args.data;
    let csv = convertArrayToCSV({
        data: data
    });
    let blobParts = [csv];
    data2 = args.data2;
    if(data2 != null){
        let csv2 = convertArrayToCSV({
            data: data2
        });
        blobParts = [csv, csv2];
    }

    if (csv == null) return;

    filename = args.filename || 'export.csv';
    /*if (!csv.match(/^data:text\/csv/i)) {
        csv = 'data:text/csv;charset=utf-8,' + csv;
    }*/
    //data = encodeURI(csv);

    let csvData = new Blob(blobParts,{ type: 'text/csv'});
    let csvUrl = URL.createObjectURL(csvData);

    link = document.createElement('a');
    link.setAttribute('href', csvUrl);
    link.setAttribute('download', filename);
    link.click();
}


function convertArrayToCSV(args){
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data == null || !data.length) {
        return null;
    }

    columnDelimiter = args.columnDelimiter || '","';
    lineDelimiter = args.lineDelimiter || '"\n"';

    keys = Object.keys(data[0]);

    result = '"';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function(item) {
        ctr = 0;
        keys.forEach(function(key) {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];
            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}
export function getDateString(date: Date)
{
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    return year + '-' + this.padInteger(month,2) + '-' + this.padInteger(day,2);
}

export function padInteger(number: number, size: number)
{
    let s = number+"";
    while (s.length < size) s = "0" + s;
    return s;
}

export function hideShowColumns(column, colspanValue, colspanMinValue){

    if (column.visible() == true){
        if(colspanValue !== null && colspanMinValue !== null){
            if (colspanValue > colspanMinValue) {
                column.visible(false);
                colspanValue--;
            }
        }

    }
    else{
        column.visible(true);
        colspanValue++;
    }
    return colspanValue;
}
