import { vnAppZone } from "../vnApp/vn_app_zone";
import { arraysMerge } from "../vnHelpers/helper_functions";
import {XMLHttpRequestHandler} from "../XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../XMLHttpRequestHandler/XMLHttpRequestHandler";

declare var page: any;
declare var ko: any;

/*
    This class represents a web app controller. *
    This architecture consider the following concept : an app is a list of zone

    * dependancies : page.js ( routing library )
*/
declare var Promise: any;

export abstract class vnApp {

    // Access types.
    public static readonly ACCESS_TYPE_READ   = 'r';
    public static readonly ACCESS_TYPE_WRITE  = 'w';
    public static readonly ACCESS_TYPE_UPDATE = 'u';
    public static readonly ACCESS_TYPE_DELETE = 'd';

    //MEMBER VARIABLES
    protected _zones: Array<vnAppZone>;
    protected _loggedUser : {user: any, isLoggedIn: boolean, lastIsLoggedIn: boolean};
    protected _params: any;
    protected _routeHistory: Array<string>;
    protected _isLeavePageWarningNeeded: boolean = false;
    protected _beforeUnloadEventListener;

    constructor(zones:Array<vnAppZone>) {
        this.loggedUser = {user: null, isLoggedIn: false, lastIsLoggedIn: false};

        this._routeHistory = [];

        this.zones = zones;

        this.defineRoutes();
        this.addWarningEventListeners();
    }

    // PROPERTIES
    get params():any {
        return this._params;
    }
    set params(params:any) {
        this._params = params;
    }

    get routeHistory():Array<string> {
        return this._routeHistory;
    }
    set routeHistory(params:Array<string>) {
        this._routeHistory = params;
    }

    get isLeavePageWarningNeeded():boolean{
        return this._isLeavePageWarningNeeded;
    }

    set isLeavePageWarningNeeded(isLeavePageWarningNeeded:boolean){
        this._isLeavePageWarningNeeded = isLeavePageWarningNeeded;
    }

    addRouteHistory(params:string) {

        this._routeHistory.push(params);
        if( this._routeHistory.length > 5 ){

            this._routeHistory.shift();
        }
    }

    getLastRoute() {

        if( this._routeHistory[this._routeHistory.length-2] ){

            return this._routeHistory[this._routeHistory.length-2];
        }
        else{

            // default to home "/"
            return "/";
        }
    }

    getCurrentRoute(){

        if( this._routeHistory[this._routeHistory.length-1] ){

            return this._routeHistory[this._routeHistory.length-1];
        }
        else{

            // default to home "/"
            return "/";
        }
    }

    get zones():Array<vnAppZone> {
        return this._zones;
    }
    set zones(zones:Array<vnAppZone>) {
        this._zones = zones;
    }

    get loggedUser():{user: any, isLoggedIn: boolean, lastIsLoggedIn: boolean} {
        return this._loggedUser;
    }
    set loggedUser(loggedUser:{user: any, isLoggedIn: boolean, lastIsLoggedIn: boolean}) {
        this._loggedUser = loggedUser;
    }


    public abstract getIsLoggedInHttpCallUrl();

    public getIsLoggedInHttpCallParameters(){
        return [];
    }

    public getIsLoggedInHttpCallResponse(rawResponse : any){
        return rawResponse;
    }

    public getZoneByName(name: string){
        for( let z of this.zones ){
            if( z.zoneName == name ){
                return z;
            }
        }
        return null;
    }

    setLoggedInStatus(){

        let thisApp = this;
        let promiseObj = new Promise(function (fullfill, reject) {

            //setTimeout(function(){ console.log("Hello"); }, 5000);

            // loading the page if the user is logged in
            let loginReq_url: string = thisApp.getIsLoggedInHttpCallUrl();
            let loginReq_Params: Array<Array<string>> = thisApp.getIsLoggedInHttpCallParameters();

            let contextPageCtrl = this;
            let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(loginReq_url,loginReq_Params, this);

            XHRHdl.onReadyStateFunction = (function(req, obj){

                return function() {

                    if (req.request.readyState == 4) {

                        if(  req.request.status == 200  ) {

                            let responseParsed = thisApp.getIsLoggedInHttpCallResponse(JSON.parse(req.request.response));
                            console.log(responseParsed);
                            let lastLog = thisApp.loggedUser.isLoggedIn;

                            if (responseParsed.isLoggedIn === true) {


                                thisApp.loggedUser =  responseParsed;
                                thisApp.loggedUser.lastIsLoggedIn = lastLog;
                                thisApp.loggedUser.isLoggedIn = responseParsed.isLoggedIn;

                                console.log("User session is active.");

                            }
                            else {

                                thisApp.loggedUser.user = null;
                                thisApp.loggedUser.lastIsLoggedIn = lastLog;
                                thisApp.loggedUser.isLoggedIn = false;

                                console.log("User session is NOT active.");
                            }
                            fullfill();
                        }
                        else{
                            console.log("User session is NOT active.");
                            reject();
                        }

                    }
                }
            });
            XHRHdl.execute();
        });

        return promiseObj;
    }

    //
    // Define all the routes in the "page" library
    // for this app, so, for all the zones
    defineRoutes(): void {

        //
        // Obtain all the zone's arrays of [path, function]
        // ** while we get all the routesActions we gather the different valid paths
            let tempRoutes = [];
            let validRoutes : string[] = [];
            for (let zone of this.zones) {
                tempRoutes.push(zone.routesActions);

                for(let actions in zone.routesActions){
                    if( validRoutes.indexOf(actions) === -1 ){
                        validRoutes.push(actions);
                    }
                }
            }

        //
        // Apply the "ALL" routes settings
        // if a zone has an action setted to "all"
        // we automaticaly add it to all valid path that does not have an action already
            for( let r of tempRoutes ){
                // does that zone have a "all" script
                if ("all" in r) {
                    for(let v of validRoutes){

                        if( !(v in r) ){
                            r[v] = r["all"];
                        }
                    }
                }
            }

        //
        // Merge all the arrays of [path, function] in one array
        // ** the "page" lib does not cumulate all actions for the same path
        // so we do it before setting the routes
            let mergedRoutes : any[];
            mergedRoutes = arraysMerge(tempRoutes);

        //
        // For each of the route present in the merged array
        // add all the functions as calls on the "page" library event
            for(let route in mergedRoutes ) {

                let routeActions = mergedRoutes[route];
                let _this = this;

                page(route, function (ctx) {

                    _this.params = ctx.params;

                    let bodyClasses = [];

                    // Get subdomain for body class
                    let host = window.location.host;
                    let hostParts = host.split('.');
                    if (hostParts.length > 2){

                        let subdomain = hostParts[0];
                        bodyClasses.push(subdomain);
                    }

                    // Get route for body class
                    let routeClasses = route.replace('/', ' _').replace(/\//g, ' _').split(' ');
                    bodyClasses = bodyClasses.concat(routeClasses);

                    // Set subdomain and route in body class
                    document.getElementsByTagName("body")[0].className = bodyClasses.join(' ');

                    let setLoginPromise = _this.setLoggedInStatus();

                    setLoginPromise.then( function(ctx){

                    _this.addRouteHistory(window.location.pathname);

                    for(let action of routeActions ){

                        if( _this.loggedUser.isLoggedIn || action[1] == null){

                                 action[0].call();
                            }
                            else
                            {
                                 action[1].call();
                            }
                        }
                    }, function(){ console.log("Error in the login"); } );
                });
            }

            page('*', function () {

                page.redirect('/?redirected=1');
            });

        page.start();
    }
    public addWarningEventListeners(){
        let self = this;

        window.addEventListener('popstate', function(event){
            self.popStateFunction(self.isLeavePageWarningNeeded, self.zones, event);
        });


        self._beforeUnloadEventListener = event => {
            event = event || window.event;

            if(self.isLeavePageWarningNeeded){

                if(event){
                    event.returnValue=' ';
                    return event;
                }
                if(!event.defaultPrevented){
                    event.returnValue = null;
                }
            }
            return event;

        };

        window.addEventListener('beforeunload', self._beforeUnloadEventListener);
    }

    public removeWarningEventListeners(){
        let self = this;
        window.removeEventListener('beforeunload', self._beforeUnloadEventListener);
    }

    public popStateFunction(isLeavePageWarningNeeded: boolean, zones: Array<vnAppZone>, event){

        if(isLeavePageWarningNeeded){
            if (!confirm("You are about to leave the page. Any unsaved changes will be lost.")){
                //pushing the current state of the web app so it doesn't trigger a reload
                history.pushState(true, null,null);
                return event;
            } else {
                for(let zone of zones){
                    zone.loadedModule.cancelForm();
                }
            }
        }
    }

    public windowOnBeforeUnloadFunction(event,isLeavePageWarningNeeded){
        event = event || window.event;

        if(isLeavePageWarningNeeded){

            if(event){
                event.returnValue=' ';
                return event;
            }
            if(!event.defaultPrevented){
                event.returnValue = null;
            }
        }
        return event;
    }

    public isFirstLoad(){

        return this._routeHistory.length == 1;
    }
}
