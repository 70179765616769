import { logsList_template } from '../vnKnockoutJsTemplates/logsList_template';
import { vnModule_knockoutJS } from './vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../XMLHttpRequestHandler/XMLHttpRequestHandler';
import {header} from "./header";

declare var ko: any;

export class logs extends vnModule_knockoutJS{

    protected elementsList: logsList_template;
    protected XHRHdl: XMLHttpRequestHandler;

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'logs';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Logs");

        this.elementsList = new logsList_template('logs', this.viewModel, this)

        return [this.elementsList.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getListFormData();
    }

    /**
     * @inheritDoc
     */
    protected reorderLogs(): void{

        this.getListData();
    }

    /**
     * Requests data for the filters.
     */
    protected getListFormData(): void{

        // this.loadingIn(this.elementsList.builtTemplate.children.namedItem('list-results'));
        this.loadingIn(this.elementsList.builtTemplate.children.namedItem('list-results'));
        let params: Array<Array<string>> = [];
        params.push(["data", ko.toJSON(this.elementsList.getViewModelForRequest())] );

        this.XHRHdl = new XMLHttpRequestHandler('/api/logs/list_from_data/', params, this);
        this.XHRHdl.onReadyStateFunction = this.onFilterRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for filter request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onFilterRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);
                obj.updateFilters(responseParsed);
                obj.getListData();
            });
        }
    }

    /**
     * Fetches the list data.
     */
    public getListData(): void{

        this.loadingIn(this.elementsList.builtTemplate.children.namedItem('list-results'));

        if('getListData' in this.XHRHdl){

            this.XHRHdl['getListData'].cancelAndReset();
        }

        let subViewModel = this.elementsList.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(['data', ko.toJSON(subViewModel)]);

        console.log(usrReq_params);

        this.XHRHdl['getListData'] = new XMLHttpRequestHandler('/api/logs/get/' ,usrReq_params, this);
        this.XHRHdl['getListData'].onReadyStateFunction = this.onDataRequestReturn;
        this.XHRHdl['getListData'].execute();

    }

    /**
     * Returns handler for data request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);
                console.log(responseParsed);
                obj.updateList(responseParsed);
                obj.loadingOut(obj.elementsList.builtTemplate.children.namedItem('list-results'));
            });
        }
    }

    /**
     * Update the module list using the objects list.
     *
     * @param responseParsed
     */
    protected updateList(responseParsed: any) : void{

        this.loadingIn(this.elementsList.builtTemplate.children.namedItem('list-results'));
        this.elementsList.setPageNumber(responseParsed.currentPage);
        this.elementsList.setPagination(responseParsed.totalCount);
        this.elementsList.setResultPage(responseParsed.totalCount);
        this.elementsList.setLogs(responseParsed.data);
        this.elementsList.resetTrackedLogId();
    }

    private resetPageNumber(): void{

        this.elementsList.resetPageNumber();
    }

    private resetOrderParameters(): void{

        this.elementsList.resetOrderParameters();
    }

    /**
     * Update the filters.
     *
     * @param responseParsed
     */
    protected updateFilters(responseParsed: any): void{

        this.elementsList.setAccountFilterItems(responseParsed['executors']);

        let operationsList = this.prepareOperationsList(responseParsed['operations']);
        this.elementsList.setOperationFilterItems(operationsList);
        // this.elementsList.setOperationFilterItems(responseParsed['operations']);
    }

    /**
     * Format the list of operations for display
     *
     * @param responseParsed
     */
    private prepareOperationsList(operations: Array<any>): Array<any>{

        operations.forEach(function(operation, i){
            //replace the id with the name for search
            operations[i]['id'] = operation['name'];
            //format the name for display
            operations[i]['name'] = operation['name'].replace(/_/g, ' ');
        });
        return operations;

    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public filterDropdownOnChange(): void{

        this.resetPageNumber();
        this.resetOrderParameters();
        this.resetTrackedLogId();
        this.getListData();
    }

    public resetTrackedLogId(): void{

        this.elementsList.resetTrackedLogId();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public paginationOnPageClick(): void{

        this.getListData();
    }

    /**
     * Hook.
     *
     * Cancels data request.
     */
    public filterSimpleCancel(): void{

        this.resetPageNumber();
        this.resetOrderParameters();
        this.getListData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public filterSimpleOnChange(): void{

        this.resetPageNumber();
        this.resetOrderParameters();
        this.getListData();
    }

    /**
     * Hook.
     *
     * refresh the data on request
     */
    public autoRefresh(){
        this.getListData();
    }
}