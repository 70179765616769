import { vnTemplate } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate';
import { pagination_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/pagination_template';
import { resultsInfo_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/resultsInfo_template';
import { filterSimple_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterSimple_template";
import {vnApp_ads_ninja} from "../vnApp_ads_ninja";
import {filterDropdown_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDropdown_template";

declare var ko: any;

export class bidderTypeLibraryList_template extends vnTemplate{

    protected searchFilter: filterSimple_template;
    protected pagination: pagination_template;
    protected deviceFilter: filterDropdown_template;
    protected resultsInfo: resultsInfo_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
        <div class="list-header w-justify margin-top"> 
            
            <div class="w-left">
                <div data-bind="if: hasWriteAccess">
                    <button data-bind="click: onClickAdd" class="btn icon i-add" type="button">Add Bidder Type Library</button>
                </div>    
                <!--<div>
                    <label>Device</label>
                    ${this.deviceFilter.getHtmlBuild()}
                </div>-->
            </div>
            <div class="w-center">
                ${this.searchFilter.getHtmlBuild()}
            </div>           
            <div class="w-right">
                ${this.pagination.getHtmlBuild()}          
            </div>
        </div>
        <div class="w-list-results" id="list-results">
            <table class="list-results">
                ${this.resultsInfo.getHtmlBuild()}
                <thead> 
                    <tr>    
                        <th class="short" data-bind="visible: hasUpdateAccess"></th>  
                        <th class="w-btn" data-bind="visible: hasUpdateAccess"><span>edit</span></th>
                        <th><span class="icon i-position"></span></th>      
                        <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'isEnabled') }, css: { 'list-sort-desc': orderByAndDirection()=='isEnabled_DESC', 'list-sort-asc': orderByAndDirection()=='isEnabled_ASC' }">status</span></th> 
                        <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'name') }, css: { 'list-sort-desc': orderByAndDirection()=='name_DESC', 'list-sort-asc': orderByAndDirection()=='name_ASC' }">name</span></th>
                        <th><span>library</span></th>
                        <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'device') }, css: { 'list-sort-desc': orderByAndDirection()=='device_DESC', 'list-sort-asc': orderByAndDirection()=='device_ASC' }">device</span></th>
<!--                        <th><span>extra<br />parameters</span></th>-->
                        <th><span>ads setup tag</span></th>
                        <th><span>Geo Group</span></th>
                        <th><span>bidder type</span></th>
<!--                        <th class="w-btn" data-bind="visible: hasDeleteAccess"><span>Delete</span></th>-->
                   </tr> 
                </thead>
                <tbody data-bind="foreach: sortable" class="drag-handles sortable">  
                     <tr data-bind="css: { dragging: dragging },
                                       dragZone: { name: 'sortable',
                                         dragStart: $parent.dragStart,
                                         dragEnd: $parent.dragEnd
                                       },
                                       dragEvents: {
                                         accepts: 'sortable',
                                         dragOver: $parent.reorderSortable,
                                         data: { items: $parent.sortable, item: $data }
                                       }">
                        <td class="drag-handle short" data-bind="visible: $parent.hasUpdateAccess"><span class="btn no-bg icon i-move"></span></td>
                        <td class="" data-bind="visible: $parent.hasUpdateAccess">
                            <button data-bind="visible: $parent.hasUpdateAccess, event: { click: $parent.onClickEdit }" class="btn icon-only icon i-edit" type="button"><span class="hidden-text">Edit</span></button>
                            <!-- ko if: 1 == value.bidderType.id -->
                                <button data-bind="visible: $parent.hasUpdateAccess, event: { click: $parent.onClickEditPrebid }" class="btn icon-only icon i-prebid-conf" type="button"><span class="hidden-text">Prebid Configurator</span></button>
                            <!-- /ko -->
                        </td>
                        <td class=""><span data-bind="text: value.position"></span></td>
                        <td class=""><span data-bind="text: $parent.processResultStatus(value.isEnabled)"></span></td>                        
                        <td class=""><span data-bind="text: value.name"></span></td>
                        <td class=""><span data-bind="text: value.bidderType.name"></span></td>
                        <td class=""><span data-bind="text: $parent.processResult(value.device)"></span></td>
<!--                        <td class=""><span data-bind="text: $parent.processResultExtraParams(extraParameters)"></span></td>-->
                        <td class=""><span data-bind="text: $parent.processResultAdsSetupTag(value.adsSetupTag)"></span></td>
                        <td class=""><span data-bind="text: $parent.processResultGeoGroup(value.geoGroup)"></span></td>
                        <td class=""><span class="line-clamp" data-bind="text: value.headerDependancy"></span></td>
<!--                        <td class="align-right" data-bind="visible: $parent.hasDeleteAccess"><button data-bind="visible: $parent.hasDeleteAccess, event: { click: $parent.onClickDelete }" class="btn icon-only icon i-delete" type="button"><span class="hidden-text">Delete</span></button></td>-->
                    </tr>      
                </tbody>
            </table>
        </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{

        this.deviceFilter = new filterDropdown_template('deviceFilter', this.getTemplateViewModel(), this.caller);
        this.searchFilter = new filterSimple_template('searchFilter', this.getTemplateViewModel(), this.caller);
        this.resultsInfo  = new resultsInfo_template('resultsInfo', this.getTemplateViewModel(), this.caller);

        this.pagination = new pagination_template('pagination', this.getTemplateViewModel(), this.caller);
        this.pagination.getTemplateViewModel().itemPerPage = 25;
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return 'bidderTypeLibraryList_template';
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return [];
    }

    /**
     * @inheritDoc
     */
    public activateLoading(): void{

        this.getTemplateViewModel().isLoading(1);
    }

    /**
     * @inheritDoc
     */
    public deactivateLoading(): void{

        this.getTemplateViewModel().isLoading(0);
    }

    /**
     * @inheritDoc
     */
    public buildViewModel(): Object{

        let self = this;

        return {
            orderBy: ko.observable('position'),
            orderByDirection: ko.observable('ASC'),
            orderByAndDirection: ko.observable('position_ASC'),
            bidderTypes: ko.observableArray([]),
            hasUpdateAccess: ko.observable(this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_BIDDER_TYPE, vnApp_ads_ninja.ACCESS_TYPE_UPDATE)),
            hasDeleteAccess: ko.observable(this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_BIDDER_TYPE, vnApp_ads_ninja.ACCESS_TYPE_DELETE)),
            hasWriteAccess: ko.observable(this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_BIDDER_TYPE, vnApp_ads_ninja.ACCESS_TYPE_WRITE)),
            onClickReorder: function(value, data, event){ self.onClickReorder(value, data, event); },
            onClickEdit: function(data, event){ self.onClickEdit(data.value.id, data.value.bidderType.id); },
            onClickEditPrebid: function(data, event){ self.onClickEditPrebid(data.value.id, data.value.bidderType.id); },
            onClickAdd: function(){ self.onClickAdd(); },
            onClickDelete: function(data){ self.onClickDelete(data); },
            processResult: function(value){ return (!value) ? 'all' : value },
            // processResultExtraParams: function(value){ return JSON.stringify(value) },
            sortable: ko.observableArray([]),
            isFiltered: ko.observable(false),
            processResultAdsSetupTag: function(value){ return (!value) ? 'none' : value.name },
            processResultGeoGroup: function(value){return (!value) ? 'All' : value.name},
            processResultStatus: function(value){ return (value) ? 'active' : 'inactive' },
            dragStart: function(data, event){ self.startDrag(data, event); },
            dragEnd: function(data){ data.dragging(false); self.updatePositions() },
            reorderSortable: function(event, dragData, zoneData){ self.reorderSortable(event, dragData, zoneData); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        let sortable           = this.getTemplateViewModel().sortable();
        let sortableForBackend = [];

        if(typeof sortable !== "undefined" && sortable !== null && Object.keys(sortable).length !== 0){

            for(let currentSortable of sortable){

                sortableForBackend.push({id: currentSortable.value.id, position: currentSortable.value.position});
            }
        }

        return {
            pagination: this.pagination.getTemplateViewModel().pageNumber(),
            orderBy: this.getTemplateViewModel().orderBy(),
            orderByDirection: this.getTemplateViewModel().orderByDirection(),
            filters: {
                searchString: this.searchFilter.getTemplateViewModel().filterKeyword(),
                selectedDevice: this.deviceFilter.getTemplateViewModel().selectedOption(),
            },
            sortable: sortableForBackend,
            isFiltered: this.getTemplateViewModel().isFiltered(),
        };
    }

    //
    // Handles reordering on click event.
    public onClickReorder(value, data, event): void{

        this.getTemplateViewModel().orderBy(value);

        if( this.getTemplateViewModel().orderByDirection() == 'DESC' ){

            this.getTemplateViewModel().orderByDirection('ASC');
        }
        else{

            this.getTemplateViewModel().orderByDirection('DESC');
        }

        this.getTemplateViewModel().orderByAndDirection(value + '_' + this.getTemplateViewModel().orderByDirection());
        this.caller.reorderBidderTypes();
    }

    public setPagination(totalCount: number): void{

        this.pagination.setNumberItemToPaginate(totalCount);
    }

    public resetPageNumber(): void{

        this.getTemplateViewModel().pagination.pageNumber(1);
    }

    public resetOrderParameters(): void{

        this.getTemplateViewModel().orderBy('');
        this.getTemplateViewModel().orderByDirection('');
        this.getTemplateViewModel().orderByAndDirection('');
    }

    //
    // Sets data.
    public setData(data: Array<any>): void{

        this.getTemplateViewModel().bidderTypes(data);

        let result = this.toDraggable(data);

        this.getTemplateViewModel().sortable(result);
    }

    public setResultInfo(nbResults, nbPages, execTime){

        this.resultsInfo.setExecutionTime(execTime);
        this.resultsInfo.setNbResults(nbResults);
        this.resultsInfo.setNbPages(nbPages);
    }

    public updatePagination(totalCount){

        this.pagination.setNumberItemToPaginate(totalCount);
    }

    //
    // Handles on edit button click event.
    public onClickEdit(id, idBidderType){

        this.caller.edit(id, idBidderType);
    }

    //
    // Handles on edit button click event.
    public onClickEditPrebid(id, idBidderType){

        this.caller.editPrebid(id, idBidderType);
    }

    public setDeviceFilterItems(devices): void{

        let devicesList = [];

        for(let device of devices){
            devicesList.push(device);
        }

        devicesList.unshift({'id' : 0, 'name' : 'all'});

        this.deviceFilter.setOptions(devicesList);
    }

    /**
     * Handles the add user action.
     */
    public onClickAdd(){

        this.caller.add();
    }

    /**
     * Handles on delete button click event.
     */
    public onClickDelete(data){

        this.caller.confirmationModal.setTitle('Confirm Deletion');
        this.caller.confirmationModal.setContext('delete');
        this.caller.confirmationModal.setMessageMain('Are you sure you would like to delete this bidder type library?');
        this.caller.confirmationModal.setDataConfirm(data.id);
        this.caller.confirmationModal.setMessageSecondary(data.name);
        this.caller.confirmationModal.show();
    }

    public startDrag(data, event){

        try{

            this.executeDrag(data, event);
        }
        catch(err){

            throw new Error(err.message);
        }
    }

    public executeDrag(data, event) {

        if(event.srcElement.className.indexOf('i-move') !== -1){

            data.dragging(true);
            return true;
        }
        else{

            throw new Error('drag only allowed from the handle');
        }
    }

    public reorderSortable(event, dragData, zoneData): void{

        if(dragData !== zoneData.item){

            var zoneDataIndex = zoneData.items.indexOf(zoneData.item);

            zoneData.items.remove(dragData);
            zoneData.items.splice(zoneDataIndex, 0, dragData);
        }
    }

    /**
     * Handles dragstop event mainly to update adsSetups positions.
     */
    public updatePositions(){

        this.caller.updatePositions();
    }

    private toDraggable(values){

        return ko.utils.arrayMap(values, function (value){

            return {
                value: value,
                dragging: ko.observable(false),
                isSelected: ko.observable(false)
            };
        });
    }

    public setIsFiltered(value){

        this.getTemplateViewModel().isFiltered(value);
    }
}