import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import {header} from "./header";
import {adsSetupTagEdit_template} from "../components/templates/adsSetupTagEdit_template";

declare var ko: any;
declare var page: any;

export class adsSetupTag_edit extends vnModule_knockoutJS{

    protected requestHandler: XMLHttpRequestHandler;
    protected template: adsSetupTagEdit_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return 'adsSetupTag_edit';
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName('Header');
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle('Ads Setup Tag Edit');

        // create the users form
        this.template = new adsSetupTagEdit_template('adsSetupTagEdit_template', this.viewModel, this);

        return [this.template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getEditData();
    }

    //
    // Hook.
    // Cancels the edits to a user.
    protected cancel(){

        this.loadingIn(this.template.builtTemplate);

        this.releaseLock();

        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "Content type action canceled!");

        page.redirect(`/ads_setup_tag`);
    }

    //
    // Requests a lock release.
    protected releaseLock(): void{

        let formReq_params: Array<Array<string>> = [];

        // Request a lock release.
        this.requestHandler = new XMLHttpRequestHandler(`/api/ads_setup_tag/${this.getVnAppZone().app.params.id}/release_lock/`, formReq_params, this);
        this.requestHandler.execute();
    }


    //
    // Obtain the dynamic data required to fill the form.
    protected getEditData(takeover: boolean = false): void{

        this.loadingIn(this.template.builtTemplate);

        let id = this.getVnAppZone().app.params.id || null;

        console.log(id);

        this.requestHandler = new XMLHttpRequestHandler(`/api/ads_setup_tag/get/${id}/`, [['takeover', (takeover) ? '1' : '0']], this);
        this.requestHandler.onReadyStateFunction = this.onEditDataRequestReturn;
        this.requestHandler.execute();

    }

    //
    // Handles the form data response.
    protected onEditDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditData(responseParsed);
                obj.loadingOut(obj.template.builtTemplate);
            });
        }
    }

    //
    // Updates the form elements.
    protected updateEditData(responseParsed: any){

        this.template.setData(responseParsed.data);
    }

    //
    // Hook.
    // Saves the edits to an entity.
    protected save(): void{

        this.loadingIn(this.template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.template.getViewModelForRequest();

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let requestHandler:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/ads_setup_tag/save/`, formReq_params, this);
        requestHandler.mode                      = XMLHttpRequestHandler_requestType.POST;
        requestHandler.onReadyStateFunction      = this.onSaveReturn;
        requestHandler.execute();
    }

    //
    // Handles save response.
    protected onSaveReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'content type created successfully!');
                obj.releaseLock();
                page.redirect(`/ads_setup_tag/`);
            });

            obj.loadingOut(obj.template.builtTemplate);
        }
    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.getEditData(true);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }

    //
    // Obtain the dynamic data required to fill the form.
    protected getEditFormData(): void{

        this.loadingIn(this.template.builtTemplate);

        this.requestHandler = new XMLHttpRequestHandler(`/api/ads_setup_tag/references/`, [], this);
        this.requestHandler.onReadyStateFunction = this.onEditFormDataRequestReturn;
        this.requestHandler.execute();
    }

    //
    // Returns handler for edit form request.
    protected onEditFormDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditFormData(responseParsed);
                obj.loadingOut(obj.template.builtTemplate);
                obj.getEditData();
            });
        }
    }

    //
    // Update form data.
    protected updateEditFormData(responseParsed: any): void{

    }
}
