import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class geoGroupEdit_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <!-- ko ifnot: idGeoGroup -->
                <h3 class="form-title icon i-add">Add New GEO Group</h3>
                <!-- /ko -->

                <!-- ko if: idGeoGroup -->
                <h3 class="form-title icon i-geogroup">edit GEO Group</h3>
                <!-- /ko -->

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
            <div class="w-form-body"> 
                <div class="form-body">
                    <div class="form-group row">
                        <label for="name" class="form-label">name</label>
                        <input id="name" data-bind="value: name" type="text" class="input-text">
                     </div>
                     <div class="form-group">
                        <hr class="form-hr">
                     </div>
                     <div class="form-group">
                        <label class="form-sub-title">Country</label>
                        <div data-bind="foreach: allGeos">
                            <div class="form-check-inline">
                                <input type="checkbox" data-bind="checked: $parent.geos(), value: id, attr:{'id' : 'geo_' + id, 'name' : 'geo_' + id}">
                                <label class="form-label icon i-check" data-bind="text: name, attr:{'for': 'geo_' + id}"></label>
                            </div>
                        </div>
                     </div>
                </div>   
            </div>
            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "geoGroupEdit-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;

        return {
            idGeoGroup: ko.observable(''),
            name: ko.observable(''),
            geos: ko.observableArray([]),
            allGeos: ko.observableArray([]),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); }
        };

    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            idGeoGroup: this.getTemplateViewModel().idGeoGroup(),
            name: this.getTemplateViewModel().name(),
            geos: this.getTemplateViewModel().geos()
        };
    }

    public setGeoGroup(geoGroup): void{

        this.getTemplateViewModel().idGeoGroup(geoGroup.id);
        this.getTemplateViewModel().name(geoGroup.name);
        this.getTemplateViewModel().geos(geoGroup.geos);

    }


    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        this.caller.saveEditGeoGroup();
    }


    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancelGeoGroup();
    }
}
