import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import { sspGroupEdit_template } from "../components/templates/sspGroupEdit_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class sspGroup_edit extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected sspGroupEdit_template: sspGroupEdit_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return "sspGroup_edit";
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("SSP Group Edit");

        // create the users form
        this.sspGroupEdit_template = new sspGroupEdit_template("sspGroupEdit_template", this.viewModel, this);

        return [this.sspGroupEdit_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getEditFormData();
    }

    /**
     * Hook.
     *
     * Saves the edits to a user.
     */
    protected saveEditSspGroup(): void{

        this.save();
    }

    /**
     * Hook.
     *
     * Cancels the edits to a user.
     */
    protected cancelSspGroup(){

        this.loadingIn(this.sspGroupEdit_template.builtTemplate);

        this.releaseLock();

        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "Source action canceled!");

        page.redirect(`/brand/${this.getVnAppZone().app.params.idBrand}/ssp_groups`);
    }

    /**
     * Requests a lock release.
     */
    protected releaseLock(): void{

        let formReq_params: Array<Array<string>> = [];

        // Request a lock release.
        this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ssp_groups/${this.getVnAppZone().app.params.id}/release_lock/`, formReq_params, this);
        this.XHRHdl.execute();
    }

    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditFormData(): void{

        this.loadingIn(this.sspGroupEdit_template.builtTemplate);

        this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ssp_groups/references/`, [], this);
        this.XHRHdl.onReadyStateFunction = this.onEditFormDataRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for edit form request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onEditFormDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditFormData(responseParsed);
                obj.getEditData();
            });
        }
    }

    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditData(takeover: boolean = false): void{

        this.loadingIn(this.sspGroupEdit_template.builtTemplate);

        let id = this.getVnAppZone().app.params.id || null;

        this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ssp_groups/get/${id}/`, [['takeover', (takeover) ? '1' : '0']], this);
        this.XHRHdl.onReadyStateFunction = this.onEditDataRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onEditDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateEditData(responseParsed);
                obj.loadingOut(obj.sspGroupEdit_template.builtTemplate);
            });
        }
    }

    /**
     * Update the filters.
     *
     * @param responseParsed
     */
    protected updateEditFormData(responseParsed: any): void{

        this.sspGroupEdit_template.getTemplateViewModel().allSsps(responseParsed.ssps);
        this.sspGroupEdit_template.setDevicesOptionItems(responseParsed.devices);
        this.sspGroupEdit_template.setAdsSetupTagOptionItems(responseParsed.adsSetupTags);
    }

    /**
     * Updates the form elements.
     *
     */
    protected updateEditData(responseParsed: any){

        this.setSspGroup(responseParsed.data);
        // this.sspGroupEdit_template.getTemplateViewModel().extraParameters(this.formatExtraParametersForFrontend(responseParsed.data.extraParameters));
    }

    //
    // Sets SSP groups.
    protected setSspGroup(sspGroup){

        sspGroup.ssps = sspGroup.ssps_list_id || [];
        this.sspGroupEdit_template.setSspGroup(sspGroup);
    }

    // //
    // // Format extra params.
    // private formatExtraParametersForFrontend(extraParameters){
    //
    //     let extraParametersForTemplate = [];
    //
    //     if(extraParameters){
    //
    //         for(let extraParameter of extraParameters){
    //
    //             extraParametersForTemplate.push({'name': Object.keys(extraParameter)[0], 'value': extraParameter[Object.keys(extraParameter)[0]]});
    //         }
    //     }
    //
    //     return extraParametersForTemplate;
    // }
    //
    // //
    // // Format extra params.
    // private formatExtraParametersForBackend(extraParameters){
    //
    //     let extraParametersForBackend = [];
    //
    //     for(let extraParameter of extraParameters){
    //
    //         let newExtraParameter                  = {};
    //         newExtraParameter[extraParameter.name] = extraParameter.value;
    //
    //         extraParametersForBackend.push(newExtraParameter);
    //     }
    //
    //     return extraParametersForBackend;
    // }

    /**
     * Saves the source data.
     */
    protected save(): void{

        this.loadingIn(this.sspGroupEdit_template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.sspGroupEdit_template.getViewModelForRequest();

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ssp_groups/save/`, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveReturn;
        XHRHdl.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'ssp group created successfully!');
                obj.releaseLock();
                page.redirect(`/brand/${obj.getVnAppZone().app.params.idBrand}/ssp_groups/`);
            });

            obj.loadingOut(obj.sspGroupEdit_template.builtTemplate);
        }
    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.getEditData(true);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public filterDropdownOnChange(): void{

    }
}
