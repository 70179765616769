import {vnTemplate} from "./vnTemplate";

declare var ko: any;

export class infoButton_template extends vnTemplate{

    protected getTemplateHtml(): string{

        return `
            <div data-bind="" style="position: relative">
                <!-- ko ifnot: buttonLabel -->
                    <button type="button" class="open-button icon i-help" data-bind="event: { click: onClickToggle }"></button>
                <!-- /ko -->
                <!-- ko if: buttonLabel -->
                    <button type="button" class="open-button" data-bind="text: buttonLabel, event: { click: onClickToggle }"></button>
                <!-- /ko -->
                <div class="tooltips-adv" data-bind="visible: isVisible" >      
                    <h3 class="tooltips-adv-title " data-bind="text: title"></h3>
                    <div class="tooltips-adv-content" data-bind="html: visibleContent"></div>
                    <button type="button" class="close-button icon i-x-after" data-bind="event: { click: onClickClose }"></button>
                </div>
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return 'infoButton-template';
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ['infoButton'];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any {

        let self = this;

        return {
            isVisible: ko.observable(false),
            buttonLabel: ko.observable(''),
            title: ko.observable(''),
            content: ko.observable(''),
            visibleContent: ko.observable(''),
            onClickToggle: function(){ self.toggleVisibility(this); },
            onClickClose: function(){ this.isVisible(false); },
        };
    }

    //
    // Sets the button label.
    public setButtonLabel(buttonLabel){

        this.getTemplateViewModel().buttonLabel(buttonLabel);
    }

    //
    // Sets the title.
    public setTitle(title){

        this.getTemplateViewModel().title(title);
    }

    //
    // Sets the content.
    public setContent(content){

        this.getTemplateViewModel().content(content);
    }

    //
    // Hides tooltip.
    public hide(){

        this.getTemplateViewModel().isVisible(false);
    }

    //
    // Toggle tooltip.
    protected toggleVisibility(viewModel): void{

        if(this.viewModel.lastButtonClicked && this.viewModel.lastButtonClicked !== this.idElement){

            this.viewModel[this.viewModel.lastButtonClicked].isVisible(false);
        }

        if('' === viewModel.visibleContent() || viewModel.visibleContent() !== viewModel.content()){

            viewModel.visibleContent(viewModel.content());
        }

        viewModel.isVisible(!viewModel.isVisible());

        this.viewModel.lastButtonClicked = this.idElement;
    }
}
