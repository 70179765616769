import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";
import { filterDropdown_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDropdown_template';

declare var ko: any;

export class sspEdit_template extends vnTemplate{

    protected biddingStyleOption: filterDropdown_template;
    protected sspBidAdapter: filterDropdown_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

    return `
        <div class="form-header">
            <!-- ko ifnot: idSsp -->
            <h3 class="form-title icon i-add">Add New ssp</h3>
            <!-- /ko -->

            <!-- ko if: idSsp -->
            <h3 class="form-title icon i-ssp">edit ssp</h3>
            <!-- /ko -->

            <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
        </div>
 
        <div class="w-form-body">
             <div class="form-body full-width">
                <!-- ko ifnot: idSsp -->
                <div class="form-group">
                    <label class="form-label">advertiser name</label>
                    <input data-bind="value: advertiserName" type="text" class="input-text">
                </div>
                <!-- /ko -->
             
                <div class="form-group">
                    <label class="form-label">code</label>
                    <input data-bind="value: name" type="text" class="input-text">
                </div>
                      
                <!-- ko if: alias -->
                <div class="form-group">
                    <label class="form-label">alias</label>
                    <input data-bind="value: alias" type="text" class="input-text" disabled>
                </div>
                <!-- /ko -->
                
                <div class="form-group row">
                    <label class="form-label">Bidding Style</label>
                    ${this.biddingStyleOption.getHtmlBuild()}
                </div>
                
                <div class="form-group row">
                    <label class="form-label">SSP Bid Adapter</label>
                    ${this.sspBidAdapter.getHtmlBuild()}
                </div>
                
                <!-- ko ifnot: idSsp -->
                <div class="form-group row ">
                    <label class="form-label">Creative Type</label>
                    <div>
                        <div class="form-check-inline" style="width: auto;margin-right: 2rem;"> 
                            <input type="radio" name="creativeType" id="banner" value="banner" data-bind="checked: creativeType">
                            <label class="form-label" style="width: auto" for="banner">Banner</label>
                        </div>
                        <div class="form-check-inline" style="width: auto;margin-right: 2rem;"> 
                            <input type="radio" name="creativeType" id="video" value="video" data-bind="checked: creativeType">
                            <label class="form-label" style="width: auto" for="video">Video</label>
                        </div>
                    </div>
                </div>  
                <!-- /ko -->
             
                <div class="form-group border row adding">
                    <label class="form-label full">labels params</label>
                    <input data-bind="value: newLabelName" type="text" class="input-text">
                    <select class="short" data-bind="
                            options: allTypes,
                            optionsText: 'name',
                            optionsValue: 'id',
                            value: newLabelType
                        ">
                    </select>
                    <button data-bind="click: onClickAddLabel" class="btn icon-only icon i-add" type="button"></button>
                    
                </div>
                
                    
                <div data-bind="foreach: labels">
                    <div class="form-group row adding"> 
                        <input data-bind="value: name" type="text" class="input-text">                     
                        <select data-bind="
                                options: $parent.allTypes,
                                optionsText: 'name',
                                optionsValue: 'id',
                                value: type
                            ">
                        </select>
                        <button data-bind="click: $parent.onClickDeleteLabel" class="btn icon-only icon i-remove" type="button"></button>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-footer">
            <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
            <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save SSP</span></button>  
        </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

        this.biddingStyleOption = new filterDropdown_template('biddingStyleOption', this.getTemplateViewModel(), this.caller);
        this.sspBidAdapter = new filterDropdown_template('sspBidAdapter', this.getTemplateViewModel(), this.caller);
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "sspEdit-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "margin-top", "ssps"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;

        return {
            idSsp: ko.observable(''),
            name: ko.observable(''),
            advertiserName: ko.observable(''),
            alias: ko.observable(''),
            labels: ko.observableArray([]),
            allTypes: ko.observableArray([]),
            allBiddingStyles: ko.observableArray([]),
            allBidAdapters: ko.observableArray([]),
            creativeType: ko.observable('banner'),
            newLabelName: ko.observable(''),
            newLabelType: ko.observable('string'),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
            onClickAddLabel: function(data, event){ huit_neuf_this.onClickAddLabel(); },
            onClickDeleteLabel: function(data, event){ huit_neuf_this.onClickDeleteLabel(data); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            idSsp: this.getTemplateViewModel().idSsp(),
            name: this.getTemplateViewModel().name(),
            advertiserName: this.getTemplateViewModel().advertiserName(),
            idBiddingStyle: this.biddingStyleOption.getTemplateViewModel().selectedOption(),
            idSspBidAdapter: this.sspBidAdapter.getTemplateViewModel().selectedOption(),
            creativeType: this.getTemplateViewModel().creativeType(),
            labels: this.getTemplateViewModel().labels(),
        };
    }

    public setSsp(ssp): void{

        this.getTemplateViewModel().idSsp(ssp.id);
        this.getTemplateViewModel().name(ssp.name);
        this.getTemplateViewModel().advertiserName(ssp.advertiserName);
        this.getTemplateViewModel().alias(ssp.alias || '');
        this.getTemplateViewModel().labels(ssp.paramsLabels);
        this.getTemplateViewModel().biddingStyleOption.setSelectedOption(ssp.biddingStyle.id);
        this.getTemplateViewModel().sspBidAdapter.setSelectedOption(ssp.sspBidAdapter.id);
    }

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        this.caller.saveEditSsp();
    }

    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancelEditSsp();
    }

    /**
     * Handles add new label click event.
     */
    protected onClickAddLabel(): void{

        let newLabelName = this.getTemplateViewModel().newLabelName();
        let newLabelType = this.getTemplateViewModel().newLabelType();

        if(newLabelName == ''){

            this.caller.notifyParamLabelMissingName();
            return;
        }

        this.getTemplateViewModel().labels.push({name: newLabelName, type: newLabelType});

        this.getTemplateViewModel().newLabelName('');
        this.getTemplateViewModel().newLabelType('string');
    }

    /**
     * Handles delete label click event.
     */
    protected onClickDeleteLabel(removedLabel): void{

        this.getTemplateViewModel().labels.remove(removedLabel);
    }

    protected filterDropdownOnChange(idElement): void{

    }

    //
    // Set dropdown options.
    public setBiddingStyleOptionItems(options): void{

        this.biddingStyleOption.setOptions(options);
    }

    //
    // Set dropdown options.
    public setSspBidAdapterItems(options): void{

        this.sspBidAdapter.setOptions(options);
    }
}
