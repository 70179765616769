import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";
import {filterSingleDate_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterSingleDate_template";
import {validationsList_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/validationsList_template";
import {filterDropdown_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDropdown_template";
import {vnApp_ads_ninja} from "../vnApp_ads_ninja";

declare var ko: any;
declare var dhtmlXCalendarObject: any;
declare var Dropzone: any;

export class dataImport_template extends vnTemplate{

    public filterDate: filterSingleDate_template;
    public reportFilter: filterDropdown_template;
    public brandsFilter: filterDropdown_template;
    public countriesFilter: filterDropdown_template;
    protected validationsList: validationsList_template;

    protected getTemplateHtml(): string{

        return `
                
            <div class="sub-header w-justify"> 
              
                <div class="w-left" data-bind="visible: hasWriteAccess">
                    <div><label class="form-label" for="">Report</label>` + this.reportFilter.getHtmlBuild() + `</div>            
                    
                    <!-- ko if: isAffiliateReport() -->
                    <div>
                        <label class="form-label" for="">Brand</label>
                        ` + this.brandsFilter.getHtmlBuild() + `
                    </div>   
                    <!-- /ko -->
                    
                    <!-- ko ifnot: hideDateFilter() -->
                    <div><label class="form-label" for="">Date</label>` + this.filterDate.getHtmlBuild() + `</div>   
                    <!-- /ko -->

                    <!-- ko if: isAmazonAffiliateReport() -->
                    <div>
                        <label class="form-label" for="">Country</label>
                        ` + this.countriesFilter.getHtmlBuild() + `
                    </div>
                    <!-- /ko -->

                    <div id="dropzone"> 
                        <div id="my-drop-zone" class="dropzone needsclick dz-clickable"></div>
                        <input id="reportPath" data-bind="value: reportPath" type="hidden">
                    </div>
                    <button data-bind="event: { click: onClickImport }, css: {'disable' :  disableImport() }" class="btn highlight icon i-import" type="button">Import Report</button>
                </div>
                
                <div class="w-right flex-none">
                    <a href="https://docs.google.com/spreadsheets/d/1Es4IxotnDM6i3mtM4fqaprRhEpSjsJ4d7JZ4I--MEQA/edit?usp=sharing" class="btn highlight icon i-sheet" target="_blank">report format examples</a>
                </div>
            </div>
  
            <div class="list-header w-justify">  
                <div class="w-left">
                    <h2 class="section-title icon i-data">Data Import</h2>
                 </div>
            </div>
                
            <div class="w-list-results"  id="list-results">
                <table class="list-results">
                    <thead>
                        <tr>
                            <th><span>source</span></th>
                            <!-- ko foreach: datesForPulls -->
                            <th class="align-right no-wrap"><span data-bind="text: $data"></span></th>
                            <!-- /ko -->
                        </tr>
                    </thead>
                    <tbody data-bind="foreach: pulls">
                        
                        <tr>
                            <td class="border-right"><span data-bind="text: source"></span></td>
                            <!-- ko foreach: $data.pull -->
                            <td class="align-right" data-bind="attr: {class: classname}, css: { 'alert': dataCount == 0 }"><span class="icon" data-bind="text: dataCount"></span></td>
                            <!-- /ko -->
                        </tr>
                    </tbody>
                </table>
             </div>  
            `;
    }

    protected initPartial():void{

        this.filterDate = new filterSingleDate_template("filterDate", this.getTemplateViewModel(), this.caller);
        this.reportFilter = new filterDropdown_template("reportFilter", this.getTemplateViewModel(), this.caller)
        this.brandsFilter = new filterDropdown_template("brandsFilter", this.getTemplateViewModel(), this.caller)
        this.countriesFilter = new filterDropdown_template("countriesFilter", this.getTemplateViewModel(), this.caller)
        this.validationsList = new validationsList_template("validationList", this.getTemplateViewModel(), this.caller);
    }
    protected getTemplateName(): string{

        return "data_import-template";
    }

    protected getMainElementType(): string{

        return "div";
    }

    protected getMainElementClasses(): Array<string>{

        return [];
    }

    protected buildViewModel(): any{

        // define the viewmodel data/ functions
        let huit_neuf_this = this;

        return {

            pulls: ko.observableArray(),
            datesForPulls: ko.observableArray(),
            reportPath: ko.observable(null),
            hasWriteAccess: ko.observable(this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_DATA_IMPORT, vnApp_ads_ninja.ACCESS_TYPE_WRITE)),
            onClickImport: function (data, event){

                huit_neuf_this.onClickImport();
            },
            disableImport: function (data){

                return ko.computed(function (){

                    return huit_neuf_this.disableImport();
                }, this)
            },
            isFileUploadMissing: function (data){

                return ko.computed(function (){

                    return huit_neuf_this.isFileUploadMissing();
                }, this)
            },
            isReportFilterMissing: function (data){

                return ko.computed(function (){

                    return huit_neuf_this.isReportFilterMissing();
                }, this)
            },
            isAmazonAffiliateReport: function (data){

                return ko.computed(function (){

                    return huit_neuf_this.isAmazonAffiliateReport();
                }, this)
            },
            isAffiliateReport: function (data){

                return ko.computed(function (){

                    return huit_neuf_this.isAffiliateReport();
                }, this)
            },
            hideDateFilter: function (data){

                return ko.computed(function (){

                    return huit_neuf_this.hideDateFilter();
                }, this)
            },
        };
    }

    public getViewModelForRequest(){

        return {

            reportFilter: this.getTemplateViewModel().reportFilter.selectedOption,
            filterDate: this.getTemplateViewModel().filterDate,
            reportPath: this.getTemplateViewModel().reportPath,
            pulls: this.getTemplateViewModel().pulls,
            countriesFilter: this.getTemplateViewModel().countriesFilter.selectedOption,
            brandsFilter: this.getTemplateViewModel().brandsFilter.selectedOption,

        };
    }

    protected isAmazonAffiliateReport(){

        if (this.getTemplateViewModel().reportFilter.selectedOption() == "amazon affiliate report"){

            return true;
        }else{

            return false;
        }
    }

    /**
     * Determines if report is an affiliate report.
     */
    protected isAffiliateReport(){

        const affiliateReports = [
            'amazon affiliate report',
            'amazon onsite report',
            'commission junction report',
            'netline report',
            'sponsored deals report',
            'stackcommerce report',
            'stackmedia report',
        ];

        return (affiliateReports.indexOf(this.getTemplateViewModel().reportFilter.selectedOption()) > -1);
    }

    /**
     * Determines if report is the Amazon OnSite report.
     */
    protected hideDateFilter(){

        const affiliateReports = [
            'amazon affiliate report',
            'amazon onsite report',
            'stackmedia report',
        ];

        return (affiliateReports.indexOf(this.getTemplateViewModel().reportFilter.selectedOption()) > -1);
    }

    public setBrands(brands: Array<any>): void{

        this.brandsFilter.setOptions(brands);
        this.brandsFilter.setLabel("Brand");

    }

    public setCountries(countries: Array<any>): void{

        this.countriesFilter.setOptions(countries);
        this.countriesFilter.setLabel("Geo");

    }

    protected onClickImport(){

        if (!this.isFileUploadMissing()){

            this.caller.importReport();
        }
    }

    public disableImport(){

        return this.isFileUploadMissing() || this.isReportFilterMissing();
    }

    public isFileUploadMissing(){

        return this.getTemplateViewModel().reportPath() == null;
    }

    public isReportFilterMissing(){

        return this.getTemplateViewModel().reportFilter.selectedOption() == "";
    }

    public addDataPullStatus(dataPullData, source){

        for (let dataPullDataItem of dataPullData){

            dataPullDataItem['classname'] = 'align-right';
        }

        let arrayData =  {source: source, pull: dataPullData}

        this.getTemplateViewModel().pulls.push(arrayData);

    }

    public resetDataPullStatus(){

        this.getTemplateViewModel().pulls([]);
    }

    public addCellSelectedClass(){

        let newPulls = this.getTemplateViewModel().pulls();
        for (let idx1 in newPulls){

            for (let idx2 in newPulls[idx1]['pull']){

                if (newPulls[idx1]['pull'][idx2].source == this.getTemplateViewModel().reportFilter.selectedOption() && newPulls[idx1]['pull'][idx2].dateData == this.getTemplateViewModel().filterDate.singleDatePicker()){

                    newPulls[idx1]['pull'][idx2].classname = "flash";
                }
            }
        }

        this.resetDataPullStatus();
        this.getTemplateViewModel().pulls(newPulls);
    }

    public setDatesForPulls(dates){

        this.getTemplateViewModel().datesForPulls(dates);
    }
}
