import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class bidderTypeEdit_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <!-- ko ifnot: idBidderType -->
                <h3 class="form-title icon i-add">Add New Bidder Type</h3>
                <!-- /ko -->

                <!-- ko if: idBidderType -->
                <h3 class="form-title icon i-bidder">edit Bidder Type</h3>
                <!-- /ko -->

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
            <div class="w-form-body">
                <div class="form-body full-width">
                    <div class="form-group row">
                        <label for="name" class="form-label">name</label>
                        <input id="name" data-bind="value: name" type="text" class="input-text">
                     </div>
                </div>  
            </div> 

            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save Bidder Type</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "bidderTypeEdit-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "w-form-large", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;

        return {
            idBidderType: ko.observable(''),
            name: ko.observable(''),
            headerDependancy: ko.observable(''),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); }
        };

    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            idBidderType: this.getTemplateViewModel().idBidderType(),
            name: this.getTemplateViewModel().name(),
            headerDependancy: this.getTemplateViewModel().headerDependancy()
        };
    }

    public setBidderType(bidderType): void{

        this.getTemplateViewModel().idBidderType(bidderType.id);
        this.getTemplateViewModel().name(bidderType.name);
        this.getTemplateViewModel().headerDependancy(bidderType.headerDependancy);

    }


    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        this.caller.saveEditBidderType();
    }


    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancelEditBidderType();
    }
}
