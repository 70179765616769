import {vnModule} from "./vnModule";
import {vnAppZone} from "./vn_app_zone";
import {validationsList_template} from "../vnKnockoutJsTemplates/validationsList_template";
import {vn_app_zone_notification} from "./vn_app_zone_notification";
import {confirmationModal_template} from "../vnKnockoutJsTemplates/confirmationModal_template";
import {XMLHttpRequestHandler, XMLHttpRequestHandler_requestType} from "../XMLHttpRequestHandler/XMLHttpRequestHandler";

declare var ko: any;
declare var page: any;

export abstract class vnModule_knockoutJS extends vnModule{
    
    // adding the viewModel object for knockoutJS purpose
    protected viewModel: any = {};
    protected template: any;
    protected XHRHdls: XMLHttpRequestHandler[] = [];

    // every modue will have ad efault validationList template to use so we can
    // have a generic treatment of validation result sets
    protected validationTemplate: validationsList_template;

    //
    // Modal to confirm takeover of lock.
    public confirmationModal: confirmationModal_template;

    constructor(vnAppZone: vnAppZone) {
        super(vnAppZone);

        this.validationTemplate = new validationsList_template("app_validationList", this.viewModel, this);

        this.confirmationModal = new confirmationModal_template('app_confirmationModal', this.viewModel, this);
        this.confirmationModal.setTitle('Locked');
        this.confirmationModal.setMessageSecondary('The editing user will lose their unsaved changes if you takeover. Would you like to takeover?');
    }

    public build(): Array<HTMLElement>{

        this.content = [];

        this.content.push(this.validationTemplate.build());
        this.content.push(this.confirmationModal.build());

        for(let c of this.buildContent()){

            this.content.push(<HTMLElement>c);
        }

        return this.content;
    }

    //
    // overwriting the internal load to include the apply of the binding for knockout JS*
    protected internalLoad(container: HTMLElement){

        this.build();
        this.insertIn(container);

        // * see here
        for(let c of this.content){
            ko.applyBindings(this.viewModel, c);
        }

        this.loadSavedViewModule();

        this.fillPage();
    }

    protected loadSavedViewModule(){
        let saved_viewModel = this.getVnAppZone().getGlobal(this.getModuleName() + "_viewModel")
        if(saved_viewModel){
            this.loadViewModelFromViewModel(saved_viewModel);
        }
        this.getVnAppZone().removeGlobal(this.getModuleName() + "_viewModel");
    }

    protected saveViewModule(viewModel){
        this.getVnAppZone().addGlobal({index: this.getModuleName() + "_viewModel", value: viewModel});
    }

    protected savePreviousPage(pageTo, pageFrom){

        let pageFromPathAndParams = pageFrom.replace(window.location.origin, '');

        this.getVnAppZone().addGlobal( { index:pageTo , value: pageFromPathAndParams });
    }

    protected loadPreviousPage(currentPage){

        return this.getVnAppZone().getGlobal(currentPage);
    }

    protected loadViewModelFromViewModel(viewModel){
        for(let v in viewModel){
            if(typeof (viewModel[v]) != "function" && typeof (viewModel[v]) != "string"){
                for(let x in viewModel[v]){
                    if(typeof (viewModel[v][x]) != "function"){
                        this.viewModel["list"][v][x] = viewModel[v][x];
                    }else{
                        if(viewModel[v][x].name == "c"){
                            this.viewModel["list"][v][x](viewModel[v][x]());
                        }
                    }
                }
            }else if(typeof (viewModel[v]) == "function"){
                if(viewModel[v].name == "c"){
                    this.viewModel["list"][v](viewModel[v]());
                }
            }else if(typeof (viewModel[v]) == "string"){
                this.viewModel["list"][v] = viewModel[v];
            }
        }
    }

    protected manageResponse(req, obj, successFct){

        if(req.request.readyState == 4){

            if(req.request.status == 200){

                if(successFct){

                    successFct(req, obj);
                }
                obj.validationTemplate.clearMessages();

            }else if(req.request.status == 423){

                let responseParsed = JSON.parse(req.request.response);
                let lastRoute = obj.getVnAppZone().app.getLastRoute();

                if(responseParsed.hasOwnProperty('isTakeoverAllowed') && responseParsed.isTakeoverAllowed){

                    obj.confirmationModal.setContext('takeover');
                    obj.confirmationModal.setDataClose(lastRoute);
                    obj.confirmationModal.setMessageMain(responseParsed.message);
                    obj.confirmationModal.show();
                }else{

                    obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, responseParsed.message);
                    page.redirect(lastRoute);
                }
            }else if(req.request.status == 400){

                let responseParsed = JSON.parse(req.request.response);
                obj.validationTemplate.setMessages(responseParsed.validations);
                window.scrollTo(0, 0);
            }else if(req.request.status == 401){

                let originalResponseParsed = JSON.parse(req.request.response);
                let lastRoute = obj.getVnAppZone().app.getLastRoute();

                // Must make a separate call to the backend to figure out if the user is truly logged in (app.loggedUser.isLoggedIn might be stale)
                let loginReq_url: string = obj.getVnAppZone().getApp().getIsLoggedInHttpCallUrl();
                let loginReq_Params: Array<Array<string>> = obj.getVnAppZone().getApp().getIsLoggedInHttpCallParameters();

                let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(loginReq_url,loginReq_Params, this);

                XHRHdl.onReadyStateFunction = (function(req, obj){

                    return function(){

                        if (req.request.readyState == 4){

                            if(  req.request.status == 200  ){

                                let responseParsed = obj.getVnAppZone().getApp().getIsLoggedInHttpCallResponse(JSON.parse(req.request.response));
                                if (responseParsed.isLoggedIn === true){

                                    obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, originalResponseParsed.message);
                                    page.redirect(lastRoute);
                                }
                                else{

                                    obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, originalResponseParsed.message);
                                    page.redirect('/');
                                }
                            }
                            else{
                                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, originalResponseParsed.message);
                                page.redirect('/');
                            }
                        }
                    }
                });
                XHRHdl.execute();
            }else{

                if(req.request.status != "0"){

                    try {
                        let responseParsed = JSON.parse(req.request.response);
                        obj.validationTemplate.setSingleMessage({
                            field: "error",
                            "label": req.request.status,
                            message: responseParsed.message
                        });
                    } catch (e){
                        obj.validationTemplate.setSingleMessage({
                            field: "error",
                            "label": req.request.status,
                            message: 'Something went wrong !'
                        });
                    }
                    window.scrollTo(0, 0); 
                }
            }
        }
    }

    public updateViewModelFromPath(){

        let urlParams = this.getUrlParmsFromPath();

        if(Object.keys(urlParams).length != 0 && Object.keys(urlParams)[0] !== "undefined"){

            this.template.setViewModelFromRequest(urlParams);
        }
    }



    public getUrlParmsFromPath(){

        let path = window.location.href;

        let queryString = window.location.href.split('?')[1];
        queryString = decodeURIComponent(queryString);
        let urlParams = [];

        if(queryString){

            let urlParamsSections = queryString.split("&");


            for(let urlParamsSection of urlParamsSections){

                let urlKeyValue = urlParamsSection.split("=");
                urlKeyValue[1] = decodeURIComponent(urlKeyValue[1]);

                if(this.isJson(urlKeyValue[1])){

                    urlKeyValue[1] = JSON.parse(urlKeyValue[1]);
                }

                urlParams[urlKeyValue[0]] = urlKeyValue[1];
            }
        }

        return urlParams;
    }

    private isJson(str){
        try {
            JSON.parse(str);
        } catch (e){
            return false;
        }
        return true;
    }

    public sendFilterCall(baseUrl, viewModelParams, returnFunction, callType = XMLHttpRequestHandler_requestType.GET, callIdentifier = '', loadItem = null, replaceUrl = true){

        let callKey = '';

        // If callIdentifier is specified, it means we want to make sure to cancel previous requests with the same identifier before sending this call.
        if(callIdentifier != ''){

            callKey = baseUrl + '-' + callIdentifier;

            if(callKey in this.XHRHdls){

                this.XHRHdls[callKey].cancelAndReset();
            }
        }
        if(loadItem){
            this.loadingIn(loadItem);
        }

        let filterParams = viewModelParams;
        if(viewModelParams.filters){
            filterParams = viewModelParams.filters;
        }

        if(replaceUrl){
            this.updateFilterUrl(baseUrl, filterParams);
        }

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(['data', ko.toJSON(viewModelParams)]);

        let XHRHdl: XMLHttpRequestHandler = new XMLHttpRequestHandler(baseUrl, usrReq_params, this);
        XHRHdl.mode = callType;

        XHRHdl.onReadyStateFunction = returnFunction;
        XHRHdl.execute();

        // Keep track the call so we can cancel it later if needed.
        if(callKey != ''){
            this.XHRHdls[callKey] = XHRHdl;
        }
    }

    public updateFilterUrl(baseUrl, viewModelParams){

        // @ts-ignore
        let clonedViewModelParams = Object.assign({}, viewModelParams);

        //build the url

        let queryString = '';

        let firstParam = true;
        let useDateRange = false;

        for(let key in clonedViewModelParams){

            let paramValue = clonedViewModelParams[key];

            if(paramValue == null){

                continue;
            }

            if(key == "dateFilterType"){

                if(clonedViewModelParams["startDate"] == null && clonedViewModelParams["endDate"] == null && clonedViewModelParams["startDateRange"] == null && clonedViewModelParams["endDateRange"] == null){
                    continue;
                }
                if(paramValue == "range"){

                    useDateRange = true;
                }
            }

            if((key == "startDate" || key == "endDate") && useDateRange){
                continue;
            }
            if((key == "startDateRange" || key == "endDateRange") && !useDateRange){
                continue;
            }

            if(Array.isArray(paramValue)){
                if(paramValue.length == 0){
                    continue;
                }

                paramValue = JSON.stringify(paramValue);
                paramValue = encodeURIComponent(paramValue);
            }
            
            if(typeof paramValue === 'object'){

                //specifically for hiding columns
                if(key == "hideColumns"){
                    let columns = paramValue;
                    let resultArray = Object.keys(columns).map(function(indx){
                        let column = columns[indx];
                        if(!column){
                            return indx;
                        }
                        return null;
                    });
                    paramValue = resultArray.filter(val => val);
                    if(paramValue.length == 0){
                        continue;
                    }
                }
                paramValue = JSON.stringify(paramValue);
                paramValue = encodeURIComponent(paramValue);
            }

            if(typeof paramValue === 'function'){

                let functionValue = paramValue();
                if(functionValue){
                    paramValue = JSON.stringify(functionValue);
                    paramValue = encodeURIComponent(functionValue);
                }else{
                    continue;
                }
            }

            if(paramValue !== ""){

                if(firstParam){

                    queryString = queryString + key + '=' + paramValue;
                    firstParam = false;
                }else{

                    queryString = queryString + '&' + key + '=' + paramValue;
                }
            }
        }

        let rootPath = window.location.href.split('?')[0];
        let path = rootPath;

        if(queryString){
            path = rootPath + "?" + queryString;
        }

        window.history.replaceState(window.history.state, document.title, path);
        this.getVnAppZone().getApp().routeHistory[this.getVnAppZone().getApp().routeHistory.length - 1] = path;
    }

    //
    // Hook.
    // Handles on confirm button click event from modal.
    public confirmationModalOnClickConfirm(context, data): void {

    };

    //
    // Hook.
    // Handles on close button click event from modal.
    public confirmationModalOnClickClose(context, data): void {

    };

    //
    // Determines if logged in user has a role.
    public isOfRole(role: Number){

        let show = false;
        let vnApp = this.getVnAppZone().app;
        let roles: Array<any> = this.getVnAppZone().getApp().loggedUser.user.valnet_user.roles;
        let roleIds = [];

        for(let role of roles){

            roleIds.push(role.id);
        }

        if(roleIds.indexOf(role) > -1){

            show = true;
        }

        return show;
    }

    //
    // Determines if user has access to page.
    public hasAccessToSection(idSection: Number, accessType: string = ''): boolean {

        if(this.isOfRole(1)){

            return true;
        }

        let vnApp = this.getVnAppZone().app;
        let roles: Array<any> = this.getVnAppZone().getApp().loggedUser.user.valnet_user.roles;

        for(let role of roles){

            for(let section of role.sections){

                if(section.idSection === idSection){

                    if((accessType && section.access_arr.includes(accessType)) || (!accessType && section.access_arr.length > 0)){

                        return true;
                    }else{

                        return false;
                    }
                }
            }
        }

        return false;
    }
    
    public getPresetType(){

        return this.getModuleName();
    }

    public redirectToPreset(queryString){
        
        let rootPath = window.location.href.split('?')[0];
        let path = rootPath + queryString;
        window.history.replaceState(window.history.state, document.title, path);
        this.getVnAppZone().getApp().routeHistory[this.getVnAppZone().getApp().routeHistory.length - 1] = path;
    }

    protected getSearchPresetsListData(): void{

        if("getSearchPresetsListData" in this.XHRHdls){

            // @ts-ignore
            this.XHRHdls["getSearchPresetsListData"].cancelAndReset();
        }

        let data = {type: this.getModuleName()};

        let subViewModel = JSON.parse(ko.toJSON(data));
        let usrReq_url: string = '/api/search_preset/get/';
        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(["data", ko.toJSON(subViewModel)]);

        this.XHRHdls['getSearchPresetsListData']= new XMLHttpRequestHandler(usrReq_url, usrReq_params, this);

        this.XHRHdls['getSearchPresetsListData'].onReadyStateFunction = this.onGetSearchPresetsReturn;
        this.XHRHdls['getSearchPresetsListData'].execute();
    }

    protected onGetSearchPresetsReturn(req, obj): Function {

        return () => {

            obj.manageResponse(req, obj, function (){

                let response = JSON.parse(req.request.response);
                obj.template.presetFilter.setSearchPresets(response);

                // If there is a favorite and we don't have a query string, redirect to that favorite
                let favorite = obj.template.presetFilter.getTemplateViewModel().searchPresetFavorite();

                if(favorite.id != null && window.location.search.length == 0){

                    obj.redirectToPreset(favorite.queryString);
                }

                obj.getListFormData();
            });
        }
    }

    protected saveSearchPreset(){

        if("saveSearchPreset" in this.XHRHdls){

            // @ts-ignore
            this.XHRHdls["saveSearchPreset"].cancelAndReset();
        }

        let formReq_url: string = '/api/search_preset/save';
        let formReq_params: Array<Array<string>> = [];

        let subViewModel = JSON.parse(ko.toJSON(this.template.presetFilter.getViewModelForPresetRequest()));

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        this.XHRHdls['saveSearchPreset'] = new XMLHttpRequestHandler(formReq_url,formReq_params, this);

        this.XHRHdls['saveSearchPreset'].mode = XMLHttpRequestHandler_requestType.POST;
        this.XHRHdls['saveSearchPreset'].onReadyStateFunction = this.onPostSearchPreset
        this.XHRHdls['saveSearchPreset'].execute();
    }

    private onPostSearchPreset(req, obj): Function{

        return function (){

            obj.manageResponse(req, obj, function (){

                let response = JSON.parse(req.request.response);

                if(req.request.status == 200){

                    obj.template.presetFilter.addCreatedPresetToList(response);
                    obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, "Preset saved successfully.");
                }else{

                    obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_ERROR, "Preset could not be saved.");
                }

                obj.getVnAppZone().showNotificationToModule();
            });
        }
    }

    protected saveFavoriteSearchPreset( params: {presetId: number, searchPresetType: string}): void{

        if("saveFavoriteSearchPreset" in this.XHRHdls){

            // @ts-ignore
            this.XHRHdls["saveFavoriteSearchPreset"].cancelAndReset();
        }
        let formReq_url: string = '/api/search_preset/favorite/save/';
        let formReq_params: Array<Array<string>> = [];

        let subViewModel = JSON.parse(ko.toJSON(params));

        formReq_params.push(["data", ko.toJSON(subViewModel)]);

        this.XHRHdls['saveFavoriteSearchPreset'] = new XMLHttpRequestHandler(formReq_url,formReq_params, this);

        this.XHRHdls['saveFavoriteSearchPreset'].mode = XMLHttpRequestHandler_requestType.POST;
        this.XHRHdls['saveFavoriteSearchPreset'].onReadyStateFunction = this.onPostFavoriteSearchPreset
        this.XHRHdls['saveFavoriteSearchPreset'].execute();
    }

    private onPostFavoriteSearchPreset(req, obj): Function {
        return function (){
            obj.manageResponse(req, obj, function (){

            });
        }
    }

    protected deleteSearchPreset(presetId: number): void {

        if("deleteSearchPreset" in this.XHRHdls){

            // @ts-ignore
            this.XHRHdls["deleteSearchPreset"].cancelAndReset();
        }

        let usrReq_url: string = '/api/search_preset/delete/' + presetId + '/';
        let usrReq_params: Array<Array<string>> = [];

        this.XHRHdls['deleteSearchPreset'] = new XMLHttpRequestHandler(usrReq_url, usrReq_params, this);

        this.XHRHdls['deleteSearchPreset'].onReadyStateFunction = this.onDeleteSearchPresetReturn;
        this.XHRHdls['deleteSearchPreset'].execute();
    }

    protected onDeleteSearchPresetReturn(req, obj): Function{

        return () => {

            obj.manageResponse(req, obj, function (){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, "Preset deleted successfully.");
                obj.getVnAppZone().showNotificationToModule();
            });
        }
    }

    public onClickBookmarkPreset(url): void {

        // Copy the bookmark to clipboard
        window.navigator['clipboard'].writeText(url);

        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, "Preset bookmark copied to clipboard.");
        this.getVnAppZone().showNotificationToModule();
    }
    //
    // Handles navigation for new tab with key press
    protected handleNavigation(url, event){

        if (event.ctrlKey || event.metaKey || event.which === 2) {

            // Open link in a new tab if Ctrl/Cmd is pressed or if the middle mouse button is clicked
            window.open(url, '_blank');
        } else {

            // Otherwise, open the link in the same tab
            page(url);
        }
    }
    
}

