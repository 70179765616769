import { vnTemplate } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate';

declare var ko: any;

export class globalCss_template extends vnTemplate {

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <h3 class="form-title icon i-txt">Global Css</h3>
                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
            <div class="w-form-body">
                <div class="form-body full-width">
                    <div class="form-group">
                       <textarea id="globalCss" data-bind="value: globalCss" class="input-text long extra" style="height: 150rem"></textarea>
                    </div>                    
               </div> 
            </div>
            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return 'globalCss_template';
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "w-form-large","margin-top"];
    }

    /**
     * @inheritDoc
     */
    public buildViewModel(): Object{

        let self = this;

        return {
            globalCss: ko.observable(''),
            onClickSave: function(){ self.onClickSave(); },
            onClickCancel: function(){ self.onClickCancel(); }
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            globalCss: this.getTemplateViewModel().globalCss()
        };
    }

    /**
     * Sets form data.
     *
     * @param data
     */
    public setData(data): void {

        this.getTemplateViewModel().globalCss(data);
    }

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        this.caller.save();
    }


    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancelEditGlobalCss();
    }

}