import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";
import { vn_app_zone_notification } from "../../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";

declare var ko: any;

export class sspConfigsEdit_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

    return `
        <div class="form-header">
            <div>
                <h3 class="form-title icon i-sspConfig" data-bind="text: getTitle(ssp().name)">Edit SSP Configs</h3>
                <button data-bind="click: onClickAddConfig" class="btn icon only-icon i-add" type="button"></button>
            
                <button class="btn" type="button" data-bind="click: setAllSelected(true)">Select All</button>
                <button class="btn" type="button" data-bind="click: setAllSelected(false)">Deselect All</button>
                <span class="" title="Copy selected items. Can only copy while no changes are made. Save changes to re-enable copy.">
                    <button class="btn icon i-copy" type="button" data-bind="click: onClickCopyConfigs, enabled: canCopy(), css: { 'is-disable': canCopy() == false }">Copy Selected</button>
                </span>
                <span title="Insert copied items. Can only insert while no changes are made. Save changes to re-enable insert.">
                    <button class="btn icon i-insert" type="button" data-bind="click: onClickInsert, visible: isInsertButtonShown, enabled: !hasSomethingChanged, css: { 'is-disable': hasSomethingChanged() == true }">Insert Copied SSP Configs</button>
                </span>
            </div>
            <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
        </div>
          
        <div class="w-form-body"  data-bind="foreach: configs"> 
          
           
            <hr class="form-hr">
            <div class="form-body "> 
                <div class="form-group row  start">
                    <div class="form-check-inline" style="width: auto;" data-bind="visible: id">
                        <input type="checkbox" data-bind="checked: isSelected, value: id, attr:{'id' : 'config_' + id, 'name' : 'config_' + id}">
                        <label data-bind="attr:{for: 'config_' + id}" class="form-label icon i-check"></label>
                    </div>
                    <button data-bind="click: $parent.onClickCopyConfig" class="btn icon-only icon i-copy" type="button"></button>
                    <button class="w-tooltips btn icon-only icon i-view" type="button">
                        <div class="tooltips form-group" style="width: 30re,;">
                            <div class="w-preview">
                                <pre data-bind="text: preview" style="text-align: left;"></pre>
                            </div> 
                        </div>  
                    </button>
                </div>
                <div class="form-group row">
                    <legend class="form-legend">Status</legend>
                    <div class="form-check-inline width-auto">
                        <input type="radio" value="1" data-bind="checked: isEnabled, attr:{ 'id': 'enabled_yes_' + identifier, 'name': 'enabled_yes_' + identifier }" />
                        <label class="form-label" data-bind="attr:{ 'for': 'enabled_yes_' + identifier }">Active</label>
                    </div>
                    <div class="form-check-inline width-auto">
                        <input type="radio" value="0" data-bind="checked: isEnabled, attr:{ 'id': 'enabled_no_' + identifier, 'name': 'enabled_no_' + identifier }" />
                        <label class="form-label" data-bind="attr:{ 'for': 'enabled_no_' + identifier }">Inactive</label>
                    </div>
                </div>
                
                <div class="form-group row">
                    <label class="form-label">Device</label>
                    <select data-bind="
                            options: $parent.allDevices,
                            optionsText: 'name',
                            optionsValue: 'id',
                            value: device
                        ">
                    </select>
                </div>
                <div class="form-group row">
                        <label class="form-label">Size</label>
                        <input data-bind="value: size" type="text" class="input-text num">
                    </div>
                <div class="form-group row ">
                        <label class="form-label fix">Position</label>
                        <input data-bind="value: position" type="text" class="input-text num">
                    </div>
                </div>
            <div class="form-body">
                    <div class="form-group row end ">               
                        <button data-bind="click: $parent.onClickDeleteConfig" class="btn icon-only icon i-remove" type="button"></button>
                    </div>
                    <div class="form-group row">
                        <label class="form-label">Media Type</label>
                        <select data-bind="
                                options: $parent.allMediaTypes,
                                optionsText: 'name',
                                optionsValue: 'id',
                                value: mediaType
                            ">
                        </select>
                    </div>
                    
                    <div data-bind="foreach: params">
                        <div class="form-group row ">
                            <label class="form-label" data-bind="text: label.name"></label>
                            <input data-bind="value: value" type="text" class="input-text">
                        </div>
                    </div>
                    
                   
                </div>
                
        </div>
        <div class="form-footer">
            <button data-bind="event: { click: onClickCancel }" class="btn" type="button"><span>Cancel</span></button>
            <button data-bind="event: { click: function(data, event) { onClickSave(false, data, event) } }" class="btn" type="button"><span>Save</span></button>  
            <button data-bind="event: { click: function(data, event) { onClickSave(true, data, event) } }" class="btn" type="button"><span>Save and Stay</span></button>  
        </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "sspConfigsEdit-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "w-form-large", "margin-top", "ssps"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let self = this;

        return {
            brand: ko.observable({}),
            ssp: ko.observable({}),
            allMediaTypes: ko.observableArray([]),
            allDevices: ko.observableArray([]),
            configs: ko.observableArray([]),
            newLabelName: ko.observable(''),
            newLabelType: ko.observable('string'),
            atLeastOneSelected: ko.observable(false),
            hasSomethingChanged: ko.observable(false),
            isInsertButtonShown: ko.observable(false),
            canCopy: function(data){ return self.canCopy() },
            onClickSave: function(value, data, event){ self.onClickSave(value); },
            onClickCancel: function(data, event){ self.onClickCancel(); },
            onClickAddConfig: function(data, event){ self.onClickAddConfig(); },
            onClickCopyConfig: function(data, event){ self.onClickCopyConfig(data); },
            onClickDeleteConfig: function(data, event){ self.onClickDeleteConfig(data); },
            getTitle: function(sspName){ return self.getTitle(sspName); },
            setAllSelected: function(data, event){ self.setAllSelected(data); },
            onClickCopyConfigs: function(data){ self.onClickCopyConfigs(); },
            onClickInsert: function(data){ self.onClickInsert(); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            configs: this.getTemplateViewModel().configs()
        };
    }

    public setData(data): void{

        this.getTemplateViewModel().ssp(data.ssp);
        this.getTemplateViewModel().brand(data.brand);
        this.getTemplateViewModel().isInsertButtonShown(data.copiedEntitiesInSession);

        let configsForViewModel = [];

        for(let config of data.ssp.configs){

            if(config.brand.id !== data.brand.id){

                continue;
            }

            config.sspName    = data.ssp.name;
            config.isEnabled  = (config.isEnabled) ? '1' : '0';
            config.isSelected = false;

            // Register observables and subscribers.
            this.registerConfigObservablesAndSubscribers(config);

            // Create preview.
            config.preview = ko.observable(this.createPrebidAdUnitPreview(config));

            configsForViewModel.push(config);
        }

        this.getTemplateViewModel().configs(configsForViewModel);
    }

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(stay = false): void{

        this.caller.save(stay);
    }

    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancel();
    }

    /**
     * Handles add new configs click event.
     */
    protected onClickAddConfig(): void{

        let ssp       = this.getTemplateViewModel().ssp();
        let labels    = ssp.paramsLabels;
        let brand     = this.getTemplateViewModel().brand();
        let newConfig = {
            id: null,
            device: '',
            size: '',
            position: '',
            mediaType: '',
            brand: brand,
            params: [],
            preview: ko.observable(''),
            sspName: ssp.name,
            isEnabled: '0',
            isSelected: false,
        };

        for(let label of labels){

            if(label.name == ''){

                this.caller.notifyParamLabelMissingName();
                return;
            }

            newConfig.params.push({label : label, value: ''});
        }

        // Register observables and subscribers.
        this.registerConfigObservablesAndSubscribers(newConfig);

        this.getTemplateViewModel().configs.unshift(newConfig);
    }

    //
    // Handles copy event.
    protected onClickCopyConfig(config): void{

        this.handleChangeDetected();

        let newConfig = {
            id: null,
            device: config.device(),
            size: config.size(),
            position: config.position,
            mediaType: config.mediaType(),
            brand: config.brand,
            params: [],
            preview: '',
            sspName: config.sspName,
            isEnabled: config.isEnabled(),
            isSelected: false,
        };

        let oldParams = config.params();

        for(let oldParam of oldParams){

            newConfig.params.push({label : oldParam.label, value: oldParam.value()});
        }

        // Register observables and subscribers.
        this.registerConfigObservablesAndSubscribers(newConfig);

        // Create preview.
        newConfig.preview = ko.observable(this.createPrebidAdUnitPreview(newConfig));

        this.getTemplateViewModel().configs.unshift(newConfig);

        this.caller.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Copied and inserted.');
        this.caller.getVnAppZone().showNotificationToModule();
    }

    /**
     * Handles delete configs click event.
     */
    protected onClickDeleteConfig(removedConfig): void{

        this.getTemplateViewModel().configs.remove(removedConfig);
    }

    protected filterDropdownOnChange(idElement): void{

    }

    protected getTitle(sspName){

        return `Edit ${sspName} SSP Configs`;
    }

    //
    // Creates a preview of the config as generated for Prebid.
    protected createPrebidAdUnitPreview(config): string{

        let size      = config.size();
        let mediaType = config.mediaType();
        let params    = config.params();

        if(!size || !mediaType){

            return '';
        }

        size = size.split('x');

        if(Array.isArray(size)){

            size = size.map(x => Number(x));
        }

        var previewParams = {};

        for(let x in params){

            let value = params[x].value();

            if('' === value){

                continue;
            }

            switch(params[x].label.type){

                case 'integer':
                    value = Number(value);

                    break;

                case 'boolean':
                    value = Boolean(value)

                case 'array':
                case 'json':
                    try{
                        value = JSON.parse(value);
                    }
                    catch(e){
                        value = 'INVALID JSON';
                    }

                    break;
            }

            previewParams[params[x].label.name] = value;
        }

        let preview = {
            bids: [],
            code: "div-gpt-ad-example",
            mediaTypes: {},
            sizes: [
                size,
            ],
        };

        var bid = {
            bidder: config.sspName,
            params: previewParams,
        };

        preview.bids.push(bid);

        preview.mediaTypes[mediaType] = {
            sizes: [
                size,
            ],
        };

        return JSON.stringify(preview, null, 2);
    }

    //
    // Register observables and subscribers.
    private registerConfigObservablesAndSubscribers(config): object{

        var self = this;

        for(let x in config.params){

            config.params[x].value = ko.observable(config.params[x].value);
            config.params[x].value.subscribe(function(newValue){ self.handlePreview(config); });
        }

        config.device     = ko.observable(config.device)
        config.params     = ko.observableArray(config.params);
        config.mediaType  = ko.observable(config.mediaType);
        config.size       = ko.observable(config.size);
        config.isEnabled  = ko.observable(config.isEnabled);
        config.isSelected = ko.observable(config.isSelected);

        // Register subscribers.
        config.params.subscribe(function(newValue){ self.handlePreview(config); });
        config.size.subscribe(function(newValue){ self.handlePreview(config); });
        config.mediaType.subscribe(function(newValue){ self.handlePreview(config); });
        config.device.subscribe(function(newValue){ self.handleChangeDetected(); });
        config.isEnabled.subscribe(function(newValue){ self.handleChangeDetected(); });
        config.isSelected.subscribe(function(newValue){ self.handleSelectionChange(newValue); });

        // Add unique identifier for input fields.
        config.identifier = config.id || Date.now();

        return config;
    }

    //
    // Handle preview change.
    private handlePreview(config){

        config.preview(this.createPrebidAdUnitPreview(config))
        this.handleChangeDetected();
    }

    //
    // Handles changes detected.
    private handleChangeDetected(){

        this.getTemplateViewModel().hasSomethingChanged(true);
    }

    //
    // Sets value of isSelected for all configs.
    private setAllSelected(value: boolean){

        let configs = this.getTemplateViewModel().configs();

        configs.forEach((config) => {

            config.isSelected(value);
        });

        this.getTemplateViewModel().configs(configs);
        this.getTemplateViewModel().atLeastOneSelected(value);
    }

    //
    // Handles changes to selected items.
    private handleSelectionChange(newValue){

        if(newValue){

            this.getTemplateViewModel().atLeastOneSelected(true);
            return;
        }

        let configs = this.getTemplateViewModel().configs();

        for(var x in configs){

            if (configs[x].isSelected() == true){

                this.getTemplateViewModel().atLeastOneSelected(true);
                return;
            }
        }

        this.getTemplateViewModel().atLeastOneSelected(false);
    }

    //
    // Handles copying multiple
    public onClickCopyConfigs(){

        let configs = this.getTemplateViewModel().configs();
        let ids       = [];

        configs.forEach((config) => {

            if(config.isSelected()){

                ids.push(config.id);
            }
        });

        this.caller.copy(ids);
    }

    //
    // Handles on insert button click event.
    private onClickInsert(){

        this.caller.insert();
    }

    //
    // Determines if you can copy a config.
    private canCopy(){

        return (this.getTemplateViewModel().atLeastOneSelected() && !this.getTemplateViewModel().hasSomethingChanged());
    }
}
