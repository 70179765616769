import {vnTemplate} from "./vnTemplate";

declare var ko: any;

export class simpleListHeader_template extends vnTemplate{

    private headersLevels;

    constructor(id: string, viewModel: any, caller, headers: Array<any>) {
        super(id, viewModel, caller);

        this.setHeadersLevels(headers);
    }

    //
    // inherited
    protected getTemplateHtml(): string{

        return `
                <!-- ko foreach: headersLevels -->                
                <tr data-bind="foreach: headers, class: classLevel"> 
                    <th data-bind="attr: { colspan: colspan
                    }">
                    <!-- ko if: fieldName -->
                    <span class="icon list-sort-none" data-bind="text: headerName, event: { click: $parents[1].onClickReorder.bind($data, fieldName) }, css: { 'list-sort-desc': $parents[1].orderByAndDirection()== fieldName+'_DESC', 'list-sort-asc': $parents[1].orderByAndDirection()== fieldName+'_ASC' }, class: cssClass"></span>
                    <!-- /ko -->
                    
                    <!-- ko ifnot: fieldName -->
                    <span class="" data-bind="text: headerName, class: cssClass"></span>
                    <!-- /ko -->
                    </th>
                </tr>
                <!-- /ko -->
        `;
    }

    //
    // inherited
    protected getTemplateName(): string{

        return "simpleList-template";
    }

    //
    // inherited
    protected getMainElementType(): string{

        return "thead";
    }

    //
    // inherited
    protected getMainElementClasses(): Array<string>{

        return [];
    }

    //
    // inherited
    protected buildViewModel(): any{

        // Define the viewmodel data/ functions.
        let huit_neuf_this = this;

        return{

            headersLevels: ko.observableArray(),
            orderBy: ko.observable(""),
            orderByDirection: ko.observable("ASC"),
            orderByAndDirection: ko.observable(""),
            onClickReorder: function(value, data, event){ huit_neuf_this.onClickReorder(value, data, event); }
        };
    }

    //
    // template action : reorder
    public onClickReorder(value, data, event){

        this.getTemplateViewModel().orderBy(value);
        //if column has already been sorted, flip the direction
        if (this.getTemplateViewModel().orderByAndDirection().includes(value)){

            if (this.getTemplateViewModel().orderByDirection() == "DESC"){

                this.getTemplateViewModel().orderByDirection("ASC");
            }else{

                this.getTemplateViewModel().orderByDirection("DESC");
            }
        }else{

            //default column sort direction
            this.getTemplateViewModel().orderByDirection("DESC");
        }

        this.getTemplateViewModel().orderByAndDirection(value + "_" + this.getTemplateViewModel().orderByDirection());
        this.caller.dataReorder(this.idElement);
    }

    public setHeadersLevels(headers){

        let headersLevels = [];
        let actualLevel = 0;
        let moreLevels = false;
        let classLevelName = 'header-' + actualLevel;

        headersLevels.push({"headers" : [], "classLevel" : classLevelName});

        if(headers.length > 0) {

            for (let header of headers) {

                //first level --> headers
                let colspan = (header.subHeaders != null && header.subHeaders.length > 1)  ? header.subHeaders.length : 1;

                let cssClass = (header.hasOwnProperty('class'))  ? header.class : "";

                headersLevels[0].headers.push({
                    headerName: header.headerName,
                    fieldName: header.fieldName,
                    colspan: colspan,
                    cssClass: cssClass
                });

                actualLevel = 0;
                let actualHeader = header;

                //processing deeper levels -->  subHeaders
                while (actualHeader.subHeaders != null && actualHeader.subHeaders.length != 0) {

                    actualLevel = actualLevel + 1;
                    classLevelName = 'header-' + actualLevel;

                    for (let subHeader of header.subHeaders) {

                        let newSubHeader = {headerName: subHeader.headerName, fieldName: subHeader.fieldName, colspan: 1, cssClass: ''};

                        if(subHeader.subHeaders.length > 1){
                            newSubHeader.colspan = subHeader.subHeaders.length;
                        }

                        if (subHeader.hasOwnProperty('class')) {
                            newSubHeader.cssClass = subHeader.class;
                        }

                        if (headersLevels[actualLevel] == null) {

                            headersLevels[actualLevel] = {"headers": [], "classLevel": classLevelName};
                        }

                        headersLevels[actualLevel].headers.push(newSubHeader);

                    }

                    actualHeader = actualHeader.subHeaders;

                }

            }

            this.headersLevels = headersLevels;
            this.getTemplateViewModel().headersLevels(headersLevels);

            this.setViewModelForRequest();

        }
    }

    private setViewModelForRequest(){

        let nbrLevels = this.headersLevels.length;

        this.getViewModelForRequest().headersLevels(this.headersLevels);
        this.getViewModelForRequest().orderBy(this.headersLevels[nbrLevels - 1].headers[0].fieldName);
        this.getViewModelForRequest().orderByAndDirection(this.headersLevels[nbrLevels - 1].headers[0].fieldName+"_ASC");

    }

}
