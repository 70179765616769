import {vnTemplate} from "./vnTemplate";
import {infoButton_template} from "./infoButton_template";


declare var ko: any;

export class rolesEdit_template extends vnTemplate {
    
    protected getTemplateHtml(): string {

        return `
                   
            <div class="form-header" data-bind="with: role">
                <input type="hidden">
                
                <!-- ko ifnot: $parent.idRole() -->
                <h3 class="form-title icon i-role">Add Role</h3>
                <!-- /ko -->
                
                <!-- ko if: $parent.idRole() -->
                <h3 class="form-title icon i-role" data-bind="text: name"></h3>
                <!-- /ko -->

                <button data-bind="event: { click: $parent.onClickCancel }" class="close-button icon i-close" type="button"></button>
            </div>
            
            <div id="view-results">
    
                <div data-bind="with: role">
                    <div class="w-form-body">
                        
                        <div class="form-body">
            
                            <div class="form-group">
                                <label class="form-label">id</label>
                                <div class="w-input">
                                    <input type="text" name="id" id="id" disabled class="input-text is-disable" data-bind="value: id">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Name</label>
                                <div class="w-input">
                                    <input type="text" name="name" id="name" " class="input-text" data-bind="value: name">
                                </div>
                            </div>
                        </div> 
                        <div class="form-body">
                            <div class="form-group">
                                <label class="form-label">Sequence</label>
                                <div class="w-input">
                                    <input type="text" name="sequence" id="sequence"  class="input-text" data-bind="value: sequence">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label">Description</label>
                                <div class="w-input">
                                    <input type="text" name="description" id="description"  class="input-text" data-bind="value: description">
                                </div>
                            </div>                  
                        </div>   
                    </div>  
  
                    <div class="w-form-body"> 
                        <div class="form-body full-width"> 
                        <table class="list-action">
                            <thead>
                                <tr>
                                    <th>
                                        <span class="align-left">sections</span>
                                    </th>
                                    <th class="align-center"><span class="icon i-edit">write</span></th>
                                    <th class="align-center"><span class="icon i-view">read</span></th>
                                    <th class="align-center"><span class="icon i-update">update</span></th>
                                    <th class="align-center"><span class="icon i-delete">delete</span></th>
                                </tr>
                            </thead>
                            <tbody data-bind="foreach: sections">
                                <tr>
                                    <td>
                                        <!-- ko if: $data.infoButton -->
                                            <div class="w-tooltips-adv short pos-right" data-bind="template: { name: 'infoButton-template', data: $data.infoButton.getTemplateViewModel() }"></div>
                                        <!-- /ko -->
                                        <h2><span data-bind="text: section.name"></span></h2>
                                    </td>
                                    <!-- ko foreach: permissions -->
                                        <td>
                                            <div class="form-group">                       
                                                <input type="checkbox" data-bind="attr: {id:$parent.section.name + '-' + name}, value: short_name, checked: $parent.access_arr">
                                                <label class="form-label icon i-check"  data-bind="text: name, attr: {for: $parent.section.name + '-' + name}"></label>
                                            </div>   
                                        </td>
                                    <!-- /ko -->
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
                <div class="form-footer">
                    <button class="btn" type="button" data-bind="click: onClickCancel">Cancel</button>
                    <button class="btn highlight" type="button" data-bind="click: onClickSave">Save</button>
                </div>
            </div>
            `;
    }

    protected initPartial(): void {

    }

    protected getTemplateName(): string {
        return "rolesEdit_template";
    }

    protected getMainElementClasses(): Array<string> {
        return ["w-form", "w-form-large", "margin-top", "roles-view"];
    }

    protected buildViewModel(): any {

        let myself = this;
        return {
            idRole: ko.observable(""),
            role: ko.observable(),
            permissions: ko.observableArray([{'name' : 'write', 'short_name' : 'w'}, {'name' : 'read', 'short_name' : 'r'}, {'name' : 'update', 'short_name' : 'u'}, {'name' : 'delete', 'short_name' : 'd'}]),
            onClickSave: function (data, event) {
                myself.onClickSave();
            },
            onClickCancel: function (data, event) {
                myself.onClickCancel();
            }
        };
    }

    // implementation of base class [vnTemplate]
    public getViewModelForRequest() {

        return {
            role : this.getTemplateViewModel().role()
        }
    }

    //
    // click on save button
    protected onClickSave(): void {
        this.caller.saveEditRole();
    }

    //
    // click on cancel button
    protected onClickCancel(): void {

        this.caller.cancelEditRole();
    }

    public setRole(role: {id: number, name: string, description: string, sequence: number, sections: Array<{id: number, name: string, idSection: number, access: string, access_arr: Array<string>, section: {id: number, name: string}}>}): void {

        let associatedSectionsObs = role.sections;
        let description = null;
        for(let assocSec of associatedSectionsObs){

            assocSec['permissions'] = this.getTemplateViewModel().permissions();

            description = assocSec['section']['description'] || '';
            if(description != ''){
                this.addInfoButton(assocSec, assocSec['id'], description);
            }
        }

        role.sections.sort((a, b) => (a.section.name > b.section.name) ? 1 : -1)

        this.getTemplateViewModel().role(role);
    }
    
    //
    // Adds info buttons.
    public addInfoButton(section, id, description){

        section['infoButton'] = new infoButton_template(`infoButton_${id}`, this.getTemplateViewModel(), this.caller);
        section['infoButton'].setTitle('Help: Permission');
        section['infoButton'].setContent(`<p>${description}</p>`);
    }

    public getRole(): void {

        let role = this.getTemplateViewModel().role();
        
        for(let section of role.sections){

            delete section['infoButton'];
        }
        
        return role;
    }

    //
    // error
    protected onUpdateError(status, data): void {
        console.log('onUpdateError' + status);
    }


}
