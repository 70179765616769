import {vnTemplate} from "./vnTemplate";

declare var ko: any;

export class menu_template extends vnTemplate{

    protected getTemplateHtml(): string{

        return `
            <ul data-bind="foreach: menus" class="main-nav-list">
            	<li class="main-nav-item " data-bind="css: { 'dropdown': subMenus().length > 0 }">
            		<a data-bind="attr: {href: url,class: 'main-nav-link icon' + $data.cssClass}, css: { 'is-selected': selected, 'dropdown-toggle': subMenus().length > 0 }, event: { mousedown: $parent.menuClick }, text: text"></a>            	
            		<ul data-bind="visible: subMenus().length, foreach: subMenus" class="dropdown-list">
            		    <li class="dropdown">
            		        <a  data-bind="text: $data.text,attr: {href: $data.url, class: 'dropdown-link dropdown-toggle icon ' + $data.cssClass}, css: {'dropdown-toggle': 'subMenus' in $data }, event: { mousedown: $parents[1].menuClick.bind($data, $parent, $data) }"></a>
                            <!-- ko if: "subMenus" in $data -->
                            <ul data-bind="visible: subMenus.length, foreach: subMenus" class="dropdown-list">
                                <li>
                                    <a data-bind="text: $data.text,attr: {href: $data.url, class: 'dropdown-link icon ' + $data.cssClass}, event: { mousedown: $parents[2].menuClick.bind($data, $parents[1], $data) }"></a>
                                </li>
                            </ul>
                            <!-- /ko -->
                        </li>
                    </ul>
            	</li>
            </ul>
            `;
    }

    protected getTemplateName(): string{
        return "menu-template";
    }
    protected getMainElementType(): string{
    	return "nav";
    }
    protected getMainElementClasses(): Array<string>{
    	return ["main-nav"];
    }

    protected buildViewModel(): any{
        let huit_neuf_this = this;
        return {
            menus: ko.observableArray([])
            ,menuClick: function(data, event){ huit_neuf_this.menuClick(data, event); }
                };
    }

    menuClick(data, event){
        for( let m of this.viewModel[this.idElement].menus()){
            m.selected(false);
        }
        data.selected(true);
        return true;
    }

    setMenus(menus): void{


        let menusArray = [];

        for( let m of menus ){
            let n = {text:"",url:"",selected:true, subMenus: [], cssClass: ""};
            n.text = ko.observable(m.text);
            n.selected = ko.observable(false);
            n.url = m.url;
            n.subMenus = ko.observable(m.subMenus);
            n.cssClass = (m.cssClass.length > 0)? " " + m.cssClass.join(" "): "";

            if(m.hasOwnProperty('selected')){

                n.selected = ko.observable(m.selected);
            }

            if(m.hasOwnProperty('visible') && !m.visible){

                continue;
            }

            menusArray.push(n);
        }


        this.getTemplateViewModel().menus(menusArray);
    }

}
