import { articleLevelSV_template } from '../components/templates/articleLevelSV_template';
import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import {header} from "./header";
import {vnApp_ads_ninja} from "../components/vnApp_ads_ninja";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";

declare var ko: any;
declare var page: any;

export class articleLevelSV extends vnModule_knockoutJS{

    protected articleLevelSV_template: articleLevelSV_template;
    protected XHRHdl: XMLHttpRequestHandler;

    static devices : Array<{id : string, name : string}> = [
        { "id": "mobile",  "name": "Mobile"},
        { "id": "desktop",  "name": "Desktop"},
    ];

    static reportLimit : Array<{id : string, name : string}> = [
        { "id": "100",  "name": "100"},
        { "id": "250",  "name": "250"},
        { "id": "500",  "name": "500"},
        { "id": "1000",  "name": "1000"},
        { "id": "2000",  "name": "2000"},
        { "id": "5000",  "name": "5000"},
    ];

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'article_level_sv';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Content RPM Tool");

        this.articleLevelSV_template = new articleLevelSV_template('article_level_sv', this.viewModel, this);

        return [this.articleLevelSV_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.articleLevelSV_template.buildDatePickerCalendar();
        // this.getListData();
        this.getListFormData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public paginationOnPageClick(): void{

        this.getListData();
    }

    /**
     * Requests data for the filters.
     */
    protected getListFormData(): void{

        this.loadingIn(this.articleLevelSV_template.builtTemplate.children.namedItem('list-results'));
        this.articleLevelSV_template.hideExportButton();
        this.XHRHdl = new XMLHttpRequestHandler('/api/article_level_sv/references/', [], this);
        this.XHRHdl.onReadyStateFunction = this.onFilterRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for filter request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onFilterRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateFilters(responseParsed);
                obj.getListData();
            });
        }
    }

    /**
     * Fetches the list data.
     */
    public getListData(): void{

        this.loadingIn(this.articleLevelSV_template.builtTemplate.children.namedItem('list-results'));
        this.articleLevelSV_template.hideExportButton();

        if(this.XHRHdl){

            this.XHRHdl.cancelAndReset();
        }

        let subViewModel = this.articleLevelSV_template.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(['data', ko.toJSON(subViewModel)] );

        this.XHRHdl = new XMLHttpRequestHandler('/api/article_level_sv/get/', usrReq_params, this);
        this.XHRHdl.onReadyStateFunction = this.onDataRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Fetches export data
     */
    public getExportData(): void{

        this.loadingIn(this.articleLevelSV_template.builtTemplate.children.namedItem("list-results"));

        if(this.XHRHdl){

            this.XHRHdl.cancelAndReset();
        }

        let subViewModel = this.articleLevelSV_template.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(["data", ko.toJSON(subViewModel)] );
        this.XHRHdl = new XMLHttpRequestHandler('/api/article_level_sv/export/',usrReq_params, this);

        this.XHRHdl.onReadyStateFunction = this.onGetExportData;
        this.XHRHdl.execute();
    }

    protected onGetExportData(req, obj){

        return function () {

            obj.manageResponse(req, obj, function () {

                let responseParsed = JSON.parse(req.request.response);
                if (req.request.status == 200) {

                    obj.articleLevelSV_template.setExportData(responseParsed);
                    obj.articleLevelSV_template.exportData();
                }
            });

            obj.loadingOut(obj.articleLevelSV_template.builtTemplate.children.namedItem("list-results"));
        }
    }

    /**
     * Returns handler for data request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateList(responseParsed);
                obj.setResultInfo(responseParsed.totalCount, responseParsed.totalPage);
                //obj.updatePagination(responseParsed.totalCount);
                obj.loadingOut(obj.articleLevelSV_template.builtTemplate.children.namedItem('list-results'));
            });
        }
    }

    /**
     * Update the module list using the objects list.
     *
     * @param responseParsed
     */
    protected updateList(responseParsed: any): void{

        this.loadingIn(this.articleLevelSV_template.builtTemplate.children.namedItem('list-results'));
        // this.articleLevelSV_template.setDeviceFilterItems(articleLevelSV.devices);
        this.articleLevelSV_template.setArticles(responseParsed.data);

    }

    /**
     * Update the filters.
     *
     * @param responseParsed
     */
    protected updateFilters(responseParsed: any): void{

        this.articleLevelSV_template.setDeviceFilterItems(articleLevelSV.devices);
        this.articleLevelSV_template.setBrandFilterItems(responseParsed.brands);
        this.articleLevelSV_template.setReportLimitFilterItems(articleLevelSV.reportLimit);
        this.articleLevelSV_template.getTemplateViewModel().reportLimit.setSelectedOption("250");
        // this.articleLevelSV_template.setArticleLevelSV(responseParsed.artisleLevelSV);
    }


    protected setResultInfo(totalCount, totalPage){
        let execTime = Math.round((new Date().getTime() - this.XHRHdl.requestStartTime)/1000*100)/100;
        this.articleLevelSV_template.setResultInfo(totalCount, totalPage, execTime);
    }

    // protected updatePagination(totalCount) {

    //     this.articleLevelSV_template.updatePagination(totalCount);

    // }

    /**
     * Hook.
     *
     * Cancels data request.
     */
    protected filterSimpleCancel(){

        if(this.XHRHdl){

            this.XHRHdl.cancelAndReset();
        }
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected filterSimpleOnChange(){

        this.articleLevelSV_template.resetPageNumber();
        this.articleLevelSV_template.resetOrderParameters();
        this.getListData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public filterDropdownOnChange(): void{

        this.articleLevelSV_template.resetPageNumber();
        this.articleLevelSV_template.resetOrderParameters();
        this.getListData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected filterMultipleSelectorOnChange(){

        this.articleLevelSV_template.setSelectedBrands();

        this.getListData();
    }

    /**
     * @inheritDoc
     */
    protected reorderArticles(): void{

        this.getListData();
    }

    protected filterDateOnChange(){

        this.getListData();
    }


}