import {vnTemplate} from "./vnTemplate";
import {filterDropdown_template} from "./filterDropdown_template";
import {pagination_template} from "./pagination_template";
import {resultsInfo_template} from "./resultsInfo_template";
import {filterSimple_template} from "./filterSimple_template";
import {infoButton_template} from "./infoButton_template";
import {autoRefresh_template} from "./autoRefresh_template";
import {filterSingleGroupSelector_template} from "./filterSingleGroupSelector_template";
import {filterPreset_template} from "./filterPreset_template";
import { multipleSelector_template } from "./multipleSelector_template";
import { filterMultipleAutoComplete_template } from "./filterMultipleAutoComplete_template";

declare var ko: any;

export class tasksList_template extends vnTemplate {

    protected operationFilter: filterDropdown_template;
    protected updateStatuses: filterDropdown_template;
    protected pagination: pagination_template;
    protected resultsInfo: resultsInfo_template;
    protected simpleFilter: filterSimple_template;
    protected autoRefresh: autoRefresh_template;
    protected brandFilter: filterSingleGroupSelector_template;
    protected pipelineFilter: filterSingleGroupSelector_template;
    protected executerAutocomplete: filterMultipleAutoComplete_template;
    protected filterPreset: filterPreset_template;
    protected statusFilter: multipleSelector_template;
    public filterTag: filterMultipleAutoComplete_template;



    //
    // implementation of base class [vnTemplate]
    protected getTemplateHtml(): string {

        return /* html */`
                <div class="sub-header filter-preset w-justify ">
                    ${(this.filterPreset.getHtmlBuild())}
                </div>

                <div class="sub-header w-justify">
                        <div class="w-left">
                            <div>
                                <label class="form-label">Brand</label>
                                ${(this.brandFilter.getHtmlBuild())}
                            </div>

                            <!-- ko if: hasPipelineFilter -->
                            <div>
                                <label class="form-label">Pipeline</label>
                                ${(this.pipelineFilter.getHtmlBuild())}
                            </div>
                            <!-- /ko -->

                            <div>
                                ${(this.executerAutocomplete.getHtmlBuild())}
                            </div>

                            <div>
                                <label class="form-label">Operation </label>
                                ${(this.operationFilter.getHtmlBuild())}
                            </div>

                            <!-- ko if: hasTagFilter -->
                            <div>
                                ${this.filterTag.getHtmlBuild()}
                            </div>
                            <!-- /ko -->

                            ${(this.statusFilter.getHtmlBuild())}

                            <!-- ko if: permissions()['tasklist_manage'].update == true -->
                            <div class="d-inline-flex">
                                <div class="border-left apply" data-bind="css: { 'is-disable': selectedCount() === 0}">
                                    <label class="form-label">Bulk Action</label>
                                    ${(this.updateStatuses.getHtmlBuild())}
                                </div>
                                <button class="btn highlight left-join icon i-check" type="button" data-bind="css: { 'is-disable': selectedCount() === 0},  event: {click: onClickUpdate}, text: (selectedCount() === 0) ? 'Apply' : 'Apply To ' + selectedCount() + ' Selected Tasks'"></button>
                            </div>
                            <!-- /ko -->
                        </div>
                </div>

                <div class="w-filters-selected">
                    <div id="filters-selected" class="filters-selected">
                        <div id="selected-tags"></div>
                        <div id="selected-task_statuses"></div>
                        <div id="selected-executers"></div>
                    </div>
                </div>

                <div class="list-header w-justify">
                     <div class="w-left">

                        <!-- ko if: permissions()['tasklist_manage'].write == true -->
                            <button data-bind="click: onClickAdd" class="btn icon i-add" type="button">Add task</button>
                        <!-- /ko -->
                     </div>
                     <div class="w-center">
                        ${(this.simpleFilter.getHtmlBuild())}
                    </div>
                     <div class="w-right">
                        ${(this.pagination.getHtmlBuild())}
                     </div>
                </div>
                <div class="w-show-hide-columns-btn">
                    <button data-bind="click: toggleListMoreInfo, text: (listMoreInfo() ? 'Less' : 'More')" class="btn secondary icon i-add" type="button"></button>
                </div>

                <div class="w-list-results content_buckets taller" id="list-results">
                    ${(this.autoRefresh.getHtmlBuild())}
                    <table class="list-results list-sections body-min-height" id="list-results-table" style="white-space: nowrap">
                        ${(this.resultsInfo.getHtmlBuild())}
                        <thead class="sticky">
                            <input data-bind="value: orderBy" type="hidden">
                            <input data-bind="value: orderByDirection" type="hidden">
                            <tr>


                                <!-- ko if: permissions()['tasklist_manage'].update == true -->
                                <th>
                                    <div style="display: flex;justify-content: center;">
                                        <input type="checkbox" id="selectAll" value="checked" data-bind="checked: selectAllChecked, event: {click: setAllChecked}" >
                                        <label style="" for="selectAll" class="form-label icon i-check"></label>
                                    </div>
                                </th>
                                <!-- /ko -->
                                <!-- ko if: permissions()['tasklist_reschedule_task'].write == true -->
                                    <th><span>Reschedule</span></th>
                                <!-- /ko -->
                                <th><span data-bind="event: { click: onClickReorder.bind($data, 'brand') }, css: { 'list-sort-desc': orderByAndDirection()=='brand_DESC', 'list-sort-asc': orderByAndDirection()=='brand_ASC' }" class="icon">Brand</span></th>
                                <th><span data-bind="event: { click: onClickReorder.bind($data, 'idStatus') }, css: { 'list-sort-desc': orderByAndDirection()=='idStatus_DESC', 'list-sort-asc': orderByAndDirection()=='idStatus_ASC' }" class="icon">Status</span></th>
                                <th><span data-bind="event: { click: onClickReorder.bind($data, 'idOperation') }, css: { 'list-sort-desc': orderByAndDirection()=='idOperation_DESC', 'list-sort-asc': orderByAndDirection()=='idOperation_ASC' }" class="icon">Operation</span></th>
                                <!-- ko if: hasPipelineFilter -->
                                    <th><span data-bind="event: { click: onClickReorder.bind($data, 'group') }, css: { 'list-sort-desc': orderByAndDirection()=='group_DESC', 'list-sort-asc': orderByAndDirection()=='group_ASC' }" class="icon">Pipeline</span></th>
                                <!-- /ko -->
                                <th><span data-bind="event: { click: onClickReorder.bind($data, 'dateOperation') }, css: { 'list-sort-desc': orderByAndDirection()=='dateOperation_DESC', 'list-sort-asc': orderByAndDirection()=='dateOperation_ASC' }" class="icon">Operation Date</span></th>
                                <th><span data-bind="event: { click: onClickReorder.bind($data, 'dateScheduled') }, css: { 'list-sort-desc': orderByAndDirection()=='dateScheduled_DESC', 'list-sort-asc': orderByAndDirection()=='dateScheduled_ASC' }" class="icon">Scheduled Date</span></th>
                                <th><span data-bind="event: { click: onClickReorder.bind($data, 'dateExecuted') }, css: { 'list-sort-desc': orderByAndDirection()=='dateExecuted_DESC', 'list-sort-asc': orderByAndDirection()=='dateExecuted_ASC' }" class="icon">Execution Date</span></th>
                                <th><span data-bind="event: { click: onClickReorder.bind($data, 'executionTime') }, css: { 'list-sort-desc': orderByAndDirection()=='executionTime_DESC', 'list-sort-asc': orderByAndDirection()=='executionTime_ASC' }" class="icon">Time to Execute</span></th>
                                <th><span data-bind="event: { click: onClickReorder.bind($data, 'extraInfo') }, css: { 'list-sort-desc': orderByAndDirection()=='extraInfo_DESC', 'list-sort-asc': orderByAndDirection()=='extraInfo_ASC' }" class="icon">Extra Info</span></th>
                                <th><span data-bind="event: { click: onClickReorder.bind($data, 'nbErrors') }, css: { 'list-sort-desc': orderByAndDirection()=='nbErrors_DESC', 'list-sort-asc': orderByAndDirection()=='nbErrors_ASC' }" class="icon">Errors</span></th>
                                <th><span data-bind="event: { click: onClickReorder.bind($data, 'userInfo') }, css: { 'list-sort-desc': orderByAndDirection()=='userInfo_DESC', 'list-sort-asc': orderByAndDirection()=='userInfo_ASC' }" class="icon">User Info/Type</span></th>
                                <!-- ko if: listMoreInfo() -->
                                    <th><span data-bind="event: { click: onClickReorder.bind($data, 'pid') }, css: { 'list-sort-desc': orderByAndDirection()=='pid_DESC', 'list-sort-asc': orderByAndDirection()=='pid_ASC' }" class="icon">pid</span></th>
                                    <th><span data-bind="event: { click: onClickReorder.bind($data, 'id') }, css: { 'list-sort-desc': orderByAndDirection()=='id_DESC', 'list-sort-asc': orderByAndDirection()=='id_ASC' }" class="icon">Id</span></th>
                                    <th><span data-bind="event: { click: onClickReorder.bind($data, 'dateCreation') }, css: { 'list-sort-desc': orderByAndDirection()=='dateCreation_DESC', 'list-sort-asc': orderByAndDirection()=='dateCreation_ASC' }" class="icon">Creation Date</span></th>
                                    <th><span data-bind="event: { click: onClickReorder.bind($data, 'dateUpdated') }, css: { 'list-sort-desc': orderByAndDirection()=='dateUpdated_DESC', 'list-sort-asc': orderByAndDirection()=='dateUpdated_ASC' }" class="icon">Updated Date</span></th>
                                    <th><span data-bind="event: { click: onClickReorder.bind($data, 'idTaskExecuter') }, css: { 'list-sort-desc': orderByAndDirection()=='idTaskExecuter_DESC', 'list-sort-asc': orderByAndDirection()=='idTaskExecuter_ASC' }" class="icon">Task Executor</span></th>
                                    <th><span data-bind="event: { click: onClickReorder.bind($data, 'userInfo') }, css: { 'list-sort-desc': orderByAndDirection()=='userInfo_DESC', 'list-sort-asc': orderByAndDirection()=='userInfo_ASC' }" class="icon">User Info</span></th>
                                    <th><span data-bind="event: { click: onClickReorder.bind($data, 'type') }, css: { 'list-sort-desc': orderByAndDirection()=='type_DESC', 'list-sort-asc': orderByAndDirection()=='type_ASC' }" class="icon">Type</span></th>
                                <!-- /ko -->

                                <!-- ko if: permissions()['tasklist_manage'].update == true -->
                                    <th><span>Delete</span></th>
                                <!-- /ko -->
                            </tr>
                        </thead>
                        <tbody data-bind="foreach: tasks" id="list-results-body">
                            <tr data-bind="css: { 'is-checked': checked, 'is-reschedule': ($parent.taskToReschedule() != null && $parent.taskToReschedule()['id'] == id) }">

                                <!-- ko if: $parent.permissions()['tasklist_manage'].update == true -->
                                <td>
                                    <div style="margin-top:-1.25rem">
                                        <input type="checkbox" value="checked" data-bind="checked: checked, value: id, attr:{id: 'task_' + id}, click: $parent.onClickChecked""/>
                                        <label data-bind="text: name, attr:{for: 'task_' + id}" class="form-label icon i-check"></label>
                                    </div>
                                </td>
                                <!-- /ko -->
                                 <!-- ko if: $parent.permissions()['tasklist_reschedule_task'].write == true -->
                                    <td class="align-center">
                                    <!-- ko if: (status.id == null || status.id == 3) -->
                                        <button class="btn only-icon icon i-reschedule" type="button" data-bind="click: $parent.onClickReschedule"></button>
                                    <!-- /ko -->
                                    </td>
                                <!-- /ko -->
                                <td class="align-center task_brand"><span data-bind="css: 'full-icon dark square-icon i-' + brand"></span></td>
                                <td class="align-center task_status_name"><span class="status" data-bind="class: 'status-' + status.name.replace(/[^a-z0-9]+/gi, '-').toLowerCase()"></span></td>
                                <td class="align-right task_operation_name"><span data-bind="text: operation.name"></span></td>
                                <!-- ko if: $parent.hasPipelineFilter -->
                                    <td class="task_pipeline"><span data-bind="text: (taskExecuter.group ? taskExecuter.group.name : 'Other') + ' (' + taskExecuter.taskExecuter + ')'"></span></td>
                                <!-- /ko -->
                                <td class="align-left task_date_operation"><span data-bind="text: dateOperation"></span></td>
                                <td class="align-left task_date_scheduled"><span data-bind="text: dateScheduled"></span></td>
                                <td class="align-left task_data_executed"><span data-bind="text: dateExecuted"></span></td>
                                <td class="align-left task_date_creation"><span data-bind="text: executionTime"></span></td>
                                <td class="task_extra_info"><span data-bind="text: extraInfo"></span></td>
                                <td class="task_nb_errors"><span data-bind="text: nbErrors"></span></td>
                                <td class="task_user_info"><span data-bind="text: [userInfo.substring(0,4), type.substring(0,4)].join('-')"></span></td>
                                <!-- ko if: $parent.listMoreInfo() -->
                                <td class="task_pid"><span data-bind="text: pid"></span></td>
                                <td class="task_id"><span data-bind="text: id"></span></td>
                                <td class="task_date_creation"><span data-bind="text: dateCreation"></span></td>
                                <td class="task_date_updated"><span data-bind="text: dateUpdated.date"></span></td>
                                    <td class="task_taskExecuter_name"><span data-bind="text: taskExecuter.name"></span></td>
                                    <td class="task_user_info"><span data-bind="text: userInfo"></span></td>
                                    <td class="task_type"><span data-bind="text: type"></span></td>
                                <!-- /ko -->

                                <!-- ko if: $parent.permissions()['tasklist_manage'].update  == true -->
                                    <td class="align-right"><button class="btn only-icon icon i-delete" type="button"  data-bind="click: $parent.onClickDelete"></button></td>
                                <!-- /ko -->
                            </tr>
                        <tbody>
                        <tfoot>
                        </tfoot>
                    </table>
                </div>



                <div data-bind="css: { 'is-visible': taskDeleteConfirmationVisible }"  class="w-lightbox dark-theme">

                    <div class="lightbox-content">

                        <div class="w-form lightbox-form">
                            <div class="form-header">
                                 <strong class="form-title">Delete Task</strong>
                                 <button data-bind="event: { click: onClickDeleteDialogHide }" class="close-button icon i-close" type="button"></button>
                            </div>

                            <div class="form-body">
                                <div class="form-group">
                                        <!-- ko if: taskToDelete().status.id == 1 -->
                                            <p class="form-text">This task is currently in-progress,  deleting it may have unexpected results</p>
                                        <!-- /ko -->
                                        <p class="form-text">Are you sure you want to delete this task</p>
                                </div>
                            </div>
                            <div class="form-footer">
                                <button data-bind="event: { click: onClickDeleteDialogHide }" class="btn light half" type="button">Cancel</button>
                                <button data-bind="event: { click: onClickDeleteConfirm }" class="btn highlight half" type="button">Delete</button>
                            </div>
                        </div>

                    </div>
                </div>

                <div data-bind="css: { 'is-visible': taskUpdateConfirmationVisible }"  class="w-lightbox dark-theme">

                    <div class="lightbox-content">

                        <div class="w-form lightbox-form">
                            <div class="form-header">
                                 <strong class="form-title">Update Task</strong>
                                 <button data-bind="event: { click: onClickDeleteDialogHide }" class="close-button icon i-close" type="button"></button>
                            </div>

                            <div class="form-body">
                                <div class="form-group">
                                        <p class="form-text">Are you sure you want to update task(s)</p>
                                </div>
                            </div>
                            <div class="form-footer">
                                <button data-bind="event: { click: onClickUpdateDialogHide }" class="btn light half" type="button">Cancel</button>
                                <button data-bind="event: { click: onClickUpdateConfirm }" class="btn highlight half" type="button">Update</button>
                            </div>
                        </div>

                    </div>
                </div>



                <div class="w-timeline" id="timeline" data-bind="css: {'is-open' : taskToReschedule() != null}">
                    <div class="w-timeline-btn">
                        <button class="btn alert" type="button" data-bind="event: { click: onClickCancelReschedule}">cancel</button>
                        <button class="btn" type="button" data-bind="disable: taskToRescheduleIsConflicting() == true, event: { click: onClickSaveReschedule}">save</button>
                    </div>

                    <div class="timeline-option" data-bind="css: {'is-open': manualTimeRescheduleIsOpen() == true}">

                        <!-- <input type="time" id="appt" name="appt" step="1" data-bind="value: taskToRescheduleTargetTime, event: { change: onChangeSetRescheduleTime}"> -->
                        <label for="" class="form-label">Set time<br>Manually</label>
                        <div class="w-time-picker icon i-reschedule">
                            <input type="number" min="0" max="23" step="1" id="hour" name="hour" data-bind="value: taskToRescheduleTargetTimeHour, event: { change: onChangeSetRescheduleTime}">
                            <span>:</span>
                            <input type="number" min="0" max="59" step="1" id="minute" name="minute" data-bind="value: taskToRescheduleTargetTimeMinute, event: { change: onChangeSetRescheduleTime}">
                            <span>:</span>
                            <input type="number" min="0" max="59" step="1" id="second" name="second" data-bind="value: taskToRescheduleTargetTimeSecond, event: { change: onChangeSetRescheduleTime}">

                        </div>
                        <button class="option-btn icon i-dropdown-simple" type="button" data-bind="event: { click: onClickToggleManualTimeRescheduleIsOpen}"></button>

                    </div>

                    <div class="timeline-list" data-bind="foreach: timeline">

                        <!-- ko if: type == 'date' -->
                        <time class="timeline-day" data-bind="text: date"></time>
                        <!-- /ko -->

                        <!-- ko if: type == 'slot' -->
                        <button class="timeline-btn" data-bind="event: { click: $parent.onClickUpdateRescheduledSelection }"></button>
                        <!-- /ko -->

                        <!-- ko if: type == 'slotWithTargetFlag' -->
                        <div class="timeline-btn is-selected">
                            <div class="timeline-marker" data-bind="event: { click: $parent.onClickUpdateRescheduledSelection }">
                                <span class="icon i-task-group"></span>
                                <span class="icon i-arrow-right"></span>
                                <span class="icon i-reschedule"></span>
                                <time data-bind="text: time"></time>
                            </div>
                        </div>
                        <!-- /ko -->

                        <!-- ko if: type == 'targetFlag' -->
                       <div class="timeline-btn is-not-selected">
                            <div class="timeline-marker" data-bind="event: { click: $parent.onClickUpdateRescheduledSelection }">
                                <span class="icon i-task-group"></span>
                                <span class="icon i-arrow-right"></span>
                                <span class="icon i-reschedule"></span>
                                <time data-bind="text: time"></time>
                            </div>
                        </div>
                        <!-- /ko -->

                        <!-- ko if: type == 'block' -->
                        <div class="timeline-item" data-bind="css: {'is-selected': (source == true && $parent.taskToRescheduleTargetTime() == time && $parent.taskToRescheduleTargetDate() == date), 'is-moved': (source == true && ( $parent.taskToRescheduleTargetTime() != time || $parent.taskToRescheduleTargetDate() != date))}">
                            <!--
                            <div class="timeline-details">
                                <div class="timeline-details-header">Operations</div>
                                <ul data-bind="foreach: tasks">
                                    <li data-bind="text: operation.name"></li>
                                </ul>
                            </div>
                            -->
                            <div class="timeline-unit icon">
                                <time data-bind="text: time"></time>
                            </div>
                            <div class="w-tooltips-adv pos-top" data-bind="template: { name: 'infoButton-template', data: $data.infoButton.getTemplateViewModel() }"></div>
                        </div>
                        <!-- /ko -->

                        <!-- ko if: type == 'conflict' -->
                        <div class="timeline-item is-conflict">
                            <div class="timeline-unit icon">
                                <span class="time">Conflict</span>
                            </div>
                        </div>
                        <div class="timeline-btn is-conflict">
                            <div class="timeline-marker" data-bind="event: { click: $parent.onClickUpdateRescheduledSelection }">
                                <span class="icon i-task-group"></span>
                                <span class="icon i-arrow-right"></span>
                                <span class="icon i-reschedule"></span>
                                <time data-bind="text: time"></time>
                            </div>
                        </div>
                        <!-- /ko -->

                    </div>

                </div>

            `;
    }

    //
    // implementation of base class [vnTemplate]
    protected initPartial(): void {

        this.operationFilter = new filterDropdown_template("operationFilter", this.getTemplateViewModel(), this.caller);

        this.brandFilter = new filterSingleGroupSelector_template("brandFilter", this.getTemplateViewModel(), this.caller);

        this.pipelineFilter = new filterSingleGroupSelector_template("pipelineFilter", this.getTemplateViewModel(), this.caller);

        this.executerAutocomplete = new filterMultipleAutoComplete_template("executerFilterAutocomplete", this.getTemplateViewModel(), this.caller);
        this.executerAutocomplete.setIsVisible(true);
        this.executerAutocomplete.setPlaceHolderText("Task Executor");
        this.executerAutocomplete.setTagContainerId('selected-executers');
        this.executerAutocomplete.setTagContainerCaption('Selected Task Executors:');
        this.executerAutocomplete.setMinInputSuggestionLength(3);

        this.resultsInfo = new resultsInfo_template("resultsInfo", this.getTemplateViewModel(), this.caller);
        this.pagination = new pagination_template("pagination", this.getTemplateViewModel(), this.caller);
        this.pagination.getTemplateViewModel().itemPerPage = 25;
        this.simpleFilter = new filterSimple_template("simpleFilter", this.getTemplateViewModel(), this.caller);
        this.updateStatuses = new filterDropdown_template("updateStatuses", this.getTemplateViewModel(), this.caller);
        this.updateStatuses.setDisable(true);
        this.autoRefresh = new autoRefresh_template('autoRefresh', this.getTemplateViewModel(), this.caller);
        this.autoRefresh.setRefreshFrequency(5000);

        this.filterPreset = new filterPreset_template("filterPreset", this.getTemplateViewModel(), this.caller);
        this.filterPreset.getTemplateViewModel().searchPresetType('tasks');

        this.filterTag = new filterMultipleAutoComplete_template("filterTag", this.getTemplateViewModel(), this.caller);
        this.filterTag.setIsVisible(true);
        this.filterTag.setPlaceHolderText("Extra Info Tag");
        this.filterTag.setTagContainerId('selected-tags');
        this.filterTag.setTagContainerCaption('Selected Extra Info Tags:');
        this.filterTag.setMinInputSuggestionLength(3);

        this.statusFilter = new multipleSelector_template("statusFilter", this.getTemplateViewModel(), this.caller);
        this.statusFilter.setLabel("status");
        this.statusFilter.setFilterContainerClass('margin-neg');
        this.statusFilter.setTagContainerId('selected-task_statuses');
        this.statusFilter.setTagContainerCaption('Selected task statuses:');
    }

    public setPrimaryTagsFilterItems(items){

        this.filterTag.setItems(items);
    }

    // implementation of base class [vnTemplate]
    protected getTemplateName(): string {

        return "tasksList_template";
    }

    //
    // implementation of base class [vnTemplate]
    protected getMainElementClasses(): Array<string> {

        return [];
    }

    //
    // implementation of base class [vnTemplate]
    public activateLoading(): void {

        this.getTemplateViewModel().isLoading(1);
    }

    //
    // implementation of base class [vnTemplate]
    public deactivateLoading(): void {

        this.getTemplateViewModel().isLoading(0);
    }

    // implementation of base class [vnTemplate]
    public buildViewModel(): Object {

        let self = this;
        return {
            orderBy: ko.observable("id"),
            orderByDirection: ko.observable("DESC"),
            orderByAndDirection: ko.observable("dateScheduled_DESC"),
            tasks: ko.observableArray([]),
            hasPipelineFilter: ko.observable(false),
            hasTagFilter: ko.observable(false),
            isLoading: ko.observable(0),
            isVisible: ko.observable('is-visible'),
            operationFilter: ko.observable([]),
            statusOptions: ko.observableArray([]),
            statusOptionsSelected: ko.observable(0),
            statusOptionsSelectedText: ko.observable("all"),
            executorFilter: ko.observable([]),
            idUpdateDelete: ko.observable(),
            listMoreInfo: ko.observable(false),
            permissions: ko.observableArray(this.initPermissions(['tasklist_manage', 'tasklist_reschedule_task'])),
            taskToDelete: ko.observable({status: {id: 0}}),
            taskDeleteConfirmationVisible: ko.observable(0),
            taskUpdateConfirmationVisible: ko.observable(0),
            taskToReschedule: ko.observable(null),
            taskToRescheduleTargetTimeHour: ko.observable(null),
            taskToRescheduleTargetTimeMinute: ko.observable(null),
            taskToRescheduleTargetTimeSecond: ko.observable(null),
            taskToRescheduleTargetTime: ko.observable(null),
            taskToRescheduleTargetDate: ko.observable(null),
            taskToRescheduleIsConflicting: ko.observable(false),
            manualTimeRescheduleIsOpen: ko.observable(false),
            timeline: ko.observableArray([]),
            selectAllChecked: ko.observable(false),
            selectedCount: ko.observable(0),
            setAllChecked: function () {
                return self.setAllChecked();
            },
            onClickChecked: function (data) {
                self.onClickChecked(data);
            },
            onClickReorder: function (value, data, event) {
                self.onClickReorder(value, data, event);
            },
            onChangeFilterAction: function (data, event) {
                self.onChangeFilterAction(data, event);
            },
            onClickDelete: function (data) {
                self.onClickDelete(data);
            },
            onClickDeleteDialogHide: function (data, event) {
                self.onClickDeleteDialogHide();
            },
            onClickDeleteConfirm: function (data, event) {
                self.onClickDeleteConfirm();
            },

            onClickUpdate: function (data) {
                self.onClickUpdate(data);
            },
            onClickUpdateDialogHide: function (data, event) {
                self.onClickUpdateDialogHide();
            },
            onClickUpdateConfirm: function (data, event) {
                self.onClickUpdateConfirm();
            },
            onClickAdd: function () {
                self.onClickAdd();
            },
            onClickToggleManualTimeRescheduleIsOpen: function () {
                self.onClickToggleManualTimeRescheduleIsOpen();
            },
            onClickReschedule: function (data) {
                self.onClickReschedule(data);
            },
            onClickUpdateRescheduledSelection: function (data) {
                self.onClickUpdateRescheduledSelection(data);
            },
            onChangeSetRescheduleTime: function () {
                self.onChangeSetRescheduleTime();
            },
            onClickCancelReschedule: function () {
                self.onClickCancelReschedule();
            },
            onClickSaveReschedule: function () {
                self.onClickSaveReschedule();
            },
            onStatusOptionsChange: function (data) {
                self.onStatusOptionsChange(data);
            },
            toggleListMoreInfo: function () {
                self.toggleListMoreInfo();
            }
        };
    }

    public onChangeFilterAction(data, event): void {

        console.log("Lamara Mouffok --- >>>>");
    }


    private toggleListMoreInfo(): void {

        this.getTemplateViewModel().listMoreInfo(!this.getTemplateViewModel().listMoreInfo());
    }

    //
    // implementation of base class [vnTemplate]
    public getViewModelForRequest(): Object {
        const tagFilter = this.getTemplateViewModel().hasTagFilter() ? this.filterTag.getSelectedItems() : [];

        return {
            filters : {
                operationFilter: this.operationFilter.getTemplateViewModel().selectedOption(),
                status: this.getTemplateViewModel().statusFilter.selectedItems(),
                brandFilter: this.brandFilter.getTemplateViewModel().selectedOption(),
                pipelineFilter: this.pipelineFilter.getTemplateViewModel().selectedOption(),
                executorFilter: this.executerAutocomplete.getSelectedItems(),
                autoRefreshFilter: this.autoRefresh.getTemplateViewModel().toggleAutoRefresh(),
                tags: tagFilter,
            },
            operationFilter: this.operationFilter.getTemplateViewModel().selectedOption(),
            status: this.getTemplateViewModel().statusFilter.selectedItems(),
            brandFilter: this.brandFilter.getTemplateViewModel().selectedOption(),
            executorFilter: this.executerAutocomplete.getSelectedItems(),
            autoRefreshFilter: this.autoRefresh.getTemplateViewModel().toggleAutoRefresh(),
            pagination: this.pagination.getTemplateViewModel().pageNumber(),
            orderBy: this.getTemplateViewModel().orderBy(),
            orderByDirection: this.getTemplateViewModel().orderByDirection(),
            keywords: this.simpleFilter.getTemplateViewModel().filterKeyword(),
            taskToReschedule: this.getTemplateViewModel().taskToReschedule(),
            taskToRescheduleTargetTime: this.getTemplateViewModel().taskToRescheduleTargetTime(),
            taskToRescheduleTargetDate: this.getTemplateViewModel().taskToRescheduleTargetDate(),
            autoRefresh: this.autoRefresh.getTemplateViewModel().callRefresh,
            searchPresetName: this.getTemplateViewModel().filterPreset.searchPresetName(),
            searchPresetType: 'tasks',
        };
    }


    public onClickReorder(value, data, event): void {

        this.getTemplateViewModel().orderBy(value);
        if (this.getTemplateViewModel().orderByDirection() == "DESC") {

            this.getTemplateViewModel().orderByDirection("ASC");
        } else {

            this.getTemplateViewModel().orderByDirection("DESC");
        }
        this.getTemplateViewModel().orderByAndDirection(value + "_" + this.getTemplateViewModel().orderByDirection());
        this.caller.reorderTasks();
    }

    public setHasTagFilter(value: boolean): void {

        this.getTemplateViewModel().hasTagFilter(value);
    }

    public setOperationFilterItems(operations): void {

        operations.unshift({"id": 0, "name": "all"});
        this.operationFilter.setOptions(operations);
    }

    public setStatusFilterItems(statuses): void {
        for(let status of statuses) {
            if(typeof status.name == "string") {
                const iconClass = `status-${status.name.replace(/[^a-z0-9]+/gi, '-').toLowerCase()}`;
                status.itemIcon = `<i class="icon i-${iconClass}"></i>`;
            }
        }

        this.statusFilter.setItems(statuses);
    }

    public setUpdateStatuses(status): void {

        this.updateStatuses.setOptions(status);
    }

    public setBrandFilterItems(brand: any): void {

        brand.unshift({"id": 0, "name": "all"});
        this.brandFilter.setOptions(brand);
        this.brandFilter.setSelectedOption(0);
    }

    public setPipelineFilterItems(pipeline: any): void {

        if(pipeline && pipeline.length) {
            this.getTemplateViewModel().hasPipelineFilter(true);
            pipeline.unshift({"id": 0, "name": "all"});
            pipeline.push({"id": null, "name": "Other"});
            this.pipelineFilter.setOptions(pipeline);
            this.pipelineFilter.setSelectedOption(0);
        } else {
            this.getTemplateViewModel().hasPipelineFilter(false);
        }
    }

    public setIdUpdateDelete(idUpdateDelete: number): void {

        this.getTemplateViewModel().idUpdateDelete(idUpdateDelete);
    }

    public setPagination(totalCount: number): void {

        this.pagination.setNumberItemToPaginate(totalCount);
    }

    public setPermissions(permissions: Array<any>): void {

        this.getTemplateViewModel().permissions(permissions);
    }

    public setResultPage(totalCount: number, execTime: number = 0): void {

        let resultsInfoToSet = {
            nbr_results: totalCount,
            nbr_pages: Math.ceil(totalCount / this.getTemplateViewModel().pagination.itemPerPage),
            execution_time: execTime
        };

        this.resultsInfo.setResults(resultsInfoToSet);
    }

    public resetPageNumber(): void {

        this.getTemplateViewModel().pagination.pageNumber(1);
    }

    public resetOrderParameters(): void {

        this.getTemplateViewModel().orderBy("id");
        this.getTemplateViewModel().orderByDirection("DESC");
        this.getTemplateViewModel().orderByAndDirection("id_DESC");
    }

    protected onUpdateError(): void {

        console.log('onUpdateError');
    }

    /**
     * @param tasks
     */
    public setTasks(tasks: Array<any>): void {

        this.getTemplateViewModel().selectAllChecked(false);

        tasks.forEach((task) => {
            if (task.status == null) {

                task.status = {"id": null, "name": "Pending"};
            }
            task.checked = false;
            task.extraInfo = (task.extraInfo != null) ? JSON.stringify(task.extraInfo) : "";
            if(task.taskExecuter) {
                task.brand = task.taskExecuter.taskExecuter ? task.taskExecuter.taskExecuter.split("-")[0] : "";
            } else {
                task.brand = "";
            }
        });
        this.getTemplateViewModel().tasks(tasks);

        this.setSelectedCount();

    }

    public setAllChecked() {

        let selectAllValue = this.getTemplateViewModel().selectAllChecked();

        let tasks = this.getTemplateViewModel().tasks();

        for (var index in tasks) {

            tasks[index].checked = selectAllValue;
        }

        this.getTemplateViewModel().tasks([]);
        this.getTemplateViewModel().tasks(tasks);

        this.setSelectedCount();

        return true;
    }

    public setSelectedCount() {

        let selectedCount = 0;

        let tasks = this.getTemplateViewModel().tasks();

        for (var index in tasks) {

            if (tasks[index].checked == true) {

                selectedCount++;
            }
        }

        this.getTemplateViewModel().selectedCount(selectedCount);
        this.updateStatuses.setDisable(selectedCount === 0);


        if (selectedCount > 0) {

            this.autoRefresh.setToggleAutoRefresh(false);
        }
    }

    public onClickDelete(task: Array<any>) {

        this.getTemplateViewModel().taskToDelete(task);
        this.getTemplateViewModel().taskDeleteConfirmationVisible(1);
    }

    public onClickDeleteDialogHide() {

        this.getTemplateViewModel().taskDeleteConfirmationVisible(0);
    }

    public onClickDeleteConfirm() {

        this.getTemplateViewModel().taskDeleteConfirmationVisible(0);
        this.caller.deleteTask(this.getTemplateViewModel().taskToDelete());
    }


    public onClickUpdate(task: Array<any>) {

        if (this.updateStatuses.getTemplateViewModel().selectedOption() == this.getTemplateViewModel().idUpdateDelete()) {

            this.getTemplateViewModel().taskUpdateConfirmationVisible(1);
        } else {

            this.onClickUpdateConfirm();
        }

        this.disposeTimeLine()

    }

    public onClickUpdateDialogHide() {

        this.getTemplateViewModel().taskUpdateConfirmationVisible(0);
    }

    public onClickUpdateConfirm() {

        this.getTemplateViewModel().taskUpdateConfirmationVisible(0);
        let tasks = this.getTemplateViewModel().tasks();
        let ids = [];

        for (let index in tasks) {

            if (tasks[index].checked == true) {

                ids.push(tasks[index].id)
            }
        }

        this.caller.updateTask(ids, this.updateStatuses.getTemplateViewModel().selectedOption());
    }


    /**
     * Handles the add task action.
     */
    public onClickAdd() {

        this.caller.addTask();
    }

    public onClickChecked(data) {

        let tasks = this.getTemplateViewModel().tasks();


        this.getTemplateViewModel().tasks([]);
        this.getTemplateViewModel().tasks(tasks);

        this.setSelectedCount();

        return true;
    }

    public onClickToggleManualTimeRescheduleIsOpen() {

        let manualTimeRescheduleIsOpen = this.getTemplateViewModel().manualTimeRescheduleIsOpen();

        if (manualTimeRescheduleIsOpen) {

            this.getTemplateViewModel().manualTimeRescheduleIsOpen(false);
        } else {

            this.getTemplateViewModel().manualTimeRescheduleIsOpen(true);
        }
    }

    public onClickReschedule(task: Array<any>) {

        this.onClickCancelReschedule();
        this.getTemplateViewModel().taskToReschedule(task);

        this.caller.rescheduleTask(this.getTemplateViewModel().taskToReschedule());
    }

    public setTimeline(timeline) {

        this.getTemplateViewModel().taskToRescheduleIsConflicting(false);

        for (var index in timeline) {

            if (timeline[index].type == "conflict") {

                this.getTemplateViewModel().taskToRescheduleIsConflicting(true);
            }

            if (timeline[index].source == true) {

                let taskToRescheduleTargetTime = this.getTemplateViewModel().taskToRescheduleTargetTime();

                if (taskToRescheduleTargetTime == null) {
                    let splitTime = timeline[index].time.split(":");
                    this.getTemplateViewModel().taskToRescheduleTargetTimeHour(splitTime[0]);
                    this.getTemplateViewModel().taskToRescheduleTargetTimeMinute(splitTime[1]);
                    this.getTemplateViewModel().taskToRescheduleTargetTimeSecond(splitTime[2]);

                    this.getTemplateViewModel().taskToRescheduleTargetTime(timeline[index].time);
                }

                let taskToRescheduleTargetDate = this.getTemplateViewModel().taskToRescheduleTargetDate();
                if (taskToRescheduleTargetDate == null) {
                    this.getTemplateViewModel().taskToRescheduleTargetDate(timeline[index].date);
                }
            }

            if (timeline[index].type == "block") {
                timeline[index].infoButton = new infoButton_template("infoButton" + index, this.getTemplateViewModel(), this.caller);
                timeline[index].infoButton.setTitle('Operations');

                let tasks = ''
                for (let task of timeline[index].tasks) {

                    tasks = tasks + '<p>' + task.operation.name + '</p>';
                }
                timeline[index].infoButton.setContent(tasks);
            }
        }

        this.getTemplateViewModel().timeline([]);

        this.getTemplateViewModel().timeline(timeline);
        console.log(this.getTemplateViewModel().taskToRescheduleIsConflicting());

    }

    public onClickUpdateRescheduledSelection(slot) {

        let splitTime = slot.time.split(":");
        this.getTemplateViewModel().taskToRescheduleTargetTimeHour(splitTime[0]);
        this.getTemplateViewModel().taskToRescheduleTargetTimeMinute(splitTime[1]);
        this.getTemplateViewModel().taskToRescheduleTargetTimeSecond(splitTime[2]);

        this.getTemplateViewModel().taskToRescheduleTargetTime(slot.time);
        this.getTemplateViewModel().taskToRescheduleTargetDate(slot.date);

        this.caller.rescheduleTask();
    }

    public onChangeSetRescheduleTime() {

        let taskToRescheduleTargetTimeHour = ~~this.getTemplateViewModel().taskToRescheduleTargetTimeHour();
        if (taskToRescheduleTargetTimeHour > 23) {

            taskToRescheduleTargetTimeHour = 23;
        }
        if (taskToRescheduleTargetTimeHour < 0) {

            taskToRescheduleTargetTimeHour = 0;
        }

        let taskToRescheduleTargetTimeMinute = ~~this.getTemplateViewModel().taskToRescheduleTargetTimeMinute();
        if (taskToRescheduleTargetTimeMinute > 59) {

            taskToRescheduleTargetTimeMinute = 59;
        }
        if (taskToRescheduleTargetTimeMinute < 0) {

            taskToRescheduleTargetTimeMinute = 0;
        }

        let taskToRescheduleTargetTimeSecond = ~~this.getTemplateViewModel().taskToRescheduleTargetTimeSecond();
        if (taskToRescheduleTargetTimeSecond > 59) {

            taskToRescheduleTargetTimeSecond = 59;
        }
        if (taskToRescheduleTargetTimeSecond < 0) {

            taskToRescheduleTargetTimeSecond = 0;
        }

        let taskToRescheduleTargetTimeHourPadded = this.padInteger(taskToRescheduleTargetTimeHour, 2);
        let taskToRescheduleTargetTimeMinutePadded = this.padInteger(taskToRescheduleTargetTimeMinute, 2);
        let taskToRescheduleTargetTimeSecondPadded = this.padInteger(taskToRescheduleTargetTimeSecond, 2);

        this.getTemplateViewModel().taskToRescheduleTargetTimeHour(taskToRescheduleTargetTimeHourPadded);
        this.getTemplateViewModel().taskToRescheduleTargetTimeMinute(taskToRescheduleTargetTimeMinutePadded);
        this.getTemplateViewModel().taskToRescheduleTargetTimeSecond(taskToRescheduleTargetTimeSecondPadded);

        this.getTemplateViewModel().taskToRescheduleTargetTime(taskToRescheduleTargetTimeHourPadded + ":" + taskToRescheduleTargetTimeMinutePadded + ":" + taskToRescheduleTargetTimeSecondPadded);

        this.caller.rescheduleTask();
    }

    public onClickCancelReschedule() {
        this.disposeTimeLine()
    }

    public onClickSaveReschedule() {

        this.caller.saveRescheduleTask();
    }

    public onStatusOptionsChange(selected) {

        this.getTemplateViewModel().statusOptionsSelectedText(selected.name);
        this.caller.getListData();
    }

    private padInteger(number: number, size: number) {

        let s = number + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    public setSearchableFields(searchableFields: Array<any>): void {

        this.simpleFilter.setSearchableFields(searchableFields);
    }

    public setSearchPresets(presets) {
        this.filterPreset.setSearchPresets(presets);
    }

    public addCreatedPresetToList(response) {
        this.filterPreset.addCreatedPresetToList(response);
    }

    public setFilterSelectedItems(objFilter, urlParams) {
        if(objFilter instanceof filterSingleGroupSelector_template){
            let filter: filterSingleGroupSelector_template = null;
            switch(objFilter.idElement){
                case 'brandFilter':
                    filter = this.brandFilter;
                    break;
            }

            if(filter !== null){
                const options = [... filter.getTemplateViewModel().options()];
                urlParams = urlParams ? urlParams : 0;
                const selectExecutorFromParams = options.filter(o=>o.id === urlParams)[0];
                const {id, name} = selectExecutorFromParams;
                objFilter.setSelectedOption(id);
                objFilter.setselectedOptionText(name);
            }
        }

        if(objFilter instanceof filterDropdown_template){
            objFilter.setSelectedOption(urlParams)
        }

        if(objFilter instanceof multipleSelector_template){
            if(urlParams) {
                const params = urlParams ? urlParams : [];
                let selectedOptionsFromParams : Array<{id: any}> = [];
                let selectedIDsFromParams : Array<number> = [];

                for(let param of params) {
                    if(param === "") {
                        param = null;
                    }
                    const selectedOptions = objFilter.getItems().filter(o=>o.id == param);
                    if(selectedOptions.length > 0) {
                        const selectedOption = objFilter.getItems().filter(o=>o.id == param)[0];
                        selectedOptionsFromParams.push(selectedOption);
                        if(selectedOption) {
                            selectedIDsFromParams.push(selectedOption.id);
                        }
                    }
                }

                objFilter.setSelectedItems(selectedOptionsFromParams);
                objFilter.setTags(selectedIDsFromParams, objFilter.getItems());
            }
        }

        if(objFilter instanceof autoRefresh_template){
            objFilter.setToggleAutoRefresh(urlParams)
        }

        if(objFilter instanceof filterMultipleAutoComplete_template){
            objFilter.setItems(urlParams);
        }
    }

    public setMultipleAutocompletePresetFilter(autocompleteFilter, items){
        if(items){
            const ids = items.map(t => t.id);
            const names = items.map(t => t.name);

            if(ids != null && names != null && ids.length == names.length){

                let items = [];
                for(let i = 0; i < ids.length; i++){

                    items.push({id: ids[i], name: names[i]})
                }

                autocompleteFilter.getTemplateViewModel().selectedItems(items);
                autocompleteFilter.getTemplateViewModel().selectedItemIds(ids);
                autocompleteFilter.getTemplateViewModel().selectedItemNames(names);
                autocompleteFilter.getTemplateViewModel().setTags(ids, items);
            }else{

                autocompleteFilter.getTemplateViewModel().selectedItems(null);
                autocompleteFilter.getTemplateViewModel().selectedItemIds(null);
                autocompleteFilter.getTemplateViewModel().selectedItemNames(null);
            }
        }
    }

    public setViewModelFromRequest(urlParams) {

        if (urlParams != null) {
            this.setFilterSelectedItems(this.brandFilter, urlParams.brandFilter);
            this.setMultipleAutocompletePresetFilter(this.executerAutocomplete, urlParams.executorFilter);
            this.setFilterSelectedItems(this.operationFilter, urlParams.operationFilter);
            this.setFilterSelectedItems(this.statusFilter, urlParams.status);
            this.setFilterSelectedItems(this.autoRefresh, urlParams.autoRefreshFilter);

            if(this.getTemplateViewModel().hasTagFilter() && urlParams.tags) {
                this.setMultipleAutocompletePresetFilter(this.filterTag, urlParams.tags);
            } else {
                this.setMultipleAutocompletePresetFilter(this.filterTag, []);
            }
        }
    }

    public disposeTimeLine(){
        this.getTemplateViewModel().taskToRescheduleIsConflicting(false);
        this.getTemplateViewModel().taskToReschedule(null);
        this.getTemplateViewModel().taskToRescheduleTargetTime(null);
        this.getTemplateViewModel().taskToRescheduleTargetDate(null);
        this.getTemplateViewModel().timeline([]);
    }
}