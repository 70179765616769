import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import {header} from "./header";
import { prebid_sspSetupAdd_template } from '../components/templates/prebid_sspSetupAdd_template';

declare var ko: any;
declare var page: any;

export class prebid_sspSetup_add extends vnModule_knockoutJS{

    protected prebidSspSetupAdd_template: prebid_sspSetupAdd_template;
    protected XHRHdl: XMLHttpRequestHandler;

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'prebid_sspSetup_add';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("SSP Line Item Upload");

        this.prebidSspSetupAdd_template = new prebid_sspSetupAdd_template('prebidSspSetupAdd', this.viewModel, this)

        return [this.prebidSspSetupAdd_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

    }

    /**
     * Hook.
     *
     * Cancels the edits to a user.
     */
    protected cancelEditPrebidSspSetupAdd(){

        // Redirect to configurations page
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "SSP Line Item Upload action canceled!");

        page.redirect(`/configurations`);
    }

    /**
     * Saves the source data.
     */
    protected save(): void{

        this.loadingIn(this.prebidSspSetupAdd_template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.prebidSspSetupAdd_template.getViewModelForRequest();

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/prebid_sspSetup_add/save/`, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveReturn;
        XHRHdl.execute();

    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, "SSP Line Item Uploaded!");

                page.redirect(`/configurations`);
            });
            obj.loadingOut(obj.prebidSspSetupAdd_template.builtTemplate);
        }
    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.save();
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        //Stay put
    }

}