import {vnTemplate} from "./vnTemplate";
import {infoButton_template} from "./infoButton_template";

declare var ko: any;

export class multipleSelector_template extends vnTemplate{

    protected filterContainerClass: string;
    protected tagContainerId: string = 'filters-selected';
    protected tagContainerCaption: string = 'Selected Filters:';

    protected getTemplateHtml(): string{

        return /* html */`
            <div class="dropdown" data-bind="attr: { id: 'multipleSelector_' + elementIdentifier()}, css: { 'is-no-header' : !selectAllEnabled() && !searchEnabled }">
                <button type="button" class="dropdown-toggle icon" data-bind="text: label"></button>
                <!-- ko if: !isDisabled() -->
                <div data-bind="class: 'dropdown-list '">
                    <div class="w-multi-dropdown">
                        <div class="w-multi-dropdown-header">

                             <!-- ko if: selectAllEnabled() -->
                             <div class="multi-dropdown-header">
                                <button class="btn" type="button" id="select_all" data-bind="disable: isDisabled(), event: { click: onSelectAllAction}">Select All</button>
                                <button class="btn" type="button" id="deselect_all" data-bind="disable: isDisabled(), event: { click: onDeselectAllAction}">Deselect All</button>
                             </div>
                             <!-- /ko -->

                            <!-- ko if: searchEnabled() && (isMutliLevel() || !selectAllEnabled()) -->
                            <div class="multi-dropdown-header">
                                <div class="w-search icon i-search2">
                                    <input class="multi-dropdown-search" type="text" data-bind="textInput: searchValue, event: { keyup: onSearchAction}">
                                </div>
                            </div>
                            <!-- /ko -->
                        </div>


                        <div class="multi-dropdown-body" data-bind="class: isMutliLevel() ? 'is-multi' : ''">


                            <ul class="multi-dropdown-list" data-bind="attr: { id: 'multipleSelector_content_' + elementIdentifier()}">
                                <!-- ko if: isMutliLevel() -->
                                <!-- ko foreach: groups -->
                                <li data-bind="class: ($parent.openedGroupsDrawers.indexOf(name) != -1 || ($parent.searchValue != '' && $parent.searchMatches.indexOf(name) != -1)) ? 'multi-dropdown-item is-open' : 'multi-dropdown-item', style: { display: ($parent.searchValue() != '' && $parent.searchMatches.indexOf(name) == -1) ? 'none' : 'block' }">
                                    <div class="w-input">
                                        <button class="btn-collapse icon i-dropdown-simple-b" type="button" data-bind="event: { click: $parent.onClickGroupDrawerAction.bind($parent)}"></button>
                                        <input class="icon" data-bind="disable: $parent.isDisabled(), value: name, checked: $parent.selectedGroups, attr:{id: 'group_' + $parent.elementIdentifier() + '_' + name}, event: { click: $parent.onClickGroupFilterAction.bind($parent)}" type="checkbox">
                                        <label class="icon i-check" data-bind="text: name, attr:{for: 'group_' + $parent.elementIdentifier() + '_' + name}"></label>
                                    </div>

                                    <div class="multi-dropdown-collapse">
                                        <ul class="multi-dropdown-list">
                                            <!-- ko foreach: items -->
                                            <li class="multi-dropdown-item" data-bind="style: { display: ($parents[1].searchValue() != '' && $parents[1].searchMatches.indexOf(name) == -1) ? 'none' : 'block' }">
                                                <div class="w-input">
                                                    <input class="icon" data-bind="disable: $parents[1].isDisabled(), value: id, checked: $parents[1].selectedItems, attr:{id: 'item_' + $parents[1].elementIdentifier() + '_' + id}, event: { click: $parents[1].onClickItemFilterAction.bind($parents[1])}" type="checkbox">
                                                    <label class="icon i-check" data-bind="text: name, attr:{for: 'item_' + $parents[1].elementIdentifier() + '_' + id}"></label>
                                                    <!-- ko if: $data.tooltip -->
                                                    <div class="w-tooltips-adv pos-right short" data-bind="template: { name: 'infoButton-template', data: $data.tooltip.getTemplateViewModel() }"></div>
                                                    <!-- /ko -->
                                                    <!-- ko if: $parents[1].displaySelectionOrder() -->
                                                        <!-- ko if: $parents[1].selectedItemsOrderBySelection.indexOf(id) != -1 -->
                                                            <span class="note" data-bind="text: $parents[1].selectedItemsOrderBySelection.indexOf(id) + 1"></span>
                                                        <!-- /ko -->
                                                    <!-- /ko -->
                                                </div>
                                            </li>
                                            <!-- /ko -->
                                        </ul>
                                    </div>
                                </li>
                                <!-- /ko -->
                                <!-- /ko -->

                                <!-- ko ifnot: isMutliLevel() -->
                                <!-- ko foreach: items -->
                                <li class="multi-dropdown-item" data-bind="style: { display: ($parent.searchValue() != '' && $parent.searchMatches.indexOf(name) == -1) ? 'none' : 'block' }">
                                    <div class="w-input">
                                        <input class="icon" data-bind="disable: $parent.isDisabled(), value: id, checked: $parent.selectedItems, enable: $parent.selectedItems().indexOf($data.id) !== -1 || !$parent.selectionLimitHit(), attr:{id: 'item_' + $parent.elementIdentifier() + '_' + id}, event: { click: $parent.onClickItemFilterAction.bind($parent)}" type="checkbox">
                                        <!-- ko if: (typeof itemIcon === 'undefined') || !itemIcon -->
                                            <label class="icon i-check" data-bind="text: name, attr:{for: 'item_' + $parent.elementIdentifier() + '_' + id}, class: (typeof itemClass !== 'undefined') && itemClass ? itemClass : ''"></label>
                                        <!-- /ko -->
                                        <!-- ko if: (typeof itemIcon !== 'undefined') && itemIcon -->
                                            <label class="icon i-check" data-bind="html: name + itemIcon, attr:{for: 'item_' + $parent.elementIdentifier() + '_' + id}, class: (typeof itemClass !== 'undefined') && itemClass ? itemClass : ''"></label>
                                        <!-- /ko -->
                                        <!-- ko if: $data.tooltip -->
                                        <div class="w-tooltips-adv pos-right short" data-bind="template: { name: 'infoButton-template', data: $data.tooltip.getTemplateViewModel() }"></div>
                                        <!-- /ko -->
                                        <!-- ko if: $parent.displaySelectionOrder() -->
                                            <!-- ko if: $parent.selectedItemsOrderBySelection.indexOf(id) != -1 -->
                                                <span class="note" data-bind="text: $parent.selectedItemsOrderBySelection.indexOf(id) + 1"></span>
                                            <!-- /ko -->
                                        <!-- /ko -->
                                    </div>
                                </li>
                                <!-- /ko -->
                                <!-- /ko -->
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- /ko -->
            </div>
            `;
    }

    protected getTemplateName(): string{
        return "multiSelector-template";
    }

    protected getMainElementClasses(): Array<string>{
        return [this.filterContainerClass];
    }

    protected buildViewModel(): any{

        // define the viewmodel data/ functions
        let huit_neuf_this = this;
        return  {

            groups: ko.observableArray([])
            ,items: ko.observableArray([])
            ,label: ko.observableArray([])
            ,selectedItems: ko.observableArray([])
            ,selectedGroups: ko.observableArray([])
            ,searchMatches: ko.observableArray([])
            ,openedGroupsDrawers: ko.observableArray([])
            ,selectedItemsOrderBySelection: ko.observableArray([])
            ,separateFilter: ko.observable(false)
            ,elementIdentifier: ko.observable(this.idElement)
            ,selectAllEnabled: ko.observable(true)
            ,searchEnabled: ko.observable(true)
            ,selectionLimit: ko.observable(0) // zero for disabled
            ,selectionLimitHit: ko.observable(false)
            ,displaySelectionOrder: ko.observable(false)
            ,isMutliLevel: ko.observable(false)
            ,searchValue: ko.observable("")
            ,isDisabled : ko.observable(false)
            ,onClickFilterList: function (data) { huit_neuf_this.onClickFilterList(data);}
            ,onSearchAction: function () { huit_neuf_this.onSearchAction();}
            ,onClickItemFilterAction : function (data) { huit_neuf_this.onClickFilterList(data);return true;}
            ,onClickGroupFilterAction: function (data) { huit_neuf_this.onClickGroupFilterList(data);return true;}
            ,onClickGroupDrawerAction: function (data) { huit_neuf_this.onClickGroupDrawerAction(data);return true;}
            ,onSelectAllAction: function () { huit_neuf_this.onSelectAllAction();}
            ,onDeselectAllAction: function () { huit_neuf_this.onDeselectAllAction();}
            ,setTags: function (selectedItems, allItems) { huit_neuf_this.setTags(selectedItems, allItems);}
        };
    }

    protected onDeselectAllAction(){
        this.viewModel[this.idElement].selectedItems([]);
        this.viewModel[this.idElement].selectedGroups([]);
        this.viewModel[this.idElement].openedGroupsDrawers([]);
        this.viewModel[this.idElement].selectedItemsOrderBySelection([]);
        this.removeTagContainer();
        this.applyFilter();
    }

    protected onSearchAction(){

        let searchValue = this.getSearchValue();
        if (searchValue == ''){

            this.getTemplateViewModel().searchMatches([]);

            return;
        }

        let matches = [];

        if (this.getTemplateViewModel().isMutliLevel()){

            for (let group of this.getTemplateViewModel().groups()){

                if (group.name.search(new RegExp(searchValue, "i")) != -1 && matches.indexOf(group.name) == -1){

                    matches.push(group.name);
                }

                for (let item of group.items){

                    if (item.name.search(new RegExp(searchValue, "i")) != -1 && matches.indexOf(item.name) == -1){

                        matches.push(item.name);

                        if (matches.indexOf(group.name) == -1){

                            matches.push(group.name);
                        }
                    }
                }
            }
        } else{

            for (let item of this.getTemplateViewModel().items()){

                if (item.name.search(new RegExp(searchValue, "i")) != -1 && matches.indexOf(item.name) == -1) {

                    matches.push(item.name);
                }
            }
        }

        this.getTemplateViewModel().searchMatches(matches);
    }

    protected onClickFilterList(item){
        this.checkLimit();
        this.showItemFilters(item, this.getSelectedItems(), this.getItems());
        this.applyFilter();

    }

    protected checkLimit() {
        let selectionLimit = this.viewModel[this.idElement].selectionLimit();
        let selectedItems = this.getSelectedItems();

        if (selectionLimit > 0) {
            if (selectedItems.length == selectionLimit) {
                this.viewModel[this.idElement].selectionLimitHit(true);
            } else {
                this.viewModel[this.idElement].selectionLimitHit(false);
            }
        }else {
            this.viewModel[this.idElement].selectionLimitHit(false);
        }
    }

    protected onClickGroupFilterList(group){

        let selectedGroups = this.getSelectedGroups();
        let selectedItems = this.getSelectedItems();
        if(selectedGroups.indexOf(group.name) != -1){
            for(let item of group.items) {
                if (selectedItems.indexOf(item.id) == -1) {
                    this.getTemplateViewModel().selectedItems.push(item.id);
                    this.showItemFilters(item, this.getSelectedItems(), this.getItems());
                }
            }

            let openedGroupsDrawers = this.getOpenedGroupsDrawers();
            if (openedGroupsDrawers.indexOf(group.name) == -1){

                openedGroupsDrawers.push(group.name);
            }

            this.getTemplateViewModel().openedGroupsDrawers(openedGroupsDrawers);
        }else {
            for (let item of group.items) {
                if (selectedItems.indexOf(item.id) != -1) {
                    this.getTemplateViewModel().selectedItems.remove(item.id);
                    this.showItemFilters(item, this.getSelectedItems(), this.getItems());
                }
            }
        }

        this.applyFilter();
    }

    protected onClickGroupDrawerAction(group){

        let openedGroupsDrawers = this.getOpenedGroupsDrawers();
        let index = openedGroupsDrawers.indexOf(group.name);

        if (index != -1){

            openedGroupsDrawers.splice(index, 1);
        } else{

            openedGroupsDrawers.push(group.name);
        }

        this.getTemplateViewModel().openedGroupsDrawers(openedGroupsDrawers);
    }

    protected showItemFilters(lastSelectedItem, selectedItems, allItems) {
        let huit_neuf_this = this;

        this.removeTagContainer();

        let isCaptionAppended = false;

        for (let item of allItems) {

            if (selectedItems.indexOf(item.id) != -1) {

                if (!isCaptionAppended) {
                    this.addCaption();
                    isCaptionAppended = true;
                }

                this.addTag(item);
            }
        }

        this.updateSelectedItemsOrderBySelection(lastSelectedItem);

    }

    public setTags(selectedItems, allItems){

        this.removeTagContainer();

        let isCaptionAppended = false;
        for (let item of allItems) {

            if (selectedItems.indexOf(item.id) != -1) {

                if (!isCaptionAppended) {
                    this.addCaption();
                    isCaptionAppended = true;
                }

                this.addTag(item);
            }
        }
    }

    protected onSelectAllAction() {
        let huit_neuf_this = this;
        let allItems = this.viewModel[this.idElement].items();

        if(this.getTemplateViewModel().isMutliLevel()){
            let allGroups = this.viewModel[this.idElement].groups();
            this.setSelectedGroups(allGroups);
        }else{
            this.setSelectedItems(allItems);
        }

        this.removeTagContainer();

        let isCaptionAppended = false;
        for (let item of allItems) {

            if (!isCaptionAppended) {
                this.addCaption();
                isCaptionAppended = true;
            }

            this.addTag(item);
        }

        this.applyFilter();
    }

    public setSelectedItems(selectedItems : Array<{id: any}>){
        let items = [];
        for(let c of selectedItems){
            items.push(c.id);
        }

        this.viewModel[this.idElement].selectedItems(items);
        this.checkLimit();
    }

    public setSelectedGroups(selectedGroups : Array<{name: string}>){
        let items = [];
        let groups = [];
        let allGroups = this.getGroups();
        console.log(allGroups);

        for(let selectedGroup of selectedGroups){
            let selectedGroupName = selectedGroup.name;
            let selectedGroupData = allGroups.find(i => i.name === selectedGroupName);

            for(let item of selectedGroupData.items){
                items.push(item.id);
            }

            groups.push(selectedGroupName);


        }

        this.viewModel[this.idElement].selectedGroups(groups);
        this.viewModel[this.idElement].selectedItems(items);
    }

    public setSelectedItemsOrderBySelection(selectedItemsOrderBySelection : Array<{id: string | number}>){
        let items = [];
        for(let c of selectedItemsOrderBySelection){
            items.push(c.id);
        }

        this.viewModel[this.idElement].selectedItemsOrderBySelection(items);
    }

    public setSeparateFilter(separateFilter){
        this.getTemplateViewModel().separateFilter(separateFilter);
    }

    public setItems(items){

        let i = 0;
        for (let item of items){

            item = this.addTooltipData(item, i++);
        }

        this.getTemplateViewModel().items(items);
        this.getTemplateViewModel().groups(items);
    }

    public setMultiSectionItems(groups: Array<{name : string, items : Array<{id : number, name : string}>}>){

        let items = [];
        let i = 0;

        for(let group of groups){

            group = this.addTooltipData(group, i++);

            for(let item of group.items){

                item = this.addTooltipData(item, i++);

                items.push(item);
            }
        }

        this.getTemplateViewModel().isMutliLevel(true);
        this.getTemplateViewModel().groups(groups);
        this.getTemplateViewModel().items(items);
        this.getTemplateViewModel().selectionLimit(0);
    }

    private createDescriptionTooltip(i, description){

        let tooltip = new infoButton_template('tooltip-' + i++, this.getTemplateViewModel(), this.caller);
        tooltip.setContent(description);

        return tooltip;
    }

    public setLabel(label){
        this.getTemplateViewModel().label(label);
    }

    public setFilterContainerClass(className: string)
    {
        this.filterContainerClass = className;
    }

    public setTagContainerId(id: string)
    {
        this.tagContainerId = id;
    }

    public setTagContainerCaption(caption: string)
    {
        this.tagContainerCaption = caption;
    }

    protected addCaption()
    {
        if (this.getTagContainer()) {
            this.getTagContainer().appendChild(this.createCaption());
        }

    }

    protected addTag(tag)
    {
        if (this.getTagContainer()) {
            this.getTagContainer().appendChild(this.createTag(tag));
        }

    }

    protected createCaption()
    {
        let element = document.createElement('label');

        element.setAttribute('class', 'small');
        element.appendChild(document.createTextNode(this.tagContainerCaption));

        return element;
    }

    protected createTag(tag)
    {
        let huit_neuf_this = this;

        let element = document.createElement('button');

        element.setAttribute('id', tag.id);
        element.setAttribute('class', 'btn-f-s icon i-x-after');
        element.setAttribute('type', 'button');

        element.addEventListener('click', function (e) {
            let selectedItems = [];

            for (let selectedItem of huit_neuf_this.getTemplateViewModel().selectedItems()) {

                if (this.id == `${selectedItem}`) {
                    continue;
                }

                selectedItems.push(selectedItem);
            }

            for (let selectedItemOrderBySelection of huit_neuf_this.getTemplateViewModel().selectedItemsOrderBySelection()) {

                if (this.id == selectedItemOrderBySelection) {
                    huit_neuf_this.getTemplateViewModel().selectedItemsOrderBySelection.remove(selectedItemOrderBySelection);
                }

            }

            huit_neuf_this.getTemplateViewModel().selectedItems(selectedItems);
            huit_neuf_this.applyFilter();

            if (selectedItems.length > 0) {
                this.parentNode.removeChild(this);
            } else {
                huit_neuf_this.removeTagContainer();
            }

            huit_neuf_this.checkLimit();
        }, false);

        element.appendChild(document.createTextNode(tag.name));

        return element;
    }

    private applyFilter(){

        this.caller.filterMultipleSelectorOnChange(this.idElement);
    }

    protected getTagContainer()
    {
        return document.getElementById(this.tagContainerId);
    }

    protected removeTagContainer()
    {
        if(document.getElementById(this.tagContainerId)){
            document.getElementById(this.tagContainerId).innerHTML = '';
        }

    }

    private updateSelectedItemsOrderBySelection(lastSelectedItem){

        let alreadySelectedOrderedItems = this.viewModel[this.idElement].selectedItemsOrderBySelection();
        let itemAlreadySelected = false;
        for(let alreadySelectedOrderedItem of alreadySelectedOrderedItems){
            if(alreadySelectedOrderedItem == lastSelectedItem.id){
                this.viewModel[this.idElement].selectedItemsOrderBySelection.remove(alreadySelectedOrderedItem);
                itemAlreadySelected = true;
            }
        }

        if(!itemAlreadySelected){
            this.viewModel[this.idElement].selectedItemsOrderBySelection.push(lastSelectedItem.id);
        }

    }

    public setSelectionLimit(limit){
        this.viewModel[this.idElement].selectionLimit(limit);
        if (limit > 0) {
            this.viewModel[this.idElement].selectAllEnabled(false);
        }
    }

    public setSelectAllEnabled(flag: boolean){
        if (flag) {
            this.viewModel[this.idElement].selectionLimit(0);
        }
        this.viewModel[this.idElement].selectAllEnabled(flag);
    }

    public setSearchEnabled(flag: boolean){
        this.viewModel[this.idElement].searchEnabled(flag);
    }

    public setDisplaySelectionOrder(flag: boolean){
        this.viewModel[this.idElement].displaySelectionOrder(flag);
    }

    public getSelectedItems(){
        return this.viewModel[this.idElement].selectedItems();
    }

    public getSelectedGroups(){
        return this.getTemplateViewModel().selectedGroups();
    }

    public getOpenedGroupsDrawers(){
        return this.getTemplateViewModel().openedGroupsDrawers();
    }

    public getItems(){
        return this.getTemplateViewModel().items();
    }

    public getGroups(){
        return this.getTemplateViewModel().groups();
    }

    public getSearchValue(){
        return this.getTemplateViewModel().searchValue();
    }

    //
    // Adds tooltip data to an object.
    private addTooltipData(obj, index) {

        if (obj.hasOwnProperty('description') && obj['description']) {

            obj['tooltip'] = this.createDescriptionTooltip(index++, obj['description']);
        }

        return obj;
    }

    public setIsDisabled(value : boolean){
        this.getTemplateViewModel().isDisabled(value)
    }
}
