import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class videoPrebidFloors_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `


            <div class="list-header w-justify">
                <div class="w-left">
                    <div>
                        <label class="form-label">Geo</label>
                        <select data-bind="
                                options: geos,
                                optionsText: 'name',
                                optionsValue: 'id',
                                value: currentIdGeo,
                                event: { change: onChangeGeo }
                            ">
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-header">
                <h3 class="form-title icon i-config">Edit Video Prebid Floors</h3>

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
      
            <div class="form-body" style="width: auto;" data-bind="visible: currentIdGeo()">
                <div>
                    <div class="form-group" style="padding: 10px 0 10px 0; text-align: center">
                        <label class="form-sub-title" data-bind="text: name"></label>
                    </div>
                    <div class="form-group row">
                        <label for="instreamFloor" class="form-label">Instream</label>
                        <input data-bind="value: defaultInstream" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <input id="instreamFloor" data-bind="value: instreamFloor" type="text" class="input-text" style="width:15%; margin : 0 5px">
                    </div>                     
                    <div class="form-group row">
                        <label for="outstreamFloor" class="form-label">Outstream</label>
                        <input data-bind="value: defaultOutstream" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <input id="outstreamFloor" data-bind="value: outstreamFloor" type="text" class="input-text" style="width:15%; margin : 0 5px">
                    </div>                      
                    <div class="form-group row">
                        <label for="companionFloor" class="form-label">Companion</label>
                        <input data-bind="value: defaultCompanion" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <input id="companionFloor" data-bind="value: companionFloor" type="text" class="input-text" style="width:15%; margin : 0 5px">
                    </div>                       
                    <div class="form-group row">
                        <label for="outstreamInBannerFloor" class="form-label">Outstream-In-Banner</label>
                        <input data-bind="value: defaultOutstreamInBanner" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <input id="outstreamInBannerFloor" data-bind="value: outstreamInBannerFloor" type="text" class="input-text" style="width:15%; margin : 0 5px">
                    </div>      
                </div>
            </div>

            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "videoPrebidFloors-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "w-form-large", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{
        let huit_neuf_this = this;

        return {
            id: ko.observable(''),
            geos: ko.observableArray([]),
            floors: ko.observableArray([]),
            instreamFloor: ko.observable(""),
            outstreamFloor: ko.observable(""),
            companionFloor: ko.observable(""),
            outstreamInBannerFloor: ko.observable(""),
            defaultInstream: ko.observable(""),
            defaultOutstream: ko.observable(""),
            defaultCompanion: ko.observable(""),
            defaultOutstreamInBanner: ko.observable(""),
            defaultVideoPrebidFloor: ko.observable(""),
            currentIdGeo: ko.observable(''),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
            onChangeGeo: function (data, event) { huit_neuf_this.onChangeGeo(); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        let viewModel         = this.getTemplateViewModel();

        return {
            id : viewModel.id(),
            idGeo : viewModel.currentIdGeo(),
            instreamFloor : viewModel.instreamFloor(),
            outstreamFloor : viewModel.outstreamFloor(),
            companionFloor : viewModel.companionFloor(),
            outstreamInBannerFloor : viewModel.outstreamInBannerFloor()
        };
    }

    public setVideoPrebidFloors(videoPrebidFloors): void {

        let viewModel = this.getTemplateViewModel();

        let floors = [];
        this.getTemplateViewModel().geos().forEach(geo => {
            let found = false;

            videoPrebidFloors.forEach(videoPrebidFloor => {
                if (geo.id === videoPrebidFloor.idGeo){
                    floors.push(videoPrebidFloor);
                    found = true;
                }
            });

            if (!found){
                floors.push(
                    {
                        id: null,
                        name: geo.name,
                        idGeo: geo.id,
                        instreamFloor: viewModel.defaultVideoPrebidFloor(),
                        outstreamFloor: viewModel.defaultVideoPrebidFloor(),
                        outstreamInBannerFloor: viewModel.defaultVideoPrebidFloor(),
                        companionFloor: viewModel.defaultVideoPrebidFloor()
                    }
                );
            }

        });
        this.getTemplateViewModel().floors(floors);
        this.onChangeGeo();
    }
    
    public setGeos(geos){
        this.getTemplateViewModel().currentIdGeo(geos[0].id);
        this.getTemplateViewModel().geos(geos);
    }    
    
    public setDefaultFloors(defaultFloors){
        this.getTemplateViewModel().defaultInstream(defaultFloors.instreamFloor);
        this.getTemplateViewModel().defaultOutstream(defaultFloors.outstreamFloor);
        this.getTemplateViewModel().defaultCompanion(defaultFloors.companionFloor);
        this.getTemplateViewModel().defaultOutstreamInBanner(defaultFloors.outstreamInBannerFloor);
    }

    public setDefaultVideoPrebidFloor(defaultVideoPrebidFloor){
        this.getTemplateViewModel().defaultVideoPrebidFloor(defaultVideoPrebidFloor);
    }

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{
        this.caller.saveVideoPrebidFloor();
    }


    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{
        this.caller.cancelvideoPrebidFloor();
    }

    /**
     * Return the current advising partner shown in t   ab
     */
    protected onChangeGeo() {
        let viewModel = this.getTemplateViewModel();
        this.getTemplateViewModel().floors().forEach(floor => {
            if (floor.idGeo === viewModel.currentIdGeo()){
                viewModel.id(floor.id);
                viewModel.instreamFloor(floor.instreamFloor);
                viewModel.outstreamFloor(floor.outstreamFloor);
                viewModel.companionFloor(floor.companionFloor);
                viewModel.outstreamInBannerFloor(floor.outstreamInBannerFloor);
            }
        });
    }

}
