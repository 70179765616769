import { vnTemplate } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";
import * as am4core from "@amcharts/amcharts4/core" ;
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";

declare let ko: any;
declare let AmCharts: any;

export class amCharts_v4_category_line_template extends vnTemplate{

    protected getTemplateHtml(): string{

        return `<div data-bind="attr: {id: idChart}, style: {width: csswidth, height: cssheight}"></div>`;
    }

    public getTemplateName(): string{
        return "amCharts_v4_category_line_template";
    }

    protected buildViewModel(): any{

        let _this = this;
        return  {
            idChart: ko.observable("chartDiv_" + _this.idElement),
            category: ko.observable(""),
            csswidth: ko.observable("98%"),
            cssheight: ko.observable("550px"),
            yaxis: ko.observableArray([]),
            ranges: ko.observableArray([]),
            disableClickableLegend: ko.observable(0),
            enableXScrollbar: ko.observable(0),
            autoRotateLabels: ko.observable(0),
            labelMaxWidth: ko.observable(null),
        };
    }

    protected getMainElementClasses(): Array<string>{
        return ["graph"];
    }


    public setCategory(category) {
        this.getTemplateViewModel().category(category);
    }

    public setYaxis(axis) {
        this.getTemplateViewModel().yaxis(axis);
    }

    public setRanges(ranges) {
        this.getTemplateViewModel().ranges(ranges);
    }

    public setDisableClickableLegend(value){

        this.getTemplateViewModel().disableClickableLegend(value);
    }

    public setEnableXScrollbar(value){

        this.getTemplateViewModel().enableXScrollbar(value);
    }

    public setLabelMaxWidth(value) {

        this.getTemplateViewModel().labelMaxWidth(value);
    }

    public setAutoRotateLabels(value) {

        this.getTemplateViewModel().autoRotateLabels(value);
    }

    //public drawChart(data, labels, axes, seriess, textColor){
    public drawChart(chartData) {

        let _this = this;

        /* Chart code */
        // Themes begin
        am4core.options.autoDispose = true;

        am4core.useTheme(am4themes_animated);
        am4core.useTheme(am4themes_dark);

        // Themes end

        // Create chart instance
        let chart = am4core.create("chartDiv_" + _this.idElement, am4charts.XYChart);

        // Increase contrast by taking evey second color
        //chart.colors.step = 2;

        // Add data
        chart.data = chartData;

        // Create axes
        let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());

        xAxis.dataFields.category = this.getTemplateViewModel().category();

        if (this.getTemplateViewModel().labelMaxWidth() != null) {

            var label = xAxis.renderer.labels.template;
            label.truncate = true;
            label.maxWidth = this.getTemplateViewModel().labelMaxWidth();
        }

        if (this.getTemplateViewModel().autoRotateLabels() == 1) {

            xAxis.events.on("sizechanged", function (ev) {

                var axis = ev.target;
                var cellWidth = axis.pixelWidth / (axis.endIndex - axis.startIndex);
                if (cellWidth < axis.renderer.labels.template.maxWidth) {
                    axis.renderer.labels.template.rotation = -45;
                    axis.renderer.labels.template.horizontalCenter = "right";
                    axis.renderer.labels.template.verticalCenter = "middle";
                } else {
                    axis.renderer.labels.template.rotation = 0;
                    axis.renderer.labels.template.horizontalCenter = "middle";
                    axis.renderer.labels.template.verticalCenter = "top";
                }
            });
        }

        xAxis.renderer.grid.template.location = 0;
        xAxis.renderer.minGridDistance = 1;

        let yAxis = [];
        for (let axis of this.getTemplateViewModel().yaxis()) {

            let valueAxis = new am4charts.ValueAxis();
            valueAxis.maxPrecision = 0;

            if (typeof yAxis[axis.axis] === 'undefined') {
                yAxis[axis.axis] = chart.yAxes.push(valueAxis);
            }

            let series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = axis.field;
            series.minBulletDistance = 10;
            if (axis.hasOwnProperty('tooltipfield')) {

                series.dataFields.customValue = axis.tooltipfield;
            }else {

                series.dataFields.customValue = axis.field;
            }

            if (axis.hasOwnProperty('stacked')){

                series.stacked = axis.stacked;
                series.fillOpacity = 0.5;
            }

            if(axis.hasOwnProperty('hidden')){

                series.hidden = axis.hidden;
            }

            if(axis.hasOwnProperty('showOnInit')){

                series.showOnInit = axis.showOnInit;
            }

            if (axis.hasOwnProperty('fillOpacity')){

                series.fillOpacity = axis.fillOpacity;
            }

            if (axis.hasOwnProperty('filters')){

                for (let filter of axis.filters){
                    series.filters.push(filter);
                }
            }

            if (axis.hasOwnProperty('strokeDasharray')){

                series.strokeDasharray = axis.strokeDasharray;
            }

            if (axis.hasOwnProperty('strokeWidth')){

                series.strokeWidth = axis.strokeWidth;
            }else{

                series.strokeWidth = 2;
            }

            series.dataFields.categoryX = this.getTemplateViewModel().category();
            series.fill = am4core.color(axis.color);
            series.stroke = am4core.color(axis.color);
            series.yAxis = yAxis[axis.axis];
            series.name = axis.name;
            series.tooltipText = "{name}: [bold]{customValue}[/]";
            series.smoothing = "monotoneX";
            series.showOnInit = true;


            let interfaceColors = new am4core.InterfaceColorSet();


            let bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.stroke = interfaceColors.getFor("background");
            bullet.circle.strokeWidth = 2;

            yAxis[axis.axis].renderer.line.strokeOpacity = 1;
            yAxis[axis.axis].renderer.line.strokeWidth = 2;
            //yAxis[axis.axis].renderer.line.stroke = series.stroke;
            //yAxis[axis.axis].renderer.labels.template.fill = series.stroke;
            yAxis[axis.axis].renderer.line.stroke = am4core.color('#FCF370');
            yAxis[axis.axis].renderer.labels.template.fill = am4core.color('#FCF370');
            yAxis[axis.axis].renderer.opposite = axis.opposite;
        }

        for (let range of this.getTemplateViewModel().ranges()) {

            let addrange = xAxis.axisRanges.create();
            addrange.category = range.category;
            addrange.endCategory = range.endCategory;
            addrange.axisFill.fill = am4core.color(range.fill);
            addrange.axisFill.fillOpacity = range.fillOpacity;
            addrange.locations.category = range.locationsCategory;
            addrange.locations.endCategory = range.locationsEndCategory;
        }

        // Add legend
        chart.legend = new am4charts.Legend();

        if (this.getTemplateViewModel().disableClickableLegend() == 1) {

            chart.legend.itemContainers.template.clickable = false;
            chart.legend.itemContainers.template.focusable = false;
            chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
        }

        if (this.getTemplateViewModel().enableXScrollbar() == 1){

            chart.scrollbarX = new am4charts.XYChartScrollbar();
        }

        if (chartData.length === 0 ) {

            let message = chart.createChild(am4core.Label);
            message.text = "No data available.";
            message.fontSize = 15;
            message.align = "center";
            message.isMeasured = false;

            message.x = am4core.percent(50);
            message.y = am4core.percent(50);
            message.verticalCenter = "middle";
            message.horizontalCenter = "middle";
        }

        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        // Fe styles
        chart.logo.disabled = true;


        return chart;
    }
}
