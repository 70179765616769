import {vnTemplate} from "./vnTemplate";
import {filterSimple_template} from "./filterSimple_template";
import {pagination_template} from "./pagination_template";
import {resultsInfo_template} from "./resultsInfo_template";

declare var ko: any;

export class rolesList_template extends vnTemplate {

    protected filter: filterSimple_template;
    protected pagination: pagination_template;
    protected resultInfos: resultsInfo_template;

    protected getTemplateHtml(): string {

        return  `
                
                <div class="list-header margin-top w-justify">
                    
                    <div class="w-left">
                        <!-- ko if: isAddButtonVisible() -->
                        <button data-bind="click: onClickAdd" class="btn secondary icon i-add" type="button">Add role</button>
                        <!-- /ko --> 
                    </div>
                    <div class="w-left">
                        ` + this.filter.getHtmlBuild() +`
                    </div>
                    <div class="w-right">
                        ` + this.pagination.getHtmlBuild() +`
                    </div>
                </div>
         
                <div class="w-list-results"  id="list-results">
                    <table class="list-results">
                        ` + this.resultInfos.getHtmlBuild() + `
                        <thead>
                            <input data-bind="value: orderBy" type="hidden">
                            <input data-bind="value: orderByDirection" type="hidden">
                            <tr>
                                <!-- <th><span>View</span></th> -->
                                <th><span data-bind="event: { click: onClickReorder.bind($data, 'id') }, css: { 'list-sort-desc': orderByAndDirection()=='id_DESC', 'list-sort-asc': orderByAndDirection()=='id_ASC' }" class="icon list-sort-none">Id</span></th>
                                <th><span data-bind="event: { click: onClickReorder.bind($data, 'name') }, css: { 'list-sort-desc': orderByAndDirection()=='name_DESC', 'list-sort-asc': orderByAndDirection()=='name_ASC' }" class="icon list-sort-none">Name</span></th>
                                <th><span data-bind="event: { click: onClickReorder.bind($data, 'description') }, css: { 'list-sort-desc': orderByAndDirection()=='description_DESC', 'list-sort-asc': orderByAndDirection()=='description_ASC' }" class="icon list-sort-none">Description</span></th>
                                <th><span data-bind="event: { click: onClickReorder.bind($data, 'sequence') }, css: { 'list-sort-desc': orderByAndDirection()=='sequence_DESC', 'list-sort-asc': orderByAndDirection()=='sequence_ASC' }" class="icon list-sort-none">Sequence</span></th>
                            </tr>
                        </thead>
                        <tbody data-bind="foreach: roles">        
                            <tr class="cursor">
                                <!-- <td class="align-left"><button class="btn icon border only-icon icon i-view" type="button" ></button></td> -->
                                <td data-bind="click: $parent.onClickView" class="role_id"><a data-bind="attr: {href: viewUrl}"><span data-bind="text: id"></span></a></td>
                                <td data-bind="click: $parent.onClickView" class="role_name"><a data-bind="attr: {href: viewUrl}"><span data-bind="text: name"></span></a></td>
                                <td data-bind="click: $parent.onClickView" class="role_description"><a data-bind="attr: {href: viewUrl}"><span data-bind="text: description"></span></a></td>
                                <td data-bind="click: $parent.onClickView" class="role_sequence"><a data-bind="attr: {href: viewUrl}"><span data-bind="text: sequence"></span></a></td>
                            </tr>                 
                        <tbody>
                        <tfoot>
                        </tfoot>
                    </table>
                </div>
            `;
    }

    protected initPartial(): void {
        this.filter = new filterSimple_template("filter", this.getTemplateViewModel(), this.caller);
        this.pagination = new pagination_template("pagination", this.getTemplateViewModel(), this.caller);
        this.pagination.getTemplateViewModel().itemPerPage = 25;
        this.resultInfos = new resultsInfo_template("resultInfo", this.getTemplateViewModel(), this.caller);
    }

    protected getTemplateName(): string {
        return "rolesList-template";
    }

    protected getMainElementType(): string {
        return "div";
    }

    protected getMainElementClasses(): Array<string> {
        return ["w-content"];
    }

    protected buildViewModel(): any {

        // define the viewmodel data/ functions
        let me = this;
        return {
            orderBy: ko.observable(""),
            orderByDirection: ko.observable(""),
            orderByAndDirection: ko.observable(""),
            roles: ko.observableArray([]),
            isAddButtonVisible: ko.observable(false),
            onClickReorder: function (value, data, event) {
                me.onClickReorder(value, data, event);
            },
            onClickView: function (data, event) {
                me.onClickView(data.id);
            },
            onClickAdd: (data, event) => {
                me.onClickAdd();
            }
        };
    }

    //
    // implementation of base class [vnTemplate]
    public getViewModelForRequest() {

        return {
            orderBy: this.getTemplateViewModel().orderBy,
            orderByDirection: this.getTemplateViewModel().orderByDirection,
            orderByAndDirection: this.getTemplateViewModel().orderByAndDirection,
            pagination: this.getTemplateViewModel().pagination,
            keyword: this.getTemplateViewModel().filter.filterKeyword
        }
    }

    /**
     *
     * @param value
     * @param data
     * @param event
     */
    public onClickReorder(value, data, event) {

        data.orderBy(value);
        //if column has already been sorted, flip the direction
        if (data.orderByAndDirection().includes(value)) {
            if (data.orderByDirection() == "DESC") {
                data.orderByDirection("ASC");
            } else {
                data.orderByDirection("DESC");
            }
        } else {
            //default column sort direction
            data.orderByDirection("DESC");
        }

        data.orderByAndDirection(value + "_" + data.orderByDirection());
        this.caller.rolesListReorder();
    }

    /**
     *
     * @param id
     */
    public onClickView(id): void {
        this.caller.viewRole(id);
    }

    public onClickAdd(): void {
        this.caller.addRole();
    }

    /**
     *
     * @param roles
     */
    public setRoles(roles: Array<{ id: number, name: string, description: string, sequence: number}>): void {

        roles.forEach((r) => r['viewUrl'] = '/roles/view/' + r.id)
        this.getTemplateViewModel().roles(roles);
    }

    /**
     *
     * @param nbResults
     * @param nbPages
     * @param execTime
     */
    public setResultInfo(nbResults, nbPages, execTime ) {

        this.resultInfos.setExecutionTime(execTime);
        this.resultInfos.setNbResults(nbResults);
        this.resultInfos.setNbPages(nbPages);
    }

    public setPaginationTotalCount(totalCount: number):  void {
        this.pagination.setNumberItemToPaginate(totalCount);
    }

    public isAddButtonVisible(isAddButtonVisible: boolean): void {
        this.getTemplateViewModel().isAddButtonVisible(isAddButtonVisible);
    }
}
