import {vnTemplate} from "./vnTemplate";
import {vnAppZone} from "../vnApp/vn_app_zone";

declare var ko: any;

export class singleSelector_template extends vnTemplate{

    protected getTemplateHtml(): string{

        return `
                <!-- ko if: selected()  -->
                    <button data-bind=" css: selected().iconImage" class="btn secondary dropdown-toggle icon i-dropdown" type="button">
                    <span data-bind="text: selected().name"></span>
                    </button>
                <!-- /ko -->
                <!-- ko ifnot: selected() || items().length == 0  -->
                    <button class="btn secondary dropdown-toggle icon i-dropdown" type="button"><span></span></button>
                <!-- /ko -->
                <ul  data-bind="foreach: items" class="dropdown-list main-nav-list" >
                        <li class="main-nav-item">
                            <a class="main-nav-link" data-bind="click: $parent.onChangeSelected, text: name, css: iconImage, attr: { href: '/brand/' + id + '/home' }"></a>
                        </li>
                </ul>
            `;
    }

    protected getTemplateName(): string{
        return "singleSelector-template";
    }

    protected getMainElementClasses(): Array<string>{
        return ["dropdown", "prop-dropdown"];
    }

    protected buildViewModel(): any{

        // define the viewmodel data/ functions
        let huit_neuf_this = this;
        return  {
             selected: ko.observable()
            ,items: ko.observableArray()
            ,onChangeSelected: function(brand){ huit_neuf_this.onChangeSelected(brand); }
        };
    }

    public setItems(items: Array<{id: number, name: string, iconImage: string}>){
        this.getTemplateViewModel().items(items);
    }

    public setSelected(selected){
        this.viewModel[this.idElement].selected(selected);
    }

    public setSelectedById(idSelected){

        let selection;
        for (let i of this.getTemplateViewModel().items()) {
            if( i.id==idSelected){
                selection = i;
            }
        }

        this.getTemplateViewModel().selected(selection);
    }

    private onChangeSelected(selected){

        this.setSelected(selected);
        this.caller.changeSelection( selected.id );
    }

}
