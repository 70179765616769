import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";
import { filterDropdown_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDropdown_template';

declare var ko: any;

export class sspGroupEdit_template extends vnTemplate{

    protected deviceOption: filterDropdown_template;
    protected adsSetupTagOption: filterDropdown_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <!-- ko ifnot: idSspGroup -->
                <h3 class="form-title icon i-add">Add SSP Group</h3>
                <!-- /ko -->

                <!-- ko if: idSspGroup -->
                <h3 class="form-title icon i-group">edit SSP Group</h3>
                <!-- /ko -->

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
            <div class="w-form-body"> 
                <div class="form-body">
                    <div class="form-group">
                        <legend class="form-legend">Status</legend>
                        <div class="form-check-inline width-auto">
                            <input type="radio" name="isEnabled" id="enabled_yes" value="1" data-bind="checked: isEnabled" />
                            <label class="form-label" for="enabled_yes">Active</label>
                        </div>
                        <div class="form-check-inline width-auto">
                            <input type="radio" name="isEnabled" id="enabled_no" value="0" data-bind="checked: isEnabled" />
                            <label class="form-label" for="enabled_no">Inactive</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="name" class="form-label">name</label>
                        <input id="name" data-bind="value: name" type="text" class="input-text">
                     </div>
                     <div class="form-group row">
                        <label class="form-label">Device</label>
                        ${this.deviceOption.getHtmlBuild()}
                    </div>
                    <div class="form-group row">
                        <label class="form-label">Tag</label>
                        ${this.adsSetupTagOption.getHtmlBuild()}
                    </div>
                     <div class="form-group">
                        <hr class="form-hr" />
                     </div>
                     <div class="form-group">
                        <label for="" class="form-sub-title">SSP<small>s</small></label>
                        <div data-bind="foreach: allSsps">
                            <div class="form-check-inline">
                                <input type="checkbox" data-bind="checked: $parent.ssps(), value: id, attr:{'id' : 'ssp_' + id, 'name' : 'ssp_' + id}">
                                <label class="form-label icon i-check" data-bind="text: displayName, attr:{'for': 'ssp_' + id}"></label>
                            </div>
                        </div>
                     </div>
                     
<!--                     <div class="form-group row adding border-top">-->
<!--                        <label class="form-label full">Extra parameters</label>-->
<!--                        <button data-bind="click: onClickAddExtraParameter" class="btn border icon-only icon i-add" type="button"></button>-->
<!--                    </div>-->
<!--                    -->
<!--                    <div data-bind="foreach: extraParameters">-->
<!--                        <div class="form-group row adding"> -->
<!--                            <div class="row">-->
<!--                                <label class="form-label fix">Name</label>-->
<!--                                <input data-bind="value: name" type="text" class="input-text">-->
<!--                            </div>-->
<!--                            <div class=" row ">-->
<!--                                <label class="form-label fix">Value</label>-->
<!--                                <input data-bind="value: value" type="text" class="input-text">-->
<!--                            </div>-->
<!--                            <div class=" right">-->
<!--                                <button data-bind="click: $parent.onClickDeleteExtraParameter" class="btn border icon-only icon i-remove" type="button"></button>-->
<!--                            </div>-->
<!--                        </div> -->
<!--                    </div> -->
                </div>   
            </div>
            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{

        this.deviceOption      = new filterDropdown_template('deviceOption', this.getTemplateViewModel(), this.caller);
        this.adsSetupTagOption = new filterDropdown_template('adsSetupTagOption', this.getTemplateViewModel(), this.caller);
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "sspGroupEdit-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;

        return {
            idSspGroup: ko.observable(''),
            name: ko.observable(''),
            ssps: ko.observableArray([]),
            allSsps: ko.observableArray([]),
            // extraParameters: ko.observableArray([]),
            isEnabled: ko.observable(0),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
            // onClickAddExtraParameter: function(data, event){ huit_neuf_this.onClickAddExtraParameter(); },
            // onClickDeleteExtraParameter: function(data, event){ huit_neuf_this.onClickDeleteExtraParameter(data); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            idSspGroup: this.getTemplateViewModel().idSspGroup(),
            name: this.getTemplateViewModel().name(),
            ssps: this.getTemplateViewModel().ssps(),
            device: this.deviceOption.getTemplateViewModel().selectedOption(),
            // extraParameters: this.caller.formatExtraParametersForBackend(this.getTemplateViewModel().extraParameters()),
            isEnabled: this.getTemplateViewModel().isEnabled(),
            idAdsSetupTag: this.adsSetupTagOption.getTemplateViewModel().selectedOption(),
        };
    }

    //
    // Sets data.
    public setSspGroup(sspGroup): void{

        this.getTemplateViewModel().idSspGroup(sspGroup.id);
        this.getTemplateViewModel().name(sspGroup.name);
        this.getTemplateViewModel().ssps(sspGroup.ssps || []);
        this.getTemplateViewModel().isEnabled(sspGroup.isEnabled ? '1' : '0');
        this.getTemplateViewModel().deviceOption.setSelectedOption(sspGroup.device);
        this.getTemplateViewModel().adsSetupTagOption.setSelectedOption(sspGroup.idAdsSetupTag);
    }

    //
    // Device options.
    public setDevicesOptionItems(devices): void{

        devices.unshift({id: null, name: 'all'});
        this.deviceOption.setOptions(devices);
    }

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        this.caller.saveEditSspGroup();
    }

    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancelSspGroup();
    }

    // /**
    //  * Handles add new extra parameter click event.
    //  */
    // protected onClickAddExtraParameter(): void{
    //
    //     this.getTemplateViewModel().extraParameters.push({name: '', value: ''});
    // }
    //
    // /**
    //  * Handles add new extra parameter click event.
    //  */
    // protected onClickDeleteExtraParameter(data): void{
    //
    //     let removedExtraParameter = this.getTemplateViewModel().extraParameters().find(i => i.name === data.name);
    //     this.getTemplateViewModel().extraParameters.remove(removedExtraParameter);
    // }

    public setAdsSetupTagOptionItems(options): void{

        options.unshift({ id: null, name: 'none'});
        this.adsSetupTagOption.setOptions(options);
    }
}
