import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import {header} from "./header";
import {bidderTypeLibraryList_template} from "../components/templates/bidderTypeLibraryList_template";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";

declare var ko: any;
declare var page: any;

export class bidderTypeLibrary_list extends vnModule_knockoutJS{

    protected template: bidderTypeLibraryList_template;
    protected requestHandler: XMLHttpRequestHandler;

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'bidderTypeLibrary_list';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName('Header');
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle('Bidder Type Library List');

        this.template = new bidderTypeLibraryList_template('bidderTypeLibraryList_template', this.viewModel, this);

        return [this.template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getListFormData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public paginationOnPageClick(): void{

        this.getListData();
    }

    /**
     * @inheritDoc
     */
    protected reorderBidderTypes(): void{

        this.getListData();
    }


    /**
     * Fetches the list data.
     */
    public getListData(): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem('list-results'));

        let subViewModel = this.template.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(['data', ko.toJSON(subViewModel)] );

        this.requestHandler = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/bidder_type_library/get/`, usrReq_params, this);
        this.requestHandler.onReadyStateFunction = this.onDataRequestReturn;
        this.requestHandler.execute();
    }

    /**
     * Returns handler for data request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateList(responseParsed);
                obj.setResultInfo(responseParsed.totalCount, responseParsed.totalPage);
                obj.updatePagination(responseParsed.totalCount);
                obj.loadingOut(obj.template.builtTemplate.children.namedItem('list-results'));
            });
        }
    }

    /**
     * Update the module list using the objects list.
     *
     * @param responseParsed
     */
    protected updateList(responseParsed: any): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem('list-results'));
        
        this.template.setData(responseParsed.data);
    }
    
    //
    // Hook.
    // Redirects to edit page.
    protected edit(id, idBidderType): void{

        page(`/brand/${this.getVnAppZone().app.params.idBrand}/bidder_type_library/edit/${id}`);
    }

    //
    // Hook.
    // Redirects to edit page.
    protected editPrebid(id, idBidderType): void{

        page(`/brand/${this.getVnAppZone().app.params.idBrand}/bidder_type_library/prebidConfigurator/edit/${id}`);
    }

    protected setResultInfo(totalCount, totalPage){

        let execTime = Math.round((new Date().getTime() - this.requestHandler.requestStartTime)/1000*100)/100;
        this.template.setResultInfo(totalCount, totalPage, execTime);
    }

    protected updatePagination(totalCount) {

        this.template.updatePagination(totalCount);
    }

    /**
     * Hook.
     *
     * Cancels data request.
     */
    protected filterSimpleCancel(){

        if(this.requestHandler){

            this.requestHandler.cancelAndReset();
        }
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected filterSimpleOnChange(){

        this.template.resetPageNumber();
        this.template.resetOrderParameters();
        this.getListData();
    }

    //
    // Requests data for the filters.
    protected getListFormData(): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem('list-results'));

        this.requestHandler = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/bidder_type_library/references`, [], this);
        this.requestHandler.onReadyStateFunction = this.onFilterRequestReturn;
        this.requestHandler.execute();
    }

    //
    // Returns handler for filter request.
    protected onFilterRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateFilters(responseParsed);
                obj.getListData();
            });
        }
    }

    //
    // Update the filters.
    protected updateFilters(responseParsed: any): void{

        this.template.setDeviceFilterItems(responseParsed.devices);
    }

    //
    // Hook.
    // Initiates data refresh.
    public filterDropdownOnChange(): void{

        this.getListData();
    }

    /**
     * Hook.
     *
     * Redirects to create user page.
     */
    protected add(): void{

        page(`/brand/${this.getVnAppZone().app.params.idBrand}/bidder_type_library/add`);
    }


    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.delete(data);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

    }

    /**
     * Deletes the requested resource.
     *
     * @param id
     * @protected
     */
    protected delete(id): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem('list-results'));

        this.requestHandler                      = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/bidder_type_library/delete/${id}`, [], this);
        this.requestHandler.mode                 = XMLHttpRequestHandler_requestType.POST;
        this.requestHandler.onReadyStateFunction = this.onDeleteRequestReturn;
        this.requestHandler.execute();
    }

    /**
     * Returns handler for delete request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDeleteRequestReturn(req, obj): Function{

        return function(){

            obj.loadingOut(obj.template.builtTemplate.children.namedItem('list-results'));

            obj.manageResponse(req, obj, function(){

                obj.getListData();
            });
        }
    }

    /**
     * Saves the user data.
     */
    protected updatePositions(): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem('list-results'));

        let formReq_params: Array<Array<string>> = [];
        let subViewModel                         = this.template.getViewModelForRequest();

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/bidder_type_library/update_positions/`, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onUpdatePositionsReturn;
        XHRHdl.execute();
    }

    /**
     * Handles update positions response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onUpdatePositionsReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.getListData();
            });
        }
    }
}