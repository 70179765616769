import {vnTemplate} from "./vnTemplate";

declare var ko: any;

export class filterMultipleAutoComplete_template extends vnTemplate{

    protected delay = 400;
    protected minInputSuggestionLength = 3;
    protected debounceTimerId: number;

    protected filterContainerClass: string;
    protected tagContainerId: string = 'filters-selected';
    protected tagContainerCaption: string = 'Selected Filters:';

    protected getTemplateHtml(): string{

        return `
                <div class="w-spec-search icon i-auto-complete" data-bind="visible: isVisible, css: icon">
                    <input data-bind="event: { keyup: onChangeInput, blur: onBlurInput }, valueUpdate : 'afterkeydown', attr:{placeholder:placeHolderText}, textInput: nameSelectedItem" list="items" class="input-text">
                        <ul class="form-auto-list" data-bind="foreach: items,  visible: isShowItemSuggestions" id="items">
                            <li class="form-auto-item" data-bind="attr:{id:id}, text: name, css: $data.iconImage || '', click: $parent.onClickInput" ></li>
                        </ul>
                    </input>
                </div>
                `;
    }

    protected getTemplateName(): string{

        return "filterMultipleAutoComplete-template";
    }

    protected getMainElementClasses(): Array<string>{

        return [this.filterContainerClass];
    }

    protected buildViewModel(): any{

        // define the viewmodel data/ functions
        let huit_neuf_this = this;
        return  {
            items: ko.observableArray([])
            ,selectedItemsOrderBySelection: ko.observableArray([])
            ,selectedItemIds: ko.observableArray([])
            ,selectedItemNames: ko.observableArray([])
            ,selectedItems: ko.observableArray([])
            ,nameSelectedItem: ko.observable("")
            ,placeHolderText: ko.observable("")
            ,elementIdentifier: ko.observable(this.idElement)
            ,orderBy: "dateCreation"
            ,orderByDirection: "DESC"
            , isShowItemSuggestions: ko.observable(false)
            , isVisible: ko.observable(false)
            , icon: ko.observable("i-user2")
            , onChangeInput: function (data, event) { huit_neuf_this.onChangeInput(event); }
            , onClickInput: function (data, event) { huit_neuf_this.onClickInput(data, event); }
            , onBlurInput: function (data, event) { huit_neuf_this.onBlurInput(data, event); }
            , setTags: function (selectedItems, allItems) { huit_neuf_this.setTags(selectedItems, allItems);}
        };
    }

    protected onChangeInput(event) {

        clearTimeout(this.debounceTimerId);

        let _myThis = this;
        this.debounceTimerId = window.setTimeout(function() {

            _myThis.updateSelectedItems(event);
        }, this.delay);
    }

    protected updateSelectedItems(event) {

        let input = event.target;
        let inputVal = input.value;

        if (inputVal.length >= this.minInputSuggestionLength) {

            this.caller.getMultipleAutoCompleteSuggestions(this.idElement, inputVal);
            this.getTemplateViewModel().isShowItemSuggestions(true);
        }else {

            this.getTemplateViewModel().isShowItemSuggestions(false);
        }
    }

    protected onClickInput(data, event) {

        this.getTemplateViewModel().nameSelectedItem("");
        let currSelectedItems = this.getTemplateViewModel().selectedItems();

        if(currSelectedItems == null){
            currSelectedItems = [];
        }

        //only add item if its not already there
        if(currSelectedItems.filter(i => i.id == data.id).length == 0){
            currSelectedItems.push({id: data.id, name: data.name});
        }

        this.getTemplateViewModel().selectedItems(currSelectedItems);
        this.getTemplateViewModel().selectedItemIds(this.getSelectedItemIds());
        this.getTemplateViewModel().selectedItemNames(this.getSelectedItemNames());

        this.getTemplateViewModel().isShowItemSuggestions(false);

        this.showItemFilters(data, currSelectedItems);
        this.applyFilter();
    }

    protected showItemFilters(lastSelectedItem, selectedItems) {

        let huit_neuf_this = this;

        this.removeTagContainer();

        let isCaptionAppended = false;

        for (let item of selectedItems) {

            if (!isCaptionAppended) {

                this.addCaption();
                isCaptionAppended = true;
            }

            this.addTag(item);
        }

        this.updateSelectedItemsOrderBySelection(lastSelectedItem);
    }

    private updateSelectedItemsOrderBySelection(lastSelectedItem){

        let alreadySelectedOrderedItems = this.viewModel[this.idElement].selectedItemsOrderBySelection();
        let itemAlreadySelected = false;
        for(let alreadySelectedOrderedItem of alreadySelectedOrderedItems){

            if(alreadySelectedOrderedItem == lastSelectedItem.id){

                this.viewModel[this.idElement].selectedItemsOrderBySelection.remove(alreadySelectedOrderedItem);
                itemAlreadySelected = true;
            }
        }

        if(!itemAlreadySelected){

            this.viewModel[this.idElement].selectedItemsOrderBySelection.push(lastSelectedItem.id);
        }
    }

    protected onBlurInput(data, event) {

        setTimeout(function (tmpl) {

            tmpl.nameSelectedItem("");
            tmpl.isShowItemSuggestions(false);
        }, 250, this.viewModel[this.idElement]);
    }

    public setIsVisible(isVisible) {

        this.getTemplateViewModel().isVisible(isVisible);
    }

    public setItems(items) {

        this.getTemplateViewModel().items(items);
    }

    public setPlaceHolderText(placeHolderText) {

        this.getTemplateViewModel().placeHolderText(placeHolderText);
    }

    public setIcon(icon) {

        this.getTemplateViewModel().icon(icon);
    }

    public setSelectedItems(selectedItems : Array<{id: any}>) {

        let items = [];
        for(let c of selectedItems) {

            items.push(c.id);
        }

        this.viewModel[this.idElement].selectedItems(items);
        this.viewModel[this.idElement].selectedItemIds(this.getSelectedItemIds());
        this.viewModel[this.idElement].selectedItemNames(this.getSelectedItemNames());
    }

    public setFilterContainerClass(className: string) {

        this.filterContainerClass = className;
    }

    public setTagContainerId(id: string) {

        this.tagContainerId = id;
    }

    protected getTagContainer() {

        return document.getElementById(this.tagContainerId);
    }

    protected removeTagContainer() {

        document.getElementById(this.tagContainerId).innerHTML = '';
    }

    public setTagContainerCaption(caption: string) {

        this.tagContainerCaption = caption;
    }

    protected createCaption() {

        let element = document.createElement('label');

        element.setAttribute('class', 'small');
        element.appendChild(document.createTextNode(this.tagContainerCaption));

        return element;
    }

    public setTags(selectedItems, allItems) {

        this.removeTagContainer();

        let isCaptionAppended = false;
        for (let item of allItems) {

            if (selectedItems.indexOf(item.id) != -1) {

                if (!isCaptionAppended) {

                    this.addCaption();
                    isCaptionAppended = true;
                }

                this.addTag(item);
            }
        }
    }

    protected addCaption() {

        this.getTagContainer().appendChild(this.createCaption());
    }

    protected addTag(tag) {

        this.getTagContainer().appendChild(this.createTag(tag));
    }

    protected createTag(tag) {

        let huit_neuf_this = this;

        let element = document.createElement('button');

        element.setAttribute('id', tag.id);
        element.setAttribute('class', 'btn-f-s icon i-x-after');
        element.setAttribute('type', 'button');

        element.addEventListener('click', function (e) {

            let selectedItems = [];

            for (let selectedItem of huit_neuf_this.getTemplateViewModel().selectedItems()) {

                if (this.id == selectedItem.id) {

                    continue;
                }

                selectedItems.push(selectedItem);
            }



            for (let selectedItemOrderBySelection of huit_neuf_this.getTemplateViewModel().selectedItemsOrderBySelection()) {

                if (this.id == selectedItemOrderBySelection) {

                    huit_neuf_this.getTemplateViewModel().selectedItemsOrderBySelection.remove(selectedItemOrderBySelection);
                }
            }

            huit_neuf_this.getTemplateViewModel().selectedItems(selectedItems);
            huit_neuf_this.getTemplateViewModel().selectedItemIds(huit_neuf_this.getSelectedItemIds());
            huit_neuf_this.getTemplateViewModel().selectedItemNames(huit_neuf_this.getSelectedItemNames());


            huit_neuf_this.applyFilter();

            if (selectedItems.length > 0) {

                this.parentNode.removeChild(this);
            } else {

                huit_neuf_this.removeTagContainer();
            }

        }, false);

        element.appendChild(document.createTextNode(tag.name));

        return element;
    }

    private applyFilter() {

        this.caller.filterSimpleOnChange(this.idElement);
    }

    public getSelectedItems(){

        return this.viewModel[this.idElement].selectedItems();
    }

    public setMinInputSuggestionLength(len){

        this.minInputSuggestionLength = len;
    }

    public getSelectedItemIds(){

        return this.pluck(this.viewModel[this.idElement].selectedItems(), 'id');
    }

    private getSelectedItemNames(){

        return this.pluck(this.viewModel[this.idElement].selectedItems(), 'name');
    }

    private pluck(array, key) {

        return array.map(o => o[key]);
    }
}
