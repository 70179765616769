import { filterDropdown_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDropdown_template";
import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class advertisingPartnerByBrand_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
        <!-- ko if: advertisingPartnersByBrand().length == 0 -->
            <div class="form-body w-form"  style="width:90rem;">
                <div class="w-form-body"> 
                    <div class="form-header" style="margin-top:4rem;">
                        <h3 class="form-title icon form-text tip-warning">No Advertising Partner Access has been created yet.</h3>
                    </div>
                    <a href="/advertising_partners/access/add" type="button"><button class="btn icon i-add" type="button"> Create Advertising Partner Access</button></a>
                </div>
            </div>
        <!-- /ko -->
        <!-- ko if: advertisingPartnersByBrand().length > 0 -->
            <div class="list-header w-justify">
                <div class="w-left">
                    <div>
                        <label class="form-label">Ad Partner Access</label>
                        <select data-bind="
                                options: advertisingPartnersByBrand,
                                optionsText: 'name',
                                optionsValue: 'idAdvertisingPartnerAccess',
                                value: currentIdAdvertisingPartnerAccess,
                                event: { change: onChangeAdvertisingPartnerByBrand }
                            ">
                        </select>
                    </div>
                </div>
            </div>

            <div class="form-body w-form"  style="width:90rem;" data-bind="visible: currentIdAdvertisingPartnerAccess()">
                <div class="w-form-body"> 
                    <div class="form-header" style="margin-top:4rem;">
                        <h3 class="form-title icon i-settings" data-bind="text: currentAdvertisingPartnerByBrand().name"></h3>
                    </div>

                    <div class="form-group" style="margin-top: 1.6rem;">
                        <legend class="form-legend">active</legend>
                        <div class="form-check-inline width-auto">
                            <input type="radio" name="isActive" id="active_yes" data-bind="value:true, checked: isCurrentActive" />
                            <label class="form-label" for="active_yes">Yes</label>
                        </div>
                        <div class="form-check-inline width-auto">
                            <input type="radio" name="isActive" id="active_no" data-bind="value:false, checked: isCurrentActive" />
                            <label class="form-label" for="active_no">No</label>
                        </div>
                    </div>                 
                    
                    <div class="form-group" data-bind="visible: isCurrentActive, foreach: currentAdvertisingPartnerByBrand().fields">
                        <!-- ko if: field_type != 'dynamic'-->
                        <div class="row">
                            <label class="form-label" data-bind="text: field_name"></label>
                            <input style="margin-top:3px" data-bind="value: value, disable: field_type == 'global', css: { 'read-only': field_type == 'global'  }" type="text" class="input-text">
                        </div>
                        <!-- /ko -->
                    </div>

                    <div class="form-footer">
                        <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>  
                        <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save advertising Partner</span></button>  
                    </div>
                </div>
            </div>
        <!-- /ko -->
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "advertisingPartnerByBrand-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{
        return [];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{
        let huit_neuf_this = this;
        return {
            currentAdvertisingPartnerByBrand: ko.observableArray([]),
            currentIdAdvertisingPartnerAccess: ko.observable(''),
            advertisingPartnersByBrand: ko.observableArray([]),
            isCurrentActive: ko.observable(false),
            onChangeAdvertisingPartnerByBrand: function (data, event) { huit_neuf_this.onChangeAdvertisingPartnerByBrand(); },
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); }
        };

    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{
        return {
            idAdvertisingPartnerByBrand: this.getTemplateViewModel().currentAdvertisingPartnerByBrand().idAdvertisingPartnerByBrand,
            idAdvertisingPartnerAccess: this.getTemplateViewModel().currentAdvertisingPartnerByBrand().idAdvertisingPartnerAccess,
            idBrand: this.getTemplateViewModel().currentAdvertisingPartnerByBrand().idBrand,
            isActive: this.getTemplateViewModel().isCurrentActive(),
            fields: this.getTemplateViewModel().currentAdvertisingPartnerByBrand().fields,
        };
    }

    public setAdvertisingPartnersByBrand(advertisingPartnersByBrand): void{
        this.getTemplateViewModel().advertisingPartnersByBrand(advertisingPartnersByBrand);
        if (advertisingPartnersByBrand.length > 0) {
            this.getTemplateViewModel().currentAdvertisingPartnerByBrand(advertisingPartnersByBrand[0]);
            this.getTemplateViewModel().isCurrentActive(!!advertisingPartnersByBrand[0].isActive);
        }
    }
    
    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{
        this.caller.saveAdvertisingPartnerByBrand();
    }


    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancelAdvertisingPartnerByBrand();
    }

    /**
     * Return the current advising partner shown in tab
     */
    private onChangeAdvertisingPartnerByBrand() {
        this.getTemplateViewModel().advertisingPartnersByBrand().forEach((advertisingPartnerByBrand) => {
            if (advertisingPartnerByBrand.idAdvertisingPartnerAccess == this.getTemplateViewModel().currentIdAdvertisingPartnerAccess()) {
                this.getTemplateViewModel().currentAdvertisingPartnerByBrand(advertisingPartnerByBrand);
                this.getTemplateViewModel().isCurrentActive(!!advertisingPartnerByBrand.isActive);
            }
        })
    }

}
