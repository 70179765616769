import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";
import { filterDropdown_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDropdown_template';

declare var ko: any;

export class bidderTypeLibraryEdit_template extends vnTemplate{

    protected deviceOption: filterDropdown_template;
    protected bidderTypeOption: filterDropdown_template;
    protected adsSetupTagOption: filterDropdown_template;
    protected geoGroupOption: filterDropdown_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <!-- ko ifnot: id -->
                <h3 class="form-title icon i-add">Add New Bidder Type Library</h3>
                <!-- /ko -->

                <!-- ko if: id -->
                <h3 class="form-title icon i-bidder">Edit Bidder Type Library</h3>
                <!-- /ko -->

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
            <div class="w-form-body">
                <div class="form-body full-width">
                    <div class="form-group">
                        <legend class="form-legend">Status</legend>
                        <div class="form-check-inline width-auto">
                            <input type="radio" name="isEnabled" id="enabled_yes" value="1" data-bind="checked: isEnabled" />
                            <label class="form-label" for="enabled_yes">Active</label>
                        </div>
                        <div class="form-check-inline width-auto">
                            <input type="radio" name="isEnabled" id="enabled_no" value="0" data-bind="checked: isEnabled" />
                            <label class="form-label" for="enabled_no">Inactive</label>
                        </div>
                    </div>
                     <div class="form-group row">
                        <label for="name" class="form-label">name</label>
                        <input id="name" data-bind="value: name" type="text" class="input-text">
                     </div>
                     <div class="form-group row">
                        <label class="form-label">Device</label>
                        ${this.deviceOption.getHtmlBuild()}
                     </div>
                     <div class="form-group row">
                        <label class="form-label">Tag</label>
                        ${this.adsSetupTagOption.getHtmlBuild()}
                    </div>
                     <div class="form-group row">
                        <label class="form-label">Bidder Type</label>
                        ${this.bidderTypeOption.getHtmlBuild()}
                     </div>
                     <div class="form-group row">
                        <label class="form-label">GEO Group</label>
                        ${this.geoGroupOption.getHtmlBuild()}
                     </div>
                     <div class="form-group row start" data-bind="visible:bidderTypeOption.selectedOption() == 2">
                        <label for="header_dependancy" class="form-label">library</label>
                        <!-- ko ifnot: id -->
                        <textarea id="header_dependancy" data-bind="value: headerDependancy" class="input-text" style="height:55rem;"></textarea>
                        <!-- /ko -->
                        <!-- ko if: id -->
                        <textarea id="header_dependancy" data-bind="value: headerDependancy" class="input-text" style="height:55rem;"></textarea>
                        <!-- /ko -->
                     </div>
                     <!-- ko if: bidderTypeOption.selectedOption() == 1 && !idSspGroup()-->
                     <div class="form-group start" >
                        <hr class="form-hr" />
                        <label class="form-label">Prebid Configurator</label>
                        <div data-bind="foreach: prebidOptions">
                            <div class="">
                                <strong class="form-sub-title" data-bind="text: name"></strong>
                            </div>
                            <div data-bind="foreach: items" class="">
                                <div class="form-check-inline">
                                    <input type="checkbox" data-bind="checked: checked, attr:{ 'id': 'option_' + id, 'name': 'option_' + id }, event: {click:$parents[1].onClickCheckBidder}">
                                    <label class="form-label icon i-check title" data-bind="text: displayName, attr:{ 'for': 'option_' + id }"></label>   
                                </div>
                            </div>                        
                        </div>
                    </div>
                    <div class="form-group start" data-bind="visible:showPrebidServer()">
                        <label class="form-sub-title">Prebid Server SSPs</label>
                        <div data-bind="foreach: allServerSsps">
                            <div class="form-check-inline">
                                <input type="checkbox" data-bind="checked: $parent.serverSsps(), value: id, attr:{'id' : 'ssp_' + id, 'name' : 'ssp_' + id}">
                                <label class="form-label icon i-check" data-bind="text: displayName, attr:{'for': 'ssp_' + id}" style="white-space: nowrap;"></label>
                            </div>
                        </div>
                    </div>
                    <!-- /ko -->
                     
                     
                </div>  
            </div> 

            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

        this.deviceOption       = new filterDropdown_template('deviceOption', this.getTemplateViewModel(), this.caller);
        this.bidderTypeOption   = new filterDropdown_template('bidderTypeOption', this.getTemplateViewModel(), this.caller);
        this.adsSetupTagOption  = new filterDropdown_template('adsSetupTagOption', this.getTemplateViewModel(), this.caller);
        this.geoGroupOption     = new filterDropdown_template('geoGroupOption', this.getTemplateViewModel(), this.caller);
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "bidderTypeLibraryEdit_template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "w-form-large", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;

        return {
            id: ko.observable(''),
            idSspGroup: ko.observable(''),
            headerDependancy: ko.observable(''),
            name: ko.observable(''),
            // extraParameters: ko.observableArray([]),
            isEnabled: ko.observable(0),
            idBidderType: ko.observable(''),
            prebidOptions: ko.observableArray([]),
            showPrebidServer: ko.observable(false),
            allServerSsps: ko.observableArray([]),
            serverSsps: ko.observableArray([]),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
            onClickCheckBidder: function (data, event) {huit_neuf_this.onClickCheckBidder(data);return true}
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            id: this.getTemplateViewModel().id(),
            headerDependancy: this.getTemplateViewModel().headerDependancy(),
            name: this.getTemplateViewModel().name(),
            device: this.deviceOption.getTemplateViewModel().selectedOption(),
            // extraParameters: this.caller.formatExtraParametersForBackend(this.getTemplateViewModel().extraParameters()),
            isEnabled: this.getTemplateViewModel().isEnabled(),
            idBidderType: this.bidderTypeOption.getTemplateViewModel().selectedOption(),
            idAdsSetupTag: this.adsSetupTagOption.getTemplateViewModel().selectedOption(),
            idGeoGroup: this.geoGroupOption.getTemplateViewModel().selectedOption(),
            prebidOptions:this.getTemplateViewModel().prebidOptions(),
            serverSsps: this.getTemplateViewModel().serverSsps(),
        };
    }

    public setData(data): void{

        this.getTemplateViewModel().id(data.id);
        this.getTemplateViewModel().name(data.name);
        this.getTemplateViewModel().headerDependancy(data.headerDependancy || '<script></script>');
        this.getTemplateViewModel().isEnabled(data.isEnabled ? '1' : '0');
        this.getTemplateViewModel().deviceOption.setSelectedOption(data.device);
        this.getTemplateViewModel().bidderTypeOption.setSelectedOption((data.bidderType && data.bidderType.id) ? data.bidderType.id : null);
        this.getTemplateViewModel().adsSetupTagOption.setSelectedOption((data.adsSetupTag && data.adsSetupTag.id) ? data.adsSetupTag.id : null);
        this.getTemplateViewModel().geoGroupOption.setSelectedOption((data.geoGroup && data.geoGroup.id) ? data.geoGroup.id : null);
        this.getTemplateViewModel().idSspGroup(data.idSspGroup);
    }

    //
    // Device options.
    public setDevicesOptionItems(devices): void{

        devices.unshift({id: null, name: 'all'});
        this.deviceOption.setOptions(devices);
    }

    //
    // Bidder type options.
    public setBidderTypeOptionItems(options): void{

        this.bidderTypeOption.setOptions(options);
    }

    //
    // Handles clicking save button event.
    protected onClickSave(): void{

        this.caller.saveEditBidderType();
    }

    //
    // Handles clicking cancel button event.
    protected onClickCancel(): void{

        this.caller.cancelEditBidderType();
    }

    // /**
    //  * Handles add new extra parameter click event.
    //  */
    // protected onClickAddExtraParameter(): void{
    //
    //     this.getTemplateViewModel().extraParameters.push({name: '', value: ''});
    // }

    // /**
    //  * Handles add new extra parameter click event.
    //  */
    // protected onClickDeleteExtraParameter(data): void{
    //
    //     let removedExtraParameter = this.getTemplateViewModel().extraParameters().find(i => i.name === data.name);
    //     this.getTemplateViewModel().extraParameters.remove(removedExtraParameter);
    // }

    public setAdsSetupTagOptionItems(options): void{

        options.unshift({ id: null, name: 'none'});
        this.adsSetupTagOption.setOptions(options);
    }

    public setGeoGroupsOptionItems(options): void{

        options.unshift({id: null, name: 'all'});
        this.geoGroupOption.setOptions(options);
    }

    public setPrebidOptionItems(data): void {

        let options = [];
        let sectionName = {
            bidAdapter: 'Bid Adapters',
            analyticsAdapter: 'Analytics Adapters',
            idSystem: 'ID System',
            realTimeDataProvider: 'Real Time Data Providers',
            adServerVideo: 'Ad Server Video Modules',
        };

        for(let x in data){

            options.push({ name: sectionName[x] || x, items: data[x] });
        }

        this.getTemplateViewModel().prebidOptions(options);

    }

    public setPrebidServerDefault(option): void {

        this.getTemplateViewModel().showPrebidServer(option);
    }

    protected onClickCheckBidder(data){

        if(data.name == "prebidServer"){

            this.getTemplateViewModel().showPrebidServer(data.checked);
        }

        return true;
    }

    public setAllServerSsps(data) : void {

        this.getTemplateViewModel().allServerSsps(data);

    }

}
