import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class connectionConfig_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <!-- ko ifnot: idConnectionConfig -->
                <h3 class="form-title icon i-config">Edit Connection Config</h3>
                <!-- /ko -->

                <!-- ko if: idConnectionConfig -->
                <h3 class="form-title icon i-config">Edit Connection Config</h3>
                <!-- /ko -->

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
            <div class="w-form-body">
                <div class="form-body full-width">
                    <div class="form-group row">
                        <label for="name" class="form-label">Type</label>
                        <select data-bind="
                                options: connectionTypes,
                                optionsText: 'value',
                                optionsValue: 'value',
                                value: selectedConnectionType,
                                event: { change: onChangeConnectionType() }
                            ">
                        </select>
                    </div> 
                </div> 
                <div class="form-body full-width" data-bind="visible: selectedConnectionType() != ''">
                    <div class="form-group row">
                        <input id="adsDisabled" type="checkbox" data-bind="checked: adsDisabled, attr: {'name': 'adsDisabled' }, event: { change: onChangeAdsDisabled }">
                        <label for="adsDisabled" class="form-label icon i-check">Disable ads</label>
                    </div>                    
                    
                    <div class="form-group row">
                        <input id="prebidDisabled" type="checkbox" data-bind="checked: prebidDisabled, attr: {'name': 'prebidDisabled' }, event: { change: onChangePrebidDisabled }">
                        <label for="prebidDisabled" class="form-label icon i-check">Disable Prebid</label>
                    </div>

                    <div class="form-group row">
                        <label for="failSafeTimeout" class="form-label">Failsafe Timeout(ms)</label>
                        <!-- ko if: showGlobalConfig -->
                        <input data-bind="value: globalFailSafeTimeout" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="failSafeTimeout" data-bind="value: failSafeTimeout, css: { 'read-only': failsafeTimeoutInputDisabled }, disable: failsafeTimeoutInputDisabled" type="text" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>
                    <div class="form-group row">
                        <label for="amazonAuctionTimeout" class="form-label">Amazon Auction Timeout (ms)</label>
                        <!-- ko if: showGlobalConfig -->
                        <input data-bind="value: globalAmazonAuctionTimeout" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="amazonAuctionTimeout" data-bind="value: amazonAuctionTimeout, css: { 'read-only': amazonTimeoutInputDisabled }, disable: amazonTimeoutInputDisabled" type="text" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>   
                    <div class="form-group row">
                        <label for="prebidAuctionTimeout" class="form-label">Prebid Auction Timeout(ms)</label>
                        <!-- ko if: showGlobalConfig -->
                        <input data-bind="value: globalPrebidAuctionTimeout" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="prebidAuctionTimeout" data-bind="value: prebidAuctionTimeout, css: { 'read-only': prebidTimeoutInputDisabled}, disable: prebidTimeoutInputDisabled" type="text" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>     
               </div> 
            </div>

            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "connectionConfig-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;

        return {
            idConnectionConfig : ko.observable(''),
            idBrand: ko.observable(''),
            selectedConnectionType : ko.observable(''),
            adsDisabled : ko.observable(false),
            prebidDisabled : ko.observable(false),
            failSafeTimeout: ko.observable(0),
            prebidAuctionTimeout: ko.observable(0),
            amazonAuctionTimeout: ko.observable(0),
            connectionTypes: ko.observableArray([]),
            showGlobalConfig: ko.observable(false),
            globalFailSafeTimeout: ko.observable(0),
            globalPrebidAuctionTimeout: ko.observable(0),
            globalAmazonAuctionTimeout: ko.observable(0),
            failsafeTimeoutInputDisabled: ko.observable(false),
            prebidTimeoutInputDisabled: ko.observable(false),
            amazonTimeoutInputDisabled: ko.observable(false),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
            onChangeConnectionType: function (data, event) { huit_neuf_this.onChangeConnectionType(); },
            onChangeAdsDisabled: function (data, event) { huit_neuf_this.onChangeAdsDisabled(); },
            onChangePrebidDisabled: function (data, event) { huit_neuf_this.onChangePrebidDisabled(); }
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        let viewModel = this.getTemplateViewModel();

        return {
            idConnectionConfig: viewModel.idConnectionConfig(),
            idBrand: viewModel.idBrand(),
            connectionType: viewModel.selectedConnectionType(),
            adsDisabled: viewModel.adsDisabled(),
            prebidDisabled: viewModel.prebidDisabled(),
            failSafeTimeout: viewModel.failSafeTimeout(),
            prebidAuctionTimeout: viewModel.prebidAuctionTimeout(),
            amazonAuctionTimeout: viewModel.amazonAuctionTimeout()
        };
    }

    public setConnectionConfig(connectionConfig): void{
        this.getTemplateViewModel().idConnectionConfig(connectionConfig.id);
        this.getTemplateViewModel().idBrand(connectionConfig.idBrand);
        this.getTemplateViewModel().selectedConnectionType(connectionConfig.connectionType);
        this.getTemplateViewModel().adsDisabled(connectionConfig.adsDisabled == null ? true : connectionConfig.adsDisabled);
        this.getTemplateViewModel().prebidDisabled(connectionConfig.prebidDisabled == null ? true : connectionConfig.prebidDisabled);
        this.getTemplateViewModel().failSafeTimeout(connectionConfig.failSafeTimeout);
        this.getTemplateViewModel().prebidAuctionTimeout(connectionConfig.prebidAuctionTimeout);
        this.getTemplateViewModel().amazonAuctionTimeout(connectionConfig.amazonAuctionTimeout);

        this.onChangeAdsDisabled();
        this.onChangePrebidDisabled();
    }

    public setGlobalConfig(globalConfig){
        if (globalConfig !== null){
            this.getTemplateViewModel().showGlobalConfig(true);
            this.getTemplateViewModel().globalFailSafeTimeout(globalConfig.failSafeTimeout);
            this.getTemplateViewModel().globalPrebidAuctionTimeout(globalConfig.prebidAuctionTimeout);
            this.getTemplateViewModel().globalAmazonAuctionTimeout(globalConfig.amazonAuctionTimeout);
        } else {
            this.getTemplateViewModel().showGlobalConfig(false);
        }
    }

    public setConnectionTypes(connectionTypes){
        this.getTemplateViewModel().connectionTypes(connectionTypes);
    }

    public setIdBrand(idBrand){
        this.getTemplateViewModel().idBrand(idBrand);
    }


    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        this.caller.saveConnectionConfig();
    }


    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancelConnectionConfig();
    }

    protected onChangeConnectionType(): void{
        this.caller.changeConnectionType(
            this.getTemplateViewModel().selectedConnectionType(),
            this.getTemplateViewModel().idBrand()
        );
    }

    protected onChangeAdsDisabled(): void{
        this.getTemplateViewModel().failsafeTimeoutInputDisabled(this.getTemplateViewModel().adsDisabled());
        this.getTemplateViewModel().amazonTimeoutInputDisabled(this.getTemplateViewModel().adsDisabled());
        this.getTemplateViewModel().prebidTimeoutInputDisabled(this.getTemplateViewModel().adsDisabled() || this.getTemplateViewModel().prebidDisabled());
    }

    protected onChangePrebidDisabled(): void{
        this.getTemplateViewModel().prebidTimeoutInputDisabled(this.getTemplateViewModel().adsDisabled() || this.getTemplateViewModel().prebidDisabled());
    }

}
