import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class gamAccountEdit_template extends vnTemplate{

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
        <div class="w-form" style="width:90rem;">
            <div class="form-header">
                <!-- ko ifnot: idGamAccount -->
                <h3 class="form-title icon i-add">Add New GAM Account</h3>
                <!-- /ko -->

                <!-- ko if: idGamAccount -->
                <h3 class="form-title icon i-content">Edit GAM Account</h3>
                <!-- /ko -->

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>

            <div class="form-body">
                <div class="form-group" style="margin-top: 1.6rem;">
                    <legend class="form-legend">active</legend>
                    <div class="form-check-inline width-auto">
                        <input type="radio" name="isActive" id="active_yes" data-bind="value:true, checked: isActive" />
                        <label class="form-label" for="active_yes">Yes</label>
                    </div>
                    <div class="form-check-inline width-auto">
                        <input type="radio" name="isActive" id="active_no" data-bind="value:false, checked: isActive" />
                        <label class="form-label" for="active_no">No</label>
                    </div>
                </div>  

                <div class="form-group row">
                    <label for="name" class="form-label">name</label>
                    <input id="name" data-bind="value: name" type="text" class="input-text">
                </div>
                
                <div class="form-group row">
                    <label for="networkCode" class="form-label">network Code</label>
                    <input id="networkCode" data-bind="value: networkCode" type="text" class="input-text">
                </div>                   
                <div class="form-group row">
                    <label for="jsonPath" class="form-label">Json path to credentials</label>
                    <input id="jsonPath" data-bind="value: jsonPath" type="text" class="input-text">
                </div>                   
                <div class="form-group row">
                    <label for="sourceKeyId" class="form-label">SOURCE dimension key</label>
                    <input id="sourceKeyId" data-bind="value: sourceKeyId" type="text" class="input-text">
                </div>                   
                <div class="form-group row">
                    <label for="templateKeyId" class="form-label">TEMPLATE dimension key</label>
                    <input id="templateKeyId" data-bind="value: templateKeyId" type="text" class="input-text">
                </div>                    
                <div class="form-group row">
                    <label for="intentKeyId" class="form-label">INTENT dimension key</label>
                    <input id="intentKeyId" data-bind="value: intentKeyId" type="text" class="input-text">
                </div>                   
                <div class="form-group row">
                    <label for="siteKeyId" class="form-label">SITE dimension key</label>
                    <input id="siteKeyId" data-bind="value: siteKeyId" type="text" class="input-text">
                </div>                   
            </div>   

            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save Gam Account</span></button>  
            </div>
        </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "gamAccountEdit-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{
        let huit_neuf_this = this;

        return {
            idGamAccount: ko.observable(''),
            isActive: ko.observable(true),
            name: ko.observable(''),
            networkCode: ko.observable(''),
            jsonPath: ko.observable(''),
            sourceKeyId: ko.observable(''),
            templateKeyId: ko.observable(''),
            intentKeyId: ko.observable(''),
            siteKeyId: ko.observable(''),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        let viewModel = this.getTemplateViewModel()
        return {
            idGamAccount: viewModel.idGamAccount(),
            isActive: viewModel.isActive(),
            name: viewModel.name(),
            networkCode: viewModel.networkCode(),
            jsonPath: viewModel.jsonPath(),
            sourceKeyId: viewModel.sourceKeyId(),
            templateKeyId: viewModel.templateKeyId(),
            intentKeyId: viewModel.intentKeyId(),
            siteKeyId: viewModel.siteKeyId(),
        };
    }

    public setGamAccount(gamAccount): void{
        this.getTemplateViewModel().idGamAccount(gamAccount.id);
        this.getTemplateViewModel().isActive(gamAccount.isActive);
        this.getTemplateViewModel().name(gamAccount.name);
        this.getTemplateViewModel().networkCode(gamAccount.networkCode);
        this.getTemplateViewModel().jsonPath(gamAccount.jsonPath);
        this.getTemplateViewModel().sourceKeyId(gamAccount.sourceKeyId);
        this.getTemplateViewModel().templateKeyId(gamAccount.templateKeyId);
        this.getTemplateViewModel().intentKeyId(gamAccount.intentKeyId);
        this.getTemplateViewModel().siteKeyId(gamAccount.siteKeyId);
    }

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{
        this.caller.saveGamAccount();
    }

    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{
        this.caller.cancelEditGamAccount();
    }    
}
