import { vnTemplate } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate';
import { filterSimple_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterSimple_template";
import { multipleSelector_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/multipleSelector_template";
import { pagination_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/pagination_template';
import { resultsInfo_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/resultsInfo_template';

declare var ko: any;

export class adUnitList_template extends vnTemplate {

    protected searchFilter: filterSimple_template;
    protected dimensionFilter: multipleSelector_template;
    protected typeFilter: multipleSelector_template;
    protected templateFilter: multipleSelector_template;
    protected statusFilter: multipleSelector_template;
    protected adSetupFilter: multipleSelector_template;
    protected zoneFilter: multipleSelector_template;
    protected bidderTypeFilter: multipleSelector_template;
    protected pagination: pagination_template;
    protected resultsInfo: resultsInfo_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="w-page-tab">
                <div class="page-tab">
                    <button data-bind="click: onClickRedirect"  class="btn large icon i-settings" type="button">Ad Setups</button>
                    <button class="btn large icon i-unit is-selected " type="button">Ad Units</button>
                </div>
            </div>
            <div class="sub-header">
                <div class="w-justify">
                    <div class="w-left">
                        <div>
                            <label class="form-label form-label-box multi">Dimension</label>
                            ${this.dimensionFilter.getHtmlBuild()}
                        </div> 
                        <div>
                            <label class="form-label form-label-box multi">Type</label>
                            ${this.typeFilter.getHtmlBuild()}
                        </div>
                        <div>
                            <label class="form-label form-label-box multi">Template</label>
                            ${this.templateFilter.getHtmlBuild()}
                        </div>
                        <div>
                            <label class="form-label form-label-box multi">Status</label>
                            ${this.statusFilter.getHtmlBuild()}
                        </div>
                        <div>
                            <label class="form-label form-label-box multi">Ad Setup</label>
                            ${this.adSetupFilter.getHtmlBuild()}
                        </div>
                        <div>
                            <label class="form-label form-label-box multi">Zone</label>
                            ${this.zoneFilter.getHtmlBuild()}
                        </div>
                        <div>
                            <label class="form-label form-label-box multi">Bidder Type</label>
                            ${this.bidderTypeFilter.getHtmlBuild()}
                        </div>
                    </div>
                </div>
            </div>      
            <div class="w-filters-selected">
                <div id="filters-selected" class="filters-selected">
                    <div id="selected-dimensions"></div>
                    <div id="selected-types"></div>
                    <div id="selected-templates"></div>
                    <div id="selected-statuses"></div>
                    <div id="selected-adSetups"></div>
                    <div id="selected-zones"></div>
                    <div id="selected-bidderTypes"></div>
                </div>
            </div>      
            <div class="list-header w-justify" >
                <div class="w-left">
                    <strong class="section-title icon i-unit">Ads Units</strong>
                </div>
                <div class="w-center">
                    ${this.searchFilter.getHtmlBuild()}
                </div>
                <div class="w-right">
                    ${this.pagination.getHtmlBuild()}
                </div>
            </div>
            <div class="w-list-results" id="list-results">
                <table class="list-results">
                    ${this.resultsInfo.getHtmlBuild()}
                    <thead> 
                        <tr>
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'name') }, css: { 'list-sort-desc': orderByAndDirection()=='name_DESC', 'list-sort-asc': orderByAndDirection()=='name_ASC' }">Name</span></th>  
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'divId') }, css: { 'list-sort-desc': orderByAndDirection()=='divId_DESC', 'list-sort-asc': orderByAndDirection()=='divId_ASC' }">Div Id</span></th>                       
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'nameAdsSetup') }, css: { 'list-sort-desc': orderByAndDirection()=='nameAdsSetup_DESC', 'list-sort-asc': orderByAndDirection()=='nameAdsSetup_ASC' }">Ad Setup</span></th>
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'nameZone') }, css: { 'list-sort-desc': orderByAndDirection()=='nameZone_DESC', 'list-sort-asc': orderByAndDirection()=='nameZone_ASC' }">Zone</span></th>
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'status') }, css: { 'list-sort-desc': orderByAndDirection()=='status_DESC', 'list-sort-asc': orderByAndDirection()=='status_ASC' }">Status</span></th>
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'nameDimension') }, css: { 'list-sort-desc': orderByAndDirection()=='nameDimension_DESC', 'list-sort-asc': orderByAndDirection()=='nameDimension_ASC' }">Dimension</span></th>
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'nameAdType') }, css: { 'list-sort-desc': orderByAndDirection()=='nameAdType_DESC', 'list-sort-asc': orderByAndDirection()=='nameAdType_ASC' }">Type</span></th>
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'nameAdTemplate') }, css: { 'list-sort-desc': orderByAndDirection()=='nameAdTemplate_DESC', 'list-sort-asc': orderByAndDirection()=='nameAdTemplate_ASC' }">Template</span></th>
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'isDynamicallyInjected') }, css: { 'list-sort-desc': orderByAndDirection()=='isDynamicallyInjected_DESC', 'list-sort-asc': orderByAndDirection()=='isDynamicallyInjected_ASC' }">Dynamic Injection</span></th>
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'isConnectedBelowAd') }, css: { 'list-sort-desc': orderByAndDirection()=='isConnectedBelowAd_DESC', 'list-sort-asc': orderByAndDirection()=='isConnectedBelowAd_ASC' }">Connected Below Ad</span></th>
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'hasFloatingOptions') }, css: { 'list-sort-desc': orderByAndDirection()=='hasFloatingOptions_DESC', 'list-sort-asc': orderByAndDirection()=='hasFloatingOptions_ASC' }">Floating</span></th>
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'hasRefreshOptions') }, css: { 'list-sort-desc': orderByAndDirection()=='hasRefreshOptions_DESC', 'list-sort-asc': orderByAndDirection()=='hasRefreshOptions_ASC' }">Refresh</span></th>
                            <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'isRepeatable') }, css: { 'list-sort-desc': orderByAndDirection()=='isRepeatable_DESC', 'list-sort-asc': orderByAndDirection()=='isRepeatable_ASC' }">Repeatable</span></th>
                       </tr> 
                    </thead>
                    <tbody data-bind="foreach: listData">  
                        <tr data-bind="cursor">
                            <td class=""><span data-bind="text: name"></span></td>
                            <td class=""><span data-bind="text: divId"></span></td>
                            <td class=""><a target="_blank" data-bind="attr: { href: $parent.generateUrl($data) }"><span class="icon i-link" data-bind="text: nameAdsSetup"></span></a></td>
                            <td class=""><span data-bind="text: nameZone"></span></td>
                            <td class="" style="text-transform: capitalize;"><span data-bind="text: status"></span></td>
                            <td class=""><span data-bind="text: nameDimension"></span></td>
                            <td class=""><span data-bind="text: nameAdType"></span></td>
                            <td class=""><span data-bind="text: nameAdTemplate"></span></td>
                            <td class="" style="text-transform: capitalize;"><span data-bind="text: $parent.getBooleanRepresentation(isDynamicallyInjected)"></span></td>
                            <td class="" style="text-transform: capitalize;"><span data-bind="text: $parent.getBooleanRepresentation(isConnectedBelowAd)"></span></td>
                            <td class="" style="text-transform: capitalize;"><span data-bind="text: $parent.getBooleanRepresentation(hasFloatingOptions)"></span></td>
                            <td class="" style="text-transform: capitalize;"><span data-bind="text: $parent.getBooleanRepresentation(hasRefreshOptions)"></span></td>
                            <td class="" style="text-transform: capitalize;"><span data-bind="text: $parent.getBooleanRepresentation(isRepeatable)"></span></td>
                        </tr>      
                    </tbody>
                </table>    
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{

        this.searchFilter = new filterSimple_template('searchFilter', this.getTemplateViewModel(), this.caller);
        this.resultsInfo  = new resultsInfo_template('resultsInfo', this.getTemplateViewModel(), this.caller);

        this.dimensionFilter = new multipleSelector_template("dimensionFilter", this.getTemplateViewModel(), this.caller);
        this.dimensionFilter.setFilterContainerClass('margin-neg');
        this.dimensionFilter.setTagContainerId('selected-dimensions');
        this.dimensionFilter.setTagContainerCaption('Selected Dimensions:');

        this.typeFilter = new multipleSelector_template("typeFilter", this.getTemplateViewModel(), this.caller);
        this.typeFilter.setFilterContainerClass('margin-neg');
        this.typeFilter.setTagContainerId('selected-types');
        this.typeFilter.setTagContainerCaption('Selected Types:');

        this.templateFilter = new multipleSelector_template("templateFilter", this.getTemplateViewModel(), this.caller);
        this.templateFilter.setFilterContainerClass('margin-neg');
        this.templateFilter.setTagContainerId('selected-templates');
        this.templateFilter.setTagContainerCaption('Selected Templates:');

        this.statusFilter = new multipleSelector_template("statusFilter", this.getTemplateViewModel(), this.caller);
        this.statusFilter.setFilterContainerClass('margin-neg');
        this.statusFilter.setTagContainerId('selected-statuses');
        this.statusFilter.setTagContainerCaption('Selected Statuses:');

        this.adSetupFilter = new multipleSelector_template("adSetupFilter", this.getTemplateViewModel(), this.caller);
        this.adSetupFilter.setFilterContainerClass('margin-neg');
        this.adSetupFilter.setTagContainerId('selected-adSetups');
        this.adSetupFilter.setTagContainerCaption('Selected Ad Setups:');

        this.zoneFilter = new multipleSelector_template("zoneFilter", this.getTemplateViewModel(), this.caller);
        this.zoneFilter.setFilterContainerClass('margin-neg');
        this.zoneFilter.setTagContainerId('selected-zones');
        this.zoneFilter.setTagContainerCaption('Selected Zones:');

        this.bidderTypeFilter = new multipleSelector_template("bidderTypeFilter", this.getTemplateViewModel(), this.caller);
        this.bidderTypeFilter.setFilterContainerClass('margin-neg');
        this.bidderTypeFilter.setTagContainerId('selected-bidderTypes');
        this.bidderTypeFilter.setTagContainerCaption('Selected Bidder Types:');

        this.pagination = new pagination_template('pagination', this.getTemplateViewModel(), this.caller);
        this.pagination.getTemplateViewModel().itemPerPage = 25;
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return 'adUnitList_template';
    }

    /**
     * @inheritDoc
     */
    protected getMainElementType(): string{

        return "div";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-content"];
    }

    /**
     * @inheritDoc
     */
    public buildViewModel(): Object{

        let self = this;

        return {
            orderBy: ko.observable('name'),
            orderByDirection: ko.observable('ASC'),
            orderByAndDirection: ko.observable('name_ASC'),
            listData: ko.observableArray([]),
            onClickReorder: function(value, data, event){ self.onClickReorder(value, data, event); },
            onClickRedirect: function(data, event){ self.caller.goToAdSetupsList(); },
            generateUrl: function(data){ return self.generateUrl(data); },
            getBooleanRepresentation: function(value){ return self.getBooleanRepresentation(value); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            pagination: this.pagination.getTemplateViewModel().pageNumber(),
            orderBy: this.getTemplateViewModel().orderBy(),
            orderByDirection: this.getTemplateViewModel().orderByDirection(),
            filters: {
                searchString: this.searchFilter.getTemplateViewModel().filterKeyword(),
                selectedDimensions: this.dimensionFilter.getTemplateViewModel().selectedItems(),
                selectedTypes: this.typeFilter.getTemplateViewModel().selectedItems(),
                selectedTemplates: this.templateFilter.getTemplateViewModel().selectedItems(),
                selectedStatuses: this.statusFilter.getTemplateViewModel().selectedItems(),
                selectedAdSetups: this.adSetupFilter.getTemplateViewModel().selectedItems(),
                selectedZones: this.zoneFilter.getTemplateViewModel().selectedItems(),
                selectedBidderTypes: this.bidderTypeFilter.getTemplateViewModel().selectedItems(),
            },
        };
    }

    /**
     * Handles Reordering table.
     *
     * @param value
     * @param data
     * @param event
     */
    public onClickReorder(value, data, event){

        data.orderBy(value);

        // If column has already been sorted, flip the direction.
        if(data.orderByAndDirection().includes(value)){

            if(data.orderByDirection() == "DESC"){

                data.orderByDirection("ASC");
            }
            else{

                data.orderByDirection("DESC");
            }
        }
        else{

            //default column sort direction
            data.orderByDirection("DESC");
        }

        data.orderByAndDirection(value + "_" + data.orderByDirection());
        this.caller.dataReorder();
    }

    /**
     * Sets users.
     *
     * @param listData
     */
    public setListData(listData): void{

        this.getTemplateViewModel().listData(listData);
    }

    /**
     * Sets filter items.
     *
     * @param data
     */
    public setDimensionFilterItems(data): void{

        this.setMultiSelectFilter(this.dimensionFilter, data);
    }

    /**
     * Sets filter items.
     *
     * @param data
     */
    public setTypeFilterItems(data): void{

        data.unshift({'id' : 0, 'name' : 'other'});
        this.setMultiSelectFilter(this.typeFilter, data);
    }

    /**
     * Sets filter items.
     *
     * @param data
     */
    public setTemplateFilterItems(data): void{

        this.setMultiSelectFilter(this.templateFilter, data);
    }

    /**
     * Sets filter items.
     *
     * @param data
     */
    public setStatusFilterItems(data): void{

        this.setMultiSelectFilter(this.statusFilter, data);
    }

    /**
     * Sets filter items.
     *
     * @param data
     */
    public setAdSetupFilterItems(data): void{

        this.setMultiSelectFilter(this.adSetupFilter, data);
    }

    /**
     * Sets filter items.
     *
     * @param data
     */
    public setBidderTypeFilterItems(data): void{

        this.setMultiSelectFilter(this.bidderTypeFilter, data);
    }

    /**
     * Sets filter items.f
     *
     * @param data
     */
    public setZoneFilterItems(data): void{

        let zonesByGroup = {};
        let groupedItems = [];

        for(let zone of data){

            if(!zonesByGroup.hasOwnProperty(zone.groupName)){

                zonesByGroup[zone.groupName] = [];
            }

            zonesByGroup[zone.groupName].push({'id': zone.id, 'name': zone.name});
        }

        for(let key in zonesByGroup){

            groupedItems.push({'name' : key, 'items': zonesByGroup[key]});
        }

        this.zoneFilter.setMultiSectionItems(groupedItems);
    }

    /**
     * Sets data for the result info template.
     *
     * @param nbResults
     * @param nbPages
     * @param execTime
     */
    public setResultInfo(nbResults, nbPages, execTime){

        this.resultsInfo.setExecutionTime(execTime);
        this.resultsInfo.setNbResults(nbResults);
        this.resultsInfo.setNbPages(nbPages);
    }

    /**
     * Sets data for a multi select filter.
     *
     * @param filter
     * @param {Array<{ id: number, name: string }>} data
     */
    public setMultiSelectFilter(filter, data: Array<{ id: string, name: string }>): void{

        filter.setItems(data);
    }

    //
    // Generate the URL to ad setup.
    private generateUrl(data): string{

        return `/brand/${data.idBrand}/ads_setups/${data.idAdsSetup}/adunits`;
    }

    private getBooleanRepresentation(value){

        return (value) ? 'yes' : 'no';
    }
}