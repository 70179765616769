import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import {header} from "./header";
import {adsSetupTagList_template} from "../components/templates/adsSetupTagList_template";

declare var ko: any;
declare var page: any;

export class adsSetupTag_list extends vnModule_knockoutJS{

    protected template: adsSetupTagList_template;
    protected requestHandler: XMLHttpRequestHandler;

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'adsSetupTag_list';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName('Header');
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle('Ads Setup Tag List');

        this.template = new adsSetupTagList_template('adsSetupTagList_template', this.viewModel, this)

        return [this.template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getListData();
    }

    //
    // Hook.
    // Initiates data refresh.
    public paginationOnPageClick(): void{

        this.getListData();
    }

    /**
     * @inheritDoc
     */
    protected reorderList(): void{

        this.getListData();
    }

    //
    // Fetches the list data.
    public getListData(): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem('list-results'));

        let subViewModel = this.template.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(['data', ko.toJSON(subViewModel)] );

        this.requestHandler = new XMLHttpRequestHandler('/api/ads_setup_tag/get/', usrReq_params, this);
        this.requestHandler.onReadyStateFunction = this.onDataRequestReturn;
        this.requestHandler.execute();
    }

    //
    // Returns handler for data request.
    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateList(responseParsed);
                obj.setResultInfo(responseParsed.totalCount, responseParsed.totalPage);
                obj.updatePagination(responseParsed.totalCount);
                obj.loadingOut(obj.template.builtTemplate.children.namedItem('list-results'));
            });
        }
    }

    //
    // Update the module list using the objects list.
    protected updateList(responseParsed: any): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem('list-results'));


        this.template.setData(responseParsed.data);
    }

    //
    // Hook.
    // Redirects to create user page.
    protected add(): void{

        page(`/ads_setup_tag/add`);
    }

    // Hook.
    // Redirects to create user page.
    protected edit(id): void{

        page(`/ads_setup_tag/edit/${id}`);
    }

    //
    // Deletes the requested resource.
    protected delete(id): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem('list-results'));

        this.requestHandler                      = new XMLHttpRequestHandler(`/api/ads_setup_tag/delete/${id}`, [], this);
        this.requestHandler.mode                 = XMLHttpRequestHandler_requestType.POST;
        this.requestHandler.onReadyStateFunction = this.onDeleteRequestReturn;
        this.requestHandler.execute();
    }

    //
    // Returns handler for delete request.
    protected onDeleteRequestReturn(req, obj): Function{

        return function(){

            obj.loadingOut(obj.template.builtTemplate.children.namedItem('list-results'));

            obj.manageResponse(req, obj, function(){

                obj.getListData();
            });
        }
    }

    protected setResultInfo(totalCount, totalPage){
        let execTime = Math.round((new Date().getTime() - this.requestHandler.requestStartTime)/1000*100)/100;
        this.template.setResultInfo(totalCount, totalPage, execTime);
    }

    protected updatePagination(totalCount) {

        this.template.updatePagination(totalCount);

    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.delete(data);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

    }

    //
    // Hook.
    // Cancels data request.
    protected filterSimpleCancel(){

        if(this.requestHandler){

            this.requestHandler.cancelAndReset();
        }
    }

    //
    // Hook.
    // Initiates data refresh.
    protected filterSimpleOnChange(){

        this.template.resetPageNumber();
        this.template.resetOrderParameters();
        this.getListData();
    }
}