import { vnTemplate } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate';
import { pagination_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/pagination_template';
import { resultsInfo_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/resultsInfo_template';
import { filterSimple_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterSimple_template";
import {vnApp_ads_ninja} from "../vnApp_ads_ninja";

declare var ko: any;

export class sspList_template extends vnTemplate {

    protected searchFilter: filterSimple_template;
    protected pagination: pagination_template;
    protected resultsInfo: resultsInfo_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
        <div class="list-header w-justify margin-top"> 
            <div class="w-left" data-bind="if: hasWriteAccess">
                <button data-bind="click: onClickAdd" class="btn icon i-add" type="button">Add SSP</button>
            </div>    
            <div class="w-center">
                ${this.searchFilter.getHtmlBuild()}
            </div>         
            <div class="w-right">
                ${this.pagination.getHtmlBuild()}          
            </div>
        </div>
        <div class="w-list-results" id="list-results">
            <table class="list-results">
                ${this.resultsInfo.getHtmlBuild()}
                <thead> 
                    <tr>    
                        <th class="w-btn" data-bind="visible: hasUpdateAccess"><span>edit</span></th>
                        <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'name') }, css: { 'list-sort-desc': orderByAndDirection()=='name_DESC', 'list-sort-asc': orderByAndDirection()=='name_ASC' }">name</span></th> 
                        <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'alias') }, css: { 'list-sort-desc': orderByAndDirection()=='alias_DESC', 'list-sort-asc': orderByAndDirection()=='alias_ASC' }">alias</span></th>
                        <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'nameBiddingStyle') }, css: { 'list-sort-desc': orderByAndDirection()=='nameBiddingStyle_DESC', 'list-sort-asc': orderByAndDirection()=='nameBiddingStyle_ASC' }">bidding Style</span></th> 
                        <th><span class="icon list-sort-none" data-bind="event: { click: onClickReorder.bind($data, 'nameSspBidAdapter') }, css: { 'list-sort-desc': orderByAndDirection()=='nameSspBidAdapter_DESC', 'list-sort-asc': orderByAndDirection()=='nameSspBidAdapter_ASC' }">SSP bid adapter</span></th> 
                        <th class="w-btn" data-bind="visible: hasWriteAccess"><span>copy</span></th>
                        <th class="w-btn" data-bind="visible: hasDeleteAccess"><span>Delete</span></th>
                   </tr> 
                </thead>
                <tbody data-bind="foreach: ssps">  
                    <tr>
                        <td class="" data-bind="visible: $parent.hasUpdateAccess"><button data-bind="visible: $parent.hasUpdateAccess, event: { click: $parent.onClickEdit }" class="btn icon-only icon i-edit" type="button"><span class="hidden-text">Edit</span></button></td>
                        <td class=""><span data-bind="text: name"></span></td>
                        <td class=""><span data-bind="text: $parent.processAlias(alias)"></span></td>
                        <td class=""><span data-bind="text: nameBiddingStyle"></span></td>
                        <td class=""><span data-bind="text: nameSspBidAdapter"></span></td>
                        <td class="align-right" data-bind="visible: $parent.hasWriteAccess"><button data-bind="visible: $parent.hasWriteAccess, event: { click: $parent.onClickCopy }" class="btn icon-only icon i-copy" type="button"><span class="hidden-text">Copy</span></button></td>
                        <td class="align-right" data-bind="visible: $parent.hasDeleteAccess"><button data-bind="visible: $parent.hasDeleteAccess, event: { click: $parent.onClickDelete }" class="btn icon-only icon i-delete" type="button"><span class="hidden-text">Delete</span></button></td>
                    </tr>      
                </tbody>
            </table>
        </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial(): void{

        this.searchFilter = new filterSimple_template('searchFilter', this.getTemplateViewModel(), this.caller);
        this.resultsInfo  = new resultsInfo_template('resultsInfo', this.getTemplateViewModel(), this.caller);

        this.pagination = new pagination_template('pagination', this.getTemplateViewModel(), this.caller);
        this.pagination.getTemplateViewModel().itemPerPage = 25;
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return 'sspsList_template';
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return [];
    }

    /**
     * @inheritDoc
     */
    public activateLoading(): void{

        this.getTemplateViewModel().isLoading(1);
    }

    /**
     * @inheritDoc
     */
    public deactivateLoading(): void{

        this.getTemplateViewModel().isLoading(0);
    }

    /**
     * @inheritDoc
     */
    public buildViewModel(): Object{

        let self = this;

        return {
            idBrand: ko.observable(0),
            orderBy: ko.observable(''),
            orderByDirection: ko.observable(''),
            orderByAndDirection: ko.observable(''),
            ssps: ko.observableArray([]),
            hasUpdateAccess: ko.observable(this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SSP, vnApp_ads_ninja.ACCESS_TYPE_UPDATE)),
            hasDeleteAccess: ko.observable(this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SSP, vnApp_ads_ninja.ACCESS_TYPE_DELETE)),
            hasWriteAccess: ko.observable(this.caller.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_SSP, vnApp_ads_ninja.ACCESS_TYPE_WRITE)),
            onClickReorder: function(value, data, event){ self.onClickReorder(value, data, event); },
            onClickEdit: function(data, event){ self.onClickEdit(data.id); },
            onClickCopy: function(data, event){ self.onClickCopy(data.id); },
            onClickAdd: function(){ self.onClickAdd(); },
            onClickDelete: function(data){ self.onClickDelete(data); },
            processAlias: function(value){ return (!value) ? 'none' : value },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            pagination: this.pagination.getTemplateViewModel().pageNumber(),
            orderBy: this.getTemplateViewModel().orderBy(),
            orderByDirection: this.getTemplateViewModel().orderByDirection(),
            filters: {
                searchString: this.searchFilter.getTemplateViewModel().filterKeyword(),
            },
        };
    }

    /**
     * Handles reordering on click event.
     *
     * @param value
     * @param data
     * @param event
     */
    public onClickReorder(value, data, event): void{

        this.getTemplateViewModel().orderBy(value);

        if( this.getTemplateViewModel().orderByDirection() == 'DESC' ){

            this.getTemplateViewModel().orderByDirection('ASC');
        }
        else{

            this.getTemplateViewModel().orderByDirection('DESC');
        }

        this.getTemplateViewModel().orderByAndDirection(value + '_' + this.getTemplateViewModel().orderByDirection());
        this.caller.reorderSsps();
    }

    public setPagination(totalCount: number): void{

        this.pagination.setNumberItemToPaginate(totalCount);
    }


    public resetPageNumber(): void{

        this.getTemplateViewModel().pagination.pageNumber(1);
    }

    public resetOrderParameters(): void{

        this.getTemplateViewModel().orderBy('');
        this.getTemplateViewModel().orderByDirection('');
        this.getTemplateViewModel().orderByAndDirection('');
    }

    /**
     * Sets ssps.
     *
     * @param {Array} ssps
     */
    public setSsps(ssps: Array<any>): void{

        this.getTemplateViewModel().ssps(ssps);
    }

    public setIdBrand(value: Number): void{

        this.getTemplateViewModel().idBrand(value);
    }

    public setResultInfo(nbResults, nbPages, execTime){

        this.resultsInfo.setExecutionTime(execTime);
        this.resultsInfo.setNbResults(nbResults);
        this.resultsInfo.setNbPages(nbPages);
    }

    public updatePagination(totalCount){
        this.pagination.setNumberItemToPaginate(totalCount);
    }

    /**
     * Handles the add user action.
     */
    public onClickAdd(){

        this.caller.addSsp();
    }

    /**
     * Handles on edit button click event.
     *
     * @param idSsp
     */
    public onClickEdit(idSsp){

        this.caller.editSsp(idSsp);
    }

    //
    // Handles on copy button click event.
    public onClickCopy(id){

        this.caller.copy(id);
    }

    /**
     * Handles on delete button click event.
     *
     * @param data
     */
    public onClickDelete(data){

        this.caller.confirmationModal.setTitle('Confirm Deletion');
        this.caller.confirmationModal.setContext('delete');
        this.caller.confirmationModal.setMessageMain('Are you sure you would like to delete this SSP?');
        this.caller.confirmationModal.setDataConfirm(data.id);
        this.caller.confirmationModal.setMessageSecondary(data.name);
        this.caller.confirmationModal.show();
    }
}