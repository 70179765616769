import {vnTemplate} from "./vnTemplate";
import {infoButton_template} from "./infoButton_template";


declare var ko: any;
declare var page: any;

export class rolesView_template extends vnTemplate {
    
    protected getTemplateHtml(): string {

        return `
        
            <div class="form-header" data-bind="with: role">
                <input type="hidden">
                <button class="btn icon i-back" data-bind="click: $parent.onClickBackToList" type="button">back to role list</button>
                <h3 class="form-title icon i-role" data-bind="text: name"></h3>
                <button class="btn icon i-edit" type="button" data-bind="click: $parent.onClickEdit">Edit Role</button>
            </div>
            
            <div id="view-results">
            
                 <div data-bind="with: role">     
                    <div class="w-form-body">
                
                        <div class="form-body">
         
                            <div class="form-group">
                                 <dl class="double-list">
                
                                    <dt>
                                        <span>Id</span>
                                    </dt>
                                    <dd>
                                        <span data-bind="text: id"></span>
                                    </dd>
                                     <dt>
                                        <span>Name</span>
                                    </dt>
                                    <dd>
                                        <span data-bind="text: name"></span>
                                    </dd>
                                </dl>
                            </div>
                              
                         </div>
                         <div class="form-body">               
                            <div class="form-group">
                                <dl class="double-list">              
                                    <dt>
                                        <span>Sequence</span>
                                    </dt>
                                    <dd>
                                        <span data-bind="text: sequence"></span>
                                    </dd>
                                    <dt class="full-width">
                                        <span>Description</span>
                                    </dt>
                                    <dd class="full-width">
                                        <span data-bind="text: description"></span>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                    
                    <div class="w-form-body"> 
                        <div class="form-body full-width"> 
                        <table class="list-action">
                            <thead>
                                <tr>
                                    <th><span>sections</span></th>
                                    <th class="align-center"><span class="icon i-edit">write</span></th>
                                    <th class="align-center"><span class="icon i-view">read</span></th>
                                    <th class="align-center"><span class="icon i-update">update</span></th>
                                    <th class="align-center"><span class="icon i-delete">delete</span></th>
                                </tr>
                            </thead>
                            <tbody data-bind="foreach: sections">
                                <tr>
                                    <td>
                                        <!-- ko if: $data.infoButton -->
                                            <div class="w-tooltips-adv short pos-right" data-bind="template: { name: 'infoButton-template', data: $data.infoButton.getTemplateViewModel() }"></div>
                                        <!-- /ko -->
                                        <h3><span data-bind="text: section.name"></span></h3>
                                    </td>
                                    <!-- ko foreach: permissions -->
                                        <td class="align-center">
                                            <div class="form-group">                       
                                                <input type="checkbox" class="read-only" data-bind="attr: {id:$parent.section.name + '-' + name}, value: short_name, checked: $parent.access_arr" >
                                                <label class="form-label icon i-check"  data-bind="text: name, attr: {for: $parent.section.name + '-' + name}"></label>
                                            </div>   
                                        </td>
                                    <!-- /ko -->
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    protected initPartial() {
     }

    /**
     *
     * @protected
     */
    protected getTemplateName(): string {
        return "rolesView_template";
    }

    protected getMainElementClasses(): Array<string> {

        return ["w-form", "w-form-large", "margin-top", "roles-view"];
    }

    protected buildViewModel(): any {

        let myself = this;
        return {
            idRole: ko.observable(""),
            role: ko.observable(),
            permissions: ko.observableArray([{'name' : 'write', 'short_name' : 'w'}, {'name' : 'read', 'short_name' : 'r'}, {'name' : 'update', 'short_name' : 'u'}, {'name' : 'delete', 'short_name' : 'd'}]),
            onClickBackToList: function () {
                myself.onClickBackToList();
            },
            onClickEdit: function () {
                myself.onClickEdit();
            }
        };
    }

    // implementation of base class [vnTemplate]
    public getViewModelForRequest(): Object {

        return {
            idRole : this.getTemplateViewModel().idRole
        }
    }

    protected onClickBackToList(): void {
        this.caller.backToList();
    }

    protected onClickEdit(): void {
        page('/roles/edit/' + this.getTemplateViewModel().idRole());
    }


    public setRole(role: {id: number, name: string, description: string, sequence: number, sections: Array<{id: number, name: string, idSection: number, access: string, access_arr: Array<string>, section: {id: number, name: string}}>}): void {

        let associatedSectionsObs = role.sections;

        for(let assocSec of associatedSectionsObs){

            assocSec['permissions'] = this.getTemplateViewModel().permissions();

            let description = assocSec['section']['description'] || '';

            if(description != ''){
                assocSec['infoButton'] = new infoButton_template(`infoButton_${assocSec['id']}`, this.getTemplateViewModel(), this.caller);
                assocSec['infoButton'].setTitle('Help: Permission');

                assocSec['infoButton'].setContent(`<p>${description}</p>`);
            }
        }

        role.sections.sort((a, b) => (a.section.name > b.section.name) ? 1 : -1)

        this.getTemplateViewModel().role(role);
    }

    public getRole() {
        return this.getTemplateViewModel().role();
    }

    protected onUpdateError(status, data): void {
        console.log('onUpdateError' + status);
    }
}
