import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";
import {filterDropdown_template} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDropdown_template";

declare var ko: any;

export class adZoneEdit_template extends vnTemplate{

    protected typeFilter: filterDropdown_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <!-- ko ifnot: id -->
                <h3 class="form-title icon i-add">Add New Zone</h3>
                <!-- /ko -->

                <!-- ko if: id -->
                <h3 class="form-title icon i-ad_template_2">Edit Zone</h3>
                <!-- /ko -->

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>

            <div class="form-body">
                <div class="form-group row">
                    <label for="name" class="form-label">name</label>
                    <input id="name" data-bind="value: name" type="text" class="input-text">
                </div>
                <div class="form-group row">
                    <label class="form-label">Group</label>
                    <select data-bind="
                    options: groups,
                    optionsText: 'name',
                    optionsValue: 'id',
                    value: groupName,
                    attr: {'id' : 'groups', 'name' : 'groups' }
                    ">
                    </select>
                </div>
                <div class="form-group row">
                    <label class="form-label">Type</label>
                    ${this.typeFilter.getHtmlBuild()}
                </div>
            </div>   
          
            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

        this.typeFilter = new filterDropdown_template('typeFilter', this.getTemplateViewModel(), this.caller);
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "adZoneEdit_template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let self = this;

        return {
            id: ko.observable(''),
            name: ko.observable(''),
            groupName: ko.observable(''),
            groups: ko.observableArray([]),
            zones: ko.observableArray([]),
            showWarning: ko.observable(false),
            onClickSave: function(data, event){ self.onClickSave(); },
            onClickCancel: function(data, event){ self.onClickCancel(); }
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            id: this.getTemplateViewModel().id(),
            name: this.getTemplateViewModel().name(),
            groupName: this.getTemplateViewModel().groupName(),
            type: this.typeFilter.getTemplateViewModel().selectedOption(),
        };
    }

    //
    // Sets form data.
    public setFormData(data): void{

        this.getTemplateViewModel().id(data.id);
        this.getTemplateViewModel().name(data.name);
        this.getTemplateViewModel().groupName(data.groupName);
        this.typeFilter.setSelectedOption(data.type);
    }

    //
    // Handles clicking save button event.
    protected onClickSave(): void{

        if(this.getTemplateViewModel().showWarning()){

            this.caller.confirmationModal.setContext('saveModal');
            this.caller.confirmationModal.setTitle('Updating Zone Type');
            this.caller.confirmationModal.setDataConfirm(null);
            this.caller.confirmationModal.setMessageMain('Changing the type of this "repeatable" zone to "regular" will remove all associated "child" zones.');
            this.caller.confirmationModal.setMessageSecondary('Are you sure you want to proceed?');
            this.caller.confirmationModal.show();

            return;
        }

        this.caller.save();
    }

    //
    // Handles clicking save button event.
    protected onClickSaveFromModal(): void{

        this.caller.save();
    }

    //
    // Handles clicking cancel button event.
    protected onClickCancel(): void{

        this.caller.cancel();
    }

    //
    // Sets filter options.
    public setTypeFilterOptions(types): void{

        this.typeFilter.setOptions(types);
    }
}
