import { adUnitList_template } from '../components/templates/adUnitList_template';
import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import {pagination_template} from "../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/pagination_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class adUnit_list extends vnModule_knockoutJS{

    protected template: adUnitList_template;
    protected requestHandler: XMLHttpRequestHandler;
    protected pagination: pagination_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'adUnit_list';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Ad Units List");

        this.template = new adUnitList_template('adUnit_list', this.viewModel, this)

        return [this.template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getListFormData();
    }

    /**
     * Requests data for the filters.
     */
    protected getListFormData(): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem('list-results'));

        this.requestHandler = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ad_units/search/references/`, [], this);
        this.requestHandler.onReadyStateFunction = this.onFilterRequestReturn;
        this.requestHandler.execute();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public paginationOnPageClick(): void{

        this.getListData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected dataReorder(){

        this.getListData();
    }

    /**
     * Hook.
     *
     * Cancels data request.
     */
    protected filterSimpleCancel(){

        if(this.requestHandler){

            this.requestHandler.cancelAndReset();
        }
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected filterSimpleOnChange(){

        this.viewModel.adUnit_list.pagination.pageNumber(1);
        this.getListData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public filterDropdownOnChange(): void{

        this.viewModel.adUnit_list.pagination.pageNumber(1);
        this.getListData();
    }

    /**
     * Fetches the list data.
     */
    public getListData(): void{

        this.loadingIn(this.template.builtTemplate.children.namedItem("list-results"));

        if( this.requestHandler ){
            this.requestHandler.cancelAndReset();
        }

        let parameters: Array<Array<string>> = [];
        parameters.push(["data", ko.toJSON(this.template.getViewModelForRequest())]);

        this.requestHandler = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ad_units/search/get/`, parameters, this);
        this.requestHandler.onReadyStateFunction = this.onDataRequestReturn;
        this.requestHandler.execute();
    }

    /**
     * Returns handler for filter request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onFilterRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response || '{}');

                obj.updateFilters(responseParsed);
                obj.getListData();
            });
        }
    }

    /**
     * Returns handler for data request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response || '{}');

                obj.updateList(responseParsed);
                obj.loadingOut(obj.template.builtTemplate.children.namedItem('list-results'));
            });
        }
    }

    /**
     * Update the module list using the objects list.
     *
     * @param responseParsed
     */
    protected updateList(responseParsed: any){

        let execTime = Math.round((new Date().getTime() - this.requestHandler.requestStartTime) / 1000 * 100) / 100;

        this.template.setResultInfo(responseParsed.totalCount, responseParsed.totalPage,execTime );
        this.template.setListData(responseParsed.data.listData);

        // Update the paginator.
        this.viewModel.adUnit_list.pagination.itemCount(responseParsed.totalCount);
    }

    /**
     * Update the filters.
     *
     * @param responseParsed
     */
    protected updateFilters(responseParsed: any): void{

        this.template.setDimensionFilterItems(responseParsed.dimensions);
        this.template.setTypeFilterItems(responseParsed.types);
        this.template.setTemplateFilterItems(responseParsed.templates);
        this.template.setStatusFilterItems(responseParsed.statuses);
        this.template.setAdSetupFilterItems(responseParsed.adSetups);
        this.template.setZoneFilterItems(responseParsed.zones);
        this.template.setBidderTypeFilterItems(responseParsed.bidderTypes);
    }

    /**
     * Hook.
     *
     * Redirects to ad setups page.
     */
    protected goToAdSetupsList(): void{

        page(`/brand/${this.getVnAppZone().app.params.idBrand}/ads_setups`);
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected filterMultipleSelectorOnChange(){

        this.viewModel.adUnit_list.pagination.pageNumber(1);
        this.getListData();
    }
}