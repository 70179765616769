import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { login_template } from "../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/login_template";

declare var page: any;
declare var ko: any;

export class login extends vnModule_knockoutJS{

    protected loginTemplate: login_template;
    protected requestedUrl:  string;

    protected getModuleName(){
        return "login";
    }

    buildContent() : Array<Element>{

        this.loginTemplate = new login_template("loginForm", this.viewModel, this);
        return [this.loginTemplate.build()];
    }

    // template event
    public redirectToLogin(){
        page.redirect('/');
    }

    // template event
    protected onSubmitLoginForm(){

        this.requestedUrl = window.location.pathname;
        let loginReq_url: string = '/api/access/login/';
        let loginReq_Params: Array<Array<string>> = [];
        loginReq_Params.push( ["data", ko.toJSON(this.viewModel["loginForm"])] );
        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(loginReq_url,loginReq_Params, this);
        XHRHdl.onReadyStateFunction = (function(req, obj){

            return function(){

                if (req.request.readyState == 4) {

                    let responseParsed = JSON.parse(req.request.response);
                    let actualModule = (<login>obj);

                    if(req.request.status == 200){
                        if(actualModule.requestedUrl != "/") {
                            page.redirect(actualModule.requestedUrl);
                        } else {
                            page.redirect('/home');
                        }
                    }
                    else{
                        actualModule.onUpdateError(req.request.status, responseParsed)
                    }
                }
            }
        });
        XHRHdl.execute();
    }

    // template event
    public retrievePassword(){

        let loginReq_url: string = '/api/access/retrievepassword/';
        let loginReq_Params: Array<Array<string>> = [];
        loginReq_Params.push( ["data", ko.toJSON(this.viewModel["loginForm"])] );
        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(loginReq_url,loginReq_Params, this);
        XHRHdl.onReadyStateFunction = (function(req, obj){

            return function(){

                if (req.request.readyState == 4){

                    let responseParsed = JSON.parse(req.request.response);
                    let actualModule = (<login>obj);

                    if(req.request.status == 200){

                        document.querySelector("#retrieve-password").classList.add("is-hidden");
                        actualModule.loginTemplate.getTemplateViewModel().sentMessage("block");
                        actualModule.loginTemplate.getTemplateViewModel().retrieveInfo("Please verify your inbox, we have sent you an email with instructions on how to reset your password.");
                        actualModule.loginTemplate.clearValidationsMessages();
                    }
                    else{

                        actualModule.onUpdateError(req.request.status, responseParsed);
                    }
                }
            }
        });
        XHRHdl.execute();
    }

    protected onUpdateError(requestStatus: any, responseParsed) : void{

        this.loginTemplate.setError(responseParsed);
    }
}
