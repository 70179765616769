import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import { usersEdit_template } from "../components/templates/usersEdit_template";
import {header} from "./header";
import {vnApp_ads_ninja} from "../components/vnApp_ads_ninja";

declare var ko: any;
declare var page: any;
declare var Dropzone: any;

export class users_edit extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected editForm: usersEdit_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return "users_edit";
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Edit User");

        // create the users form
        this.editForm = new usersEdit_template("editForm", this.viewModel, this);

        return [this.editForm.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getFormData();
    }

    /**
     * Hook.
     *
     * Saves the edits to a user.
     */
    protected saveEditUser(): void{

        this.save();
    }

    /**
     * Hook.
     *
     * Cancels the edits to a user.
     */
    protected cancelEditUser(){

        this.loadingIn(this.editForm.builtTemplate);

        this.releaseLock();

        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "User action canceled!");

        if(this.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_USER_OTHERS)){

            page.redirect('/users');
        }
        else{

            page.redirect(`/users/profile/${this.getVnAppZone().app.params.id}`);
        }
    }

    /**
     * Requests a lock release.
     */
    protected releaseLock(): void{

        let id = this.getVnAppZone().app.params.id;

        if(!id){

            return;
        }

        let formReq_params: Array<Array<string>> = [];

        formReq_params.push(["data", ko.toJSON({'itemType' : 'user_edit'})] );

        // Request a lock release.
        this.XHRHdl = new XMLHttpRequestHandler(`/api/users/${id}/release_lock/`, formReq_params, this);
        this.XHRHdl.execute();
    }

    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getFormData(takeover: boolean = false): void{

        this.loadingIn(this.editForm.builtTemplate);

        let id = this.getVnAppZone().app.params.id || null;

        if(id){

            this.editForm.getViewModelForRequest().idUser(id);
        }

        this.XHRHdl                      = new XMLHttpRequestHandler(`/api/users/get/${id}/`, [['takeover', (takeover) ? '1' : '0']], this);
        this.XHRHdl.onReadyStateFunction = this.onGetFormDataReturn;
        this.XHRHdl.execute();
    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onGetFormDataReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let thisModule     = (<users_edit>obj);
                let responseParsed = JSON.parse(req.request.response || '{}');
                let vnApp          = thisModule.getVnAppZone().app;
                let appData        = responseParsed.data;
                let user           = responseParsed.data.user;
                let requestingUserProperties = responseParsed.data.requestingUserProperties;
                let requestingUserRoles = responseParsed.data.requestingUserRoles;

                obj.editForm.setupForm(vnApp, appData.roles, appData.properties, requestingUserRoles, requestingUserProperties);

                if(user.valnet_user.id !== undefined && user.valnet_user.id !== null){

                    obj.editForm.setSelectedProperties(user.brands);
                    obj.editForm.setSelectedRoles(user.valnet_user.roles);
                    obj.updateElementForm(user, obj);
                }
                else{

                    obj.editForm.getViewModelForRequest().isChangingPassword(true);
                }

                obj.generateDropzone(user.valnet_user);
                obj.loadingOut(obj.editForm.builtTemplate);
            });
        }
    }

    /**
     * Updates the form elements.
     *
     * @param user
     * @param thisModule
     */
    protected updateElementForm(user, thisModule: users_edit){

        thisModule.editForm.getViewModelForRequest().idUser(user.id);
        thisModule.editForm.getViewModelForRequest().firstName(user.valnet_user.firstName);
        thisModule.editForm.getViewModelForRequest().lastName(user.valnet_user.lastName);
        thisModule.editForm.getViewModelForRequest().loginName(user.valnet_user.loginName);
        thisModule.editForm.getViewModelForRequest().email(user.valnet_user.email);
        thisModule.editForm.getViewModelForRequest().password('');
        thisModule.editForm.getViewModelForRequest().passwordConfirmation('');
        thisModule.editForm.getViewModelForRequest().isActive((user.valnet_user.isActive || 0 == user.valnet_user.isActive) ? (user.valnet_user.isActive).toString() : '1');
    }

    /**
     * Generates the dropzone.
     *
     * @param user
     */
    protected generateDropzone(user): void{

        let myDropzone = new Dropzone("div#my-drop-zone", { url: "/api/users/uploadImage/", maxFiles: 1, acceptedFiles: "image/jpeg,image/png,image/gif"});
        let _this      = this;

        myDropzone.on("success", function(file, serverFileName) {

            _this.editForm.getViewModelForRequest().pictureUrl(serverFileName.data);
        });
        myDropzone.on("addedfile", function(file){

            if (this.files.length > 1) {
                this.removeFile(this.files[0]);
            }
        });

        if(user && user.pictureUrl){

            let mockFile = { name: user.pictureUrl, size: parseInt(user.pictureSize)};

            myDropzone.emit("addedfile", mockFile);
            myDropzone.createThumbnailFromUrl(mockFile, "/usersImages/" + mockFile.name);
            myDropzone.emit("complete", mockFile);
            myDropzone.files.push(mockFile);
            _this.editForm.getViewModelForRequest().pictureUrl(user.pictureUrl);
        }
    }

    /**
     * Saves the user data.
     */
    protected save(): void{

        this.loadingIn(this.editForm.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel                    = JSON.parse(ko.toJSON(this.viewModel));
        subViewModel["editForm"].properties = null;
        subViewModel["editForm"].roles      = null;

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler('/api/users/save', formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveReturn;
        XHRHdl.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                obj.releaseLock();

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'User saved successfully!');

                if(obj.hasAccessToSection(vnApp_ads_ninja.SECTION_ID_USER_OTHERS)){

                    page.redirect('/users');
                }
                else{

                    page.redirect(`/users/profile/${obj.getVnAppZone().app.params.id}`);
                }
            });

            obj.loadingOut(obj.editForm.builtTemplate);
        }
    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.getFormData(true);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }
}
