import {vnModule_knockoutJS} from "./vnModule_knockoutJS";
import {XMLHttpRequestHandler} from "../XMLHttpRequestHandler/XMLHttpRequestHandler";
import {pagination_template} from "../vnKnockoutJsTemplates/pagination_template";
import {rolesList_template} from "../vnKnockoutJsTemplates/rolesList_template";
import { vn_app_zone_notification } from "./vn_app_zone_notification";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class roles extends vnModule_knockoutJS{

    public XHRHdl: XMLHttpRequestHandler;
    protected rolesListTemplate: rolesList_template;
    protected pagination: pagination_template;

    protected getModuleName(){

        return "roles";
    }

    buildContent() : Array<Element>{

        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        
        if(zoneHeader){

            let loadedModule = zoneHeader.loadedModule;
            (<header>loadedModule).setHeaderTitle("Role List");
        }

        // create validation errors list template
        // create the users list
        this.rolesListTemplate = new rolesList_template("list", this.viewModel, this);

        return [this.rolesListTemplate.build()];
    }

    public fillPage() : void {
        this.filterList();
    }

    /**
     *
     * @param id
     * @protected
     */
    protected viewRole(id){

        this.saveViewModule(this.getMemoryViewModel());
        page('/roles/view/'+id);
    }


    protected addRole(): void {

        this.saveViewModule(this.getMemoryViewModel());
        page('/roles/add');
    }

    protected paginationOnPageClick(): void {
        this.filterList();
    }

    public rolesListReorder(): void {
        this.filterList();
    }


    /**
     *
     * @protected
     */
    protected filterSimpleOnChange(): void {
        this.viewModel.list.pagination.pageNumber(1);
        this.filterList();
    }

    /**
     *
     * @protected
     */
    protected filterSimpleCancel(): void {

        if( this.XHRHdl ){
            this.XHRHdl.cancelAndReset();
        }
    }

    /**
     *
     * @protected
     */
    protected getMemoryViewModel(): void {
        return this.rolesListTemplate.getTemplateViewModel();
    }

    /**
     * @protected
     */
    protected filterList(): void {

        if (this.XHRHdl) {
            this.XHRHdl.cancelAndReset();
        }

        // LOADING : starts
        this.loadingIn(this.rolesListTemplate.builtTemplate.children.namedItem("list-results"));

        let usrReq_url: string = '/api/roles/get';
        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(["data", ko.toJSON(this.rolesListTemplate.getViewModelForRequest())] );
        this.XHRHdl = new XMLHttpRequestHandler(usrReq_url,usrReq_params, this);
        this.XHRHdl.onReadyStateFunction = this.onFilterRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     *
     * @param req
     * @param obj
     * @protected
     */
    protected onFilterRequestReturn(req, obj): Function {
        return function(){
            obj.manageResponse(req, obj, function(){
                if (req.request.status == 200) {
                    let responseParsed = JSON.parse(req.request.response);
                    obj.updateList(responseParsed);
                }
            });
            obj.loadingOut(obj.rolesListTemplate.builtTemplate.children.namedItem("list-results"));
        }
    }

    //
    // update the module list using the objects list
    protected updateList(responseParsed: any): void {

        if (responseParsed.extraData) {
            this.updateExtraData(responseParsed.extraData);
        }

        this.rolesListTemplate.setRoles(responseParsed.data);

        let execTime = Math.round((new Date().getTime() - this.XHRHdl.requestStartTime)/1000*100)/100;
        this.rolesListTemplate.setResultInfo(responseParsed.totalCount, responseParsed.totalPage,execTime );

        // update the paginator
        this.rolesListTemplate.setPaginationTotalCount(responseParsed.totalCount);
    }

    //
    // update additional data needed in template.
    protected updateExtraData(extraData): void {

        this.rolesListTemplate.isAddButtonVisible(extraData.canAdd ? true : false);
    }
}