import { adTemplateList_template } from '../components/templates/adTemplateList_template';
import { vnModule_knockoutJS } from '../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS';
import { XMLHttpRequestHandler } from '../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler';
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class adTemplate_list extends vnModule_knockoutJS{

    protected adTemplateList_template: adTemplateList_template;
    protected XHRHdl: XMLHttpRequestHandler;

    /**
     * @inheritDoc
     */
    protected getModuleName(): string{

        return 'adTemplate_list';
    }

    /**
     * @inheritDoc
     */
    public buildContent() : Array<Element>{


        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Ad Templates List");

        this.adTemplateList_template = new adTemplateList_template('adTypes', this.viewModel, this);

        return [this.adTemplateList_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{

        this.getListData();
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    public paginationOnPageClick(): void{

        this.getListData();
    }

    /**
     * @inheritDoc
     */
    protected reorderAdTemplates(): void{

        this.getListData();
    }


    /**
     * Fetches the list data.
     */
    public getListData(): void{

        this.loadingIn(this.adTemplateList_template.builtTemplate.children.namedItem('list-results'));

        let subViewModel = this.adTemplateList_template.getViewModelForRequest();

        let usrReq_params: Array<Array<string>> = [];
        usrReq_params.push(['data', ko.toJSON(subViewModel)] );

        this.XHRHdl = new XMLHttpRequestHandler('/api/ad_templates/get/', usrReq_params, this);
        this.XHRHdl.onReadyStateFunction = this.onDataRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for data request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);

                obj.updateList(responseParsed);
                obj.setResultInfo(responseParsed.totalCount, responseParsed.totalPage);
                obj.updatePagination(responseParsed.totalCount);
                obj.loadingOut(obj.adTemplateList_template.builtTemplate.children.namedItem('list-results'));
            });
        }
    }

    /**
     * Update the module list using the objects list.
     *
     * @param responseParsed
     */
    protected updateList(responseParsed: any): void{

        this.loadingIn(this.adTemplateList_template.builtTemplate.children.namedItem('list-results'));


        this.adTemplateList_template.setAdTemplates(responseParsed.data);
    }

    /**
     * Hook.
     *
     * Redirects to create source page.
     */
    protected addAdTemplate(): void{

        page(`/ad_templates/add`);
    }

    /**
     * Hook.
     *
     * Redirects to create Geo page.
     */
    protected editAdTemplate(id): void{

        // page(`/ad_templates/edit/${id}`);
        this.handleNavigation(`/ad_templates/edit/${id}`, event);
    }

    /**
     * Deletes the requested resource.
     *
     * @param id
     * @protected
     */
    protected deleteAdTemplate(id): void{

        this.loadingIn(this.adTemplateList_template.builtTemplate.children.namedItem('list-results'));

        this.XHRHdl                      = new XMLHttpRequestHandler(`/api/ad_templates/delete/${id}`, [], this);
        this.XHRHdl.mode                 = XMLHttpRequestHandler_requestType.POST;
        this.XHRHdl.onReadyStateFunction = this.onDeleteRequestReturn;
        this.XHRHdl.execute();
    }

    /**
     * Returns handler for delete request.
     *
     * @param req
     * @param obj
     *
     * @return {function(): void}
     */
    protected onDeleteRequestReturn(req, obj): Function{

        return function(){

            obj.loadingOut(obj.adTemplateList_template.builtTemplate.children.namedItem('list-results'));

            obj.manageResponse(req, obj, function(){

                obj.getListData();
            });
        }
    }

    protected setResultInfo(totalCount, totalPage){
        let execTime = Math.round((new Date().getTime() - this.XHRHdl.requestStartTime)/1000*100)/100;
        this.adTemplateList_template.setResultInfo(totalCount, totalPage, execTime);
    }

    protected updatePagination(totalCount) {

        this.adTemplateList_template.updatePagination(totalCount);

    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.deleteAdTemplate(data);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

    }

    /**
     * Hook.
     *
     * Cancels data request.
     */
    protected filterSimpleCancel(){

        if(this.XHRHdl){

            this.XHRHdl.cancelAndReset();
        }
    }

    /**
     * Hook.
     *
     * Initiates data refresh.
     */
    protected filterSimpleOnChange(){

        this.adTemplateList_template.resetPageNumber();
        this.adTemplateList_template.resetOrderParameters();
        this.getListData();
    }
}